package de.geomobile.frontend.features.map

import com.ccfraser.muirwik.components.styles.Breakpoint
import com.ccfraser.muirwik.components.styles.up
import de.geomobile.common.portalmodels.*
import de.geomobile.common.portalmodels.Position
import de.geomobile.frontend.currentTheme
import de.geomobile.frontend.portalWebSocketApi
import de.geomobile.frontend.utils.maps.googlemapreact.*
import kotlinext.js.jsObject
import kotlinx.css.*
import kotlinx.serialization.builtins.serializer
import kotlinx.serialization.json.Json
import react.*
import styled.css
import styled.styledDiv
import utils.maps.googlemaps.LatLng
import utils.maps.googlemaps.LatLngBounds
import kotlin.js.Promise

fun RBuilder.mapView(
    hideOfflineVehicles: Boolean,
    showOnlyBadSignalVehicles: Boolean,
    drawerMenu: ReactElement,
    onDeviceClick: (DeviceIdentifier) -> Unit,
) = child(MapView::class) {
    attrs.hideOfflineVehicles = hideOfflineVehicles
    attrs.showOnlyBadSignalVehicles = showOnlyBadSignalVehicles
    attrs.drawerMenu = drawerMenu
    attrs.onDeviceClick = onDeviceClick
}

class MapView : RComponent<MapView.Props, MapView.State>() {

    private val session by kotlin.lazy {
        portalWebSocketApi.subscribe("/devicepositions")
    }

    interface Props : RProps {
        var hideOfflineVehicles: Boolean
        var showOnlyBadSignalVehicles: Boolean
        var drawerMenu: ReactElement
        var onDeviceClick: (DeviceIdentifier) -> Unit
    }

    class State : RState {
        var map: MapsDict? = null
        var devices: Map<Int, MapDevicePositionDTO> = emptyMap()
        var center: Coords? = googleMapCoords(
            latitude = 51.400427,
            longitude = 10.283918
        )
        var bounds: LatLngBounds? = null
        var firstUpdate: Boolean = true
    }

    init {
        state = State()
    }

    override fun componentDidMount() {
        session.connect {
            onmessage = {
                val update =
                    Json.decodeFromString(
                        DeltaUpdate.serializer(MapDevicePositionDTO.serializer(), Int.serializer()),
                        it
                    )

                val devices = state.devices.plus(update.newOrModified.orEmpty().associateBy { it.id })
                    .minus(update.deleted.orEmpty())

                setState {
                    this.devices = devices

                    if (firstUpdate) {
                        center = null
                        val bounds = devices.map { it.value.location }.bounds()
                        if (bounds != null) {
                            if (map != null) {
                                map!!.map.fitBounds(bounds)
                            } else {
                                this.bounds = bounds
                            }
                        }
                    }

                    firstUpdate = false
                }
            }
        }
    }

    override fun componentWillUnmount() {
        session.close()
    }

    override fun RBuilder.render() {
        styledDiv {
            css {
                display = Display.flex
                flexDirection = FlexDirection.column
                media(currentTheme.breakpoints.up(Breakpoint.sm)) {
                    height = 100.vh - 64.px
                }
                height = 100.vh - 56.px
                width = 100.pct
            }
            googleMap {
                attrs {
                    defaultCenter = state.center
                    defaultZoom = 3
                    resetBoundsOnResize = true
                    googleMapLoader = { Promise.resolve(js("google.maps") as Any) }
                    onGoogleApiLoaded = { maps ->
                        setState {
                            if (bounds != null) {
                                maps.map.fitBounds(bounds!!)
                            }

                            this.bounds = null
                            this.map = maps
                        }
                    }
                    options = jsObject<MapOptions> {
                        mapTypeControl = true
                        fullscreenControl = false
                    }
                }

                state.devices.values
                    .filter {
                        (!props.hideOfflineVehicles) ||
                                (props.hideOfflineVehicles && it.lastSeenStatus < TimestampStatus.Status.OFFLINE)
                    }
                    .filter {
                        (!props.showOnlyBadSignalVehicles) ||
                                (props.showOnlyBadSignalVehicles && it.gpsQuality < 70)
                    }
                    .forEach {
                        marker {
                            enableTransform = true
                            currentZoom = state.map?.map?.getZoom()?.toInt() ?: 3
                            key = it.id.toString()
                            lat = it.location.latitude
                            lng = it.location.longitude

                            id = it.id
                            vehicleId = it.vehicleId
                            serialNumber = it.serialNumber
                            vehicleType = it.vehicleType
                            statusLocation = it.lastLocationStatus
                            statusLastSeen = it.lastSeenStatus
                            gpsQuality = it.gpsQuality
                            onClick = props.onDeviceClick
                        }
                    }
            }
        }
    }
}

fun RBuilder.googleMap(block: RElementBuilder<Props>.() -> Unit): ReactElement {
    return child(GoogleMapReact::class, block)
}

fun googleMapCoords(
    latitude: Double,
    longitude: Double,
) = jsObject<Coords> {
    lat = latitude
    lng = longitude
}

fun Position.Location.toGoogleMapCoords() = jsObject<Coords> {
    lat = this@toGoogleMapCoords.latitude
    lng = this@toGoogleMapCoords.longitude
}

fun RBuilder.marker(block: MapMarker.Props.() -> Unit): ReactElement {
    return child(MapMarker::class) {
        attrs(block)
    }
}

fun RBuilder.circleMarker(block: MapCircleMarker.Props.() -> Unit): ReactElement {
    return child(MapCircleMarker::class) {
        attrs(block)
    }
}

fun Iterable<Position.Location>.bounds(): LatLngBounds? {
    if (none()) return null
    return LatLngBounds(
        sw = LatLng(
            lat = minByOrNull { it.latitude }!!.latitude,
            lng = minByOrNull { it.longitude }!!.longitude
        ),
        ne = LatLng(
            lat = maxByOrNull { it.latitude }!!.latitude,
            lng = maxByOrNull { it.longitude }!!.longitude
        )
    )
}