package de.geomobile.common.statistics

import kotlinx.serialization.Serializable

@Serializable
data class Month(
    val year: Int,
    val month: Int
) {
    companion object {
        fun parse(text: String):Month? {
            val split = text.split("-")
            val year = split.first().toIntOrNull() ?: return null
            val month = split.last().toIntOrNull() ?: return null
            if (month !in 1..12) return null
            return Month(year = year, month = month)
        }
    }
}

fun Month.format() : String = "$year-${month.toString().padStart(2, '0')}"

val Month.text: String
    get() {
        val monthText = when (month) {
            1 -> "Januar"
            2 -> "Februar"
            3 -> "März"
            4 -> "April"
            5 -> "Mai"
            6 -> "Juni"
            7 -> "Juli"
            8 -> "August"
            9 -> "September"
            10 -> "Oktober"
            11 -> "November"
            12 -> "Dezember"
            else -> error("invalid month $this")
        }

        return "$monthText ($year)"
    }

