package de.geomobile.frontend.features.admin.users

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonSize
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardActions
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.list.mList
import com.ccfraser.muirwik.components.list.mListItem
import com.ccfraser.muirwik.components.list.mListItemIcon
import com.ccfraser.muirwik.components.list.mListItemText
import de.geomobile.common.permission.Permissions
import de.geomobile.common.portalmodels.UserDTO
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.utils.CComponent
import de.geomobile.frontend.utils.authorize
import de.geomobile.frontend.utils.mCardHeaderExtended
import kotlinext.js.jsObject
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.*
import react.RBuilder
import react.RProps
import react.RState
import react.dom.div
import react.setState
import styled.css

fun RBuilder.adminUserDetail(
    userId: String,
    goBack: () -> Unit,
    edit: () -> Unit,
) = child(AdminUserDetail::class) {
    attrs.userId = userId
    attrs.goBack = goBack
    attrs.edit = edit
}

class AdminUserDetail : CComponent<AdminUserDetail.Props, AdminUserDetail.State>() {

    private var sendInviteJob: Job = Job()

    interface Props : RProps {
        var userId: String
        var goBack: () -> Unit
        var edit: () -> Unit
    }

    class State(
        var user: UserDTO? = null,
        var inviteSend: Boolean = false,
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        launch {
            val user = withContext(Dispatchers.Default) {
                portalRestApi.get("/admin/users/${props.userId}", UserDTO.serializer())
            }

            setState { this.user = user }
        }
    }

    override fun RBuilder.render() {
        val user = state.user

        mCard {
            mCardHeaderExtended(
                title = "Benutzer",
                subHeader = user?.email ?: "",
                titleTextTransform = TextTransform.uppercase,
                avatar = RBuilder().mIconButtonNoTranslate(
                    iconName = "arrow_back",
                    onClick = { props.goBack() }
                )
            ) {
                attrs.titleTypographyProps = jsObject<MTypographyProps> {
                    variant = MTypographyVariant.subtitle2
                    color = MTypographyColor.secondary
                }
                attrs.subheaderTypographyProps = jsObject<MTypographyProps> {
                    variant = MTypographyVariant.caption
                }
            }
            mDivider { }
            mCardContent {
                if (user == null)
                    mCircularProgress { css { display = Display.block; margin(LinearDimension.auto) } }
                else {
                    mList {
                        attrs.disablePadding = true

                        mListItem {
                            attrs.divider = true
                            attrs.button = false

                            mListItemText {
                                mTypography(
                                    text = "E-Mail",
                                    variant = MTypographyVariant.caption,
                                )
                                mTypography(
                                    text = user.email,
                                    variant = MTypographyVariant.subtitle2,
                                    color = MTypographyColor.textPrimary
                                )
                            }
                        }

                        mListItem {
                            attrs.divider = true
                            attrs.button = false

                            mListItemText {
                                mTypography(
                                    text = "Vorname",
                                    variant = MTypographyVariant.caption,
                                )
                                mTypography(
                                    text = user.firstName ?: "-",
                                    variant = MTypographyVariant.subtitle2,
                                    color = MTypographyColor.textPrimary
                                )
                            }
                        }

                        mListItem {
                            attrs.divider = true
                            attrs.button = false

                            mListItemText {
                                mTypography(
                                    text = "Nachname",
                                    variant = MTypographyVariant.caption,
                                )
                                mTypography(
                                    text = user.lastName ?: "-",
                                    variant = MTypographyVariant.subtitle2,
                                    color = MTypographyColor.textPrimary
                                )
                            }
                        }

                        mListItem {
                            attrs.divider = true
                            attrs.button = false

                            mListItemText {
                                mTypography(
                                    text = "Unternehmen",
                                    variant = MTypographyVariant.caption,
                                )
                                mTypography(
                                    text = user.company.name,
                                    variant = MTypographyVariant.subtitle2,
                                    color = MTypographyColor.textPrimary
                                )
                            }
                        }

                        mListItem {
                            attrs.divider = true
                            attrs.button = false

                            mListItemText {
                                mTypography(
                                    text = "Rolle",
                                    variant = MTypographyVariant.caption,
                                )
                                mTypography(
                                    text = user.role.name,
                                    variant = MTypographyVariant.subtitle2,
                                    color = MTypographyColor.textPrimary
                                )
                            }
                        }

                        mListItem {
                            attrs.divider = false
                            attrs.button = false

                            mListItemText {
                                mTypography(
                                    text = "Status",
                                    variant = MTypographyVariant.caption,
                                )
                                mTypography(
                                    text = user.state.toText(),
                                    variant = MTypographyVariant.subtitle2,
                                    color = MTypographyColor.textPrimary
                                )
                                authorize(Permissions.UserManagement.usersEdit) {
                                    if (user.state is UserDTO.State.Invited) {
                                        mListItemIcon {
                                            attrs.disabled = true
                                            attrs.disableRipple = true

                                            mIconButtonNoTranslate(
                                                iconName = "Erneut einladen",
                                                iconColor = MIconColor.inherit,
                                                addAsChild = true,
                                                size = MButtonSize.medium,
                                                onClick = { sendInvite() }
                                            )
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            authorize(Permissions.UserManagement.usersEdit) {
                mDivider { }
                mCardActions {
                    css { padding(2.spacingUnits) }
                    mButton(
                        caption = "Bearbeiten",
                        variant = MButtonVariant.contained,
                        color = MColor.secondary,
                        disabled = state.user == null,
                        onClick = { props.edit() }
                    ) {
                        attrs.disableElevation = true
                    }
                }
            }
        }

        mSnackbar(
            message = "Einladung wurde versendet",
            open = state.inviteSend,
            horizAnchor = MSnackbarHorizAnchor.center,
            autoHideDuration = 4000
        ) {
            attrs.onClose = { _, _ ->
                setState { this.inviteSend = false }
            }
            attrs.action = RBuilder().div {
                mIconButtonNoTranslate(
                    iconName = "close",
                    onClick = { setState { this.inviteSend = false } },
                    color = MColor.inherit
                )
            }
        }
    }

    private fun sendInvite() {
        sendInviteJob.cancel()
        sendInviteJob = launch {
            val newUser = withContext(Dispatchers.Default) {
                portalRestApi.post(
                    path = "/admin/users/${props.userId}/invite",
                    serializer = UserDTO.serializer()
                )
            }

            setState {
                this.user = newUser
                this.inviteSend = true
            }
        }
    }
}