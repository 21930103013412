package de.geomobile.frontend.features.map

import com.ccfraser.muirwik.components.button.MButtonSize
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.lab.alert.MAlertSeverity
import com.ccfraser.muirwik.components.lab.alert.MAlertVariant
import com.ccfraser.muirwik.components.lab.alert.mAlert
import com.ccfraser.muirwik.components.list.mList
import com.ccfraser.muirwik.components.list.mListItem
import com.ccfraser.muirwik.components.mDivider
import com.ccfraser.muirwik.components.mIconButtonNoTranslate
import com.ccfraser.muirwik.components.mSwitchWithLabel
import com.ccfraser.muirwik.components.spacingUnits
import com.ccfraser.muirwik.components.styles.Breakpoint
import com.ccfraser.muirwik.components.styles.up
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.currentTheme
import kotlinx.css.*
import org.w3c.dom.events.Event
import react.RBuilder
import react.RComponent
import react.RProps
import react.RState
import styled.css
import styled.styledDiv

fun RBuilder.mapSettings(
    hideOfflineVehicles: Boolean,
    showOnlyBadSignalVehicles: Boolean,
    handleHideOfflineVehicles: (event: Event, checked: Boolean) -> Unit,
    handleShowOnlyBadSignalVehicles: (event: Event, checked: Boolean) -> Unit,
    onClose: () -> Unit,
) = child(MapSettingsView::class) {
    attrs.hideOfflineVehicles = hideOfflineVehicles
    attrs.showOnlyBadSignalVehicles = showOnlyBadSignalVehicles
    attrs.handleHideOfflineVehicles = handleHideOfflineVehicles
    attrs.handleShowOnlyBadSignalVehicles = handleShowOnlyBadSignalVehicles
    attrs.onClose = onClose
}

class MapSettingsView : RComponent<MapSettingsView.Props, RState>() {

    interface Props : RProps {
        var hideOfflineVehicles: Boolean
        var showOnlyBadSignalVehicles: Boolean
        var handleHideOfflineVehicles: (event: Event, checked: Boolean) -> Unit
        var handleShowOnlyBadSignalVehicles: (event: Event, checked: Boolean) -> Unit
        var onClose: () -> Unit
    }

    override fun RBuilder.render() {
        mCard {
            css(GlobalStyles.card)
            css {
                display = Display.flex
                borderRadius = 0.px
                media(currentTheme.breakpoints.up(Breakpoint.sm)) {
                    height = 100.vh - 64.px
                    width = 400.px
                }
                height = 100.vh - 56.px
                width = 400.px
                overflow = Overflow.auto
                flexDirection = FlexDirection.column
            }
            mCardContent {
                css(GlobalStyles.cardDeviceSidebarContent)
                styledDiv {
                    css { padding(1.spacingUnits) }
                    mIconButtonNoTranslate(
                        iconName = "close",
                        size = MButtonSize.small,
                        onClick = { props.onClose() }
                    )
                }
                mDivider { css { width = 100.pct } }
                mList {
                    mListItem {
                        attrs.button = true

                        mSwitchWithLabel(
                            label = "Offline ausblenden",
                            checked = props.hideOfflineVehicles,
                            onChange = props.handleHideOfflineVehicles
                        )
                    }
                    mListItem {
                        attrs.button = true

                        mSwitchWithLabel(
                            label = "Nur schlechtes GPS",
                            checked = props.showOnlyBadSignalVehicles,
                            onChange = props.handleShowOnlyBadSignalVehicles,
                        )
                    }
                }
                styledDiv {
                    css { padding(2.spacingUnits) }

                    mAlert(
                        variant = MAlertVariant.outlined,
                        severity = MAlertSeverity.info,
                        message = "Sofern die Anzahl der Ausfälle bei der Signalübertragung einen Grenzwert von 20% oder mehr erreicht, erfolgt eine Einordnung der betroffenen Geräte in die Kategorie \"Signal ist schwach\". Wird jede Woche zurückgesetzt.",
                    )
                }
            }
        }
    }
}