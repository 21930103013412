package de.geomobile.common.softwaremgmt

import de.geomobile.common.portalmodels.DeviceSmallDTO
import de.geomobile.common.portalmodels.TimestampStatus
import de.geomobile.common.time.LocalDateTime
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient

@Serializable
data class AssignmentUpdateState(
    val lastPollTimestamp: TimestampStatus = TimestampStatus(),
    val assignment: DeviceSoftwareAssignment? = null,
    val softwareUpdateStates: List<ReceivedUpdateState> = emptyList(),
    val lastInstalledExternalVersion: String? = null
) {
    // Order is important!!
    enum class State(val readableName: String) {
        NOTHING_ASSIGNED("-"),
        UP_TO_DATE("Aktuell"),
        UPDATE_AVAILABLE("Ausstehend"),
        UPDATING("Aktualisierend"),
        UPDATE_CANCELED("Abgebrochen"),
        UPDATE_FAILED("Fehler");
    }

    @Transient
    val state: State
        get() = assignmentUpdateState(assignment, softwareUpdateStates.associate { it.state.softwareId to it.state })

    @Transient
    val progress: Int
        get() = assignmentUpdateProgress(assignment, softwareUpdateStates.associate { it.state.softwareId to it.state })

    @Transient
    val externalState: State
        get() =
            if (lastInstalledExternalVersion == assignment?.currentAssignment?.bundle?.externalVersion) State.UP_TO_DATE
            else state
}

@Serializable
data class ReceivedUpdateState(
    val deviceId: Int,
    val received: LocalDateTime,
    val state: UpdateState
)

@Serializable
sealed class UpdateState {
    @Transient
    abstract val type: Type
    abstract val current: SoftwareVersionDTO?
    abstract val softwareId: String

    @Serializable
    @SerialName("INSTALLED")
    data class Installed(override val current: SoftwareVersionDTO? = null) : UpdateState() {
        @Transient
        override val type = Type.INSTALLED
        override val softwareId = current?.softwareId.orEmpty()
    }

    @Serializable
    sealed class Updating : UpdateState() {

        abstract val from: SoftwareVersionDTO?
        abstract val to: SoftwareVersionDTO

        override val current: SoftwareVersionDTO? get() = from
        override val softwareId get() = to.softwareId

        @Serializable
        @SerialName("WAITING_IN_QUEUE")
        data class WaitingInQueue(override val from: SoftwareVersionDTO? = null, override val to: SoftwareVersionDTO) :
            Updating() {
            @Transient
            override val type = Type.WAITING_IN_QUEUE
        }

        @Serializable
        @SerialName("DOWNLOADING")
        data class Downloading(override val from: SoftwareVersionDTO? = null, override val to: SoftwareVersionDTO) :
            Updating() {
            @Transient
            override val type = Type.DOWNLOADING
        }

        @Serializable
        @SerialName("DOWNLOADED")
        data class Downloaded(override val from: SoftwareVersionDTO? = null, override val to: SoftwareVersionDTO) :
            Updating() {
            @Transient
            override val type = Type.DOWNLOADED
        }

        @Serializable
        @SerialName("UNPACKED")
        data class Unpacked(override val from: SoftwareVersionDTO? = null, override val to: SoftwareVersionDTO) :
            Updating() {
            @Transient
            override val type = Type.UNPACKED
        }

        @Serializable
        @SerialName("INITIALIZED")
        data class Initialized(override val from: SoftwareVersionDTO? = null, override val to: SoftwareVersionDTO) :
            Updating() {
            @Transient
            override val type = Type.INITIALIZED
        }

        @Serializable
        @SerialName("WAITING_FOR_RESTART")
        data class WaitingForRestart(
            override val from: SoftwareVersionDTO? = null,
            override val to: SoftwareVersionDTO
        ) :
            Updating() {
            @Transient
            override val type = Type.WAITING_FOR_RESTART
        }

    }

    @Serializable
    @SerialName("CANCELED")
    data class Canceled(
        val from: SoftwareVersionDTO? = null,
        val to: SoftwareVersionDTO,
        val rolledBack: Boolean
    ) : UpdateState() {
        @Transient
        override val type = Type.CANCELED
        override val current: SoftwareVersionDTO? = from
        override val softwareId = to.softwareId
    }

    @Serializable
    @SerialName("FAILED")
    data class Failed(
        val from: SoftwareVersionDTO? = null,
        val to: SoftwareVersionDTO,
        val rolledBack: Boolean,
        val error: String
    ) : UpdateState() {
        @Transient
        override val type = Type.FAILED
        override val current: SoftwareVersionDTO? = from
        override val softwareId = to.softwareId
    }

    enum class Type {
        INSTALLED,
        WAITING_IN_QUEUE,
        DOWNLOADING,
        DOWNLOADED,
        UNPACKED,
        INITIALIZED,
        WAITING_FOR_RESTART,
        FAILED,
        CANCELED,
        UNINSTALLED
    }
}

fun assignmentUpdateState(
    assignment: DeviceSoftwareAssignment?,
    updateStates: Map<String, UpdateState>
): AssignmentUpdateState.State {
    if (assignment == null) return AssignmentUpdateState.State.NOTHING_ASSIGNED

    val assigned = assignment.currentAssignment.bundle.software
        .associateBy(
            { it.software.id },
            { it.softwareVersion }
        )

    val toBeDeleted = updateStates
        .filter { !assigned.containsKey(it.key) }
        .mapValues { AssignmentUpdateState.State.UPDATE_AVAILABLE }

    val states = assigned.mapValues { (softwareId, assigned) ->
        val updateState = updateStates[softwareId]
            ?: return@mapValues AssignmentUpdateState.State.UPDATE_AVAILABLE

        if (updateState.isOutdated(
                assigned,
                assignment.reassignment?.reassignedAt ?: assignment.currentAssignment.assignedAt
            )
        ) {
            AssignmentUpdateState.State.UPDATE_AVAILABLE
        } else {
            when (updateState) {
                is UpdateState.Installed -> AssignmentUpdateState.State.UP_TO_DATE
                is UpdateState.Updating -> AssignmentUpdateState.State.UPDATING
                is UpdateState.Canceled -> AssignmentUpdateState.State.UPDATE_CANCELED
                is UpdateState.Failed -> AssignmentUpdateState.State.UPDATE_FAILED
            }
        }
    }
        .plus(toBeDeleted)
        .values

    return states.maxByOrNull { it.ordinal } ?: AssignmentUpdateState.State.UP_TO_DATE
}

fun assignmentUpdateProgress(
    assignment: DeviceSoftwareAssignment?,
    updateStates: Map<String, UpdateState>
): Int {
    if (assignment == null) return -1

    val assigned = assignment.currentAssignment.bundle.software
        .associateBy(
            { it.software.id },
            { it.softwareVersion }
        )

    val states = assigned.mapNotNull { (softwareId, _) ->
        updateStates[softwareId]
    }

    val max = assigned.size * 3
    val progress = states.map { updateState ->
        when (updateState.type) {
            UpdateState.Type.INSTALLED ->
                if(updateState.isOutdated(assigned[updateState.softwareId]!!, assignment.reassignment?.reassignedAt ?: assignment.currentAssignment.assignedAt)) 0 else 3
            UpdateState.Type.DOWNLOADING -> 2
            UpdateState.Type.WAITING_IN_QUEUE -> 1
            else -> 0
        }
    }.sum()

    return ((progress.toDouble() / max.toDouble()) * 100.0).toInt()
}

@Serializable
data class UpdateStateSmallDTO(
    val currentAssignment: SoftwareAssignment,
    val currentUpdateStates: List<ReceivedUpdateState> = emptyList(),
    val lastInstalledExternalVersion: String? = null
)

fun UpdateState.isOutdated(assignment: SoftwareVersion, assignedAt: LocalDateTime): Boolean = when (this) {
    is UpdateState.Installed -> current == null || current!!.isOutdated(assignment, current!!.assignedAt) //ignore assigned at
    is UpdateState.Updating -> to.isOutdated(assignment, to.assignedAt) //ignore assigned at
    is UpdateState.Failed -> to.isOutdated(assignment, assignedAt)
    is UpdateState.Canceled -> to.isOutdated(assignment, assignedAt)
}

private fun SoftwareVersionDTO.isOutdated(assignment: SoftwareVersion, assignedAt: LocalDateTime): Boolean =
    this.version != assignment.version || this.assignedAt != assignedAt


val AssignmentUpdateState.smallDto: UpdateStateSmallDTO?
    get() = assignment?.let {
        UpdateStateSmallDTO(
            currentAssignment = assignment.assignmentOverwrite ?: assignment.currentAssignment,
            currentUpdateStates = softwareUpdateStates,
            lastInstalledExternalVersion = lastInstalledExternalVersion
        )
    }