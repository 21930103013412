package de.geomobile.frontend.features.softwareManagement.software

import com.ccfraser.muirwik.components.MColor
import com.ccfraser.muirwik.components.MGridDirection
import com.ccfraser.muirwik.components.button.MButtonSize
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.list.mList
import com.ccfraser.muirwik.components.list.mListItem
import com.ccfraser.muirwik.components.list.mListSubheader
import com.ccfraser.muirwik.components.mDivider
import com.ccfraser.muirwik.components.spacingUnits
import com.ccfraser.muirwik.components.styles.Breakpoint
import components.emptyView
import de.geomobile.common.portalmodels.Product
import de.geomobile.common.softwaremgmt.Software
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.spacer
import de.geomobile.frontend.utils.*
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.*
import kotlinx.serialization.builtins.ListSerializer
import react.RBuilder
import react.RProps
import react.RState
import react.router.dom.route
import react.router.dom.switch
import react.setState
import styled.css
import styled.styledDiv

fun RBuilder.softwareManagement(
    selectedId: String?,
    addingNew: Boolean,
    changeSelection: (selectedId: String?, addingNew: Boolean) -> Unit,
    product: Product,
    path: String
) = child(SoftwareManagement::class) {
    attrs.selectedId = selectedId
    attrs.addingNew = addingNew
    attrs.changeSelection = changeSelection
    attrs.product = product
    attrs.path = path
}

interface SelectedSoftwareProps : RProps {
    var selectedId: String?
}

class SoftwareManagement : CComponent<SoftwareManagement.Props, SoftwareManagement.State>() {

    private var loadSoftwareJob: Job = Job()

    interface Props : RProps {
        var selectedId: String?
        var addingNew: Boolean
        var changeSelection: (selectedId: String?, addingNew: Boolean) -> Unit
        var product: Product
        var path: String
    }

    class State(
        var software: List<Software> = emptyList(),
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        loadSoftware()
    }

    private fun loadSoftware() {
        loadSoftwareJob.cancel()
        loadSoftwareJob = launch {
            val software = withContext(Dispatchers.Default) {
                portalRestApi.get(
                    path = "/software/management/get",
                    serializer = ListSerializer(
                        Software.serializer()
                    ),
                    parameter = mapOf("product" to props.product.readableName)
                )
            }

            setState {
                this.software = software
            }
        }
    }

    override fun RBuilder.render() {
        val selected = props.selectedId?.let { name ->
            state.software.singleOrNull { it.id == name }
        }

        spacer()
        mGridContainer2(direction = MGridDirection.row) {
            softwareList()

            mGridItem2(
                MGridBreakpoints2(MGridSize2.Cells9)
                    .down(Breakpoint.md, MGridSize2.Cells8)
                    .down(Breakpoint.sm, MGridSize2.Cells12)
            ) {
                if (selected != null || props.addingNew) {
                    softwareDetail(
                        software = selected,
                        onSave = {
                            loadSoftware()
                            if (props.addingNew) {
                                props.changeSelection(it.id, false)
                            }
                        },
                        product = props.product
                    )

                    if (selected != null) {
                        switch {
                            route<SelectedSoftwareVersionProps>("${props.path}/${selected.id}/:selectedVersion?") { newProps ->
                                softwareVersionManagement(
                                    softwareId = selected.id,
                                    selectedVersion = newProps.match.params.selectedVersion,
                                    changeSelection = { selectedVersion, _ ->
                                        var path = "${props.path}/${selected.id}"
                                        if (selectedVersion != null)
                                            path += "/$selectedVersion"
                                        if (path != newProps.location.pathname)
                                            newProps.history.push(path)
                                    }
                                )
                            }
                        }
                    }
                } else {
                    emptyView(
                        title = if (props.product == Product.INTERACT) "Firmware" else "Software",
                        caption = "Es wurde keine ${if (props.product == Product.INTERACT) "Firmware" else "Software"} ausgewählt",
                        addButton = false
                    )
                }
            }
        }
    }

    private fun RBuilder.softwareList() {
        mGridItem2(
            MGridBreakpoints2(MGridSize2.Cells3)
                .down(Breakpoint.md, MGridSize2.Cells4)
                .down(Breakpoint.sm, MGridSize2.Cells12)
        ) {
            mCard {
                css(GlobalStyles.card)
                mCardContent {
                    css(GlobalStyles.cardListContent)

                    if (state.software.isEmpty()) {
                        emptyView(
                            iconName = "menu",
                            title = if (props.product == Product.INTERACT) "Firmware" else "Software",
                            caption = "Es wurde keine ${if (props.product == Product.INTERACT) "Firmware" else "Software"} gefunden",
                            addButton = true,
                            actionBtnCaption = "Erstellen"
                        ) { props.changeSelection(null, true) }
                    } else {
                        mCard {
                            css(GlobalStyles.card)
                            mCardContent {
                                css(GlobalStyles.cardListContent)

                                styledDiv {
                                    css {
                                        display = Display.flex
                                        justifyContent = JustifyContent.spaceBetween
                                        alignItems = Align.center
                                    }
                                    mListSubheader(heading = if (props.product == Product.INTERACT) "Firmware" else "Software")
                                    mButton(
                                        caption = "Neu",
                                        size = MButtonSize.small,
                                        color = MColor.secondary,
                                        variant = MButtonVariant.outlined,
                                        onClick = { props.changeSelection(null, true) }
                                    ) {
                                        css { margin(0.spacingUnits, 1.spacingUnits) }
                                    }
                                }
                                mDivider { }
                                mList {
                                    for (software in state.software) {
                                        mListItem(
                                            primaryText = software.name,
                                            divider = false,
                                            selected = software.id == props.selectedId,
                                            onClick = { props.changeSelection(software.id, false) }
                                        ) {
                                            attrs.dense = true
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}