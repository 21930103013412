package portalmodels

import de.geomobile.common.portalmodels.Product
import de.geomobile.common.time.LocalDateTime
import kotlinx.serialization.Serializable

@Serializable
data class VehicleStatusDTO(
        var cpuId: String? = null,
        var serialNumber: Int? = null,
        var vehicleId: String,
        var product: Product,
        var companyId: String,
        var checkedAt: LocalDateTime,
        var lastSeen: LocalDateTime? = null,
        var isBetaTime: Boolean
        //var elaHistory: String? = ""
)