package de.geomobile.common.softwaremgmt

import de.geomobile.common.portalmodels.Product
import de.geomobile.common.time.LocalDateTime
import kotlinx.serialization.Serializable

@Serializable
data class Software(
    val id: String,
    val name: String,
    val uploadToken: String,
    val updater: Boolean = false,
    val product: Product
)

private val allowedNameCharacters = ('A'..'Z') + ('a'..'z') + ('0'..'9') + '_' + '-'
fun Software.isIdValid() = id.isNotBlank() && id.all { it in allowedNameCharacters }

@Serializable
data class SoftwareVersion(
    val softwareId: String,
    val version: String,
    val size: Long,
    val md5: String,
    val rebootOnSuccess: Boolean = false,
    val rollbackOnFailure: Boolean = false,
    val temporaryDownload: Boolean = false,
    val committedAt: LocalDateTime? = null,
    val uploadedBy: String,
    val downloadToken: String
)

@Serializable
data class SoftwareVersionDTO(
    val softwareId: String,
    val version: String,
    val assignedAt: LocalDateTime,
    val size: Long? = null,
    val md5: String? = null,
    val rebootOnSuccess: Boolean = false,
    val rollbackOnFailure: Boolean = false,
    val temporaryDownload: Boolean = false
)

fun SoftwareVersion.toDto(assignedAt: LocalDateTime) = SoftwareVersionDTO(
    softwareId = softwareId,
    version = version,
    assignedAt = assignedAt,
    size = size,
    md5 = md5,
    rebootOnSuccess = rebootOnSuccess,
    rollbackOnFailure = rollbackOnFailure,
    temporaryDownload = temporaryDownload
)

@Serializable
data class SoftwareFile(
    val id: String,
    val filename: String,
    val downloadUrl: String = "",
    val md5: String,
    val size: Long
) {
    val host: String? = hostRegex.find(filename)?.groupValues?.getOrNull(1)
    fun relevantForHost(target: String?) : Boolean = when (this.host) {
        null -> true
        target -> true
        else -> false
    }
}

private val hostRegex = Regex("^__(.+?)__\\/")