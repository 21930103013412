package utils

import kotlin.math.ln
import kotlin.math.pow
import kotlin.math.roundToInt

fun Long.humanReadableByteCount(si: Boolean = true): String {
    val unit = if (si) 1000 else 1024
    if (this < unit) return "$this B"
    val exp = (ln(this.toDouble()) / ln(unit.toDouble())).toInt()
    val pre = (if (si) "kMGTPE" else "KMGTPE")[exp - 1] + if (si) "" else "i"
    val value = this / unit.toDouble().pow(exp.toDouble())
    return "${(value * 100.0).roundToInt() / 100.0} ${pre}B"
}