package de.geomobile.common.portalmodels

import de.geomobile.common.stage.Stage
import kotlinx.serialization.Serializable


@Serializable
data class MapDevicePositionDTO(
    val id :Int,
    val product: Product,
    val serialNumber: Int? = null,
    val cpuId: String? = null,
    val vehicleId: String? = null,
    val vehicleType: VehicleType,
    val company: CompanySmall,
    val stage: Stage,
    val lastLocationStatus: TimestampStatus.Status,
    val lastSeenStatus: TimestampStatus.Status,
    val location: Position.Location,
    val gpsQuality: Int,
    val sharingWith: List<CompanySmall> = emptyList()
) {

    fun identifierById(): DeviceIdentifier.Id = DeviceIdentifier.Id(id = id)

    fun identifierBySerialNumber(): DeviceIdentifier.SerialNumber? = serialNumber?.let {
        DeviceIdentifier.SerialNumber(
            product = product,
            serialNumber = serialNumber
        )
    }

    fun identifierByCpuId(): DeviceIdentifier.CpuId? = cpuId?.let {
        DeviceIdentifier.CpuId(
            product = product,
            cpuId = cpuId
        )
    }

    fun primaryIdentifier(): DeviceIdentifier =
        identifierBySerialNumber()
            ?: identifierByCpuId()
            ?: identifierById()
}
