package de.geomobile.common.portalmodels

import de.geomobile.common.time.LocalDate
import de.geomobile.common.time.LocalDateTime
import kotlinx.serialization.Serializable

@Serializable
data class AllPrintJobsDTO(
    val openJobs: List<PrintJobDTO>,
    val closedJobs: List<PrintJobDTO>
)

@Serializable
data class PrintJobDTO(
    val serialNumber: String,
    val materialNumber: Int,
    val manufacturingDate: LocalDate,
    val printedAt: LocalDateTime? = null
)