package de.geomobile.frontend.features.device.detail

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.list.mList
import com.ccfraser.muirwik.components.list.mListItem
import com.ccfraser.muirwik.components.list.mListItemText
import de.geomobile.common.feature.Features
import de.geomobile.common.permission.Permissions
import de.geomobile.common.portalmodels.DeviceDetailDTO
import de.geomobile.common.portalmodels.VehicleType
import de.geomobile.frontend.api.TopicSession
import de.geomobile.frontend.features.device.toDetailPath
import de.geomobile.frontend.portalWebSocketApi
import de.geomobile.frontend.utils.*
import kotlinx.css.input
import kotlinx.css.padding
import kotlinx.css.properties.TextDecoration
import kotlinx.css.properties.Time
import kotlinx.css.px
import kotlinx.css.textDecoration
import kotlinx.html.InputType
import kotlinx.serialization.json.Json
import react.*
import styled.css
import styled.styledDiv

fun RBuilder.deviceStatus(
    id: Int,
    onDeviceDetailPage: Boolean,
) = child(DeviceStatusComponent::class) {
    key = id.toString()
    attrs.id = id
    attrs.onDeviceDetailPage = onDeviceDetailPage
}

class DeviceStatusComponent : RComponent<DeviceStatusComponent.Props, DeviceStatusComponent.State>() {

    private var session: TopicSession? = null

    interface Props : RProps {
        var id: Int
        var onDeviceDetailPage: Boolean
    }

    class State(
        var deviceDetail: DeviceDetailDTO? = null,
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        session?.close()
        session = portalWebSocketApi.subscribe("/device/detail", mapOf("id" to props.id.toString()))
        session?.connect {
            onmessage = {
                val deviceDetail = Json.decodeFromString(DeviceDetailDTO.serializer(), it)

                setState {
                    this.deviceDetail = deviceDetail
                }
            }
        }
    }

    override fun componentWillUnmount() {
        session?.close()
    }

    override fun RBuilder.render() {
        styledDiv {
            if (state.deviceDetail == null) styledDiv {
                css { padding(2.spacingUnits) }
                mSkeleton(
                    height = 100.px,
                    animation = MSkeletonAnimation.wave,
                    variant = MSkeletonVariant.rect
                )
            } else {
                val device = state.deviceDetail ?: return

                styledDiv {
                    if (props.onDeviceDetailPage) {
                        css { padding(2.spacingUnits) }
                    }

                    mList {
                        attrs.disablePadding = true
                        attrs.dense = true

                        mListItem {
                            attrs.button = false
                            attrs.divider = true

                            mListItemText {
                                mTypography(
                                    text = "Seriennummer",
                                    variant = MTypographyVariant.caption,
                                )

                                if (device.serialNumber.toString() != "null") {
                                    styledRouteLink("/devices${device.primaryIdentifier().toDetailPath()}") {
                                        css { textDecoration = TextDecoration.none }
                                        mTypography(
                                            text = device.serialNumber.toString(),
                                            variant = MTypographyVariant.subtitle2,
                                            color = MTypographyColor.secondary
                                        )
                                    }
                                } else {
                                    mTypography(
                                        text = "-",
                                        variant = MTypographyVariant.subtitle2,
                                        color = MTypographyColor.textPrimary
                                    )
                                }
                            }
                        }

                        tableRowVehicleIdEdit(
                            label = "Fahrzeugnummer",
                            allowEdit = isAuthorized(Permissions.DeviceManagement.vehicleIdWrite),
                            device = device
                        )

                        //if vehicle type is set in vehicle profile -> do not allow changes
                        if (device.vehicleProfile != null) {
                            mListItem {
                                attrs.button = false
                                attrs.divider = true

                                mListItemText {
                                    mTypography(
                                        text = "Fahrzeugtyp",
                                        variant = MTypographyVariant.caption,
                                    )
                                    mTypography(
                                        text = device.vehicleType.readableName,
                                        variant = MTypographyVariant.subtitle2,
                                        color = MTypographyColor.textPrimary
                                    )
                                }
                            }
                        } else {
                            tableRowChooseValue(
                                label = "Fahrzeugtyp",
                                loadValues = { getAllVehicleTypes() },
                                loadSelectedValue = { device.vehicleType },
                                loadApplyPath = { "/device/${device.id}/vehicleType" },
                                loadBody = { newValue -> (newValue as VehicleType).name },
                                allowEdit = isAuthorized(Permissions.DeviceManagement.vehicleTypeWrite),
                                selectionTitle = { (it as VehicleType).readableName }
                            )
                        }
                        mListItem {
                            attrs.button = false
                            attrs.divider = true

                            mListItemText {
                                mTypography(
                                    text = "Produktvariante",
                                    variant = MTypographyVariant.caption,
                                )
                                mTypography(
                                    text = device.productVariant ?: "-",
                                    color = MTypographyColor.initial,
                                    align = MTypographyAlign.left,
                                    variant = MTypographyVariant.subtitle2
                                )
                            }
                        }
                        withFeature(Features.VehicleProfileFeatures.vehicleProfiles) {
                            if (device.vehicleProfile == null) {
                                mListItem {
                                    attrs.button = false
                                    attrs.divider = true

                                    mListItemText {
                                        mTypography(
                                            text = "Fahrzeugprofil",
                                            variant = MTypographyVariant.caption,
                                        )
                                        mTypography(
                                            text = "-",
                                            variant = MTypographyVariant.subtitle2,
                                            color = MTypographyColor.textPrimary
                                        )
                                    }
                                }
                            } else {
                                mListItem {
                                    attrs.button = false
                                    attrs.divider = true

                                    mListItemText {
                                        mTypography(
                                            text = "Fahrzeugprofil",
                                            variant = MTypographyVariant.caption,
                                        )
                                        styledRouteLink("/vehicleprofiles/manage/${device.vehicleProfile!!.id}/view") {
                                            css { textDecoration = TextDecoration.none }
                                            mTypography(
                                                text = device.vehicleProfile!!.name,
                                                variant = MTypographyVariant.subtitle2,
                                                color = MTypographyColor.secondary
                                            )
                                        }
                                    }
                                }
                            }
                        }
                        withFeature(Features.VehicleProfileFeatures.fmsProfiles) {
                            if (device.vehicleProfile == null) {
                                mListItem {
                                    attrs.button = false
                                    attrs.divider = true

                                    mListItemText {
                                        mTypography(
                                            text = "FMS Profil",
                                            variant = MTypographyVariant.caption,
                                        )
                                        mTypography(
                                            text = "-",
                                            variant = MTypographyVariant.subtitle2,
                                            color = MTypographyColor.textPrimary
                                        )
                                    }
                                }
                            } else {
                                mListItem {
                                    attrs.button = false
                                    attrs.divider = true

                                    mListItemText {
                                        mTypography(
                                            text = "FMS Profil",
                                            variant = MTypographyVariant.caption,
                                        )
                                        styledRouteLink("/vehicleprofiles/fms/${device.fmsProfile?.id}") {
                                            css { textDecoration = TextDecoration.none }
                                            mTypography(
                                                text = device.fmsProfile?.name,
                                                color = MTypographyColor.secondary,
                                                align = MTypographyAlign.left,
                                                variant = MTypographyVariant.subtitle2
                                            )
                                        }
                                    }
                                }
                            }
                        }
                        withFeature(Features.DeviceFeatures.busAccess) {
                            if (device.ibisProfile == null) {
                                mListItem {
                                    attrs.button = false
                                    attrs.divider = true

                                    mListItemText {
                                        mTypography(
                                            text = "IBIS Profil",
                                            variant = MTypographyVariant.caption,
                                        )
                                        mTypography(
                                            text = "-",
                                            variant = MTypographyVariant.subtitle2,
                                            color = MTypographyColor.textPrimary
                                        )
                                    }
                                }
                            } else {
                                mListItem {
                                    attrs.button = false
                                    attrs.divider = true

                                    mListItemText {
                                        mTypography(
                                            text = "IBIS Profil",
                                            variant = MTypographyVariant.caption,
                                        )
                                        styledRouteLink("/vehicleprofiles/ibis/${device.ibisProfile?.profileId}") {
                                            css { textDecoration = TextDecoration.none }
                                            mTypography(
                                                text = device.ibisProfile?.profileName,
                                                color = MTypographyColor.secondary,
                                                align = MTypographyAlign.left,
                                                variant = MTypographyVariant.subtitle2
                                            )
                                        }
                                    }
                                }
                            }
                        }
                        tableRowMultilineEditValue(
                            label = "Herstelldatum",
                            loadValue = { device.inceptionDate.toText() },
                            loadApplyPath = { "/device/${device.id}/inceptiondate" },
                            allowEdit = isAuthorized(Permissions.AdminPermissions.internalAccess)
                        )
                        mListItem {
                            attrs.button = false
                            attrs.divider = true

                            mListItemText {
                                mTypography(
                                    text = "Betriebssystem Installationsdatum",
                                    variant = MTypographyVariant.caption,
                                )
                                mTypography(
                                    text = device.installDate.toText(),
                                    color = MTypographyColor.initial,
                                    align = MTypographyAlign.left,
                                    variant = MTypographyVariant.subtitle2
                                )
                            }
                        }
                        tableRowMultilineEditValue(
                            label = "Lieferdatum",
                            loadValue = { device.shippingDate?.toText() },
                            loadApplyPath = { "/device/${device.id}/shippingdate" },
                            allowEdit = isAuthorized(Permissions.AdminPermissions.internalAccess)
                        )

                        tableRowMultilineEditValue(
                            label = "Beschreibung",
                            loadValue = { device.description?.takeIf { it.isNotBlank() } },
                            loadApplyPath = { "/device/${device.id}/description" },
                            allowEdit = isAuthorized(Permissions.DeviceManagement.vehicleDescriptionWrite)
                        )
                        mListItem {
                            attrs.button = false
                            attrs.divider = true

                            mListItemText {
                                mTypography(
                                    text = "Zuletzt gesehen",
                                    variant = MTypographyVariant.caption,
                                )
                                styledDiv {
                                    timestampStatus(device.lastSeen)
                                }
                            }
                        }

                        authorize(Permissions.AdminPermissions.internalAccess) {
                            if (device.betaLastSeen.timestamp != null) {
                                mListItem {
                                    attrs.button = false
                                    attrs.divider = true

                                    mListItemText {
                                        mTypography(
                                            text = "Zuletzt gesehen (beta)",
                                            variant = MTypographyVariant.caption,
                                        )
                                        styledDiv {
                                            timestampStatus(device.betaLastSeen)
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    private fun getAllVehicleTypes(): List<VehicleType> = VehicleType.values().toList()
}