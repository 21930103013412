package de.geomobile.frontend.features.device.detail.selftest

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonSize
import com.ccfraser.muirwik.components.list.mList
import com.ccfraser.muirwik.components.list.mListItem
import com.ccfraser.muirwik.components.list.mListItemIcon
import com.ccfraser.muirwik.components.list.mListItemText
import kotlinx.css.*
import react.RBuilder
import react.RComponent
import react.RProps
import react.RState
import styled.css

fun RBuilder.deviceSelfTestResult(
    selfTestResult: SelfTestResult?,
) = child(DeviceSelfTestResultComponent::class) {
    attrs.selfTestResult = selfTestResult
}

class DeviceSelfTestResultComponent : RComponent<DeviceSelfTestResultComponent.Props, RState>() {

    interface Props : RProps {
        var selfTestResult: SelfTestResult?
    }

    override fun RBuilder.render() {
        val selfTestResult = props.selfTestResult

        if (selfTestResult == null) {
            mTypography(
                text = "Keine Daten verfügbar",
                variant = MTypographyVariant.body1,
                align = MTypographyAlign.center
            ) { css { padding(2.spacingUnits) } }
        } else {
            mList {
                attrs.dense = true
                for (result in selfTestResult.moduleSelftestResults) {
                    for (testResult in result.testResults) {
                        mListItem {
                            attrs.button = false
                            attrs.divider = true

                            mListItemIcon {
                                attrs.disabled = true
                                attrs.disableRipple = true
                                mIconButtonNoTranslate(
                                    iconName = if (testResult.result) "check" else "clear",
                                    iconColor = MIconColor.inherit,
                                    addAsChild = true,
                                    size = MButtonSize.medium
                                ) {
                                    css {
                                        marginTop = 1.spacingUnits
                                        backgroundColor = if (testResult.result) Color.green else Color.red
                                        color = Color.white
                                    }
                                }
                            }
                            mListItemText {
                                css { padding(0.spacingUnits, 1.spacingUnits) }
                                mTypography(
                                    text = result.slotModule.name + " (" + result.slotModule.slot + ")",
                                    variant = MTypographyVariant.caption,
                                )
                                mTypography(
                                    text = testResult.description,
                                    variant = MTypographyVariant.subtitle2
                                )
                                mTypography(
                                    text = testResult.resultText,
                                    variant = MTypographyVariant.caption
                                ) {
                                    css { wordBreak = WordBreak.breakAll }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}