package de.geomobile.frontend.features.softwareManagement.bundle

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonSize
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.list.mList
import com.ccfraser.muirwik.components.list.mListItem
import com.ccfraser.muirwik.components.list.mListSubheader
import com.ccfraser.muirwik.components.styles.Breakpoint
import components.emptyView
import de.geomobile.common.portalmodels.Company
import de.geomobile.common.portalmodels.DeviceIdentifier
import de.geomobile.common.portalmodels.Product
import de.geomobile.common.softwaremgmt.SoftwareAssignment
import de.geomobile.common.softwaremgmt.SoftwareAssignmentFilter
import de.geomobile.common.softwaremgmt.SoftwareBundle
import de.geomobile.common.utils.Tree
import de.geomobile.common.utils.emptyTree
import de.geomobile.common.utils.tree
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.utils.*
import kotlinx.coroutines.*
import kotlinx.css.*
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.builtins.MapSerializer
import kotlinx.serialization.builtins.serializer
import react.*
import react.router.dom.route
import react.router.dom.switch
import styled.css
import styled.styledDiv

fun RBuilder.bundleList(
    company: Company,
    selectedBundleVersion: String?,
    product: Product,
    changeSelection: (selectedBundleVersion: String?, addingNew: Boolean) -> Unit,
) = child(BundleList::class) {
    attrs.company = company
    attrs.selectedBundleVersion = selectedBundleVersion
    attrs.changeSelection = changeSelection
    attrs.key = company.id
    attrs.product = product
}

interface SelectedBundleVersionProps : RProps {
    var selectedVersion: String?
}

class BundleList : CComponent<BundleList.Props, BundleList.State>() {

    private var loadSoftwareJob: Job = Job()

    interface Props : RProps {
        var company: Company
        var selectedBundleVersion: String?
        var changeSelection: (selectedBundleVersion: String?, addingNew: Boolean) -> Unit
        var product: Product
    }

    data class State(
        var newBundle: List<SoftwareBundle.BundledSoftware>? = null,
        var deletedSoftwareIds: List<String>? = null,
        var bundles: List<SoftwareBundle>? = null,
        var addingSoftware: Boolean = false,
        var assignments: Tree<SoftwareAssignmentFilter> = emptyTree(),
        var assignmentOverwrites: Map<DeviceIdentifier.Id, SoftwareAssignment> = emptyMap(),
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        loadBundles()
    }

    private fun loadBundles() {
        loadSoftwareJob.cancel()
        loadSoftwareJob = launch {
            val deferredAssignments = async(Dispatchers.Default) {
                portalRestApi.get(
                    "/software/assignment/get",
                    SoftwareAssignmentFilter.serializer().tree,
                    parameter = mapOf("product" to props.product.readableName)
                )
            }

            val deferredAssignmentOverwrites = async(Dispatchers.Default) {
                portalRestApi.get(
                    "/software/assignment/overwrite",
                    MapSerializer(Int.serializer(), SoftwareAssignment.serializer())
                )
            }

            val bundles = withContext(Dispatchers.Default) {
                portalRestApi.get(
                    "/software/bundle/${props.company.id}",
                    ListSerializer(SoftwareBundle.serializer()),
                    parameter = mapOf("product" to props.product.readableName)
                )
            }

            val assignments = deferredAssignments.await()
            val assignmentOverwrites = deferredAssignmentOverwrites.await().mapKeys { DeviceIdentifier.Id(it.key) }

            setState {
                this.assignments = assignments
                this.assignmentOverwrites = assignmentOverwrites
                this.bundles = bundles
            }
        }
    }

    override fun RBuilder.render() {
        val bundles = state.bundles ?: return

        val selected = props.selectedBundleVersion?.let { version ->
            bundles.singleOrNull { it.internalVersion == version }
        }

        mGridContainer2(
            direction = MGridDirection.row
        ) {
            bundleList(bundles, selected)

            mGridItem2(
                MGridBreakpoints2(MGridSize2.Cells8)
                    .down(Breakpoint.sm, MGridSize2.Cells12)
            ) {
                when {
                    props.selectedBundleVersion == "new" -> bundleEdit(
                        props.company.id,
                        initial = bundles.lastOrNull(),
                        createNew = true,
                        onSave = {
                            loadBundles()
                            props.changeSelection(selected?.internalVersion, false)
                        },
                        product = props.product
                    )

                    selected != null -> switch {
                        route("/software/${props.product.readableName}/bundles/${props.company.id}/${selected.internalVersion}/edit") {
                            bundleEdit(
                                companyId = props.company.id,
                                initial = selected,
                                createNew = false,
                                onSave = {
                                    loadBundles()
                                    props.changeSelection(selected.internalVersion, false)
                                },
                                product = props.product
                            )
                        }
                        route("/software/${props.product.readableName}/bundles/${props.company.id}/${selected.internalVersion}") {
                            bundleDetail(
                                softwareBundle = selected,
                                assignments = state.assignments.findNodes { it.assignment.bundle.id == selected.id }
                                    .map { it.value },
                                overwrites = state.assignmentOverwrites.filterValues { it.bundle.id == selected.id },
                                product = props.product
                            )
                        }
                    }

                    else -> {
                        mCard {
                            css(GlobalStyles.card)
                            mCardContent {
                                css(GlobalStyles.cardListContent)
                                emptyView(
                                    boxShadow = false,
                                    title = "Bundles",
                                    caption = "Es wurde kein Bundle ausgewählt",
                                    addButton = false
                                )
                            }
                        }
                    }
                }
            }
        }
    }

    private fun RBuilder.bundleList(
        bundles: List<SoftwareBundle>,
        selected: SoftwareBundle?,
    ) {
        mGridItem2(
            MGridBreakpoints2(MGridSize2.Cells4)
                .down(Breakpoint.sm, MGridSize2.Cells12)
        ) {
            mCard {
                css(GlobalStyles.card)
                mCardContent {
                    css(GlobalStyles.cardListContent)

                    if (state.bundles?.isEmpty() == true) {
                        emptyView(
                            iconName = "menu",
                            title = "Bundles",
                            caption = "Es wurden keine Bundles gefunden",
                            addButton = true,
                            actionBtnCaption = "Erstellen"
                        ) { props.changeSelection(null, true) }
                    } else {
                        mCard {
                            css(GlobalStyles.card)
                            mCardContent {
                                css(GlobalStyles.cardListContent)
                                styledDiv {
                                    css {
                                        display = Display.flex
                                        justifyContent = JustifyContent.spaceBetween
                                        alignItems = Align.center
                                    }
                                    mListSubheader(heading = "Bundles")
                                    mTooltip(title = "Bundle erstellen") {
                                        mButton(
                                            caption = "Neu",
                                            size = MButtonSize.small,
                                            color = MColor.secondary,
                                            variant = MButtonVariant.outlined,
                                            onClick = { props.changeSelection(null, true) }
                                        ) {
                                            css { margin(0.spacingUnits, 1.spacingUnits) }
                                        }
                                    }
                                }
                                mDivider { }
                                mList {
                                    for (bundle in bundles.asReversed()) {
                                        mListItem(
                                            primaryText = "${bundle.internalVersion} (${bundle.externalVersion ?: bundle.internalVersion})",
                                            divider = false,
                                            selected = bundle.internalVersion == selected?.internalVersion,
                                            onClick = { props.changeSelection(bundle.internalVersion, false) }
                                        ) {
                                            attrs.dense = true
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}