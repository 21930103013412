package de.geomobile.common.feature

import kotlinx.serialization.Serializable


@Serializable
data class Feature(
    val id: String,
    val name: String,
    val testVersion: Boolean = false
)

open class FeatureGroup(
    val id: String,
    val name: String
) {
    private var _features = mutableSetOf<Feature>()
    val features: Set<Feature> = _features

    fun feature(id: String, name: String): Feature {
        val feature = Feature(
            id = "${this.id}__$id",
            name = name,
            testVersion = false
        )
        _features.add(feature)
        return feature
    }
}