package de.geomobile.frontend.utils

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonSize
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.dialog.mDialog
import com.ccfraser.muirwik.components.dialog.mDialogActions
import com.ccfraser.muirwik.components.dialog.mDialogContent
import com.ccfraser.muirwik.components.dialog.mDialogTitle
import com.ccfraser.muirwik.components.list.mList
import com.ccfraser.muirwik.components.list.mListItem
import com.ccfraser.muirwik.components.list.mListItemText
import com.ccfraser.muirwik.components.list.mListSubheader
import com.ccfraser.muirwik.components.menu.mMenu
import com.ccfraser.muirwik.components.menu.mMenuItem
import com.ccfraser.muirwik.components.transitions.mCollapse
import de.geomobile.common.utils.Tree
import kotlinx.css.*
import org.w3c.dom.Node
import react.*
import styled.css

data class TreeEntry(
    val key: String,
    val name: String,
    val selected: Boolean,
    val onClick: () -> Unit,
    val expandedByDefault: Boolean = false,
    val new: Boolean,
    val color: Color? = null,
    val warning: Boolean = false,
    val alert: Boolean = false,
)

fun RBuilder.tree(
    heading: String?,
    tree: Tree<TreeEntry>,
) = child(TreeComponent::class) {
    attrs.heading = heading
    attrs.tree = tree
}

class TreeComponent(props: Props) : RComponent<TreeComponent.Props, RState>(props) {

    interface Props : RProps {
        var heading: String?
        var tree: Tree<TreeEntry>
    }

    override fun RBuilder.render() {
        if (props.heading != null) {
            mListSubheader(heading = props.heading!!)
            mDivider { }
        }
        mList {
            for (rootNode in props.tree.rootNodes) {
                treeNode(rootNode)
            }
        }
    }
}

fun RBuilder.treeNode(
    node: Tree.Node<TreeEntry>,
) = child(TreeNodeComponent::class) {
    attrs.node = node
    attrs.key = node.value.key
}

class TreeNodeComponent(props: Props) : RComponent<TreeNodeComponent.Props, TreeNodeComponent.State>(props) {

    interface Props : RProps {
        var node: Tree.Node<TreeEntry>
    }

    class State(
        var expanded: Boolean,
    ) : RState

    init {
        state = State(expanded = props.node.value.expandedByDefault || props.node.subTree.any { it.selected })
    }

    override fun componentWillReceiveProps(nextProps: Props) {
        if (nextProps.node.subTree.any { it.selected }) {
            setState { expanded = true }
        }
    }

    override fun RBuilder.render() {
        val value = props.node.value
        val expanded = state.expanded

        mListItem(
            key = value.key,
            divider = false,
            selected = value.selected,
            button = true,
            dense = true,
            onClick = { value.onClick() }
        ) {
            if (props.node.children.any())
                mIconButtonNoTranslate(
                    iconName = if (expanded) "expand_less" else "expand_more",
                    size = MButtonSize.small,
                    onClick = {
                        setState { this.expanded = !expanded }
                        if (expanded) value.onClick()
                        it.stopPropagation()
                    }
                ) {
                    css {
                        padding(0.spacingUnits)
                        marginRight = 1.spacingUnits
                        //visibility = if (props.node.children.any()) Visibility.visible else Visibility.hidden
                    }
                }

            mListItemText(value.name) {
                if (value.color != null)
                    css {
                        children {
                            color = value.color
                        }
                    }
                if (value.new)
                    css {
                        children {
                            fontStyle = FontStyle.italic
                        }
                    }

            }
            if (value.alert || value.warning)
                mIconNoTranslate("warning") {
                    css {
                        borderRadius = 3.px
                        color = when {
                            value.alert -> Color.red
                            value.warning -> Color.yellow
                            else -> Color.black
                        }
                        textAlign = TextAlign.center
                    }
                }
        }

        if (props.node.children.any())
            mCollapse(show = expanded) {
                css { paddingLeft = 2.spacingUnits }
                for (child in props.node.children) {
                    treeNode(child)
                }
            }
    }
}


fun RBuilder.treeOperations(
    tree: Tree<TreeEntry>,
    onMoveSelectedNode: ((Int) -> Unit)? = null,
    onAddTopLevel: () -> Unit,
    onAddSibling: (() -> Unit)? = null,
    onAddChild: (() -> Unit)? = null,
    onDelete: () -> Unit,
    onSelectNewParent: ((TreeEntry?) -> Unit)? = null,
) = child(TreeOperationsComponent::class) {
    attrs.tree = tree
    attrs.onMoveSelectedNode = onMoveSelectedNode
    attrs.onAddTopLevel = onAddTopLevel
    attrs.onAddSibling = onAddSibling
    attrs.onAddChild = onAddChild
    attrs.onDelete = onDelete
    attrs.onSelectNewParent = onSelectNewParent
}

class TreeOperationsComponent(props: Props) :
    RComponent<TreeOperationsComponent.Props, TreeOperationsComponent.State>(props) {

    interface Props : RProps {
        var tree: Tree<TreeEntry>
        var onMoveSelectedNode: ((Int) -> Unit)?
        var onAddTopLevel: () -> Unit
        var onAddSibling: (() -> Unit)?
        var onAddChild: (() -> Unit)?
        var onDelete: () -> Unit
        var onSelectNewParent: ((TreeEntry?) -> Unit)?
    }

    class State(
        var addMenuAnchor: Node? = null,
        var confirmDelete: Boolean = false,
        var selectParent: Boolean = false,
    ) : RState

    init {
        state = State()
    }

    override fun RBuilder.render() {

        val selected = props.tree.findNode { it.selected }
        val siblings = props.tree.findSiblings { it.selected }

        props.onSelectNewParent?.let {
            mIconButtonNoTranslate(
                iconName = "compare_arrows",
                color = MColor.inherit,
                size = MButtonSize.small,
                disabled = selected == null,
                onClick = { setState { selectParent = true } }
            )
        }

        props.onMoveSelectedNode?.let {
            mIconButtonNoTranslate(
                iconName = "keyboard_arrow_up",
                color = MColor.inherit,
                size = MButtonSize.small,
                disabled = selected == null || siblings?.first()?.selected == true,
                onClick = {
                    it(-1)
                }
            )
            mIconButtonNoTranslate(
                iconName = "keyboard_arrow_down",
                color = MColor.inherit,
                size = MButtonSize.small,
                disabled = selected == null || siblings?.last()?.selected == true,
                onClick = {
                    it(+1)
                }
            )
        }
        mIconButtonNoTranslate(
            iconName = "add",
            color = MColor.inherit,
            size = MButtonSize.small,
            disabled = selected?.value?.new == true,
            onClick = {
                val anchor = it.currentTarget.asDynamic()
                setState {
                    if(props.onAddChild == null && props.onAddSibling == null)
                        props.onAddTopLevel()
                    else
                        addMenuAnchor = anchor
                }
            }
        )
        mIconButtonNoTranslate(
            iconName = "remove",
            color = MColor.inherit,
            size = MButtonSize.small,
            disabled = selected == null || selected?.value?.new == true,
            onClick = { setState { confirmDelete = true } }
        )

        mMenu(
            open = state.addMenuAnchor != null,
            anchorElement = state.addMenuAnchor,
            onClose = { _, _ -> setState { addMenuAnchor = null } }) {
            mMenuItem(
                primaryText = "add top level",
                onClick = {
                    props.onAddTopLevel()
                    setState { addMenuAnchor = null }
                }
            )
            if (selected != null) {
                props.onAddSibling?.let {
                    mMenuItem(
                        primaryText = "add sibling",
                        onClick = {
                            it()
                            setState { addMenuAnchor = null }
                        }
                    )
                }
                props.onAddChild?.let {
                    mMenuItem(
                        primaryText = "add child",
                        onClick = {
                            it()
                            setState { addMenuAnchor = null }
                        }
                    )
                }
            }
        }

        mDialog(
            open = state.confirmDelete,
            onClose = { _, _ ->
                setState { confirmDelete = false }
            }
        ) {
            mDialogContent {
                mTypography("Möchten Sie diesen Eintrag und alle Untereinträge löschen?")
            }
            mDialogActions {
                mButton(
                    caption = "Abbrechen",
                    onClick = {
                        setState { confirmDelete = false }
                    }
                )
                mButton(
                    caption = "Löschen",
                    onClick = {
                        props.onDelete()
                        setState { confirmDelete = false }
                    }
                ) {
                    css { color = Color.red }
                }
            }
        }

        mDialog(
            open = state.selectParent,
            onClose = { _, _ -> setState { selectParent = false } }
        ) {
            mDialogTitle(text = "Select new parent")
            mDialogContent {
                mListItem(
                    primaryText = "TOP LEVEL",
                    onClick = {
                        props.onSelectNewParent?.let { it(null) }
                        setState { selectParent = false }
                    }
                )
                tree(
                    heading = null,
                    props.tree
                        .map { _, selected ->
                            if (selected.children.any { it.value.selected })
                                selected.value.copy(name = selected.value.name + " (current)")
                            else
                                selected.value
                        }
                        .filter { !it.selected }
                        .mapValues { entry ->
                            entry.copy(
                                expandedByDefault = true,
                                onClick = {
                                    props.onSelectNewParent?.let { it(entry) }
                                    setState { selectParent = false }
                                }
                            )
                        }
                )
            }
            mDialogActions {
                mButton(
                    caption = "Abbrechen",
                    onClick = { setState { selectParent = false } }
                )
            }
        }
    }
}