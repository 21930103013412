package portalmodels

import de.geomobile.common.time.LocalDateTime
import kotlinx.serialization.Serializable


@Serializable
data class DeviceFmsDTO(
    val data: List<FmsDataDTO> = emptyList()
)

@Serializable
data class FmsDataDTO(
    val title: String,
    val pgnId: String,
    val msg: List<FmsMessage>,
    val timestamp: LocalDateTime?
)

@Serializable
data class FmsMessage(
        val entryName: String,
        val entryValue: String
)
