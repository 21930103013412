package de.geomobile.common.portalmodels

import kotlinx.serialization.Serializable

@Serializable
data class SoftwareStatusDTO(
    val lastInstalled: String? = null,
    val assigned: String? = null,
    val updateProgress: Int = -1,
    val updateStatus: String? = null,
    val lastPoll: TimestampStatus = TimestampStatus(),
    val legacyHawkbit: Boolean = false
)