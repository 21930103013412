package de.geomobile.frontend.features.device.detail

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardActionArea
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.table.*
import com.ccfraser.muirwik.components.transitions.mCollapse
import de.geomobile.common.feature.Features
import de.geomobile.common.portalmodels.IbisSniffer
import de.geomobile.frontend.device.detail.trialRibbon
import de.geomobile.frontend.portalWebSocketApi
import de.geomobile.frontend.utils.isTrial
import de.geomobile.frontend.utils.mCardHeaderExtended
import de.geomobile.frontend.utils.mIconNoTranslate
import de.geomobile.frontend.utils.timestampStatus
import kotlinx.css.*
import kotlinx.serialization.json.Json
import react.*
import styled.css
import styled.styledDiv
import styled.styledTd

fun RBuilder.ibisSniffer(
    id: Int,
) = child(DeviceSnifferComponent::class) {
    key = id.toString()
    attrs.id = id
}

class DeviceSnifferComponent :
    RComponent<DeviceSnifferComponent.Props, DeviceSnifferComponent.State>() {

    private val ibisSnifferSocket by kotlin.lazy {
        portalWebSocketApi.subscribe("/device/ivantoWireless/ibis/devices", mapOf("id" to props.id.toString()))
    }

    interface Props : RProps {
        var id: Int
        var ibisSnifferExpanded: Boolean
        var onIbisSnifferExpanded: () -> Unit
    }

    class State(
        var ibisSniffer: IbisSniffer = IbisSniffer(),
    ) : RState

    init {
        state = State()
    }

    data class Row(
        val title: String,
        val telegram: List<IbisSniffer.ToggleState>?,
    )

    override fun componentDidMount() {
        ibisSnifferSocket.connect {
            onmessage = {
                val ibisSniffer = Json.decodeFromString(IbisSniffer.serializer(), it)
                setState {
                    this.ibisSniffer = ibisSniffer
                }
            }
        }
    }

    override fun componentWillUnmount() {
        ibisSnifferSocket.close()
    }

    override fun RBuilder.render() {
        val ibisSniffer = state.ibisSniffer
        val rows = listOfNotNull(
            Row("Ansagegerät", ibisSniffer.stop_ann),
            Row("Anzeigen", ibisSniffer.display),
            Row("Entwerter", ibisSniffer.validator),
            Row("Fahrgasterf.", ibisSniffer.counter),
            Row("EKS", ibisSniffer.eks),
            Row("LSA", ibisSniffer.lsa)
        )

        ibisSniffer.status?.let { status ->
            styledDiv {
                css.paddingLeft = 2.spacingUnits
                timestampStatus(status)
            }
        }
        styledDiv {
            css { padding(2.spacingUnits) }
            mTableContainer {
                mTable {
                    mTableHead {
                        mTableRow {
                            mTableCell(
                                variant = MTableCellVariant.head,
                                padding = MTableCellPadding.none,
                            ) { }
                            repeat(15 + 1) { index ->
                                mTableCell(
                                    variant = MTableCellVariant.head,
                                    padding = MTableCellPadding.none,
                                    align = MTableCellAlign.center
                                ) {
                                    css { padding(1.spacingUnits) }
                                    mTypography(
                                        text = index.toString(),
                                        variant = MTypographyVariant.caption
                                    )
                                }
                            }
                        }
                    }
                    mTableBody {
                        for (row in rows) {
                            mTableRow {
                                styledTd {
                                    css { padding(0.spacingUnits, 1.spacingUnits) }
                                    mTypography(
                                        text = row.title,
                                        variant = MTypographyVariant.caption
                                    )
                                }
                                for (i in 0..15) {
                                    styledTd {
                                        css { textAlign = TextAlign.center }
                                        if (row.telegram != null)
                                            when (row.telegram[i]) {
                                                IbisSniffer.ToggleState.ON -> mIconNoTranslate("check") {
                                                    css { color = Color.darkGreen }
                                                }

                                                IbisSniffer.ToggleState.OFF -> mIconNoTranslate("clear") {
                                                    css { color = Color.darkRed }
                                                }

                                                IbisSniffer.ToggleState.NA -> mIconNoTranslate("remove") {
                                                    css { color = Color.grey }
                                                }
                                            }
                                        else mIconNoTranslate("remove") {
                                            css { color = Color.grey }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}