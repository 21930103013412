package portalmodels

import kotlinx.serialization.Serializable

@Serializable
data class IbisProfileDTO(
    val profileId: Int,
    val profileName: String,
    val companyId: String,
    val customOptions: String?,
)

@Serializable
data class IbisProfileEntryDTO(
    val profileId: Int,
    val entryId: Int,
    val telegramType: String,
    val isAppMapping: Boolean,
    val isPortalMapping: Boolean,
    val ibisFunction: IbisFunction,
    val filter: List<IbisTelegramFilterDTO>
)





