package de.geomobile.frontend.features.admin.companies

import de.geomobile.common.permission.Permissions
import de.geomobile.frontend.utils.authorize
import react.RBuilder
import react.RComponent
import react.RProps
import react.RState
import react.router.dom.route
import react.router.dom.switch

fun RBuilder.adminCompanies(path: String) = child(AdminCompanies::class) {
    attrs.path = path
}

class AdminCompanies : RComponent<AdminCompanies.Props, RState>() {

    interface Props : RProps {
        var path: String
    }

    override fun RBuilder.render() {

        switch {

            route<RProps>(props.path, exact = true) { routeProps ->
                adminCompanyList(
                    companySelected = { routeProps.history.push("${props.path}/$it") },
                    createCompany = { routeProps.history.push("${props.path}/create") }
                )
            }

            authorize(Permissions.CompanyManagement.companiesEdit) {

                route<RProps>("${props.path}/create", exact = true) { routeProps ->
                    adminCompanyCreate(
                        cancel = { routeProps.history.push(props.path) },
                        created = { routeProps.history.push(props.path) }
                    )
                }

                route<AdminCompanyDetailEdit.Props>("${props.path}/:companyId", exact = true) { routeProps ->
                    val companyId = routeProps.match.params.companyId!!
                    adminCompanyEdit(
                        companyId,
                        goBack = { routeProps.history.push(props.path) }
                    )
                }
            }
        }
    }
}