package de.geomobile.frontend

import com.ccfraser.muirwik.components.MColor
import com.ccfraser.muirwik.components.MHiddenImplementation
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.dialog.mDialog
import com.ccfraser.muirwik.components.dialog.mDialogActions
import com.ccfraser.muirwik.components.mHidden
import com.ccfraser.muirwik.components.mIconButtonNoTranslate
import com.ccfraser.muirwik.components.styles.Breakpoint
import de.geomobile.common.permission.Permissions
import de.geomobile.common.portalmodels.Product
import de.geomobile.common.portalmodels.UserDTO
import de.geomobile.frontend.api.ApiErrorInterceptor
import de.geomobile.frontend.auth.AuthenticationProviderInterceptor
import de.geomobile.frontend.features.admin.adminDashboard
import de.geomobile.frontend.features.companyProfile.companyProfileDashboard
import de.geomobile.frontend.features.config.SelectedDeviceConfigProps
import de.geomobile.frontend.features.config.deviceConfig
import de.geomobile.frontend.features.dashboard.dashboard
import de.geomobile.frontend.features.debug.debugSettings
import de.geomobile.frontend.features.device.devices
import de.geomobile.frontend.features.device.toDetailPath
import de.geomobile.frontend.features.documentation.documentation
import de.geomobile.frontend.features.map.devicesMap
import de.geomobile.frontend.features.monitoring.monitoringDashboard
import de.geomobile.frontend.features.portalSettings.portalSettingsDashboard
import de.geomobile.frontend.features.print.printJobList
import de.geomobile.frontend.features.repair.repairDashboard
import de.geomobile.frontend.features.softwareManagement.queue.updateQueue
import de.geomobile.frontend.features.softwareManagement.softwareDashboard
import de.geomobile.frontend.features.statistics.accesspoint.statisticsAccessPointDashboard
import de.geomobile.frontend.features.tts.textToSpeechDashboard
import de.geomobile.frontend.features.upload.upload
import de.geomobile.frontend.features.userSettings.userSettingsDashboard
import de.geomobile.frontend.features.vehicleProfile.vehicleProfilesDashboard
import de.geomobile.frontend.utils.CComponent
import de.geomobile.frontend.utils.authorize
import kotlinx.browser.localStorage
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.Display
import kotlinx.css.display
import kotlinx.css.pct
import kotlinx.css.width
import kotlinx.html.DIV
import react.*
import react.dom.key
import react.router.dom.redirect
import react.router.dom.route
import react.router.dom.switch
import styled.StyledDOMBuilder
import styled.css
import styled.styledDiv

fun RBuilder.loggedIn(
    user: UserDTO,
    onLogout: () -> Unit
) = child(LoggedIn::class) {
    attrs.user = user
    attrs.onLogout = onLogout
}

class LoggedIn : CComponent<LoggedIn.Props, LoggedIn.State>() {

    private var logoutJob: Job = Job()

    interface Props : RProps {
        var user: UserDTO
        var onLogout: () -> Unit
    }

    class State(
        var responsiveDrawerOpen: Boolean = false,
        var debugSettingsOpen: Boolean = false
    ) : RState

    init {
        state = State()
    }

    override fun RBuilder.render() {
        switch {
            route<RProps>("/") { request ->
                styledDiv {
                    attrs.key = props.user.hashCode().toString()
                    css {
                        display = Display.flex
                        width = 100.pct
                    }

                    drawer(
                        location = request.location,
                        responsiveDrawerOpen = state.responsiveDrawerOpen,
                        onClose = { setState { this.responsiveDrawerOpen = false } },
                        openDebugSettings = { setState { this.debugSettingsOpen = true } }
                    )

                    val drawerMenu = RBuilder().mHidden(
                        mdUp = true,
                        implementation = MHiddenImplementation.css
                    ) {
                        mIconButtonNoTranslate(
                            iconName = "menu",
                            color = MColor.inherit,
                            onClick = {
                                setState {
                                    this.responsiveDrawerOpen = !this.responsiveDrawerOpen
                                }
                            }
                        )
                    }

                    // Main content area
                    styledDiv {
                        css(GlobalStyles.content)
                        mainContent(drawerMenu)
                    }
                }
            }
        }

        authorize(Permissions.AdminPermissions.debugAccess) {
            mDialog(
                maxWidth = Breakpoint.lg,
                open = state.debugSettingsOpen,
                onClose = { _, _ -> setState { this.debugSettingsOpen = false } }
            ) {
                debugSettings()
                mDialogActions {
                    mButton(
                        caption = "Schließen",
                        onClick = {
                            setState { this.debugSettingsOpen = false }
                        }
                    )
                }
            }
        }
    }

    private fun StyledDOMBuilder<DIV>.mainContent(drawerMenu: ReactElement) {
        switch {
            route<RProps>("/tts") { props ->
                // TODO: Check for appropriate Permission
                textToSpeechDashboard(props.match.path, drawerMenu)
            }

            authorize(Permissions.StatisticsManagement.dashboard) {
                // TODO: Check back with Max for appropriate Permission
                route<RProps>("/dashboard") { props ->
                    dashboard(
                        path = props.match.path,
                        drawerMenu = drawerMenu
                    )
                }
            }

            route<RProps>("/devices") { props ->
                devices(props.location.pathname, props.match.path, drawerMenu)
            }

            authorize(Permissions.StatisticsManagement.statView) {
                route<RProps>("/statistics") { props ->
                    statisticsAccessPointDashboard(props.match.path, drawerMenu)
                }
            }

            authorize(Permissions.AdminPermissions.internalAccess) {
                route<SelectedDeviceConfigProps>("/config/:selectedId(-?\\d+)?") { props ->
                    deviceConfig(
                        selectedId = props.match.params.selectedId?.toIntOrNull(),
                        drawerMenu = drawerMenu,
                        changeSelection = { selectedId ->
                            var path = "/config"
                            if (selectedId != null) {
                                path += "/$selectedId"
                            }
                            if (path != props.location.pathname)
                                props.history.push(path)
                        },
                        onDeviceClick = { identifier ->
                            props.history.push("/devices${identifier.toDetailPath()}")
                        }
                    )
                }

                route<RProps>("/software/${Product.CONNECT.readableName}") { props ->
                    softwareDashboard(props.match.path, drawerMenu, product = Product.CONNECT)
                }

                redirect(from = "/software", to = "/software/${Product.CONNECT.readableName}", exact = true)

                route<RProps>("/software/${Product.INTERACT.readableName}") { props ->
                    softwareDashboard(props.match.path, drawerMenu, product = Product.INTERACT)
                }

                route<RProps>("/software/queue") { props ->
                    updateQueue(
                        drawerMenu = drawerMenu,
                        openDevice = { cpuId -> props.history.push("/devices/${cpuId.product.readableName}/cpuid/${cpuId.cpuId}") }
                    )
                }

                route("/print", exact = true) {
                    printJobList(drawerMenu)
                }
            }

            authorize(Permissions.MapManagement.mapView) {
                route<RProps>("/map") { props ->
                    devicesMap(props.match.path, drawerMenu)
                }
            }

            // TODO: Check back with Max for appropriate Permission
            route<RProps>("/upload") { props ->
                upload(props.location.pathname, props.match.path, drawerMenu)
            }

            route<RProps>("/documentation") { props ->
                documentation(props.location.pathname, props.match.path, drawerMenu)
            }

            route<RProps>("/features/monitoring") { props ->
                monitoringDashboard(path = props.match.path, drawerMenu)
            }

            authorize(Permissions.AdminPermissions.adminControlsView) {
                route<RProps>("/admin") { props ->
                    adminDashboard(
                        path = props.match.path,
                        drawerMenu = drawerMenu,
                        onDeviceClick = { identifier ->
                            props.history.push("/devices${identifier.toDetailPath()}")
                        })
                }
            }

            authorize(Permissions.VehicleProfileManagement.profilesView) {
                route<RProps>("/vehicleprofiles") { props ->
                    vehicleProfilesDashboard(
                        path = props.match.path,
                        drawerMenu = drawerMenu,
                        onDeviceClick = { identifier ->
                            props.history.push("/devices${identifier.toDetailPath()}")
                        }
                    )
                }
            }

            authorize(Permissions.CompanyProfileManagement.profileView) {
                route<RProps>("/companyprofile") { props ->
                    companyProfileDashboard(
                        path = props.match.path,
                        drawerMenu = drawerMenu
                    )
                }
            }

            authorize(Permissions.DeviceManagement.repairHistoryUser) {
                route<RProps>("/features/repair") { props ->
                    repairDashboard(
                        path = props.match.path,
                        drawerMenu = drawerMenu
                    )
                }
            }

            authorize(Permissions.AdminPermissions.internalAccess) {
                route<RProps>("/features/portal-settings") { props ->
                    portalSettingsDashboard(
                        path = props.match.path,
                        drawerMenu = drawerMenu
                    )
                }
            }

            route<RProps>("/features/user-settings") { props ->
                userSettingsDashboard(
                    path = props.match.path,
                    drawerMenu = drawerMenu,
                    logout = { logout() },
                    clearStorage = { clearStorage() },
                )
            }

            redirect(from = "/", to = "/devices", exact = true)
        }
    }

    private fun logout() {
        val oldToken = UserStore.realToken
        UserStore.clear()

        logoutJob.cancel()
        logoutJob = launch {
            withContext(Dispatchers.Default) {
                val logoutApi = portalRestApi.copy(
                    interceptors = listOf(
                        ApiErrorInterceptor,
                        AuthenticationProviderInterceptor { oldToken }
                    )
                )
                logoutApi.delete("/logout")
            }
            props.onLogout()
        }
    }

    /** Empty the localStorage and keep the user to prevent logout */
    private fun clearStorage() {

        var storedToken = localStorage.getItem("token")
        var storedUser = localStorage.getItem("user")

        localStorage.clear()

        if (storedToken != null) {
            localStorage.setItem("token", storedToken)
        }

        if (storedUser != null) {
            localStorage.setItem("user", storedUser)
        }
    }
}