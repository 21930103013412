package de.geomobile.frontend.features.statistics.accesspoint

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.form.MFormControlMargin
import com.ccfraser.muirwik.components.form.MFormControlVariant
import com.ccfraser.muirwik.components.form.mFormHelperText
import com.ccfraser.muirwik.components.list.mList
import com.ccfraser.muirwik.components.list.mListItem
import com.ccfraser.muirwik.components.list.mListSubheader
import com.ccfraser.muirwik.components.menu.mMenuItem
import com.ccfraser.muirwik.components.styles.Breakpoint
import components.emptyView
import de.geomobile.common.permission.Permissions
import de.geomobile.common.portalmodels.*
import de.geomobile.common.statistics.DateTreeElement
import de.geomobile.common.statistics.Days
import de.geomobile.common.statistics.Month
import de.geomobile.common.statistics.text
import de.geomobile.common.time.LocalDateTime
import de.geomobile.common.utils.Tree
import de.geomobile.common.utils.emptyTree
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.spacer
import de.geomobile.frontend.utils.*
import de.geomobile.frontend.utils.charts.Column
import de.geomobile.frontend.utils.charts.chart
import kotlinx.browser.localStorage
import kotlinx.coroutines.*
import kotlinx.css.*
import kotlinx.serialization.builtins.ListSerializer
import org.w3c.dom.get
import org.w3c.dom.set
import react.*
import react.dom.div
import styled.StyledElementBuilder
import styled.css
import styled.styledDiv
import kotlin.js.Date

fun RBuilder.statisticsAccessPointWIFI() = child(StatisticsAccessPointWIFI::class) {}

class StatisticsAccessPointWIFI :
    CComponent<StatisticsAccessPointWIFI.Props, StatisticsAccessPointWIFI.State>() {

    private var fetchAvailableTimespanJob: Job = Job()
    private var fetchDevicesJob: Job = Job()
    private var fetchVehicleProfilesJob: Job = Job()
    private var loadCompaniesJob: Job = Job()
    private var fetchDataJobs: MutableList<Job> = mutableListOf()

    private val MEGABYTE_DIVISOR = 1.0E6
    private val GIGABYTE_DIVISOR = 1.0E9

    enum class DataSetKey {
        TRAFFIC,
        TRAFFIC_TOTAL,
        TRAFFIC_RANKING,
        TRAFFIC_HOURLY_TOTAL,
        TRAFFIC_HOURLY_WEEKDAY,
        TRAFFIC_HOURLY_WEEKEND,
        USER,
        USER_TOTAL,
        USER_RANKING,
        USER_HOURLY_TOTAL,
        USER_HOURLY_WEEKDAY,
        USER_HOURLY_WEEKEND
    }

    interface Props : RProps {
        var path: String
        var drawerMenu: ReactElement
    }

    class State(
        var tree: Tree<DateTreeElement> = emptyTree(),
        var loadingDays: Boolean = true,
        var selectedId: DateTreeElement? = null,
        var myCompany: String? = null,
        var companies: List<Company>? = null,
        var vehicleProfiles: List<VehicleProfileDTO>? = null,
        var deviceList: List<DeviceDTO>? = null,
        var selectedVP: VehicleProfileDTO? = null,
        var selectedDevice: DeviceDTO? = null,
        var requestParameter: Map<String, String> = mapOf(),
        var filenameSuffix: String? = null,
        var chartData: MutableMap<DataSetKey, Array<Array<Any>>?> = mutableMapOf(),
        var rankingData: MutableMap<DataSetKey, List<Pair<String, Long>>?> = mutableMapOf(),
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        fetchCompanies()
    }

    private fun State.setTimespanNodes(total: Days) {
        val years = total.days
            .distinctBy { it.year() }
            .map {
                DateTreeElement(DateTreeElement.DateElementType.YEAR, it)
            }
        val months = total.days
            .distinctBy { "${it.year()}-${it.month()}" }
            .map {
                DateTreeElement(DateTreeElement.DateElementType.MONTH, it)
            }
        val days = total.days
            .map {
                DateTreeElement(DateTreeElement.DateElementType.DAY, it)
            }
        val dayNodes = days.map {
            Tree.Node(it)
        }
        val monthNodes = months.map {
            Tree.Node(
                value = it,
                children = dayNodes.filter { n ->
                    "${n.value.myDate.year()}-${n.value.myDate.month()}" == "${it.myDate.year()}-${it.myDate.month()}"
                }.toList()
            )
        }
        val yearNodes = years.map {
            Tree.Node(
                value = it,
                children = monthNodes.filter { n -> n.value.myDate.year() == it.myDate.year() }
            )
        }.reversed()

        tree = Tree(yearNodes)
    }

    private fun clearStates() {
        setState {
            chartData.clear()
            rankingData.clear()
        }
    }

    private fun changeSelection(id: DateTreeElement? = state.selectedId) {
        clearStates()
        setState {
            selectedId = id
        }

        id?.let {
            when (id.type) {
                DateTreeElement.DateElementType.YEAR -> {
                    fetchYearData(id)
                }

                DateTreeElement.DateElementType.MONTH -> {
                    fetchMonthData(id)
                }

                DateTreeElement.DateElementType.DAY -> {
                    fetchDayData(id)
                }
            }
        }
    }

    private fun getTitle(element: DateTreeElement?): String? {
        return element?.let {
            when (it.type) {
                DateTreeElement.DateElementType.YEAR -> it.myDate.year()
                DateTreeElement.DateElementType.MONTH -> it.myDate.monthAsName()
                DateTreeElement.DateElementType.DAY -> "${it.myDate.dayOfMonth()}. ${it.myDate.dayAsName().take(2)}"
            }
        }
    }

    private fun fetchCompanies() {
        loadCompaniesJob.cancel()
        loadCompaniesJob = launch {
            val deferredCompanies = if (isAuthorized(Permissions.CompanyProfileManagement.notRestrictedToCompany))
                async(Dispatchers.Default) {
                    portalRestApi.get("/admin/companies", ListSerializer(Company.serializer()))
                }
            else {
                null
            }

            val ownUser =
                async(Dispatchers.Default) {
                    portalRestApi.get("/user", UserDTO.serializer())
                }.await()

            val companies = deferredCompanies?.await()

            val localStorageCompanyId = try {
                localStorage["StatisticsCompany"]
            } catch (e: NoSuchElementException) {
                localStorage["StatisticsCompany"] = ownUser.company.id
                null
            }

            val company =
                if (localStorageCompanyId != null) {
                    companies?.first { it.id == localStorageCompanyId }?.small ?: ownUser.company.small
                } else {
                    localStorage["StatisticsCompany"] = ownUser.company.id
                    ownUser.company.small
                }

            setState {
                company?.id?.let {
                    this.myCompany = it
                }
                companies?.let {
                    this.companies = companies
                }
            }
            fetchAvailableTimespan()
            fetchDevices()
            fetchVehicleProfiles()
        }
    }

    private fun fetchDevices() {
        fetchDevicesJob.cancel()
        fetchDevicesJob = launch {
            val devices = withContext(Dispatchers.Default) {
                state.myCompany?.let {
                    portalRestApi.get("/admin/companies/${it}/devices", ListSerializer(DeviceDTO.serializer()))
                        .filter { l -> l.vehicleId.isNotEmpty() }
                        .sortedBy { l -> l.vehicleId }
                }
            }

            devices?.let {
                setState {
                    deviceList = devices
                }
            }
        }
    }

    private fun fetchVehicleProfiles() {
        fetchVehicleProfilesJob.cancel()
        fetchVehicleProfilesJob = launch {
            val vps = withContext(Dispatchers.Default) {
                state.myCompany?.let {
                    portalRestApi.get("/vehicleprofiles", ListSerializer(VehicleProfileDTO.serializer()))
                }
            }

            vps?.let {
                setState {
                    vehicleProfiles = vps.filter { it.company.id == state.myCompany }
                }
            }
        }
    }

    private fun fetchAvailableTimespan() {
        fetchAvailableTimespanJob.cancel()
        fetchAvailableTimespanJob = launch {
            val days = withContext(Dispatchers.Default) {
                state.myCompany?.let {
                    portalRestApi.get(
                        path = "/statistics/accesspoint/${it}/dayswithdata",
                        parameter = state.requestParameter,
                        serializer = Days.serializer()
                    )
                }
            }

            if (days != null && days.days.isNotEmpty())
                setState {
                    setTimespanNodes(days)
                }
            else
                setState {
                    loadingDays = false
                }
        }
    }

    private fun fetchYearData(id: DateTreeElement) {
        try {
            fetchDataJobs.forEach {
                it.cancel()
            }
            fetchDataJobs.clear()
            fetchDataJobs.add(
                launch {
                    val trafficByMonth = withContext(Dispatchers.Default) {
                        portalRestApi.getRaw(
                            path = "/statistics/accesspoint/${state.myCompany}/traffic/year/${id.myDate.year()}",
                            parameter = state.requestParameter
                        )
                            .ifEmpty { null }
                            ?.let { l ->
                                val template = mutableMapOf<String, Long>()
                                for (i in 1..12) {
                                    template["${id.myDate.year()}-${i.toString().padStart(2, '0')}"] = 0
                                }
                                l.lines().forEach {
                                    val split = it.split(";")
                                    template[split[0]] = split[1].toLong()
                                }
                                template.map {
                                    arrayOf(Month.parse(it.key)!!.text as Any, it.value / GIGABYTE_DIVISOR)
                                }
                                    .toTypedArray()
                            } ?: arrayOf()
                    }
                    setState { this.chartData[DataSetKey.TRAFFIC_TOTAL] = trafficByMonth }
                }
            )
            fetchDataJobs.add(
                launch {
                    val trafficRankingByMonth = withContext(Dispatchers.Default) {
                        portalRestApi.getRaw(
                            path = "/statistics/accesspoint/${state.myCompany}/traffic/ranking/year/${id.myDate.year()}",
                            parameter = state.requestParameter
                        )
                            .ifEmpty { null }
                            ?.let {
                                it.lines().map { line ->
                                    val split = line.split(';')
                                    val cpuid = split[0]
                                    val traffic = (split[1].toLong() / GIGABYTE_DIVISOR).toLong()
                                    cpuid to traffic
                                }.toList()
                            }
                    }
                    setState { this.rankingData[DataSetKey.TRAFFIC_RANKING] = trafficRankingByMonth }
                }
            )
            fetchDataJobs.add(
                launch {
                    val clientsPerMonth = withContext(Dispatchers.Default) {
                        portalRestApi.getRaw(
                            path = "/statistics/accesspoint/${state.myCompany}/users/year/${id.myDate.year()}",
                            parameter = state.requestParameter
                        )
                            .ifEmpty { null }
                            ?.let { l ->
                                val template = mutableMapOf<String, Long>()
                                for (i in 1..12) {
                                    template["${id.myDate.year()}-${i.toString().padStart(2, '0')}"] = 0
                                }
                                l.lines().forEach {
                                    val split = it.split(";")
                                    template[split[0]] = split[1].toLong()
                                }
                                template.map {
                                    arrayOf(Month.parse(it.key)!!.text as Any, it.value)
                                }
                                    .toTypedArray()
                            } ?: arrayOf()
                    }
                    setState { this.chartData[DataSetKey.USER_TOTAL] = clientsPerMonth }
                }
            )
            fetchDataJobs.add(
                launch {
                    val usersRankingByMonth = withContext(Dispatchers.Default) {
                        portalRestApi.getRaw(
                            path = "/statistics/accesspoint/${state.myCompany}/users/ranking/year/${id.myDate.year()}",
                            parameter = state.requestParameter
                        )
                            .ifEmpty { null }
                            ?.let {
                                it.lines().map { line ->
                                    val split = line.split(';')
                                    val cpuid = split[0]
                                    val traffic = split[1].toLong()
                                    cpuid to traffic
                                }.toList()
                            }
                    }
                    setState { this.rankingData[DataSetKey.USER_RANKING] = usersRankingByMonth }
                }
            )
            fetchDataJobs.add(
                launch {
                    val usersHourlyByMonthTotal = withContext(Dispatchers.Default) {
                        portalRestApi.getRaw(
                            path = "/statistics/accesspoint/${state.myCompany}/users/hourly/year/${id.myDate.year()}",
                            parameter = state.requestParameter
                        )
                            .ifEmpty { null }
                            .mapFromHourly()
                    }
                    setState { this.chartData[DataSetKey.USER_HOURLY_TOTAL] = usersHourlyByMonthTotal }
                }
            )
            fetchDataJobs.add(
                launch {
                    val usersHourlyByMonthWeekday = withContext(Dispatchers.Default) {
                        portalRestApi.getRaw(
                            path = "/statistics/accesspoint/${state.myCompany}/users/hourly/year/${id.myDate.year()}",
                            parameter = state.requestParameter + mapOf("weekend" to "false")
                        )
                            .ifEmpty { null }
                            .mapFromHourly()
                    }
                    setState { this.chartData[DataSetKey.USER_HOURLY_WEEKDAY] = usersHourlyByMonthWeekday }
                }
            )
            fetchDataJobs.add(
                launch {
                    val usersHourlyByMonthWeekend = withContext(Dispatchers.Default) {
                        portalRestApi.getRaw(
                            path = "/statistics/accesspoint/${state.myCompany}/users/hourly/year/${id.myDate.year()}",
                            parameter = state.requestParameter + mapOf("weekend" to "true")
                        )
                            .ifEmpty { null }
                            .mapFromHourly()
                    }
                    setState { this.chartData[DataSetKey.USER_HOURLY_WEEKEND] = usersHourlyByMonthWeekend }
                }
            )
            fetchDataJobs.add(
                launch {
                    val trafficHourlyByMonthTotal = withContext(Dispatchers.Default) {
                        portalRestApi.getRaw(
                            path = "/statistics/accesspoint/${state.myCompany}/traffic/hourly/year/${id.myDate.year()}",
                            parameter = state.requestParameter
                        )
                            .ifEmpty { null }
                            .mapFromHourly(MEGABYTE_DIVISOR)
                    }
                    setState { this.chartData[DataSetKey.TRAFFIC_HOURLY_TOTAL] = trafficHourlyByMonthTotal }
                }
            )
            fetchDataJobs.add(
                launch {
                    val trafficHourlyByMonthWeekday = withContext(Dispatchers.Default) {
                        portalRestApi.getRaw(
                            path = "/statistics/accesspoint/${state.myCompany}/traffic/hourly/year/${id.myDate.year()}",
                            parameter = state.requestParameter + mapOf("weekend" to "false")
                        )
                            .ifEmpty { null }
                            .mapFromHourly(MEGABYTE_DIVISOR)
                    }
                    setState { this.chartData[DataSetKey.TRAFFIC_HOURLY_WEEKDAY] = trafficHourlyByMonthWeekday }
                }
            )
            fetchDataJobs.add(
                launch {
                    val trafficHourlyByMonthWeekend = withContext(Dispatchers.Default) {
                        portalRestApi.getRaw(
                            path = "/statistics/accesspoint/${state.myCompany}/traffic/hourly/year/${id.myDate.year()}",
                            parameter = state.requestParameter + mapOf("weekend" to "true")
                        )
                            .ifEmpty { null }
                            .mapFromHourly(MEGABYTE_DIVISOR)
                    }
                    setState { this.chartData[DataSetKey.TRAFFIC_HOURLY_WEEKEND] = trafficHourlyByMonthWeekend }
                }
            )
        } catch (cex: CancellationException) {

        }
    }

    private fun fetchMonthData(id: DateTreeElement) {
        try {
            fetchDataJobs.forEach {
                it.cancel()
            }
            fetchDataJobs.clear()
            fetchDataJobs.add(
                launch {
                    val trafficPerDay = withContext(Dispatchers.Default) {
                        portalRestApi.getRaw(
                            path = "/statistics/accesspoint/${state.myCompany}/traffic/month/${id.myDate.year()}-${
                                id.myDate?.month().toString().padStart(2, '0')
                            }",
                            parameter = state.requestParameter
                        )
                            .ifEmpty { null }
                            ?.let { l ->
                                val template = mutableMapOf<Int, Long>()
                                for (i in 1..31) {
                                    template[i] = 0
                                }
                                l.lines().forEach {
                                    val split = it.split(";")
                                    val day = split[0].split("-")[2].toInt()
                                    val value = split[1].toLong()
                                    template[day] = value
                                }
                                template.map {
                                    arrayOf(it.key as Any, it.value / GIGABYTE_DIVISOR)
                                }.toTypedArray()
                            } ?: arrayOf()
                    }
                    setState { this.chartData[DataSetKey.TRAFFIC_TOTAL] = trafficPerDay }
                }
            )
            fetchDataJobs.add(
                launch {
                    val trafficRankingByDay = withContext(Dispatchers.Default) {
                        portalRestApi.getRaw(
                            path = "/statistics/accesspoint/${state.myCompany}/traffic/ranking/month/${id.myDate.year()}-${
                                id.myDate?.month().toString().padStart(2, '0')
                            }",
                            parameter = state.requestParameter
                        )
                            .ifEmpty { null }
                            ?.let {
                                it.lines().map { line ->
                                    val split = line.split(';')
                                    val cpuid = split[0]
                                    val traffic = (split[1].toLong() / GIGABYTE_DIVISOR).toLong()
                                    cpuid to traffic
                                }.toList()
                            }
                    }
                    setState { this.rankingData[DataSetKey.TRAFFIC_RANKING] = trafficRankingByDay }
                }
            )
            fetchDataJobs.add(
                launch {
                    val clientsPerDay = withContext(Dispatchers.Default) {
                        portalRestApi.getRaw(
                            path = "/statistics/accesspoint/${state.myCompany}/users/month/${id.myDate.year()}-${
                                id.myDate?.month().toString().padStart(2, '0')
                            }",
                            parameter = state.requestParameter
                        )
                            .ifEmpty { null }
                            ?.let { l ->
                                val template = listOf(1..31)
                                    .flatten()
                                    .associateWith { 0L }
                                    .toMutableMap()

                                l.lines()
                                    .forEach {
                                        val split = it.split(";")
                                        val day = split[0].split("-")[2].toInt()
                                        val value = split[1].toLong()
                                        template[day] = value
                                    }
                                template.map {
                                    arrayOf(it.key as Any, it.value as Any)
                                }.toTypedArray()
                            } ?: arrayOf()
                    }
                    setState { this.chartData[DataSetKey.USER_TOTAL] = clientsPerDay }
                }
            )
            fetchDataJobs.add(
                launch {
                    val usersRankingByDay = withContext(Dispatchers.Default) {
                        portalRestApi.getRaw(
                            path = "/statistics/accesspoint/${state.myCompany}/users/ranking/month/${id.myDate.year()}-${
                                id.myDate?.month().toString().padStart(2, '0')
                            }",
                            parameter = state.requestParameter
                        )
                            .ifEmpty { null }
                            ?.let {
                                it.lines().map { line ->
                                    val split = line.split(';')
                                    val cpuid = split[0]
                                    val traffic = split[1].toLong()
                                    cpuid to traffic
                                }.toList()
                            }
                    }
                    setState { this.rankingData[DataSetKey.USER_RANKING] = usersRankingByDay }
                }
            )
            fetchDataJobs.add(
                launch {
                    val usersHourlyByDayTotal = withContext(Dispatchers.Default) {
                        portalRestApi.getRaw(
                            path = "/statistics/accesspoint/${state.myCompany}/users/hourly/month/${id.myDate.year()}-${
                                id.myDate?.month().toString().padStart(2, '0')
                            }",
                            parameter = state.requestParameter
                        )
                            .ifEmpty { null }
                            .mapFromHourly()
                    }
                    setState { this.chartData[DataSetKey.USER_HOURLY_TOTAL] = usersHourlyByDayTotal }
                }
            )
            fetchDataJobs.add(
                launch {
                    val usersHourlyByDayWeekday = withContext(Dispatchers.Default) {
                        portalRestApi.getRaw(
                            path = "/statistics/accesspoint/${state.myCompany}/users/hourly/month/${id.myDate.year()}-${
                                id.myDate?.month().toString().padStart(2, '0')
                            }",
                            parameter = state.requestParameter + mapOf("weekend" to "false")
                        )
                            .ifEmpty { null }
                            .mapFromHourly()
                    }
                    setState { this.chartData[DataSetKey.USER_HOURLY_WEEKDAY] = usersHourlyByDayWeekday }
                }
            )
            fetchDataJobs.add(
                launch {
                    val usersHourlyByDayWeekend = withContext(Dispatchers.Default) {
                        portalRestApi.getRaw(
                            path = "/statistics/accesspoint/${state.myCompany}/users/hourly/month/${id.myDate.year()}-${
                                id.myDate?.month().toString().padStart(2, '0')
                            }",
                            parameter = state.requestParameter + mapOf("weekend" to "true")
                        )
                            .ifEmpty { null }
                            .mapFromHourly()
                    }
                    setState { this.chartData[DataSetKey.USER_HOURLY_WEEKEND] = usersHourlyByDayWeekend }
                }
            )
            fetchDataJobs.add(
                launch {
                    val trafficHourlyByDayTotal = withContext(Dispatchers.Default) {
                        portalRestApi.getRaw(
                            path = "/statistics/accesspoint/${state.myCompany}/traffic/hourly/month/${id.myDate.year()}-${
                                id.myDate?.month().toString().padStart(2, '0')
                            }",
                            parameter = state.requestParameter
                        )
                            .ifEmpty { null }
                            .mapFromHourly(MEGABYTE_DIVISOR)
                    }
                    setState { this.chartData[DataSetKey.TRAFFIC_HOURLY_TOTAL] = trafficHourlyByDayTotal }
                }
            )
            fetchDataJobs.add(
                launch {
                    val trafficHourlyByDayWeekday = withContext(Dispatchers.Default) {
                        portalRestApi.getRaw(
                            path = "/statistics/accesspoint/${state.myCompany}/traffic/hourly/month/${id.myDate.year()}-${
                                id.myDate?.month().toString().padStart(2, '0')
                            }",
                            parameter = state.requestParameter + mapOf("weekend" to "false")
                        )
                            .ifEmpty { null }
                            .mapFromHourly(MEGABYTE_DIVISOR)
                    }
                    setState { this.chartData[DataSetKey.TRAFFIC_HOURLY_WEEKDAY] = trafficHourlyByDayWeekday }
                }
            )
            fetchDataJobs.add(
                launch {
                    val trafficHourlyByDayWeekend = withContext(Dispatchers.Default) {
                        portalRestApi.getRaw(
                            path = "/statistics/accesspoint/${state.myCompany}/traffic/hourly/month/${id.myDate.year()}-${
                                id.myDate?.month().toString().padStart(2, '0')
                            }",
                            parameter = state.requestParameter + mapOf("weekend" to "true")
                        )
                            .ifEmpty { null }
                            .mapFromHourly(MEGABYTE_DIVISOR)
                    }
                    setState { this.chartData[DataSetKey.TRAFFIC_HOURLY_WEEKEND] = trafficHourlyByDayWeekend }
                }
            )
        } catch (cex: CancellationException) {
        }
    }

    private fun fetchDayData(id: DateTreeElement) {
        try {
            fetchDataJobs.forEach {
                it.cancel()
            }
            fetchDataJobs.clear()
            fetchDataJobs.add(
                launch {
                    val users = withContext(Dispatchers.Default) {

                        portalRestApi.getRaw(
                            path = "/statistics/accesspoint/${state.myCompany}/users/day/${id.myDate.year()}-${
                                id.myDate?.month().toString().padStart(2, '0')
                            }-${id.myDate.dayOfMonth().toString().padStart(2, '0')}",
                            parameter = state.requestParameter
                        )
                            .ifEmpty { null }
                            ?.let { l ->
                                val template = mutableMapOf<Date, Long>()
                                var start = LocalDateTime.parse(l.lines().first().split(";")[0])//.atStartOfDay()
                                val end = start.plusDays(1)
                                while (start < end) {
                                    template.put(start.jsDate, 0)
                                    start = start.plusMinutes(5)
                                }
                                l.lines().forEach {
                                    val split = it.split(";")
                                    val time = LocalDateTime.parse(split[0]).jsDate
                                    val value = split[1].toLong()
                                    template.keys.singleOrNull { s ->
                                        s.toString() == time.toString()
                                    }?.let { k ->
                                        template[k] = value
                                    }
                                }
                                template.map {
                                    arrayOf(it.key as Any, it.value)
                                }.toTypedArray()
                            } ?: arrayOf()
                    }
                    setState { this.chartData[DataSetKey.USER_TOTAL] = users }
                }
            )
            fetchDataJobs.add(
                launch {
                    val usersRanking = withContext(Dispatchers.Default) {
                        portalRestApi.getRaw(
                            path = "/statistics/accesspoint/${state.myCompany}/users/ranking/day/${id.myDate.year()}-${
                                id.myDate?.month().toString().padStart(2, '0')
                            }-${id.myDate.dayOfMonth().toString().padStart(2, '0')}",
                            parameter = state.requestParameter
                        )
                            .ifEmpty { null }
                            ?.let {
                                it.lines().map { line ->
                                    val split = line.split(';')
                                    val cpuid = split[0]
                                    val traffic = split[1].toLong()
                                    cpuid to traffic
                                }.toList()
                            }
                    }
                    setState { this.rankingData[DataSetKey.USER_RANKING] = usersRanking }
                }
            )
            fetchDataJobs.add(
                launch {
                    val trafficRanking = withContext(Dispatchers.Default) {
                        portalRestApi.getRaw(
                            path = "/statistics/accesspoint/${state.myCompany}/traffic/ranking/day/${id.myDate.year()}-${
                                id.myDate?.month().toString().padStart(2, '0')
                            }-${id.myDate.dayOfMonth().toString().padStart(2, '0')}",
                            parameter = state.requestParameter
                        )
                            .ifEmpty { null }
                            ?.let {
                                it.lines().map { line ->
                                    val split = line.split(';')
                                    val cpuid = split[0]
                                    val traffic = (split[1].toLong() / MEGABYTE_DIVISOR).toLong()
                                    cpuid to traffic
                                }.toList()
                            }
                    }
                    setState { this.rankingData[DataSetKey.TRAFFIC_RANKING] = trafficRanking }
                }
            )
            fetchDataJobs.add(
                launch {
                    val traffic = withContext(Dispatchers.Default) {
                        portalRestApi.getRaw(
                            path = "/statistics/accesspoint/${state.myCompany}/traffic/day/${id.myDate.year()}-${
                                id.myDate?.month().toString().padStart(2, '0')
                            }-${id.myDate.dayOfMonth().toString().padStart(2, '0')}",
                            parameter = state.requestParameter
                        )
                            .ifEmpty { null }
                            ?.let { l ->
                                val template = mutableMapOf<Date, Long>()
                                var start = LocalDateTime.parse(l.lines().first().split(";")[0])
                                val end = start.plusDays(1)
                                while (start < end) {
                                    template.put(start.jsDate, 0)
                                    start = start.plusMinutes(5)
                                }
                                l.lines().forEach {
                                    val split = it.split(";")
                                    val time = LocalDateTime.parse(split[0]).jsDate
                                    val value = split[1].toLong()
                                    template.keys.singleOrNull { s ->
                                        s.toString() == time.toString()
                                    }?.let { k ->
                                        template[k] = value
                                    }
                                }
                                template.map {
                                    arrayOf(it.key as Any, it.value / MEGABYTE_DIVISOR)
                                }.toTypedArray()
                            } ?: arrayOf()
                    }
                    setState { this.chartData[DataSetKey.TRAFFIC_TOTAL] = traffic }
                }
            )
        } catch (cex: CancellationException) {
        }
    }

    private fun State.generateFilterParameter() {
        if (selectedDevice == null && selectedVP == null) {
            requestParameter = mapOf()
            changeSelection()
            return
        }

        var entries: MutableMap<String, String> = mutableMapOf()

        selectedVP?.let { entries["vehicleprofileid"] = it.id }
        selectedDevice?.cpuId?.let { entries["devicecpuid"] = it }

        requestParameter = entries

        changeSelection()
    }

    private fun State.generateFilename() {
        if (selectedDevice == null && selectedVP == null) {
            filenameSuffix = null
            return
        }
        var str = ""
        selectedVP?.let { str += "_profil_${it.id}" } ?: ""
        selectedDevice?.let { str += "_devicecpuid_${it.cpuId}" } ?: ""
        filenameSuffix = str
    }

    override fun RBuilder.render() {
        authorize(Permissions.StatisticsManagement.wifiStatView) {
            spacer()
            mGridContainer2(direction = MGridDirection.row) {
                mGridItem2(
                    MGridBreakpoints2(MGridSize2.Cells3)
                        .down(Breakpoint.md, MGridSize2.Cells12)
                ) {
                    sideFilter()
                }
                mGridItem2(
                    MGridBreakpoints2(MGridSize2.Cells9)
                        .down(Breakpoint.md, MGridSize2.Cells12)
                ) {
                    detail()
                }
            }
        }
    }

    private fun StyledElementBuilder<MGridProps2>.sideFilter() {

        val tree: Tree<DateTreeElement?> = state.tree as Tree<DateTreeElement?>

        val entryTree = tree
            .mapByBranch { branch, (value) ->
                TreeEntry(
                    key = value?.myDate.toString(),
                    name = getTitle(value) ?: "Neuer Filter",
                    new = value == null,
                    selected = value == state.selectedId,
                    expandedByDefault = branch.size <= 0,
                    onClick = {
                        value?.let {
                            changeSelection(it)
                        }
                    }
                )
            }

        mGridContainer2(direction = MGridDirection.column) {
            mGridItem2 {
                if (state.loadingDays && tree.rootNodes.isEmpty()) {
                    mSkeleton(
                        height = 100.px,
                        animation = MSkeletonAnimation.wave,
                        variant = MSkeletonVariant.rect
                    )
                } else {
                    if (tree.rootNodes.isNotEmpty()) {
                        mCard {
                            css(GlobalStyles.card)
                            mCardContent {
                                css(GlobalStyles.cardListContent)
                                tree(heading = "Filter", tree = entryTree)
                            }
                        }
                    } else {
                        emptyView(
                            iconName = "menu",
                            title = "Filter",
                            caption = "Es wurden keine Filter gefunden",
                            addButton = false,
                        )
                    }
                }
            }
            state.selectedId?.let {
                mGridItem2 {
                    header()
                }
            }
        }
    }

    private fun RBuilder.detail() {
        mGridContainer2(direction = MGridDirection.row) {
            val vps = state.selectedVP?.id
            val dev = state.selectedDevice?.cpuId

            if (state.selectedId != null)
                mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                    mCard {
                        css(GlobalStyles.card)
                        mCardContent {
                            css(GlobalStyles.cardContent)
                            mListSubheader(heading = "Eigenschaften")
                            mDivider { }
                            mGridContainer2(direction = MGridDirection.row) {
                                mGridItem2(MGridBreakpoints2(MGridSize2.Cells6)) {
                                    styledDiv {
                                        css {
                                            padding(2.spacingUnits)
                                            paddingBottom = 0.spacingUnits
                                        }
                                        mSelect(
                                            variant = MFormControlVariant.outlined,
                                            value = vps ?: "0",
                                            name = "Fahrzeugprofil",
                                            id = "vehicleprofile",
                                            onChange = { event, _ ->
                                                var id = event.targetValue as String
                                                setState {
                                                    selectedVP = if (id != "0") {
                                                        selectedDevice = null
                                                        vehicleProfiles?.firstOrNull { it.id == id }
                                                    } else
                                                        null
                                                    generateFilename()
                                                    generateFilterParameter()
                                                }
                                            }
                                        ) {
                                            attrs.margin = MFormControlMargin.dense.toString()
                                            attrs.fullWidth = true
                                            mMenuItem(primaryText = "Ungefiltert", value = "0")
                                            state.vehicleProfiles?.let {
                                                for (mode in it) {
                                                    mMenuItem(primaryText = mode.name, value = mode.id)
                                                }
                                            }
                                        }
                                        mFormHelperText("Fahrzeugprofil")
                                    }
                                }
                                mGridItem2(MGridBreakpoints2(MGridSize2.Cells6)) {
                                    styledDiv {
                                        css {
                                            padding(2.spacingUnits)
                                            paddingBottom = 0.spacingUnits
                                        }
                                        mSelect(
                                            variant = MFormControlVariant.outlined,
                                            value = dev ?: "0",
                                            name = "Geräteliste",
                                            id = "devicelist",
                                            onChange = { event, _ ->
                                                val id = event.targetValue as String
                                                setState {
                                                    selectedDevice = if (id != "0") {
                                                        selectedVP = null
                                                        deviceList?.firstOrNull { it.cpuId == id }
                                                    } else
                                                        null
                                                    generateFilename()
                                                    generateFilterParameter()
                                                }
                                            }
                                        ) {
                                            attrs.margin = MFormControlMargin.dense.toString()
                                            attrs.fullWidth = true
                                            mMenuItem(primaryText = "Ungefiltert", value = "0")
                                            state.deviceList?.let {
                                                for (mode in it) {
                                                    mMenuItem(primaryText = mode.vehicleId, value = mode.cpuId)
                                                }
                                            }
                                        }
                                        mFormHelperText("Gerät")
                                    }
                                }
                            }
                        }
                    }
                }
            else {
                mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                    emptyView(
                        title = "Statistik",
                        caption = "Es wurde keine Statistik gefunden",
                        addButton = false
                    )
                }
            }
            mGridItem2(
                MGridBreakpoints2(MGridSize2.Cells6)
                    .down(Breakpoint.md, MGridSize2.Cells12)
            ) {
                totalContent(DataSetKey.TRAFFIC_TOTAL)
            }
            mGridItem2(
                MGridBreakpoints2(MGridSize2.Cells6)
                    .down(Breakpoint.md, MGridSize2.Cells12)
            ) {
                totalContent(DataSetKey.USER_TOTAL)
            }
            mGridItem2(
                MGridBreakpoints2(MGridSize2.Cells6)
                    .down(Breakpoint.md, MGridSize2.Cells12)
            ) {
                rankingContent(DataSetKey.TRAFFIC_RANKING)
            }
            mGridItem2(
                MGridBreakpoints2(MGridSize2.Cells6)
                    .down(Breakpoint.md, MGridSize2.Cells12)
            ) {
                rankingContent(DataSetKey.USER_RANKING)
            }
        }
        if (state.selectedId?.type ?: DateTreeElement.DateElementType.DAY != DateTreeElement.DateElementType.DAY) {
            div {
                mDivider { css { height = 2.spacingUnits; backgroundColor = Color.transparent } }
                mGridContainer2(direction = MGridDirection.row) {
                    mGridItem2(
                        MGridBreakpoints2(MGridSize2.Cells6)
                            .down(Breakpoint.md, MGridSize2.Cells12)
                    ) {
                        wholeDayTrafficChart()
                    }
                    mGridItem2(
                        MGridBreakpoints2(MGridSize2.Cells6)
                            .down(Breakpoint.md, MGridSize2.Cells12)
                    ) {
                        wholeDayUserChart()
                    }
                    mGridItem2(
                        MGridBreakpoints2(MGridSize2.Cells6)
                            .down(Breakpoint.md, MGridSize2.Cells12)
                    ) {
                        hourlyChartContent(DataSetKey.TRAFFIC_HOURLY_TOTAL)
                    }
                    mGridItem2(
                        MGridBreakpoints2(MGridSize2.Cells6)
                            .down(Breakpoint.md, MGridSize2.Cells12)
                    ) {
                        hourlyChartContent(DataSetKey.USER_HOURLY_TOTAL)
                    }
                }
                mDivider { css { height = 2.spacingUnits; backgroundColor = Color.transparent } }
                mGridContainer2(direction = MGridDirection.row) {
                    mGridItem2(
                        MGridBreakpoints2(MGridSize2.Cells6)
                            .down(Breakpoint.md, MGridSize2.Cells12)
                    ) {
                        hourlyChartContent(DataSetKey.TRAFFIC_HOURLY_WEEKDAY)
                    }
                    mGridItem2(
                        MGridBreakpoints2(MGridSize2.Cells6)
                            .down(Breakpoint.md, MGridSize2.Cells12)
                    ) {
                        hourlyChartContent(DataSetKey.USER_HOURLY_WEEKDAY)
                    }
                    mGridItem2(
                        MGridBreakpoints2(MGridSize2.Cells6)
                            .down(Breakpoint.md, MGridSize2.Cells12)
                    ) {
                        hourlyChartContent(DataSetKey.TRAFFIC_HOURLY_WEEKEND)
                    }
                    mGridItem2(
                        MGridBreakpoints2(MGridSize2.Cells6)
                            .down(Breakpoint.md, MGridSize2.Cells12)
                    ) {
                        hourlyChartContent(DataSetKey.USER_HOURLY_WEEKEND)
                    }
                }
            }
        } else {
            div {
                mDivider { css { height = 2.spacingUnits; backgroundColor = Color.transparent } }
                mGridContainer2(direction = MGridDirection.row) {
                    mGridItem2(
                        MGridBreakpoints2(MGridSize2.Cells6)
                            .down(Breakpoint.md, MGridSize2.Cells12)
                    ) {
                        wholeDayTrafficChart()
                    }
                    mGridItem2(
                        MGridBreakpoints2(MGridSize2.Cells6)
                            .down(Breakpoint.md, MGridSize2.Cells12)
                    ) {
                        wholeDayUserChart()
                    }
                }
            }
        }
    }

    private fun RBuilder.header() {
        mCard {
            css(GlobalStyles.card)
            mCardContent {
                css(GlobalStyles.cardContent)
                downloads()
            }
        }
    }

    private fun RBuilder.downloads() {
        mListSubheader(heading = "Exportieren")
        mDivider { }
        styledDiv {
            css {
                display = Display.flex
                flexDirection = FlexDirection.column
                padding(2.spacingUnits, 2.spacingUnits, 0.spacingUnits, 2.spacingUnits)
            }
            styledDiv {
                css {
                    padding(0.spacingUnits, 0.spacingUnits, 2.spacingUnits)
                }
                mTooltip("Download der angezeigten Daten als CSV.") {
                    var fname = "wifi_statistik_traffic_${state.selectedId?.myDate?.year()}"
                    state.filenameSuffix?.let { fname += "_$it" }
                    mLink(
                        hRefOptions = HRefOptions(
                            href = "/../statistics/accesspoint/${state.myCompany}/download/traffic/year/${state.selectedId?.myDate?.year()}/$fname.csv"
                        ),
                        underline = MLinkUnderline.none
                    ) {
                        attrs.color = MTypographyColor.inherit
                        mButton(
                            caption = "Datenverbrauch",
                            variant = MButtonVariant.outlined,
                            color = MColor.secondary,
                        ) {
                            attrs.fullWidth = true
                            attrs.endIcon = mIconNoTranslate("get_app", addAsChild = false)
                        }
                    }
                }
                mTooltip("Download der angezeigten Daten als CSV.") {
                    var fname = "wifi_statistik_traffic_${state.selectedId?.myDate?.year()}"
                    state.filenameSuffix?.let { fname += "_$it" }
                    mLink(
                        hRefOptions = HRefOptions(
                            href = "/../statistics/accesspoint/${state.myCompany}/download/users/year/${state.selectedId?.myDate?.year()}/$fname.csv"
                        ),
                        underline = MLinkUnderline.none
                    ) {
                        attrs.color = MTypographyColor.inherit
                        mButton(
                            caption = "Verbindungen",
                            variant = MButtonVariant.outlined,
                            color = MColor.secondary,
                        ) {
                            attrs.endIcon = mIconNoTranslate("get_app", addAsChild = false)
                            attrs.fullWidth = true
                            css { marginTop = 12.px }
                        }
                    }
                }
            }
            mTypography(
                text = "Daten werden jede Nacht um 2 Uhr aktualisiert.",
                color = MTypographyColor.textSecondary,
                variant = MTypographyVariant.caption
            )
        }
    }

    private fun RBuilder.totalContent(type: DataSetKey) = state.selectedId?.let {
        when (val settings = state.chartData[type]) {
            null -> mSkeleton(
                height = 100.px,
                animation = MSkeletonAnimation.wave,
                variant = MSkeletonVariant.rect
            )

            else -> totalLabel(type, settings)
        }
    }

    private fun RBuilder.rankingContent(type: DataSetKey) = state.selectedId?.let {
        when (val settings = state.rankingData[type]) {
            null -> mSkeleton(
                height = 100.px,
                animation = MSkeletonAnimation.wave,
                variant = MSkeletonVariant.rect
            )

            else -> rankingList(type, settings!!)
        }
    }

    private fun RBuilder.chartContent(scope: DateTreeElement.DateElementType, type: DataSetKey) {
        val chartData = when (type) {
            DataSetKey.TRAFFIC -> state.chartData[DataSetKey.TRAFFIC_TOTAL]
            DataSetKey.TRAFFIC_TOTAL -> state.chartData[DataSetKey.TRAFFIC_TOTAL]
            DataSetKey.USER -> state.chartData[DataSetKey.USER_TOTAL]
            DataSetKey.USER_TOTAL -> state.chartData[DataSetKey.USER_TOTAL]
            else -> null
        }

        when (chartData) {
            null -> mSkeleton(
                height = 100.px,
                animation = MSkeletonAnimation.wave,
                variant = MSkeletonVariant.rect
            )

            else -> div {
                when (scope) {
                    DateTreeElement.DateElementType.YEAR -> yearlyChart(type, chartData)
                    DateTreeElement.DateElementType.MONTH -> monthlyChart(type, chartData)
                    DateTreeElement.DateElementType.DAY -> dailyChart(type, chartData)
                }
            }
        }
    }

    private fun RBuilder.hourlyChartContent(type: DataSetKey) {
        when (val chartData = state.chartData[type]) {
            null -> mSkeleton(
                height = 100.px,
                animation = MSkeletonAnimation.wave,
                variant = MSkeletonVariant.rect
            )

            else -> hourlyChart(type, chartData)
        }
    }

    private fun RBuilder.totalLabel(type: DataSetKey, data: Array<Array<Any>>?) {
        val total = data.let { outer ->
            var tally = 0.0
            outer?.forEach { inner ->
                inner.forEach {
                    when (it) {
                        is Double -> tally = tally.plus(it)
                        is kotlin.Float -> tally = tally.plus(it)
                        is Long -> tally = tally.plus(it)
                        is Int -> tally = tally.plus(it)
                    }
                }
            }
            tally.toLong().formatDecimalSeparator()
        }
        val title =
            when (type) {
                DataSetKey.TRAFFIC_TOTAL -> "Traffic"
                DataSetKey.USER_TOTAL -> "Nutzer"
                else -> ""
            }
        val label =
            if (type == DataSetKey.TRAFFIC_TOTAL)
                when (state.selectedId!!.type) {
                    DateTreeElement.DateElementType.YEAR -> "$total GB"
                    DateTreeElement.DateElementType.MONTH -> "$total GB"
                    DateTreeElement.DateElementType.DAY -> "$total MB"
                    else -> total
                }
            else
                total

        mCard {
            css(GlobalStyles.card)
            mCardContent {
                css(GlobalStyles.cardBoxContent)
                if (data!!.isEmpty()) mTypography(
                    text = "Keine Daten",
                    align = MTypographyAlign.center,
                    variant = MTypographyVariant.subtitle2,
                    color = MTypographyColor.textSecondary
                ) { css { padding(1.spacingUnits) } }
                else {
                    styledDiv {
                        css { padding(1.spacingUnits) }
                        mListSubheader {
                            mTypography(
                                text = "Gesamt $title",
                                align = MTypographyAlign.center,
                                variant = MTypographyVariant.subtitle2,
                                color = MTypographyColor.primary
                            ) { css { padding(1.spacingUnits) } }
                        }
                        mTypography(
                            text = label,
                            align = MTypographyAlign.center,
                            variant = MTypographyVariant.h4,
                            color = MTypographyColor.secondary
                        ) { css { padding(1.spacingUnits); fontWeight = FontWeight.bold } }
                    }
                }
            }
        }
    }

    private fun RBuilder.rankingList(type: DataSetKey, data: List<Pair<String, Long>>) {
        val unit = if (type == DataSetKey.TRAFFIC_RANKING)
            when (state.selectedId!!.type) {
                DateTreeElement.DateElementType.YEAR -> "GB"
                DateTreeElement.DateElementType.MONTH -> "GB"
                DateTreeElement.DateElementType.DAY -> "MB"
                else -> ""
            }
        else
            ""
        mCard {
            css(GlobalStyles.card)
            mCardContent {
                css(GlobalStyles.cardBoxContent)
                if (data!!.isEmpty()) mTypography(
                    text = "Keine Daten",
                    align = MTypographyAlign.center,
                    variant = MTypographyVariant.subtitle2,
                    color = MTypographyColor.textSecondary
                ) { css { padding(1.spacingUnits) } }
                else {
                    styledDiv {
                        mListSubheader {
                            mTypography(
                                text = "Top 5 ${if (type.name.startsWith("TRAFFIC")) "Traffic" else "Nutzer"}",
                                align = MTypographyAlign.center,
                                variant = MTypographyVariant.subtitle2,
                                color = MTypographyColor.primary
                            ) { css { padding(1.spacingUnits) } }
                        }
                        mList {
                            css { padding(0.spacingUnits) }
                            data.take(5).forEach {
                                mListItem(
                                    primaryText = it.first,
                                    secondaryText = if (type.name.startsWith("TRAFFIC")) "${it.second} $unit" else "${it.second}",
                                    hRefOptions = HRefOptions(
                                        href = "/portal/devices/ivantoConnect/cpuid/${it.first}"
                                    ),
                                ) { attrs.divider = false; attrs.dense = true }
                            }
                        }
                    }
                }
            }
        }
    }

    private fun RBuilder.yearlyChart(type: DataSetKey, data: Array<Array<Any>>?) {
        val settings = when (type) {
            DataSetKey.USER_TOTAL -> Pair("Nutzer", "Nutzer")
            DataSetKey.TRAFFIC_TOTAL -> Pair("Datenverbrauch", "Datenverbrauch (GB)")
            else -> Pair("", "")
        }

        mCard {
            css(GlobalStyles.card)
            mCardContent {
                css(GlobalStyles.cardChartContent)
                if (data!!.isEmpty()) mTypography(
                    text = "Keine Daten",
                    align = MTypographyAlign.center,
                    variant = MTypographyVariant.subtitle2
                )
                chart(
                    type = "ColumnChart",
                    titleLabel = settings.first,
                    yFormat = if (type.name.startsWith("TRAFFIC")) "#,###.## GB" else null,
                    columns = arrayOf(
                        Column(type = "string", label = "Monat"),
                        Column(type = "number", label = settings.second, format = "#,###.## GB")
                    ),
                    rows = data
                )
            }
        }
    }

    private fun RBuilder.monthlyChart(type: DataSetKey, data: Array<Array<Any>>?) {
        val settings = when (type) {
            DataSetKey.USER_TOTAL -> Pair("Nutzer", "Nutzer")
            DataSetKey.TRAFFIC_TOTAL -> Pair("Datenverbrauch", "Datenverbrauch (MB)")
            else -> Pair("", "")
        }

        mCard {
            css(GlobalStyles.card)
            mCardContent {
                css(GlobalStyles.cardChartContent)
                if (data!!.isEmpty()) mTypography(
                    text = "Keine Daten",
                    align = MTypographyAlign.center,
                    variant = MTypographyVariant.subtitle2,
                    color = MTypographyColor.textSecondary
                ) { css { padding(1.spacingUnits) } }
                chart(
                    type = "ColumnChart",
                    titleLabel = settings.first,
                    yFormat = if (type.name.startsWith("TRAFFIC")) "#,###.## GB" else null,
                    columns = arrayOf(
                        Column(type = "string", label = "Tag"),
                        Column(type = "number", label = settings.second)
                    ),
                    rows = data
                )
            }
        }
    }

    private fun RBuilder.dailyChart(type: DataSetKey, data: Array<Array<Any>>?) {
        val settings = when (type) {
            DataSetKey.USER_TOTAL -> Pair("Nutzer", "Nutzer")
            DataSetKey.TRAFFIC_TOTAL -> Pair("Datenverbrauch", "Datenverbrauch (MB)")
            else -> Pair("", "")
        }

        mCard {
            css(GlobalStyles.card)
            mCardContent {
                css(GlobalStyles.cardChartContent)
                if (data!!.isEmpty()) mTypography(
                    text = "Keine Daten",
                    align = MTypographyAlign.center,
                    variant = MTypographyVariant.subtitle2,
                    color = MTypographyColor.textSecondary
                ) { css { padding(1.spacingUnits) } }
                chart(
                    type = "LineChart",
                    titleLabel = settings.first,
                    xFormat = "HH:mm",
                    legendPosition = "bottom",
                    columns = arrayOf(
                        Column(type = "datetime", label = "Zeit"),
                        Column(type = "number", label = settings.second)
                    ),
                    rows = data
                )
            }
        }
    }

    private fun RBuilder.hourlyChart(type: DataSetKey, data: Array<Array<Any>>?) {
        val settings = when (type) {
            DataSetKey.USER_HOURLY_TOTAL -> Pair("Stündliche Nutzer", "Nutzer")
            DataSetKey.USER_HOURLY_WEEKDAY -> Pair("Stündliche Nutzer Montag - Freitag", "Nutzer")
            DataSetKey.USER_HOURLY_WEEKEND -> Pair("Stündliche Nutzer Wochenende", "Nutzer")
            DataSetKey.TRAFFIC_HOURLY_TOTAL -> Pair("Stündlicher Datenverbrauch", "Datenverbrauch (MB)")
            DataSetKey.TRAFFIC_HOURLY_WEEKDAY -> Pair(
                "Stündlicher Datenverbrauch Montag - Freitag",
                "Datenverbrauch (MB)"
            )

            DataSetKey.TRAFFIC_HOURLY_WEEKEND -> Pair(
                "Stündlicher Datenverbrauch Wochenende",
                "Datenverbrauch (MB)"
            )

            else -> Pair("", "")
        }

        mCard {
            css(GlobalStyles.card)
            mCardContent {
                css(GlobalStyles.cardChartContent)
                if (data!!.isEmpty()) mTypography(
                    text = "Keine Daten",
                    align = MTypographyAlign.center,
                    variant = MTypographyVariant.subtitle2,
                    color = MTypographyColor.textSecondary
                ) { css { padding(1.spacingUnits) } }
                chart(
                    type = "ColumnChart",
                    titleLabel = settings.first,
//                    isLogScale = type.name.startsWith("TRAFFIC"),
                    yFormat = if (type.name.startsWith("TRAFFIC")) "#,###.## GB" else null,
                    legendPosition = "bottom",
                    columns = arrayOf(
                        Column(type = "number", label = "Stunde"),
                        Column(type = "number", label = "Median ${settings.second}"),
                        Column(type = "number", label = "Durchschnittlicher ${settings.second}"),
                        Column(type = "number", label = "Maximaler ${settings.second}"),
                    ),
                    rows = data
                )
            }
        }
    }

    private fun RBuilder.wholeDayTrafficChart() {
        state.selectedId?.let {
            chartContent(it.type, DataSetKey.TRAFFIC_TOTAL)
        }
    }

    private fun RBuilder.wholeDayUserChart() {
        state.selectedId?.let {
            chartContent(it.type, DataSetKey.USER_TOTAL)
        }
    }

    private fun String?.mapFromHourly(divisor: Double? = null): Array<Array<Any>>? {
        val template = listOf(1..24)
            .flatten()
            .associate { "$it" to Triple(0 as Any, 0 as Any, 0 as Any) }
            .toMutableMap()
        this?.let { str ->
            str
                .lines()
                .forEach { line ->
                    val split = line.split(';')
                    val hour = split[0].toLong() as Any
                    val median = split[1].toLong().let { divisor?.let { div -> (it / div) } ?: it } as Any
                    val average = split[2].toLong().let { divisor?.let { div -> (it / div) } ?: it } as Any
                    val maximum = split[3].toLong().let { divisor?.let { div -> (it / div) } ?: it } as Any
                    template["$hour"] = Triple(median, average, maximum)
                }
        }
        return template.map {
            arrayOf(it.key, it.value.first, it.value.second, it.value.third)
        }.toTypedArray()
    }

    fun Long.formatDecimalSeparator(): String {
        return toString()
            .reversed()
            .chunked(3)
            .joinToString(".")
            .reversed()
    }
}