package de.geomobile.frontend.features.admin.roles

import com.ccfraser.muirwik.components.MGridDirection
import com.ccfraser.muirwik.components.form.mFormControlLabel
import com.ccfraser.muirwik.components.list.mList
import com.ccfraser.muirwik.components.list.mListItem
import com.ccfraser.muirwik.components.mCheckbox
import com.ccfraser.muirwik.components.mCheckboxWithLabel
import com.ccfraser.muirwik.components.spacingUnits
import com.ccfraser.muirwik.components.styles.Breakpoint
import de.geomobile.common.feature.Feature
import de.geomobile.common.permission.Permission
import de.geomobile.common.permission.PermissionGroup
import de.geomobile.common.permission.Permissions
import de.geomobile.frontend.utils.*
import kotlinx.css.*
import kotlinx.css.properties.border
import kotlinx.css.properties.borderBottom
import react.*
import styled.css
import styled.styledDiv

fun RBuilder.permissionSelection(
    allVisible: Boolean,
    mutable: Boolean,
    features: Set<Feature>?,
    permissions: Set<Permission>,
    onChanged: (Set<Permission>) -> Unit,
) = child(PermissionSelectionComponent::class) {
    attrs.allVisible = allVisible
    attrs.mutable = mutable
    attrs.features = features
    attrs.permissions = permissions
    attrs.onChanged = onChanged
}

class PermissionSelectionComponent : RComponent<PermissionSelectionComponent.Props, RState>() {

    interface Props : RProps {
        var allVisible: Boolean
        var mutable: Boolean
        var features: Set<Feature>?
        var permissions: Set<Permission>
        var onChanged: (Set<Permission>) -> Unit
    }

    override fun RBuilder.render() {
        val selectedPermissions = props.permissions

        mGridContainer2(direction = MGridDirection.row) {
            for (permissionGroup in Permissions.permissionGroups) {
                if (props.allVisible || (permissionGroup.visibleForCompany && hasFeature(*permissionGroup.requiredFeatures.toTypedArray()))) {
                    mGridItem2(
                        MGridBreakpoints2(MGridSize2.Cells6)
                            .down(Breakpoint.sm, MGridSize2.Cells12)
                    ) {
                        renderPermissionGroup(permissionGroup, selectedPermissions)
                    }
                }
            }
        }
    }

    private fun RElementBuilder<MGridProps2>.renderPermissionGroup(
        permissionGroup: PermissionGroup,
        selectedPermissions: Set<Permission>,
    ) {
        val permissionsInGroup = permissionGroup.permissions
            .filter { props.allVisible || it.visibleForCompany }
            .filter { props.allVisible || hasFeature(*it.requiredFeatures.toTypedArray()) }

        val selectedPermissionsInGroup = permissionsInGroup.intersect(selectedPermissions)

        styledDiv {
            css {
                border(1.px, BorderStyle.solid, Color.lightGrey)
                borderRadius = 1.spacingUnits
            }

            styledDiv {
                css {
                    textTransform = TextTransform.uppercase
                    borderBottom(1.px, BorderStyle.solid, Color.lightGrey)
                    display = Display.flex
                    padding(1.spacingUnits, 2.spacingUnits)
                }

                mCheckboxWithLabel(
                    checked = selectedPermissionsInGroup.size == permissionsInGroup.size,
                    label = when {
                        props.allVisible && !permissionGroup.visibleForCompany ->
                            "${permissionGroup.name} (internal)"

                        props.allVisible && permissionGroup.requiredFeatures.any() ->
                            "${permissionGroup.name} [" + permissionGroup.requiredFeatures
                                .map { it.name }
                                .joinToString("; ") + "]"

                        else -> permissionGroup.name
                    },
                    indeterminate = selectedPermissionsInGroup.any() && selectedPermissionsInGroup.size < permissionsInGroup.size,
                    disabled = !props.mutable,
                    onChange = { _, checked ->
                        props.onChanged(
                            if (checked) props.permissions + permissionsInGroup
                            else props.permissions - permissionsInGroup
                        )
                    }
                )
            }

            mList {
                for (permission in permissionsInGroup) {
                    mListItem {
                        css { padding(vertical = 0.spacingUnits) }

                        val featuresEnabled = props.features
                            ?.map { it.id }
                            ?.containsAll(permission.requiredFeatures.map { it.id })
                            ?: true

                        val checked = featuresEnabled && selectedPermissionsInGroup.contains(permission)
                        val disabled = !featuresEnabled || !props.mutable
                        val checkBox = mCheckbox(
                            checked = checked,
                            disabled = disabled,
                            onChange = { _, checked ->
                                props.onChanged(
                                    if (checked) props.permissions + permission
                                    else props.permissions - permission
                                )
                            },
                            addAsChild = false
                        ) { css { padding(vertical = 0.spacingUnits) } }

                        val label = when {
                            props.allVisible && !permission.visibleForCompany ->
                                "${permission.name} (internal)"

                            props.allVisible && permission.requiredFeatures.any() ->
                                "${permission.name} [" +
                                        permission.requiredFeatures.joinToString("; ") { it.name } +
                                        "]"

                            else -> permission.name
                        }

                        mFormControlLabel(
                            label,
                            checkBox,
                            checked,
                            disabled
                        )
                    }
                }
            }
        }
    }
}