package de.geomobile.frontend.features.softwareManagement.software

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.card.mCardHeader
import com.ccfraser.muirwik.components.list.mListSubheader
import de.geomobile.common.softwaremgmt.SoftwareVersion
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.utils.mCheckboxWithLabelPadding
import de.geomobile.frontend.utils.toText
import kotlinext.js.jsObject
import kotlinx.css.padding
import kotlinx.css.pct
import kotlinx.css.px
import kotlinx.css.width
import react.*
import styled.css
import styled.styledDiv
import utils.humanReadableByteCount

fun RBuilder.softwareVersionDetail(
    softwareVersion: SoftwareVersion,
) = child(SoftwareVersionDetail::class) {
    attrs.softwareVersion = softwareVersion
}

class SoftwareVersionDetail : RComponent<SoftwareVersionDetail.Props, RState>() {

    interface Props : RProps {
        var softwareVersion: SoftwareVersion
    }

    override fun RBuilder.render() {

        val selected = props.softwareVersion

        mCard {
            css(GlobalStyles.card)
            mCardHeader(
                title = selected.version,
                subHeader = "${selected.uploadedBy} (${selected.committedAt!!.toText()}), ${selected.size.humanReadableByteCount()}, ${selected.md5}",
                action = buildElement {
                    mIconButtonNoTranslate(
                        iconName = "get_app",
                        href = "/../software/download/${selected.softwareId}/${selected.version}?token=${selected.downloadToken}"
                    ) {
                        attrs.asDynamic().download =
                            "${selected.softwareId}__${selected.version.replace(".", "_")}.zip"
                    }
                }
            ) {
                attrs.titleTypographyProps = jsObject<MTypographyProps> {
                    variant = MTypographyVariant.subtitle2
                    color = MTypographyColor.secondary
                }
                attrs.subheaderTypographyProps = jsObject<MTypographyProps> {
                    variant = MTypographyVariant.caption
                }
            }
            mDivider { }
            mCardContent {
                css(GlobalStyles.cardBoxContent)
                styledDiv {
                    css { padding(2.spacingUnits, 0.spacingUnits) }
                    mCheckboxWithLabelPadding(
                        label = "Reboot on success",
                        paddingCheckbox = "2px",
                        checked = selected.rebootOnSuccess,
                        disabled = true
                    ) {
                        css {
                            width = 100.pct
                            padding(0.spacingUnits, 3.spacingUnits)
                        }
                    }
                    mCheckboxWithLabelPadding(
                        label = "Rollback on failure",
                        paddingCheckbox = "2px",
                        checked = selected.rollbackOnFailure,
                        disabled = true
                    ) {
                        css {
                            width = 100.pct
                            padding(0.spacingUnits, 3.spacingUnits)
                        }
                    }
                    mCheckboxWithLabelPadding(
                        label = "Temporary download",
                        paddingCheckbox = "2px",
                        checked = selected.temporaryDownload,
                        disabled = true
                    ) {
                        css {
                            width = 100.pct
                            padding(0.spacingUnits, 3.spacingUnits)
                        }
                    }
                }
                mDivider {}
                mListSubheader(heading = "Dateien")
                softwareVersionFiles(selected.softwareId, selected.version, selected.downloadToken)
            }
        }
    }
}