package portalmodels

import de.geomobile.common.portalmodels.*
import de.geomobile.common.softwaremgmt.UpdateStateSmallDTO
import de.geomobile.common.time.LocalDateTime
import kotlinx.serialization.Serializable

@Serializable
data class DashboardDeviceSummaryDTO(
    var deviceId: Int,
    var device: DeviceSmallDTO? = null,
    var ivantoOnlineStatus: TimestampStatus? = null,
    var linuxOnlineStatus: TimestampStatus? = null,
    var updateStatus: UpdateStateSmallDTO? = null,
    var ibisStatus: TimestampStatus? = null,
    var fmsStatus: TimestampStatus? = null,
    var gpsStatus: Position? = null,
)

@Serializable
data class DashboardReviewSummaryDTO(
    var devices: Map<Int, DeviceSmallDTO> = mapOf(),
    var softwareOutOfDate: List<Int> = listOf(),
    var softwareFailed: List<Int> = listOf(),
    var deviceLongOffline: List<Int> = listOf(),
    var deviceOnlyLinuxOnline: List<Int> = listOf(),
    var deviceUncategorized: List<Int> = listOf(),
)

@Serializable
data class DashboardStatusSummaryDTO(
    var vehicleStatus: Map<VehicleType, VehicleStatus>? = null,
    var hardwareStatus: Map<Product, HardwareStatus>? = null,
    var softwareStatus: Map<String, SoftwareStatus>? = null,
)

@Serializable
data class VehicleStatus(
    val linuxOnline: Int,
    val ivantoOnline: Int,
    val offline: Int,
)

@Serializable
data class HardwareStatus(
    val linuxOnline: Int,
    val ivantoOnline: Int,
    val offline: Int,
    val inRepair: Int,
    val inStorage: Int,
)

@Serializable
data class SoftwareStatus(
    val assignmentDate: LocalDateTime,
    val installed: Int,
    val waitingInQueue: Int,
    val outdated: Int,
    val failed: Int,
)