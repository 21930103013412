package de.geomobile.frontend.features.config

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.*
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardActionArea
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.dialog.mDialog
import com.ccfraser.muirwik.components.dialog.mDialogActions
import com.ccfraser.muirwik.components.dialog.mDialogContent
import com.ccfraser.muirwik.components.dialog.mDialogTitle
import com.ccfraser.muirwik.components.form.MFormControlMargin
import com.ccfraser.muirwik.components.form.MFormControlVariant
import com.ccfraser.muirwik.components.form.mFormControl
import com.ccfraser.muirwik.components.form.margin
import com.ccfraser.muirwik.components.input.MInputProps
import com.ccfraser.muirwik.components.lab.alert.MAlertSeverity
import com.ccfraser.muirwik.components.lab.alert.MAlertVariant
import com.ccfraser.muirwik.components.lab.alert.mAlert
import com.ccfraser.muirwik.components.list.mListSubheader
import com.ccfraser.muirwik.components.styles.Breakpoint
import com.ccfraser.muirwik.components.transitions.mCollapse
import de.geomobile.common.filter.*
import de.geomobile.common.portalmodels.ChangeDeviceConfigFilterDTO
import de.geomobile.common.portalmodels.DeviceIdentifier
import de.geomobile.common.utils.indentedJson
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.features.device.list.DeviceListItem
import de.geomobile.frontend.features.device.list.deviceList
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.utils.*
import de.geomobile.server.device.config.DeviceConfigFilter
import de.geomobile.server.device.config.checkForConflict
import kotlinext.js.jsObject
import kotlinx.browser.localStorage
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.*
import kotlinx.serialization.builtins.serializer
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonObject
import org.w3c.dom.get
import org.w3c.dom.set
import react.*
import styled.css
import styled.styledDiv

fun RBuilder.deviceConfigDetail(
    newParentId: Int?,
    newPosition: Int?,
    deviceConfigFilter: DeviceConfigFilter,
    branch: List<DeviceConfigFilter>,
    siblings: List<DeviceConfigFilter>,
    conflicts: List<DeviceConfigFilter>,
    deviceFilter: Map<String, Filter<DeviceListItem, *>>,
    onSaved: (filterId: Int?) -> Unit,
    onDeviceClick: (id: DeviceIdentifier) -> Unit,
) = child(DeviceConfigDetail::class) {
    attrs.newParentId = newParentId
    attrs.newPosition = newPosition
    attrs.deviceConfigFilter = deviceConfigFilter
    attrs.branch = branch
    attrs.siblings = siblings
    attrs.conflicts = conflicts
    attrs.deviceFilter = deviceFilter
    attrs.onSaved = onSaved
    attrs.onDeviceClick = onDeviceClick

    attrs.key = deviceConfigFilter.id.toString()
}

class DeviceConfigDetail : CComponent<DeviceConfigDetail.Props, DeviceConfigDetail.State>() {

    private var saveJob: Job = Job()

    interface Props : RProps {
        var newParentId: Int?
        var newPosition: Int?
        var deviceConfigFilter: DeviceConfigFilter
        var branch: List<DeviceConfigFilter>
        var siblings: List<DeviceConfigFilter>
        var conflicts: List<DeviceConfigFilter>
        var deviceFilter: Map<String, Filter<DeviceListItem, *>>
        var onSaved: (filterId: Int?) -> Unit
        var onDeviceClick: (id: DeviceIdentifier) -> Unit
    }

    class State(
        var validFilter: Boolean = true,
        var validConfigOverlay: Boolean = true,

        var filterChanged: Boolean = false,
        var configOverlayChanged: Boolean = false,

        var newName: String? = null,
        var newFilter: String? = null,
        var newConfigOverlay: String? = null,
        var newDeactivateConfigs: Boolean? = null,
        var newDisabledConfigs: Boolean? = null,

        var comment: String = "",

        var showHistory: Boolean = false,

        var showDeviceList: Boolean = localStorage["deviceConfigShowDeviceList"]?.toBoolean() ?: true,
        var deviceCount: Int = 0,
    ) : RState

    init {
        state = State()
    }

    fun conflictsWithSiblings(): List<String> {
        val conflicts: MutableList<String> = mutableListOf()
        props.siblings.forEach {
            if (checkForConflict(props.deviceConfigFilter.configOverlay, it.configOverlay)) {
                conflicts.add(it.name)
            }
        }
        return conflicts.toList()
    }

    override fun RBuilder.render() {

        val new = props.deviceConfigFilter.id < 0

        mGridContainer2(direction = MGridDirection.column) {
            mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                mCard {
                    css(GlobalStyles.card)
                    mCardContent {
                        css(GlobalStyles.cardBoxContent)
                        mListSubheader(heading = "Konfiguration")
                        mDivider { }
                        styledDiv {
                            css { padding(2.spacingUnits) }

                            mTextField(
                                label = "Name",
                                margin = MFormControlMargin.dense,
                                value = state.newName ?: props.deviceConfigFilter.name,
                                variant = MFormControlVariant.outlined,
                                onChange = {
                                    val value = it.targetInputValue
                                    setState { newName = value.takeIf { it != props.deviceConfigFilter.name } }
                                }
                            ) {
                                attrs.fullWidth = true
                                attrs.inputProps = if (!new) {
                                    jsObject<MInputProps> {
                                        endAdornment = buildElement {
                                            mIconButtonNoTranslate(
                                                size = MButtonSize.small,
                                                iconName = "history",
                                                onClick = { setState { showHistory = true } }
                                            ) {
                                                css { fontSize = LinearDimension.inherit }
                                                attrs.edge = MIconEdge.start
                                            }
                                        }
                                    }
                                } else jsObject {}
                            }

                            mTextField(
                                label = "Filter",
                                value = state.newFilter ?: props.deviceConfigFilter.filterRules
                                    .queryString(props.deviceFilter),
                                margin = MFormControlMargin.dense,
                                variant = MFormControlVariant.outlined,
                                error = if (state.filterChanged) !state.validFilter else !props.deviceConfigFilter.filterValid,
                                fullWidth = true,
                                onChange = {
                                    val value = it.targetInputValue
                                    val parsed = try {
                                        value.parseFilterRules(props.deviceFilter)
                                    } catch (e: Throwable) {
                                        null
                                    }
                                    setState {
                                        newFilter = value
                                        filterChanged = parsed != props.deviceConfigFilter.filterRules
                                        validFilter = parsed != null
                                    }
                                }
                            ) { css { margin(2.spacingUnits, 0.spacingUnits) } }

                            styledDiv {
                                css { padding(1.spacingUnits) }
                                mFormControl(fullWidth = true) {
                                    mSwitchWithLabel(
                                        label = "Diese Konfiguration deaktivieren",
                                        checked = state.newDisabledConfigs ?: props.deviceConfigFilter.disabled,
                                        onChange = { _, checked ->
                                            setState {
                                                newDisabledConfigs =
                                                    checked.takeIf { it != props.deviceConfigFilter.disabled }
                                            }
                                        }
                                    )
                                }
                                mFormControl(fullWidth = true) {
                                    mSwitchWithLabel(
                                        label = "Gesamte Verteilung an angegbene Geräte deaktiveren",
                                        checked = state.newDeactivateConfigs
                                            ?: props.deviceConfigFilter.deactivateConfigs,
                                        onChange = { _, checked ->
                                            setState {
                                                newDeactivateConfigs =
                                                    checked.takeIf { it != props.deviceConfigFilter.deactivateConfigs }
                                            }
                                        }
                                    )
                                }
                            }
                        }

                        if (
                            state.newName != null ||
                            state.filterChanged ||
                            state.newDeactivateConfigs != null ||
                            state.newDisabledConfigs != null ||
                            state.configOverlayChanged ||
                            props.newParentId != null ||
                            props.newPosition != null
                        ) {
                            styledDiv {
                                css { padding(0.px, 2.spacingUnits, 3.spacingUnits, 2.spacingUnits) }
                                mGridContainer2(
                                    direction = MGridDirection.row
                                ) {
                                    mGridItem2(
                                        MGridBreakpoints2(MGridSize2.Cells12)
                                    ) {
                                        mTextField(
                                            label = "",
                                            value = state.comment,
                                            placeholder = "Was wurde geändert?",
                                            variant = MFormControlVariant.outlined,
                                            onChange = {
                                                val value = it.targetInputValue
                                                setState { comment = value }
                                            }
                                        ) {
                                            attrs.margin = MFormControlMargin.dense;
                                            attrs.fullWidth = true
                                        }
                                    }
                                    mGridItem2(
                                        MGridBreakpoints2(MGridSize2.Auto)
                                            .down(Breakpoint.sm, MGridSize2.Cells12)
                                    ) {
                                        mButton(
                                            caption = "Übernehmen",
                                            variant = MButtonVariant.contained,
                                            color = MColor.secondary,
                                            disabled = (if (state.filterChanged) !state.validFilter else !props.deviceConfigFilter.filterValid) ||
                                                    !state.validConfigOverlay ||
                                                    state.newName?.isBlank() == true,
                                            onClick = { saveDeviceConfig() }
                                        ) {
                                            attrs.fullWidth = true
                                            attrs.disableElevation = true
                                        }
                                    }
                                    mGridItem2(
                                        MGridBreakpoints2(MGridSize2.Auto)
                                            .down(Breakpoint.sm, MGridSize2.Cells12)
                                    ) {
                                        mButton(
                                            variant = MButtonVariant.contained,
                                            caption = "Abbrechen",
                                            color = MColor.default,
                                            onClick = {
                                                clearChanges()
                                                props.onSaved(props.deviceConfigFilter.id.takeIf { it >= 0 })
                                            }
                                        ) {
                                            attrs.fullWidth = true
                                            attrs.disableElevation = true
                                        }
                                    }
                                }
                            }
                        }

                        // TODO: Add link to conflicting config filter
                        if (props.conflicts.isNotEmpty()) {
                            styledDiv {
                                css { padding(0.px, 2.spacingUnits) }
                                props.conflicts.forEach {
                                    mAlert(
                                        variant = MAlertVariant.filled,
                                        severity = MAlertSeverity.error,
                                        message = "Konflikt mit ${it.name} oder einer der Unterknoten!"
                                    ) {
                                        css {
                                            marginBottom = 1.spacingUnits
                                            ":last-child" { marginBottom = 0.spacingUnits }
                                        }
                                    }
                                }
                            }
                        }

                        if (conflictsWithSiblings().isNotEmpty()) {

                            if (props.conflicts.isNotEmpty()) {
                                mDivider {
                                    css {
                                        marginTop = 1.spacingUnits;
                                        backgroundColor = Color.transparent
                                    }
                                }
                            }

                            styledDiv {
                                css { padding(0.px, 2.spacingUnits) }
                                conflictsWithSiblings().forEach {
                                    mAlert(
                                        variant = MAlertVariant.outlined,
                                        severity = MAlertSeverity.warning,
                                        message = "Potenzieller Konflikt mit $it."
                                    ) {
                                        css {
                                            marginBottom = 1.spacingUnits
                                            ":last-child" { marginBottom = 0.spacingUnits }
                                        }
                                    }
                                }
                            }
                        }

                        styledDiv {
                            css { padding(2.spacingUnits, 0.spacingUnits) }
                            deviceConfigOverlay(
                                overlay = props.deviceConfigFilter.configOverlay,
                                overlayEdit = state.newConfigOverlay ?: indentedJson.encodeToString(
                                    JsonObject.serializer(),
                                    props.deviceConfigFilter.configOverlay
                                ),
                                preceding = props.branch.dropLast(1).map { it.configOverlay },
                                onChange = { valid, changed, raw ->
                                    setState {
                                        validConfigOverlay = valid
                                        configOverlayChanged = !valid || changed != null
                                        newConfigOverlay = raw
                                    }
                                }
                            )
                        }
                    }
                }
            }
            mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                filteredDevices()
            }
        }

        mDialog(
            open = state.showHistory,
            fullWidth = true,
            maxWidth = Breakpoint.lg,
            onClose = { _, _ -> setState { showHistory = false } }
        ) {
            css {
                children {
                    lastChild {
                        children {
                            lastChild {
                                height = 90.pct
                            }
                        }
                    }
                }
            }
            mDialogTitle(text = "History \"${props.deviceConfigFilter.name}\"")
            mDialogContent {
                deviceConfigHistory(props.deviceConfigFilter.id, props.deviceFilter)
            }
            mDialogActions {
                mButton("Close", onClick = { setState { showHistory = false } })
            }
        }
    }

    private fun RBuilder.filteredDevices() {
        mCard {
            css(GlobalStyles.card)
            mCardContent {
                css(GlobalStyles.cardBoxContent)

                val filter = if (state.validFilter && state.newFilter != null)
                    state.newFilter!!.parseFilterRules(props.deviceFilter)
                else
                    props.deviceConfigFilter.filterRules

                val matcher = FilterMatcher(
                    filterRules = FilterRules(
                        rules = props.branch.dropLast(1).flatMap { it.filterRules.rules }.plus(filter.rules)
                    ),
                    filters = props.deviceFilter
                )

                mCardActionArea(
                    onClick = {
                        localStorage["deviceConfigShowDeviceList"] = (!state.showDeviceList).toString()
                        setState { this.showDeviceList = !this.showDeviceList }
                    }
                ) {
                    mCardHeaderExtended(
                        title = "${state.deviceCount} Geräte gefunden",
                        action = mIconButtonNoTranslate(
                            if (state.showDeviceList) "expand_less" else "expand_more",
                            onClick = {
                                localStorage["deviceConfigShowDeviceList"] = (!state.showDeviceList).toString()
                                setState { this.showDeviceList = !this.showDeviceList }
                            },
                            addAsChild = false
                        )
                    )
                }
                mCollapse(state.showDeviceList) {
                    deviceList(
                        persistenceId = "DeviceConfig",
                        onDeviceClick = { props.onDeviceClick(it) },
                        filter = matcher,
                        onFilteredDevicesChanged = {
                            if (state.deviceCount != it.count())
                                setState { deviceCount = it.count() }
                        }
                    )
                }
            }
        }
    }

    private fun clearChanges() {
        setState {
            newName = null
            newFilter = null
            newConfigOverlay = null
            newDeactivateConfigs = null
            newDisabledConfigs = null
            filterChanged = false
            configOverlayChanged = false
        }
    }

    private fun saveDeviceConfig() {
        val configFilter = props.deviceConfigFilter.copy(
            name = state.newName ?: props.deviceConfigFilter.name,
            filterRules = state.newFilter?.parseFilterRules(props.deviceFilter)
                ?: props.deviceConfigFilter.filterRules,
            configOverlay = state.newConfigOverlay?.let { Json.decodeFromString(JsonObject.serializer(), it) }
                ?: props.deviceConfigFilter.configOverlay,
            deactivateConfigs = state.newDeactivateConfigs ?: props.deviceConfigFilter.deactivateConfigs,
            disabled = state.newDisabledConfigs ?: props.deviceConfigFilter.disabled
        )

        saveJob.cancel()
        saveJob = launch {
            val filterId = withContext(Dispatchers.Default) {
                portalRestApi.put(
                    path = "/config",
                    body = Json.encodeToString(
                        ChangeDeviceConfigFilterDTO.serializer(),
                        ChangeDeviceConfigFilterDTO(
                            configFilter = configFilter,
                            comment = state.comment,
                            parentId = props.newParentId,
                            position = props.newPosition
                        )
                    ),
                    serializer = Int.serializer()
                )
            }

            clearChanges()
            props.onSaved(filterId)
        }
    }

}