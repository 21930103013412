package de.geomobile.frontend.features.userSettings

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.list.mList
import com.ccfraser.muirwik.components.list.mListItem
import com.ccfraser.muirwik.components.list.mListItemText
import com.ccfraser.muirwik.components.list.mListSubheader
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.spacer
import de.geomobile.frontend.utils.*
import kotlinx.css.*
import react.RBuilder
import react.RProps
import react.RState
import react.ReactElement
import styled.css
import styled.styledDiv

fun RBuilder.userSettingsDashboard(
    path: String,
    drawerMenu: ReactElement,
    logout: () -> Unit,
    clearStorage: () -> Unit
) = child(UserSettingsDashboard::class) {
    attrs.path = path
    attrs.drawerMenu = drawerMenu
    attrs.logout = logout
    attrs.clearStorage = clearStorage
}

class UserSettingsDashboard : CComponent<UserSettingsDashboard.Props, RState>() {

    interface Props : RProps {
        var path: String
        var drawerMenu: ReactElement
        var logout: () -> Unit
        var clearStorage: () -> Unit
    }

    override fun RBuilder.render() {
        mAppBar(position = MAppBarPosition.fixed) {
            css(GlobalStyles.appbar)
            mToolbar {
                child(props.drawerMenu)
                mToolbarTitle2("Konto")
            }
        }
        spacer()
        styledDiv {
            css { padding(2.spacingUnits) }
            mGridContainer2(direction = MGridDirection.row) {
                mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                    mCard {
                        css(GlobalStyles.card)
                        mCardContent {
                            css(GlobalStyles.cardListContent)
                            mListSubheader(heading = "Einstellungen")
                            mDivider { }
                            mList {
                                attrs.disablePadding = true

                                mListItem {
                                    attrs.divider = true
                                    attrs.button = false

                                    mListItemText {
                                        mButton(
                                            caption = "Cache leeren",
                                            variant = MButtonVariant.contained,
                                            color = MColor.secondary,
                                            onClick = { props.clearStorage() }
                                        ) {
                                            css { margin(1.spacingUnits, 0.spacingUnits) }
                                            attrs.disableElevation = true
                                        }
                                        mTypography(
                                            text = "Portal wird auf die Werkseinstellungen zurückgesetzt.",
                                            variant = MTypographyVariant.caption,
                                            color = MTypographyColor.textSecondary,
                                            component = "div"
                                        )
                                    }
                                }

                                mListItem {
                                    attrs.divider = false
                                    attrs.button = false

                                    mListItemText {
                                        mButton(
                                            caption = "Abmelden",
                                            variant = MButtonVariant.contained,
                                            color = MColor.inherit,
                                            onClick = { props.logout() }
                                        ) {
                                            css {
                                                margin(1.spacingUnits, 0.spacingUnits)
                                                backgroundColor = Color.red
                                                color = Color.white
                                            }
                                            attrs.disableElevation = true
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}