package portalmodels

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonNames

@Serializable
data class FakeGpsDTO(
    val companyId: String,
    val stationNormal: String,
    val stationIbis: String?,
    val latitude: Double,
    val longitude: Double
)

@Serializable
data class FakeGpsMQTT(
    val stations: List<FakeGpsMQTTEntry>
)

@Serializable
data class FakeGpsMQTTEntry(
    val station: String,
    @SerialName("lat")
    val latitude: Double,
    @SerialName("lon")
    val longitude: Double
)

fun FakeGpsDTO.toMQTTEntry() =
    FakeGpsMQTTEntry(
        station = stationIbis ?: stationNormal,
        latitude = latitude,
        longitude = longitude
    )
