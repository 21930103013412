package de.geomobile.common.errorhandling

import kotlinx.serialization.Serializable

/**
 * This represents a standard REST api error.
 *
 * See http://blog.apigee.com/detail/restful_api_design_what_about_errors/ for the rationale behind this class.
 *
 * @author Adib Saikali
 *
 */
@Serializable
data class ApiError(
    val httpStatus: ApiHttpStatus,
    val apiCode: ApiErrorCodes,
    val userMessage: String,
    val developerMessage: String? = null,
    val validationErrors: List<ApiValidationError>? = null
) {
    companion object {
        val unknown = ApiError(
            httpStatus = ApiHttpStatus.INTERNAL_SERVER_ERROR,
            apiCode = ApiErrorCodes.UNKNOWN_ERROR,
            userMessage = "An unhandled/unknown error occurred",
            developerMessage = "unhandled error"
        )
        val notFound = ApiError(
            httpStatus = ApiHttpStatus.NOT_FOUND,
            apiCode = ApiErrorCodes.NOT_FOUND,
            userMessage = "Requested resource not found",
            developerMessage = "Requested resource not found"
        )
    }
}

/**
 * class to store field validation errors which are attached to a ApiError
 *
 * @author Adib Saikali
 */
@Serializable
data class ApiValidationError(
    var fieldName: String,
    var message: String? = null
)


val ApiError.Companion.authenticationNeeded
    get() = ApiError(
        httpStatus = ApiHttpStatus.UNAUTHORIZED,
        apiCode = ApiErrorCodes.AUTHENTICATION_NEEDED,
        userMessage = "You need to login",
        developerMessage = "The server endpoint requires authentication, user needs to login"
    )

val ApiError.Companion.userBlocked
    get() = ApiError(
        httpStatus = ApiHttpStatus.FORBIDDEN,
        apiCode = ApiErrorCodes.USER_BLOCKED,
        userMessage = "Der Account ist gesperrt",
        developerMessage = "User is blocked"
    )