package portalmodels

import kotlinx.serialization.Serializable

enum class SFTPEncryption(val readableName: String) {
    AES128("AES-128"),
    AES256("AES-256"),
    ARCFOUR("ARC Four"),
    CBC("CBC"),
    CAST128("CAST 128"),
    TRIPLEDES("Triple DES"),
    BLOWFISH("Blowfish"),
    TWOFISH("Twofish");

    companion object {
        private val mapping = values().associateBy(SFTPEncryption::readableName)
        fun fromReadable(readableName: String) = mapping[readableName]
    }
}


@Serializable
data class SFTPProfileDTO(
        var username: String = "",
        var password: String = "",
        var server: String = "",
        var port: Int = 0,
        var path: String = "",
        var encryption: SFTPEncryption = SFTPEncryption.AES128
)

//@Serializable
//data class CompanyProfileSmallDTO(
//        val company: CompanySmall
//)
//
//val CompanyProfileDTO.small get() = CompanyProfileSmallDTO(company = company)