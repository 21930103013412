package portalmodels

import de.geomobile.common.portalmodels.VehicleType
import kotlinx.serialization.Serializable

@Serializable
data class IbisLineAliasListDTO(
    var companyId: String,
    var aliasList: List<IbisLineAliasDTO>
)

@Serializable
data class IbisLineAliasDTO(
    var lineId: String,
    var lineAlias: String?,
    var vehicleType: VehicleType,
    var userSet: Boolean,
    var enabled: Boolean,
)