package de.geomobile.common.errorhandling

import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.Serializer
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder


/**
 * A Rest API should have a simple interface and a simple way for handling errors. This enumeration contains a subset of HTTP status codes
 * that should be used as part of an error response.
 *
 * Think hard about adding another status code to this class keeping things simple is good, especially for a front end developer building a
 * user interface why should they have to deal with numerous error conditions that boil down to its the client's fault, its the server's
 * fault.
 *
 * See http://blog.apigee.com/detail/restful_api_design_what_about_errors/ for the rational behind this class.
 *
 * @author Adib Saikali
 */
@Serializable(with = ApiHttpStatus.Companion::class)
enum class ApiHttpStatus(val statusCode: Int, val description: String) {
    /**
     * `200 OK ` Use when everything went well.
     */
    OK(200, "Ok"),

    /**
     * `204 No Content ` Use when everything went well and there is no content.
     */
    NO_CONTENT(204, "No Content"),

    /**
     * `500 Internal Server Error` Use when there is a problem on the server that is not caused by something that the client did.
     */
    INTERNAL_SERVER_ERROR(500, "Internal Server Error"),

    /**
     * `400 Bad Request` Use when something is wrong in the input form the client, such as missing fields or invalid data.
     */
    BAD_REQUEST(400, "Bad Request"),

    /**
     * `401 Forbidden` When the requester is not logged in, it means that the client should log in before sending the request again.
     */
    UNAUTHORIZED(401, "Unauthorized"),

    /**
     * `403 Forbidden` When when the requester does not have permission to carry out an operation. The requester is logged in but they
     * don't have permission to perform the operation, re-sending the request will still cause a 403.
     */
    FORBIDDEN(403, "Forbidden"),

    /**
     * `404 Forbidden` When the URL the request was received on was not found.
     */
    NOT_FOUND(404, "Not Found");

    @Serializer(forClass = ApiHttpStatus::class)
    companion object : KSerializer<ApiHttpStatus> {

        override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("ApiHttpStatus", PrimitiveKind.STRING)

        override fun serialize(encoder: Encoder, obj: ApiHttpStatus) = encoder.encodeInt(obj.statusCode)

        override fun deserialize(decoder: Decoder): ApiHttpStatus = decoder.decodeInt().toApiHttpStatus()
    }
}

fun Int.toApiHttpStatus() = when (this) {
    200 -> ApiHttpStatus.OK
    204 -> ApiHttpStatus.NO_CONTENT
    400 -> ApiHttpStatus.BAD_REQUEST
    401 -> ApiHttpStatus.UNAUTHORIZED
    403 -> ApiHttpStatus.FORBIDDEN
    404 -> ApiHttpStatus.NOT_FOUND
    in 500..599 -> ApiHttpStatus.INTERNAL_SERVER_ERROR
    else -> error("status $this not defined in ApiHttpStatus")
}
