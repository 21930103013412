package de.geomobile.frontend.features.device

import com.ccfraser.muirwik.components.spacingUnits
import de.geomobile.common.permission.Permissions
import de.geomobile.common.portalmodels.DeviceIdentifier
import de.geomobile.common.portalmodels.Product
import de.geomobile.frontend.features.device.detail.deviceDetail
import de.geomobile.frontend.features.device.detail.deviceQuickCheck
import de.geomobile.frontend.features.device.list.deviceListPage
import de.geomobile.frontend.features.device.stationlist.deviceStationList
import de.geomobile.frontend.utils.authorize
import device.DevicePath
import kotlinx.css.padding
import react.*
import react.router.dom.RouteResultProps
import react.router.dom.route
import styled.css
import styled.styledDiv

fun RBuilder.devices(
    path: String,
    basePath: String,
    drawerMenu: ReactElement,
) = child(DevicesComponent::class) {
    attrs.path = path
    attrs.basePath = basePath
    attrs.drawerMenu = drawerMenu
}

class DevicesComponent : RComponent<DevicesComponent.Props, DevicesComponent.State>() {

    interface Props : RProps {
        var path: String
        var basePath: String
        var drawerMenu: ReactElement
    }

    class State : RState {
        var previousPath: String = ""
    }

    init {
        state = State()
    }

    override fun componentWillReceiveProps(nextProps: Props) {
        if (nextProps.path != props.path)
            setState { previousPath = props.path }
    }

    override fun RBuilder.render() {
        route<RProps>(props.basePath) { routeProps ->
            deviceListPage(
                headless = !routeProps.match.isExact,
                drawerMenu = props.drawerMenu,
                onDeviceClick = { identifier -> routeProps.history.push("${props.basePath}${identifier.toDetailPath()}") }
            )
        }

        authorize(Permissions.DeviceManagement.devicesView) {
            route<IdProp>("${props.basePath}/:id", exact = true) { routeProps ->
                val id: Int = routeProps.match.params.id

                deviceDetailed(DeviceIdentifier.Id(id), routeProps)
            }

            route<SerialNumberProp>(
                "${props.basePath}/:product/serialnumber/:serialNumber",
                exact = true
            ) { routeProps ->
                val product = Product.fromName(routeProps.match.params.product)
                val serialNumber = routeProps.match.params.serialNumber

                deviceDetailed(DeviceIdentifier.SerialNumber(product, serialNumber), routeProps)
            }
            route<CpuIdProp>("${props.basePath}/:product/cpuid/:cpuId", exact = true) { routeProps ->
                val product = Product.fromName(routeProps.match.params.product)
                val cpuId = routeProps.match.params.cpuId

                deviceDetailed(DeviceIdentifier.CpuId(product, cpuId), routeProps)
            }
        }

        route<IdProp>("${props.basePath}/quickcheck/:id", exact = true) { routeProps ->
            val id: Int = routeProps.match.params.id
            deviceDetailed(DeviceIdentifier.Id(id), routeProps, target = DevicePath.DEVICE_QUICK_CHECK)
        }

        route<SerialNumberProp>("${props.basePath}/quickcheck/:product/:serialNumber", exact = true) { routeProps ->
            val product = Product.fromName(routeProps.match.params.product)
            val serialNumber = routeProps.match.params.serialNumber
            deviceDetailed(DeviceIdentifier.SerialNumber(product, serialNumber), routeProps, target = DevicePath.DEVICE_QUICK_CHECK)
        }

        route<IdProp>("${props.basePath}/stationlist/:id", exact = true) { routeProps ->
            val id: Int = routeProps.match.params.id
            deviceDetailed(DeviceIdentifier.Id(id), routeProps, target = DevicePath.DEVICE_STATION_LIST)
        }

        route<SerialNumberProp>("${props.basePath}/stationlist/:product/:serialNumber", exact = true) { routeProps ->
            val product = Product.fromName(routeProps.match.params.product)
            val serialNumber = routeProps.match.params.serialNumber
            deviceDetailed(DeviceIdentifier.SerialNumber(product, serialNumber), routeProps, target = DevicePath.DEVICE_STATION_LIST)
        }
    }

    private fun RBuilder.deviceDetailed(
        identifier: DeviceIdentifier,
        routeProps: RouteResultProps<*>,
        target: DevicePath = DevicePath.DEVICE_DETAIL
    ): ReactElement {
//        val detailFunc = if (isQuickCheck) RBuilder::deviceQuickCheck else RBuilder::deviceDetail
        return styledDiv {
            css { padding(2.spacingUnits) }
            when(target) {
                DevicePath.DEVICE_DETAIL -> deviceDetail(identifier) {
                    if (state.previousPath == props.basePath)
                        routeProps.history.asDynamic().goBack()
                    else
                        routeProps.history.push(props.basePath)
                    Unit
                }
                DevicePath.DEVICE_QUICK_CHECK -> deviceQuickCheck(identifier, routeProps.history)
                DevicePath.DEVICE_STATION_LIST -> deviceStationList(identifier, routeProps.history)
            }
        }
    }

    private interface IdProp : RProps {
        var id: Int
    }

    private interface SerialNumberProp : RProps {
        var product: String
        var serialNumber: Int
    }

    private interface CpuIdProp : RProps {
        var product: String
        var cpuId: String
    }
}

fun DeviceIdentifier.toDetailPath(): String = when (this) {
    is DeviceIdentifier.Id -> "/$id"
    is DeviceIdentifier.SerialNumber -> "/${product.readableName}/serialnumber/$serialNumber"
    is DeviceIdentifier.CpuId -> "/${product.readableName}/cpuid/$cpuId"
    is DeviceIdentifier.VehicleId -> "/abtractXYZ" //TODO: Verlinkung einbinden
    else -> { "/error"}
}