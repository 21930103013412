package de.geomobile.frontend.features.config

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.list.mList
import com.ccfraser.muirwik.components.list.mListItem
import de.geomobile.common.filter.Filter
import de.geomobile.common.filter.queryString
import de.geomobile.common.utils.indentedJson
import de.geomobile.frontend.features.device.list.DeviceListItem
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.utils.*
import de.geomobile.frontend.utils.MGridJustify
import de.geomobile.server.device.config.DeviceConfigFilterHistory
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.*
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.json.JsonObject
import react.RBuilder
import react.RProps
import react.RState
import react.setState
import styled.css

fun RBuilder.deviceConfigHistory(
    id: Int,
    deviceFilter: Map<String, Filter<DeviceListItem, *>>,
) = child(DeviceConfigHistory::class) {
    attrs.id = id
    attrs.deviceFilter = deviceFilter
}

class DeviceConfigHistory : CComponent<DeviceConfigHistory.Props, DeviceConfigHistory.State>() {

    private var loadJob: Job = Job()

    interface Props : RProps {
        var id: Int
        var deviceFilter: Map<String, Filter<DeviceListItem, *>>
    }

    class State(
        var history: List<DeviceConfigFilterHistory> = emptyList(),
        var selected: DeviceConfigFilterHistory? = null,
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        loadFilterHistory()
    }

    private fun loadFilterHistory() {
        loadJob.cancel()
        loadJob = launch {
            val history = withContext(Dispatchers.Default) {
                portalRestApi.get("/config/${props.id}/history", ListSerializer(DeviceConfigFilterHistory.serializer()))
            }

            setState {
                this.history = history.sortedByDescending { it.createdAt.millis }
                this.selected = history.maxByOrNull { it.createdAt.millis }
            }
        }
    }

    override fun RBuilder.render() {
        mGridContainer2(
            justify = MGridJustify.spaceAround,
            spacing = MGridSpacing.spacing2
        ) {
            css {
                margin(0.spacingUnits)
                height = 100.pct
            }

            mGridItem2(MGridBreakpoints2(MGridSize2.Cells5)) {
                css {
                    overflow = Overflow.auto
                    height = 100.pct
                }
                mList {
                    for (entry in state.history) {
                        mListItem(
                            primaryText = "${entry.createdAt.toText()} (${entry.createdBy})",
                            secondaryText = entry.comment.takeIf { it.isNotBlank() },
                            selected = entry == state.selected,
                            onClick = { setState { selected = entry } }
                        )
                    }
                }
            }

            mGridItem2(MGridBreakpoints2(MGridSize2.Cells7)) {
                css {
                    overflow = Overflow.auto
                    height = 100.pct
                }
                val selected = state.selected
                if (selected != null) {
                    mTypography("Name", variant = MTypographyVariant.caption, color = MTypographyColor.textSecondary)
                    mTypography(selected.name, gutterBottom = true)
                    mTypography("Filter", variant = MTypographyVariant.caption, color = MTypographyColor.textSecondary)
                    mTypography(selected.filterRules.queryString(props.deviceFilter), gutterBottom = true)
                    mTypography(
                        "Deactivate Configs",
                        variant = MTypographyVariant.caption,
                        color = MTypographyColor.textSecondary
                    )
                    mTypography(selected.deactivateConfigs.toString(), gutterBottom = true)
                    mTypography(
                        "Config Overlay",
                        variant = MTypographyVariant.caption,
                        color = MTypographyColor.textSecondary
                    )
                    jsonEditor(indentedJson.encodeToString(JsonObject.serializer(), selected.configOverlay))
                }
            }

        }

    }

}