package de.geomobile.common.filter

import kotlinx.serialization.Serializable
import kotlinx.serialization.json.*


@Serializable
data class FilterRules(val rules: List<FilterRule>)

@Serializable
data class FilterRule(
        val filterId: String,
        val rule: Filter.Rule
)

fun FilterRules.queryString(filters: Map<String, Filter<*, *>>): String =
        rules.joinToString(" AND ") { it.queryString(filters) }

fun FilterRule.queryString(filters: Map<String, Filter<*, *>>): String {
    val filter = filters[filterId]

    val operator = filter?.operators?.singleOrNull { it.id == rule.operatorId }

    return when {
        filter == null -> "??$filterId??"
        operator == null -> "${filter.queryName} ??${rule.operatorId}??"
        operator.relational -> {
            val value: String = try {
                if (operator.arrayOperation) {
                    rule.ref.jsonArray.map {
                        when (filter) {
                            is FilterLong -> filter.valueToString(it.jsonPrimitive.long)
                            is FilterDouble -> filter.valueToString(it.jsonPrimitive.double)
                            is FilterString -> filter.valueToString(it.jsonPrimitive.contentOrNull)
                            is FilterEnumerableInt -> filter.valueToString(it.jsonPrimitive.int)
                            is FilterEnumerableString -> filter.valueToString(it.jsonPrimitive.content)
                        }
                    }.toString()
                } else {
                    when (filter) {
                        is FilterLong -> filter.valueToString(rule.ref.jsonPrimitive.long)
                        is FilterDouble -> filter.valueToString(rule.ref.jsonPrimitive.double)
                        is FilterString -> filter.valueToString(rule.ref.jsonPrimitive.contentOrNull)
                        is FilterEnumerableInt -> filter.valueToString(rule.ref.jsonPrimitive.int)
                        is FilterEnumerableString -> filter.valueToString(rule.ref.jsonPrimitive.content)
                    }
                }
            } catch (e: Throwable) {
                "??${rule.ref}??"
            }

            "${filter.queryName} ${operator.operator} $value"
        }
        else -> "${filter.queryName} ${operator.operator}"
    }
}

fun FilterRules.isValid(filters: Map<String, Filter<*, *>>): Boolean = rules.all { it.isValid(filters) }

fun FilterRule.isValid(filters: Map<String, Filter<*, *>>) :Boolean {
    val filter = filters[filterId] ?: return false

    val operator = filter.operators.singleOrNull { it.id == rule.operatorId } ?: return false

    return when {
        operator.relational -> {
            try {
                if (operator.arrayOperation) {
                    rule.ref.jsonArray.map {
                        when (filter) {
                            is FilterLong -> filter.valueToString(it.jsonPrimitive.long)
                            is FilterDouble -> filter.valueToString(it.jsonPrimitive.double)
                            is FilterString -> filter.valueToString(it.jsonPrimitive.contentOrNull)
                            is FilterEnumerableInt -> filter.valueToString(it.jsonPrimitive.int)
                            is FilterEnumerableString -> filter.valueToString(it.jsonPrimitive.content)
                        }
                    }.toString()
                } else {
                    when (filter) {
                        is FilterLong -> filter.valueToString(rule.ref.jsonPrimitive.long)
                        is FilterDouble -> filter.valueToString(rule.ref.jsonPrimitive.double)
                        is FilterString -> filter.valueToString(rule.ref.jsonPrimitive.contentOrNull)
                        is FilterEnumerableInt -> filter.valueToString(rule.ref.jsonPrimitive.int)
                        is FilterEnumerableString -> filter.valueToString(rule.ref.jsonPrimitive.content)
                    }
                }
            } catch (e: Throwable) {
                return false
            }
            true
        }
        else -> true
    }
}