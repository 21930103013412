// Code generated by Wire protocol buffer compiler, do not edit.
// Source: de.geomobile.common.portalmodels.DeviceListItemDelta in portalmodels/device_list_item_dto.proto
package de.geomobile.common.portalmodels

import com.squareup.wire.FieldEncoding
import com.squareup.wire.Message
import com.squareup.wire.ProtoAdapter
import com.squareup.wire.ProtoReader
import com.squareup.wire.ProtoWriter
import com.squareup.wire.ReverseProtoWriter
import com.squareup.wire.Syntax
import com.squareup.wire.Syntax.PROTO_2
import com.squareup.wire.WireField
import com.squareup.wire.`internal`.immutableCopyOf
import com.squareup.wire.`internal`.missingRequiredFields
import com.squareup.wire.`internal`.redactElements
import com.squareup.wire.`internal`.sanitize
import kotlin.Any
import kotlin.AssertionError
import kotlin.Boolean
import kotlin.Deprecated
import kotlin.DeprecationLevel
import kotlin.Int
import kotlin.Long
import kotlin.Nothing
import kotlin.String
import kotlin.Unit
import kotlin.collections.List
import kotlin.jvm.JvmField
import okio.ByteString

public class DeviceListItemDelta(
  companies: List<Company> = emptyList(),
  newOrModified: List<DeviceListItemDTO> = emptyList(),
  deleted: List<Int> = emptyList(),
  vehicleProfiles: List<VehicleProfile> = emptyList(),
  unknownFields: ByteString = ByteString.EMPTY
) : Message<DeviceListItemDelta, Nothing>(ADAPTER, unknownFields) {
  @field:WireField(
    tag = 1,
    adapter = "de.geomobile.common.portalmodels.DeviceListItemDelta${'$'}Company#ADAPTER",
    label = WireField.Label.REPEATED
  )
  public val companies: List<Company> = immutableCopyOf("companies", companies)

  @field:WireField(
    tag = 2,
    adapter = "de.geomobile.common.portalmodels.DeviceListItemDTO#ADAPTER",
    label = WireField.Label.REPEATED
  )
  public val newOrModified: List<DeviceListItemDTO> = immutableCopyOf("newOrModified",
      newOrModified)

  @field:WireField(
    tag = 3,
    adapter = "com.squareup.wire.ProtoAdapter#INT32",
    label = WireField.Label.REPEATED
  )
  public val deleted: List<Int> = immutableCopyOf("deleted", deleted)

  @field:WireField(
    tag = 4,
    adapter = "de.geomobile.common.portalmodels.DeviceListItemDelta${'$'}VehicleProfile#ADAPTER",
    label = WireField.Label.REPEATED
  )
  public val vehicleProfiles: List<VehicleProfile> = immutableCopyOf("vehicleProfiles",
      vehicleProfiles)

  @Deprecated(
    message = "Shouldn't be used in Kotlin",
    level = DeprecationLevel.HIDDEN
  )
  public override fun newBuilder(): Nothing = throw
      AssertionError("Builders are deprecated and only available in a javaInterop build; see https://square.github.io/wire/wire_compiler/#kotlin")

  public override fun equals(other: Any?): Boolean {
    if (other === this) return true
    if (other !is DeviceListItemDelta) return false
    if (unknownFields != other.unknownFields) return false
    if (companies != other.companies) return false
    if (newOrModified != other.newOrModified) return false
    if (deleted != other.deleted) return false
    if (vehicleProfiles != other.vehicleProfiles) return false
    return true
  }

  public override fun hashCode(): Int {
    var result = super.hashCode
    if (result == 0) {
      result = unknownFields.hashCode()
      result = result * 37 + companies.hashCode()
      result = result * 37 + newOrModified.hashCode()
      result = result * 37 + deleted.hashCode()
      result = result * 37 + vehicleProfiles.hashCode()
      super.hashCode = result
    }
    return result
  }

  public override fun toString(): String {
    val result = mutableListOf<String>()
    if (companies.isNotEmpty()) result += """companies=$companies"""
    if (newOrModified.isNotEmpty()) result += """newOrModified=$newOrModified"""
    if (deleted.isNotEmpty()) result += """deleted=$deleted"""
    if (vehicleProfiles.isNotEmpty()) result += """vehicleProfiles=$vehicleProfiles"""
    return result.joinToString(prefix = "DeviceListItemDelta{", separator = ", ", postfix = "}")
  }

  public fun copy(
    companies: List<Company> = this.companies,
    newOrModified: List<DeviceListItemDTO> = this.newOrModified,
    deleted: List<Int> = this.deleted,
    vehicleProfiles: List<VehicleProfile> = this.vehicleProfiles,
    unknownFields: ByteString = this.unknownFields
  ): DeviceListItemDelta = DeviceListItemDelta(companies, newOrModified, deleted, vehicleProfiles,
      unknownFields)

  public companion object {
    @JvmField
    public val ADAPTER: ProtoAdapter<DeviceListItemDelta> = object :
        ProtoAdapter<DeviceListItemDelta>(
      FieldEncoding.LENGTH_DELIMITED, 
      DeviceListItemDelta::class, 
      "type.googleapis.com/de.geomobile.common.portalmodels.DeviceListItemDelta", 
      PROTO_2, 
      null, 
      "portalmodels/device_list_item_dto.proto"
    ) {
      public override fun encodedSize(`value`: DeviceListItemDelta): Int {
        var size = value.unknownFields.size
        size += Company.ADAPTER.asRepeated().encodedSizeWithTag(1, value.companies)
        size += DeviceListItemDTO.ADAPTER.asRepeated().encodedSizeWithTag(2, value.newOrModified)
        size += ProtoAdapter.INT32.asRepeated().encodedSizeWithTag(3, value.deleted)
        size += VehicleProfile.ADAPTER.asRepeated().encodedSizeWithTag(4, value.vehicleProfiles)
        return size
      }

      public override fun encode(writer: ProtoWriter, `value`: DeviceListItemDelta): Unit {
        Company.ADAPTER.asRepeated().encodeWithTag(writer, 1, value.companies)
        DeviceListItemDTO.ADAPTER.asRepeated().encodeWithTag(writer, 2, value.newOrModified)
        ProtoAdapter.INT32.asRepeated().encodeWithTag(writer, 3, value.deleted)
        VehicleProfile.ADAPTER.asRepeated().encodeWithTag(writer, 4, value.vehicleProfiles)
        writer.writeBytes(value.unknownFields)
      }

      public override fun encode(writer: ReverseProtoWriter, `value`: DeviceListItemDelta): Unit {
        writer.writeBytes(value.unknownFields)
        VehicleProfile.ADAPTER.asRepeated().encodeWithTag(writer, 4, value.vehicleProfiles)
        ProtoAdapter.INT32.asRepeated().encodeWithTag(writer, 3, value.deleted)
        DeviceListItemDTO.ADAPTER.asRepeated().encodeWithTag(writer, 2, value.newOrModified)
        Company.ADAPTER.asRepeated().encodeWithTag(writer, 1, value.companies)
      }

      public override fun decode(reader: ProtoReader): DeviceListItemDelta {
        val companies = mutableListOf<Company>()
        val newOrModified = mutableListOf<DeviceListItemDTO>()
        val deleted = mutableListOf<Int>()
        val vehicleProfiles = mutableListOf<VehicleProfile>()
        val unknownFields = reader.forEachTag { tag ->
          when (tag) {
            1 -> companies.add(Company.ADAPTER.decode(reader))
            2 -> newOrModified.add(DeviceListItemDTO.ADAPTER.decode(reader))
            3 -> deleted.add(ProtoAdapter.INT32.decode(reader))
            4 -> vehicleProfiles.add(VehicleProfile.ADAPTER.decode(reader))
            else -> reader.readUnknownField(tag)
          }
        }
        return DeviceListItemDelta(
          companies = companies,
          newOrModified = newOrModified,
          deleted = deleted,
          vehicleProfiles = vehicleProfiles,
          unknownFields = unknownFields
        )
      }

      public override fun redact(`value`: DeviceListItemDelta): DeviceListItemDelta = value.copy(
        companies = value.companies.redactElements(Company.ADAPTER),
        newOrModified = value.newOrModified.redactElements(DeviceListItemDTO.ADAPTER),
        vehicleProfiles = value.vehicleProfiles.redactElements(VehicleProfile.ADAPTER),
        unknownFields = ByteString.EMPTY
      )
    }

    private const val serialVersionUID: Long = 0L
  }

  public class Company(
    @field:WireField(
      tag = 1,
      adapter = "com.squareup.wire.ProtoAdapter#STRING",
      label = WireField.Label.REQUIRED
    )
    public val id: String,
    @field:WireField(
      tag = 2,
      adapter = "com.squareup.wire.ProtoAdapter#STRING",
      label = WireField.Label.REQUIRED
    )
    public val name: String,
    unknownFields: ByteString = ByteString.EMPTY
  ) : Message<Company, Nothing>(ADAPTER, unknownFields) {
    @Deprecated(
      message = "Shouldn't be used in Kotlin",
      level = DeprecationLevel.HIDDEN
    )
    public override fun newBuilder(): Nothing = throw
        AssertionError("Builders are deprecated and only available in a javaInterop build; see https://square.github.io/wire/wire_compiler/#kotlin")

    public override fun equals(other: Any?): Boolean {
      if (other === this) return true
      if (other !is Company) return false
      if (unknownFields != other.unknownFields) return false
      if (id != other.id) return false
      if (name != other.name) return false
      return true
    }

    public override fun hashCode(): Int {
      var result = super.hashCode
      if (result == 0) {
        result = unknownFields.hashCode()
        result = result * 37 + id.hashCode()
        result = result * 37 + name.hashCode()
        super.hashCode = result
      }
      return result
    }

    public override fun toString(): String {
      val result = mutableListOf<String>()
      result += """id=${sanitize(id)}"""
      result += """name=${sanitize(name)}"""
      return result.joinToString(prefix = "Company{", separator = ", ", postfix = "}")
    }

    public fun copy(
      id: String = this.id,
      name: String = this.name,
      unknownFields: ByteString = this.unknownFields
    ): Company = Company(id, name, unknownFields)

    public companion object {
      @JvmField
      public val ADAPTER: ProtoAdapter<Company> = object : ProtoAdapter<Company>(
        FieldEncoding.LENGTH_DELIMITED, 
        Company::class, 
        "type.googleapis.com/de.geomobile.common.portalmodels.DeviceListItemDelta.Company", 
        PROTO_2, 
        null, 
        "portalmodels/device_list_item_dto.proto"
      ) {
        public override fun encodedSize(`value`: Company): Int {
          var size = value.unknownFields.size
          size += ProtoAdapter.STRING.encodedSizeWithTag(1, value.id)
          size += ProtoAdapter.STRING.encodedSizeWithTag(2, value.name)
          return size
        }

        public override fun encode(writer: ProtoWriter, `value`: Company): Unit {
          ProtoAdapter.STRING.encodeWithTag(writer, 1, value.id)
          ProtoAdapter.STRING.encodeWithTag(writer, 2, value.name)
          writer.writeBytes(value.unknownFields)
        }

        public override fun encode(writer: ReverseProtoWriter, `value`: Company): Unit {
          writer.writeBytes(value.unknownFields)
          ProtoAdapter.STRING.encodeWithTag(writer, 2, value.name)
          ProtoAdapter.STRING.encodeWithTag(writer, 1, value.id)
        }

        public override fun decode(reader: ProtoReader): Company {
          var id: String? = null
          var name: String? = null
          val unknownFields = reader.forEachTag { tag ->
            when (tag) {
              1 -> id = ProtoAdapter.STRING.decode(reader)
              2 -> name = ProtoAdapter.STRING.decode(reader)
              else -> reader.readUnknownField(tag)
            }
          }
          return Company(
            id = id ?: throw missingRequiredFields(id, "id"),
            name = name ?: throw missingRequiredFields(name, "name"),
            unknownFields = unknownFields
          )
        }

        public override fun redact(`value`: Company): Company = value.copy(
          unknownFields = ByteString.EMPTY
        )
      }

      private const val serialVersionUID: Long = 0L
    }
  }

  public class VehicleProfile(
    @field:WireField(
      tag = 1,
      adapter = "com.squareup.wire.ProtoAdapter#STRING",
      label = WireField.Label.REQUIRED
    )
    public val id: String,
    @field:WireField(
      tag = 2,
      adapter = "com.squareup.wire.ProtoAdapter#STRING",
      label = WireField.Label.REQUIRED
    )
    public val name: String,
    unknownFields: ByteString = ByteString.EMPTY
  ) : Message<VehicleProfile, Nothing>(ADAPTER, unknownFields) {
    @Deprecated(
      message = "Shouldn't be used in Kotlin",
      level = DeprecationLevel.HIDDEN
    )
    public override fun newBuilder(): Nothing = throw
        AssertionError("Builders are deprecated and only available in a javaInterop build; see https://square.github.io/wire/wire_compiler/#kotlin")

    public override fun equals(other: Any?): Boolean {
      if (other === this) return true
      if (other !is VehicleProfile) return false
      if (unknownFields != other.unknownFields) return false
      if (id != other.id) return false
      if (name != other.name) return false
      return true
    }

    public override fun hashCode(): Int {
      var result = super.hashCode
      if (result == 0) {
        result = unknownFields.hashCode()
        result = result * 37 + id.hashCode()
        result = result * 37 + name.hashCode()
        super.hashCode = result
      }
      return result
    }

    public override fun toString(): String {
      val result = mutableListOf<String>()
      result += """id=${sanitize(id)}"""
      result += """name=${sanitize(name)}"""
      return result.joinToString(prefix = "VehicleProfile{", separator = ", ", postfix = "}")
    }

    public fun copy(
      id: String = this.id,
      name: String = this.name,
      unknownFields: ByteString = this.unknownFields
    ): VehicleProfile = VehicleProfile(id, name, unknownFields)

    public companion object {
      @JvmField
      public val ADAPTER: ProtoAdapter<VehicleProfile> = object : ProtoAdapter<VehicleProfile>(
        FieldEncoding.LENGTH_DELIMITED, 
        VehicleProfile::class, 
        "type.googleapis.com/de.geomobile.common.portalmodels.DeviceListItemDelta.VehicleProfile", 
        PROTO_2, 
        null, 
        "portalmodels/device_list_item_dto.proto"
      ) {
        public override fun encodedSize(`value`: VehicleProfile): Int {
          var size = value.unknownFields.size
          size += ProtoAdapter.STRING.encodedSizeWithTag(1, value.id)
          size += ProtoAdapter.STRING.encodedSizeWithTag(2, value.name)
          return size
        }

        public override fun encode(writer: ProtoWriter, `value`: VehicleProfile): Unit {
          ProtoAdapter.STRING.encodeWithTag(writer, 1, value.id)
          ProtoAdapter.STRING.encodeWithTag(writer, 2, value.name)
          writer.writeBytes(value.unknownFields)
        }

        public override fun encode(writer: ReverseProtoWriter, `value`: VehicleProfile): Unit {
          writer.writeBytes(value.unknownFields)
          ProtoAdapter.STRING.encodeWithTag(writer, 2, value.name)
          ProtoAdapter.STRING.encodeWithTag(writer, 1, value.id)
        }

        public override fun decode(reader: ProtoReader): VehicleProfile {
          var id: String? = null
          var name: String? = null
          val unknownFields = reader.forEachTag { tag ->
            when (tag) {
              1 -> id = ProtoAdapter.STRING.decode(reader)
              2 -> name = ProtoAdapter.STRING.decode(reader)
              else -> reader.readUnknownField(tag)
            }
          }
          return VehicleProfile(
            id = id ?: throw missingRequiredFields(id, "id"),
            name = name ?: throw missingRequiredFields(name, "name"),
            unknownFields = unknownFields
          )
        }

        public override fun redact(`value`: VehicleProfile): VehicleProfile = value.copy(
          unknownFields = ByteString.EMPTY
        )
      }

      private const val serialVersionUID: Long = 0L
    }
  }
}
