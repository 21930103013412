package de.geomobile.common.permission

import de.geomobile.common.feature.Features

object Permissions {
    val permissionGroups: Set<PermissionGroup> = setOf(
        AdminPermissions,
        UserManagement,
        CompanyManagement,
        RoleManagement,
        VehicleProfileManagement,
        CompanyProfileManagement,
        MapManagement,
        StatisticsManagement,
        DeviceManagement
    )
    val allPermissions: Set<Permission> = permissionGroups.flatMap { it.permissions }.toSet()

    fun getById(id: String) = allPermissions.first { it.id == id }
    fun findById(id: String) = allPermissions.firstOrNull { it.id == id }


    object AdminPermissions : PermissionGroup("ADMIN", "Administrator") {
        val adminControlsView = permission("ADMIN_CONTROLS_VIEW", "Admin Einstellungen sehen")
        val internalAccess = permission("INTERNAL_ACCESS", "Zugriff auf ivanto-interne Funktionen", false)
        val debugAccess = permission("DEBUG_ACCESS", "Zugriff auf Debugging-Funktionen", false)
        val resetAccess = permission("RESET_ACCESS", "Zugriff auf die Factory Reset Funtkion für Boxen", false)
    }

    object UserManagement : PermissionGroup("USER_MGMT", "Benutzerverwaltung") {
        val notRestrictedToCompany =
            permission("NOT_RESTRICTED_TO_COMPANY", "Zugriff auf Benutzer aller Unternehmen", false)

        val usersView = permission("USERS_VIEW", "Benutzer sehen")
        val usersEdit = permission("USERS_EDIT", "Benutzer bearbeiten")
    }

    object CompanyManagement : PermissionGroup("COMPANY_MGMT", "Unternehmensverwaltung", false) {
        val companiesView = permission("COMPANIES_VIEW", "Unternehmen sehen", false)
        val companiesEdit = permission("COMPANIES_EDIT", "Unternehmen bearbeiten", false)
        val stageEdit = permission("STAGE_EDIT", "Phasen zuweisen", false)
        val featureEdit = permission("FEATURE_EDIT", "Feature zuweisen", false)
        val textToSpeechEdit = permission("TEXT_TO_SPEECH_EDIT", "Text to Speech zuweisen", false)

    }

    object RoleManagement : PermissionGroup("ROLE_MGMT", "Rollenverwaltung") {
        val notRestrictedToCompany =
            permission("NOT_RESTRICTED_TO_COMPANY", "Zugriff auf Rollen aller Unternehmen", false)

        val rolesView = permission("ROLES_VIEW", "Rollen sehen")
        val rolesEdit = permission("ROLES_EDIT", "Rollen bearbeiten")
    }

    object VehicleProfileManagement : PermissionGroup(
        "VEHICLE_PROFILE_MGMT",
        "Fahrzeugprofilverwaltung",
        requiredFeatures = setOf(Features.VehicleProfileFeatures.vehicleProfiles)
    ) {
        val notRestrictedToCompany =
            permission("NOT_RESTRICTED_TO_COMPANY", "Zugriff auf Fahrzeugprofile aller Unternehmen", false)
        val profilesView =
            permission("PROFILES_VIEW", "Fahrzeugprofile sehen", Features.VehicleProfileFeatures.vehicleProfiles)
        val profilesEdit =
            permission("PROFILES_EDIT", "Fahrzeugprofile bearbeiten", Features.VehicleProfileFeatures.vehicleProfiles)
        val profilesAssign =
            permission("PROFILES_ASSIGN", "Fahrzeugprofile zuweisen", Features.VehicleProfileFeatures.vehicleProfiles)
        val fmsProfileEdit =
            permission("FMS_PROFILE_EDIT", "FMS Profil bearbeiten", Features.VehicleProfileFeatures.fmsProfiles)
        val fmsProfileView =
            permission("FMS_PROFILE_VIEW", "FMS Profil sehen", Features.VehicleProfileFeatures.fmsProfiles)
        val ibisProfileView = permission("IBIS_PROFILE_VIEW", "IBIS Profil sehen", Features.VehicleProfileFeatures.ibisProfiles)
        val ibisProfileEdit = permission("IBIS_PROFILE_EDIT", "IBIS Profil bearbeiten", Features.VehicleProfileFeatures.ibisProfiles)
        val wiringProfileView = permission("WIRING_PROFILE_VIEW", "Verkabelungsprofil sehen", Features.VehicleProfileFeatures.wiringProfiles)
        val wiringProfileEdit = permission("WIRING_PROFILE_EDIT", "Verkabelungsprofil bearbeiten", Features.VehicleProfileFeatures.wiringProfiles)
    }

    object CompanyProfileManagement : PermissionGroup(
        "COMPANY_PROFILE_MGMT",
        "Unternehmensprofilverwaltung",
        requiredFeatures = setOf(Features.CompanyFeatures.companyProfiles)
    ) {
        val notRestrictedToCompany =
            permission("NOT_RESTRICTED_TO_COMPANY", "Zugriff auf Unternehmensprofile aller Unternehmen", false)

        val profileView =
            permission("PROFILES_VIEW", "Unternehmensprofil sehen", Features.CompanyFeatures.companyProfiles)
        val profileEdit =
            permission("PROFILES_EDIT", "Unternehmensprofil bearbeiten", Features.CompanyFeatures.companyProfiles)

        val ibisLineView = permission("IBIS_LINE_VIEW", "IBIS Linien Liste sehen", Features.CompanyFeatures.companyProfiles)
        val ibisLineEdit = permission("IBIS_LINE_EDIT", "IBIS Linien Liste bearbeiten", Features.CompanyFeatures.companyProfiles)
        val fshView = permission("FSH_VIEW", "Fahrzeughistorie sehen", Features.CompanyFeatures.companyProfiles)
        val depotList = permission("DEPOT_LIST", "Betriebshöfe sehen", Features.CompanyFeatures.companyProfiles)
        val fakeGPSEdit = permission("FAKE_GPS", "GPS Tunnel Koordinaten bearbeiten", Features.CompanyFeatures.fakeGps)
        val ticketApiView = permission("TICKET_API", "Ticket API sehen", Features.CompanyFeatures.companyProfiles)
        val ticketApiEdit = permission("TICKET_API_EDIT", "Ticket API bearbeiten", Features.CompanyFeatures.companyProfiles)
    }

    object StatisticsManagement : PermissionGroup(
        "STATISTICS_MGMT", "Statistikverwaltung", requiredFeatures = setOf(Features.StatisticsFeatures.statistics)
    ) {
        val statView = permission("STATISTICS", "Statistiken sehen", Features.StatisticsFeatures.statistics)
        val wifiStatView = permission(
            "WIFI_STAT_VIEW",
            "WIFI Statistik sehen",
            Features.StatisticsFeatures.statistics,
            Features.StatisticsFeatures.wifiStatistics
        )
        val lteStatView = permission(
            "LTE_STAT_VIEW",
            "LTE Statistik sehen",
            Features.StatisticsFeatures.statistics,
            Features.StatisticsFeatures.lteStatistics
        )
        val passengerCountView = permission(
            "PASSENGER_COUNT_STAT_VIEW",
            "Fahrgastzählung sehen",
            Features.StatisticsFeatures.statistics,
            Features.StatisticsFeatures.passengerCount
        )
        val dashboard = permission(
            "DASHBOARD_VIEW",
            "Dashboard sehen",
            Features.StatisticsFeatures.statistics,
            Features.StatisticsFeatures.dashboard
        )
    }

    object MapManagement :
        PermissionGroup("MAP_MGMT", "Kartenverwaltung", requiredFeatures = setOf(Features.MapFeatures.map)) {
        val mapView = permission("MAP", "Karte sehen", Features.MapFeatures.map)
    }

    object DeviceManagement : PermissionGroup("DEVICE_MGMT", "Geräteverwaltung") {
        val devicesView = permission("DEVICES_VIEW", "Geräte sehen")
        val deviceStatus = permission("DEVICE_STATUS", "Geräteinformationen")
        val map = permission("MAP", "Karte und GPS", Features.DeviceFeatures.map)
        val vehicleOccupancy =
            permission("VEHICLE_OCCUPANCY", "Fahrzeugauslastung", Features.DeviceFeatures.vehicleOccupancy)
        val software = permission("SOFTWARE", "Softwareversion", Features.DeviceFeatures.software)
        val softwareDetail =
            permission("SOFTWARE_DETAIL", "Softwaredetails (einzelne Pakete)", Features.DeviceFeatures.softwareDetail)
        val selfTest = permission("SELF_TEST", "Zugriff auf Selbsttest", Features.DeviceFeatures.selfTest)
        val individualParts = permission("INDIVIDUAL_PARTS", "Erfassung Ersatzteilen", Features.DeviceFeatures.individualParts)
        val audio = permission("AUDIO", "Audioeinstellungen sehen", Features.DeviceFeatures.audioConfig)
        val audioConfig = permission("AUDIO_CONFIG", "Audioeinstellungen ändern", Features.DeviceFeatures.audioConfig)

        val cda = permission("CDA", "CDA-Informationen sehen", Features.CompanyFeatures.cda)
        val ela = permission("ELA", "ELA-Konfiguration sehen", Features.CompanyFeatures.ela)
        val ela_config = permission("ELA_CONFIG", "ELA konfigurieren", Features.CompanyFeatures.ela)
        val busAccess = permission("BUS_ACCESS", "IBIS Info", Features.DeviceFeatures.busAccess)
        val busAccessV18 = permission("BUS_ACCESS_V18", "IBIS Info (v18)", Features.DeviceFeatures.busAccessV18)
        val busAccessV18Write =
            permission("BUS_ACCESS_V18_WRITE", "IBIS Einstellungen (v18)", Features.DeviceFeatures.busAccessV18)
        val fms = permission("FMS", "FMS", Features.VehicleProfileFeatures.fmsProfiles)
        val cellular = permission("CELLULAR", "Mobilfunk", Features.DeviceFeatures.cellular)
        val wifi = permission("WIFI", "WIFI", Features.DeviceFeatures.wifi)
        val lan = permission("LAN", "LAN", Features.DeviceFeatures.lan)
        val repairHistoryUser = permission(
            "REPAIR_HISTORY_USER", "Zugriff auf Nutzeransicht der Reparaturen", Features.DeviceFeatures.repairHistory
        )

        //        val repairHistoryAdmin = permission("REPAIR_HISTORY_ADMIN", "Zugriff auf Adminansicht der Reparaturen", Features.DeviceFeatures.repairHistory)
        val cableCheck =
            permission("CABLE_CHECK", "Zugriff auf die Überprüfung der Verkabelung", Features.DeviceFeatures.cableCheck)
        val deviceListExport =
            permission("DEVICE_LIST_EXPORT", "Geräteliste exportieren", Features.DeviceFeatures.deviceListExport)
        val ibisSniffer = permission("IBIS_SNIFFER", "IBIS Geräteerkennung", Features.DeviceFeatures.ibisSniffer)

        val delete = permission("DELETE", "Geräte löschen", visibleForCompany = false)
        val vehicleIdWrite = permission("VEHICLE_ID_WRITE", "Fahrzeugnummer ändern")
        val vehicleTypeWrite = permission("VEHICLE_TYPE_WRITE", "Fahrzeugtyp ändern")
        val vehicleDescriptionWrite = permission("VEHICLE_DESCRIPTION_WRITE", "Fahrzeugbeschreibung ändern")
        val tripInfo = permission("TRIP_INFO", "IBIS IP Fahrgastinformationen anzeigen")
        val trainSetInfo = permission("TRAIN_SET_INFO", "IBIS IP Zugverbandsinformationen anzeigen")

    }
}