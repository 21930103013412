package de.geomobile.frontend.features.companyProfile

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardActions
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.list.mListSubheader
import com.ccfraser.muirwik.components.menu.mMenuItem
import com.ccfraser.muirwik.components.table.mTable
import com.ccfraser.muirwik.components.table.mTableBody
import com.ccfraser.muirwik.components.table.mTableHead
import de.geomobile.common.permission.Permissions
import de.geomobile.common.portalmodels.UserDTO
import de.geomobile.common.portalmodels.VehicleType
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.utils.*
import kotlinx.browser.localStorage
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.*
import kotlinx.serialization.json.Json
import org.w3c.dom.get
import portalmodels.IbisLineAliasDTO
import portalmodels.IbisLineAliasListDTO
import react.RBuilder
import react.RProps
import react.RState
import react.setState
import styled.css
import styled.styledDiv

fun RBuilder.companyProfileIBISLine() = child(CompanyProfileIBISLine::class) {}

class CompanyProfileIBISLine : CComponent<CompanyProfileIBISLine.Props, CompanyProfileIBISLine.State>() {

    private var companyJob: Job = Job()
    private var dataJob: Job = Job()
    private var fetchIbisProfilesJob: Job = Job()

    interface Props : RProps {}

    class State(
        var company: String? = null,
        var profileActive: Boolean = false,
        var lineAlias: IbisLineAliasListDTO? = null,
        var selectedIndex: Int = -1,
        var saveSuccess: Boolean = false
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        launch {
            loadCompany()
        }.invokeOnCompletion {
            state.company?.let {
                fetchAlias(it)
            }
        }
    }

    suspend fun loadCompany() {
        val companyId =
            if (isAuthorized(Permissions.VehicleProfileManagement.notRestrictedToCompany) && localStorage["CompanyProfile"] != null) {
                localStorage["CompanyProfile"]
            } else {
                withContext(Dispatchers.Default) {
                    portalRestApi.get("/user", UserDTO.serializer())
                }.company.id
            }
        setState {
            company = companyId
        }
    }

    private fun fetchAlias(companyId: String) {
        fetchIbisProfilesJob.cancel()
        fetchIbisProfilesJob = launch {
            val lineAlias = try {
                portalRestApi.get(
                    path = "/companyprofile/${companyId}/ibis/linealias",
                    serializer = IbisLineAliasListDTO.serializer()
                )
            } catch (e: Exception) {
                println("no alias found")
                null
            }
            setState {
                lineAlias?.let {
                    this.lineAlias = it
                }
            }
        }
    }

    private fun saveLineAlias(companyId: String, lineAlias: IbisLineAliasListDTO) {
        launch {
            val toSave = lineAlias.copy(aliasList =
                lineAlias.aliasList.map {
                    if(it.enabled)
                        it.copy(userSet = true)
                    else
                        it
                }
            )
            val bodyProfile = Json.encodeToJsonElement(IbisLineAliasListDTO.serializer(), toSave)
            portalRestApi.put("/companyprofile/${companyId}/ibis/linealias", bodyProfile)

            setState { saveSuccess = true }
        }.invokeOnCompletion {
            fetchAlias(companyId)
        }
    }

    override fun RBuilder.render() {
        mGridContainer2(direction = MGridDirection.row) {
            mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                mCard {
                    css { marginBottom = 2.spacingUnits }
                    mCardContent {
                        css(GlobalStyles.cardBoxContent)
                        mTypography(
                            text = "Diese Liste wird von den Apps verwendet, um alle verfügbaren Linien anzuzeigen. Sie dient zum Filtern von dargestellen Fahrzeugen zum Beispiel auf der Live-Karte oder im Bus-Radar.\n" +
                                "\n" +
                                "Zusätzlich kann diese Liste verwendet werden, um Linien-Bezeichnungen, die per IBIS empfangen wurden, zu ersetzen. (Da einige Telegramme die Linie nur als numerischen Wert übertragen, kann hier eine Ersetzung eingetragen werden: z.B. IBIS Name: 11 -> Anzeige Name: U11).",
                            variant = MTypographyVariant.body2
                        ) { css {
                            maxWidth = 800.px
                            padding(2.spacingUnits)
                        }}
                    }
                }
                mCard {
                    css(GlobalStyles.card)
                    mCardContent {
                        css(GlobalStyles.cardBoxContent)

                        mListSubheader(
                            heading = "Linien Liste"
                        )
                        mDivider { }
                        styledDiv {
                            css {
                                padding(2.spacingUnits)
                                maxWidth = 600.px
                            }

                            mTable {
                                mTableHead {
                                    mTableRowSlim {
                                        mTableCellSlim { css { width = 6.pct } }
                                        mTableCellSlim { +"Anzeige Name"; css { width = 22.pct; textAlign = TextAlign.center } }
                                        mTableCellSlim { +"IBIS Name"; css { width = 22.pct; textAlign = TextAlign.center } }
                                        mTableCellSlim { +"Fahrzeugtyp"; css { width = 25.pct; textAlign = TextAlign.center } }
                                        mTableCellSlim { +"Aktiv"; css { width = 12.pct; textAlign = TextAlign.center } }
                                        mTableCellSlim { +"Ausgelesen"; css { width = 12.pct; textAlign = TextAlign.center } }
                                    }
                                }
                                mTableBody {
                                    state.lineAlias?.aliasList?.let { aliasList ->
                                        aliasList
                                            .plus(IbisLineAliasDTO("", "", VehicleType.NONE, false, false))
                                            .forEachIndexed { index, alias ->
                                                mTableRowSlim {
                                                    mTableCellSlim {
                                                        css { width = 40.px }
                                                        mIconButtonNoTranslate(
                                                            iconName = "delete",
                                                            onClick = {
                                                                setState {
                                                                    lineAlias?.aliasList = aliasList.minus(alias)
                                                                }
                                                            }
                                                        ) { css { padding(0.px) } }
                                                    }
                                                    mTableCellSlim {
                                                        css { padding(0.spacingUnits) }
                                                        mTextField(
                                                            value = alias.lineAlias ?: "",
                                                            label = "",
                                                            onChange = { event ->
                                                                setState {
                                                                    val result = event.targetValue as String
                                                                    if (index >= (aliasList.size))
                                                                        lineAlias?.aliasList =
                                                                            aliasList.plus(alias.copy(lineAlias = result, userSet = true))
                                                                    else
                                                                        lineAlias?.aliasList?.get(index)?.lineAlias =
                                                                            result
                                                                }
                                                            }
                                                        ) { css { margin(0.px, 0.px, 8.px, 8.px) }}
                                                    }
                                                    mTableCellSlim {
                                                        css { padding(0.spacingUnits) }
                                                        mTextField(
                                                            value = alias.lineId,
                                                            label = "",
                                                            onChange = { event ->
                                                                setState {
                                                                    val result = event.targetValue as String
                                                                    if (index >= (aliasList.size))
                                                                        lineAlias?.aliasList =
                                                                            aliasList.plus(alias.copy(lineId = result, userSet = true))
                                                                    else
                                                                        lineAlias?.aliasList?.get(index)?.lineId =
                                                                            result
                                                                }
                                                            }
                                                        ) { css { margin(0.px, 0.px, 8.px, 8.px) }}
                                                    }
                                                    mTableCellSlim {
                                                        css { padding(0.spacingUnits) }
                                                        mSelect(
                                                            value = alias.vehicleType,
                                                            onChange = { event, _ ->
                                                                setState {
                                                                    val result = event.targetValue as String
                                                                    when {
                                                                        index >= (aliasList.size) -> lineAlias?.aliasList =
                                                                            aliasList.plus(
                                                                                alias.copy(
                                                                                    vehicleType = VehicleType.valueOf(
                                                                                        result
                                                                                    ),
                                                                                    userSet = true
                                                                                )
                                                                            )

                                                                        else -> lineAlias?.aliasList?.get(index)?.vehicleType =
                                                                            VehicleType.valueOf(result)
                                                                    }
                                                                }
                                                            }
                                                        ) {
                                                            css {
                                                                width = 90.pct
                                                                marginBottom = 8.px
                                                                marginLeft = 8.px
                                                            }
                                                            VehicleType.values().forEach {
                                                                mMenuItem(
                                                                    value = it.name,
                                                                    primaryText = it.readableName
                                                                )
                                                            }
                                                        }
                                                    }
                                                    mTableCellSlim {
                                                        css { padding(0.spacingUnits) }
                                                        mCheckbox(
                                                            checked = alias.enabled,
                                                            onChange = { _, checked ->
                                                                setState {
                                                                    if (index >= (aliasList.size))
                                                                        lineAlias?.aliasList =
                                                                            aliasList.plus(alias.copy(enabled = checked, userSet = true))
                                                                    else
                                                                        lineAlias?.aliasList?.get(index)?.enabled =
                                                                            checked
                                                                }
                                                            }
                                                        ) { css {
                                                            width = 100.pct
                                                            padding(0.px)
                                                        }}
                                                    }
                                                    mTableCellSlim {
                                                        css { padding(0.spacingUnits) }
                                                        mCheckbox(
                                                            checked = !alias.userSet,
                                                            disabled = true,
                                                        ) { css {
                                                            width = 100.pct
                                                            padding(0.px)
                                                        }}
                                                    }
                                                }
                                            }
                                    }
                                }
                            }
                            mCardActions {
                                css { padding(2.spacingUnits) }
                                styledDiv {
                                    css {
                                        justifyItems = JustifyItems.center
                                        display = Display.flex
                                        alignItems = Align.center
                                    }
                                    mButton(
                                        caption = "Übernehmen",
                                        variant = MButtonVariant.contained,
                                        color = MColor.secondary,
                                        onClick = {
                                            state.company?.let { company ->
                                                state.lineAlias?.let { lines ->
                                                    saveLineAlias(company, lines)
                                                }
                                            }
                                        }
                                    ) {
                                        attrs.disableElevation = true
                                        attrs.fullWidth = true
                                    }
                                    if (state.saveSuccess == true) {
                                        mIconNoTranslate("check") {
                                            css {
                                                color = Color.green;
                                                margin(0.spacingUnits, 1.spacingUnits)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}