package hardware

@kotlinx.serialization.Serializable
enum class HardwareFeature(val readableName: String) {
    GPS("GPS"),
    PASSENGER_COUNT("Fahrzeugauslastung"),
    SOFTWARE("Software"),
    AUDIO("Audio"),
    IBIS_INFO("IBIS Info"),
    IBIS_INFO_V18("IBIS Info V18"),
    IBIS_DEVICES("IBIS Geräte"),
    IBIS_IP("IBIS IP"),
    FMS("FMS"),
    CELLULAR("Mobilfunkmodul"),
    WIRELESS("Wifimodul"),
    LAN("LAN"),
    CABLE_CHECK("Überprüfung der Verkabelung"),
    CABLE_CHECK_V2("Überprüfung der Verkabelung V2"),
    SELF_CHECK("Selbsttest"),
    QR_CODE("QR-Code"),
    CDA("CDA"),
    ELA("ELA");

    companion object {
        private val mapping = values().associateBy(HardwareFeature::readableName)
        fun fromReadable(readableName: String) = mapping[readableName]
    }
}