package de.geomobile.frontend.features.tts

import com.ccfraser.muirwik.components.MGridDirection
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.list.mList
import com.ccfraser.muirwik.components.list.mListItem
import com.ccfraser.muirwik.components.styles.Breakpoint
import de.geomobile.common.permission.Permission
import de.geomobile.common.permission.Permissions
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.spacer
import de.geomobile.frontend.utils.*
import react.RBuilder
import react.RProps
import react.RState
import react.router.dom.redirect
import react.router.dom.route
import react.router.dom.switch
import styled.css

fun RBuilder.textToSpeechNew() = child(TextToSpeechNew::class) {}

class TextToSpeechNew : CComponent<TextToSpeechNew.Props, TextToSpeechNew.State>() {
    interface Props : RProps

    class State(
        var categoryList: Map<Pair<String, String>, Permission> = mapOf(
            ("Baukasten" to "builder") to Permissions.CompanyManagement.textToSpeechEdit,
            ("Wochenplaner" to "schedule") to Permissions.CompanyManagement.textToSpeechEdit,
            ("Haltestellen" to "stations") to Permissions.CompanyManagement.textToSpeechEdit,
            ("Töne & Türsignal" to "sounds") to Permissions.CompanyManagement.textToSpeechEdit,
            ("Wortkorrektur" to "wordreplacement") to Permissions.CompanyManagement.textToSpeechEdit,
            ("Symbole" to "symbolreplacement") to Permissions.CompanyManagement.textToSpeechEdit,
        ),
    ) : RState

    init {
        state = State()
    }

    override fun RBuilder.render() {
        spacer()
        mGridContainer2(direction = MGridDirection.row) {
            mGridItem2(
                MGridBreakpoints2(MGridSize2.Cells2)
                    .down(Breakpoint.md, MGridSize2.Cells12)
            ) {
                sideBar()
            }
            mGridItem2(
                MGridBreakpoints2(MGridSize2.Cells10)
                    .down(Breakpoint.md, MGridSize2.Cells12)
            ) {
                switch {
                    route<RProps>("/tts/new/builder") {
                        textToSpeechBuilder()
                    }
                    route<RProps>("/tts/new/schedule") {
                        textToSpeechSchedule()
                    }
                    route<RProps>("/tts/new/stations") {
                        textToSpeechStations()
                    }
                    route<RProps>("/tts/new/sounds") {
                        textToSpeechCustomSounds()
                    }
                    route<RProps>("/tts/new/wordreplacement") {
                        textToSpeechWordReplacement()
                    }
                    route<RProps>("/tts/new/symbolreplacement") {
                        textToSpeechSymbolReplacement()
                    }
                    redirect(
                        from = "/tts/new",
                        to = "/tts/new" + "/" + state.categoryList.entries.first().key.second.lowercase(),
                        exact = true
                    )
                }
            }
        }
    }

    private fun RBuilder.sideBar() {
        mGridContainer2(direction = MGridDirection.column) {
            mGridItem2 {
                mCard {
                    css(GlobalStyles.card)
                    mCardContent {
                        css(GlobalStyles.cardListItemContent)
                        route<RProps>("/tts/new") { props ->
                            mList {
                                for (category in state.categoryList) {
                                    authorize(category.value) {
                                        mListItem(
                                            primaryText = category.key.first,
                                            divider = false,
                                            onClick = {
                                                props.history.push("/tts/new/${category.key.second.lowercase()}")
                                            }
                                        ) {
                                            attrs.dense = true
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}
