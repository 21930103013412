package de.geomobile.frontend.features.device.detail

import de.geomobile.common.feature.Features
import de.geomobile.common.portalmodels.AccessPointInterfaceDTO
import de.geomobile.common.portalmodels.ClientInterfaceDTO
import de.geomobile.common.portalmodels.DeviceWifiModuleDTO
import de.geomobile.common.portalmodels.DeviceWifiStatusDTO
import de.geomobile.common.utils.nonStrictJson
import de.geomobile.frontend.utils.isTrial
import react.RBuilder

fun RBuilder.wifiStatus(
    id: Int,
) = deviceInfoComponent(
    id = id,
    subscriptionTopic = "/device/wifi",
    emptyPlaceholder = InfoCard(title = "WLAN nicht vorhanden", content = InfoEmpty, showCollapse = false),
    mapper = { nonStrictJson.decodeFromString(DeviceWifiStatusDTO.serializer(), it).infoItem() }
)

fun DeviceWifiStatusDTO.infoItem() = InfoList(
    modules.orEmpty().mapIndexed { index, dto ->
        InfoCard(
            index = index,
            title = "WLAN ${index + 1}",
            status = status,
            expanded = true,
            showCollapse = false,
            trial = isTrial(Features.DeviceFeatures.cellular),
            content = dto.infoItem()
        )
    }
)

fun DeviceWifiModuleDTO.infoItem() = InfoGrid(
    columns = 2,
    items = clientInterfaces.orEmpty().map { dto ->
        InfoGroup(
            enabledIcon = "wifi",
            disabledIcon = "wifi_off",
            enabled = dto.enabled,
            title = "Client",
            content = dto.infoItem()
        )
    } + accessPointInterfaces.orEmpty().map { dto ->
        InfoGroup(
            enabledIcon = "wifi",
            disabledIcon = "wifi_off",
            enabled = dto.enabled,
            title = "AccessPoint",
            content = dto.infoItem()
        )
    }
)

fun ClientInterfaceDTO.infoItem() = if (enabled != true) {
    InfoList(
        InfoRow(title = "Interface", value = interfaceName),
        InfoRow(title = "Aktiviert", value = enabled.toString())
    )
} else {
    InfoList(
        InfoRow(title = "Interface", value = interfaceName),
        InfoRow(title = "Aktiviert", value = enabled.toString()),
        InfoRow(title = "WPA-Status", value = wpaState),
        InfoRow(title = "Frequenz", value = freq),
        InfoRow(title = "SSID", value = ssid),
        InfoRow(title = "IP-Adresse", value = ipAddress),
        InfoRow(title = "Adresse", value = address),
        InfoRow(title = "Key-Management", value = keyMgmt),
        InfoRow(title = "BSSID", value = bssid),
        InfoRow(title = "RSSI", value = rssi),
        InfoRow(title = "Noise", value = noise),
        InfoRow(title = "Width", value = width)
    )
}

fun AccessPointInterfaceDTO.infoItem() = if (enabled != true) {
    InfoList(
        InfoRow(title = "Interface", value = interfaceName),
        InfoRow(title = "Aktiviert", value = enabled.toString())
    )
} else {
    InfoList(
        InfoRow(title = "Interface", value = interfaceName),
        InfoRow(title = "Aktiviert", value = enabled.toString()),
        InfoRow(title = "Frequenz", value = freq),
        InfoRow(title = "Kanal", value = channel),
        InfoRow(title = "BSSID", value = bssid),
        InfoRow(title = "SSID", value = ssid),
        InfoRow(title = "State", value = state),
        InfoRow(title = "Stations", value = numSta),
        InfoRow(title = "Key Management", value = keyMgmt),
        InfoRow(title = "Group Cipher", value = groupCipher),
        InfoRow(title = "WPA Pairwise Cipher", value = wpaPairwiseCipher)
    )
}