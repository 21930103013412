package de.geomobile.frontend.features.softwareManagement

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import de.geomobile.common.permission.Permissions
import de.geomobile.common.portalmodels.Product
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.features.device.toDetailPath
import de.geomobile.frontend.features.softwareManagement.assignment.SelectedAssignmentFilterProps
import de.geomobile.frontend.features.softwareManagement.assignment.assignmentManagement
import de.geomobile.frontend.features.softwareManagement.bundle.SelectedCompanyProps
import de.geomobile.frontend.features.softwareManagement.bundle.bundleManagement
import de.geomobile.frontend.features.softwareManagement.factoryreset.factoryReset
import de.geomobile.frontend.features.softwareManagement.software.SelectedSoftwareProps
import de.geomobile.frontend.features.softwareManagement.software.softwareManagement
import de.geomobile.frontend.spacer
import de.geomobile.frontend.utils.isAuthorized
import de.geomobile.frontend.utils.mIconNoTranslate
import de.geomobile.frontend.utils.mToolbarTitle2
import kotlinx.css.marginLeft
import kotlinx.css.padding
import react.*
import react.router.dom.redirect
import react.router.dom.route
import react.router.dom.switch
import styled.css
import styled.styledDiv

fun RBuilder.softwareDashboard(path: String, drawerMenu: ReactElement, product: Product) =
    child(SoftwareDashboard::class) {
        attrs.path = path
        attrs.drawerMenu = drawerMenu
        attrs.product = product
        attrs.tabs = if (product == Product.INTERACT) listOf(
            "Assignments" to "/assignments",
            "Bundles" to "/bundles",
            "Firmware" to "/management",
        ) else listOf(
            "Assignments" to "/assignments",
            "Bundles" to "/bundles",
            "Software" to "/management",
            "Box Zurücksetzen" to "/factoryreset"
        )
    }

class SoftwareDashboard : RComponent<SoftwareDashboard.Props, RState>() {
    interface Props : RProps {
        var path: String
        var drawerMenu: ReactElement
        var product: Product
        var tabs: List<Pair<String, String>>
    }

    override fun RBuilder.render() {
        route<RProps>(props.path) { routeProps ->
            mAppBar(position = MAppBarPosition.fixed) {
                css(GlobalStyles.appbar)
                mToolbar {
                    child(props.drawerMenu)
                    mToolbarTitle2("Software")
                    mLink(
                        hRefOptions = HRefOptions(
                            href = "/../software/download/ivanto-uploader",
                        ),
                        underline = MLinkUnderline.none,
                    ) {
                        attrs.color = MTypographyColor.inherit

                        mButton(
                            caption = "Uploader",
                            variant = MButtonVariant.outlined,
                            color = MColor.inherit
                        ) {
                            mIconNoTranslate("get_app") {
                                css.marginLeft = 1.spacingUnits
                            }
                        }
                    }
                }

                val currentRoute = routeProps.history.location.pathname
                val selectedTab = props.tabs.firstOrNull {
                    currentRoute.startsWith(props.path + it.second)
                } ?: props.tabs.first()

                mTabs(
                    value = props.path + selectedTab.second,
                    centered = true,
                    onChange = { _, value -> routeProps.history.push(value as String) }
                ) {
                    for ((label, route) in props.tabs) {
                        if (route == "/factoryreset") if (isAuthorized(Permissions.AdminPermissions.resetAccess))
                            mTab(label, props.path + route)
                        else continue
                        else mTab(label, props.path + route)
                    }
                }
            }
        }
        spacer()
        styledDiv {
            css { padding(2.spacingUnits) }
            switch {
                val routes = props.tabs.map { it.second }
                if ("/management" in routes) {
                    route<SelectedSoftwareProps>(props.path + "/management/:selectedId?") { route ->
                        softwareManagement(
                            selectedId = route.match.params.selectedId?.takeIf { it != "new" },
                            addingNew = route.match.params.selectedId == "new",
                            changeSelection = { selectedId, addingNew ->
                                var path = props.path + "/management"
                                if (selectedId != null) path += "/$selectedId"
                                if (addingNew) path += "/new"
                                if (path != route.location.pathname) route.history.push(path)
                            },
                            product = props.product,
                            path = props.path + "/management"
                        )
                    }
                }

                if ("/bundles" in routes) {
                    route<SelectedCompanyProps>(props.path + "/bundles/:selectedCompanyId?") { route ->
                        bundleManagement(
                            selectedCompanyId = route.match.params.selectedCompanyId,
                            product = props.product,
                            changeSelection = { selectedCompanyId ->
                                val path = props.path + "/bundles/$selectedCompanyId"
                                if (path != route.location.pathname) route.history.push(path)
                            }
                        )
                    }
                }

                route<SelectedAssignmentFilterProps>(props.path + "/assignments/:selectedId(-?\\d+)?") { route ->
                    assignmentManagement(selectedId = route.match.params.selectedId?.toIntOrNull(),
                        product = props.product,
                        changeSelection = { selectedId ->
                            var path = props.path + "/assignments"
                            if (selectedId != null) {
                                path += "/$selectedId"
                            }
                            if (path != route.location.pathname) route.history.push(path)
                        },
                        onDeviceClick = { identifier ->
                            route.history.push("/devices${identifier.toDetailPath()}")
                        }
                    )
                }

                if ("/factoryreset" in routes) {
                    route<RProps>(props.path + "/factoryreset") { _ ->
                        factoryReset()
                    }
                }

                redirect(from = props.path, to = props.path + props.tabs.first().second, exact = true)
            }
        }
    }
}