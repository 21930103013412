package hardware

import kotlinx.serialization.Serializable

@Serializable
enum class AssemblyLifecycle(val readableName: String) {
    IN_PRODUCTION("In Production"),
    END_OF_LIFE("End of Life"),
    NOT_DELIVERABLE("Nicht Bestellbar");
    companion object {
        private val mapping = values().associateBy(AssemblyLifecycle::readableName)
        fun fromReadable(readableName: String) = mapping[readableName]
    }
}