package de.geomobile.frontend.features.companyProfile

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardActions
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.form.*
import com.ccfraser.muirwik.components.input.MInputProps
import com.ccfraser.muirwik.components.list.mListSubheader
import com.ccfraser.muirwik.components.styles.Breakpoint
import de.geomobile.common.permission.Permissions
import de.geomobile.common.portalmodels.*
import de.geomobile.common.portalmodels.Position
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.features.map.circleMarker
import de.geomobile.frontend.features.map.googleMap
import de.geomobile.frontend.features.map.googleMapCoords
import de.geomobile.frontend.features.map.toGoogleMapCoords
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.utils.*
import de.geomobile.frontend.utils.grid.*
import de.geomobile.frontend.utils.maps.googlemapreact.Coords
import de.geomobile.frontend.utils.maps.googlemapreact.MapOptions
import kotlinext.js.jsObject
import kotlinx.browser.localStorage
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.async
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.*
import kotlinx.html.InputType
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.json.Json
import org.w3c.dom.get
import portalmodels.CompanyDepotDTO
import react.*
import react.router.dom.route
import styled.css
import styled.styledDiv
import utils.maps.googlemaps.Circle
import utils.maps.googlemaps.LatLng
import kotlin.js.Promise

fun RBuilder.companyProfileDepots() = child(CompanyProfileDepots::class) {}

class CompanyProfileDepots : CComponent<CompanyProfileDepots.Props, CompanyProfileDepots.State>() {

    private val LOCATION_ZOOM = 16
    private val DEFAULT_ZOOM = 6

    interface Props : RProps

    class State(
        var company: CompanySmall? = null,
        var depotSelected: String? = null,
        var loading: Boolean = false,
        var columns: List<Column>,
        var rows: List<Row> = emptyList(),
        var name: String? = null,
        var lat: Double? = null,
        var lon: Double? = null,
        var radius: Double = 0.0,
        var circle: Circle = Circle(),
        // map
        var center: Coords? = googleMapCoords(latitude = 51.400427, longitude = 10.283918),
        var zoom: Int? = 6,
        var locked: Boolean = true
    ) : RState

    init {
        val columns = listOf(
            Column(name = "name", title = "Name"),
            Column(name = "lat", title = "Latitude"),
            Column(name = "lon", title = "Longitude"),
            Column(name = "radius", title = "Radius")
        )
        state = State(
            columns = columns
        )
    }

    data class Row(
        val name: String, val lat: Double, var lon: Double, var radius: Double
    )

    override fun componentDidMount() {
        launch {
            val deferredCompanies =
                if (isAuthorized(Permissions.CompanyProfileManagement.notRestrictedToCompany)) async(
                    Dispatchers.Default
                ) {
                    portalRestApi.get("/admin/companies", ListSerializer(Company.serializer()))
                }
                else null

            // get company from local storage (if authorized and not null) otherwise use own users company
            val company = if (isAuthorized(Permissions.CompanyProfileManagement.notRestrictedToCompany)) {
                deferredCompanies?.await()?.first { it.id == localStorage["CompanyProfile"] }?.small
            } else {
                withContext(Dispatchers.Default) {
                    portalRestApi.get("/user", UserDTO.serializer())
                }.company.small
            }

            loadDepots(company!!.id)
            setState {
                this.company = company
            }
        }
    }

    private val rowWrapper = rFunction<RowProps>("TableRowWrapper") { rowProps ->
        val newProps = kotlinext.js.clone(rowProps)

        newProps.asDynamic().onClick = {
            setState {
                this.depotSelected = (rowProps.tableRow.row as Row).name
                this.name = (rowProps.tableRow.row as Row).name
                this.lat = (rowProps.tableRow.row as Row).lat
                this.lon = (rowProps.tableRow.row as Row).lon
                this.radius = (rowProps.tableRow.row as Row).radius
                this.locked = true
                this.zoom = LOCATION_ZOOM
            }
        }

        newProps.asDynamic().style = kotlinext.js.js { cursor = "pointer" }
        tableRow(newProps)
    }

    override fun RBuilder.render() {
        route<RProps>("/companyprofile/system/betriebshöfe") { _ ->
            mGridContainer2(direction = MGridDirection.column) {
                mBackdrop(open = state.loading) {
                    css { color = Color.white; zIndex = 999999 }
                    mCircularProgress(color = MCircularProgressColor.inherit)
                }
                mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                    mGridContainer2(direction = MGridDirection.row) {
                        mGridItem2(
                            MGridBreakpoints2(MGridSize2.Cells6)
                                .down(Breakpoint.sm, MGridSize2.Cells12)
                        ) {
                            mCard {
                                css(GlobalStyles.card)
                                mCardContent {
                                    css(GlobalStyles.cardBoxContent)
                                    mListSubheader(heading = "Einstellungen")
                                    mDivider { }
                                    styledDiv {
                                        css { padding(2.spacingUnits) }
                                        mFormGroup {
                                            mFormControl(
                                                margin = MFormControlMargin.dense, required = true, fullWidth = true
                                            ) {
                                                mTextField(id = "name",
                                                    required = true,
                                                    label = "Name",
                                                    variant = MFormControlVariant.outlined,
                                                    value = state.name ?: "",
                                                    onChange = {
                                                        val value = it.targetInputValue
                                                        setState { this.name = value }
                                                    }) {
                                                    attrs.margin = MFormControlMargin.dense
                                                }
                                            }
                                            mFormControl(
                                                margin = MFormControlMargin.none, required = true, fullWidth = true
                                            ) {
                                                mTextField(id = "lat",
                                                    label = "Latitude",
                                                    variant = MFormControlVariant.outlined,
                                                    value = state.lat?.toString() ?: "0",
                                                    type = InputType.number,
                                                    onChange = {
                                                        val value = it.targetInputValue.toDouble()
                                                        setState {
                                                            this.lat = value.takeIf { it != this.lat }
                                                            this.locked = true
                                                            this.zoom = LOCATION_ZOOM
                                                        }
                                                    }) {
                                                    attrs.margin = MFormControlMargin.dense
                                                    attrs.inputProps = jsObject<MInputProps> {
                                                        inputProps = kotlinext.js.js { min = 0 }
                                                    }
                                                }
                                            }
                                            mFormControl(
                                                margin = MFormControlMargin.dense, required = true, fullWidth = true
                                            ) {
                                                mTextField(id = "lon",
                                                    label = "Longitude",
                                                    variant = MFormControlVariant.outlined,
                                                    value = state.lon?.toString() ?: "0",
                                                    type = InputType.number,
                                                    onChange = {
                                                        val value = it.targetInputValue.toDouble()
                                                        setState {
                                                            this.lon = value.takeIf { it != this.lon }
                                                            this.locked = true
                                                            this.zoom = LOCATION_ZOOM
                                                        }
                                                    }) {
                                                    attrs.margin = MFormControlMargin.dense
                                                    attrs.inputProps = jsObject<MInputProps> {
                                                        inputProps = kotlinext.js.js { min = 0 }
                                                    }
                                                }
                                            }
                                            mFormControl(
                                                margin = MFormControlMargin.dense, required = true, fullWidth = true
                                            ) {
                                                mTextField(id = "radius",
                                                    label = "Radius (in m)",
                                                    variant = MFormControlVariant.outlined,
                                                    value = state.radius.toString(),
                                                    type = InputType.number,
                                                    onChange = {
                                                        val value = it.targetInputValue.toDouble()
                                                        setState {
                                                            this.radius = value
                                                        }
                                                    }) {
                                                    attrs.margin = MFormControlMargin.dense
                                                    attrs.inputProps = jsObject<MInputProps> {
                                                        inputProps = kotlinext.js.js { min = 0 }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                mDivider { }
                                mCardActions {
                                    css { padding(2.spacingUnits) }
                                    mButton(
                                        caption = "Übernehmen",
                                        variant = MButtonVariant.contained,
                                        color = MColor.secondary,
                                        disabled = state.name == null || state.name == "" || state.lat == null || state.lon == null ||
                                                // no duplicate names
                                                state.rows.map { it.name }.filter { it != state.depotSelected }
                                                    .contains(state.name),
                                        onClick = {
                                            launch {
                                                withContext(Dispatchers.Default) {
                                                    setState { loading = true }
                                                    // check if adding new depot or updating existing depot
                                                    val path =
                                                        if (state.depotSelected != null) "/companyprofile/depots/manage"
                                                        else "/companyprofile/depots/add"

                                                    portalRestApi.put(
                                                        path, body = Json.encodeToString(
                                                            CompanyDepotDTO.serializer(), CompanyDepotDTO(
                                                                name = state.name ?: "",
                                                                company = state.company!!,
                                                                lat = state.lat ?: 0.0,
                                                                lon = state.lon ?: 0.0,
                                                                radius = state.radius
                                                            )
                                                        )
                                                    )
                                                    resetEntries()
                                                    loadDepots(state.company!!.id)
                                                    setState { loading = false }
                                                }
                                            }
                                        }
                                    ) {
                                        attrs.disableElevation = true
                                        attrs.fullWidth = true
                                    }
                                    mButton(
                                        caption = "Löschen",
                                        variant = MButtonVariant.contained,
                                        disabled = state.depotSelected == null,
                                        color = MColor.inherit,
                                        onClick = {
                                            launch {
                                                withContext(Dispatchers.Default) {
                                                    setState { loading = true }
                                                    val path = "/companyprofile/depots/manage"

                                                    portalRestApi.delete(
                                                        path, body = Json.encodeToString(
                                                            CompanyDepotDTO.serializer(), CompanyDepotDTO(
                                                                name = state.name ?: "",
                                                                company = state.company!!,
                                                                lat = state.lat ?: 0.0,
                                                                lon = state.lon ?: 0.0,
                                                                radius = state.radius
                                                            )
                                                        )
                                                    )
                                                    resetEntries()
                                                    println("Reloading depots for company: ${state.company!!.id}")
                                                    loadDepots(state.company!!.id)
                                                    setState { loading = false }
                                                }
                                            }
                                        }
                                    ) {
                                        attrs.fullWidth = true
                                        attrs.disableElevation = true
                                        css {
                                            backgroundColor = Color.red;
                                            color = Color.white
                                        }
                                    }
                                    mButton(
                                        caption = "Abbrechen",
                                        color = MColor.default,
                                        onClick = { resetEntries() },
                                        variant = MButtonVariant.contained
                                    ) {
                                        attrs.disableElevation = true
                                        attrs.fullWidth = true
                                    }
                                }
                            }
                        }
                        mGridItem2(
                            MGridBreakpoints2(MGridSize2.Cells6).down(Breakpoint.sm, MGridSize2.Cells12)
                        ) {
                            showDepotOnMap()
                        }
                    }
                }
                mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                    mCard {
                        css(GlobalStyles.card)
                        mCardContent {
                            css(GlobalStyles.cardBoxContent)
                            mListSubheader(heading = "Betriebshöfe")
                            mDivider { }
                            depotList()
                        }
                    }
                }
            }
        }
    }

    private fun RBuilder.showDepotOnMap() {
        mCard {
            css {
                flexGrow = 1.0
                display = Display.flex
                flexDirection = FlexDirection.column
            }
            css(GlobalStyles.cardSameHeight)
            mCardContent {
                css(GlobalStyles.cardBoxContent)
                css { height = 100.pct }
                googleMap {
                    attrs {
                        if (state.lat != null && state.lon != null && state.locked) {
                            center =
                                Position.Location(latitude = state.lat!!, longitude = state.lon!!).toGoogleMapCoords()
                            zoom = state.zoom
                        } else {
                            center = null
                            zoom = null
                        }
                        defaultCenter = googleMapCoords(latitude = 51.400427, longitude = 10.283918)
                        defaultZoom = DEFAULT_ZOOM
                        options = jsObject<MapOptions> {
                            mapTypeControl = true
                        }
                        googleMapLoader = { Promise.resolve(js("google.maps") as Any) }
                        onDrag = {
                            setState {
                                this.locked = false
                            }
                        }
                        onChange = {
                            setState {
                                this.locked = false
                            }
                        }
                        yesIWantToUseGoogleMapApiInternals = true
                        onGoogleApiLoaded = {
                            state.circle.setMap(it.map)
                            it.map.data

                        }
                    }
                    state.lat?.let { depotLat ->
                        state.lon?.let { depotLon ->
                            if (state.radius == 0.0) {
                                // don't show circle if radius is 0
                                state.circle.setVisible(false)
                            } else {
                                state.circle.setCenter(LatLng(lat = depotLat, lng = depotLon))
                                state.circle.setRadius(state.radius)
                                state.circle.setVisible(true)
                            }
                            circleMarker {
                                key = state.name ?: "-"
                                lat = depotLat
                                lng = depotLon
                                radius = 0 // don't use custom circle (Google Maps circle is being used)
                                id = 0
                                onClick = {
                                    setState {
                                        this.locked = true
                                        this.zoom = LOCATION_ZOOM
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    private val listCellStyle = rFunction<RProps>("TableCell") { cellProps ->
        val newProps = kotlinext.js.clone(cellProps)

        newProps.asDynamic().style = kotlinext.js.js {
            paddingTop = 1.spacingUnits
            paddingBottom = 1.spacingUnits
        }

        tableCell(newProps)
    }

    private fun RBuilder.depotList() {
        grid(
            columns = state.columns,
            rows = state.rows
        ) {
            table(
                rowComponent = rowWrapper,
                columnExtensions = listOf(
                    TableProps.ColumnExtension(columnName = "name", width = 240),
                    TableProps.ColumnExtension(columnName = "state", width = 120)
                ),
                cellComponent = listCellStyle
            )
            tableHeaderRow(
                showSortingControls = false
            )
        }
    }

    // reset depot entries and map
    private fun resetEntries() {
        setState {
            this.depotSelected = null
            this.name = null
            this.radius = 0.0
            this.lat = null
            this.lon = null
            this.circle.setVisible(false)
        }
    }

    private suspend fun loadDepots(companyId: String) {
        val depots = withContext(Dispatchers.Default) {
            portalRestApi.get(
                "/companyprofile/${companyId}/depots", ListSerializer(CompanyDepotDTO.serializer())
            )
        }
        val rows = depots.map {
            Row(
                name = it.name, lat = it.lat, lon = it.lon, radius = it.radius
            )
        }
        setState {
            this.rows = rows
        }
    }
}