package de.geomobile.frontend

import com.ccfraser.muirwik.components.mCssBaseline
import com.ccfraser.muirwik.components.mThemeProvider
import com.ccfraser.muirwik.components.styles.Theme
import com.ccfraser.muirwik.components.styles.ThemeOptions
import com.ccfraser.muirwik.components.styles.createMuiTheme
import react.RBuilder
import react.RComponent
import react.RProps
import react.RState
import react.router.dom.browserRouter

fun RBuilder.app() = child(App::class) {}

class App : RComponent<RProps, RState>() {
    override fun RBuilder.render() {
        mCssBaseline()
        mThemeProvider(currentTheme) {
            browserRouter {
                attrs.asDynamic().basename = "/portal"
                mainFrame()
            }
        }
    }
}

val themeTemplate = js(
    """({
                    palette: {
                        type: 'placeholder',
                        primary: {
                            main: 'placeholder'
                        },
                        secondary: {
                            main: 'placeholder',
                            contrastText: 'placeholder'
                        }
                    },
                    typography: {
                        useNextVariants: 'placeholder'
                    }
                })"""
) as ThemeOptions

private fun themeOptions(type: String) = (themeTemplate).apply {
    palette?.type = type
    palette?.primary.main = "#4d545d" // Grey
    palette?.secondary.main = "#ee7203" // Orange
    palette?.secondary.contrastText = "#fff"
}

val currentTheme = createMuiTheme(
    themeOptions("light")
)

var reverseTheme: Theme = createMuiTheme(
    themeOptions("dark")
)


