package de.geomobile.common.time

import com.squareup.wire.Instant
import kotlinx.serialization.Serializable
import kotlin.js.Date

@Serializable(with = LocalDateSerializer::class)
actual data class LocalDate actual constructor(actual val millis: Long) : Comparable<LocalDate> {

//    actual constructor(instant: Instant) : this(
//        instant.getEpochSecond() * 1000
//    )
    actual companion object {
        actual fun now(): LocalDate = LocalDate(Date.now().toLong())
        actual fun parse(encoded: String) = LocalDate(Date.parse(encoded).toLong())
    }

    actual fun format(): String = jsDate.toISOString().take(10)

    override fun compareTo(other: LocalDate): Int = millis.compareTo(other.millis)

    val jsDate: Date get() = Date(millis)

    override fun toString(): String = format()

}