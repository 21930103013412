package de.geomobile.frontend.features.dashboard

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonSize
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardActionArea
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.list.*
import com.ccfraser.muirwik.components.styles.Breakpoint
import com.ccfraser.muirwik.components.transitions.mCollapse
import de.geomobile.common.feature.Features
import de.geomobile.common.permission.Permissions
import de.geomobile.common.portalmodels.UserDTO
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.api.TopicSession
import de.geomobile.frontend.device.detail.trialRibbon
import de.geomobile.frontend.features.device.detail.deviceDetailMap
import de.geomobile.frontend.features.device.detail.deviceGps
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.portalWebSocketApi
import de.geomobile.frontend.spacer
import de.geomobile.frontend.utils.*
import kotlinext.js.assign
import kotlinext.js.jsObject
import kotlinx.browser.localStorage
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.*
import kotlinx.serialization.json.Json
import org.w3c.dom.get
import portalmodels.DashboardDeviceSummaryDTO
import portalmodels.DashboardReviewSummaryDTO
import portalmodels.DashboardStatusSummaryDTO
import react.*
import styled.css

fun RBuilder.dashboardReview(
    path: String
) = child(DashboardReview::class) {
    attrs.path = path
}

class DashboardReview : CComponent<DashboardReview.Props, DashboardReview.State>() {

    private var session: TopicSession? = null

    interface Props : RProps {
        var path: String
    }

    class State(
        var company: String? = null,
        var loading: Boolean = true,
        var summary: DashboardReviewSummaryDTO? = null,
        var expandMap: MutableMap<String, Boolean> = mutableMapOf()
    ) : RState

    init {
        state = State()
    }

    suspend fun loadCompany() {
        val companyId =
            if (isAuthorized(Permissions.VehicleProfileManagement.notRestrictedToCompany) && localStorage["StatusDashboard"] != null) {
                localStorage["StatusDashboard"]
            } else {
                withContext(Dispatchers.Default) {
                    portalRestApi.get("/user", UserDTO.serializer())
                }.company.id
            }

        setState {
            company = companyId
        }
    }

    private fun loadSummary() {
        session?.close()
        session = portalWebSocketApi.subscribe("/dashboard/review", mapOf("company" to (state.company.toString())))
        session?.connect {
            onmessage = { msg ->
                val response = Json.decodeFromString(DashboardReviewSummaryDTO.serializer(), msg)
                setState {
                    summary = response
                    loading = false
                }
            }
        }
    }

    override fun componentDidMount() {
        launch {
            loadCompany()
            loadSummary()
        }
    }

    override fun componentWillUnmount() {
        super.componentWillUnmount()
        session?.close()
    }

    fun RBuilder.reviewCard(title: String, text: String, collapseId: String, deviceList: List<Int>) =
        mCard {
            css {
                position = Position.relative
                margin(1.spacingUnits)
            }
            mCardActionArea(
                onClick = {
                    setState {
                        state.expandMap[collapseId] = state.expandMap[collapseId]?.let { !it } ?: true
                    }
                },
            ) {
                attrs.component = "div"
                mCardHeaderExtended(
                    title = title,
                    action = mIconButtonNoTranslate(
                        if (state.expandMap[collapseId] == true) "expand_less" else "expand_more",
                        addAsChild = false
                    ) {
                        attrs.component = "div"
                    }
                )
            }
            mCollapse(state.expandMap[collapseId] == true) {
                mDivider {}
                mCardContent {
                    css { background = "#eeeeee" }
                    if(deviceList.isEmpty())
                        mTypography(
                            text = "Keine Geräte in dieser Katergorie.",
                            variant = MTypographyVariant.subtitle2,
                            color = MTypographyColor.primary,
                            align = MTypographyAlign.left
                        )
                    else
                        deviceList.forEach { deviceId ->
                            state.summary?.devices?.get(deviceId)?.let { device ->
                                mLink(
                                    hRefOptions = HRefOptions(
                                        href = "../devices/${device.product.readableName}/serialnumber/${device.serialNumber}",
                                    ),
                                    underline = MLinkUnderline.always
                                ) {
                                    mTypography(
                                        text = text.replace("??", "${device.serialNumber}"),
                                        variant = MTypographyVariant.subtitle2,
                                        color = MTypographyColor.primary,
                                        align = MTypographyAlign.left
                                    )
                                }
                            }
                        }
                }
            }
    }

    override fun RBuilder.render() {
        spacer()
        reviewCard(
            title = "Geräte mit langer Offlinezeit",
            text = "Das Gerät mit der Seriennummer ?? ist seit mehr als 2 Wochen offline.",
            collapseId = "devicesLongOffline",
            deviceList = state.summary?.deviceLongOffline ?: listOf()
        )
        reviewCard(
            title = "Geräte ohne ivanto Zeitstempel",
            text = "Das Gerät mit der Seriennummer ?? meldet sich nicht in ivanto.",
            collapseId = "devicesivantoOffline",
            deviceList = state.summary?.deviceOnlyLinuxOnline ?: listOf()
        )
        reviewCard(
            title = "Geräte ohne Fahrzeugtyp",
            text = "Das Gerät mit der Seriennummer ?? hat keinen Fahrzeugtyp",
            collapseId = "devicesNoVehicleType",
            deviceList = state.summary?.deviceUncategorized ?: listOf()
        )
        reviewCard(
            title = "Geräte mit veralteter Software",
            text = "Das Gerät mit der Seriennummer ?? ist nicht auf dem neusten Software Stand.",
            collapseId = "devicesOutdatedSoftware",
            deviceList = state.summary?.softwareOutOfDate ?: listOf()
        )
        reviewCard(
            title = "Geräte mit fehlerhaften Updates",
            text = "Das Gerät mit der Seriennummer ?? hat fehlerhafte Updates.",
            collapseId = "devicesFailedSoftware",
            deviceList = state.summary?.softwareFailed ?: listOf()
        )

        /*
        mList {
            attrs.disablePadding = true
            mListItem {
                attrs.key = "devicesLongOffline"
                attrs.divider = true
                attrs.button = true
                attrs.onClick = {
                    setState {
                        state.expandMap["devicesLongOffline"] = state.expandMap["devicesLongOffline"]?.let { !it } ?: true
                    }
                }

                mListItemText(
                    primary = "Geräte",
                ) {
                    attrs.primaryTypographyProps = jsObject {
                        variant = MTypographyVariant.subtitle2
                        color = MTypographyColor.secondary
                    }
                    attrs.secondaryTypographyProps = jsObject {
                        variant = MTypographyVariant.caption
                    }
                }
                mListItemSecondaryAction {
                    mIconButtonNoTranslate(
                        if (state.expandMap["devicesLongOffline"] == true) "expand_less" else "expand_more",
                        onClick = {
                            setState {
                                state.expandMap["devicesLongOffline"] = state.expandMap["devicesLongOffline"]?.let { !it } ?: true
                            }
                        },
                        addAsChild = true
                    )
                }
            }
            mCollapse(state.expandMap["devicesLongOffline"] ?: false) {
                mCardContent {
                    css { background = "#eeeeee" }
                    state.summary?.deviceLongOffline?.forEach { deviceId ->
                        state.summary?.devices?.get(deviceId)?.let { device ->
                            mLink(
                                hRefOptions = HRefOptions(
                                    href = "../../devices/${device.product.readableName}/serialnumber/${device.serialNumber}",
                                ),
                                underline = MLinkUnderline.none
                            ) {
                                mTypography(
                                    text = "Das Gerät mit der Seriennummer ${device.serialNumber} ist seit mehr als 2 Wochen nicht mehr online.",
                                    variant = MTypographyVariant.subtitle2,
                                    color = MTypographyColor.secondary,
                                    align = MTypographyAlign.left
                                )
                            }
                        }
                    }
                }
            }
        }


        spacer()
        if (state.loading && state.summary == null)
            mSkeleton(
                height = 150.px,
                animation = MSkeletonAnimation.wave,
                variant = MSkeletonVariant.rect
            )
        else {
            mCard {
                css(GlobalStyles.card)
                mListSubheader(heading = "Probleme") {
                    css { textAlign = TextAlign.center }
                }
                mDivider { }
                mCardContent {
                    mTypography(
                        text = "Geräte",
                        variant = MTypographyVariant.h6,
                    )
                    state.summary?.deviceLongOffline?.forEach {
                        state.summary?.devices?.get(it)?.let { device ->
                            mLink(
                                hRefOptions = HRefOptions(
                                    href = "../../devices/${device.product.readableName}/serialnumber/${device.serialNumber}",
                                ),
                                underline = MLinkUnderline.none
                            ) {
                                mTypography(
                                    text = "Das Gerät mit der Seriennummer ${device.serialNumber} ist seit mehr als 2 Wochen nicht mehr online.",
                                    variant = MTypographyVariant.subtitle2,
                                    color = MTypographyColor.secondary,
                                    align = MTypographyAlign.left
                                )
                            }
                        }
                    }
                    mDivider {  }
                    state.summary?.deviceOnlyLinuxOnline?.forEach {
                        state.summary?.devices?.get(it)?.let { device ->
                            mLink(
                                hRefOptions = HRefOptions(
                                    href = "../../devices/${device.product.readableName}/serialnumber/${device.serialNumber}",
                                ),
                                underline = MLinkUnderline.none
                            ) {
                                mTypography(
                                    text = "Das Gerät mit der Seriennummer ${device.serialNumber} meldet sich nicht per ivanto.",
                                    variant = MTypographyVariant.subtitle2,
                                    color = MTypographyColor.secondary,
                                    align = MTypographyAlign.left
                                )
                            }
                        }
                    }
                    mDivider { }
                    state.summary?.deviceUncategorized?.forEach {
                        state.summary?.devices?.get(it)?.let { device ->
                            mLink(
                                hRefOptions = HRefOptions(
                                    href = "../../devices/${device.product.readableName}/serialnumber/${device.serialNumber}",
                                ),
                                underline = MLinkUnderline.none
                            ) {
                                mTypography(
                                    text = "Das Gerät mit der Seriennummer ${device.serialNumber} hat keinen Fahrzeugtyp.",
                                    variant = MTypographyVariant.subtitle2,
                                    color = MTypographyColor.secondary,
                                    align = MTypographyAlign.left
                                )
                            }
                        }
                    }
                    mTypography(
                        text = "Software",
                        variant = MTypographyVariant.h6,
                    )
                    state.summary?.softwareOutOfDate?.forEach {
                        state.summary?.devices?.get(it)?.let { device ->
                            mLink(
                                hRefOptions = HRefOptions(
                                    href = "../../devices/${device.product.readableName}/serialnumber/${device.serialNumber}",
                                ),
                                underline = MLinkUnderline.none
                            ) {
                                mTypography(
                                    text = "Das Gerät mit der Seriennummer ${device.serialNumber} hat veraltete Software.",
                                    variant = MTypographyVariant.subtitle2,
                                    color = MTypographyColor.secondary,
                                    align = MTypographyAlign.left
                                )
                            }
                        }
                    }
                    state.summary?.softwareFailed?.forEach {
                        state.summary?.devices?.get(it)?.let { device ->
                            mLink(
                                hRefOptions = HRefOptions(
                                    href = "../../devices/${device.product.readableName}/serialnumber/${device.serialNumber}",
                                ),
                                underline = MLinkUnderline.none
                            ) {
                                mTypography(
                                    text = "Das Gerät mit der Seriennummer ${device.serialNumber} hat fehlgeschlagene Updates.",
                                    variant = MTypographyVariant.subtitle2,
                                    color = MTypographyColor.secondary,
                                    align = MTypographyAlign.left
                                )
                            }
                        }
                    }
                }
            }
        }

        */
    }
}