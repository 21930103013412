package de.geomobile.frontend.auth

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.form.MFormControlVariant
import com.ccfraser.muirwik.components.form.mFormGroup
import com.ccfraser.muirwik.components.styles.Breakpoint
import com.ccfraser.muirwik.components.styles.up
import de.geomobile.common.portalmodels.LoginDTO
import de.geomobile.common.portalmodels.LoginResponseDTO
import de.geomobile.frontend.UserStore
import de.geomobile.frontend.api.ApiException
import de.geomobile.frontend.api.isInvalidSessionError
import de.geomobile.frontend.currentTheme
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.utils.CComponent
import kotlinx.browser.document
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.*
import kotlinx.html.InputType
import kotlinx.serialization.json.Json
import react.RBuilder
import react.RProps
import react.RState
import react.dom.div
import react.setState
import styled.css
import styled.styledDiv
import styled.styledImg

fun RBuilder.login(
    loggedIn: () -> Unit,
    requestPasswordReset: (email: String) -> Unit,
) = child(Login::class) {
    attrs.loggedIn = loggedIn
    attrs.requestPasswordReset = requestPasswordReset
}

class Login : CComponent<Login.Props, Login.State>() {

    private var job: Job = Job()

    interface Props : RProps {
        var loggedIn: () -> Unit
        var requestPasswordReset: (email: String) -> Unit
    }

    class State(
        var email: String = "",
        var password: String = "",
        var loading: Boolean = false,
        var error: String? = null,
    ) : RState

    init {
        state = State()
    }

    override fun RBuilder.render() {
        val loading = state.loading
        val error = state.error

        styledDiv {
            css {
                width = 100.pct
                display = Display.block
                marginLeft = 2.spacingUnits
                marginRight = 2.spacingUnits
                marginTop = 80.px
                media(currentTheme.breakpoints.up(Breakpoint.sm)) {
                    width = 400.px
                    marginLeft = LinearDimension.auto
                    marginRight = LinearDimension.auto
                }
            }

            document.onkeydown = {
                if (it.keyCode == 13) // User hits enter
                    tryLogin()
            }

            mPaper {
                css {
                    marginTop = 8.spacingUnits
                    marginBottom = 2.spacingUnits
                    display = Display.flex
                    flexDirection = FlexDirection.column
                    alignItems = Align.center
                    padding(2.spacingUnits, 3.spacingUnits, 3.spacingUnits)
                }
                styledImg(alt = "ivanto Logo", src = "/portal/static/ivanto_logo_rgb.png") {
                    css {
                        margin(horizontal = LinearDimension.auto)
                        height = 80.px
                        display = Display.block
                    }
                }
                mFormGroup {
                    css {
                        marginTop = 1.spacingUnits
                        width = 100.pct
                    }

                    mTextField(
                        label = "E-Mail",
                        name = "email",
                        id = "email",
                        variant = MFormControlVariant.outlined,
                        autoFocus = true,
                        onChange = {
                            val value = it.targetInputValue
                            setState { email = value }
                        }
                    ) {
                        attrs.autoComplete = "email"
                    }
                    mTextField(
                        label = "Passwort",
                        name = "password",
                        type = InputType.password,
                        id = "password",
                        variant = MFormControlVariant.outlined,
                        onChange = {
                            val value = it.targetInputValue
                            setState { password = value }
                        }
                    ) {
                        attrs.autoComplete = "current-password"
                    }

                    if (loading) {
                        mCircularProgress {
                            css {
                                marginTop = 3.spacingUnits
                                marginLeft = LinearDimension.auto
                                marginRight = LinearDimension.auto
                            }
                        }
                    } else {
                        mButton(
                            variant = MButtonVariant.contained,
                            color = MColor.primary,
                            caption = "Anmelden",
                            onClick = { tryLogin() }
                        ) {
                            attrs.disableElevation = true
                            attrs.fullWidth = true

                            css { marginTop = 3.spacingUnits }
                        }
                    }

                    mButton(
                        caption = "Passwort vergessen?",
                        onClick = { props.requestPasswordReset(state.email) }
                    ) {
                        attrs.fullWidth = true

                        css { marginTop = 2.spacingUnits }
                    }

                    if (error != null) {
                        mSnackbar(
                            error,
                            open = true,
                            horizAnchor = MSnackbarHorizAnchor.center,
                            autoHideDuration = 4000
                        ) {
                            attrs.onClose = { _, _ -> setState { this.error = null } }
                            attrs.action = RBuilder().div {
                                mIconButtonNoTranslate(
                                    iconName = "close",
                                    onClick = { setState { this.error = null } },
                                    color = MColor.inherit
                                )
                            }
                        }
                    }
                }
            }
        }
    }

    private fun tryLogin() {
        job.cancel()
        job = launch {
            setState { loading = true }
            try {
                val response = withContext(Dispatchers.Default) {
                    portalRestApi.post(
                        path = "/login",
                        body = Json.encodeToString(
                            LoginDTO.serializer(), LoginDTO(
                                email = state.email,
                                password = state.password
                            )
                        ),
                        serializer = LoginResponseDTO.serializer()
                    )
                }

                UserStore.realToken = response.token
                UserStore.realUser = response.userDTO
                props.loggedIn()

            } catch (e: ApiException) {
                println("LOGIN ERROR: $e")

                val error = if (e.apiError.isInvalidSessionError)
                    "Ungültige Anmeldedaten"
                else
                    e.message

                setState {
                    this.error = error
                    this.loading = false
                }
            }

        }
    }
}