package de.geomobile.frontend.features.vehicleProfile

import de.geomobile.common.permission.Permissions
import de.geomobile.common.portalmodels.DeviceIdentifier
import de.geomobile.frontend.utils.authorize
import react.RBuilder
import react.RComponent
import react.RProps
import react.RState
import react.router.dom.route
import react.router.dom.switch

fun RBuilder.vehicleProfilesAssign(
    path: String,
    onDeviceClick: (id: DeviceIdentifier) -> Unit,
) = child(VehicleProfilesAssign::class) {
    attrs.path = path
    attrs.onDeviceClick = onDeviceClick
}

class VehicleProfilesAssign : RComponent<VehicleProfilesAssign.Props, RState>() {

    interface Props : RProps {
        var path: String
        var onDeviceClick: (id: DeviceIdentifier) -> Unit
    }

    override fun RBuilder.render() {
        switch {
            route<RProps>(props.path, exact = true) { routeProps ->
                vehicleProfilesAssignList(profileSelected = { routeProps.history.push("${props.path}/$it") })
            }
            authorize(Permissions.VehicleProfileManagement.profilesAssign) {
                route<VehicleProfilesDetail.Props>("${props.path}/:profileId", exact = true) { routeProps ->
                    val profileId = routeProps.match.params.profileId
                    vehicleProfilesAssignEdit(
                        profileId,
                        goBack = { routeProps.history.push(props.path) },
                        onDeviceClick = props.onDeviceClick
                    )
                }
            }
        }
    }
}