package de.geomobile.common.portalmodels

import de.geomobile.common.time.LocalDateTime
import kotlinx.serialization.Serializable

enum class PassengerCountSource(val readableName: String) {
    NONE("-"),
    SIMPLE("Standard"),
    R2P("R2P"),
    SIMPLE_CALIB("SIMPLE_CALIB");
}

enum class OccupancyLevel(val readableName: String) {
    NONE("-"),
    LEVEL_1("Füllstand Stufe 1"),
    LEVEL_2("Füllstand Stufe 2"),
    LEVEL_3("Füllstand Stufe 3"),
}

@Serializable
data class DevicePassengerStatisticDTO(
    val source: PassengerCountSource = PassengerCountSource.NONE,
    val count: Int? = null, // the number of passengers (in case of wifi scan data: this value is already calibrated)
    val occupancy: OccupancyLevel? = null,
    val occupancyPercentage: Double? = null,
    val scan_intersection_filtered: Int? = null,
    val scan_intersection: Int? = null,
    val scan1: Int? = null,
    val scan2: Int? = null,
    val distance: Double? = null,
    val factor: Double? = null,
    val numUsedValues: Int? = null,
    val status: String? = null,
    val timestamp: TimestampStatus = TimestampStatus()
)

@Serializable
data class DevicePassengerStatisticsDTO(
    val statistics: List<DevicePassengerStatisticDTO> = emptyList()
)

@Serializable
data class R2PWifiCalibrationDTO(
    val timestamp: LocalDateTime,
    val factor: Double
)

//@Serializable
//data class PassengerCountingMap(
//    val offset: Int,
//    val values: Map<String, PassengerCountingData>
//)

@Serializable
data class PassengerCountingData(
    val id: String,
    val entries: List<Entry>
) {

    @Serializable
    data class Entry(
        val enter: Int,
        val exit: Int,
        val type: Type,
        val quality: Quality
    ) {
        enum class Type {
            ADULT,
            CHILD,
            BIKE,
            WHEELCHAIR,
            PRAM,
            UNIDENTIFIED,
            OTHER
        }

        enum class Quality {
            DEFECT,
            REGULAR,
            SABOTAGE,
            OTHER
        }
    }
}

