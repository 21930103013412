package de.geomobile.frontend.features.admin.stages

import com.ccfraser.muirwik.components.mCheckboxWithLabel
import de.geomobile.common.stage.Stage
import react.RBuilder
import react.RComponent
import react.RProps
import react.RState

fun RBuilder.stageSelection(
    mutable: Boolean,
    stages: Set<Stage>,
    onChanged: (Set<Stage>) -> Unit,
) = child(StageSelectionComponent::class) {
    attrs.mutable = mutable
    attrs.stages = stages
    attrs.onChanged = onChanged
}

class StageSelectionComponent : RComponent<StageSelectionComponent.Props, RState>() {

    interface Props : RProps {
        var mutable: Boolean
        var stages: Set<Stage>
        var onChanged: (Set<Stage>) -> Unit
    }

    override fun RBuilder.render() {
        val selectedStages = props.stages
        val allStages = Stage.values()

        for (stage in allStages) {
            mCheckboxWithLabel(
                label = stage.readableName,
                checked = stage in selectedStages,
                disabled = !props.mutable,
                onChange = { _, checked ->
                    props.onChanged(
                        if (checked) props.stages + stage
                        else props.stages - stage
                    )
                }
            )
        }
    }
}