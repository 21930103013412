package de.geomobile.frontend.features.device.stationlist

import com.ccfraser.muirwik.components.MTypographyVariant
import com.ccfraser.muirwik.components.mTypography
import de.geomobile.common.portalmodels.*
import de.geomobile.common.utils.nonStrictJson
import de.geomobile.frontend.api.TopicSession
import de.geomobile.frontend.features.device.deviceIdResolver
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.portalWebSocketApi
import de.geomobile.frontend.utils.CComponent
import kotlinx.browser.window
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import kotlinx.css.*
import kotlinx.css.Position
import kotlinx.serialization.builtins.serializer
import kotlinx.serialization.json.Json
import portalmodels.IbisFunction
import react.RBuilder
import react.RProps
import react.RState
import react.router.dom.RouteResultHistory
import react.setState
import styled.css
import styled.styledDiv
import styled.styledImg

fun RBuilder.deviceStationList(
    identifier: DeviceIdentifier,
    routeProps: RouteResultHistory,
) = deviceIdResolver(identifier) { id ->
    child(DeviceStationList::class) {
        attrs.identifier = id
        attrs.routeProps = routeProps
    }
}

class DeviceStationList : CComponent<DeviceStationList.Props, DeviceStationList.State>() {

    private var telegramSession: TopicSession? = null
    private var cableCheckSession: TopicSession? = null

    interface Props : RProps {
        var identifier: DeviceIdentifier.Id
        var routeProps: RouteResultHistory
    }

    class State(
        var id: Int,
        var redirectProduct: String? = Product.ALL.readableName,
        var redirectSerial: String? = null,
        var nextStation: Int = 0,
        var line: String = "",
        var stationList: List<String> = listOf(),
        var destination: String = "",
        var next4: List<String> = listOf(),
        var stop: Boolean = false
    ) : RState

    init {
        state = State(id = 0)
    }

    override fun componentDidMount() {
        setState { id = props.identifier.id }
        window.scroll(0.0, 0.0)

        launch(Dispatchers.Default) {
            val serialnumber =
                if(props.identifier.id == 0)
                    0
                else
                    portalRestApi.get("/device/${props.identifier.id}/serialnumber", Int.serializer())

            setState {
                this.redirectSerial = serialnumber.toString()
            }
        }
        connectCableCheck()
        connectTelegramBroadcast()
    }
    private fun connectCableCheck() {
        cableCheckSession?.close()
        cableCheckSession = portalWebSocketApi.subscribe("/device/cablecheck", mapOf("id" to props.identifier.id.toString()))
        cableCheckSession?.connect {
            onmessage = { message ->
                val item = Json.decodeFromString(CableCheck.serializer(), message)
                setState {
                    if (item.inputs[2]?.raw == "false") {
                        stop = true
                    }
                    if(item.relays[1]?.raw == "true") {
                        stop = true
                    }
                    if (item.inputs[0]?.raw == "false") {
                        stop = false
                    }
                }
            }
        }
    }

    private fun connectTelegramBroadcast() {
        telegramSession?.close()
        telegramSession = portalWebSocketApi.subscribe("/device/ibis_info_v18", mapOf("id" to props.identifier.id.toString()))
        telegramSession?.connect {
            onmessage = { message ->
                val item = Json.decodeFromString(DeviceIbisV18InfoRawDTO.serializer(), message)
                val telegram = deserializeTelegram(item)
                telegram?.let {
                    println("Func: ${it.function}, Telegram: ${it.telegram}")
                    when(it.function) {
                        IbisFunction.STATIONS -> {
                            val stations = mutableListOf<String>()
                            (it.telegram as ListMapTelegram).value.forEach { station ->
                                stations.add(station.value["title"] ?: "")
                            }
                            setState {
                                stationList = stations.toList()
                            }
                        }
                        IbisFunction.STATION_INDEX -> {
                            val telegramValue = try { (it.telegram as LineTelegram).value.toInt() } catch (ex: Exception) {
                                (it.telegram as ValueTelegram).value.toInt()
                            }

                            setState {
                                nextStation = telegramValue
                            }
                        }
                        IbisFunction.LINE -> {
                            val telegramValue = try { (it.telegram as LineTelegram).value } catch (ex: Exception) {
                                (it.telegram as ValueTelegram).value
                            }
                            setState {
                                line = telegramValue
                            }
                        }
                        IbisFunction.DESTINATION -> {
                            val telegramValue = try { (it.telegram as LineTelegram).value } catch (ex: Exception) {
                                (it.telegram as ValueTelegram).value
                            }
                            setState {
                                destination = telegramValue
                            }
                        }
                        else -> { }
                    }
                }
                println("Linie: ${state.line}")
                println("Aktuelle Station: ${state.stationList.getOrNull(state.nextStation - 1)}")
                if(state.nextStation <= state.stationList.size) {
                    val next4 = state.stationList.drop(state.nextStation).take(4)
                    setState { this.next4 = next4 }
                    println("Nächste 4 Stationen: $next4")
                }
            }
        }
    }

    private fun deserializeTelegram(dto: DeviceIbisV18InfoRawDTO): DeviceIbisV18InfoDTO? {
        val telegramSerializer = IbisTelegramSerializer.values().firstOrNull { it.name.uppercase() == dto.function.uppercase() }?.serializer ?: ValueTelegram.serializer()
        return try {
            if (telegramSerializer !is LoggingTelegramSerializer)
                DeviceIbisV18InfoDTO(dto.timestamp, IbisFunction.valueOf(dto.function.uppercase()), dto.type, nonStrictJson.decodeFromString(telegramSerializer, dto.telegram))
            else
                DeviceIbisV18InfoDTO(dto.timestamp, IbisFunction.valueOf(dto.function.uppercase()), dto.type, LoggingTelegram(dto.telegram))
        } catch (ex: Exception) {
            null
        }
    }

    override fun RBuilder.render() {
        val id = state.id
        println("CHECK")
        styledImg(alt = "Perlenkette", src = "/portal/static/stationlist.png") {
            css {
                backgroundColor = Color("#fbfafd")
                position = Position.absolute
                display = Display.block
                top = 0.px
                left = 0.px
                height = 100.vh
                maxWidth = 100.pct
                zIndex = 2
                overflow = Overflow.hidden
            }
        }
        styledDiv {
            css {
//                backgroundColor = Color("pink")
                position = Position.absolute
                top = 0.px
                left = 0.px
                height = 100.vh
                zIndex = 3
            }

            styledDiv {
                css {
                    display = Display.flex
                }

                mTypography(
                    text = state.line,
                    variant = MTypographyVariant.h1,
                    noWrap = true
                ) {
                    css {
                        position = Position.absolute
                        width = 14.vw
                        fontFamily = "arial"
                        fontWeight = FontWeight.bold
                        fontSize = 10.vh
                        textAlign = TextAlign.center
                        paddingTop = 2.vh
                        paddingLeft = 2.vw
                    }

                }
                mTypography(
                    text = state.destination,
                    variant = MTypographyVariant.h1,
                    noWrap = true
                ) {
                    css {
                        position = Position.absolute
                        fontFamily = "arial"
                        fontWeight = FontWeight.bold
                        fontSize = 10.vh
                        paddingTop = 2.vh
                        paddingLeft = 1.25.vw
                        left = 14.vw
                    }
                }
                mTypography(
                    text = if(state.stop) "HÄLT" else "",
                    variant = MTypographyVariant.h1,
                    noWrap = true
                ) {
                    css {
                        position = Position.absolute
                        color = Color.red
                        fontFamily = "arial"
                        fontWeight = FontWeight.bold
                        fontSize = 10.vh
                        paddingTop = 2.vh
                        paddingLeft = 1.25.vw
                        left = 80.vw
                    }
                }
            }
            styledDiv {
                css {  }
                state.next4.forEachIndexed { index, station ->
                    mTypography(
                        text = station,
                        variant = MTypographyVariant.body1,
                        noWrap = true
                    ) {

                        css {
                            fontWeight = FontWeight.normal
                            position = Position.absolute
                            fontSize = 10.vh
                            left = 12.5.vw
                            bottom = LinearDimension("${1 + index * 9.75}vw")
                        }
//                        if(index == 0) {
//                            css {
//                                fontWeight = FontWeight.w500
//                                position = Position.absolute
//                                fontFamily = "arial"
//                                fontSize = 10.vh
//                                left = 12.5.vw
//                                bottom = LinearDimension("${2 + index * 9.1}vw")
//                            }
//                        } else {
//
//                        }
                    }
                }

            }
        }


    }
}