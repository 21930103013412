package de.geomobile.frontend.features.softwareManagement.queue

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonSize
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.dialog.mDialog
import com.ccfraser.muirwik.components.dialog.mDialogActions
import com.ccfraser.muirwik.components.dialog.mDialogContent
import com.ccfraser.muirwik.components.list.*
import com.ccfraser.muirwik.components.menu.mMenu
import com.ccfraser.muirwik.components.menu.mMenuItem
import com.ccfraser.muirwik.components.styles.Breakpoint
import de.geomobile.common.portalmodels.DeviceIdentifier
import de.geomobile.common.portalmodels.parseCpuId
import de.geomobile.common.softwaremgmt.QueueDTO
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.api.TopicSession
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.portalWebSocketApi
import de.geomobile.frontend.spacer
import de.geomobile.frontend.utils.*
import kotlinext.js.jsObject
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.css.*
import kotlinx.html.InputType
import kotlinx.serialization.json.Json
import org.w3c.dom.Node
import react.*
import styled.css
import styled.styledDiv
import kotlin.math.max
import kotlin.math.min

fun RBuilder.updateQueue(drawerMenu: ReactElement, openDevice: (DeviceIdentifier.CpuId) -> Unit) =
    child(UpdateQueue::class) {
        attrs.openDevice = openDevice
        attrs.drawerMenu = drawerMenu
    }

private data class BlockOption(
    val text: String,
    val durationSec: Long,
)

private val blockOptions = listOf(
    BlockOption("5 Minutes", 5 * 60),
    BlockOption("10 Minutes", 10 * 60),
    BlockOption("30 Minutes", 30 * 60),
    BlockOption("1 Hour", 60 * 60),
    BlockOption("12 Hours", 12 * 60 * 60),
    BlockOption("1 Day", 24 * 60 * 60),
    BlockOption("7 Days", 7 * 24 * 60 * 60),
    BlockOption("30 Days", 30 * 24 * 60 * 60),
    BlockOption("Forever", -1)
)

class UpdateQueue : CComponent<UpdateQueue.Props, UpdateQueue.State>() {

    private var session: TopicSession? = null
    private var job: Job = Job()

    interface Props : RProps {
        var openDevice: (DeviceIdentifier.CpuId) -> Unit
        var drawerMenu: ReactElement
    }

    class State(
        var queue: QueueDTO = QueueDTO(0, emptyList()),
        var menuAnchor: Node? = null,
        var menuItem: QueueDTO.Item? = null,
        var showBlockDialog: Boolean = false,
        var selectedBlockOptionIndex: Int = 0,
        var newSlotCount: Int? = null,
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        session?.close()
        session = portalWebSocketApi.subscribe("/software/queue")
        session?.connect {
            onmessage = {
                val queue = Json.decodeFromString(QueueDTO.serializer(), it)
                setState {
                    this.queue = queue
                }
            }
        }
    }

    override fun componentWillUnmount() {
        session?.close()
    }

    override fun RBuilder.render() {
        mAppBar(position = MAppBarPosition.fixed) {
            css(GlobalStyles.appbar)
            mToolbar {
                child(props.drawerMenu)
                mToolbarTitle2("Update Queue")
            }
        }
        spacer()
        styledDiv {
            css { padding(2.spacingUnits) }
            mGridContainer2(direction = MGridDirection.row) {
                mGridItem2(
                    MGridBreakpoints2(MGridSize2.Cells4)
                        .down(Breakpoint.md, MGridSize2.Cells12)
                ) {
                    update()
                }
                mGridItem2(
                    MGridBreakpoints2(MGridSize2.Cells4)
                        .down(Breakpoint.md, MGridSize2.Cells12)
                ) {
                    waiting()
                }
                mGridItem2(
                    MGridBreakpoints2(MGridSize2.Cells4)
                        .down(Breakpoint.md, MGridSize2.Cells12)
                ) {
                    blocked()
                }
            }
            mMenu(
                open = state.menuAnchor != null,
                anchorElement = state.menuAnchor,
                onClose = { _, _ ->
                    setState {
                        menuAnchor = null
                    }
                }) {
                mMenuItem(
                    if (state.menuItem?.state == QueueDTO.State.BLOCKED)
                        "Entblocken"
                    else
                        "Blockieren",
                    onClick = {
                        if (state.menuItem!!.state == QueueDTO.State.BLOCKED) {
                            unblock(state.menuItem!!.id)
                        } else {
                            setState { showBlockDialog = true }
                        }
                        setState {
                            menuAnchor = null
                        }
                    })
            }
        }
        mDialog(
            open = state.showBlockDialog,
            onClose = { _, _ -> setState { showBlockDialog = false } }
        ) {
            mDialogContent {
                mRadioGroup {
                    for ((index, option) in blockOptions.withIndex()) {
                        mRadioWithLabel(option.text,
                            checked = index == state.selectedBlockOptionIndex,
                            onChange = { _, selected ->
                                if (selected) setState { selectedBlockOptionIndex = index }
                            })
                    }
                }
            }
            mDialogActions {
                mButton("Abbrechen", color = MColor.primary, onClick = {
                    setState { showBlockDialog = false }
                })
                mButton("Blockieren", color = MColor.primary, onClick = {
                    block(state.menuItem!!.id)
                    setState { showBlockDialog = false }
                })
            }
        }
        mDialog(
            open = state.newSlotCount != null,
            onClose = { _, _ -> setState { newSlotCount = null } }
        ) {
            mDialogContent {
                css {
                    minWidth = 400.px
                    overflow = Overflow.hidden
                    display = Display.flex
                    alignItems = Align.baseline
                }
                mSlider(value = state.newSlotCount ?: 1, min = 1, max = 100, step = 1, onChange = { value ->
                    val count = min(100, max(1, value))
                    setState { newSlotCount = count }
                }) {
                    css {
                        paddingRight = 2.spacingUnits
                    }
                }
                mTextField(
                    id = "asd",
                    label = "",
                    type = InputType.number,
                    value = state.newSlotCount.toString(),
                    onChange = {
                        val count = min(100, max(1, it.targetInputValue.toInt()))
                        setState { newSlotCount = count }
                    },
                    autoFocus = true
                ) {
                    css { width = 50.px }
                }
            }
            mDialogActions {
                mButton("Abbrechen", color = MColor.primary, onClick = {
                    setState { newSlotCount = null }
                })
                mButton("Übernehmen", color = MColor.primary, onClick = {
                    setSlots(state.newSlotCount!!)
                    setState { newSlotCount = null }
                })
            }
        }
    }

    private fun setSlots(slotCount: Int) {
        job.cancel()
        job = launch(Dispatchers.Default) {
            portalRestApi.put("/software/queue/slots", body = slotCount.toString())
        }
    }

    private fun block(id: String) {
        val duration = blockOptions[state.selectedBlockOptionIndex].durationSec

        job.cancel()
        job = launch(Dispatchers.Default) {
            if (duration > 0) portalRestApi.put("/software/queue/$id/block", body = duration.toString())
            else portalRestApi.put("/software/queue/$id/block")
        }
    }

    private fun unblock(id: String) {
        job.cancel()
        job = launch(Dispatchers.Default) {
            portalRestApi.delete("/software/queue/$id/block")
        }
    }

    private fun RBuilder.update() {
        val updates = state.queue.items.filter { it.state == QueueDTO.State.UPDATE }.sortedBy { it.since }

        mCard {
            css(GlobalStyles.card)
            mCardContent {
                css(GlobalStyles.cardListContent)
                styledDiv {
                    css {
                        display = Display.flex
                        justifyContent = JustifyContent.spaceBetween
                        alignItems = Align.center
                    }
                    mListSubheader(heading = "Slots (${updates.size}/${state.queue.updateSlotCount})")
                    mTooltip(title = "Slots erstellen") {
                        mButton(
                            caption = "Slots",
                            size = MButtonSize.small,
                            color = MColor.secondary,
                            variant = MButtonVariant.outlined,
                            onClick = {
                                setState { newSlotCount = queue.updateSlotCount }
                            }
                        ) {
                            css { margin(0.spacingUnits, 1.spacingUnits) }
                        }
                    }
                }
                mDivider { }
                itemList(updates, state.queue.updateSlotCount)
            }
        }
    }

    private fun RBuilder.waiting() {
        val waiting = state.queue.items.filter { it.state == QueueDTO.State.WAIT }.sortedBy { it.since }

        mCard {
            css(GlobalStyles.card)
            mCardContent {
                css(GlobalStyles.cardListContent)
                mListSubheader(heading = "Warteschlange (${waiting.size})")
                mDivider { }
                if (waiting.isEmpty()) {
                    mTypography(
                        text = "Keine Updates in der Warteschlange",
                        variant = MTypographyVariant.body2,
                        align = MTypographyAlign.left,
                        color = MTypographyColor.primary
                    ) { css { padding(2.spacingUnits) } }
                } else itemList(waiting)
            }
        }
    }

    private fun RBuilder.blocked() {
        val blocked = state.queue.items.filter { it.state == QueueDTO.State.BLOCKED }.sortedBy { it.blockedUntil }

        mCard {
            css(GlobalStyles.card)
            mCardContent {
                css(GlobalStyles.cardListContent)
                mListSubheader(heading = "Blockiert (${blocked.size})")
                mDivider { }
                if (blocked.isEmpty()) {
                    mTypography(
                        text = "Keine Updates blockiert",
                        variant = MTypographyVariant.body2,
                        align = MTypographyAlign.left,
                        color = MTypographyColor.primary
                    ) { css { padding(2.spacingUnits) } }
                } else itemList(blocked)
            }
        }
    }

    private fun RBuilder.itemList(items: List<QueueDTO.Item>, max: Int? = null) {
        mList {
            for (item in items) {
                val cpuId = item.id.parseCpuId()!!

                mListItem(
                    button = true,
                    divider = false,
                    onClick = { props.openDevice(cpuId) }
                ) {
                    mListItemText(
                        primary = item.id,
                        secondary = when (item.state) {
                            QueueDTO.State.WAIT -> "Wartend seit ${item.since.toText()}"
                            QueueDTO.State.UPDATE -> "Aktualisierend seit ${item.since.toText()}"
                            QueueDTO.State.BLOCKED -> "Blockiert bis ${item.blockedUntil?.toText() ?: "Dauerhaft"}"
                        }
                    ) {
                        attrs.primaryTypographyProps = jsObject {
                            variant = MTypographyVariant.body2
                        }
                        attrs.secondaryTypographyProps = jsObject {
                            variant = MTypographyVariant.caption
                        }
                    }
                    mListItemSecondaryAction {
                        mIconButtonNoTranslate(
                            iconName = "more_vert",
                            size = MButtonSize.small,
                            onClick = {
                                val anchor = it.currentTarget.asDynamic()
                                setState {
                                    menuAnchor = anchor
                                    menuItem = item
                                }
                                it.stopPropagation()
                            })
                    }
                }
            }

            if (max != null && items.size < max) {
                for (i in items.size until max) {
                    mListItem(
                        divider = false,
                        button = true,
                        dense = true
                    ) {
                        mListItemText(
                            primary = "Empty Slot",
                            secondary = "Dieser Slot ist aktuell leer"
                        ) {
                            attrs.primaryTypographyProps = jsObject {
                                variant = MTypographyVariant.body2
                            }
                            attrs.secondaryTypographyProps = jsObject {
                                variant = MTypographyVariant.caption
                            }
                        }
                    }
                }
            } else {
                mListItem(
                    divider = false,
                    button = true
                ) {
                    mListItemText(
                        primary = "Slots werden geladen"
                    ) {
                        attrs.primaryTypographyProps = jsObject {
                            variant = MTypographyVariant.body2
                        }
                        attrs.secondaryTypographyProps = jsObject {
                            variant = MTypographyVariant.caption
                        }
                    }
                }
            }
        }
    }
}