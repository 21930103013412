package de.geomobile.frontend.features.device.detail

import com.ccfraser.muirwik.components.styles.Breakpoint
import com.ccfraser.muirwik.components.styles.down
import de.geomobile.common.portalmodels.DevicePositionDTO
import de.geomobile.frontend.currentTheme
import de.geomobile.frontend.features.map.googleMap
import de.geomobile.frontend.features.map.googleMapCoords
import de.geomobile.frontend.features.map.marker
import de.geomobile.frontend.features.map.toGoogleMapCoords
import de.geomobile.frontend.portalWebSocketApi
import de.geomobile.frontend.utils.maps.googlemapreact.Coords
import de.geomobile.frontend.utils.maps.googlemapreact.MapOptions
import kotlinext.js.jsObject
import kotlinx.css.*
import kotlinx.serialization.json.Json
import react.*
import styled.css
import styled.styledDiv
import kotlin.js.Promise

fun RBuilder.deviceDetailMap(
    id: Int,
) = child(DeviceDetailMap::class) {
    key = id.toString()
    attrs.id = id
}

class DeviceDetailMap : RComponent<DeviceDetailMap.Props, DeviceDetailMap.State>() {

    private val session by kotlin.lazy {
        portalWebSocketApi.subscribe(
            topic = "/device/position",
            parameter = mapOf("id" to props.id.toString())
        )
    }

    interface Props : RProps {
        var id: Int
    }

    class State(
        var position: DevicePositionDTO? = null,
        var center: Coords? = googleMapCoords(
            latitude = 51.400427,
            longitude = 10.283918
        ),
        var zoom: Int? = 6,
        var locked: Boolean = true,
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        session.connect {
            onmessage = {
                val position = Json.decodeFromString(DevicePositionDTO.serializer(), it)
                setState {
                    if (center != null) {
                        this.center = position.position.location?.toGoogleMapCoords()
                        if (this.position?.position?.location == null)
                            zoom = 15
                    }
                    this.position = position
                }
            }
        }
    }

    override fun componentWillUnmount() {
        session.close()
    }

    override fun RBuilder.render() {
        styledDiv {
            css {
                display = Display.flex
                flexDirection = FlexDirection.column
                media(currentTheme.breakpoints.down(Breakpoint.md)) {
                    height = 350.px
                }
                height = 100.pct
            }

            googleMap {
                attrs {
                    if (state.position?.position?.location != null && state.locked) {
                        center = state.position!!.position.location!!.toGoogleMapCoords()
                        zoom = state.zoom
                    } else {
                        center = null
                        zoom = null
                    }
                    defaultCenter = googleMapCoords(
                        latitude = 51.400427,
                        longitude = 10.283918
                    )
                    defaultZoom = 6
                    options = jsObject<MapOptions> {
                        mapTypeControl = true
                    }
                    googleMapLoader = { Promise.resolve(js("google.maps") as Any) }
                    onDrag = {
                        setState {
                            this.locked = false
                        }
                    }
                }
                state.position?.let { position ->
                    position.position.location?.let { location ->
                        marker {
                            enableTransform = true
                            currentZoom = state.zoom ?: 6
                            key = position.id.toString()
                            lat = location.latitude
                            lng = location.longitude

                            id = position.id
                            vehicleId = position.vehicleId
                            serialNumber = position.serialNumber
                            vehicleType = position.vehicleType
                            statusLocation = position.position.lastLocation.status
                            statusLastSeen = position.position.lastSeen.status
                            gpsQuality = 100
                            onClick = {
                                setState {
                                    locked = true
                                    this.zoom = 15
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}