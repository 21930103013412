package de.geomobile.frontend.features.device.list

import de.geomobile.common.filter.FilterEnumerableString
import de.geomobile.common.filter.FilterListBuilder
import de.geomobile.common.filter.FilterLong
import de.geomobile.common.filter.FilterString
import de.geomobile.common.portalmodels.Company
import de.geomobile.common.portalmodels.Product
import de.geomobile.common.portalmodels.VehicleProfileDTO
import de.geomobile.common.portalmodels.VehicleType
import de.geomobile.common.stage.Stage

class DeviceListItemFilterList(
    val products: List<Product>,
    val companies: List<Company>,
    val vehicleProfiles: List<VehicleProfileDTO>,
) : FilterListBuilder<DeviceListItem>(
    FilterEnumerableString(
        id = "product",
        name = "Produkt",
        elementToValue = { device -> device.product.readableName },
        values = products.associate { it.readableName to it.readableName }
    ),
    FilterLong(
        id = "serialNumber",
        name = "Seriennummer",
        elementToValue = { device -> device.serialNumber?.toLong() }
    ),
    FilterString(
        id = "cpuId",
        name = "Cpu Id",
        queryName = "CpuId",
        elementToValue = { device -> device.cpuId }
    ),
    FilterEnumerableString(
        id = "company",
        name = "Unternehmen",
        elementToValue = { device -> device.company.id },
        values = companies.associate { it.name to it.id }
    ),
    FilterEnumerableString(
        id = "stage",
        name = "Stage",
        elementToValue = { device -> device.stage.name },
        values = Stage.values().associate { it.readableName to it.name }
    ),
    FilterEnumerableString(
        id = "vehicleType",
        name = "Fahrzeugtyp",
        elementToValue = { device -> device.vehicleType.name },
        values = VehicleType.values().associate { it.readableName to it.name }
    ),
    FilterString(
        id = "vehicleId",
        name = "Fahrzeugnummer",
        queryName = "Fahrzeugnummer",
        elementToValue = { device -> device.vehicleId }
    ),
    FilterString(
        id = "description",
        name = "Beschreibung",
        queryName = "Beschreibung",
        elementToValue = { device -> device.description }
    ),
    FilterEnumerableString(
        id = "vehicleProfile",
        name = "Fahrzeugprofil",
        elementToValue = { device -> device.vehicleProfile?.id ?: "-" },
        values = vehicleProfiles.associate { it.name to it.id }
    ),
    FilterEnumerableString(
        id = "sharingWith",
        name = "Geteilt mit",
        elementToValue = { device -> device.sharingWith.map { it.id }.joinToString(" ") },
        values = companies.associate { it.name to it.id }
    )
)