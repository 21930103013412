package de.geomobile.frontend.features.softwareManagement.assignment

import com.ccfraser.muirwik.components.MGridDirection
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardActions
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.mDivider
import com.ccfraser.muirwik.components.styles.Breakpoint
import components.emptyView
import de.geomobile.common.filter.Filter
import de.geomobile.common.portalmodels.Company
import de.geomobile.common.portalmodels.DeviceIdentifier
import de.geomobile.common.portalmodels.Product
import de.geomobile.common.portalmodels.VehicleProfileDTO
import de.geomobile.common.softwaremgmt.SoftwareAssignmentFilter
import de.geomobile.common.utils.Tree
import de.geomobile.common.utils.emptyTree
import de.geomobile.common.utils.tree
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.features.device.list.DeviceListItem
import de.geomobile.frontend.features.device.list.DeviceListItemFilterList
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.spacer
import de.geomobile.frontend.utils.*
import kotlinx.coroutines.*
import kotlinx.css.Color
import kotlinx.css.JustifyContent
import kotlinx.css.justifyContent
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.builtins.serializer
import kotlinx.serialization.json.Json
import react.RBuilder
import react.RProps
import react.RState
import react.setState
import styled.StyledElementBuilder
import styled.css
import kotlin.math.max
import kotlin.math.min

fun RBuilder.assignmentManagement(
    selectedId: Int?,
    product: Product,
    changeSelection: (selectedId: Int?) -> Unit,
    onDeviceClick: (id: DeviceIdentifier) -> Unit,
) = child(AssignmentManagement::class) {
    attrs.selectedId = selectedId
    attrs.changeSelection = changeSelection
    attrs.onDeviceClick = onDeviceClick
    attrs.product = product
}

interface SelectedAssignmentFilterProps : RProps {
    var selectedId: String?
}

class AssignmentManagement : CComponent<AssignmentManagement.Props, AssignmentManagement.State>() {

    private var loadJob: Job = Job()
    private var saveJob: Job = Job()

    interface Props : RProps {
        var selectedId: Int?
        var product: Product
        var changeSelection: (selectedId: Int?) -> Unit
        var onDeviceClick: (id: DeviceIdentifier) -> Unit
    }

    class State(
        var filterTree: Tree<SoftwareAssignmentFilter> = emptyTree(),
        var deviceFilter: Map<String, Filter<DeviceListItem, *>> = emptyMap(),
        var changedTree: Tree<SoftwareAssignmentFilter?>? = null,
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        loadFilter()
    }

    override fun componentWillReceiveProps(nextProps: Props) {
        if (nextProps.selectedId != props.selectedId && (nextProps.selectedId == null || nextProps.selectedId!! >= 0)) {
            setState { changedTree = null }
        }
        loadFilter()
    }

    private fun loadFilter() {
        loadJob.cancel()
        loadJob = launch {
            val deferredCompanies = async(Dispatchers.Default) {
                portalRestApi.get("/admin/companies", ListSerializer(Company.serializer()))
            }
            val filterTree = withContext(Dispatchers.Default) {
                portalRestApi.get(
                    "/software/assignment/get",
                    serializer = SoftwareAssignmentFilter.serializer().tree,
                    parameter = mapOf("product" to props.product.readableName)
                )
            }

            val deferredVehicleProfiles = async(Dispatchers.Default) {
                portalRestApi.get("/vehicleprofiles", ListSerializer(VehicleProfileDTO.serializer()))
            }

            val companies = deferredCompanies.await()
            val vehicleProfiles = deferredVehicleProfiles.await()
            val deviceListItemDtoFilters: Map<String, Filter<DeviceListItem, *>> = DeviceListItemFilterList(
                products = Product.values().asList(), companies = companies, vehicleProfiles = vehicleProfiles
            ).filters

            setState {
                this.filterTree = filterTree
                this.deviceFilter = deviceListItemDtoFilters
            }
        }
    }

    override fun RBuilder.render() {
        spacer()
        mGridContainer2(direction = MGridDirection.row) {
            mGridItem2(
                MGridBreakpoints2(MGridSize2.Cells3)
                    .down(Breakpoint.md, MGridSize2.Cells12)
            ) {
                filterList()
            }
            mGridItem2(
                MGridBreakpoints2(MGridSize2.Cells9)
                    .down(Breakpoint.md, MGridSize2.Cells12)
            ) {
                detail()
            }
        }
    }

    private fun StyledElementBuilder<MGridProps2>.filterList() {
        val tree: Tree<SoftwareAssignmentFilter?> =
            (state.changedTree ?: state.filterTree) as Tree<SoftwareAssignmentFilter?>

        val entryTree = tree.mapByBranch { branch, (value) ->
            val id = value?.id ?: -1
            TreeEntry(key = id.toString(),
                name = value?.name ?: "Neuer Filter",
                new = value == null,
                color = when {
                    value == null -> null
                    !value.filterValid -> Color.red
                    branch.dropLast(1)
                        .any { it.value!!.assignment.assignedAt > value.assignment.assignedAt } -> Color.gray

                    value.assignment.canceled -> Color.red
                    else -> null
                },
                selected = id == props.selectedId,
                expandedByDefault = branch.size <= 1,
                onClick = { props.changeSelection(id) })
        }

        mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
            mCard {
                css(GlobalStyles.card)
                mCardContent {
                    css(GlobalStyles.cardListContent)
                    if (tree.rootNodes.isEmpty())
                        emptyView(
                            boxShadow = false,
                            iconName = "menu",
                            title = "Filter",
                            caption = "Es wurden keine Filter gefunden",
                            addButton = false
                        )
                    else {
                        tree(heading = "Filter", tree = entryTree)
                    }
                }
                mDivider { }
                mCardActions {
                    css { justifyContent = JustifyContent.spaceEvenly }
                    treeOperations(
                        tree = entryTree,
                        onMoveSelectedNode = { operation ->
                            val newTree = tree.transformSiblings(
                                select = { (it?.id ?: -1) == props.selectedId },
                                transform = { siblings ->
                                    val index = siblings.indexOfFirst {
                                        (it.value?.id ?: -1) == props.selectedId
                                    }

                                    siblings.toMutableList().apply {
                                        removeAt(index)
                                        add(max(0, min(siblings.lastIndex, index + operation)), siblings[index])
                                    }
                                })

                            setState { changedTree = newTree.takeIf { it != filterTree } }
                        },
                        onAddTopLevel = {
                            val newTree: Tree<SoftwareAssignmentFilter?> = Tree(
                                tree.rootNodes + Tree.Node(value = null as SoftwareAssignmentFilter?)
                            )

                            setState { changedTree = newTree }
                            props.changeSelection(-1)
                        },
                        onAddChild = {
                            val newTree = tree.plusChild(
                                selectParent = { it?.id == props.selectedId },
                                newNode = Tree.Node(value = null)
                            )

                            setState { changedTree = newTree }
                            props.changeSelection(-1)
                        },
                        onAddSibling = {
                            val newTree = tree.plusSibling(
                                select = { (it?.id ?: -1) == props.selectedId },
                                newNode = Tree.Node(value = null)
                            )

                            setState { changedTree = newTree }
                            props.changeSelection(-1)
                        },
                        onSelectNewParent = { parent ->
                            val node = tree.findNode { (it?.id ?: -1) == props.selectedId }!!
                            val newTree = tree.filter { (it?.id ?: -1) != props.selectedId }.plusChild(
                                selectParent = { it?.id == parent?.key?.toInt() },
                                newNode = node
                            )

                            setState { changedTree = newTree }
                        },
                        onDelete = {
                            deleteSoftwareAssignment()
                        }
                    )
                }
            }
        }
    }

    private fun StyledElementBuilder<MGridProps2>.detail() {
        val branch = props.selectedId?.let { selectedId ->
            (state.changedTree ?: state.filterTree).findBranch { (it?.id ?: -1) == selectedId }
        }
        val selected = branch?.lastOrNull()

        mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
            if (branch?.isNotEmpty() == true) {
                val newParent = state.changedTree?.findParent { it == selected }?.id
                val newPosition = state.changedTree?.findSiblings { it == selected }?.indexOf(selected)

                assignmentDetail(
                    newParentId = newParent,
                    newPosition = newPosition,
                    product = props.product,
                    softwareAssignmentFilter = selected,
                    branch = branch,
                    deviceFilter = state.deviceFilter,
                    onSaved = {
                        loadFilter()
                        props.changeSelection(it)
                    },
                    onDeviceClick = props.onDeviceClick
                )
            } else {
                emptyView(
                    title = "Filter",
                    caption = "Es wurde kein Filter ausgewählt",
                    addButton = false
                )
            }
        }
    }

    private fun deleteSoftwareAssignment() {
        if (props.selectedId != null) {
            saveJob.cancel()
            saveJob = launch {
                withContext(Dispatchers.Default) {
                    portalRestApi.delete(
                        path = "/software/assignment/${props.selectedId}",
                        body = Json.encodeToString(
                            String.serializer(), props.product.readableName
                        ),
                    )
                }
                loadFilter()
                props.changeSelection(null)
            }
        }
    }
}