package de.geomobile.frontend.features.device.detail.internal

import com.ccfraser.muirwik.components.MColor
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.dialog.mDialog
import com.ccfraser.muirwik.components.dialog.mDialogActions
import com.ccfraser.muirwik.components.dialog.mDialogTitle
import com.ccfraser.muirwik.components.mTooltip
import com.ccfraser.muirwik.components.spacingUnits
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.utils.CComponent
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.Color
import kotlinx.css.color
import kotlinx.css.marginLeft
import react.RBuilder
import react.RProps
import react.RState
import react.setState
import styled.css

fun RBuilder.deviceDelete(
    id: Int,
    onDeleted: () -> Unit,
) = child(DeviceDeleteComponent::class) {
    key = id.toString()
    attrs.id = id
    attrs.onDeleted = onDeleted
}

class DeviceDeleteComponent : CComponent<DeviceDeleteComponent.Props, DeviceDeleteComponent.State>() {

    interface Props : RProps {
        var id: Int
        var onDeleted: () -> Unit
    }

    class State(
        var confirmDialog: Boolean = false
    ) : RState

    init {
        state = State()
    }

    override fun RBuilder.render() {
        mTooltip("Gerät löschen") {
            mButton(
                caption = "Löschen",
                color = MColor.inherit,
                variant = MButtonVariant.outlined,
                onClick = { setState { confirmDialog = true } }
            ) { css { marginLeft = 1.spacingUnits } }
        }
        mDialog(open = state.confirmDialog) {
            mDialogTitle(text = "Soll das Gerät wirklich gelöscht werden?")
            mDialogActions {
                mButton(
                    caption = "Abbrechen",
                    onClick = { setState { confirmDialog = false } }
                )
                mButton(
                    caption = "Ja",
                    onClick = { sendDelete() }
                ) {
                    css {
                        color = Color.red
                    }
                }
            }
        }
    }

    private fun sendDelete() = launch {
        withContext(Dispatchers.Default) {
            portalRestApi.delete("/device/${props.id}")
        }

        props.onDeleted()
    }
}