package de.geomobile.frontend.features.device.detail.internal

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.list.mList
import com.ccfraser.muirwik.components.list.mListItem
import com.ccfraser.muirwik.components.list.mListSubheader
import de.geomobile.common.portalmodels.DeviceConfig
import de.geomobile.common.utils.indentedJson
import de.geomobile.frontend.features.config.deviceConfigOverlay
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.portalWebSocketApi
import de.geomobile.frontend.utils.CComponent
import de.geomobile.frontend.utils.isValidJson
import de.geomobile.frontend.utils.jsonEditor
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.css.marginLeft
import kotlinx.css.padding
import kotlinx.css.px
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.JsonObjectBuilder
import kotlinx.serialization.json.buildJsonObject
import react.RBuilder
import react.RProps
import react.RState
import react.setState
import styled.css
import styled.styledDiv

fun RBuilder.configStatus(
    id: Int,
) = child(ConfigStatusComponent::class) {
    key = id.toString()
    attrs.id = id
}

class ConfigStatusComponent : CComponent<ConfigStatusComponent.Props, ConfigStatusComponent.State>() {

    private var sendJob: Job = Job()

    private val deviceM2MSocket by lazy {
        portalWebSocketApi.subscribe("/device/config", mapOf("id" to props.id.toString()))
    }

    interface Props : RProps {
        var id: Int
        var configExpanded: Boolean
        var onConfigExpanded: () -> Unit
    }

    class State(
        var deviceConfig: DeviceConfig = DeviceConfig(
            deactivateConfigs = false,
            currentConfig = buildJsonObject(fun JsonObjectBuilder.() {}),
            individualConfigOverlay = buildJsonObject(fun JsonObjectBuilder.() {}),
            configFilterCascade = emptyList(),
            generatedConfigOverlay = buildJsonObject(fun JsonObjectBuilder.() {})
        ),
        var sending: Boolean = false,
        var error: String? = null,
        var validConfigOverlay: Boolean = true,
        var newConfigOverlay: String? = null
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        deviceM2MSocket.connect {
            onmessage = {
                val deviceConfig = Json.decodeFromString(DeviceConfig.serializer(), it)

                setState {
                    this.deviceConfig = deviceConfig
                }
            }
        }
    }

    override fun componentWillUnmount() {
        super.componentWillUnmount()
        deviceM2MSocket.close()
    }

    override fun RBuilder.render() {
        val currentConfig = Json.encodeToString(JsonObject.serializer(), state.deviceConfig.currentConfig)
        val overlay = indentedJson.encodeToString(JsonObject.serializer(), state.deviceConfig.individualConfigOverlay)
        val configEdit = state.newConfigOverlay

        styledDiv {
            css { padding(2.spacingUnits) }
            when {
                configEdit == null -> mButton(
                    variant = MButtonVariant.contained,
                    caption = "Bearbeiten",
                    color = MColor.secondary,
                    onClick = { setState { this.newConfigOverlay = overlay } },
                    addAsChild = true
                ) {
                    attrs.disableElevation = true
                }

                state.sending -> {
                    mButton(
                        variant = MButtonVariant.contained,
                        caption = "Laden...",
                        color = MColor.default,
                        disabled = true,
                        addAsChild = true
                    ) {
                        attrs.disableElevation = true
                    }
                }

                !state.sending -> {
                    mButton(
                        variant = MButtonVariant.contained,
                        caption = "Übernehmen",
                        color = MColor.secondary,
                        disabled = !state.validConfigOverlay,
                        onClick = { saveConfig(configEdit) },
                        addAsChild = true
                    ) {
                        attrs.disableElevation = true
                    }
                    mButton(
                        variant = MButtonVariant.contained,
                        caption = "Abbrechen",
                        color = MColor.default,
                        disabled = !state.validConfigOverlay,
                        onClick = { setState { this.newConfigOverlay = null } },
                        addAsChild = true
                    ) {
                        css { marginLeft = 1.spacingUnits }
                        attrs.disableElevation = true
                    }
                }
            }
        }
        mDivider {}
        if (state.deviceConfig.deactivateConfigs) {
            styledDiv {
                css { padding(2.spacingUnits) }
                mTypography(
                    text = "------- DEACTIVATED -------",
                    color = MTypographyColor.error,
                    variant = MTypographyVariant.h6,
                    align = MTypographyAlign.center
                )
            }
            mDivider { }
        }
        styledDiv {
            css { padding(2.spacingUnits) }

            mList {
                attrs.disablePadding = true

                mListSubheader(heading = "Assigned Config Overlays")
                if (state.deviceConfig.configFilterCascade.isEmpty())
                    mListItem(
                        primaryText = "None",
                    ) { attrs.divider = false; attrs.button = false }

                for (configFilter in state.deviceConfig.configFilterCascade) {
                    mListItem(
                        primaryText = configFilter.name,
                        hRefOptions = HRefOptions(
                            href = "/portal/config/${configFilter.id}"
                        ),
                    ) { attrs.divider = false }
                }
            }

            deviceConfigOverlay(
                overlayTitle = "Device Specific Config Overlay",
                overlay = state.deviceConfig.individualConfigOverlay,
                overlayEdit = configEdit,
                preceding = state.deviceConfig.configFilterCascade
                    .map { it.configOverlay }
                    .plusElement(state.deviceConfig.generatedConfigOverlay),
                onChange = { valid, _, raw ->
                    setState {
                        validConfigOverlay = valid
                        newConfigOverlay = raw
                    }
                }
            )
            mListSubheader(heading = "Current Config")
            styledDiv {
                css { padding(0.spacingUnits, 2.spacingUnits) }
                jsonEditor(
                    json = currentConfig,
                    edit = false
                )
            }
        }
    }

    private fun saveConfig(config: String) {
        if (!config.isValidJson()) return

        setState {
            this.sending = true
        }
        sendJob.cancel()
        sendJob = launch {
            launch(Dispatchers.Default) {
                portalRestApi.put("/device/${props.id}/configOverlay", config)
            }.join()
            setState {
                this.newConfigOverlay = null
                this.sending = false
            }
        }
    }
}