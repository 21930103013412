package de.geomobile.frontend.features.device.detail

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonSize
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.dialog.*
import com.ccfraser.muirwik.components.form.mFormGroup
import com.ccfraser.muirwik.components.list.mListItem
import com.ccfraser.muirwik.components.list.mListItemSecondaryAction
import com.ccfraser.muirwik.components.list.mListItemText
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.utils.CComponent
import kotlinx.browser.document
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.*
import react.RBuilder
import react.RProps
import react.RState
import react.setState
import styled.css

// TODO: Clean up
//fun RBuilder.rowChooseValues(
//    label: String,
//    allowEdit: Boolean,
//    selectionTitle: (Any) -> String,
//    loadValues: suspend () -> List<Any>,
//    loadSelectedValues: () -> Set<Any>,
//    loadApplyPath: () -> String,
//    loadBody: (Set<Any>) -> (String),
//    captionSize: MGridSize = MGridSize.cells2,
//    contentSize: MGridSize = MGridSize.cells10,
//) = row(label, captionSize = captionSize, contentSize = contentSize) {
//
//    child(ChooseValueComponent::class) {
//        attrs.label = label
//        attrs.allowEdit = allowEdit
//        attrs.selectionTitle = selectionTitle
//        attrs.loadValues = loadValues
//        attrs.loadSelectedValues = loadSelectedValues
//        attrs.loadApplyPath = loadApplyPath
//        attrs.loadBody = loadBody
//        attrs.selectMultiple = true
//    }
//}

// TODO: Clean up
//fun RBuilder.tableRowChooseValues(
//    label: String,
//    allowEdit: Boolean,
//    selectionTitle: (Any) -> String,
//    loadValues: suspend () -> List<Any>,
//    loadSelectedValues: () -> Set<Any>,
//    loadApplyPath: () -> String,
//    loadBody: (Set<Any>) -> (String),
//) = tableRow(label, wrapHandler = false) {
//
//    child(ChooseValueComponent::class) {
//        attrs.label = label
//        attrs.allowEdit = allowEdit
//        attrs.selectionTitle = selectionTitle
//        attrs.loadValues = loadValues
//        attrs.loadSelectedValues = loadSelectedValues
//        attrs.loadApplyPath = loadApplyPath
//        attrs.loadBody = loadBody
//        attrs.selectMultiple = true
//        attrs.asTableCell = true
//    }
//}

fun RBuilder.tableRowChooseValue(
    label: String,
    allowEdit: Boolean,
    selectionTitle: (Any) -> String,
    loadValues: suspend () -> List<Any>,
    loadSelectedValue: () -> Any?,
    loadApplyPath: () -> String,
    loadBody: (Any) -> (String),
) {
    child(ChooseValueComponent::class) {
        attrs.label = label
        attrs.allowEdit = allowEdit
        attrs.selectionTitle = selectionTitle
        attrs.loadValues = { loadValues() }
        attrs.loadSelectedValues = { if (loadSelectedValue() == null) setOf() else setOf(loadSelectedValue()!!) }
        attrs.loadApplyPath = loadApplyPath
        attrs.loadBody = { set -> loadBody(set.first()) }
        attrs.selectMultiple = false
    }
}

// TODO: Clean up
//fun RBuilder.rowChooseValue(
//    label: String,
//    allowEdit: Boolean,
//    selectionTitle: (Any) -> String,
//    loadValues: suspend () -> List<Any>,
//    loadSelectedValue: () -> Any?,
//    loadApplyPath: () -> String,
//    loadBody: (Any) -> (String),
//    captionSize: MGridSize = MGridSize.cells2,
//    contentSize: MGridSize = MGridSize.cells10,
//) = row(label, captionSize = captionSize, contentSize = contentSize) {
//
//    child(ChooseValueComponent::class) {
//        attrs.label = label
//        attrs.allowEdit = allowEdit
//        attrs.selectionTitle = selectionTitle
//        attrs.loadValues = { loadValues() }
//        attrs.loadSelectedValues = { if (loadSelectedValue() == null) setOf() else setOf(loadSelectedValue()!!) }
//        attrs.loadApplyPath = loadApplyPath
//        attrs.loadBody = { set -> loadBody(set.first()) }
//        attrs.selectMultiple = false
//    }
//}

interface RChooseValuesComponentProps : RProps {
    var label: String
    var selectMultiple: Boolean
    var allowEdit: Boolean
    var selectionTitle: (Any) -> String
    var loadValues: suspend () -> List<Any>
    var loadSelectedValues: () -> Set<Any>
    var loadApplyPath: () -> String
    var loadBody: (Set<Any>) -> (String)
}

class ChooseValueComponent : CComponent<RChooseValuesComponentProps, ChooseValueComponent.State>() {

    private var chooseValue: Job = Job()

    sealed class ChooseValueState {
        object Idle : ChooseValueState()
        sealed class Editing : ChooseValueState() {
            object LoadingValues : Editing()

            data class Selecting(val selectableValues: List<Any>, val selectedValues: Set<Any>) :
                Editing()

            data class Applying(val selectableValues: List<Any>, val selectedValues: Set<Any>) :
                Editing()
        }
    }

    class State(
        var renderState: ChooseValueState = ChooseValueState.Idle,
    ) : RState

    init {
        state = State()
    }

    override fun RBuilder.render() {
        val selectedValues = props.loadSelectedValues()

        if (props.selectMultiple) {
            if (selectedValues.isEmpty()) {
                mTypography(
                    text = "-",
                    variant = MTypographyVariant.subtitle2,
                    color = MTypographyColor.textPrimary
                )
            }

            for (value in selectedValues) {
                mChip(label = props.selectionTitle(value)) {
                    css { marginRight = 1.spacingUnits }
                }
            }
        } else {
            mListItem {
                attrs.button = false
                attrs.divider = true

                mListItemText {
                    mTypography(
                        text = props.label,
                        variant = MTypographyVariant.caption,
                    )
                    mTypography(
                        text = if (selectedValues.isEmpty()) "-" else props.selectionTitle(selectedValues.first()),
                        variant = MTypographyVariant.subtitle2,
                        color = MTypographyColor.textPrimary
                    )
                }
                if (props.allowEdit)
                    mListItemSecondaryAction {
                        mTooltip("Bearbeiten") {
                            mIconButtonNoTranslate(
                                iconName = "edit",
                                color = MColor.inherit,
                                size = MButtonSize.small,
                                onClick = { show() },
                                addAsChild = true
                            ) {
                                css { background = "#2196f3"; color = Color.white; }
                            }
                        }
                    }
            }
        }

        val open = state.renderState is ChooseValueState.Editing
        val currentState = state.renderState

        mDialog(
            open,
            scroll = DialogScroll.paper,
            onClose = { _, _ -> hide() }
        ) {
            attrs.fullWidth = true
            attrs.disableEscapeKeyDown = true

            mDialogTitle(props.label)
            mDialogContent {
                css { minWidth = 200.px }
                if (currentState !is ChooseValueState.Editing.Selecting) {
                    mCircularProgress {
                        css {
                            display = Display.block
                            margin(
                                vertical = 2.spacingUnits, horizontal = LinearDimension.auto
                            )
                        }
                    }
                } else {
                    mFormGroup {
                        if (props.selectMultiple) {
                            for (value in currentState.selectableValues) {
                                mCheckboxWithLabel(
                                    label = props.selectionTitle(value),
                                    checked = currentState.selectedValues.contains(value),
                                    onChange = { _, checked ->
                                        changeValue(value, checked)
                                    }
                                )
                            }
                        } else {
                            mRadioGroup(name = props.label) {
                                for (value in currentState.selectableValues) {
                                    mRadioWithLabel(
                                        props.selectionTitle(value),
                                        checked = currentState.selectedValues.contains(value),
                                        onChange = { _, selected ->
                                            chooseValue(value, selected)
                                        })
                                }
                            }
                        }
                    }
                }
            }
            mDialogActions {
                mButton(
                    "Abbrechen",
                    color = MColor.primary,
                    onClick = {
                        hide()
                    }
                )
                mButton(
                    "Speichern",
                    disabled = currentState !is ChooseValueState.Editing.Selecting,
                    color = MColor.primary,
                    onClick = {
                        if (currentState is ChooseValueState.Editing.Selecting)
                            apply()
                    }
                )
            }
        }

        document.onkeydown = {
            if (open && it.keyCode == 13) // User hits enter
                apply()
        }
    }

    private fun show() {
        setState {
            renderState = ChooseValueState.Editing.LoadingValues
        }

        chooseValue.cancel()
        chooseValue = launch {
            val allValues = withContext(Dispatchers.Default) {
                props.loadValues()
            }

            val selectedValues = withContext(Dispatchers.Default) {
                props.loadSelectedValues()
            }

            setState {
                renderState = ChooseValueState.Editing.Selecting(
                    selectableValues = allValues,
                    selectedValues = selectedValues
                )
            }
        }
    }

    private fun hide() {
        setState {
            renderState = ChooseValueState.Idle
        }
    }

    private fun chooseValue(newValue: Any, selected: Boolean) {
        (state.renderState as? ChooseValueState.Editing.Selecting)?.let {
            setState {
                if (selected) {
                    renderState = it.copy(selectedValues = setOf(newValue))
                }
            }
        }
    }

    private fun changeValue(newValue: Any, selected: Boolean) {
        (state.renderState as? ChooseValueState.Editing.Selecting)?.let {
            setState {
                renderState = if (selected) {
                    it.copy(selectedValues = it.selectedValues + newValue)
                } else {
                    it.copy(selectedValues = it.selectedValues - newValue)
                }
            }
        }
    }

    private fun apply() {

        (state.renderState as? ChooseValueState.Editing.Selecting)?.let {
            setState {
                renderState = ChooseValueState.Editing.Applying(it.selectableValues, it.selectedValues)
            }
            chooseValue.cancel()
            chooseValue = launch {
                launch(Dispatchers.Default) {

                    val path = props.loadApplyPath()
                    console.log(path)

                    val body = props.loadBody(it.selectedValues)

                    console.log(body)

                    portalRestApi.put(path, body)

                }.join()

                setState {
                    renderState = ChooseValueState.Idle
                }
            }
        }
    }
}