package de.geomobile.frontend.features.companyProfile

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardActions
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.form.MFormControlMargin
import com.ccfraser.muirwik.components.form.MFormControlVariant
import com.ccfraser.muirwik.components.form.mFormControl
import com.ccfraser.muirwik.components.list.mListSubheader
import com.ccfraser.muirwik.components.menu.mMenuItem
import components.emptyView
import de.geomobile.common.permission.Permissions
import de.geomobile.common.portalmodels.UserDTO
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.utils.*
import kotlinx.browser.localStorage
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.*
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.json.Json
import org.w3c.dom.get
import portalmodels.IbisProfileDTO
import react.RBuilder
import react.RProps
import react.RState
import react.setState
import styled.css
import styled.styledDiv

fun RBuilder.companyProfileIBIS() = child(CompanyProfileIBIS::class) {}

class CompanyProfileIBIS : CComponent<CompanyProfileIBIS.Props, CompanyProfileIBIS.State>() {

    private var companyJob: Job = Job()
    private var dataJob: Job = Job()
    private var fetchIbisProfilesJob: Job = Job()

    interface Props : RProps {}

    class State(
        var company: String? = null,
        var profileActive: Boolean = false,
        var profiles: List<IbisProfileDTO>? = null,
        var selectedIndex: Int = -1,
        var saveSuccess: Boolean = false
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        launch {
            loadCompany()
        }.invokeOnCompletion {
            fetchProfiles(state.company)
        }
    }

    suspend fun loadCompany() {
        val companyId =
            if (isAuthorized(Permissions.VehicleProfileManagement.notRestrictedToCompany) && localStorage["CompanyProfile"] != null) {
                localStorage["CompanyProfile"]
            } else {
                withContext(Dispatchers.Default) {
                    portalRestApi.get("/user", UserDTO.serializer())
                }.company.id
            }
        setState {
            company = companyId
        }
    }

    private fun fetchProfiles(companyId: String?) {
        fetchIbisProfilesJob.cancel()
        fetchIbisProfilesJob = launch {
            val profiles = portalRestApi.get(
                path = "/vehicleprofiles/ibis/profiles/${companyId}",
                serializer = ListSerializer(
                    IbisProfileDTO.serializer()
                )
            )

            val setProfile = portalRestApi.getRaw("/companyprofile/ibis/${companyId}")
            val setProfileId = if (setProfile == "null") -1
            else setProfile.toInt()

            setState {
                this.profiles = profiles.toMutableList()
                this.selectedIndex = setProfileId
                this.profileActive = setProfileId != -1
            }
        }
    }

    private fun saveIbisToCompany(companyId: String?, profile: IbisProfileDTO?) {
        launch {
            if (profile == null) {
                portalRestApi.get("/companyprofile/ibis/unset/${companyId}")
            } else {
                val bodyProfile = Json.encodeToJsonElement(IbisProfileDTO.serializer(), profile)
                portalRestApi.put("/companyprofile/ibis/set/${companyId}", bodyProfile)
            }
            setState { saveSuccess = true }
        }
    }

    override fun RBuilder.render() {
        mGridContainer2(direction = MGridDirection.row) {
            mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                mCard {
                    css(GlobalStyles.card)
                    mCardContent {
                        css(GlobalStyles.cardBoxContent)
                        if (state.profiles?.isEmpty() == false) {
                            mListSubheader(
                                heading = "IBIS Profil"
                            )
                            mDivider { }
                            styledDiv {
                                css { padding(2.spacingUnits) }

                                styledDiv {
                                    css { paddingBottom = 2.spacingUnits }

                                    mTypography(
                                        text = "Profil",
                                        variant = MTypographyVariant.caption
                                    ) { css { display = Display.block; marginBottom = 6.px } }
                                    mSelect(
                                        variant = MFormControlVariant.outlined,
                                        value = state.selectedIndex?.toString(),
                                        disabled = !state.profileActive,
                                        onChange = { event, _ ->
                                            val text = event.targetValue as String

                                            setState {
                                                selectedIndex = text.toInt()
                                                saveSuccess = false
                                            }
                                        }
                                    ) {
                                        attrs.margin = MFormControlMargin.dense.toString()
                                        attrs.fullWidth = true
                                        mMenuItem(
                                            primaryText = "Kein Profil ausgewählt",
                                            value = "-1"
                                        )
                                        state.profiles?.forEach {
                                            mMenuItem(
                                                primaryText = it.profileName,
                                                value = it.profileId.toString()
                                            )
                                        }
                                    }
                                }
                                styledDiv {
                                    css { padding(0.px, 1.spacingUnits) }
                                    mFormControl(fullWidth = true) {
                                        mCheckboxWithLabel(
                                            label = "Aktiv",
                                            checked = state.profileActive,
                                            onChange = { _, checked ->
                                                setState {
                                                    profileActive = checked
                                                    saveSuccess = false
                                                }
                                            }
                                        )
                                    }
                                }
                            }
                        } else {
                            emptyView(
                                title = "IBIS Profil",
                                caption = "Es wurden keine Profile für dieses Unternehmen gefunden",
                                addButton = false
                            )
                        }
                    }
                    if (state.profiles?.isEmpty() == false) {
                        mDivider { }
                        mCardActions {
                            css { padding(2.spacingUnits) }
                            styledDiv {
                                css {
                                    justifyItems = JustifyItems.center
                                    display = Display.flex
                                    alignItems = Align.center
                                }
                                mButton(
                                    caption = "Übernehmen",
                                    variant = MButtonVariant.contained,
                                    color = MColor.secondary,
                                    onClick = {
                                        val profile = if (!state.profileActive || state.selectedIndex == -1) null
                                        else state.profiles?.firstOrNull { it.profileId == state.selectedIndex }
                                        saveIbisToCompany(state.company, profile)
                                    }
                                ) {
                                    attrs.disableElevation = true
                                    attrs.fullWidth = true
                                }
                                if (state.saveSuccess == true) {
                                    mIconNoTranslate("check") {
                                        css {
                                            color = Color.green;
                                            margin(0.spacingUnits, 1.spacingUnits)
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}