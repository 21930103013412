package de.geomobile.common.portalmodels

import de.geomobile.common.permission.Role
import de.geomobile.common.time.LocalDateTime
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.Serializer
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.Json

@Serializable
data class UserDTO(
    val id: String,
    val email: String,
    val firstName: String? = null,
    val lastName: String? = null,
    val company: Company,
    val role: Role,
    val password: String? = null,
    @Serializable(StateSerializer::class)
    val state: State
) {

    sealed class State {
        object Created : State()
        data class Invited(val timestamp: LocalDateTime) : State()
        object Active : State()
        object Blocked : State()
    }

    @Serializer(forClass = State::class)
    object StateSerializer : KSerializer<State> {

        override val descriptor: SerialDescriptor =
            PrimitiveSerialDescriptor("WithCustomDefault", PrimitiveKind.STRING)

        override fun serialize(encoder: Encoder, obj: State) =
            when (obj) {
                State.Created -> encoder.encodeString("Created")
                is State.Invited -> encoder.encodeString(
                    "Invited: ${
                        Json.encodeToString(
                            LocalDateTime.serializer(),
                            obj.timestamp
                        )
                    }"
                )
                State.Active -> encoder.encodeString("Active")
                State.Blocked -> encoder.encodeString("Blocked")
            }

        override fun deserialize(decoder: Decoder): State {
            val raw = decoder.decodeString()
            return when {
                raw == "Created" -> State.Created
                raw.startsWith("Invited: ") -> State.Invited(
                    Json.decodeFromString(LocalDateTime.serializer(), raw.removePrefix("Invited: "))
                    )
                raw == "Active" -> State.Active
                raw == "Blocked" -> State.Blocked
                else -> error("State not specified")
            }
        }
    }

}