package de.geomobile.frontend.features.softwareManagement.software

import com.ccfraser.muirwik.components.MGridDirection
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.list.mList
import com.ccfraser.muirwik.components.list.mListItem
import com.ccfraser.muirwik.components.list.mListSubheader
import com.ccfraser.muirwik.components.mDivider
import com.ccfraser.muirwik.components.spacingUnits
import com.ccfraser.muirwik.components.styles.Breakpoint
import components.emptyView
import de.geomobile.common.softwaremgmt.SoftwareVersion
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.utils.*
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.padding
import kotlinx.serialization.builtins.ListSerializer
import react.RBuilder
import react.RProps
import react.RState
import react.setState
import styled.css
import styled.styledDiv

fun RBuilder.softwareVersionManagement(
    softwareId: String,
    selectedVersion: String?,
    changeSelection: (selectedVersion: String?, addingNew: Boolean) -> Unit,
) = child(SoftwareVersionManagement::class) {
    attrs.softwareId = softwareId
    attrs.selectedVersion = selectedVersion
    attrs.changeSelection = changeSelection
}

interface SelectedSoftwareVersionProps : RProps {
    var selectedVersion: String?
}

class SoftwareVersionManagement : CComponent<SoftwareVersionManagement.Props, SoftwareVersionManagement.State>() {

    private var loadVersionsJob: Job = Job()

    interface Props : RProps {
        var softwareId: String
        var selectedVersion: String?
        var changeSelection: (selectedVersion: String?, addingNew: Boolean) -> Unit
    }

    class State : RState {
        var versions: List<SoftwareVersion> = emptyList()
    }

    init {
        state = State()
    }

    override fun componentDidMount() {
        loadVersions()
    }

    override fun componentWillReceiveProps(nextProps: Props) {
        if (nextProps.softwareId != props.softwareId)
            loadVersions()
    }

    private fun loadVersions() {
        loadVersionsJob.cancel()
        loadVersionsJob = launch {
            val versions = withContext(Dispatchers.Default) {
                portalRestApi.get(
                    path = "/software/management/${props.softwareId}",
                    serializer = ListSerializer(SoftwareVersion.serializer())
                )
            }

            setState {
                this.versions = versions
            }
        }
    }

    override fun RBuilder.render() {
        val selected: SoftwareVersion? = props.selectedVersion
            ?.let { version ->
                state.versions.singleOrNull {
                    it.version == version
                }
            }
            ?: state.versions.lastOrNull()

        styledDiv {
            css { padding(2.spacingUnits, 0.spacingUnits) }

            mGridContainer2(
                direction = MGridDirection.row
            ) {
                versionList(selected)
                versionDetail(selected)
            }
        }
    }

    private fun RBuilder.versionList(selected: SoftwareVersion?) {
        mGridItem2(
            MGridBreakpoints2(MGridSize2.Cells4)
                .down(Breakpoint.md, MGridSize2.Cells12)
        ) {
            if (state.versions.isNotEmpty()) {
                mCard {
                    css(GlobalStyles.card)
                    mCardContent {
                        css(GlobalStyles.cardListContent)
                        mListSubheader(heading = "Version")
                        mDivider { }
                        mList {
                            for (version in state.versions.asReversed()) {
                                mListItem(
                                    primaryText = version.version,
                                    divider = false,
                                    selected = version.version == selected?.version,
                                    onClick = { props.changeSelection(version.version, false) }
                                ) {
                                    attrs.dense = true
                                }
                            }
                        }
                    }
                }
            } else {
                emptyView(
                    title = "Version",
                    caption = "Es wurde keine Version gefunden",
                    addButton = false
                )
            }
        }
    }

    private fun RBuilder.versionDetail(selected: SoftwareVersion?) {
        mGridItem2(
            MGridBreakpoints2(MGridSize2.Cells8)
                .down(Breakpoint.md, MGridSize2.Cells12)
        ) {
            mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                if (selected != null) {
                    softwareVersionDetail(selected)
                } else {
                    emptyView(
                        title = "Version",
                        caption = "Es wurde keine Version ausgewählt",
                        addButton = false
                    )
                }
            }
        }
    }
}