package de.geomobile.common.feature

object Features {
    val featureGroups: Set<FeatureGroup> = setOf(
        CompanyFeatures,
        StatisticsFeatures,
        VehicleProfileFeatures,
        MapFeatures,
        DeviceFeatures
    )
    val allFeatures: Set<Feature> = featureGroups.flatMap { it.features }.toSet()

    fun getById(id: String) = allFeatures.first { it.id == id }
    fun findById(id: String) = allFeatures.firstOrNull { it.id == id }

    object DeviceFeatures : FeatureGroup("DEVICE_FEATURES", "Geräteverwaltung") {
        val map = feature("MAP", "Karte und GPS")
        val vehicleOccupancy = feature("VEHICLE_OCCUPANCY", "Fahrzeugauslastung")
        val software = feature("SOFTWARE", "Software")
        val softwareDetail = feature("SOFTWARE_DETAIL", "Softwaredetails")
        val audioConfig = feature("AUFIO_CONFIG", "Audio")
        val busAccess = feature("BUS_ACCESS", "IBIS Info")
        val busAccessV18 = feature("BUS_ACCESS_V18", "IBIS Info v18")
        val ibisSniffer = feature("IBIS_SNIFFER", "IBIS Geräteerkennung")
        val cellular = feature("CELLULAR", "Mobilfunk")
        val wifi = feature("WIFI", "WIFI")
        val lan = feature("LAN", "LAN")
        val repairHistory = feature("REPAIR_HISTORY", "Reparaturen")
        val cableCheck = feature("CABLE_CHECK", "Überprüfung der Verkabelung")
        val selfTest = feature("SELF_TEST", "Selbsttest")
        val individualParts = feature("INDIVIDUAL_PARTS", "Einzelteile")
        val deviceListExport = feature("DEVICE_LIST_EXPORT", "Gerätelistenexport")
    }

    object MapFeatures : FeatureGroup("MAP_FEATURES", "Karte") {
        val map = feature("MAP", "Karte")
    }

    object StatisticsFeatures : FeatureGroup("STATISTICS_FEATURES", "Statistiken") {
        val statistics = feature("STATISTICS", "Statistik")
        val wifiStatistics = feature("ACCESSPOINT", "WIFI Statistik")
        val lteStatistics = feature("LTE", "LTE Statistik")
        val passengerCount = feature("PASSENGER_COUNT", "Fahrgastzählung")
        val dashboard = feature("DASHBOARD", "Dashboard")
    }

    object VehicleProfileFeatures : FeatureGroup("VEHICLE_PROFILE_FEATURES", "Fahrzeugprofile") {
        val vehicleProfiles = feature("VEHICLE_PROFILES", "Fahrzeugprofile")
        val fmsProfiles = feature("VEHICLE_FMS_PROFILES", "FMS Profile")
        val ibisProfiles = feature("VEHICLE_IBIS_PROFILES", "IBIS Profile")
        val wiringProfiles = feature("VEHICLE_WIRING_PROFILES", "Verkabelungsprofile")
    }

    object CompanyFeatures : FeatureGroup("COMPANY_FEATURES", "Unternehmensprofile") {
        val companyProfiles = feature("COMPANY_PROFILES", "Unternehmensprofile")

        //val passengerCount = feature("PASSENGER_COUNT", "Fahrgastzählung")
        val ela = feature("ELA", "ELA Unterstützung")
        val cda = feature("CDA", "CDA Unterstützung")
        val shareDevices = feature("SHARE_DEVICES", "Geräte teilen")
        val fakeGps = feature("FAKE_GPS", "Tunnel GPS")
        val textToSpeech = feature("TEXT_TO_SPEECH", "Text to Speech")
    }
}