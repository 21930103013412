package de.geomobile.common.portalmodels

import kotlinx.serialization.Serializable

@Serializable
data class DeviceAudioDTO(
    var volume: Int? = 0,
    var treble: Int? = 0,
    var bass: Int? = 0,
    var hasProfile: Boolean = true,
    var testDuration: Int? = null,
    var deviceElaStatus: Map<String,String> = emptyMap()
)

@Serializable
data class DeviceAudioSmallDTO(
    var volume: Int? = 0,
    var treble: Int? = 0,
    var bass: Int? = 0
)

val DeviceAudioDTO.small
    get() = DeviceAudioSmallDTO(
        volume = volume,
        treble = treble,
        bass = bass
    )

fun DeviceAudioDTO.changeValue(volume: Int? = null, treble: Int? = null, bass: Int? = null, testDuration: Int? = null, deviceElaStatus: Map<String, String> = emptyMap()): DeviceAudioDTO {
    volume?.let { this.volume = it }
    treble?.let { this.treble = it }
    bass?.let { this.bass = it }
    testDuration?.let { this.testDuration = it }
    if (deviceElaStatus.isNotEmpty()) { this.deviceElaStatus = deviceElaStatus }
    return this
}


