package de.geomobile.common.portalmodels

import kotlinx.serialization.Serializable


enum class FloorType(val readableName: String) {
    NONE("-"),
    LOW_FLOOR("Niederflur"),
    HIGH_FLOOR("Hochflur")
}

enum class AvailableType(val readableName: String) {
    NONE("-"),
    AVAILABLE("Vorhanden"),
    NOT_AVAILABLE("Nicht vorhanden")
}

enum class WiredType(val readableName: String) {
    NONE("-"),
    WIRED("Verdrahtet"),
    NOT_WIRED("Nicht verdrahtet")
}

enum class OutFunction(val readableName: String) {
    NONE("-"),
    STOP_REQUEST("Haltewunsch"),
    SERVICE_REQUEST("Servicewunsch"),
    RIDE_REQUEST("Mitfahrwunsch"),
    ELA_RELEASE("ELA Freigabe"),
    SERVICE_STOP_REQUEST("Service- & Haltewunsch"),
}

enum class InFunction(val readableName: String) {
    NONE("-"),
    DOOR_RELEASE("Türfreigabe"),
    EN_SPEAKER_1("Lautsprecher 1 an/aus"),
    EN_SPEAKER_2("Lautsprecher 2 an/aus"),
    DRIVING_SCHOOL_MODE("Fahrschulmodus"),
    STOP_REQUEST("Haltewunsch"),
    RIDE_REQUEST("Mitfahrwunsch"),
}

enum class PassengerLimitOption(val readableName: String) {
    ONLY_SEATED("Nur Sitzplätze"),
    ONLY_STANDING("Nur Stehplätze"),
    SEATED_STANDING("Sitz- und Stehplätze")
}

@Serializable
data class VehicleProfileDTO(
    val id: String,
    val name: String,
    val company: CompanySmall,
    val description: String? = null,
    val vehicleModel: String? = null,
    val vehicleType: VehicleType? = null,
    val vehicleIdByIBIS: Boolean? = null,
    val vehicleIdAllowOverride: Boolean? = null,
    val floorType: FloorType? = null,
    val seatingCapacity: Int? = null,
    val standingCapacity: Int? = null,
    val passengerLimitOption: PassengerLimitOption? = null,
    val lowerPassengerLimit: Int? = null,
    val upperPassengerLimit: Int? = null,
    val ramp: AvailableType? = null,
    val out1: OutFunction? = null,
    val out2: OutFunction? = null,
    val out3: OutFunction? = null,
    val out4: OutFunction? = null,
    val out5: OutFunction? = null,
    val in1: InFunction? = null,
    val in2: InFunction? = null,
    val in3: InFunction? = null,
    val in4: InFunction? = null,
    val speaker1: WiredType? = null,
    val speaker2: WiredType? = null,
    val can: WiredType? = null,
    val wiringProfile: Int? = null,
    val ibis: WiredType? = null,
    val fmsProfile: Int? = null,
    val ibisProfile: Int? = null,
    val elaAudioVolume: Int? = null,
    var elaAudioTreble: Int? = null,
    var elaAudioBass: Int? = null
)

@Serializable
data class VehicleProfileMQTTDTO(
    val id: String,
    val name: String,
    val vehicleType: VehicleType? = null,
    val out1: OutFunction? = null,
    val out2: OutFunction? = null,
    val out3: OutFunction? = null,
    val out4: OutFunction? = null,
    val out5: OutFunction? = null,
    val in1: InFunction? = null,
    val in2: InFunction? = null,
    val in3: InFunction? = null,
    val in4: InFunction? = null,
    val speaker1: WiredType? = null,
    val speaker2: WiredType? = null,
    val can: WiredType? = null,
    val ibis: WiredType? = null,
    val vehicleIdByIBIS: Boolean? = null,
)

@Serializable
data class VehicleProfileSmallDTO(
    val id: String,
    val name: String
)

@Serializable
data class VehicleProfileAssignmentDTO(
        val id: String,
        val name: String,
        val company: CompanySmall,
        val description: String? = null,
        val vehicleType: VehicleType? = null,
        val assignedDevices: List<String> = emptyList(),
        val assignedString: String = "",
        val allDevicesFound: Boolean = true
)

val VehicleProfileDTO.small get() = VehicleProfileSmallDTO(id = id, name = name)