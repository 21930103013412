package de.geomobile.frontend.utils

import com.ccfraser.muirwik.components.createStyled
import com.ccfraser.muirwik.components.setStyledPropsAndRunHandler
import org.w3c.dom.events.Event
import react.RBuilder
import react.RComponent
import react.RState
import styled.StyledHandler
import styled.StyledProps

@JsModule("@material-ui/core/Slider")
private external val sliderModule: dynamic

@Suppress("UnsafeCastFromDynamic")
private val sliderComponent: RComponent<MSliderProps, RState> = sliderModule.default

interface MSliderProps : StyledProps {
    var value: dynamic
    var min: Int?
    var max: Int?
    var step: Int?
    var onChange: ((Event, dynamic) -> Unit)?
}

fun RBuilder.mSlider(
    value: Int = 50,
    min: Int? = null,
    max: Int? = null,
    step: Int? = null,
    onChange: ((Int) -> Unit)? = null,
    className: String? = null,
    handler: StyledHandler<MSliderProps>? = null,
) = createStyled(sliderComponent) {

    attrs.value = value
    attrs.min = min
    attrs.max = max
    attrs.step = step
    attrs.onChange = { _, value -> onChange?.invoke(value) }

    setStyledPropsAndRunHandler(className, handler)
}

/* TODO: Clean up */
//fun RBuilder.mRangeSlider(
//        valueStart: Int = 0,
//        valueEnd: Int = 100,
//        min: Int? = null,
//        max: Int? = null,
//        step: Int? = null,
//        onChange: ((Int, Int) -> Unit)? = null,
//        className: String? = null,
//        handler: StyledHandler<MSliderProps>? = null) = createStyled(sliderComponent) {
//
//    attrs.value = arrayOf(valueStart, valueEnd)
//    attrs.min = min
//    attrs.max = max
//    attrs.step = step
//    attrs.onChange = {_,value -> onChange?.invoke(value[0], value[1])}
//
//    setStyledPropsAndRunHandler(className, handler)
//}
