package portalmodels

import de.geomobile.common.time.LocalDateTime
import kotlinx.serialization.Serializable

enum class RepairStepType(val readableName: String) {
    ANNOUNCED("Angekündigt"),
    EXAMINATION_BY_CUSTOMER("Prüfung Kunde"),
    ARRIVED("Eingetroffen"),
    EXAMINATION_BY_MANUFACTURER("Prüfung Hersteller"),
    IN_REPAIR("In Reparatur"),
    SENT_BACK("Rückversand"),
    IRREPARABLE("Irreparabel");

    companion object {
        private val mapping = values().associateBy(RepairStepType::readableName)
        fun fromReadable(readableName: String) = mapping[readableName]
    }
}

enum class ServiceType(val readableName: String) {
    WARRANTY("Garantiefall"),
    SERVICE("Servicefall");

    companion object {
        private val mapping = values().associateBy(ServiceType::readableName)
        fun fromReadable(readableName: String) = mapping[readableName]
    }
}

@Serializable
data class RepairDTO(
    val id: Int,
    val deviceId: Int,
    val cpuId: String,
    val materialNumber: Int? = null,
    val productVariantId: Int? = null,
    val serialNumber: Int? = null,
    val vehicleId: String? = null,
    val companyId: String? = null,
    val comment: String? = null,
    val notified: Boolean = false,
    val active: Boolean = true,
    val serviceType: ServiceType = ServiceType.WARRANTY,
    val repairSteps: List<RepairStepDTO> = listOf()

)

@Serializable
data class RepairStepDTO(
    // For newly created Steps that come from the Frontend the id field is (ab)used to store the repairId
    val id: Int,
    val createdBy: String? = null,
    var step: RepairStepType,
    val timestamp: LocalDateTime,
    val description: String
)