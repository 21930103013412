package de.geomobile.frontend.features.device.detail

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.table.*
import de.geomobile.common.portalmodels.CompanySmall
import de.geomobile.common.portalmodels.DeviceGenericInfoDTO
import de.geomobile.common.time.LocalDateTime
import de.geomobile.frontend.api.TopicSession
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.portalWebSocketApi
import de.geomobile.frontend.utils.CComponent
import de.geomobile.frontend.utils.mTableRowSlim
import de.geomobile.frontend.utils.toText
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.*
import kotlinx.serialization.json.Json
import react.RBuilder
import react.RProps
import react.RState
import react.setState
import styled.css
import styled.styledDiv

enum class GenericInfoType(val endpoint: String) {
    TRIP("ibis_trip_info"),
    TRAIN_SET("ibis_train_set_info")
}

fun RBuilder.genericInfo(
    id: Int,
    type: GenericInfoType
) = child(DeviceGenericInfo::class) {
    key = id.toString()
    attrs.id = id
    attrs.type = type
}

class DeviceGenericInfo : CComponent<DeviceGenericInfo.Props, DeviceGenericInfo.State>() {

    private var session: TopicSession? = null
    private var isMounted: Boolean = false

    val functionTranslation = mapOf(
        "line" to "Linie",
        "destination" to "Ziel",
        "next_stop" to "Nächster Halt",
        "station_index" to "Stationsindex",
        "station_list" to "Stationsliste",
        "direction" to "Richtung",
        "door_state" to "Türzustand",
        "stop_requested" to "Haltewunsch"
    )

    interface Props : RProps {
        var id: Int
        var type: GenericInfoType
    }

    class State(
        var company: CompanySmall? = null,
        var messageMap: Map<String, DeviceGenericInfoDTO> = mapOf()
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        isMounted = true
        launch {
            if (isMounted) {
                val companyId = withContext(Dispatchers.Default) {
                    portalRestApi.get("/device/${props.id}/company", CompanySmall.serializer())
                }
                setState {
                    company = companyId
                }
            }
        }.invokeOnCompletion {
            connectTelegramBroadcast()
        }
    }

    override fun componentWillUnmount() {
        isMounted = false
        session?.close()
    }

    private fun truncateText(max: Int, input: String): String {
        if (input.length > max) {
            return "${input.substring(0, max)}..."
        }
        return input
    }

    private fun connectTelegramBroadcast() {
        session?.close()
        session = portalWebSocketApi.subscribe("/device/${props.type.endpoint}", mapOf("id" to props.id.toString()))
        session?.connect {
            onmessage = { message ->
                if (isMounted) {
                    var tripMessage = Json.decodeFromString(DeviceGenericInfoDTO.serializer(), message)
                    if (tripMessage.timestamp.plusDays(14) < LocalDateTime.now())
                        tripMessage = tripMessage.copy(messageList = emptyList())
                    setState {
                        messageMap = messageMap + mapOf(tripMessage.function to tripMessage)
                    }
                }
            }
        }
    }

    override fun RBuilder.render() {
        styledDiv {
            css { padding(2.spacingUnits) }

            if (state.messageMap.isEmpty()) {
                mTypography(
                    text = "Keine Daten verfügbar",
                    variant = MTypographyVariant.body1,
                    align = MTypographyAlign.center
                )
            } else {
                mTableContainer {
                    mTable {
                        css {
                            width = LinearDimension.auto
                            whiteSpace = WhiteSpace.nowrap
                        }
                        mTableHead {
                            mTableRowSlim {
                                mTableCell(
                                    align = MTableCellAlign.left,
                                    variant = MTableCellVariant.head,
                                    padding = MTableCellPadding.none,
                                ) {
                                    css { padding(1.spacingUnits) }
                                    +"Funktion"
                                }
                                mTableCell(
                                    align = MTableCellAlign.left,
                                    variant = MTableCellVariant.head,
                                    padding = MTableCellPadding.none
                                ) {
                                    css { padding(1.spacingUnits) }
                                    +"Empfangszeit"
                                }
                                mTableCell(
                                    align = MTableCellAlign.left,
                                    variant = MTableCellVariant.head,
                                    padding = MTableCellPadding.none
                                ) {
                                    css { padding(1.spacingUnits) }
                                    +"Wert"
                                }
                                mTableCell(
                                    align = MTableCellAlign.left,
                                    variant = MTableCellVariant.head,
                                    padding = MTableCellPadding.none
                                ) {
                                    css { padding(1.spacingUnits) }
                                    +"Quelle"
                                }

                            }
                        }

                        mTableBody {
                            state
                                .messageMap
                                .entries
                                .sortedBy {
                                    if(props.type == GenericInfoType.TRIP)
                                        functionTranslation.keys.reversed().indexOf(it.key)
                                    else
                                        state.messageMap.keys.reversed().indexOf(it.key)
                                }
                                .reversed()
                                .forEach { row ->
                                    mTableRowSlim {
                                        mTableCell {
                                            css { padding(1.spacingUnits) }
                                            mTypography(
                                                text = functionTranslation[row.key] ?: row.key,
                                                variant = MTypographyVariant.body2
                                            )
                                        }
                                        mTableCell {
                                            css { padding(1.spacingUnits) }
                                            mTypography(
                                                text = row.value.timestamp.toText(),
                                                variant = MTypographyVariant.body2
                                            )
                                        }
                                        mTooltip(row.value.messageList.joinToString()) {
                                            mTableCell {
                                                css { padding(1.spacingUnits) }
                                                mTypography(
                                                    text = truncateText(100, row.value.messageList.joinToString()),
                                                    variant = MTypographyVariant.body2
                                                )
                                            }
                                        }
                                        mTableCell {
                                            css { padding(1.spacingUnits) }
                                            mTypography(
                                                text = row.value.source,
                                                variant = MTypographyVariant.body2
                                            )
                                        }
                                    }
                            }
                        }
                    }
                }
            }
        }
    }
}

