package de.geomobile.frontend.features.companyProfile


import com.ccfraser.muirwik.components.MGridDirection
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.list.mList
import com.ccfraser.muirwik.components.list.mListItem
import com.ccfraser.muirwik.components.list.mListSubheader
import com.ccfraser.muirwik.components.mDivider
import com.ccfraser.muirwik.components.styles.Breakpoint
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.spacer
import de.geomobile.frontend.utils.*
import portalmodels.CompanyProfileSIMSource
import react.RBuilder
import react.RProps
import react.RState
import react.router.dom.redirect
import react.router.dom.route
import react.router.dom.switch
import styled.css

fun RBuilder.companyProfileNetwork(
    path: String,
) = child(CompanyProfileNetwork::class) {
    attrs.path = path
}

class CompanyProfileNetwork : CComponent<CompanyProfileNetwork.Props, CompanyProfileNetwork.State>() {

    interface Props : RProps {
        var path: String
    }

    class State(
        var simDataSource: String = CompanyProfileSIMSource.REMOTE.readableName,
        var simDataExist: String = "Nein",
        var simDataDeployed: String = "Nein",
        //TODO: Replace String list with Enumerators
        var categoryList: List<String> = listOf("SIM", "Interact")
    ) : RState

    init {
        state = State()
    }

    override fun RBuilder.render() {
        spacer()
        mGridContainer2(direction = MGridDirection.row) {
            mGridItem2(
                MGridBreakpoints2(MGridSize2.Cells3)
                    .down(Breakpoint.md, MGridSize2.Cells12)
            ) {
                sideBar()
            }
            mGridItem2(
                MGridBreakpoints2(MGridSize2.Cells9)
                    .down(Breakpoint.md, MGridSize2.Cells12)
            ) {
                switch {
                    route("${props.path}/sim") {
                        companyProfileSIM()
                    }
                    route("${props.path}/interact") {
                        companyProfileInteractNetwork()
                    }
                    route("${props.path}/wifi") {
                        companyProfileWIFI()
                    }
                    route("${props.path}/can") {
                        companyProfileCAN()
                    }
                    route(props.path) {
                        redirect(
                            from = "/companyprofile/network",
                            to = props.path + "/" + state.categoryList.first().lowercase()
                        )
                    }
                }
            }
        }
    }

    private fun RBuilder.sideBar() {
        mGridContainer2(direction = MGridDirection.column) {
            mGridItem2 {
                mCard {
                    css(GlobalStyles.card)
                    mCardContent {
                        css(GlobalStyles.cardListItemContent)
                        mListSubheader(heading = "Filter")
                        mDivider { }
                        route<RProps>("/companyprofile/network") { props ->
                            mList {
                                for (category in state.categoryList) {
                                    mListItem(
                                        primaryText = category,
                                        divider = false,
                                        onClick = {
                                            props.history.push("/companyprofile/network/${category.lowercase()}")
                                        }
                                    ) {
                                        attrs.dense = true
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


