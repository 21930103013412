package de.geomobile.frontend.features.vehicleProfile

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.menu.mMenuItem
import de.geomobile.common.permission.Permission
import de.geomobile.common.permission.Permissions
import de.geomobile.common.portalmodels.*
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.spacer
import de.geomobile.frontend.utils.CComponent
import de.geomobile.frontend.utils.isAuthorized
import de.geomobile.frontend.utils.mToolbarTitle2
import kotlinx.browser.localStorage
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.async
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.*
import kotlinx.serialization.builtins.ListSerializer
import org.w3c.dom.get
import org.w3c.dom.set
import react.*
import react.router.dom.redirect
import react.router.dom.route
import react.router.dom.switch
import styled.css
import styled.styledDiv

fun RBuilder.vehicleProfilesDashboard(
    path: String,
    drawerMenu: ReactElement,
    onDeviceClick: (id: DeviceIdentifier) -> Unit,
) = child(VehicleProfilesDashboard::class) {
    attrs.path = path
    attrs.drawerMenu = drawerMenu
    attrs.onDeviceClick = onDeviceClick
}

class VehicleProfilesDashboard : CComponent<VehicleProfilesDashboard.Props, VehicleProfilesDashboard.State>() {

    private val tabs = listOf(
        Tab(
            label = "Profile bearbeiten",
            route = "/manage"
        ) { vehicleProfiles(path = it) },
        Tab(
            label = "Profile zuweisen",
            route = "/assign"
        ) { vehicleProfilesAssign(path = it, onDeviceClick = props.onDeviceClick) },
        Tab(
            label = "FMS Profile",
            route = "/fms",
            viewPermission = Permissions.VehicleProfileManagement.fmsProfileView
        ) {
            switch {
                route<LinkProps>("${props.path}/fms/:profileId") { props ->
                    val profileId = props.match.params.profileId
                    vehicleProfileFMS(profileId ?: "")
                }
                route<RProps>("${props.path}/fms") {
                    vehicleProfileFMS("")
                }
            }
        },
        Tab(
            label = "IBIS Profile",
            route = "/ibis",
            viewPermission = Permissions.VehicleProfileManagement.ibisProfileView
        ) { vehicleProfilesIBIS("") },
        Tab(
            label = "Verkabelungsprofile",
            route = "/wiring",
            viewPermission = Permissions.VehicleProfileManagement.wiringProfileView
        ) {
            switch {
                route<LinkProps>("${props.path}/wiring/:profileId") { props ->
                    val profileId = props.match.params.profileId
                    vehicleProfilesWiring(profileId ?: "")
                }
                route<RProps>("${props.path}/wiring") {
                    vehicleProfilesWiring("")
                }
            }
        },
    )

    private data class Tab(
        val label: String,
        val route: String,
        val viewPermission: Permission? = null,
        val builder: RBuilder.(path: String) -> ReactElement,
    )

    interface Props : RProps {
        var path: String
        var drawerMenu: ReactElement
        var onDeviceClick: (id: DeviceIdentifier) -> Unit
    }

    private interface LinkProps : RProps {
        var profileId: String?
    }

    class State(
        var company: CompanySmall? = null,
        var companies: List<Company>? = null,
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        launch {
            val deferredCompanies = if (isAuthorized(Permissions.VehicleProfileManagement.notRestrictedToCompany))
                async(Dispatchers.Default) {
                    portalRestApi.get("/admin/companies", ListSerializer(Company.serializer()))
                }
            else
                null

            val ownUser =
                withContext(Dispatchers.Default) {
                    portalRestApi.get("/user", UserDTO.serializer())
                }

            val companies = deferredCompanies?.await()

            val localStorageCompanyId = try {
                localStorage["VehicleProfileCompany"]
            } catch (e: NoSuchElementException) {
                localStorage["VehicleProfileCompany"] = ownUser.company.id
                null
            }

            val company =
                if (localStorageCompanyId != null) {
                    companies?.first { it.id == localStorageCompanyId }?.small
                } else {
                    localStorage["VehicleProfileCompany"] = ownUser.company.id
                    ownUser.company.small
                }

            setState {
                this.company = company
                this.companies = companies
            }
        }
    }

    override fun RBuilder.render() {

        val companies = state.companies
        val permittedTabs = tabs.filter { isAuthorized(*listOfNotNull(it.viewPermission).toTypedArray()) }

        route<RProps>(props.path) { routeProps ->
            mAppBar(position = MAppBarPosition.fixed) {
                css(GlobalStyles.appbar)

                val currentRoute = routeProps.history.location.pathname
                val selectedTab =
                    permittedTabs.firstOrNull { currentRoute.startsWith(props.path + it.route) }
                        ?: permittedTabs.first()

                mToolbar {
                    child(props.drawerMenu)
                    mToolbarTitle2("Fahrzeugprofile")

                    if (companies != null) {
                        mTypography(text = "Unternehmen", color = MTypographyColor.inherit) {
                            css {
                                display = Display.inline; marginRight = 1.spacingUnits
                            }
                        }
                        mSelect(
                            value = state.company?.id ?: "",
                            name = "company",
                            id = "company",
                            onChange = { event, _ ->
                                val id = event.targetValue as String
                                localStorage["VehicleProfileCompany"] = id
                                routeProps.history.push(props.path)
                                setState { this.company = companies.first { it.id == id }.small }
                            }
                        ) {
                            css {
                                marginTop = 2.pt
                                color = kotlinx.css.Color.white
                            }
                            for (company in companies) {
                                mMenuItem(primaryText = company.name, value = company.id)
                            }
                        }
                    }
                }
                mTabs(
                    props.path + selectedTab.route,
                    centered = true,
                    onChange = { _, value -> routeProps.history.push(value as String) }
                ) {
                    for (tab in permittedTabs) {
                        mTab(tab.label, props.path + tab.route)
                    }
                }
            }
        }
        spacer()
        styledDiv {
            css { padding(2.spacingUnits) }
            switch {
                for (tab in permittedTabs) {
                    route<RProps>(props.path + tab.route) { routeProps ->
                        tab.builder(this, routeProps.match.path)
                    }
                }
                redirect(from = props.path, to = props.path + permittedTabs.first().route, exact = true)
            }
        }
    }
}