package de.geomobile.frontend.features.device.detail

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.dialog.mDialog
import com.ccfraser.muirwik.components.dialog.mDialogActions
import com.ccfraser.muirwik.components.dialog.mDialogContent
import com.ccfraser.muirwik.components.form.MFormControlMargin
import com.ccfraser.muirwik.components.form.MFormControlVariant
import com.ccfraser.muirwik.components.form.margin
import com.ccfraser.muirwik.components.menu.mMenuItem
import com.ccfraser.muirwik.components.styles.Breakpoint
import de.geomobile.common.permission.Permissions
import de.geomobile.common.portalmodels.UserDTO
import de.geomobile.common.time.LocalDateTime
import de.geomobile.frontend.features.repair.repairJob
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.utils.*
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.Color
import kotlinx.css.color
import kotlinx.css.padding
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.builtins.MapSerializer
import kotlinx.serialization.builtins.PairSerializer
import kotlinx.serialization.builtins.serializer
import kotlinx.serialization.json.Json
import portalmodels.*
import react.RBuilder
import react.RProps
import react.RState
import react.setState
import styled.css
import styled.styledDiv

fun RBuilder.deviceRepair(
    id: Int
) = child(DeviceRepairHistoryComponent::class) {
    key = id.toString()
    attrs.id = id
}

class DeviceRepairHistoryComponent :
    CComponent<DeviceRepairHistoryComponent.Props, DeviceRepairHistoryComponent.State>() {

    private var commonRepairs =
        listOf("Kein WLAN", "Boot Schleife", "Schaltet nicht an", "Schaltet an aber ohne Funktion")

    interface Props : RProps {
        var id: Int
    }

    class State(
        var repairList: MutableList<RepairDTO> = mutableListOf(),
        val newMap: MutableMap<Int, RepairStepDTO> = mutableMapOf(),
        var assemblyList: List<ProductAssemblyDTO> = listOf(),
        var variantAssemblyMap: Map<Int, List<ProductAssemblyDTO>> = mapOf(),
        var currentUser: UserDTO? = null,
        var warnDialog: String = "",
        var saveDialog: Boolean = false,
        var deleteDialog: Boolean = false,
        var preparedDTO: RepairDTO? = null,
        var deleteDTO: RepairDTO? = null
    ) : RState

    init {
        state = State()
    }

    fun loadUser() {
        launch {
            val ownUser =
                withContext(Dispatchers.Default) {
                    portalRestApi.get("/user", UserDTO.serializer())
                }
            setState {
                currentUser = ownUser
            }
        }
    }

    private fun fetchAssemblyRelations() {
        try {
            launch {
                val assemblyList = portalRestApi.get(
                    "/portalsettings/assemblies/list",
                    ListSerializer(ProductAssemblyDTO.serializer())
                )
                val relations = portalRestApi.get(
                    "/portalsettings/assemblies/variant/relations",
                    MapSerializer(
                        Int.serializer(), // variant ID
                        ListSerializer(Int.serializer()) // assemblies
                    )
                )
                setState {
                    variantAssemblyMap = relations.mapValues { entry ->
                        entry.value.mapNotNull {
                            assemblyList.find { assembly -> assembly.id == it }
                        }
                    }
                }
            }.invokeOnCompletion { loadRepairList() }
        } catch (ex: Exception) {

        }
    }

    fun loadRepairList() {
        launch {
            val repairList = withContext(Dispatchers.Default) {
                portalRestApi.get("/repair/${props.id}/id", ListSerializer(RepairDTO.serializer()))
            }
            setState {
                this.repairList = repairList.toMutableList()
            }
        }
    }

    fun newRepairEntry(dto: RepairDTO) {
        launch {
            withContext(Dispatchers.Default) {
                val body = Json.encodeToString(RepairDTO.serializer(), dto)
                portalRestApi.put("/repair/insert/new", body, GenericResponseDTO.serializer())
            }

        }.invokeOnCompletion {
            loadRepairList()
        }
    }

    fun deleteRepair(dto: RepairDTO) {
        launch {
            withContext(Dispatchers.Default) {
                val body = Json.encodeToString(RepairDTO.serializer(), dto)
                portalRestApi.put("/repair/delete", body, GenericResponseDTO.serializer())
            }
        }.invokeOnCompletion {
            loadRepairList()
        }
    }

    override fun componentDidMount() {
        fetchAssemblyRelations()
        loadUser()
        loadRepairList()

    }

    override fun RBuilder.render() {
        if (
            isAuthorized(Permissions.AdminPermissions.internalAccess) ||
            state.repairList.isNullOrEmpty() ||
            state.repairList?.none { it.active }
        ) {
            styledDiv {
                css { padding(2.spacingUnits) }
                mGridContainer2(direction = MGridDirection.row) {
                    mGridItem2(
                        MGridBreakpoints2(MGridSize2.Cells6)
                            .down(Breakpoint.sm, MGridSize2.Cells12)
                    ) {
                        mSelect(
                            variant = MFormControlVariant.outlined,
                            value = state.newMap[0]?.step ?: RepairStepType.ANNOUNCED,
                            onChange = { event, _ ->
                                val newValue = RepairStepType.valueOf(event.targetValue as String)
                                setState {
                                    state.newMap[0] =
                                        state.newMap[0]?.copy(step = newValue) ?: RepairStepDTO(
                                            id = 0,
                                            createdBy = state.currentUser?.id ?: state.currentUser?.email,
                                            step = newValue,
                                            timestamp = LocalDateTime.now(),
                                            description = ""
                                        )
                                }
                            }
                        ) {
                            attrs.margin = MFormControlMargin.dense.toString()
                            attrs.fullWidth = true

                            for (mode in RepairStepType.values()) {
                                mMenuItem(
                                    primaryText = mode.readableName,
                                    value = mode.name,
                                    disabled =
                                    !isAuthorized(Permissions.AdminPermissions.internalAccess) &&
                                            mode > RepairStepType.ANNOUNCED
                                )
                            }
                        }
                    }
                    mGridItem2(
                        MGridBreakpoints2(MGridSize2.Cells6)
                            .down(Breakpoint.sm, MGridSize2.Cells12)
                    ) {
                        mSelect(
                            variant = MFormControlVariant.outlined,
                            value = "_select",
                            onChange = { event, _ ->
                                val text = event.targetValue as String
                                if (text != "_select")
                                    setState {
                                        state.newMap[0] =
                                            state.newMap[0]?.copy(description = text) ?: RepairStepDTO(
                                                id = 0,
                                                createdBy = state.currentUser?.id
                                                    ?: state.currentUser?.email,
                                                step = RepairStepType.ANNOUNCED,
                                                timestamp = LocalDateTime.now(),
                                                description = text
                                            )
                                    }
                            }
                        ) {
                            attrs.margin = MFormControlMargin.dense.toString()
                            attrs.fullWidth = true

                            mMenuItem(
                                primaryText = "Auswahl",
                                value = "_select",
                            )
                            val knownRepairs = commonRepairs + state.repairList
                                ?.flatMap { it.repairSteps }
                                ?.distinctBy { it.description }
                                ?.map { it.description }

                            for (mode in knownRepairs.distinct()) {
                                mMenuItem(
                                    primaryText = mode,
                                    value = mode,
                                )
                            }
                        }
                    }
                }
                mGridContainer2(direction = MGridDirection.row) {
                    mGridItem2(
                        MGridBreakpoints2(MGridSize2.Cells12)
                            .down(Breakpoint.sm, MGridSize2.Cells12)
                    ) {
                        mTextField(
                            label = "",
                            fullWidth = true,
                            variant = MFormControlVariant.outlined,
                            value = state.newMap[0]?.description ?: "",
                            onChange = {
                                val text = it.targetInputValue
                                setState {
                                    state.newMap[0] =
                                        state.newMap[0]?.copy(description = text) ?: RepairStepDTO(
                                            id = 0,
                                            createdBy = state.currentUser?.id ?: state.currentUser?.email,
                                            step = RepairStepType.ANNOUNCED,
                                            timestamp = LocalDateTime.now(),
                                            description = text
                                        )
                                }
                            }
                        ) {
                            attrs.margin = MFormControlMargin.dense
                            attrs.placeholder = "Beschreibung"
                        }
                    }
                    mGridItem2(
                        MGridBreakpoints2(MGridSize2.Cells12)
                            .down(Breakpoint.sm, MGridSize2.Cells12)
                    ) {
                        mButton(
                            caption = "Reparatur erstellen",
                            color = MColor.secondary,
                            variant = MButtonVariant.contained,
                            disabled = state.newMap[0] == null,
                            onClick = {
                                val dto = RepairDTO(
                                    id = 0,
                                    deviceId = props.id,
                                    cpuId = "0",
                                    notified = false,
                                    active = true,
                                    repairSteps = listOf(state.newMap[0]!!)
                                )
                                setState {
                                    preparedDTO = dto
                                    if (state.newMap[0]!!.description.isEmpty())
                                        warnDialog = "Es muss eine Beschreibung angegeben werden."
                                    if (state.repairList.any { it.active })
                                        warnDialog =
                                            "Es gibt bereits einen aktiven Reparaturvorgang für dieses Gerät."
                                    else
                                        saveDialog = true

                                    newMap.remove(0)
                                }
                            }
                        ) {
                            attrs.disableElevation = true
                        }
                    }
                }
            }
        }

        mDivider { }

        state.repairList.forEach { repair ->
            repairJob(
                repair = repair,
                assemblies = state.variantAssemblyMap[repair.productVariantId] ?: listOf(),
                updateTrigger = { loadRepairList() }
            )
        }

        mDialog(
            open = state.warnDialog.isNotEmpty(),
            onClose = { _, _ -> setState { warnDialog = "" } }
        ) {
            mDialogContent { mTypography("Es gibt bereits einen aktiven Reparaturvorgang.") }
            mDialogActions {
                mButton("Okay", onClick = { setState { warnDialog = "" } })
            }
        }
        mDialog(
            open = state.deleteDialog,
            onClose = { _, _ -> setState { deleteDialog = false } }
        ) {
            mDialogContent { mTypography("Reparatur unwiderruflich löschen? Sicher?") }
            mDialogActions {
                mButton("Nein, Abbrechen", onClick = { setState { deleteDialog = false } })
                mButton(
                    caption = "Ja, Bestätigen",
                    onClick = {
                        state.deleteDTO?.let { deleteRepair(it) }
                        setState { deleteDialog = false }
                    }
                ) { css { color = Color.red } }
            }
        }
        mDialog(
            open = state.saveDialog,
            onClose = { _, _ -> setState { saveDialog = false } }
        ) {
            mDialogContent { mTypography("Achtung, hierbei wird die Stage auf Retour geändert und diese Geräteseite steht Ihnen nicht mehr zur verfügung.") }
            mDialogActions {
                mButton("Nein, Abbrechen", onClick = { setState { saveDialog = false } })
                mButton(
                    caption = "Ja, Bestätigen",
                    onClick = {
                        state.preparedDTO?.let { newRepairEntry(it) }
                        setState { saveDialog = false }
                    }
                ) { css { color = Color.red } }
            }
        }
    }
}