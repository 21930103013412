package de.geomobile.common.errorhandling

import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.Serializer
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

@Serializable(with = ApiErrorCodes.Companion::class)
enum class ApiErrorCodes(val uuid: String) {
    // Common
    DEPRECATED_API("d4eb6d5a-a5e2-4a1c-bfa7-ff5c7c85c58a"),
    UNKNOWN_ERROR("436e8489-9a19-45e0-8883-598d0468f09d"),
    NOT_FOUND("7b44c655-39e1-42ad-aad1-13eb39247943"),

    // Auth
    AUTHENTICATION_NEEDED("ad14437d-2808-411c-a009-2479e1a33850"),

    // Permission/Feature
    FEATURE_NEEDED("43ae8533-466e-43e7-8ccb-ba11e897361c"),
    AUTHORIZATION_NEEDED("1b8090e2-5789-44ed-b4d1-ea1b7cb155fe"),

    // Devices
    DEVICE_WITH_CPU_ID_ALREADY_EXISTS("71ae447f-79ad-4bd7-8c9e-16f917642531"),
    DEVICE_WITH_SERIAL_NUMBER_MISSING("c1b637a1-1298-4de1-b5fc-7cbc42a0550b"),
    MALFORMED_SERIAL_NUMBER("a100375b-b1f8-4f8e-bced-75571ef260ae"),
    DEVICE_WITH_CPU_ID_MISSING("a3d5c1f8-7381-46e5-a750-9775fa393358"),
    DEVICE_NOT_IN_STAGE_CREATED("1623a2c4-b33b-407d-88f3-831a2eede014"),

    // Device tags
    DEVICE_TAG_WITH_TITLE_ALREADY_EXISTS("4705bc4d-7770-4203-bc94-49d040eaf61d"),

    // Software
    NEED_TO_WAIT_FOR_UPDATE("4029fd16-2723-430f-818a-d9117dd756f2"),

    // Portal
    USER_BLOCKED("120b2d9b-4881-4f62-914f-37531a8d7a77"),
    LOGIN_FAILURE("055b30c6-11e3-48df-8f69-943f515880a4"),
    PASSWORD_RESET_EXPIRED("5013cffb-5e69-450c-acb4-6b50a20e5cd8"),
    PASSWORD_RESET_FAILURE("3ff773e4-1223-490e-b5f8-f01a710c2b82")
    ;


    @Serializer(forClass = ApiErrorCodes::class)
    companion object : KSerializer<ApiErrorCodes> {

        override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("ApiErrorCodes", PrimitiveKind.STRING)

        override fun serialize(encoder: Encoder, obj: ApiErrorCodes) = encoder.encodeString(obj.uuid)

        override fun deserialize(decoder: Decoder): ApiErrorCodes {
            val uuid = decoder.decodeString()
            return ApiErrorCodes.values().single { it.uuid == uuid }
        }
    }
}