package portalmodels

import de.geomobile.common.time.LocalDate
import kotlinx.serialization.Serializable

fun getCharForNumber(i: Int): String {
    return when {
        (i > 0 && i < 27) -> (i + 64).toChar().toString()
        else -> "0"
    }
}

@Serializable
data class DeviceIndividualPartDTO(
    val id: Int,
    val deviceId: Int,
    val materialNumber: Int,
    val revision: Int,
    val revisionChar: String? = null,
    val subRevision: Int,
    val productionDate: LocalDate,
    val circuitBoardAssembler: Int,
    val orderNumber: String,
    val lotNumber: String,
    val serialNumber: Int,
)