package de.geomobile.frontend.features.device.detail

import com.ccfraser.muirwik.components.spacingUnits
import de.geomobile.common.permission.Permissions
import de.geomobile.common.portalmodels.DeviceAudioDTO
import de.geomobile.frontend.api.TopicSession
import de.geomobile.frontend.portalWebSocketApi
import de.geomobile.frontend.utils.CComponent
import de.geomobile.frontend.utils.isAuthorized
import kotlinx.css.padding
import kotlinx.serialization.json.Json
import react.RBuilder
import react.RProps
import react.RState
import react.setState
import styled.css
import styled.styledDiv

fun RBuilder.elaAudio(
    id: Int,
    elaAudioUpdateExpanded: Boolean = false,
    onElaAudioUpdateExpanded: () -> Unit,
    elaAudioExpanded: Boolean = false,
    onElaAudioExpanded: () -> Unit,
) = child(ElaAudio::class) {
    key = id.toString()
    attrs.id = id
    attrs.elaAudioUpdateExpanded = elaAudioUpdateExpanded
    attrs.onElaAudioUpdateExpanded = onElaAudioUpdateExpanded
    attrs.elaAudioExpanded = elaAudioExpanded
    attrs.onElaAudioExpanded = onElaAudioExpanded
}

class ElaAudio : CComponent<ElaAudio.Props, ElaAudio.State>() {

    private var session: TopicSession? = null

    interface Props : RProps {
        var id: Int
        var elaAudioUpdateExpanded: Boolean
        var onElaAudioUpdateExpanded: () -> Unit
        var elaAudioExpanded: Boolean
        var onElaAudioExpanded: () -> Unit
    }

    class State(
        var audio: DeviceAudioDTO? = null,
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        session?.close()
        session = portalWebSocketApi.subscribe("/device/elaaudio", mapOf("id" to props.id.toString()))
        session?.connect {
            onmessage = {
                val audio = Json.decodeFromString(DeviceAudioDTO.serializer(), it)
                setState {
                    this.audio = audio
                }
            }
        }
    }

    override fun componentWillUnmount() {
        session?.close()
    }

    override fun RBuilder.render() {
        styledDiv {
            css { padding(2.spacingUnits) }
            elaAudioPanel(
                elaAudioUpdateExpanded = props.elaAudioUpdateExpanded,
                onElaAudioUpdateExpanded = props.onElaAudioUpdateExpanded,
                elaAudioExpanded = props.elaAudioExpanded,
                onElaAudioExpanded = props.onElaAudioExpanded,
                label = "Lautstärke",
                value = state.audio ?: DeviceAudioDTO(),
                device = props.id,
                applyPath = "/device/${props.id}/elaaudio/volume",
                offsetPath = "/device/${props.id}/elaaudio/volume_offset",
                testPath = "/device/${props.id}/elaaudio/playaudio",
                allowEdit = isAuthorized(Permissions.DeviceManagement.ela_config)
            )
        }
    }
}