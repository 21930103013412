package de.geomobile.frontend.features.device.detail

import com.ccfraser.muirwik.components.MGridDirection
import com.ccfraser.muirwik.components.MTypographyColor
import com.ccfraser.muirwik.components.MTypographyVariant
import com.ccfraser.muirwik.components.list.mList
import com.ccfraser.muirwik.components.list.mListItem
import com.ccfraser.muirwik.components.list.mListItemText
import com.ccfraser.muirwik.components.mTypography
import com.ccfraser.muirwik.components.styles.Breakpoint
import de.geomobile.common.portalmodels.DevicePositionDTO
import de.geomobile.frontend.api.TopicSession
import de.geomobile.frontend.portalWebSocketApi
import de.geomobile.frontend.utils.*
import kotlinx.serialization.SerializationException
import kotlinx.serialization.json.Json
import react.*
import styled.styledDiv

fun RBuilder.deviceGps(
    id: Int,
) = child(DeviceGpsComponent::class) {
    key = id.toString()
    attrs.id = id
}

class DeviceGpsComponent : RComponent<DeviceGpsComponent.Props, DeviceGpsComponent.State>() {

    private var session: TopicSession? = null

    interface Props : RProps {
        var id: Int
    }

    class State(
        var devicePosition: DevicePositionDTO? = null,
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        session?.close()
        session = portalWebSocketApi.subscribe("/device/position", mapOf("id" to props.id.toString()))
        session?.connect {
            onmessage = {
                val devicePosition = try {
                    Json.decodeFromString(DevicePositionDTO.serializer(), it)
                } catch (e: SerializationException) {
                    null
                }

                setState {
                    this.devicePosition = devicePosition
                }
            }
        }
    }

    override fun componentWillUnmount() {
        session?.close()
    }

    override fun RBuilder.render() {
        val position = state.devicePosition?.position ?: return
        val location = position.location

        mList {
            attrs.dense = true

            mGridContainer2(direction = MGridDirection.row) {
                mGridItem2(
                    MGridBreakpoints2(MGridSize2.Cells12)
                        .down(Breakpoint.md, MGridSize2.Cells6)
                ) {
                    mListItem {
                        attrs.button = false
                        attrs.divider = true

                        mListItemText {
                            mTypography(
                                text = "Latitude",
                                variant = MTypographyVariant.caption,
                            )
                            mTypography(
                                text = if (location != null) location.latitude.asDynamic().toFixed(6) else "-",
                                variant = MTypographyVariant.subtitle2,
                                color = MTypographyColor.textPrimary
                            )
                        }
                    }
                    mListItem {
                        attrs.button = false
                        attrs.divider = true

                        mListItemText {
                            mTypography(
                                text = "Longitude",
                                variant = MTypographyVariant.caption,
                            )
                            mTypography(
                                text = if (location != null) location.longitude.asDynamic().toFixed(6) else "-",
                                variant = MTypographyVariant.subtitle2,
                                color = MTypographyColor.textPrimary
                            )
                        }
                    }
                    mListItem {
                        attrs.button = false
                        attrs.divider = true

                        mListItemText {
                            mTypography(
                                text = "Satelliten",
                                variant = MTypographyVariant.caption,
                            )
                            mTypography(
                                text = if (location != null) location.satellites?.toString() else "-",
                                variant = MTypographyVariant.subtitle2,
                                color = MTypographyColor.textPrimary
                            )
                        }
                    }
                }
                mGridItem2(
                    MGridBreakpoints2(MGridSize2.Cells12)
                        .down(Breakpoint.md, MGridSize2.Cells6)
                ) {
                    mListItem {
                        attrs.button = false
                        attrs.divider = true

                        mListItemText {
                            mTypography(
                                text = "Letzte Position",
                                variant = MTypographyVariant.caption,
                            )
                            styledDiv {
                                timestampStatus(position.lastLocation)
                            }
                        }
                    }
                    mListItem {
                        attrs.button = false
                        attrs.divider = true

                        mListItemText {
                            mTypography(
                                text = "Letzte Aktualisierung",
                                variant = MTypographyVariant.caption,
                            )
                            styledDiv {
                                timestampStatus(position.lastSeen)
                            }
                        }
                    }
                }
            }
        }
    }
}