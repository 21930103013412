package de.geomobile.frontend.features.portalSettings

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardActions
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.form.MFormControlMargin
import com.ccfraser.muirwik.components.form.MFormControlVariant
import com.ccfraser.muirwik.components.form.margin
import com.ccfraser.muirwik.components.list.mListSubheader
import com.ccfraser.muirwik.components.styles.Breakpoint
import components.emptyView
import de.geomobile.common.permission.Permissions
import de.geomobile.common.portalmodels.Company
import de.geomobile.common.utils.Tree
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.spacer
import de.geomobile.frontend.utils.*
import kotlinext.js.assign
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.Color
import kotlinx.css.color
import kotlinx.css.padding
import kotlinx.css.px
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.builtins.MapSerializer
import kotlinx.serialization.builtins.serializer
import kotlinx.serialization.json.Json
import portalmodels.GenericResponseDTO
import react.RBuilder
import react.RProps
import react.RState
import react.setState
import styled.StyledElementBuilder
import styled.css
import styled.styledDiv

fun RBuilder.customerInformation() = child(PortalSettingsCustomerInformation::class) {}

class PortalSettingsCustomerInformation :
    CComponent<RProps, PortalSettingsCustomerInformation.State>() {

    private var fetchListJob: Job = Job()
    private var fetchInformation: Job = Job()

    class State(
        var selectedCompany: String = "ALL",
        var companyTree: Tree<String>? = null,
        var informationMap: Map<String, String> = mapOf(),
        var saveOK: Boolean = false
    ) : RState

    init {
        state = State()
    }

    private fun fetchInformation() {
        fetchInformation.cancel()
        fetchInformation = launch {
            val companies = portalRestApi.get("/admin/companies", ListSerializer(Company.serializer()))
            val info = portalRestApi.get(
                "/portalsettings/information",
                MapSerializer(String.serializer(), String.serializer())
            )

            val treeNodes =
                listOf(Tree.Node("ALL")) + companies.map { k ->
                    Tree.Node(k.id)
                }

            setState {
                informationMap = info
                companyTree = Tree(treeNodes)
            }
        }
    }

    private fun setInformation() {
        launch {
            val response = withContext(Dispatchers.Default) {
                val body = Json.encodeToString(
                    MapSerializer(String.serializer(), String.serializer()),
                    state.informationMap
                )
                portalRestApi.put("/portalsettings/information", body, GenericResponseDTO.serializer())
            }

            if (!response.isError)
                setState {
                    saveOK = true
                }
        }.invokeOnCompletion {
            fetchInformation()
        }
    }

    override fun componentDidMount() {
        fetchInformation()
    }

    override fun RBuilder.render() {
        authorize(Permissions.DeviceManagement.repairHistoryUser) {
            spacer()
            mGridContainer2(direction = MGridDirection.row) {
                if (isAuthorized(Permissions.AdminPermissions.internalAccess)) {
                    mGridItem2(
                        MGridBreakpoints2(MGridSize2.Cells3)
                            .down(Breakpoint.md, MGridSize2.Cells12)
                    ) {
                        sideFilter()
                    }
                }
                mGridItem2(
                    MGridBreakpoints2(
                        if (isAuthorized(Permissions.AdminPermissions.internalAccess)) MGridSize2.Cells9 else MGridSize2.Cells12
                    )
                        .down(Breakpoint.md, MGridSize2.Cells12)
                ) {
                    detail()
                }
            }
        }
    }

    private fun StyledElementBuilder<MGridProps2>.sideFilter() {
        val tree: Tree<String?>? = state.companyTree as Tree<String?>?

        val entryTree = tree
            ?.mapByBranch { branch, (value) ->
                TreeEntry(
                    key = value ?: "0",
                    name = value ?: "0",
                    new = value == null,
                    selected = value == state.selectedCompany,
                    expandedByDefault = branch.size <= 0,
                    onClick = {
                        value?.let {
                            setState {
                                selectedCompany = value
                            }
                        }
                    }
                )
            }

        mGridContainer2(direction = MGridDirection.column) {
            mGridItem2 {
                if (state.companyTree == null)
                    mSkeleton(
                        height = 100.px,
                        animation = MSkeletonAnimation.wave,
                        variant = MSkeletonVariant.rect
                    )
                else {
                    mCard {
                        css(GlobalStyles.card)
                        mCardContent {
                            css(GlobalStyles.cardListContent)
                            if (tree?.rootNodes?.isEmpty() == true)
                                emptyView(
                                    boxShadow = false,
                                    iconName = "menu",
                                    title = "Unternehmen",
                                    caption = "Es wurden keine Unternehmen gefunden",
                                    addButton = false
                                )
                            else {
                                tree(heading = "Unternehmen", tree = entryTree!!)
                            }
                        }
                    }
                }
            }
        }
    }

    private fun RBuilder.detail() {
        mGridContainer2(direction = MGridDirection.row) {
            mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                mCard {
                    css(GlobalStyles.card)
                    mCardContent {
                        css(GlobalStyles.cardContent)
                        mListSubheader(heading = "Nachricht")
                        mDivider {}
                        styledDiv {
                            css { padding(2.spacingUnits) }
                            mTextField(
                                value = state.informationMap[state.selectedCompany] ?: "",
                                label = "",
                                placeholder = "Nachricht",
                                variant = MFormControlVariant.outlined,
                                onChange = { event ->
                                    setState {
                                        informationMap =
                                            informationMap + (state.selectedCompany to event.target.asDynamic().value)
                                    }
                                }
                            ) { attrs.fullWidth = true; attrs.margin = MFormControlMargin.dense }
                        }
                    }
                    mDivider {}
                    mCardActions {
                        css { padding(2.spacingUnits) }
                        mButton(
                            caption = "Übernehmen",
                            color = MColor.secondary,
                            variant = MButtonVariant.contained,
                            onClick = {
                                setInformation()
                            }
                        ) {
                            attrs.disableElevation = true
                        }
                        mButton(
                            caption = "Löschen",
                            color = MColor.inherit,
                            variant = MButtonVariant.outlined,
                            onClick = {
                                setState({
                                    assign(it) {
                                        informationMap = informationMap + (state.selectedCompany to "")
                                    }
                                }) {
                                    setInformation()
                                }
                            }
                        ) {
                            css { color = Color.red }
                            attrs.disableElevation = true
                        }

                        if (state.saveOK)
                            mIconNoTranslate("check") {
                                css {
                                    color = Color.green
                                }
                            }
                    }
                }
            }
        }
    }
}

