package de.geomobile.frontend.features.device.detail.selftest

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.dialog.DialogScroll
import com.ccfraser.muirwik.components.dialog.mDialog
import com.ccfraser.muirwik.components.dialog.mDialogActions
import com.ccfraser.muirwik.components.dialog.mDialogContent
import com.ccfraser.muirwik.components.styles.Breakpoint
import de.geomobile.common.time.LocalDateTime
import de.geomobile.frontend.features.device.detail.CustomStyles
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.utils.CComponent
import de.geomobile.frontend.utils.diffString
import de.geomobile.frontend.utils.timerWrapper
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.css.*
import kotlinx.serialization.Serializable
import react.RBuilder
import react.RProps
import react.RState
import react.setState
import reactjsonview.reactJson
import styled.css
import styled.styledDiv

fun RBuilder.deviceSelfTestHeader(
    id: Int,
    timestamp: LocalDateTime?,
    selfTestResultJson: String?,
    selfTestResult: SelfTestResult?,
) = child(DeviceSelfTestHeaderComponent::class) {
    key = id.toString()
    attrs.id = id
    attrs.timestamp = timestamp
    attrs.selfTestResult = selfTestResult
    attrs.selfTestResultJson = selfTestResultJson
}

@Serializable
data class SelfTestResult(
    val moduleSelftestResults: List<ModuleSelfTestResults>,
)

fun SelfTestResult.succeededResults(): Int {
    return moduleSelftestResults.map {
        it.testResults.filter { testResult -> testResult.result }.count()
    }.sum()
}

fun SelfTestResult.failedResults(): Int {
    return moduleSelftestResults.map {
        it.testResults.filter { testResult -> !testResult.result }.count()
    }.sum()
}

fun SelfTestResult.isSuccessful(): Boolean {
    return moduleSelftestResults.all { it.testResults.all { testResult -> testResult.result } }
}

@Serializable
data class ModuleSelfTestResults(
    val slotModule: SlotModule,
    val testResults: List<TestResult>,
)

@Serializable
data class SlotModule(
    val slot: String,
    val name: String,
)

@Serializable
data class TestResult(
    val description: String,
    val resultText: String? = null,
    val result: Boolean,
)

class DeviceSelfTestHeaderComponent :
    CComponent<DeviceSelfTestHeaderComponent.Props, DeviceSelfTestHeaderComponent.State>() {

    private var selfTestJob: Job = Job()

    interface Props : RProps {
        var id: Int
        var timestamp: LocalDateTime?
        var selfTestResultJson: String?
        var selfTestResult: SelfTestResult?
    }

    class State(
        var showJSON: Boolean = false,
    ) : RState

    init {
        state = State()
    }

    override fun RBuilder.render() {

        val timestamp = props.timestamp
        val selfTestResultJson = props.selfTestResultJson
        val selfTestResult = props.selfTestResult

        mGridContainer(
            alignItems = MGridAlignItems.center, justify = MGridJustify.spaceBetween
        ) {

            mGridItem(
                MGridBreakpoints(MGridSize.cellsAuto).down(Breakpoint.sm, MGridSize.cells12)
            ) {
                mButton(caption = "Starten",
                    variant = MButtonVariant.contained,
                    color = MColor.secondary,
                    onClick = { triggerSelfTest() }) {
                    attrs.fullWidth = true
                    attrs.disableElevation = true
                }
            }

            if (timestamp != null && selfTestResult != null) {
                styledDiv {
                    css { padding(1.spacingUnits, 0.spacingUnits) }

                    mGridItem {
                        mGridContainer {
                            mGridItem {
                                mTypography(
                                    text = "${selfTestResult.succeededResults()}",
                                    variant = MTypographyVariant.subtitle2
                                ) {
                                    css {
                                        +CustomStyles.label
                                        marginRight = 3.px
                                        backgroundColor = Color.green
                                    }
                                }
                            }
                            mGridItem {
                                mTypography(
                                    text = "${selfTestResult.failedResults()}", variant = MTypographyVariant.subtitle2
                                ) {
                                    css {
                                        +CustomStyles.label
                                        backgroundColor = Color.red
                                    }
                                }
                            }
                            mGridItem {
                                timerWrapper(1000) {
                                    val diff = (LocalDateTime.now() - timestamp).diffString()
                                    mTypography(
                                        text = "Empfangen vor $diff", variant = MTypographyVariant.caption
                                    ) { css { marginLeft = 1.spacingUnits } }
                                }
                            }
                        }
                    }
                }
            } else {
                mGridItem {
                    if (selfTestResultJson != null) {
                        mTooltip(selfTestResultJson) {
                            mTypography(
                                text = "Ergebnis", variant = MTypographyVariant.subtitle2
                            ) {
                                css {
                                    +CustomStyles.label
                                    backgroundColor = Color.green
                                }
                            }
                        }
                    }
                }
            }

            mGridItem(
                MGridBreakpoints(MGridSize.cells2).down(Breakpoint.xs, MGridSize.cells12)
            ) {
                if (selfTestResultJson != null && selfTestResult != null) {
                    mDialog(open = state.showJSON, maxWidth = Breakpoint.lg, scroll = DialogScroll.paper) {
                        mDialogContent {
                            try {
                                reactJson(src = selfTestResultJson)
                            } catch (e: Exception) {
                                mTypography(text = "Invalid JSON")
                            }
                        }

                        mDialogActions {
                            mButton("Schließen", color = MColor.primary, onClick = {
                                setState {
                                    showJSON = false
                                }
                            })
                        }
                    }
                    mButton(caption = "JSON ansehen",
                        variant = MButtonVariant.contained,
                        color = MColor.secondary,
                        onClick = { setState { showJSON = true } }) {
                        css {
                            marginLeft = LinearDimension.auto
                            display = Display.block
                        }
                        attrs.fullWidth = true
                        attrs.disableElevation = true
                    }
                }
            }
        }
    }

    private fun triggerSelfTest() {
        selfTestJob.cancel()
        selfTestJob = launch(Dispatchers.Default) {
            portalRestApi.post("/device/${props.id}/selftest")
        }
    }
}