package de.geomobile.frontend.features.admin.sharedDevices

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.card.mCardHeader
import com.ccfraser.muirwik.components.form.MFormControlVariant
import de.geomobile.common.filter.*
import de.geomobile.common.permission.Permissions
import de.geomobile.common.portalmodels.*
import de.geomobile.frontend.features.device.list.DeviceListItem
import de.geomobile.frontend.features.device.list.DeviceListItemFilterList
import de.geomobile.frontend.features.device.list.deviceList
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.utils.CComponent
import de.geomobile.frontend.utils.isAuthorized
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.async
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.*
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.builtins.serializer
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.jsonArray
import kotlinx.serialization.json.jsonPrimitive
import react.RBuilder
import react.RProps
import react.RState
import react.setState
import styled.css

fun RBuilder.sharedDevicesDetail(
    selectedGroup: Pair<String, CompanySmall?>,
    onDeviceClick: (id: DeviceIdentifier) -> Unit,
    company: CompanySmall?,
) = child(SharedDevicesDetail::class) {
    attrs.selectedGroup = selectedGroup
    attrs.onDeviceClick = onDeviceClick
    attrs.company = company
}

class SharedDevicesDetail : CComponent<SharedDevicesDetail.Props, SharedDevicesDetail.State>() {

    interface Props : RProps {
        var selectedGroup: Pair<String, CompanySmall?>
        var onDeviceClick: (id: DeviceIdentifier) -> Unit
        var company: CompanySmall?
    }

    class State(
        var assignedString: String? = null,
        var previousAssignedString: String? = null,
        var validFilter: Boolean = true,
        var invalidEntries: List<String> = emptyList(),
        var deviceFilter: Map<String, Filter<DeviceListItem, *>> = emptyMap(),
        var deviceCount: Int = 0,
        var devicesNotFound: String = "",
        var saving: Boolean = false,

        ) : RState

    init {
        state = State()
    }

    override fun componentWillReceiveProps(nextProps: SharedDevicesDetail.Props) {
        if ((nextProps.selectedGroup != props.selectedGroup) || (nextProps.company != props.company))
        // reload view when company has changed
            componentDidMount()
    }

    override fun componentDidMount() {
        launch {

            val companies = async(Dispatchers.Default) {
                portalRestApi.get("/admin/companies", ListSerializer(Company.serializer()))
            }

            val vehicleProfiles = if (isAuthorized(Permissions.VehicleProfileManagement.profilesView)) {
                withContext(Dispatchers.Default) {
                    portalRestApi.get("/vehicleprofiles", ListSerializer(VehicleProfileDTO.serializer()))
                }
            } else emptyList()

            val deviceListItemDtoFilters: Map<String, Filter<DeviceListItem, *>> = DeviceListItemFilterList(
                products = Product.values().asList(),
                companies = companies.await(),
                vehicleProfiles = vehicleProfiles
            ).filters

            val assignedString = if (props.company != null && props.selectedGroup.second != null)
                withContext(Dispatchers.Default) {
                    portalRestApi.getRaw("/devicesshared/${props.company!!.id}/${props.selectedGroup.second!!.id}")
                }
            else
                null

            // reset state
            setState(State())
            setState {
                this.assignedString = assignedString
                this.previousAssignedString = assignedString
                this.deviceFilter = deviceListItemDtoFilters
            }
        }
    }

    override fun RBuilder.render() {

        mCard {
            css {
                flexGrow = 1.0
                marginBottom = 1.spacingUnits
                display = Display.flex
                flexDirection = FlexDirection.column
                overflow = Overflow.hidden
            }

            mCardHeader(title = props.selectedGroup.first)

            mCardContent {
                css {
                    flexGrow = 1.0
                    overflowY = Overflow.auto
                    overflowX = Overflow.hidden
                    display = Display.flex
                    flexWrap = FlexWrap.wrap
                    flexDirection = FlexDirection.row
                    alignContent = Align.baseline
                    paddingTop = 0.spacingUnits
                }
                if (!listOf("Eigene Geräte", "Mir geteilte Geräte").contains(props.selectedGroup.first)) {
                    mTextField(
                        label = "Zugewiesene Seriennummern",
                        value = state.assignedString ?: "",
                        variant = MFormControlVariant.outlined,
                        disabled = state.assignedString == null,
                        fullWidth = true,
                        error = !state.validFilter,
                        onChange = {
                            val value = it.targetInputValue

                            if (value == "*") {
                                setState {
                                    this.assignedString = "*"
                                    this.validFilter = true
                                    this.devicesNotFound = ""
                                    this.invalidEntries = emptyList()
                                }
                            } else {
                                val filterStr = assignedDevicesToFilterString(value)
                                val parsed = try {
                                    filterStr?.parseFilterRules(state.deviceFilter)
                                } catch (e: Throwable) {
                                    null
                                }
                                val valueList = value.decomposeRangeValues(false)

                                // get all entries that are not integer values
                                val invalidEntries = valueList.filter { strValue ->
                                    try {
                                        strValue.toInt()
                                        false
                                    } catch (e: NumberFormatException) {
                                        true
                                    }
                                }

                                setState {
                                    this.assignedString = value
                                    this.validFilter = parsed != null
                                    this.invalidEntries = invalidEntries
                                }
                            }
                        }
                    ) {
                        css {
                            marginRight = 2.spacingUnits
                            marginBottom = 4.spacingUnits
                        }
                    }
                    // warning if at least one entry is not an integer
                    if (state.invalidEntries.isNotEmpty()) {
                        val warningText =
                            if (state.invalidEntries.size == 1)
                                "Achtung: Die eingegebene Seriennummer ${state.invalidEntries.first()} ist ungültig (nur Ziffern von 0-9 sind erlaubt)."
                            else "Achtung: Die eingegebene Seriennummern ${
                                state.invalidEntries.joinToString(
                                    ", ",
                                    limit = 10
                                )
                            } sind ungültig (nur Ziffern von 0-9 sind erlaubt)."

                        mTypography(
                            text = warningText,
                            variant = MTypographyVariant.body2,
                            color = MTypographyColor.error,
                            align = MTypographyAlign.left
                        ) {
                            css {
                                flexBasis = FlexBasis.fill
                                width = 100.pct
                            }
                        }
                    }
                    if (state.saving)
                        mCircularProgress(size = 30.px) {
                            css {
                                display = Display.block
                                margin(vertical = 1.spacingUnits, horizontal = 3.spacingUnits)
                                marginLeft = LinearDimension.auto
                            }
                        } else {

                        mButton(
                            "Alle Geräte teilen",
                            disabled = state.assignedString == "*",
                            onClick = {
                                setState {
                                    this.assignedString = "*"
                                    this.validFilter = true
                                    this.devicesNotFound = ""
                                    this.invalidEntries = emptyList()
                                }
                            }
                        ) {
                            css { marginLeft = LinearDimension.auto }
                        }

                        mButton(
                            "Speichern",
                            variant = MButtonVariant.contained,
                            color = MColor.secondary,
                            disabled = !state.validFilter || state.invalidEntries.isNotEmpty() || state.assignedString == state.previousAssignedString
                                    || state.assignedString == null,
                            onClick = { setAssignedDevices() }
                        )
                    }
                }
            }
        }
        when (props.selectedGroup.first) {
            "Eigene Geräte" -> allDevices(false)
            "Mir geteilte Geräte" -> allDevices(true)
            else -> {
                if (state.assignedString == "*") {
                    allDevices(false)
                } else filteredDevices()
            }

        }


    }

    private fun RBuilder.allDevices(onlySharedDevices: Boolean = false) {
        mCard {
            css {
                display = Display.flex
                flexDirection = FlexDirection.column
                maxHeight = 50.pct
                flexShrink = 0.0
            }
            if (state.deviceFilter.isNotEmpty() && props.company != null) {

                val filter =
                    if (onlySharedDevices) "Unternehmen != ${props.company!!.name} AND GeteiltMit contains ${props.company!!.name}".parseFilterRules(
                        state.deviceFilter
                    )
                    else "Unternehmen == ${props.company!!.name}".parseFilterRules(state.deviceFilter)

                val matcher = FilterMatcher(
                    filterRules = FilterRules(rules = filter.rules),
                    filters = state.deviceFilter
                )
                deviceList(
                    persistenceId = "SharedDevices",
                    onDeviceClick = { props.onDeviceClick(it) },
                    filter = matcher
                )
            }

        }
    }

    private fun RBuilder.filteredDevices() {
        mCard {
            css {
                display = Display.flex
                flexDirection = FlexDirection.column
                maxHeight = 50.pct
                flexShrink = 0.0
            }

            val idsInUseFilter = state.assignedString?.decomposeRangeValues(false)

            val filterString = assignedDevicesToFilterString(state.assignedString)

            if (state.deviceFilter.isNotEmpty() && filterString != null && state.validFilter) {

                val filter = filterString.parseFilterRules(state.deviceFilter)

                val matcher = FilterMatcher(
                    filterRules = FilterRules(rules = filter.rules),
                    filters = state.deviceFilter
                )
                mCardHeader(
                    title = "${state.deviceCount} von " +
                            "${idsInUseFilter?.count()} Geräte gefunden",
                    subHeader = if (state.devicesNotFound != "") "Nicht gefunden: ${state.devicesNotFound}" else null
                )

                css {
                    flex(0.0, 1.0, FlexBasis.auto)
                    overflow = Overflow.auto
                }

                deviceList(
                    persistenceId = "SharedDevices",
                    onDeviceClick = { props.onDeviceClick(it) },
                    filter = matcher,
                    onFilteredDevicesChanged = {
                        setState {
                            if (deviceCount != it.count())
                                deviceCount = it.count()

                        }
                    },
                    onGetNotMatchedRules = { notMatched ->
                        setState {
                            this.devicesNotFound = notMatched.rules
                                .firstOrNull { it.filterId == "serialNumber" && it.rule.operatorId == "IN" }
                                ?.rule?.ref?.jsonArray?.flatMap { listOf(it.jsonPrimitive.content) }?.joinToString(", ")
                                ?: ""
                        }
                    }
                )
            }
        }
    }

    private fun assignedDevicesToFilterString(filter: String?) =
        if (filter != null && props.company != null) "Unternehmen == ${props.company!!.name} AND Seriennummer in [$filter]"
        else null

    private fun setAssignedDevices() {
        setState {
            this.saving = true
        }

        launch {

            withContext(Dispatchers.Default) {
                val deviceList =
                    if (state.assignedString == "*") {
                        listOf("-1")
                    } else
                        state.assignedString?.decomposeRangeValues(false) ?: emptyList()
                //save assignedDevices
                portalRestApi.put(
                    "/devicesshared/${props.company?.id}/${props.selectedGroup.second?.id}",
                    body = Json.encodeToJsonElement(ListSerializer(String.serializer()), deviceList)
                )

            }
        }
        //reload
        componentDidMount()

    }
}