package de.geomobile.frontend

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.list.mList
import com.ccfraser.muirwik.components.list.mListItem
import com.ccfraser.muirwik.components.list.mListItemIcon
import com.ccfraser.muirwik.components.list.mListItemText
import com.ccfraser.muirwik.components.transitions.mCollapse
import de.geomobile.common.feature.Features
import de.geomobile.common.permission.Permissions
import de.geomobile.common.portalmodels.Product
import de.geomobile.frontend.device.detail.alert
import de.geomobile.frontend.device.detail.notification
import de.geomobile.frontend.device.detail.trialBadge
import de.geomobile.frontend.utils.*
import kotlinext.js.js
import kotlinext.js.jsObject
import kotlinx.coroutines.launch
import kotlinx.css.*
import kotlinx.css.properties.TextDecoration
import react.RBuilder
import react.RProps
import react.RState
import react.router.dom.RouteResultLocation
import react.setState
import styled.css
import styled.styledImg

fun RBuilder.drawer(
    location: RouteResultLocation,
    responsiveDrawerOpen: Boolean,
    onClose: () -> Unit,
    openDebugSettings: () -> Unit
) = child(Drawer::class) {
    attrs.location = location
    attrs.onClose = onClose
    attrs.responsiveDrawerOpen = responsiveDrawerOpen
    attrs.openDebugSettings = openDebugSettings
}

class Drawer : CComponent<Drawer.Props, Drawer.State>() {

    interface Props : RProps {
        var location: RouteResultLocation
        var responsiveDrawerOpen: Boolean
        var onClose: () -> Unit
        var openDebugSettings: () -> Unit
    }

    class State(
        var newRepairsPending: Boolean = false,
        var hasConflicts: Boolean = false
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        launch {
            val newRepairs = portalRestApi.getRaw("/repair/notification").lowercase()
            val configConflicts = portalRestApi.getRaw("/config/hasconflicts").lowercase()
            println(configConflicts)
            setState {
                if (newRepairs == "true")
                    newRepairsPending = true
                if (configConflicts == "true")
                    hasConflicts = true
            }
        }
    }

    override fun RBuilder.render() {
        val p: MPaperProps = jsObject { }
        p.asDynamic().style = js {
            position = "relative"
        }
        mHidden(mdUp = true) {
            mDrawer(
                props.responsiveDrawerOpen,
                MDrawerAnchor.left,
                MDrawerVariant.temporary,
                paperProps = p,
                onClose = { props.onClose() }) {
                css(GlobalStyles.drawer)
                drawerItems()
            }
        }
        mHidden(smDown = true, implementation = MHiddenImplementation.css) {
            mDrawer(
                open = true,
                MDrawerAnchor.left,
                MDrawerVariant.permanent,
                paperProps = p
            ) {
                css(GlobalStyles.drawer)
                drawerItems()
            }
        }
    }

    private fun RBuilder.drawerItems() {
        mList {
            css {
                height = 100.vh
                overflowY = Overflow.auto
                overflowX = Overflow.hidden
                wordBreak = WordBreak.keepAll
                display = Display.flex
                flexDirection = FlexDirection.column
            }
            mListItem {
                css { display = Display.block }
                styledImg(alt = "ivanto Logo", src = "/portal/static/ivanto_logo_rgb.png") {
                    css {
                        display = Display.block
                        width = 200.px
                        margin(horizontal = LinearDimension.auto)
                    }
                }
                if (PORTAL_ENV == "dev") {
                    mChip(
                        label = "Development Portal".uppercase(),
                        variant = MChipVariant.outlined,
                        size = MChipSize.small
                    ) { css { display = Display.flex } }
                } else {
                    mChip(
                        label = "Online Portal".uppercase(),
                        variant = MChipVariant.outlined,
                        size = MChipSize.small
                    ) { css { display = Display.flex } }
                }
            }
            if (UserStore.user != null) {
                mDivider { }
                addListItem(
                    caption = "Konto",
                    secondary = UserStore.user!!.email,
                    icon = "account_circle",
                    dense = true,
                    route = "/features/user-settings"
                )
            }
            mDivider { }
            authorize(Permissions.StatisticsManagement.dashboard) {
                addListItem(caption = "Dashboard", icon = "dashboard", route = "/dashboard")
            }
            authorize(Permissions.DeviceManagement.devicesView) {
                addListItem(caption = "Geräte", icon = "memory", route = "/devices")
            }
            authorize(Permissions.DeviceManagement.cableCheck) {
                addListItem(caption = "Quick Check", icon = "bolt", route = "/devices/quickcheck/0")
            }
            authorize(Permissions.CompanyManagement.textToSpeechEdit) {
                addListItem(caption = "TTS", icon = "record_voice_over_rounded", route = "/tts")
            }
            authorize(Permissions.MapManagement.mapView) {
                addListItem(
                    caption = "Karte",
                    icon = "map",
                    route = "/map",
                    trial = isTrial(Features.DeviceFeatures.map)
                )
            }
            authorize(Permissions.StatisticsManagement.statView) {
                addListItem(
                    caption = "Statistik",
                    icon = "insert_chart",
                    route = "/statistics",
                    trial = isTrial(Features.StatisticsFeatures.statistics)
                )
            }
            authorize(Permissions.VehicleProfileManagement.profilesView) {
                addListItem(
                    caption = "Fahrzeugprofile",
                    icon = "commute",
                    route = "/vehicleprofiles",
                    trial = isTrial(Features.VehicleProfileFeatures.vehicleProfiles)
                )
            }
            authorize(Permissions.CompanyProfileManagement.profileView) {
                addListItem(
                    caption = "Unternehmensprofil",
                    icon = "business",
                    route = "/companyprofile",
                    trial = isTrial(Features.CompanyFeatures.companyProfiles)
                )
            }
            authorize(Permissions.DeviceManagement.repairHistoryUser) {
                addListItem(
                    caption = "Reparaturen",
                    icon = "build",
                    route = "/features/repair",
                    trial = isTrial(Features.DeviceFeatures.repairHistory)
                )
            }
            authorize(Permissions.AdminPermissions.internalAccess) {
                addListItem(caption = "Konfiguration", icon = "tune", route = "/config")
                addListItem(caption = "Software", icon = "save", route = "/software")
                mCollapse(props.location.pathname.startsWith("/software")) {
                    css {
                        paddingLeft = 4.spacingUnits
                        minHeight = LinearDimension("unset !important")
                    }
                    addListItem(
                        caption = "Connect & Core",
                        icon = "memory",
                        route = "/software/${Product.CONNECT.readableName}",
                        dense = true
                    )
                    addListItem(
                        caption = "Interact",
                        icon = "memory",
                        route = "/software/${Product.INTERACT.readableName}",
                        dense = true
                    )
                    addListItem(
                        caption = "Update Queue",
                        icon = "update",
                        route = "/software/queue",
                        dense = true
                    )
                }
                addListItem(caption = "Druckaufträge", icon = "print", route = "/print")
            }
            addListItem(caption = "VDV Upload", icon = "get_app", route = "/upload")
            addListItem(caption = "Dokumentation", icon = "description", route = "/documentation")
            addListItem(caption = "Monitoring", icon = "visibility", route = "/features/monitoring")
            authorize(Permissions.AdminPermissions.adminControlsView) {
                mDivider { css { marginTop = LinearDimension.auto; marginBottom = 1.spacingUnits } }
            }
            authorize(Permissions.AdminPermissions.adminControlsView) {
                addListItem(caption = "Einstellungen", icon = "settings", route = "/features/portal-settings")
            }
            authorize(Permissions.AdminPermissions.adminControlsView) {
                addListItem(caption = "Admin", icon = "supervisor_account", route = "/admin")
            }
            authorize(Permissions.AdminPermissions.debugAccess) {
                addListItem(
                    caption = "Demo-Modus",
                    icon = "visibility",
                    selected = UserStore.debugMode,
                    onClick = { props.openDebugSettings() }
                )
            }
        }
    }

    fun RBuilder.addListItem(
        caption: String,
        secondary: String? = null,
        icon: String? = null,
        selected: Boolean = false,
        trial: Boolean = false,
        dense: Boolean = false,
        onClick: () -> Unit
    ) {
        mListItem(
            button = true,
            dense = dense,
            onClick = {
                onClick()
                props.onClose()
            }
        ) {
            css { padding(0.px, 2.spacingUnits) }
            mListItemIcon(
                iconName = icon,
                className = "notranslate"
            ) {
                if (selected) css { descendants { color = Color(currentTheme.palette.secondary.main) } }
            }
            mListItemText(
                primary = caption,
                secondary = secondary
            ) {
                if (selected) css { descendants { color = Color(currentTheme.palette.secondary.main) } }
                attrs.secondaryTypographyProps = jsObject {
                    noWrap = true
                    variant = MTypographyVariant.caption
                }
            }

            if (trial)
                trialBadge()

            if (isAuthorized(Permissions.AdminPermissions.internalAccess))
                if (caption == "Reparaturen" && state.newRepairsPending)
                    notification()
            if (isAuthorized(Permissions.AdminPermissions.internalAccess))
                if (caption == "Konfiguration" && state.hasConflicts)
                    alert()
        }
    }

    fun RBuilder.addListItem(
        caption: String,
        secondary: String? = null,
        icon: String? = null,
        route: String,
        trial: Boolean = false,
        dense: Boolean = false
    ) {
        styledRouteLink(to = route) {
            css {
                textDecoration = TextDecoration.none
                color = Color(currentTheme.palette.text.primary)
            }
            addListItem(
                caption = caption,
                secondary = secondary,
                icon = icon,
                selected = props.location.pathname.startsWith(route),
                dense = dense,
                trial = trial
            ) {}
        }
    }
}
