package de.geomobile.frontend.features.admin.features

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.form.mFormControlLabel
import com.ccfraser.muirwik.components.styles.Breakpoint
import com.ccfraser.muirwik.components.table.*
import de.geomobile.common.feature.Feature
import de.geomobile.common.feature.FeatureGroup
import de.geomobile.common.feature.Features
import de.geomobile.frontend.utils.*
import kotlinx.css.*
import kotlinx.css.properties.border
import kotlinx.css.properties.borderBottom
import react.*
import styled.css
import styled.styledDiv

fun RBuilder.featureSelection(
    mutable: Boolean,
    features: Map<String, Feature>,
    onChanged: (Map<String, Feature>) -> Unit,
) = child(FeatureSelectionComponent::class) {
    attrs.mutable = mutable
    attrs.features = features
    attrs.onChanged = onChanged
}

class FeatureSelectionComponent : RComponent<FeatureSelectionComponent.Props, RState>() {

    interface Props : RProps {
        var mutable: Boolean
        var features: Map<String, Feature>
        var onChanged: (Map<String, Feature>) -> Unit
    }

    override fun RBuilder.render() {
        val selectedFeatures = props.features

        mGridContainer2(direction = MGridDirection.row) {
            for (featureGroup in Features.featureGroups) {
                mGridItem2(
                    MGridBreakpoints2(MGridSize2.Cells6)
                        .down(Breakpoint.sm, MGridSize2.Cells12)
                ) {
                    renderFeatureGroup(featureGroup, selectedFeatures)
                }
            }
        }
    }

    private fun RElementBuilder<MGridProps2>.renderFeatureGroup(
        featureGroup: FeatureGroup,
        selectedFeatures: Map<String, Feature>,
    ) {
        val featuresInGroup = featureGroup.features

        styledDiv {
            css {
                border(1.px, BorderStyle.solid, Color.lightGrey)
                borderRadius = 1.spacingUnits
            }

            styledDiv {
                css {
                    width = 100.pct
                    textTransform = TextTransform.uppercase
                    borderBottom(1.px, BorderStyle.solid, Color.lightGrey)
                    display = Display.flex
                    padding(1.spacingUnits, 2.spacingUnits)
                }

                mTypography(featureGroup.name, variant = MTypographyVariant.subtitle1)
            }

            mTable {
                mTableHead {
                    mTableRow {
                        css { height = LinearDimension.fitContent }
                        mTableCell {
                            +"Testversion"
                            css {
                                background = "lightGray"
                                padding = "0px"
                                textAlign = TextAlign.center
                                width = LinearDimension("90px !important")
                            }
                        }
                        mTableCell {
                            +"Aktiviert"
                        }
                    }
                }
                mTableBody {
                    for (feature in featuresInGroup) {
                        mTableRow {
                            css { height = LinearDimension.fitContent }
                            mTableCell {
                                mCheckbox(
                                    checked = selectedFeatures[feature.id]?.testVersion ?: false,
                                    disabled = !props.mutable,
                                    onChange = { _, checked ->
                                        props.onChanged(
                                            selectedFeatures + (feature.id to feature.copy(testVersion = checked))
                                        )
                                    }
                                )
                                {
                                    css { padding(vertical = 0.spacingUnits) }
                                }
                                css {
                                    background = "rgba(224, 224, 224, 1)"
                                    padding = "0px"
                                    textAlign = TextAlign.center
                                    width = LinearDimension("90px !important")
                                }
                            }
                            mTableCell {
                                val checked = selectedFeatures[feature.id] != null
                                val disabled = !props.mutable
                                val checkBox = mCheckbox(
                                    checked = checked,
                                    disabled = disabled,
                                    onChange = { _, checked ->
                                        props.onChanged(
                                            if (checked) props.features + (feature.id to feature)
                                            else props.features - feature.id
                                        )
                                    },
                                    addAsChild = false
                                ) {
                                    css { padding(vertical = 0.spacingUnits) }
                                }

                                mFormControlLabel(
                                    feature.name,
                                    checkBox,
                                    checked,
                                    disabled
                                )
                            }
                        }
                    }
                }
            }
        }
    }
}