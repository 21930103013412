package de.geomobile.common.statistics

import de.geomobile.common.time.LocalDateTime

class DateTreeElement(
        val type: DateElementType,
        val myDate: LocalDateTime

) {

    enum class DateElementType(val readableName: String) {
        YEAR("Jahr"),
        MONTH("Monat"),
        DAY("Tag");

        companion object {
            private val mapping = values().associateBy(DateElementType::readableName)
            fun fromReadable(readableName: String) = mapping[readableName]
        }
    }
}




