package de.geomobile.common.permission

import de.geomobile.common.feature.Feature
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.Serializer
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder


@Serializable
data class Permission(
    val id: String,
    val name: String,
    val visibleForCompany: Boolean = true,
    val requiredFeatures: Set<Feature> = emptySet()
) {

    @Serializer(forClass = Permission::class)
    companion object : KSerializer<Permission> {

        override val descriptor: SerialDescriptor =
            PrimitiveSerialDescriptor("WithCustomDefault", PrimitiveKind.STRING)

        override fun serialize(encoder: Encoder, obj: Permission) = encoder.encodeString(obj.id)

        override fun deserialize(decoder: Decoder): Permission {
            val id = decoder.decodeString()
            return Permissions.getById(id)
        }
    }
}

open class PermissionGroup(
    val id: String,
    val name: String,
    val visibleForCompany: Boolean = true,
    val requiredFeatures: Set<Feature> = emptySet()
) {
    private var _permissions = mutableSetOf<Permission>()
    val permissions: Set<Permission> = _permissions


    fun permission(id: String, name: String, vararg requiredFeatures: Feature) =
        permission(id, name, true, *requiredFeatures)

    fun permission(
        id: String,
        name: String,
        visibleForCompany: Boolean = true,
        vararg requiredFeatures: Feature
    ): Permission {
        val permission = Permission(
            id = "${this.id}__$id",
            name = name,
            visibleForCompany = visibleForCompany,
            requiredFeatures = requiredFeatures.toSet()
        )
        _permissions.add(permission)
        return permission
    }
}