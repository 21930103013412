package de.geomobile.frontend.features.device.detail


import DeviceLANStatusDTO
import de.geomobile.common.feature.Features
import de.geomobile.common.utils.nonStrictJson
import de.geomobile.frontend.utils.isTrial
import kotlinx.css.Color
import react.RBuilder

fun RBuilder.lanStatus(
    id: Int,
) = deviceInfoComponent(
    id = id,
    subscriptionTopic = "/device/lan",
    emptyPlaceholder = InfoCard(title = "LAN nicht vorhanden", content = InfoEmpty, showCollapse = false),
    mapper = { nonStrictJson.decodeFromString(DeviceLANStatusDTO.serializer(), it).infoItem() }
)

fun DeviceLANStatusDTO.infoItem() = InfoList(
    InfoCard(
        title = "LAN",
        status = timestampStatus,
        trial = isTrial(Features.DeviceFeatures.lan),
        showCollapse = false,
        content = InfoGroup(
//            enabledIcon = "input",
//            iconColor = if (connected) Color.green else Color.gray,
            title = "",
            enabled = true,
            content = this.infoGroupItem()
        )
    )
)

fun DeviceLANStatusDTO.infoGroupItem() = InfoList(
    InfoRow(title = "Verbunden", value = connected.let { if (it) "ja" else "nein" }),
    InfoRow(title = "IP", value = ip),
    InfoRow(title = "Subnetzmaske", value = sm),
    InfoRow(title = "MAC", value = mac),
    InfoRow(title = "Interface", value = netinterface),
    InfoRow(title = "Gateway", value = gateway),
)