package de.geomobile.frontend.features.vehicleProfile

import com.ccfraser.muirwik.components.MGridDirection
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.spacingUnits
import de.geomobile.common.permission.Permissions
import de.geomobile.common.portalmodels.TimestampStatus
import de.geomobile.common.portalmodels.UserDTO
import de.geomobile.common.portalmodels.VehicleProfileAssignmentDTO
import de.geomobile.frontend.features.device.detail.deviceStatusLed
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.spacer
import de.geomobile.frontend.utils.*
import de.geomobile.frontend.utils.grid.*
import kotlinext.js.clone
import kotlinx.browser.localStorage
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.serialization.builtins.ListSerializer
import org.w3c.dom.get
import react.*
import react.dom.tr

fun RBuilder.vehicleProfilesAssignList(
    profileSelected: (profileId: String) -> Unit,
    persistenceId: String = "",
) = child(VehicleProfilesAssignList::class) {
    attrs.profileSelected = profileSelected
    attrs.persistenceId = persistenceId

    key = persistenceId
}

class VehicleProfilesAssignList : CComponent<VehicleProfilesAssignList.Props, VehicleProfilesAssignList.State>() {

    interface Props : RProps {
        var profileSelected: (profileId: String) -> Unit
        var persistenceId: String
    }

    class State(
        var rows: List<Row> = emptyList(),
        var columns: List<Column>,

        var filters: Array<Filter> = emptyArray(),
        var expandedGroups: Array<String> = emptyArray(),
        var grouping: Array<Grouping> = emptyArray(),
        var showFilter: Boolean = false,
    ) : RState

    data class Row(
        val id: String,
        val name: String,
        val description: String,
        val assignedString: String,
        val status: Int,
    )

    init {
        val columns = listOf(
            Column(name = "name", title = "Name"),
            Column(name = "description", title = "Beschreibung"),
            Column(name = "assignedString", title = "Zugewiesene Fahrzeugnummer/n"),
            Column(name = "status", title = "Zuweisung vollständig"),
        )
            .filter { isAuthorized(*it.permissions.toTypedArray()) }
            .filter {
                if (it.feature == null) true
                else hasFeature(it.feature)
            }

        state = State(
            columns = columns,
            // showFilter = localStorage["vehicleProfilesAssignListToggledFilter"].toBoolean()
        )
    }

    override fun componentDidMount() {
        launch {
            val profiles = withContext(Dispatchers.Default) {
                portalRestApi.get("/vehicleprofiles/assign", ListSerializer(VehicleProfileAssignmentDTO.serializer()))
            }

            // get company from local storage (if authorized and not null) otherwise use own users company
            val companyId =
                if (isAuthorized(Permissions.VehicleProfileManagement.notRestrictedToCompany) && localStorage["VehicleProfileCompany"] != null) {
                    localStorage["VehicleProfileCompany"]
                } else {
                    withContext(Dispatchers.Default) {
                        portalRestApi.get("/user", UserDTO.serializer())
                    }.company.id
                }

            // only show profiles of selected company
            val filteredProfiles = profiles.filter { it.company.id == companyId }
                .sortedByDescending { it.description }
                .sortedBy { it.name }

            val filteredRows = filteredProfiles.map {
                Row(
                    id = it.id,
                    name = it.name,
                    description = it.description ?: "-",
                    assignedString = it.assignedString.ifEmpty { "-" },
                    status = if (it.allDevicesFound) TimestampStatus.Status.ONLINE.ordinal else TimestampStatus.Status.OFFLINE.ordinal,
                )
            }

            val localStorageCompanyId = try {
                localStorage["vehicleProfilesAssignListSelectedFilters"]
            } catch (e: NoSuchElementException) {
                null
            }

            val filters =
                if ((localStorageCompanyId != null) and (localStorageCompanyId != "null")) {
                    localStorage["vehicleProfilesAssignListSelectedFilters"]
                } else {
                    localStorage["vehicleProfilesAssignListSelectedFilters"]
                }

            setState {
                this.filters = filters?.let { JSON.parse(it) } ?: emptyArray()
                this.rows = filteredRows
            }
        }
    }

    private val statusFormatter = rFunction<ValueFormatterProps>("StatusFormatter") { props ->
        deviceStatusLed(status = TimestampStatus.Status.values()[props.value as Int])
    }

    private val filterRowWrapper = rFunction<RProps>("FilterRowWrapper") { props ->
        if (state.showFilter) tableRow(props)
        else tr {}
    }

    private val rowWrapper = rFunction<RowProps>("TableRowWrapper") { rowProps ->
        val newProps = clone(rowProps)

        newProps.asDynamic().onClick = { props.profileSelected((rowProps.tableRow.row as Row).id) }
        newProps.asDynamic().style = kotlinext.js.js { cursor = "pointer" }

        tableRow(newProps)
    }

    private val listCellStyle = rFunction<RProps>("TableCell") { cellProps ->
        val newProps = clone(cellProps)

        newProps.asDynamic().style = kotlinext.js.js {
            paddingTop = 1.spacingUnits
            paddingBottom = 1.spacingUnits
        }

        tableCell(newProps)
    }

    override fun RBuilder.render() {
        spacer()
        mGridContainer2(direction = MGridDirection.row) {
            mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                mCard {
                    grid(
                        columns = state.columns,
                        rows = state.rows
                    ) {
                        searchState()
                        sortingState(
                            defaultSorting = Sorting(
                                columnName = "name",
                                direction = "asc"
                            )
                        )
                        filteringState(
                            filters = state.filters,
                            onFiltersChange = {
                                setState { filters = it }
                                // localStorage["vehicleProfilesAssignListSelectedFilters"] = JSON.stringify(it)
                            }
                        )
                        groupingState(
                            grouping = state.grouping,
                            expandedGroups = state.expandedGroups,
                            onGroupingChange = { grp -> setState { grouping = grp } },
                            onExpandedGroupsChange = { setState { expandedGroups = it } }
                        )
                        integratedGrouping()
                        integratedFiltering(
                            columnExtensions = listOf(
                                IntegratedFilteringProps.ColumnExtension(
                                    columnName = "status",
                                    predicate = { value, filter, _ ->
                                        if (filter.operation == "selectStatus") {
                                            (value as Int) == filter.value?.toInt()
                                        } else {
                                            false
                                        }
                                    }
                                ),
                            )
                        )
                        integratedSorting()
                        dataTypeProvider(
                            columns = listOf("status"),
                            formatterComponent = statusFormatter
                        )
                        dragDropProvider()
                        table(
                            cellComponent = listCellStyle,
                            rowComponent = rowWrapper,
                            columnExtensions = listOf(
                                TableProps.ColumnExtension(
                                    columnName = "name",
                                ),
                                TableProps.ColumnExtension(
                                    columnName = "description",
                                ),
                                TableProps.ColumnExtension(
                                    columnName = "assignedString",
                                    width = 400,
                                ),
                                TableProps.ColumnExtension(
                                    columnName = "status",
                                ),
                            )
                        )
                        tableHeaderRow(
                            showSortingControls = true
                        )
                        tableFilterRow(
                            cellComponent = VehicleProfilesListFilterCell::class.js.unsafeCast<RClass<TableFilterRowProps.CellProps>>(),
                            rowComponent = filterRowWrapper
                        )
                        tableGroupRow()
                        gridToolbar()
                        groupingPanel(
                            showGroupingControls = true,
                            showSortingControls = true
                        )
                        searchPanel()
                        toolbarFilterToggle(
                            active = state.showFilter,
                            onToggle = {
                                setState {
                                    showFilter = !showFilter
                                    // localStorage["vehicleProfilesAssignListToggledFilter"] = showFilter.toString()
                                    if (!showFilter)
                                        filters = emptyArray()
                                }
                            },
                        )
                    }
                }
            }
        }
    }
}