package de.geomobile.common.portalmodels

sealed class DeviceIdentifier {
    data class Id(val id: Int) : DeviceIdentifier()
    data class SerialNumber(val product: Product, val serialNumber: Int) : DeviceIdentifier()
    data class CpuId(val product: Product, val cpuId: String) : DeviceIdentifier() {
        val ivantoId = "${product.readableName}-$cpuId"
    }
    data class VehicleId(val vehicleId: String, val companyId: String) : DeviceIdentifier()
}

private val cpuIdRegex = Regex("(ivanto\\w+)-(.+?)(?:\\/|$)")

fun String.parseCpuId() : DeviceIdentifier.CpuId? {
    try {
        val groups = cpuIdRegex.find(this)?.groupValues ?: return null
        return DeviceIdentifier.CpuId(
            product = Product.fromName(groups[1], ignoreCase = false),
            cpuId = groups[2]
        )
    } catch (e: Throwable) {
        println("Error parsing CPU ID: $this")
        return null
    }
}