package de.geomobile.frontend.utils

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.card.MCardHeaderProps
import com.ccfraser.muirwik.components.card.mCardHeader
import com.ccfraser.muirwik.components.form.MFormControlLabelProps
import com.ccfraser.muirwik.components.form.MLabelPlacement
import com.ccfraser.muirwik.components.form.mFormControlLabel
import com.ccfraser.muirwik.components.list.mListItem
import com.ccfraser.muirwik.components.list.mListItemText
import com.ccfraser.muirwik.components.styles.Breakpoint
import com.ccfraser.muirwik.components.table.*
import de.geomobile.common.portalmodels.TimestampStatus
import de.geomobile.common.time.LocalDateTime
import kotlinext.js.jsObject
import kotlinx.css.*
import kotlinx.css.properties.TextDecoration
import org.w3c.dom.events.Event
import react.*
import react.router.dom.LinkComponent
import react.router.dom.LinkProps
import styled.*

fun RBuilder.mToolbarTitle2(text: String): ReactElement {
    return mTypography(
        text,
        variant = MTypographyVariant.h6,
        color = MTypographyColor.inherit,
        noWrap = true
    ) { css { flexGrow = 1.0 } }
}

@JsModule("@material-ui/core/Grid")
private external val gridDefault: dynamic

@Suppress("UnsafeCastFromDynamic")
private val gridComponent: RComponent<MGridProps2, RState> = gridDefault.default

enum class MGridSize2(internal val sizeVal: Any) {
    False(false), Auto("auto"), True(true), Cells1(1), Cells2(2),
    Cells3(3), Cells4(4), Cells5(5), Cells6(6), Cells7(7),
    Cells8(8), Cells9(9), Cells10(10), Cells11(11), Cells12(12);
}

data class MGridBreakpoints2(
    val xs: MGridSize2 = MGridSize2.Auto,
    val sm: MGridSize2 = MGridSize2.Auto,
    val md: MGridSize2 = MGridSize2.Auto,
    val lg: MGridSize2 = MGridSize2.Auto,
    val xl: MGridSize2 = MGridSize2.Auto,
) {
    constructor(defaultGridSize: MGridSize2) : this(
        defaultGridSize,
        defaultGridSize,
        defaultGridSize,
        defaultGridSize,
        defaultGridSize
    )

    fun down(breakpoint: Breakpoint, gridSize: MGridSize2): MGridBreakpoints2 {
        return when (breakpoint) {
            Breakpoint.xs -> copy(xs = gridSize)
            Breakpoint.sm -> copy(xs = gridSize, sm = gridSize)
            Breakpoint.md -> copy(xs = gridSize, sm = gridSize, md = gridSize)
            Breakpoint.lg -> copy(xs = gridSize, sm = gridSize, md = gridSize, lg = gridSize)
            Breakpoint.xl -> copy(xs = gridSize, sm = gridSize, md = gridSize, lg = gridSize, xl = gridSize)
        }
    }

    fun up(breakpoint: Breakpoint, gridSize: MGridSize2): MGridBreakpoints2 {
        return when (breakpoint) {
            Breakpoint.xs -> copy(xl = gridSize, lg = gridSize, md = gridSize, sm = gridSize, xs = gridSize)
            Breakpoint.sm -> copy(xl = gridSize, lg = gridSize, md = gridSize, sm = gridSize)
            Breakpoint.md -> copy(xl = gridSize, lg = gridSize, md = gridSize)
            Breakpoint.lg -> copy(xl = gridSize, lg = gridSize)
            Breakpoint.xl -> copy(xl = gridSize)
        }
    }
}

interface MGridProps2 : StyledProps {
    var alignContent: String
    var alignItems: String
    var direction: String
    var container: Boolean
    var justify: String
    var item: Boolean
    var lg: Any
    var md: Any
    var sm: Any
    var spacing: Int
    var xl: Any
    var xs: Any
    var wrap: String
    var zeroMinWidth: Boolean
}

/**
 * The material design components allows a grid item to be a container and an item. We have simplified things here
 * since different properties apply depending on if it is a container or an item. So, if you want both, you will have
 * to add an extra child item.
 */
fun RBuilder.mGridContainer2(
    spacing: MGridSpacing = MGridSpacing.spacing2,
    alignContent: MGridAlignContent = MGridAlignContent.stretch,
    alignItems: MGridAlignItems = MGridAlignItems.stretch,
    direction: MGridDirection = MGridDirection.row,
    justify: MGridJustify = MGridJustify.flexStart,
    wrap: MGridWrap = MGridWrap.wrap,

    className: String? = null,
    handler: StyledHandler<MGridProps2>? = null,
) = createStyled(gridComponent) {
    attrs.alignContent = alignContent.toString()
    attrs.alignItems = alignItems.toString()
    attrs.direction = direction.toString()
    attrs.justify = justify.toString().toSnakeCase()
    attrs.container = true
    attrs.spacing = spacing.ordinal
    attrs.wrap = wrap.toString()
    setStyledPropsAndRunHandler(className, handler)
}

private fun String.toSnakeCase(): String {
    var text = ""
    var isFirst = true
    this.forEach {
        if (it == it.uppercaseChar()) {
            if (!isFirst) text += "-"
            text += it.lowercaseChar()
        } else {
            text += it
        }
        isFirst = false
    }
    return text
}

fun RBuilder.mGridItem2(
    xs: MGridSize2 = MGridSize2.Auto,
    sm: MGridSize2 = MGridSize2.Auto,
    md: MGridSize2 = MGridSize2.Auto,
    lg: MGridSize2 = MGridSize2.Auto,
    xl: MGridSize2 = MGridSize2.Auto,
    zeroMinWidth: Boolean? = null,

    className: String? = null,
    handler: StyledHandler<MGridProps2>? = null,
) = createStyled(gridComponent) {
    attrs.item = true
    attrs.sm = sm.sizeVal
    attrs.md = md.sizeVal
    attrs.lg = lg.sizeVal
    attrs.xs = xs.sizeVal
    attrs.xl = xl.sizeVal
    zeroMinWidth?.let { attrs.zeroMinWidth = it }

    setStyledPropsAndRunHandler(className, handler)
}

fun RBuilder.mGridItem2(
    breakpoints: MGridBreakpoints2,
    className: String? = null,
    handler: StyledHandler<MGridProps2>? = null,
) =
    mGridItem2(
        breakpoints.xs,
        breakpoints.sm,
        breakpoints.md,
        breakpoints.lg,
        breakpoints.xl,
        null,
        className,
        handler
    )

interface StyledLinkProps : LinkProps, StyledProps

fun RBuilder.styledRouteLink(
    to: String,
    replace: Boolean = false,
    className: String? = null,
    handler: StyledHandler<StyledLinkProps>?,
): ReactElement {
    val builder = StyledElementBuilder<StyledLinkProps>(LinkComponent::class.js).apply {
        attrs {
            this.to = to
            this.replace = replace
            this.className = className
        }
        setStyledPropsAndRunHandler(className, handler)
    }
    return child(builder.create())
}

fun RBuilder.rowMedium(
    label: String,
    handler: RBuilder.() -> Unit,
) = row(label, MGridSize.cells4, MGridSize.cells8, handler)

fun RBuilder.tableRow(
    label: String,
    centerLabelVertically: Boolean = true,
    wrapHandler: Boolean = true,
    handler: (StyledElementBuilder<out StyledProps>.() -> Unit)?
) {
    mTableRow {
        mNoBorderCell {
            mTypography(
                text = label,
                variant = MTypographyVariant.caption,
                color = MTypographyColor.textSecondary
            )
            css {
                verticalAlign = VerticalAlign.top
                width = 150.px
                if (!centerLabelVertically) {
                    verticalAlign = VerticalAlign.top
                    paddingTop = 2.px
                }
            }
        }

        if (wrapHandler) {
            mNoBorderCell {
                handler?.invoke(this)

                css {
                    paddingRight = LinearDimension("0 !important")
                }
            }
        } else {
            handler?.invoke(this)
        }
    }
}

fun RBuilder.row(
    label: String,
    captionSize: MGridSize = MGridSize.cells2,
    contentSize: MGridSize = MGridSize.cells10,
    handler: RBuilder.() -> Unit,
) {
    mGridItem(
        MGridBreakpoints(captionSize)
            .down(Breakpoint.xs, MGridSize.cells12)
    ) {
        mTypography(
            text = label,
            variant = MTypographyVariant.caption
        ) {
            css { marginLeft = 1.spacingUnits }
        }
    }
    mGridItem(
        MGridBreakpoints(contentSize)
            .down(Breakpoint.xs, MGridSize.cells12)
    ) {
        styledDiv {
            css {
                display = Display.inlineFlex
                alignItems = Align.center
                width = 100.pct
            }
            handler()
        }
    }
}

fun RBuilder.rowLarge(label: String, text: String) {
    row(label) {
        mTypography(
            text = text,
            variant = MTypographyVariant.body1
        )
    }
}

fun RBuilder.listItemWrapper(label: String, text: String) {

    mListItem {
        attrs.divider = true
        attrs.button = false
        css {
            padding(0.spacingUnits)
            paddingLeft = 1.spacingUnits
        }

        mListItemText {
            mTypography(
                text = label,
                variant = MTypographyVariant.caption,
            )
            mTypography(
                text = text,
                variant = MTypographyVariant.subtitle2,
                color = MTypographyColor.textPrimary
            )
        }
    }
}

fun RBuilder.listItemLinkWrapper(label: String, toPath: String, text: String?) {
    mListItem {
        attrs.divider = true
        attrs.button = false
        css {
            padding(0.spacingUnits)
            paddingLeft = 1.spacingUnits
        }
        mListItemText {
            mTypography(
                text = label,
                variant = MTypographyVariant.caption,
            )
            styledRouteLink(toPath) {
                css { textDecoration = TextDecoration.none }
                mTypography(
                    text = text ?: "-",
                    variant = MTypographyVariant.subtitle2,
                    color = MTypographyColor.secondary,
                    align = MTypographyAlign.left
                )
            }
        }
    }
}

fun RBuilder.timestampStatus(status: TimestampStatus) {
    when (val timestamp = status.timestamp) {
        null -> mTypography(
            text = "-",
            variant = MTypographyVariant.subtitle2,
            color = MTypographyColor.textPrimary,
        )

        else -> timerWrapper(interval = 1000) {
            mTypography(
                text = timestamp.toText(),
                variant = MTypographyVariant.subtitle2,
            ) {
                css {
                    display = Display.inlineBlock
                    marginRight = 1.spacingUnits
                    color = status.status.textColor
                }
            }

            val diff = (LocalDateTime.now() - timestamp).diffString()
            mTypography(
                text = "(vor $diff)",
                variant = MTypographyVariant.caption,
            ) {
                css {
                    display = Display.inlineBlock
                    color = status.status.textColor
                }
            }
        }
    }
}

fun RBuilder.mCardHeaderExtended(
    title: String,
    titleVariant: MTypographyVariant = MTypographyVariant.h6,
    titleColor: MTypographyColor = MTypographyColor.secondary,
    titleTextTransform: TextTransform? = null,
    subHeader: String? = null,
    avatar: ReactElement? = null,
    action: ReactElement? = null,
    className: String? = null,
    handler: StyledHandler<MCardHeaderProps>? = null,
): ReactElement {
    return mCardHeader(title, subHeader, avatar, action, className) {
        val p: MTypographyProps = jsObject { }
        p.variant = titleVariant
        p.color = titleColor
        attrs.asDynamic().titleTypographyProps = p

        if (titleTextTransform != null) css.textTransform = titleTextTransform

        if (handler != null) handler()
    }
}

fun RBuilder.mNoBorderCell(handler: StyledHandler<MTableCellProps>?) = mTableCell(
    handler = {
        css { border = "0"; padding = "0" }
        handler?.invoke(this)
    })

fun RBuilder.mTableRowSlim(handler: StyledHandler<MTableRowProps>?) = mTableRow(
    handler = {
        css { height = 36.px }
        handler?.invoke(this)
    }
)

fun RBuilder.mTableCellSlim(handler: StyledHandler<MTableCellProps>?) = mTableCell(
    padding = MTableCellPadding.none,
    handler = {
        css {
            height = 48.px
        }
        handler?.invoke(this)
    }
)
@Suppress("EnumEntryName")
enum class MGridJustify {
    flexStart,
    center,
    flexEnd,
    spaceBetween,
    spaceAround;

//    override fun toString(): String {
//        return super.toString().toHyphenCase()
//    }
}

fun RBuilder.mCheckboxWithLabelPadding(
    label: String,
    checked: Boolean = false,
    color: MOptionColor = MOptionColor.secondary,
    disabled: Boolean = false,
    required: Boolean? = null,
    indeterminate: Boolean = false,
    labelPlacement: MLabelPlacement = MLabelPlacement.end,
    onChange: ((event: Event, checked: Boolean) -> Unit)? = null,
    id: String? = null,
    inputProps: RProps? = null,
    value: String? = null,
    paddingCheckbox: String? = null,

    className: String? = null,
    handler: StyledHandler<MFormControlLabelProps>? = null
): ReactElement {

    val checkBox =
        mCheckbox(checked, color, disabled, required, indeterminate, onChange, id, inputProps, value, false) {
            css { paddingCheckbox?.let { padding = it } }
        }

    return mFormControlLabel(
        label,
        checkBox,
        checked,
        disabled,
        value = value,
        labelPlacement = labelPlacement,
        className = className,
        handler = handler
    )
}

@JsModule("@material-ui/lab/Skeleton")
private external val skeletonModule: dynamic

@Suppress("UnsafeCastFromDynamic")
private val skeletonComponentType: RComponent<MSkeletonProps, RState> = skeletonModule.default

interface MSkeletonProps : StyledProps {
    var component: ElementType
    var height: String
    var width: String
}

enum class MSkeletonVariant {
    text,
    rect,
    circle;
}

enum class MSkeletonAnimation {
    pulse,
    wave,
    none;

    override fun toString(): String {
        return when (this) {
            pulse -> "pulse"
            wave -> "wave"
            none -> "false"
        }
    }
}

var MSkeletonProps.variant by EnumPropToString(MSkeletonVariant.values())
var MSkeletonProps.animation by EnumPropToString(MSkeletonAnimation.values())

fun RBuilder.mSkeleton(
    width: LinearDimension? = null,
    height: LinearDimension? = null,
    variant: MSkeletonVariant = MSkeletonVariant.rect,
    animation: MSkeletonAnimation = MSkeletonAnimation.pulse,
    component: ElementType? = null,
    className: String? = null,
    handler: StyledHandler<MSkeletonProps>? = null,
) = createStyled(skeletonComponentType) {
    css { borderRadius = 4.px }
    component?.let { attrs.component = it }
    width?.let { attrs.width = it.toString() }
    height?.let { attrs.height = it.toString() }
    attrs.variant = variant
    attrs.animation = animation
    setStyledPropsAndRunHandler(className, handler)
}