package de.geomobile.frontend.features.monitoring

import com.ccfraser.muirwik.components.*
import de.geomobile.common.permission.Permission
import de.geomobile.common.permission.Permissions
import de.geomobile.common.portalmodels.UserDTO
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.spacer
import de.geomobile.frontend.utils.CComponent
import de.geomobile.frontend.utils.isAuthorized
import de.geomobile.frontend.utils.mToolbarTitle2
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.*
import kotlinx.serialization.builtins.ListSerializer
import portalmodels.MonitoringDTO
import react.*
import react.dom.style
import react.router.dom.redirect
import react.router.dom.route
import react.router.dom.switch
import styled.css
import styled.styledDiv

fun RBuilder.monitoringDashboard(
    path: String,
    drawerMenu: ReactElement
) = child(MonitoringDashboard::class) {
    attrs.path = path
    attrs.drawerMenu = drawerMenu
}

class MonitoringDashboard : CComponent<MonitoringDashboard.Props, MonitoringDashboard.State>() {

    data class Tab(
        val label: String,
        val route: String,
        val viewPermission: Permission? = Permissions.StatisticsManagement.statView,
        val builder: RBuilder.(path: String) -> ReactElement
    )

    interface Props : RProps {
        var path: String
        var drawerMenu: ReactElement
    }

    class State(
        var tabs: MutableList<Tab> = mutableListOf()
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        launch {
            val ownUser = withContext(Dispatchers.Default) {
                portalRestApi.get("/user", UserDTO.serializer())
            }
            val monitoring = ownUser?.let {
                withContext(Dispatchers.Default) {
                    portalRestApi.get(
                        "/monitoring/${it.company.id}/companyId",
                        ListSerializer(MonitoringDTO.serializer())
                    )
                }
            }
            setState {
                monitoring.forEach { m ->
                    tabs.add(
                        Tab(
                            label = "${m.label}",
                            route = "/${m.label.lowercase().replace(" ", "")}"
                        ) {
                            monitoringContent(
                                monitoringDescription = "${m.description}",
                                monitoringPage = "${m.url}"
                            )
                        }
                    )
                }
            }
        }
    }

    override fun RBuilder.render() {
//        val permittedTabs = state.tabs.filter { isAuthorized(*listOfNotNull(it.viewPermission).toTypedArray()) }

        if (state.tabs.isNotEmpty()) {
            route<RProps>(props.path) { routeProps ->
                mAppBar(position = MAppBarPosition.fixed) {
                    css(GlobalStyles.appbar)

                    val currentRoute = routeProps.history.location.pathname
                    val selectedTab = state.tabs.firstOrNull {
                        currentRoute.startsWith(props.path + it.route)
                    } ?: state.tabs.first()

                    mToolbar {
                        child(props.drawerMenu)
                        mToolbarTitle2("Monitoring")
                    }
                    mTabs(
                        value = props.path + selectedTab.route,
                        centered = true,
                        onChange = { _, value -> routeProps.history.push(value as String) }
                    ) {
                        css {
                            height = 100.pct
                        }
                        for (tab in state.tabs) {
                            mTab(tab.label, props.path + tab.route) {
                                css {
                                    textTransform = TextTransform.none
                                }
                            }
                        }
                    }
                }
            }
            spacer()
            styledDiv {
                css {
                    paddingTop = 48.px
                }
                switch {
                    for (tab in state.tabs) {
                        this.route<RProps>(props.path + tab.route) { routeProps ->
                            tab.builder(this, routeProps.match.path)
                        }
                    }
                    redirect(from = props.path, to = props.path + state.tabs.first().route, exact = true)
                }
            }
        }
    }
}