package de.geomobile.frontend.utils

import de.geomobile.common.utils.nonStrictJson
import hardware.HardwareFeature
import kotlinx.browser.localStorage
import kotlinx.browser.sessionStorage
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.json.Json
import org.w3c.dom.get
import org.w3c.dom.set
import react.RBuilder

fun setCompatibleFeatures(features: List<HardwareFeature>) {
    sessionStorage["currentDeviceFeatures"] = Json.encodeToString(ListSerializer(HardwareFeature.serializer()), features)
}

fun unsetCompatibleFeatures() {
    sessionStorage["currentDeviceFeatures"] = ""
}

fun setCompatibleFeaturesRaw(features: String) {
    sessionStorage["currentDeviceFeatures"] = features
}

fun isCompatible(feature: HardwareFeature) =
    sessionStorage["currentDeviceFeatures"]?.let {
        if (it.isNotBlank()) {
            val currentFeatures = nonStrictJson.decodeFromString(ListSerializer(HardwareFeature.serializer()), it)
            feature in currentFeatures
        } else
            false
    } ?: false

fun RBuilder.hardwareCompatible(
    feature: HardwareFeature?,
    handler: RBuilder.() -> Unit
) {
    if (feature == null)
        handler()
    else if (isCompatible(feature))
        handler()
}