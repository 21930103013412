package de.geomobile.server.device.config

import de.geomobile.common.filter.FilterRules
import de.geomobile.common.time.LocalDateTime
import de.geomobile.common.utils.overlay
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonObject

@Serializable
data class DeviceConfigFilter(
    val id: Int,
    val name: String,
    val filterValid: Boolean,
    val filterRules: FilterRules,
    val configOverlay: JsonObject,
    val deactivateConfigs: Boolean,
    val disabled: Boolean
)

@Serializable
data class DeviceConfigFilterHistory(
    val name: String,
    val filterRules: FilterRules,
    val configOverlay: JsonObject,
    val deactivateConfigs: Boolean,
    val comment: String,
    val createdBy: String,
    val createdAt: LocalDateTime
)

fun List<DeviceConfigFilter>.checkForConflict(): List<Pair<Int, Int>> {
    val conflicts = mutableListOf<Pair<Int, Int>>()
    for(i in 0 until size) {
        for(j in i + 1 until size) {
            if(checkForConflict(this[i].configOverlay, this[j].configOverlay))
                conflicts.add(Pair(this[i].id, this[j].id))
        }
    }
    return conflicts
}

fun Map<Int, List<DeviceConfigFilter>>.checkForConflicts(): List<Pair<Int, Int>> {
    val conflicts = mutableListOf<Pair<Int, Int>>()
    for(i in this.keys.indices) {
        for(j in i + 1 until this.keys.size) {
            val key1 = this.keys.elementAt(i)
            val key2 = this.keys.elementAt(j)
            val overlay1 = this[key1]!!.map { it.configOverlay }.overlay()
            val overlay2 = this[key2]!!.map { it.configOverlay }.overlay()
            if(checkForConflict(overlay1, overlay2))
                conflicts.add(key1 to key2)
        }
    }
    return conflicts
}

fun checkForConflict(first: JsonObject, second: JsonObject): Boolean {
    val firstKeys = first.keys
    val secondKeys = second.keys
    for(key in firstKeys) {
        if(secondKeys.contains(key)) {
            val firstValue = first[key]
            val secondValue = second[key]
            if(firstValue is JsonObject && secondValue is JsonObject)
                if (checkForConflict(firstValue, secondValue))
                    return true
            else if(firstValue != secondValue)
                return true
        }
    }
    return false
}