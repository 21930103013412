package de.geomobile.frontend.features.tts

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.*
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.form.MFormControlMargin
import com.ccfraser.muirwik.components.lab.alert.MAlertSeverity
import com.ccfraser.muirwik.components.lab.alert.MAlertVariant
import com.ccfraser.muirwik.components.lab.alert.mAlert
import com.ccfraser.muirwik.components.list.mList
import com.ccfraser.muirwik.components.list.mListItem
import com.ccfraser.muirwik.components.list.mListItemIcon
import com.ccfraser.muirwik.components.list.mListItemText
import com.ccfraser.muirwik.components.menu.mMenuItem
import com.ccfraser.muirwik.components.styles.Breakpoint
import com.ccfraser.muirwik.components.styles.fade
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.currentTheme
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.utils.*
import de.geomobile.frontend.utils.MGridJustify
import kotlinx.browser.document
import kotlinx.browser.localStorage
import kotlinx.browser.window
import kotlinx.coroutines.launch
import kotlinx.css.*
import kotlinx.css.properties.scale
import kotlinx.css.properties.transform
import kotlinx.html.Draggable
import kotlinx.html.draggable
import kotlinx.html.js.onDragOverFunction
import kotlinx.html.js.onDragStartFunction
import kotlinx.html.js.onDropFunction
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.json.Json
import org.w3c.dom.Node
import org.w3c.dom.get
import portalmodels.*
import react.RBuilder
import react.RProps
import react.RState
import react.dom.span
import react.router.dom.route
import react.setState
import styled.css
import styled.styledDiv
import styled.styledImg

fun RBuilder.textToSpeechBuilder() = child(TextToSpeechBuilder::class) {}

const val maxVolume = 150
const val maxDuration = 10000
const val defaultVolume = 100
const val defaultDuration = 1000
val labelNames = mapOf(
    SnippetType.TEXT to "Wort",
    SnippetType.PLACEHOLDER to "IBIS Platzhalter",
    SnippetType.PAUSE to "Pause",
    SnippetType.SOUND to "Ton",
    SnippetType.DOORSIGNAL to "Türsignal",
    SnippetType.WARNSIGNAL to "Warnsignal",
)


data class TextToSpeechElement(
    val label: String,
    val type: SnippetType,
    var volume: Int? = null,
    var duration: Int? = null,
    var value: String? = null,
    val showVolume: Boolean,
    val showDuration: Boolean,
    val disabled: Boolean
)

data class PhraseElement(
    val key: Int,
    val ttsElement: TextToSpeechElement
)

class TextToSpeechBuilder : CComponent<TextToSpeechBuilder.Props, TextToSpeechBuilder.State>() {
    interface Props : RProps

    class State(
        var company: String? = null,
        var draggingIndex: Int? = null,
        var itemMenuAnchor: Node? = null,
        var itemMenuKey: Int? = null,
        var ttsElements: List<TextToSpeechElement>? = null,
        var currentPhrase: List<PhraseElement>? = null,
        var currentTtsPhrasesDTO: TtsPhrasesDTO? = null,
        var soundList: List<TtsSoundFileDTO>? = null,
        var phraseLength: Int = 15000,
        var text: String? = "Hallo",
        var placeholder: String? = "Platzhalter",
//        var volumes: MutableMap<Int, Int> = mutableMapOf(),
//        var durations: MutableMap<Int, Int> = mutableMapOf(),
    ) : RState

    init {
        state = State(
            ttsElements = listOf(
                TextToSpeechElement(
                    label = labelNames[SnippetType.TEXT] ?: "Unbekannt",
                    type = SnippetType.TEXT,
                    showVolume = true,
                    showDuration = false,
                    disabled = false,
                ),
                TextToSpeechElement(
                    label = labelNames[SnippetType.PLACEHOLDER] ?: "Unbekannt",
                    type = SnippetType.PLACEHOLDER,
                    showVolume = true,
                    showDuration = false,
                    disabled = false
                ),
                TextToSpeechElement(
                    label = labelNames[SnippetType.PAUSE] ?: "Unbekannt",
                    type = SnippetType.PAUSE,
                    showVolume = false,
                    showDuration = true,
                    disabled = true,
                ),
                TextToSpeechElement(
                    label = labelNames[SnippetType.SOUND] ?: "Unbekannt",
                    type = SnippetType.SOUND,
                    showVolume = true,
                    showDuration = false,
                    disabled = false,
                ),
                TextToSpeechElement(
                    label = labelNames[SnippetType.DOORSIGNAL] ?: "Unbekannt",
                    type = SnippetType.DOORSIGNAL,
                    showVolume = true,
                    showDuration = true,
                    disabled = true,
                ),
//                TextToSpeechElement(
//                    label = labelNames[SnippetType.WARNSIGNAL] ?: "Unbekannt",
//                    type = SnippetType.WARNSIGNAL,
//                    showVolume = true,
//                    showDuration = true,
//                    disabled = true,
//                )
            ),
            currentPhrase = emptyList()
        )
    }


    val handleTextFieldChange: (Int, String) -> Unit = { index, newValue ->
        setState {
            currentPhrase?.get(index)?.ttsElement?.value = newValue
        }
    }

    val handleSliderChange: (Boolean, Int, Int) -> Unit = { isVolume, index, newValue ->
        setState {
            if (isVolume)
                currentPhrase?.get(index)?.ttsElement?.volume = newValue
            else
                currentPhrase?.get(index)?.ttsElement?.duration = newValue
        }
    }

    fun fetchPhrase(company: String?) {
        launch {
            val phrase = portalRestApi.get("/tts/phrase/${company}", TtsPhrasesDTO.serializer())
            setState {
                currentPhrase = convertFromDTO(phrase)
            }
        }
    }

    fun setPhrase() {
        launch {
            val phrase = convertToDTO(state.currentPhrase ?: emptyList())
            val body = Json.encodeToString(
                TtsPhrasesDTO.serializer(), phrase
            )
            portalRestApi.post("/tts/phrase/${state.company}", body = body, GenericResponseDTO.serializer())
            portalRestApi.put("/tts/phrase/${state.company}/length/${state.phraseLength}")
        }.invokeOnCompletion {
            setState {
                currentPhrase = null
            }
            fetchPhrase(state.company)
        }
    }

    fun convertToDTO(phrase: List<PhraseElement>): TtsPhrasesDTO {
        val snippets = phrase.mapIndexed { index, element ->
            TtsSnippetDTO(
                position = index,
                type = element.ttsElement.type,
                content = element.ttsElement.value,
                volume = element.ttsElement.volume,
                durationMillis = element.ttsElement.duration
            )
        }
        return TtsPhrasesDTO(
            phraseId = state.currentTtsPhrasesDTO?.phraseId ?: -1,
            companyId = state.currentTtsPhrasesDTO?.companyId ?: state.company ?: "",
            snippets = snippets
        )
    }

    fun convertFromDTO(phrase: TtsPhrasesDTO): List<PhraseElement> {
        val elements = phrase.snippets.mapIndexed { index, element ->
            PhraseElement(
                key = index,
                ttsElement = TextToSpeechElement(
                    label = labelNames[element.type] ?: "Unbekannt",
                    type = element.type,
                    volume = element.volume,
                    duration = element.durationMillis,
                    value = element.content,
                    showVolume = element.volume != null,
                    showDuration = element.durationMillis != null,
                    disabled = element.type == SnippetType.PAUSE
                )
            )
        }
        return elements
    }

    fun playWord(index: Int) {
        window.setTimeout({
            println("breather")
        }, 50)
        state.currentPhrase?.get(index)?.ttsElement?.let {
            when (it.type) {
                SnippetType.TEXT, SnippetType.PLACEHOLDER, SnippetType.SOUND -> {
                    document.asDynamic().getElementById("audio-${index}")?.addEventListener("ended") {
                        println("TEXT ENDE")
                        state.currentPhrase?.let {
                            if (index + 1 < it.size) {
                                playWord(index + 1)
                            }

                        }
                    }
                    window.document.asDynamic().getElementById("audio-${index}")?.play()
                }

                SnippetType.PAUSE -> {
                    it.duration?.let {
                        window.setTimeout({
                            state.currentPhrase?.let {
                                println("PAUSE ENDE")
                                if (index + 1 < it.size)
                                    playWord(index + 1)
                            }
                        }, it)
                    }
                }

                SnippetType.DOORSIGNAL, SnippetType.WARNSIGNAL ->
                    document.asDynamic().getElementById("audio-${index}")?.play()
            }
        }
    }

    fun fetchSounds(company: String) {
        launch {
            val sounds = portalRestApi.get("/tts/sounds/${company}", ListSerializer(TtsSoundFileDTO.serializer()))
            setState {
                this.soundList = sounds
            }
        }
    }

    override fun componentDidMount() {
        val company = localStorage["TextToSpeechCompany"] ?: "GEOMOBILE"
        setState {
            this.company = company
        }
        fetchPhrase(company)
        fetchSounds(company)
    }

    override fun RBuilder.render() {
        route<RProps>("/tts/new/builder") { _ ->
            mGridContainer2(direction = MGridDirection.row) {
                mGridItem2(
                    MGridBreakpoints2(MGridSize2.Cells9)
                        .down(Breakpoint.sm, MGridSize2.Cells12)
                ) {
                    mCard {
                        css(GlobalStyles.card)
                        mCardContent {
                            css(GlobalStyles.cardContent)
                            styledDiv {
                                css { padding(2.spacingUnits) }
                                mGridContainer2(
                                    direction = MGridDirection.row,
                                    justify = MGridJustify.center,
                                    alignItems = MGridAlignItems.baseline,
                                    alignContent = MGridAlignContent.center
                                ) {
                                    mGridItem2(
                                        MGridBreakpoints2(MGridSize2.Cells12)
                                    ) {
                                        mTypography(
                                            text = "1. Entwurf",
                                            variant = MTypographyVariant.h6,
                                            align = MTypographyAlign.center,
                                        )
                                        mTypography(
                                            text = "Reihenfolge der Wörter kann per Drag & Drop geändert werden:",
                                            variant = MTypographyVariant.body2,
                                            color = MTypographyColor.inherit,
                                            align = MTypographyAlign.center
                                        )
                                    }

                                    if (state.currentPhrase?.isEmpty() == true) {
                                        mAlert(
                                            message = "Bisher wurde kein Wort im Baukasten erstellt",
                                            variant = MAlertVariant.outlined,
                                            severity = MAlertSeverity.warning
                                        )
                                    }

                                    styledDiv {
                                        css {
                                            if (state.currentPhrase?.isNullOrEmpty() == false) {
                                                border = "1px solid #bbbbbb"
                                                borderRadius = 3.px
                                                width = 100.pct
                                                padding(1.spacingUnits)
                                            }
                                        }

                                        mGridContainer2(
                                            direction = MGridDirection.row,
                                            justify = MGridJustify.center,
                                            alignItems = MGridAlignItems.baseline,
                                            alignContent = MGridAlignContent.center
                                        ) {
                                            state.currentPhrase?.forEachIndexed { index, data ->
                                                mGridItem2(
                                                    MGridBreakpoints2(MGridSize2.Auto)
                                                        .down(Breakpoint.sm, MGridSize2.Cells6)
                                                        .down(Breakpoint.xs, MGridSize2.Cells12)
                                                ) {
                                                    styledDiv {
                                                        css {
                                                            border = "2px dashed #bbbbbb"
                                                            borderRadius = 3.px
                                                            padding(2.px, 8.px)
                                                            backgroundColor = Color.white
                                                            hover { cursor = Cursor.pointer }
                                                        }
                                                        attrs.draggable = Draggable.htmlTrue
                                                        attrs.onDragStartFunction = { _ ->
                                                            handleDragStart(index)
                                                        }
                                                        attrs.onDragOverFunction = { event ->
                                                            event.preventDefault()
                                                        }
                                                        attrs.onDropFunction = { event ->
                                                            event.preventDefault()
                                                            handleDrop(index)
                                                        }
                                                        styledDiv {
                                                            css {
                                                                float = Float.right
                                                                position = Position.relative
                                                            }
                                                            mIconButtonNoTranslate(
                                                                size = MButtonSize.small,
                                                                iconName = "delete_outline",
                                                                onClick = {
                                                                    setState {
                                                                        currentPhrase =
                                                                            currentPhrase?.filterIndexed { idx, _ -> idx != index }
                                                                    }
                                                                }
                                                            ) {
                                                                css {
                                                                    position = Position.absolute
                                                                    left = -6.px
                                                                    zIndex = 1
                                                                    padding(0.px)
                                                                }
                                                                attrs.edge = MIconEdge.start
                                                            }
                                                        }
                                                        styledDiv {
                                                            css {
                                                                width = 170.px
                                                                display = Display.flex
                                                            }
                                                            when (data.ttsElement.type) {
                                                                SnippetType.PLACEHOLDER -> {
                                                                    mTextFieldSelect(
                                                                        value = data.ttsElement.value,
                                                                        label = "${data.ttsElement.label}",
                                                                        disabled = data.ttsElement.disabled,
                                                                        margin = MFormControlMargin.dense,
                                                                        onChange = {
                                                                            handleTextFieldChange(
                                                                                index,
                                                                                it.target?.asDynamic().value.toString()
                                                                            )
                                                                        }
                                                                    ) {
                                                                        css {
                                                                            width = 85.pct
                                                                            hover {
                                                                                cursor = Cursor.pointer
                                                                            }
                                                                        }
                                                                        attrs.autoFocus = false
                                                                        for (element in placeholder) {
                                                                            mMenuItem(
                                                                                primaryText = element.value,
                                                                                value = element.key
                                                                            )
                                                                        }
                                                                    }
                                                                }

                                                                SnippetType.SOUND -> {
                                                                    mTextFieldSelect(
                                                                        value = data.ttsElement.value,
                                                                        label = "${data.ttsElement.label}",
                                                                        disabled = data.ttsElement.disabled,
                                                                        margin = MFormControlMargin.dense,
                                                                        onChange = {
                                                                            handleTextFieldChange(
                                                                                index,
                                                                                it.target?.asDynamic().value.toString()
                                                                            )
                                                                        }
                                                                    ) {
                                                                        css {
                                                                            width = 85.pct
                                                                            hover {
                                                                                cursor = Cursor.pointer
                                                                            }
                                                                        }
                                                                        for (element in state.soundList
                                                                            ?: emptyList()) {
                                                                            mMenuItem(
                                                                                primaryText = element.name,
                                                                                value = element.soundFileId.toString()
                                                                            )
                                                                        }

                                                                        attrs.autoFocus = false
                                                                    }
                                                                }

                                                                else -> {
                                                                    mTextField(
                                                                        label = "${data.ttsElement.label}",
                                                                        value = data.ttsElement.value,
                                                                        disabled = data.ttsElement.disabled,
                                                                        margin = MFormControlMargin.dense,
                                                                        onChange = { event ->
                                                                            handleTextFieldChange(
                                                                                index,
                                                                                event.target?.asDynamic().value.toString()
                                                                            )
                                                                        }
                                                                    ) {
                                                                        css {
                                                                            width = 85.pct
                                                                            hover {
                                                                                cursor = Cursor.pointer
                                                                            }
                                                                        }
                                                                        attrs.autoFocus = false
                                                                    }
                                                                }
                                                            }
                                                            styledDiv {
                                                                css {
                                                                    marginTop = LinearDimension.auto
                                                                    marginBottom = 4.px
                                                                    marginLeft = 12.px
                                                                    width = 27.px
                                                                    display = Display.block
                                                                }
                                                                styledDiv {
                                                                    css {
                                                                        transform {
                                                                            scale(0.5)
                                                                        }
                                                                    }
                                                                    audioPlayer(
                                                                        companyId = state.company ?: "GEOMOBILE",
                                                                        content = when (data.ttsElement.type) {
                                                                            SnippetType.PLACEHOLDER -> placeholderRandom[data.ttsElement.value]?.get(
                                                                                (0..2).random()
                                                                            ) ?: ""

                                                                            else -> data.ttsElement.value ?: ""
                                                                        },
                                                                        classId = "audio-${index}",
                                                                        controls = false,
                                                                        sound = (data.ttsElement.type == SnippetType.SOUND || data.ttsElement.type == SnippetType.DOORSIGNAL)
                                                                    )
                                                                    data.ttsElement.volume?.let {
                                                                        val vol = (it / 100.0) * 0.66
                                                                        document.asDynamic().getElementById("audio-${index}")?.volume = vol
                                                                    }
                                                                }
                                                                // import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
                                                                mIconButtonNoTranslate(
                                                                    size = MButtonSize.small,
                                                                    iconName = "play_circle_outline_icon",
                                                                    onClick = {
                                                                        document.asDynamic()
                                                                            .getElementById("audio-${index}")
                                                                            .play()
                                                                            ?.then { println("PLAYED") }
                                                                            ?.catch { error ->
                                                                                println("ERROR $error")
                                                                            }
                                                                    }
                                                                ) {
                                                                    css {
                                                                        marginTop = 2.px
                                                                        marginRight = 8.px
                                                                        padding(0.px)
                                                                        transform {
                                                                            scale(0.85)
                                                                        }
                                                                    }
                                                                    attrs.edge = MIconEdge.start
                                                                }
                                                                mIconButtonNoTranslate(
                                                                    size = MButtonSize.small,
                                                                    iconName = "pan_tool",
                                                                    onClick = {
                                                                        val anchor =
                                                                            it.currentTarget.asDynamic()
                                                                        setState {
                                                                            itemMenuAnchor = anchor as? Node
                                                                            itemMenuKey = data.key
                                                                        }
                                                                    }
                                                                ) {
                                                                    css {
                                                                        padding(0.px)
                                                                        transform {
                                                                            scale(0.8)
                                                                        }
                                                                    }
                                                                    attrs.edge = MIconEdge.start
                                                                }
                                                            }
                                                        }

                                                        if (data.ttsElement.showVolume)
                                                            slider(isVolume = true, index = index)
                                                        if (data.ttsElement.showDuration)
                                                            slider(isVolume = false, index = index)
                                                    }
                                                }
                                            }

                                        }

                                    }
                                    mGridItem2(
                                        MGridBreakpoints2(MGridSize2.Cells12)
                                    ) {
                                        mTypography(
                                            text = "2. Gesamtlänge",
                                            variant = MTypographyVariant.h6,
                                            align = MTypographyAlign.center,
                                        )
                                        mTypography(
                                            text = "Alle Bausteine werden in jedem Falle gesprochen, übrige Zeit wird mit dem Türfindesignal aufgefüllt (0 = Aus):",
                                            variant = MTypographyVariant.body2,
                                            color = MTypographyColor.inherit,
                                            align = MTypographyAlign.center
                                        )
                                        mSlider(
                                            valueLabelDisplay = MSliderValueLabelDisplay.auto,
                                            valueLabelFormat = { value, _ ->
                                                val formatted = StringBuilder(value.toString()).apply {
                                                    if (value.toInt() >= 1000) {
                                                        deleteRange(length - 2, length)
                                                        insert(length - 1, ".")
                                                    }
                                                }.toString()
                                                "${formatted}s"
                                            },
                                            value = state.phraseLength,
                                            min = 0,
                                            max = 60000,
                                            step = 1000,
                                            onChange = { _, value ->
                                                setState {
                                                    phraseLength = value.toInt()
                                                }
                                            }
                                        ) {
                                            css {
                                                marginLeft = 15.pct
                                                width = 70.pct
                                            }
                                        }
                                    }
                                    mGridItem2(
                                        MGridBreakpoints2(MGridSize2.Cells12)
                                    ) {
                                        mTypography(
                                            text = "3. Satz",
                                            variant = MTypographyVariant.h6,
                                            align = MTypographyAlign.center,
                                        )
                                        mTypography(
                                            text = "So sieht der Satz in Textform aus:",
                                            variant = MTypographyVariant.body2,
                                            color = MTypographyColor.inherit,
                                            align = MTypographyAlign.center
                                        )
                                    }

                                    if (state.currentPhrase.isNullOrEmpty())
                                        mAlert(
                                            message = "Bisher wurde kein Satz gefunden",
                                            variant = MAlertVariant.outlined,
                                            severity = MAlertSeverity.warning
                                        )
                                    else
                                        mTypography(
                                            text = state.currentPhrase!!
                                                .mapNotNull {
                                                    when (it.ttsElement.type) {
                                                        SnippetType.PLACEHOLDER -> {
                                                            "<" + (placeholder[it.ttsElement.value] ?: "Fehler") + ">"
                                                        }

                                                        SnippetType.SOUND -> {
                                                            "<SOUND>"
                                                        }

                                                        else -> it.ttsElement.value
                                                    }

                                                }
                                                .joinToString(" "),
                                            variant = MTypographyVariant.subtitle1,
                                            color = MTypographyColor.inherit,
                                            align = MTypographyAlign.center
                                        )


                                    mGridItem2(
                                        MGridBreakpoints2(MGridSize2.Cells12)
                                    ) {
                                        mTypography(
                                            text = "4. Hörprobe",
                                            variant = MTypographyVariant.h6,
                                            align = MTypographyAlign.center,
                                        )
                                        mTypography(
                                            text = "Der Satz kann abgespielt werden. IBIS Platzhalter werden mit zufälligen Werten ersetzt, Türsignale werden nicht abgespielt:",
                                            variant = MTypographyVariant.body2,
                                            color = MTypographyColor.inherit,
                                            align = MTypographyAlign.center
                                        )
                                    }

                                    mTooltip(
                                        title = "Hörprobe abspielen",
                                        placement = TooltipPlacement.right
                                    ) {
                                        span {
                                            mIconButtonNoTranslate(
                                                iconName = "volume_up",
                                                disabled = state.currentPhrase.isNullOrEmpty(),
                                                iconColor = MIconColor.inherit,
                                                onClick = {
                                                    playWord(0)
                                                },
                                                addAsChild = true
                                            ) {
                                                css {
                                                    color = Color.white
                                                    background = currentTheme.palette.secondary.main
                                                    hover {
                                                        backgroundColor =
                                                            Color(fade(currentTheme.palette.secondary.main, 0.25))
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    mGridItem2(
                                        MGridBreakpoints2(MGridSize2.Cells12)
                                    ) {
                                        mTypography(
                                            text = "5. Veröffentlichen",
                                            variant = MTypographyVariant.h6,
                                            align = MTypographyAlign.center,
                                        )
                                        mTypography(
                                            text = "Den Satz an die Flotte veröffentlichen. Die Änderungen werden sofort übernommen:",
                                            variant = MTypographyVariant.body2,
                                            color = MTypographyColor.inherit,
                                            align = MTypographyAlign.center
                                        )
                                    }
                                    mTooltip(
                                        title = "Veröffentlichen",
                                        placement = TooltipPlacement.right
                                    ) {
                                        span {
                                            mButton(
                                                "Veröffentlichen",
                                                disabled = state.currentPhrase.isNullOrEmpty(),
                                                color = MColor.secondary,
                                                variant = MButtonVariant.contained,
                                                onClick = {
                                                    setPhrase()
                                                }
                                            ) {
                                                attrs.disableElevation = true
                                            }
                                        }
                                    }

                                }
                            }

                        }
                    }
                }
                mGridItem2(
                    MGridBreakpoints2(MGridSize2.Cells3)
                        .down(Breakpoint.sm, MGridSize2.Cells12)
                ) {
                    mCard {
                        css(GlobalStyles.card)
                        mCardContent {
                            css(GlobalStyles.cardListItemContent)
                            styledDiv {
                                mList {
                                    state.ttsElements?.forEachIndexed { index, element ->
                                        mListItem(
                                            divider = false,
                                            button = true,
                                            dense = true,
                                            onClick = {
                                                val buildElement = TextToSpeechElement(
                                                    label = labelNames[element.type] ?: "Unbekannt",
                                                    value = when (element.type) {
                                                        SnippetType.PLACEHOLDER -> placeholderRandom.keys.first()
                                                        SnippetType.SOUND -> state.soundList?.firstOrNull()?.soundFileId.toString()
                                                        else -> element.type.readableName
                                                    },
                                                    type = element.type,
                                                    showVolume = element.showVolume,
                                                    showDuration = element.showDuration,
                                                    volume = defaultVolume,
                                                    duration = defaultDuration,
                                                    disabled = element.disabled,
                                                )

                                                addWord(buildElement)
                                            }
                                        ) {
                                            mListItemIcon(
                                                iconName = "add"
                                            )
                                            mListItemText(
                                                primary = element.type.readableName
                                            )
                                        }
                                    }

                                }
                            }
                        }
                    }
                    mCard {
                        css(GlobalStyles.card)
                        css {
                            marginTop = 2.spacingUnits
                        }
                        mCardContent {
                            styledImg(alt = "TTS Tutorial", src = "/portal/static/tts_tutorial.jpg") {
                                css {
                                    display = Display.block
                                    width = 100.pct
                                    margin(horizontal = LinearDimension.auto)
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    private fun RBuilder.slider(isVolume: Boolean, index: Int) =
        styledDiv {
            val ttsElement = state.currentPhrase?.get(index)?.ttsElement
            mIconButtonNoTranslate(
                size = MButtonSize.small,
                iconName = if (isVolume) "volume_up" else "alarm",
                disabled = true
            ) {
                css {
                    verticalAlign = VerticalAlign.`super`
                    marginRight = 0.px
                    marginTop = 2.px
                    padding(0.px)
                }
            }
            mSlider(
                valueLabelDisplay = MSliderValueLabelDisplay.auto,
                valueLabelFormat = { value, _ ->
                    if (isVolume)
                        "${value.toInt()}%"
                    else {
                        val txtValue =
                            if (value.toInt() < 1000)
                                "0$value"
                            else
                                value.toString()
                        val formatted = txtValue.take(1) + "." + txtValue.substring(1, 2)
                        "${formatted}s"
                    }
                },
                value = if (isVolume) ttsElement?.volume else ttsElement?.duration,
                min = if (isVolume) 1 else 100,
                max = if (isVolume) maxVolume else maxDuration,
                step = if (isVolume) 1 else 100,
                onChange = { _, value ->
                    handleSliderChange(
                        isVolume,
                        index,
                        value.toInt()
                    )
                }
            ) {
                css {
                    float = Float.right
                    marginRight = 5.px
                    paddingBottom = 4.px
                    width = 85.pct
                    transform {
                        scale(0.8)
                    }
                }
            }
        }

    private fun addWord(element: TextToSpeechElement) {
        val nextKey = (state.currentPhrase?.lastOrNull()?.key ?: -1) + 1
        val newElement = PhraseElement(nextKey, element)
        setState { currentPhrase = currentPhrase?.plus(newElement) }
    }

    private fun handleDragStart(index: Int) {
        setState { draggingIndex = index }
    }

    private fun handleDrop(droppedIndex: Int) {
        val fromIndex = state.draggingIndex

        if (fromIndex == null || fromIndex == droppedIndex) {
            return
        }

        val currentSentence = state.currentPhrase?.toMutableList()

        if (currentSentence != null) {
            val draggedItem = currentSentence[fromIndex]
            currentSentence.removeAt(fromIndex)
            currentSentence.add(droppedIndex, draggedItem)
        }

        setState {
            this.currentPhrase = currentSentence
            draggingIndex = null
        }
    }

}
