package de.geomobile.frontend.features.device.detail

import de.geomobile.common.feature.Features
import de.geomobile.common.portalmodels.DeviceCellularModuleDTO
import de.geomobile.common.portalmodels.DeviceCellularStatusDTO
import de.geomobile.common.utils.nonStrictJson
import de.geomobile.frontend.utils.isTrial
import kotlinx.css.Color
import react.RBuilder

fun RBuilder.cellularStatus(
    id: Int,
) = deviceInfoComponent(
    id = id,
    subscriptionTopic = "/device/cellular",
    emptyPlaceholder = InfoCard(title = "Mobilfunk nicht vorhanden", content = InfoEmpty, showCollapse = false),
    mapper = { nonStrictJson.decodeFromString(DeviceCellularStatusDTO.serializer(), it).infoItem() }
)

fun DeviceCellularStatusDTO.infoItem() = InfoList(
    modules.orEmpty().mapIndexed { index, dto ->
        InfoCard(
            title = "Mobilfunk ${index + 1}",
            index = index,
            status = status,
            trial = isTrial(Features.DeviceFeatures.cellular),
            showCollapse = false,
            content = InfoGroup(
                enabledIcon = "signal_cellular_4_bar",
                disabledIcon = "signal_cellular_off",
                //icon color depending on rssi https://www.lte-anbieter.info/technik/asu.php
                iconColor = when (dto.rssi?.removeSuffix(" dBm")?.toIntOrNull()) {
                    null -> Color.grey
                    in -49..0 -> Color.green
                    in -72..-50 -> Color.yellowGreen
                    in -79..-73 -> Color.gold
                    in -89..-80 -> Color.orange
                    in -104..-90 -> Color.darkOrange
                    in -110..-105 -> Color.red
                    in -117..-111 -> Color.darkRed
                    else -> Color.grey
                },
                title = "Mobilfunk ${index + 1}",
                enabled = dto.enabled == true,
                content = dto.infoItem()
            )
        )
    }
)

fun DeviceCellularModuleDTO.infoItem() = InfoList(
    InfoRow(title = "ICCID", value = iccid),
    InfoRow(title = "Aktiviert", value = enabled?.let { if (it) "ja" else "nein" }),
    InfoRow(title = "Interface", value = interfaceName),
    InfoRow(title = "Verfügbar", value = available?.let { if (it) "ja" else "nein" }),
    InfoRow(title = "Provider", value = providerName),
    InfoRow(title = "RSSI", value = rssi),
//        InfoRow(title = "RSSI", value = try {
//            RssiValue.parse(rssi.orEmpty())
//        } catch (e: Throwable) {
////            rssi
//            "error"
//        }),
    InfoRow(title = "Typ", value = type?.uppercase()),
    InfoRow(title = "PIN", value = if (pinDisabled == "1") "Deaktiviert" else "Vorhanden"),
    InfoRow(title = "APN", value = apn),
    InfoRow(title = "IP-Typ", value = ipType),
    InfoRow(title = "SIM-Status ", value = simStatus)
)