package de.geomobile.common.portalmodels

import de.geomobile.common.stage.Stage
import de.geomobile.frontend.utils.TableDisplayable
import de.geomobile.frontend.utils.TableDisplayableContent
import kotlinx.serialization.Serializable

@Serializable
data class DevicePositionDTO(
    val id: Int,
    val product: Product,
    val serialNumber: Int? = null,
    val cpuId: String? = null,
    val vehicleId: String? = null,
    val vehicleType: VehicleType,
    val company: CompanySmall,
    val stage: Stage,
    val position: Position
) : TableDisplayable {
    override var enabled: Boolean? = null

    override fun asList(): List<TableDisplayableContent> {
        return listOf(
            TableDisplayableContent(key = "Latitude", value = position.location?.latitude),
            TableDisplayableContent(key = "Longitude", value = position.location?.longitude),
            TableDisplayableContent(key = "Satelliten", value = position.location?.satellites),
            TableDisplayableContent(key = "Fix", value = position.fix),
            TableDisplayableContent(key = "Letzte Position", value = position.lastLocation),
            TableDisplayableContent(key = "Letzte Aktualisierung", value = position.lastSeen)
        )
    }

    fun identifierById(): DeviceIdentifier.Id = DeviceIdentifier.Id(id = id)

    fun identifierBySerialNumber(): DeviceIdentifier.SerialNumber? = serialNumber?.let {
        DeviceIdentifier.SerialNumber(
            product = product,
            serialNumber = serialNumber
        )
    }

    fun identifierByCpuId(): DeviceIdentifier.CpuId? = cpuId?.let {
        DeviceIdentifier.CpuId(
            product = product,
            cpuId = cpuId
        )
    }

    fun primaryIdentifier(): DeviceIdentifier =
        identifierBySerialNumber()
            ?: identifierByCpuId()
            ?: identifierById()
}
