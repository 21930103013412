package de.geomobile.frontend.features.admin.users

import com.ccfraser.muirwik.components.MColor
import com.ccfraser.muirwik.components.MGridDirection
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.card.mCard
import de.geomobile.common.permission.Permissions
import de.geomobile.common.portalmodels.UserDTO
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.utils.*
import de.geomobile.frontend.utils.grid.*
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.serialization.builtins.ListSerializer
import react.*
import react.dom.tr

fun RBuilder.adminUserList(
    userSelected: (userId: String) -> Unit,
    createUser: () -> Unit,
) = child(AdminUserList::class) {
    attrs.userSelected = userSelected
    attrs.createUser = createUser
}

class AdminUserList : CComponent<AdminUserList.Props, AdminUserList.State>() {

    interface Props : RProps {
        var userSelected: (userId: String) -> Unit
        var createUser: () -> Unit
    }

    class State(
        var rows: List<Row> = emptyList(),
        var columns: List<Column> = listOf(
            Column(name = "email", title = "E-Mail"),
            Column(name = "firstName", title = "Vorname"),
            Column(name = "lastName", title = "Nachname"),
            Column(name = "company", title = "Unternehmen"),
            Column(name = "role", title = "Rolle"),
            Column(name = "state", title = "Status")
        ),
        var filters: Array<Filter> = emptyArray(),
        var expandedGroups: Array<String> = emptyArray(),
        var grouping: Array<Grouping> = emptyArray(),
        var showFilter: Boolean = false,
    ) : RState

    init {
        state = State(
            // showFilter = localStorage["adminUserListToggledFilter"].toBoolean()
        )
    }

    data class Row(
        val id: String,
        val email: String,
        val firstName: String,
        val lastName: String,
        val company: String,
        val role: String,
        val state: String,
    )

    override fun componentDidMount() {
        launch {
            val users = withContext(Dispatchers.Default) {
                portalRestApi.get("/admin/users", ListSerializer(UserDTO.serializer()))
            }

//            val localStorageCompanyId = try {
//                localStorage["adminUserListSelectedFilters"]
//            } catch (e: NoSuchElementException) {
//                null
//            }
//
//            val filters =
//                if ((localStorageCompanyId != null) and (localStorageCompanyId != "null")) {
//                    localStorage["adminUserListSelectedFilters"]
//                } else {
//                    localStorage["adminUserListSelectedFilters"]
//                }

            setState {
                // this.filters = filters?.let { JSON.parse(it) } ?: emptyArray()
                this.rows = users.map {
                    Row(
                        id = it.id,
                        email = it.email,
                        firstName = it.firstName ?: "-",
                        lastName = it.lastName ?: "-",
                        company = it.company.name,
                        role = it.role.name,
                        state = it.state.toText(true)
                    )
                }

                grouping = arrayOf(Grouping("company"))
                expandedGroups = users.map { it.company.name }.distinct().toTypedArray()
            }
        }
    }

    private val filterRowWrapper = rFunction<RProps>("FilterRowWrapper") { props ->
        if (state.showFilter) tableRow(props)
        else tr {}
    }

    private val rowWrapper = rFunction<RowProps>("TableRowWrapper") { rowProps ->
        val newProps = kotlinext.js.clone(rowProps)

        newProps.asDynamic().onClick = { props.userSelected((rowProps.tableRow.row as Row).id) }
        newProps.asDynamic().style = kotlinext.js.js { cursor = "pointer" }

        tableRow(newProps)
    }

    override fun RBuilder.render() {
        mGridContainer2(direction = MGridDirection.row) {
            authorize(Permissions.UserManagement.usersEdit) {
                mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                    mButton(
                        caption = "Benutzer erstellen",
                        variant = MButtonVariant.contained,
                        color = MColor.secondary,
                        onClick = { props.createUser() }
                    ) {
                        attrs.disableElevation = true
                    }
                }
            }
            mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                mCard {
                    grid(
                        columns = state.columns,
                        rows = state.rows
                    ) {
                        searchState()
                        sortingState(
                            defaultSorting = Sorting(columnName = "email", direction = "asc")
                        )
                        filteringState(
                            filters = state.filters,
                            onFiltersChange = {
                                setState { filters = it }
                                // localStorage["adminUserListSelectedFilters"] = JSON.stringify(it)
                            }
                        )
                        groupingState(
                            grouping = state.grouping,
                            expandedGroups = state.expandedGroups,
                            onGroupingChange = { setState { grouping = it } },
                            onExpandedGroupsChange = { setState { expandedGroups = it } }
                        )
                        integratedGrouping()
                        integratedFiltering()
                        integratedSorting()
                        dragDropProvider()
                        table(
                            rowComponent = rowWrapper,
                            columnExtensions = listOf(
                                TableProps.ColumnExtension(
                                    columnName = "email",
                                    width = 240
                                ),
                                TableProps.ColumnExtension(
                                    columnName = "state",
                                    width = 120
                                )
                            )
                        )
                        tableHeaderRow(
                            showSortingControls = true
                        )
                        tableFilterRow(
                            cellComponent = AdminUserListFilterCell::class.js.unsafeCast<RClass<TableFilterRowProps.CellProps>>(),
                            rowComponent = filterRowWrapper
                        )
                        tableGroupRow()
                        gridToolbar()
                        groupingPanel(
                            showGroupingControls = true,
                            showSortingControls = true
                        )
                        searchPanel()
                        toolbarFilterToggle(
                            active = state.showFilter,
                            onToggle = {
                                setState {
                                    showFilter = !showFilter
                                    // localStorage["adminUserListToggledFilter"] = showFilter.toString()
                                }
                            },
                        )
                    }
                }
            }
        }
    }
}