package de.geomobile.frontend.features.softwareManagement.bundle

import com.ccfraser.muirwik.components.MGridDirection
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.list.mList
import com.ccfraser.muirwik.components.list.mListItem
import com.ccfraser.muirwik.components.list.mListSubheader
import com.ccfraser.muirwik.components.mDivider
import com.ccfraser.muirwik.components.styles.Breakpoint
import components.emptyView
import de.geomobile.common.portalmodels.Company
import de.geomobile.common.portalmodels.Product
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.spacer
import de.geomobile.frontend.utils.*
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.serialization.builtins.ListSerializer
import react.RBuilder
import react.RProps
import react.RState
import react.router.dom.route
import react.router.dom.switch
import react.setState
import styled.css

fun RBuilder.bundleManagement(
    selectedCompanyId: String?,
    changeSelection: (selectedCompanyId: String) -> Unit,
    product: Product
) = child(BundleManagement::class) {
    attrs.selectedCompanyId = selectedCompanyId
    attrs.changeSelection = changeSelection
    attrs.product = product
}

interface SelectedCompanyProps : RProps {
    var selectedCompanyId: String?
}

class BundleManagement : CComponent<BundleManagement.Props, BundleManagement.State>() {

    private var loadAssignmentStatesJob: Job = Job()

    interface Props : RProps {
        var selectedCompanyId: String?
        var changeSelection: (selectedCompanyId: String) -> Unit
        var product: Product
    }

    class State(
        var companies: List<Company> = emptyList(),
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        loadCompanies()
    }

    private fun loadCompanies() {
        loadAssignmentStatesJob.cancel()
        loadAssignmentStatesJob = launch {
            val companies = withContext(Dispatchers.Default) {
                portalRestApi.get(
                    path = "/admin/companies",
                    serializer = ListSerializer(Company.serializer())
                )
            }

            setState {
                this.companies = companies
            }
        }
    }

    override fun RBuilder.render() {
        val selected = props.selectedCompanyId?.let { id ->
            state.companies.singleOrNull { it.id == id }
        }

        spacer()
        mGridContainer2(direction = MGridDirection.row) {
            mGridItem2(
                MGridBreakpoints2(MGridSize2.Cells3)
                    .down(Breakpoint.md, MGridSize2.Cells12)
            ) {
                companyList()
            }
            mGridItem2(
                MGridBreakpoints2(MGridSize2.Cells9)
                    .down(Breakpoint.md, MGridSize2.Cells12)
            ) {
                if (selected != null) {
                    switch {
                        route<SelectedBundleVersionProps>(
                            "/software/${props.product.readableName}/bundles/${selected.id}/:selectedVersion?"
                        ) { routeProps ->
                            bundleList(
                                company = selected,
                                selectedBundleVersion = routeProps.match.params.selectedVersion,
                                product = props.product,
                                changeSelection = { selectedVersion, addingNew ->
                                    var path = "/software/${props.product.readableName}/bundles/${selected.id}"
                                    if (selectedVersion != null)
                                        path += "/$selectedVersion"
                                    else if (addingNew)
                                        path += "/new"
                                    if (path != routeProps.location.pathname)
                                        routeProps.history.push(path)
                                }
                            )
                        }
                    }
                } else {
                    emptyView(
                        title = "Unternehmen",
                        caption = "Es wurde kein Unternehmen ausgewählt",
                        addButton = false
                    )
                }
            }
        }
    }

    private fun RBuilder.companyList() {
        mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
            mCard {
                css(GlobalStyles.card)
                mCardContent {
                    css(GlobalStyles.cardListContent)

                    if (state.companies.isEmpty()) {
                        emptyView(
                            iconName = "menu",
                            title = "Unternehmen",
                            caption = "Es wurden keine Unternehmen gefunden",
                            addButton = false
                        )
                    } else {
                        mCard {
                            css(GlobalStyles.card)
                            mCardContent {
                                css(GlobalStyles.cardListContent)
                                mListSubheader(heading = "Unternehmen")
                                mDivider { }
                                mList {
                                    for (company in state.companies) {
                                        mListItem(
                                            primaryText = company.name,
                                            divider = false,
                                            selected = company.id == props.selectedCompanyId,
                                            onClick = { props.changeSelection(company.id) }
                                        ) {
                                            attrs.dense = true
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}