package de.geomobile.frontend.features.statistics.accesspoint

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.form.MFormControlMargin
import com.ccfraser.muirwik.components.form.MFormControlVariant
import com.ccfraser.muirwik.components.form.mFormControlLabel
import com.ccfraser.muirwik.components.list.mListSubheader
import com.ccfraser.muirwik.components.menu.mMenuItem
import com.ccfraser.muirwik.components.styles.Breakpoint
import de.geomobile.common.permission.Permissions
import de.geomobile.common.portalmodels.Company
import de.geomobile.common.portalmodels.UserDTO
import de.geomobile.common.portalmodels.VehicleProfileDTO
import de.geomobile.common.portalmodels.small
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.features.map.googleMap
import de.geomobile.frontend.features.map.googleMapCoords
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.spacer
import de.geomobile.frontend.utils.*
import kotlinext.js.assign
import kotlinext.js.jsObject
import kotlinx.browser.localStorage
import kotlinx.coroutines.*
import kotlinx.css.*
import kotlinx.serialization.builtins.ListSerializer
import org.w3c.dom.Element
import org.w3c.dom.get
import org.w3c.dom.set
import react.*
import styled.css
import styled.styledDiv
import utils.maps.googlemaps.LatLng
import utils.maps.googlemaps.Map
import utils.maps.googlemaps.MapOptions
import utils.maps.googlemaps.Polygon
import kotlin.js.Promise
import kotlin.math.PI
import kotlin.math.cos
import kotlin.math.sin

fun RBuilder.statisticsAccessPointLTE() = child(StatisticsAccessPointLTE::class) {}

class StatisticsAccessPointLTE : CComponent<StatisticsAccessPointLTE.Props, StatisticsAccessPointLTE.State>() {

    private val LOCATION_ZOOM = 16
    private val DEFAULT_ZOOM = 6
    private val SCALING_MAX = 100
    private val SCALING_MIN = 0
    private val EXCELLENT_SIGNAL_ABS = 46..100
    private val GOOD_SIGNAL_ABS = 36..45
    private val WEAK_SIGNAL_ABS = 26..35
    private val BAD_SIGNAL_ABS = 16..25
    private val NO_SIGNAL_ABS = -20..15
    private val EXCELLENT_SIGNAL_RLT = 81..100
    private val GOOD_SIGNAL_RLT = 61..80
    private val WEAK_SIGNAL_RLT = 41..60
    private val BAD_SIGNAL_RLT = 21..40
    private val NO_SIGNAL_RLT = 0..20

    private var fetchLinesJob: Job = Job()
    private var fetchHeatPointsJob: Job = Job()
    private var loadCompaniesJob: Job = Job()

    private var scalingBase: Pair<Double, Double> = Pair(0.0, 100.0)
    private var choropleth: List<Polygon?> = listOf()
    private var relativeLabel: List<String> = listOf("Alle", "Bis 80%", "Bis 60%", "Bis 40%", "Bis 20%")
    private var absoluteLabel: List<String> = listOf("Alle", "Bis -55dBm", "Bis -65dBm ", "Bis -75dBm", "Bis -85dBm")

    data class HeatPoint(
        val line: String?,
        val location: LatLng,
        val signal: Number,
    )

    interface Props : RProps {
        var path: String
        var drawerMenu: ReactElement
    }

    class State(
        var myCompany: String? = null,
        var companies: List<Company>? = null,
        var vehicleProfiles: List<VehicleProfileDTO>? = null,
        var lines: List<String> = listOf(),
        var heatPoints: List<HeatPoint> = listOf(),
        var selectedLine: String? = "all",
        var relativeScaling: Boolean = false,
        var myMap: Map<Element>? = null,
        var signalFilter: Int = 0,
        var loadingSignalData: Boolean = true,
        var loadingLineData: Boolean = true,
    ) : RState

    init {
        state = State()
    }

    fun scale(valueIn: Double): Double {
        return (SCALING_MAX - SCALING_MIN) * (valueIn - scalingBase.first) / (scalingBase.second - scalingBase.first) + SCALING_MIN
    }

    /**
     * We cannot draw individual Circle objects because then the opacity of those overlaps,
     * hence we have to draw them in a Polygon object. Since the Polygon object does not
     * accept Circle Objects we have to draw circles ourselves. ^TH
     */
    fun drawShape(point: LatLng): Array<LatLng> {
        //return drawCircle(point)
        return drawRect(point)
    }

    // TODO: Clean up, remove?
    fun drawCircle(point: LatLng): Array<LatLng> {
        val radius = 100
        val d2r = PI / 180   // degrees to radians
        val r2d = 180 / PI   // radians to degrees
        val earthsradius = 6_371_000 // the radius of the earth in meters
        val points = 32

        // find the raidus in lat/lon
        val rlat = (radius.toDouble() / earthsradius.toDouble()) * r2d
        val rlng = rlat.toDouble() / cos(point.lat().toDouble() * d2r)

        return (0..points).map {
            val theta = PI * (it.toDouble() / (points.toDouble() / 2.0))
            val ey = point.lng().toDouble() + (rlng * cos(theta)) // center a + radius x * cos(theta)
            val ex = point.lat().toDouble() + (rlat * sin(theta)) // center b + radius y * sin(theta)
            LatLng(ex, ey)
        }.toTypedArray()
    }

    fun drawRect(point: LatLng): Array<LatLng> {
        val tl = LatLng(point.lat().toDouble() - 0.0005, point.lng().toDouble() - 0.0005)
        val tr = LatLng(point.lat().toDouble() - 0.0005, point.lng().toDouble() + 0.0005)
        val bl = LatLng(point.lat().toDouble() + 0.0005, point.lng().toDouble() + 0.0005)
        val br = LatLng(point.lat().toDouble() + 0.0005, point.lng().toDouble() - 0.0005)
        return arrayOf(tl, tr, bl, br)
    }

    override fun componentDidMount() {
        fetchCompanies()
    }

    private fun fetchCompanies() {
        loadCompaniesJob.cancel()
        loadCompaniesJob = launch {
            val deferredCompanies = if (isAuthorized(Permissions.CompanyProfileManagement.notRestrictedToCompany))
                async(Dispatchers.Default) {
                    portalRestApi.get("/admin/companies", ListSerializer(Company.serializer()))
                }
            else {
                null
            }

            val ownUser =
                async(Dispatchers.Default) {
                    portalRestApi.get("/user", UserDTO.serializer())
                }.await()

            val companies = deferredCompanies?.await()

            val localStorageCompanyId = try {
                localStorage["StatisticsCompany"]
            } catch (e: NoSuchElementException) {
                localStorage["StatisticsCompany"] = ownUser.company.id
                null
            }

            val company =
                if (localStorageCompanyId != null) {
                    companies?.first { it.id == localStorageCompanyId }?.small ?: ownUser.company.small
                } else {
                    localStorage["StatisticsCompany"] = ownUser.company.id
                    ownUser.company.small
                }

            setState {
                company?.id?.let {
                    this.myCompany = it
                }
                companies?.let {
                    this.companies = companies
                }
            }
            fetchLines()
            fetchHeatPoints()
        }
    }

    private fun fetchLines() {
        fetchLinesJob.cancel()
        fetchLinesJob = launch {
            val lines = withContext(Dispatchers.Default) {
                portalRestApi.getRaw(
                    path = "/statistics/accesspoint/${state.myCompany}/heatmap/lines"
                )
                    .ifEmpty { null }
                    ?.let { l ->
                        l.lines()
                            .sorted()
                    } ?: listOf()
            }
            setState {
                this.loadingLineData = false
                this.lines = lines
            }
        }
    }

    private fun fetchHeatPoints(line: String? = "all") {
        fetchHeatPointsJob.cancel()
        fetchHeatPointsJob = launch {
            val heatPoints = withContext(Dispatchers.Default) {
                portalRestApi.getRaw(
                    path = "/statistics/accesspoint/${state.myCompany}/heatmap/line/${line}"
                )
                    .ifEmpty { null }
                    ?.let { l ->
                        l.lines()
                            .map {
                                val split = it.split(',')
                                val lat = split[0].toDouble()
                                val lot = split[1].toDouble()
                                val rssi = split[2].toInt()
                                HeatPoint(line, LatLng(lat, lot), rssi)
                            }
                    } ?: listOf()
            }

            /**
             * Since setState does not ensure that the state is immediately updated it can happen that following
             * uses of changed state variables still have the old state. This way the follow-up function is called
             * as a callback once setState finishes ensuring the state used in the function has updated values. ^TH
             */
            setState({
                assign(it) {
                    this.heatPoints = heatPoints
                    this.loadingSignalData = false
                }
            }) { changePointDistribution() }
        }
    }

    private fun changePointDistribution() {

        // Since we create a new immutable List we have to clear the Map objects references to old geometry. ^TH
        choropleth.forEach { it?.let { it.setMap(null) } }

        val hp = state.heatPoints.filter { state.selectedLine == "all" || (it.line == state.selectedLine) }

        val maxScale = hp.maxByOrNull { it.signal.toInt() }?.let { it.signal.toDouble() }
        val minScale = hp.minByOrNull { it.signal.toInt() }?.let { it.signal.toDouble() }

        maxScale?.let { max ->
            minScale?.let { min ->
                scalingBase = Pair(min, max)
            }
        }

        val excellentSignal = hp.filter {
            if (state.relativeScaling)
                scale(it.signal.toDouble()).toInt() in EXCELLENT_SIGNAL_RLT
            else
                it.signal.toInt() in EXCELLENT_SIGNAL_ABS
        }
        val goodSignal = hp.filter {
            if (state.relativeScaling)
                scale(it.signal.toDouble()).toInt() in GOOD_SIGNAL_RLT
            else
                it.signal.toInt() in GOOD_SIGNAL_ABS
        }
        val weakSignal = hp.filter {
            if (state.relativeScaling)
                scale(it.signal.toDouble()).toInt() in WEAK_SIGNAL_RLT
            else
                it.signal.toInt() in WEAK_SIGNAL_ABS
        }
        val badSignal = hp.filter {
            if (state.relativeScaling)
                scale(it.signal.toDouble()).toInt() in BAD_SIGNAL_RLT
            else
                it.signal.toInt() in BAD_SIGNAL_ABS
        }
        val noSignal = hp.filter {
            if (state.relativeScaling)
                scale(it.signal.toDouble()).toInt() in NO_SIGNAL_RLT
            else
                it.signal.toInt() in NO_SIGNAL_ABS
        }

        val opacity = 0.7
        choropleth = listOf(
            if (state.signalFilter < 1) {
                Polygon(jsObject {
                    paths = excellentSignal.map {
                        drawShape(it.location)
                    }.toTypedArray()
                    strokeWeight = 0
                    fillColor = "green"
                    fillOpacity = opacity
                })
            } else null,
            if (state.signalFilter < 2) {
                Polygon(jsObject {
                    paths = goodSignal.map {
                        drawShape(it.location)
                    }.toTypedArray()
                    strokeWeight = 0
                    fillColor = "lawngreen"
                    fillOpacity = opacity
                })
            } else null,
            if (state.signalFilter < 3) {
                Polygon(jsObject {
                    paths = weakSignal.map {
                        drawShape(it.location)
                    }.toTypedArray()
                    strokeWeight = 0
                    fillColor = "yellow"
                    fillOpacity = opacity
                })
            } else null,
            if (state.signalFilter < 4) {
                Polygon(jsObject {
                    paths = badSignal.map {
                        drawShape(it.location)
                    }.toTypedArray()
                    strokeWeight = 0
                    fillColor = "orange"
                    fillOpacity = opacity
                })
            } else null,
            if (state.signalFilter < 5) {
                Polygon(jsObject {
                    paths = noSignal.map {
                        drawShape(it.location)
                    }.toTypedArray()
                    strokeWeight = 0
                    fillColor = "red"
                    fillOpacity = opacity
                })
            } else null
        )

        /**
         *  For changes to be displayed setMap has to be called everytime a geometry has changed.
         *  This behaviour is different from Layers like HeatMapLayer where changes take effect immediately. ^TH
         */
        setState {
            choropleth.forEach { it?.let { it.setMap(myMap) } }
        }
    }

    override fun RBuilder.render() {
        authorize(Permissions.StatisticsManagement.lteStatView) {
            spacer()
            mGridContainer2(direction = MGridDirection.row) {
                mGridItem2(
                    MGridBreakpoints2(MGridSize2.Cells3)
                        .down(Breakpoint.md, MGridSize2.Cells12)
                ) {
                    filter()
                }
                mGridItem2(
                    MGridBreakpoints2(MGridSize2.Cells9)
                        .down(Breakpoint.md, MGridSize2.Cells12)
                ) {
                    map()
                }
            }
        }
    }

    fun RBuilder.filter() {
        mGridContainer2(direction = MGridDirection.column) {
            mGridItem2 {
                if (state.loadingLineData)
                    mSkeleton(
                        height = 100.px,
                        animation = MSkeletonAnimation.wave,
                        variant = MSkeletonVariant.rect
                    )
                else
                    mCard {
                        css(GlobalStyles.card)
                        mCardContent {
                            css(GlobalStyles.cardContent)
                            mListSubheader(heading = "Linie")
                            mDivider { }
                            styledDiv {
                                css {
                                    padding(2.spacingUnits)
                                    ":last-child" { paddingBottom = 0.spacingUnits }
                                }
                                mSelect(
                                    variant = MFormControlVariant.outlined,
                                    fullWidth = true,
                                    value = state.selectedLine ?: "all",
                                    name = "Linie",
                                    id = "line",
                                    disabled = state.lines.isEmpty(),
                                    onChange = { event, _ ->
                                        val id = event.targetValue as String
                                        setState({
                                            assign(it) {
                                                loadingSignalData = true
                                                selectedLine = id
                                            }
                                        }) {
                                            fetchHeatPoints(state.selectedLine)
                                            changePointDistribution()
                                        }
                                    }
                                ) {
                                    attrs.margin = MFormControlMargin.dense.toString()
                                    mMenuItem(
                                        primaryText = "Alle",
                                        value = "all"
                                    )
                                    for (line in state.lines) {
                                        mMenuItem(
                                            primaryText = line,
                                            value = line
                                        )
                                    }
                                }
                            }
                        }
                    }
            }
            mGridItem2 {
                mCard {
                    css(GlobalStyles.card)
                    mCardContent {
                        css(GlobalStyles.cardContent)
                        mListSubheader(heading = "Filter")
                        mDivider { }
                        mRadioGroup {
                            css(GlobalStyles.cardRadioContent)
                            for (i in 0..4) {
                                mFormControlLabel(
                                    label = if (state.relativeScaling) relativeLabel[i] else absoluteLabel[i],
                                    control = mRadio(
                                        checked = state.signalFilter == i,
                                        addAsChild = false,
                                        onChange = { _, checked ->
                                            if (checked) {
                                                setState({
                                                    assign(it) {
                                                        signalFilter = i
                                                    }
                                                }) { changePointDistribution() }
                                            }
                                        }
                                    )
                                )
                            }
                        }
                    }
                }
            }
            mGridItem2 {
                mCard {
                    css(GlobalStyles.card)
                    mCardContent {
                        css(GlobalStyles.cardContent)
                        mListSubheader(heading = "Signalstärke")
                        mDivider { }
                        styledDiv {
                            css {
                                padding(2.spacingUnits)
                                ":last-child" { paddingBottom = 0.spacingUnits }
                                justifyItems = JustifyItems.center
                                display = Display.flex
                                alignItems = Align.center
                            }
                            mSwitchWithLabel(
                                label = "Absolut / Relativ",
                                checked = state.relativeScaling,
                                onChange = { _, checked ->
                                    setState({
                                        assign(it) {
                                            relativeScaling = checked
                                        }
                                    }) { changePointDistribution() }
                                }
                            )
                        }
                        styledDiv {
                            css { padding(0.spacingUnits, 2.spacingUnits) }
                            mTypography(
                                component = "div",
                                color = MTypographyColor.textSecondary,
                                variant = MTypographyVariant.caption,
                                text = "In der relativen Darstellung werden Signalstärken skaliert, von schlechtester bis bester, um Unterschiede auf homogenen Strecken besser zu erkennen. Bei der absoluten Darstellung werden Signalstärken direkt genommen, ohne Skalierung."
                            )
                        }
                    }
                }
            }
        }
    }

    fun RBuilder.map() {
        styledDiv {
            css {
                height = 700.px
                display = Display.flex
                flexDirection = FlexDirection.column
            }
            mCard {
                css {
                    flexGrow = 1.0
                    display = Display.flex
                    flexDirection = FlexDirection.column
                }
                mCardContent {
                    css(GlobalStyles.cardMapContent)
                    css { height = 100.pct }
                    googleMap {
                        attrs {
                            if (!state.heatPoints.isEmpty()) {
                                center = googleMapCoords(
                                    state.heatPoints.get((state.heatPoints.size / 2)).location.lat().toDouble(),
                                    state.heatPoints.get((state.heatPoints.size / 2)).location.lng().toDouble()
                                )
                                heatmapLibrary = true
                                zoom = 12
                            } else {
                                center = null
                                zoom = null
                            }
                            defaultCenter = googleMapCoords(latitude = 51.400427, longitude = 10.283918)
                            defaultZoom = DEFAULT_ZOOM
                            /** If there are open classes where everything is externally defined
                             * like MapOptions or PolygonOptions you initialize those as jsObject
                             */
                            options = jsObject<MapOptions> {
                                mapTypeId = "roadmap"
                                mapTypeControl = true
                            }
                            googleMapLoader = { Promise.resolve(js("google.maps") as Any) }
                            yesIWantToUseGoogleMapApiInternals = true
                            /** Once the map is loaded save a reference to the map object to add/remove layers. */
                            onGoogleApiLoaded = {
                                setState {
                                    this.myMap = it.map
                                }
                                it.map.data
                            }
                        }
                    }
                }
            }
        }
    }
}