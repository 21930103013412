package de.geomobile.common.permission

import de.geomobile.common.portalmodels.Company
import kotlinx.serialization.Serializable

@Serializable
data class Role(
    val id: String,
    val name: String,
    val mutable: Boolean,
    val visibleForCompany: Boolean = true,
    val company: Company? = null,
    val permissions: Set<Permission>
)


object ImmutableRoles {
    private val _allImmutableRoles = mutableSetOf<Role>()
    val allImmutableRoles: Set<Role> = _allImmutableRoles

    val superAdmin = role(
        id = "SUPERADMIN",
        name = "Superadmin",
        visibleForCompany = false,
        permissions = Permissions.allPermissions
    )
    val companyAdmin = role(
        id = "COMPANY_ADMIN",
        name = "Admin",
        permissions = Permissions.allPermissions.filter { it.visibleForCompany }.toSet()
    )

    private fun role(
        id: String,
        name: String,
        visibleForCompany: Boolean = true,
        permissions: Set<Permission>
    ): Role {
        val role = Role(
            id = id,
            name = name,
            mutable = false,
            visibleForCompany = visibleForCompany,
            company = null,
            permissions = permissions
        )
        _allImmutableRoles.add(role)
        return role
    }

    fun findById(id: String): Role? = allImmutableRoles.firstOrNull { it.id == id }
}