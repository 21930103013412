package de.geomobile.frontend.features.device.detail

import de.geomobile.common.feature.Features
import de.geomobile.common.permission.Permission
import de.geomobile.common.permission.Permissions
import de.geomobile.common.portalmodels.DeviceCDADTO
import de.geomobile.common.portalmodels.TimestampStatus
import de.geomobile.common.utils.nonStrictJson
import de.geomobile.frontend.utils.isAuthorized
import de.geomobile.frontend.utils.isTrial
import kotlinx.css.Color
import react.RBuilder

fun RBuilder.cdaStatus(
    id: Int,
    expanded: Boolean,
    onExpand: (() -> Unit)? = null
) = deviceInfoComponent(
    id = id,
    expanded = expanded,
    onExpand = onExpand,
    subscriptionTopic = "/device/cda",
    emptyPlaceholder = InfoCard(title = "CDA nicht vorhanden", content = InfoEmpty),
) {
    try {
        nonStrictJson.decodeFromString(DeviceCDADTO.serializer(), it).infoItem()
    } catch (e: Exception) {
        DeviceCDADTO().infoItem()
    }
}

fun DeviceCDADTO.infoItem() = InfoCard(
    title = "CDA",
    status = TimestampStatus(timestampReceived),
    isCDA = true,
    trial = isTrial(Features.CompanyFeatures.cda),
    content = InfoGroup(
        enabledIcon = timestampDeployed?.let {
            when (status){
                DeviceCDADTO.CdaStatus.CURRENT -> "check_circle"
                else -> "info"
            }
        } ?: "info",
        disabledIcon = "info",
        iconColor = timestampDeployed?.let { deployed ->
            when (status) {
                DeviceCDADTO.CdaStatus.CURRENT -> Color.green
                DeviceCDADTO.CdaStatus.OUTDATED -> Color.orange
                else -> Color.red
            }
        } ?: Color.black,
        title = timestampDeployed?.let { deployed ->
            when (status) {
                DeviceCDADTO.CdaStatus.CURRENT -> "CDA Konfiguration ist aktuell."
                DeviceCDADTO.CdaStatus.OUTDATED -> "CDA Konfiguration ist veraltet."
                DeviceCDADTO.CdaStatus.UNSET_IN_PROFILE -> "CDA Konfiguration ist nicht gesetzt."
                DeviceCDADTO.CdaStatus.UNSET_ON_BOX -> "CDA Konfiguration auf Box bisher nicht vorhanden."
                else -> when {
                    (versionOnBox?.toIntOrNull() ?: 0) > (versionInSystem?.toIntOrNull() ?: 1) -> "CDA Konfiguration auf Box ist neuer als im System."
                    (timestampOnBox?.millis ?: 0) > (timestampDeployed?.millis ?: 1) -> "CDA Konfiguration auf Box ist neuer als im System."
                    else -> "CDA Konfiguration Fehlerhaft."
                }
            }
        } ?: "CDA Konfiguration ist nicht vorhanden",
        enabled = true,
        content = infoList()
    )
)

fun DeviceCDADTO.infoList() = InfoList(
    listOfNotNull(
        timestampDeployed?.let { InfoRow(title = "Zeitstempel im System", value = it) },
        versionInSystem?.let { InfoRow(title = "Version im System", value = it) },
        timestampOnBox?.let { InfoRow(title = "Zeitstempel auf Gerät", value = it) },
        versionOnBox?.let { InfoRow(title = "Version auf Gerät", value = it) },
        if(isAuthorized(Permissions.AdminPermissions.internalAccess))
            remoteStatus?.let { InfoRow(title = "Remote Status", value = it.readableName) }
        else null,
    )
)

//fun DeviceCDADTO.infoList() = InfoList(
//    InfoRow(title = "Zeitstempel auf Gerät", value = timestampOnBox),
//    InfoRow(title = "Zeitstempel im System", value = timestampDeployed)
//)



