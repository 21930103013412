package portalmodels

import kotlinx.serialization.Serializable

@Serializable
data class IbisTelegramSettingsDTO(
    val companyId: String,
    val telegram: String,
    val active: Boolean,
)

@Serializable
data class IbisTelegramFilterSetterDTO(
    val ibisProfileId: Int,
    val ibisTelegramId: String,
    val ibisFilterId: Int,
)

@Serializable
data class IbisTelegramFilterDTO(
    val id: Int,
    val companyId: String,
    val name: String,
    val type: IbisFilterType,
    val text: String,
)

enum class IbisFilterType(val readableName: String) {
    NONE("Kein Filter"),
    WHITELIST("Whitelist"),
    BLACKLIST("Blacklist"),
    REGEX("RegEx")
}

enum class IbisFunction(val readableName: String) {
    NONE("Nicht Zugewiesen"),
    LINE("Linie"),
    STATIONS("Nächste Haltestelle (Stationen)"),
    STATION_INDEX("Nächste Haltestelle (Index)"),
    DESTINATION("Fahrtziel"),
    DIRECTION("Fahrtrichtung"),
    MULTI_DRIVE("Mehrfachtraktion"),
    VEHICLE_TYPE("Fahrzeugtyp"),
    LOGGING("Logging")
}