package de.geomobile.frontend.features.device

import de.geomobile.common.portalmodels.DeviceIdentifier
import de.geomobile.frontend.api.ApiException
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.utils.CComponent
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.serialization.builtins.serializer
import react.*

fun RBuilder.deviceIdResolver(
    identifier: DeviceIdentifier,
    render: RBuilder.(DeviceIdentifier.Id) -> Unit,
) = child(DeviceIdResolver::class) {
    attrs.key = identifier.toString()
    attrs.identifier = identifier
    attrs.render = render
}

class DeviceIdResolver : CComponent<DeviceIdResolver.Props, DeviceIdResolver.State>() {

    interface Props : RProps {
        var identifier: DeviceIdentifier
        var render: RBuilder.(DeviceIdentifier.Id) -> Unit
    }

    class State(
        var deviceId: DeviceIdentifier.Id? = null,
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        launch {
            val id = when (val identifier = props.identifier) {
                is DeviceIdentifier.Id -> identifier
                is DeviceIdentifier.SerialNumber,
                is DeviceIdentifier.VehicleId,
                is DeviceIdentifier.CpuId,
                -> withContext(Dispatchers.Default) {
                    val url = when (identifier) {
                        is DeviceIdentifier.SerialNumber -> "/device/${identifier.product.readableName}/serialnumber/${identifier.serialNumber}/id"
                        is DeviceIdentifier.CpuId -> "/device/${identifier.product.readableName}/cpuid/${identifier.cpuId}/id"
                        else -> error("not possible")
                    }
                    val id = try {
                        portalRestApi.get(url, Int.serializer())
                    } catch (e: ApiException) {
                        0
                    }
                    DeviceIdentifier.Id(id)
                }

            }
            setState { deviceId = id }
        }
    }

    override fun RBuilder.render() {
        val id = state.deviceId
        if (id != null) {
            props.render(this, id)
        }
    }
}