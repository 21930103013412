package portalmodels

import kotlinx.serialization.Serializable

@Serializable
data class MonitoringDTO(
    val companyId: String,
    val label: String,
    val url: String,
    val description: String
)