package de.geomobile.common.stage

enum class Stage(val readableName: String) {
    CREATED("Erstellt"),
    ASSEMBLED("Montiert"),
    SHIPPED("Ausgeliefert"),
    IN_STORAGE("Im Lager"),
    RETURNED("Retour"),
    DEFECT("Defekt");

    companion object {
        private val mapping = Stage.values().associateBy(Stage::readableName)
        fun fromReadable(readableName: String) = mapping[readableName]
    }
}