package de.geomobile.frontend.features.config

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardActions
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.styles.Breakpoint
import components.emptyView
import de.geomobile.common.filter.Filter
import de.geomobile.common.filter.FilterRules
import de.geomobile.common.portalmodels.Company
import de.geomobile.common.portalmodels.DeviceIdentifier
import de.geomobile.common.portalmodels.Product
import de.geomobile.common.portalmodels.VehicleProfileDTO
import de.geomobile.common.utils.Tree
import de.geomobile.common.utils.emptyTree
import de.geomobile.common.utils.tree
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.features.device.list.DeviceListItem
import de.geomobile.frontend.features.device.list.DeviceListItemFilterList
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.spacer
import de.geomobile.frontend.utils.*
import de.geomobile.server.device.config.DeviceConfigFilter
import kotlinx.coroutines.*
import kotlinx.css.Color
import kotlinx.css.JustifyContent
import kotlinx.css.justifyContent
import kotlinx.css.padding
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.builtins.PairSerializer
import kotlinx.serialization.builtins.serializer
import kotlinx.serialization.json.JsonObjectBuilder
import kotlinx.serialization.json.buildJsonObject
import react.*
import styled.StyledElementBuilder
import styled.css
import styled.styledDiv
import kotlin.math.max
import kotlin.math.min

fun RBuilder.deviceConfig(
    selectedId: Int?,
    drawerMenu: ReactElement,
    changeSelection: (selectedId: Int?) -> Unit,
    onDeviceClick: (id: DeviceIdentifier) -> Unit,
) = child(DeviceConfig::class) {
    attrs.selectedId = selectedId
    attrs.changeSelection = changeSelection
    attrs.drawerMenu = drawerMenu
    attrs.onDeviceClick = onDeviceClick
}

interface SelectedDeviceConfigProps : RProps {
    var selectedId: String?
}

class DeviceConfig : CComponent<DeviceConfig.Props, DeviceConfig.State>() {

    private var loadJob: Job = Job()
    private var saveJob: Job = Job()

    interface Props : RProps {
        var drawerMenu: ReactElement
        var selectedId: Int?
        var changeSelection: (selectedId: Int?) -> Unit
        var onDeviceClick: (id: DeviceIdentifier) -> Unit
    }

    class State(
        var filterTree: Tree<DeviceConfigFilter> = emptyTree(),
        var deviceFilter: Map<String, Filter<DeviceListItem, *>> = emptyMap(),
        var conflicts: List<Pair<DeviceConfigFilter, DeviceConfigFilter>> = emptyList(),
        var changedTree: Tree<DeviceConfigFilter>? = null,
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        loadFilter()
    }

    override fun componentWillReceiveProps(nextProps: Props) {
        if (nextProps.selectedId != props.selectedId && (nextProps.selectedId == null || nextProps.selectedId!! >= 0)) {
            setState { changedTree = null }
        }
    }

    private fun loadFilter() {
        loadJob.cancel()
        loadJob = launch {
            val companies = async(Dispatchers.Default) {
                portalRestApi.get("/admin/companies", ListSerializer(Company.serializer()))
            }
            val filterTree = withContext(Dispatchers.Default) {
                portalRestApi.get("/config", DeviceConfigFilter.serializer().tree)
            }
            val vehicleProfiles = async(Dispatchers.Default) {
                portalRestApi.get("/vehicleprofiles", ListSerializer(VehicleProfileDTO.serializer()))
            }

            val conflicts = withContext(Dispatchers.Default) {
                portalRestApi.get("/config/conflicts", ListSerializer(PairSerializer(DeviceConfigFilter.serializer(), DeviceConfigFilter.serializer())))
            }

            val deviceListItemDtoFilters: Map<String, Filter<DeviceListItem, *>> = DeviceListItemFilterList(
                products = Product.values().asList(),
                companies = companies.await(),
                vehicleProfiles = vehicleProfiles.await()
            ).filters

            setState {
                this.filterTree = filterTree
                this.changedTree = null
                this.conflicts = conflicts
                this.deviceFilter = deviceListItemDtoFilters
            }
        }
    }

    override fun RBuilder.render() {
        mAppBar(position = MAppBarPosition.fixed) {
            css(GlobalStyles.appbar)
            mToolbar {
                child(props.drawerMenu)
                mToolbarTitle2("Konfiguration")
            }
        }
        spacer()
        styledDiv {
            css { padding(2.spacingUnits) }
            mGridContainer2(direction = MGridDirection.row) {
                mGridItem2(
                    MGridBreakpoints2(MGridSize2.Cells3)
                        .down(Breakpoint.md, MGridSize2.Cells12)
                ) {
                    filterList()
                }
                mGridItem2(
                    MGridBreakpoints2(MGridSize2.Cells9)
                        .down(Breakpoint.md, MGridSize2.Cells12)
                ) {
                    detail()
                }
            }
        }
    }

    private fun StyledElementBuilder<MGridProps2>.filterList() {

        val tree = (state.changedTree ?: state.filterTree)
        val entryTree = tree
            .map { parent, (value) ->
                TreeEntry(
                    key = value.id.toString(),
                    name = value.name,
                    new = value.id < 0,
                    color = when {
                        !value.filterValid -> Color.red
                        value.deactivateConfigs -> Color.gray
                        else -> null
                    },
                    selected = value.id == props.selectedId,
                    expandedByDefault = parent == null,
                    onClick = { props.changeSelection(value.id) },
                    alert = state.conflicts.any { it.first.id == value.id || it.second.id == value.id },
                )
            }

        mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
            mCard {
                css(GlobalStyles.card)
                mCardContent {
                    css(GlobalStyles.cardListContent)

                    if (tree.rootNodes.isEmpty())
                        emptyView(
                            boxShadow = false,
                            iconName = "menu",
                            title = "Filter",
                            caption = "Es wurden keine Filter gefunden",
                            addButton = false
                        )
                    else {
                        tree(heading = "Filter", tree = entryTree)
                    }
                }
                mDivider()
                mCardActions {
                    css { justifyContent = JustifyContent.spaceEvenly }
                    treeOperations(
                        tree = entryTree,
                        onMoveSelectedNode = { operation ->
                            val newTree = tree.transformSiblings(
                                select = { it.id == props.selectedId },
                                transform = { siblings ->
                                    val index = siblings.indexOfFirst { it.value.id == props.selectedId }

                                    siblings.toMutableList().apply {
                                        removeAt(index)
                                        add(max(0, min(siblings.lastIndex, index + operation)), siblings[index])
                                    }
                                }
                            )

                            setState { changedTree = newTree.takeIf { it != filterTree } }
                        },
                        onAddTopLevel = {
                            val newTree = Tree(
                                tree.rootNodes + Tree.Node(
                                    value = DeviceConfigFilter(
                                        id = -1,
                                        name = "Neuer Filter",
                                        filterValid = true,
                                        filterRules = FilterRules(emptyList()),
                                        configOverlay = buildJsonObject(fun JsonObjectBuilder.() {

                                        }),
                                        deactivateConfigs = false,
                                        disabled = false
                                    )
                                )
                            )

                            setState { changedTree = newTree }
                            props.changeSelection(-1)
                        },
                        onAddChild = {
                            val newTree = tree.plusChild(
                                selectParent = { it?.id == props.selectedId },
                                newNode = Tree.Node(
                                    value = DeviceConfigFilter(
                                        id = -1,
                                        name = "Neuer Filter",
                                        filterValid = true,
                                        filterRules = FilterRules(emptyList()),
                                        configOverlay = buildJsonObject(fun JsonObjectBuilder.() {

                                        }),
                                        deactivateConfigs = false,
                                        disabled = false
                                    )
                                )
                            )

                            setState { changedTree = newTree }
                            props.changeSelection(-1)
                        },
                        onAddSibling = {
                            val newTree = tree.plusSibling(
                                select = { it.id == props.selectedId },
                                newNode = Tree.Node(
                                    value = DeviceConfigFilter(
                                        id = -1,
                                        name = "Neuer Filter",
                                        filterValid = true,
                                        filterRules = FilterRules(emptyList()),
                                        configOverlay = buildJsonObject(fun JsonObjectBuilder.() {

                                        }),
                                        deactivateConfigs = false,
                                        disabled = false
                                    )
                                )
                            )

                            setState { changedTree = newTree }
                            props.changeSelection(-1)
                        },
                        onSelectNewParent = { parent ->
                            val node = tree.findNode { it.id == props.selectedId }!!
                            val newTree = tree.filter { it.id != props.selectedId }
                                .plusChild(
                                    selectParent = { it?.id == parent?.key?.toInt() },
                                    newNode = node
                                )

                            setState { changedTree = newTree }
                        },
                        onDelete = {
                            deleteDeviceConfig()
                        }
                    )
                }
            }
        }
    }

    private fun StyledElementBuilder<MGridProps2>.detail() {

        val branch = props.selectedId?.let { selectedId ->
            (state.changedTree ?: state.filterTree)
                .findBranch { it.id == selectedId }
        }
        val siblings = props.selectedId?.let { selectedId ->
            (state.changedTree ?: state.filterTree)
                .findSiblings { it.id == selectedId }
        } ?: emptyList()
        val selected = branch?.lastOrNull()

        mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
            selected?.let {
                val newParent = state.changedTree?.findParent { it == selected }?.id
                val newPosition = state.changedTree?.findSiblings { it == selected }?.indexOf(selected)

                deviceConfigDetail(
                    newParentId = newParent,
                    newPosition = newPosition,
                    deviceConfigFilter = selected,
                    branch = branch,
                    siblings = siblings,
                    conflicts = state.conflicts.mapNotNull {
                        when(selected.id) {
                            it.first.id -> it.second
                            it.second.id -> it.first
                            else -> null
                        }
                    }.distinct(),
                    deviceFilter = state.deviceFilter,
                    onSaved = {
                        loadFilter()
                        props.changeSelection(it)
                    },
                    onDeviceClick = props.onDeviceClick
                )
            } ?: run {
                emptyView(
                    title = "Filter",
                    caption = "Es wurde kein Filter ausgewählt",
                    addButton = false
                )
            }
        }
    }

    private fun deleteDeviceConfig() {
        if (props.selectedId != null) {
            saveJob.cancel()
            saveJob = launch {
                withContext(Dispatchers.Default) {
                    portalRestApi.delete(path = "/config/${props.selectedId}")
                }
                loadFilter()
                props.changeSelection(null)
            }
        }
    }
}