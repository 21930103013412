package de.geomobile.frontend.features.vehicleProfile

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.styles.Breakpoint
import de.geomobile.common.permission.Permissions
import de.geomobile.common.portalmodels.VehicleProfileDTO
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.spacer
import de.geomobile.frontend.utils.*
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.marginRight
import kotlinx.css.maxWidth
import kotlinx.css.pct
import portalmodels.FmsProfileDTO
import portalmodels.IbisProfileDTO
import portalmodels.WiringProfileDTO
import react.RBuilder
import react.RProps
import react.RState
import react.setState
import styled.css

fun RBuilder.vehicleProfilesDetail(
    profileId: String,
    goBack: () -> Unit,
    edit: (() -> Unit)?,
) = child(VehicleProfilesDetail::class) {
    attrs.profileId = profileId
    attrs.goBack = goBack
    attrs.edit = edit
}

class VehicleProfilesDetail : CComponent<VehicleProfilesDetail.Props, VehicleProfilesDetail.State>() {

    interface Props : RProps {
        var profileId: String
        var goBack: () -> Unit
        var edit: (() -> Unit)?
    }

    class State(
        var profile: VehicleProfileDTO? = null,
        var fmsProfile: FmsProfileDTO? = null,
        var ibisProfile: IbisProfileDTO? = null,
        var wiringProfile: WiringProfileDTO? = null,
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        launch {
            val profile = withContext(Dispatchers.Default) {
                portalRestApi.get("/vehicleprofiles/id/${props.profileId}", VehicleProfileDTO.serializer())
            }

            val fmsProfile = profile.fmsProfile?.let {
                withContext(Dispatchers.Default) {
                    portalRestApi.get("/vehicleprofiles/fms/profile/${it}", FmsProfileDTO.serializer())
                }
            }

            val ibisProfile = profile.ibisProfile?.let {
                withContext(Dispatchers.Default) {
                    portalRestApi.get("/vehicleprofiles/ibis/profile/${it}", IbisProfileDTO.serializer())
                }
            }
            println(profile.wiringProfile)
            val wiringProfile = profile.wiringProfile?.let {
                withContext(Dispatchers.Default) {
                    portalRestApi.get("/vehicleprofiles/wiring/profile/$it", WiringProfileDTO.serializer())
                }
            }

            println(wiringProfile)
            setState {
                this.profile = profile
                this.fmsProfile = fmsProfile
                this.ibisProfile = ibisProfile
                this.wiringProfile = wiringProfile
            }
        }
    }

    override fun RBuilder.render() {
        val profile = state.profile

        spacer()
        mGridContainer2(direction = MGridDirection.row) {
            mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                mIconButtonNoTranslate(
                    iconName = "arrow_back",
                    onClick = { props.goBack() }
                ) {
                    css { marginRight = 1.spacingUnits }
                }
                if (props.edit != null) {
                    authorize(Permissions.VehicleProfileManagement.profilesEdit) {
                        mButton(
                            caption = "Bearbeiten",
                            variant = MButtonVariant.contained,
                            color = MColor.secondary,
                            disabled = state.profile == null,
                            onClick = { props.edit!!() }
                        ) {
                            attrs.disableElevation = true
                        }
                    }
                }
            }
            mCard {
                mCardContent {
                    css { maxWidth = 70.pct }
                    mGridContainer2(direction = MGridDirection.row) {
                        mGridItem2(MGridBreakpoints2(MGridSize2.Cells6)
                            .down(Breakpoint.md, MGridSize2.Cells12)) {

                            mGridContainer {
                                listItemWrapper("Name", profile?.name ?: "-")
                                listItemWrapper("Unternehmen", profile?.company?.name ?: "-")
                                listItemWrapper("Beschreibung", profile?.description ?: "-")
                                listItemWrapper("Fahrzeugmodell", profile?.vehicleModel ?: "-")
                                listItemWrapper("Fahrzeugtyp", profile?.vehicleType?.readableName ?: "-")
                                listItemWrapper("Flurtechnik", profile?.floorType?.readableName ?: "-")
                                listItemWrapper("Passagiergrenze gemessen an", profile?.passengerLimitOption?.readableName ?: "-")
                                listItemWrapper("Passagiergrenze Mittlere Auslastung in %", profile?.lowerPassengerLimit?.toString() ?: "-")
                                listItemWrapper("Passagiergrenze Hohe Auslastung in %", profile?.upperPassengerLimit?.toString() ?: "-")
                                listItemWrapper("Anzahl Sitzplätze", profile?.seatingCapacity?.toString() ?: "-")
                                listItemWrapper("Anzahl Stehplätze", profile?.standingCapacity?.toString() ?: "-")
                                authorize(Permissions.DeviceManagement.ela) {
                                    listItemWrapper("ELA Lautstärke", profile?.elaAudioVolume?.toString() ?: "-")
                                    listItemWrapper("ELA Treble", profile?.elaAudioTreble?.toString() ?: "-")
                                    listItemWrapper("ELA Bass", profile?.elaAudioBass?.toString() ?: "-")
                                }
                                listItemWrapper("Rampe", profile?.ramp?.readableName ?: "-")
                            }
                        }
                        mGridItem2(MGridBreakpoints2(MGridSize2.Cells6)
                            .down(Breakpoint.md, MGridSize2.Cells12)) {
                            authorize(Permissions.VehicleProfileManagement.fmsProfileView) {
                                listItemLinkWrapper(
                                    label = "FMS Profil",
                                    toPath = "/vehicleprofiles/fms/${state.fmsProfile?.id}",
                                    text = state.fmsProfile?.name,
                                )
                            }
                            authorize(Permissions.DeviceManagement.busAccessV18Write) {
                                listItemLinkWrapper(
                                    label = "IBIS Profil",
                                    toPath = "/vehicleprofiles/ibis/${state.ibisProfile?.profileId}",
                                    text = state.ibisProfile?.profileName,
                                )
                            }
                            authorize(Permissions.DeviceManagement.cableCheck) {
                                listItemLinkWrapper(
                                    label = "Verkabelungsprofil",
                                    toPath = "/vehicleprofiles/wiring/${state.wiringProfile?.id}",
                                    text = state.wiringProfile?.name,
                                )
                            }
                            listItemWrapper(
                                "Fahrzeugnr. über IBIS",
                                if (profile?.vehicleIdByIBIS == true) "Ja" else "-"
                            )
                            listItemWrapper(
                                "Fahrzeugnr. überschreibbar",
                                if (profile?.vehicleIdAllowOverride == true) "Ja" else "-"
                            )
                        }
                    }
                }
            }
        }
    }
}