package de.geomobile.frontend.utils.grid

import com.ccfraser.muirwik.components.createStyled
import com.ccfraser.muirwik.components.setStyledPropsAndRunHandler
import react.RBuilder
import react.RComponent
import react.RState
import styled.StyledHandler
import styled.StyledProps

@JsModule("@devexpress/dx-react-core")
private external val dx_react_core: dynamic

// TODO: Clean up
//external var Action: React.ComponentType<ActionProps> = definedExternally
//external interface ActionProps {
//    var action: (payload: Any, getters: Getters, actions: Actions) -> Unit
//    var name: String
//}
//external interface Actions {
//    @nativeGetter
//    operator fun get(actionName: String): ((payload: Any? /*= null*/) -> Unit)?
//    @nativeSetter
//    operator fun set(actionName: String, value: (payload: Any? /*= null*/) -> Unit)
//}
//external interface `T$0` {
//    fun update()
//}
//external var connectProps: (WrappedComponent: React.ComponentType<Any>, getAdditionalProps: () -> Any) -> dynamic /* React.ComponentClass<Any, Any> /* React.ComponentClass<Any, Any> & `T$0` */ | React.FunctionComponent<Any> /* React.FunctionComponent<Any> & `T$0` */ */ = definedExternally
//external var Getter: React.ComponentType<GetterProps> = definedExternally
//external interface GetterProps {
//    var computed: ((getters: Getters, actions: Actions) -> Any)? get() = definedExternally; set(value) = definedExternally
//    var name: String
//    var value: Any? get() = definedExternally; set(value) = definedExternally
//}
//external interface Getters {
//    @nativeGetter
//    operator fun get(getterName: String): Any?
//    @nativeSetter
//    operator fun set(getterName: String, value: Any)
//}
//external interface IDependency {
//    var name: String
//    var optional: Boolean? get() = definedExternally; set(value) = definedExternally
//}
//external var Plugin: React.ComponentType<PluginProps> = definedExternally
//external var PluginHost: React.ComponentType<PluginHostProps> = definedExternally
//external interface PluginHostProps {
//    var children: React.ReactNode
//}

@Suppress("UnsafeCastFromDynamic")
private val pluginComponent: RComponent<PluginProps, RState> = dx_react_core.Plugin

fun RBuilder.plugin(
    name: String,
    handler: StyledHandler<PluginProps>? = null,
) = createStyled(pluginComponent) {
    attrs.name = name
    setStyledPropsAndRunHandler(null, handler)
}

interface PluginProps : StyledProps {
    //    var children: React.ReactNode
//    var dependencies: Array<IDependency>? get() = definedExternally; set(value) = definedExternally
    var name: String?
}
//external var Template: React.ComponentType<TemplateProps> = definedExternally
//external var TemplateConnector: React.ComponentType<TemplateConnectorProps> = definedExternally

@Suppress("UnsafeCastFromDynamic")
private val templateConnectorComponent: RComponent<TemplateConnectorProps, RState> = dx_react_core.TemplateConnector

fun RBuilder.templateConnector(
    handler: StyledHandler<TemplateConnectorProps>? = null,
) = createStyled(templateConnectorComponent) {
    setStyledPropsAndRunHandler(null, handler)
}

interface TemplateConnectorProps : StyledProps {
//    var children: (getters: Getters, actions: Actions) -> React.ReactNode
}
//external var TemplatePlaceholder: React.ComponentType<TemplatePlaceholderProps> = definedExternally

@Suppress("UnsafeCastFromDynamic")
private val templatePlaceholderComponent: RComponent<TemplatePlaceholderProps, RState> =
    dx_react_core.TemplatePlaceholder

fun RBuilder.templatePlaceholder() = createStyled(templatePlaceholderComponent) {}

interface TemplatePlaceholderProps : StyledProps {
//    var children: ((content: Any) -> Any)? get() = definedExternally; set(value) = definedExternally
//    var name: String? get() = definedExternally; set(value) = definedExternally
//    var params: Any? get() = definedExternally; set(value) = definedExternally
}

@Suppress("UnsafeCastFromDynamic")
private val templateComponent: RComponent<TemplateProps, RState> = dx_react_core.Template

fun RBuilder.template(
    name: String,
    handler: StyledHandler<TemplateProps>? = null,
//    handler: RBuilder.(params: Any) -> Unit
) = createStyled(templateComponent) {
    attrs.name = name
//    attrs.children = { params -> buildElements { handler(RBuilder(), params) } }
    setStyledPropsAndRunHandler(null, handler)
}

interface TemplateProps : StyledProps {
    //    var children: (params: Any) -> ReactElement
    var name: String
    var predicate: ((params: Any) -> Boolean)?
}
