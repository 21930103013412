package de.geomobile.common.portalmodels

import kotlinx.serialization.Serializable


@Serializable
data class DeviceCellularStatusDTO(
    val status: TimestampStatus = TimestampStatus(),
    val modules: List<DeviceCellularModuleDTO>? = null
)

@Serializable
data class DeviceCellularModuleDTO(
    val iccid: String? = null,
    val interfaceName: String? = null,
    val available: Boolean? = null,
    val providerName: String? = null,
    val rssi: String? = null,
    val type: String? = null,
    val pin: String? = null,
    val apn: String? = null,
    val ipType: String? = null,
    val pinDisabled: String? = null,
    val simStatus: String? = null,
    val enabled: Boolean? = null
)