package de.geomobile.frontend.utils.charts

import com.ccfraser.muirwik.components.createStyled
import com.ccfraser.muirwik.components.setStyledPropsAndRunHandler
import de.geomobile.frontend.utils.MSkeletonAnimation
import de.geomobile.frontend.utils.MSkeletonVariant
import de.geomobile.frontend.utils.mSkeleton
import kotlinext.js.js
import kotlinx.css.padding
import kotlinx.css.pct
import kotlinx.css.boxShadow
import kotlinx.css.properties.BoxShadows
import kotlinx.css.px
import kotlinx.css.width
import org.w3c.dom.Element
import org.w3c.dom.HTMLDivElement
import react.*
import react.dom.div
import styled.StyledHandler
import styled.StyledProps
import styled.css
import kotlin.js.Date
import kotlin.js.Json

// TODO: Clean up
external interface `T$0` {
    var ChartWrapper: GoogleChartWrapper
    var ChartEditor: GoogleChartEditor
    var DataTable: GoogleDataTable
    var events: GoogleVizEvents
    var arrayToDataTable: (data: Array<Array<Any>>, isFirstRowLabels: Boolean? /*= null*/) -> GoogleDataTable
    var drawToolbar: (toolbarContainer: HTMLDivElement, components: Array<GoogleChartToolbarItem>) -> Any
//    operator fun get(otherKeys: String): Any?
//    operator fun set(otherKeys: String, value: Any)
}

external interface GoogleViz {
    var charts: GoogleChartLoader
    var visualization: `T$0`
}

external interface `T$1` {
    var dataSourceInput: Any?
//    operator fun get(otherKeyMaybe: String): Any?
//    operator fun set(otherKeyMaybe: String, value: Any)
}

external interface GoogleChartEditor {
    var openDialog: (chartWrapper: GoogleChartWrapper, chartEditorOptions: `T$1`? /*= null*/) -> Nothing?
    var getChartWrapper: () -> GoogleChartWrapper
    var setChartWrapper: (chartWrapper: GoogleChartWrapper) -> GoogleChartWrapper
    var closeDialog: () -> Nothing?
}

external interface GoogleChartLoaderOptions {
    var packages: Array<dynamic /* String /* "corechart" */ | String /* "charteditor" */ | String /* "controls" */ | String /* "calendar" */ | String /* "gantt" */ | String /* "gauge" */ | String /* "geochart" */ | String /* "map" */ | String /* "orgchart" */ | String /* "sankey" */ | String /* "table" */ | String /* "timeline" */ | String /* "treemap" */ | String /* "wordtree" */ */>?
    var language: String?
    var mapsApiKey: String?
}

external interface GoogleChartLoader {
    var load: (version: dynamic /* String /* "current" */ | String /* "upcoming" */ */, googleChartOptions: GoogleChartLoaderOptions) -> Unit
    var setOnLoadCallback: (callback: () -> Unit) -> Unit
}

external interface `T$2` {
    var width: Number?
    var height: Number?
    var is3D: Boolean?
    var title: String?
    var backgroundColor: String
}

external interface ChartWrapperProps : RProps {
    var chartType: dynamic /* String /* "AnnotationChart" */ | String /* "AreaChart" */ | String /* "BarChart" */ | String /* "BubbleChart" */ | String /* "Calendar" */ | String /* "CandlestickChart" */ | String /* "ColumnChart" */ | String /* "ComboChart" */ | String /* "DiffChart" */ | String /* "DonutChart" */ | String /* "Gantt" */ | String /* "Gauge" */ | String /* "GeoChart" */ | String /* "Histogram" */ | String /* "LineChart" */ | String /* "Line" */ | String /* "Bar" */ | String /* "Map" */ | String /* "OrgChart" */ | String /* "PieChart" */ | String /* "Sankey" */ | String /* "ScatterChart" */ | String /* "SteppedAreaChart" */ | String /* "Table" */ | String /* "Timeline" */ | String /* "TreeMap" */ | String /* "WaterfallChart" */ | String /* "WordTree" */ */
    var containerId: String?
    var options: `T$2`?
    var dataTable: Any?
    var dataSourceUrl: String?
    var query: String?
    var refreshInterval: Number?
    var view: dynamic /* Any? | Array<Any> */
    var render: ((props: ChartWrapperProps, chartWrapper: GoogleChartWrapper) -> Any)?
    var children: ((props: ChartWrapperProps, chartWrapper: GoogleChartWrapper) -> Any)?
}

external interface `T$3` {
    var row: Any?
    var column: Any?
}

external interface VizEventsProps : RProps {
    var chartWrapper: GoogleChartWrapper
    var onReady: ((chartWrapper: GoogleChartWrapper) -> Any)?
    var onError: ((chartWrapper: GoogleChartWrapper) -> Any)?
    var onSelect: ((selection: Array<`T$3`>) -> Any)?
    var render: ((props: VizEventsProps, chartWrapper: GoogleChartWrapper) -> Any)?
    var children: ((props: VizEventsProps, chartWrapper: GoogleChartWrapper) -> Any)?
}

external interface ChartWrapperOptions {
    var chartType: String
    var containerId: String
    var options: Any?
    var dataTable: GoogleDataTable?
    var dataSourceUrl: String?
    var query: String?
    var refreshInterval: Number?
    var view: dynamic /* Any? | Array<Any> */
//    operator fun get(otherOptionKey: String): Any?
//    operator fun set(otherOptionKey: String, value: Any)
}

external interface GoogleChartAction {
    var id: String
    var text: String
    var action: (chartWrapper: GoogleChartWrapper) -> Unit
}

data class GoogleChartControlProp(
    var controlType: dynamic, /* String /* "CategoryFilter" */ | String /* "ChartRangeFilter" */ | String /* "DateRangeFilter" */ | String /* "NumberRangeFilter" */ | String /* "StringFilter" */ */
    var options: Any,
//    var controlWrapperParams: Any?,
//    var controlID: String?,
    var controlPosition: String,
    /* String /* "top" */ | String /* "bottom" */ */
//    var controlEvents: Array<ReactGoogleChartEvent>?
)

external interface `T$4` {
    var removeAction: (actionID: String) -> Unit
    var getSelection: () -> Array<`T$3`>
    var setAction: (ChartAction: GoogleChartAction) -> Unit
    var getImageURI: () -> Unit
    var clearChart: () -> Unit
}

external interface GoogleChartWrapper {
    var draw: (chartArgs: ChartWrapperProps? /*= null*/) -> Any
    var toJSON: () -> String
    var clone: () -> GoogleChartWrapper
    var getDataSourceUrl: () -> String
    var getDataTable: () -> GoogleDataTable?
    var getChartType: () -> dynamic /* String /* "AnnotationChart" */ | String /* "AreaChart" */ | String /* "BarChart" */ | String /* "BubbleChart" */ | String /* "Calendar" */ | String /* "CandlestickChart" */ | String /* "ColumnChart" */ | String /* "ComboChart" */ | String /* "DiffChart" */ | String /* "DonutChart" */ | String /* "Gantt" */ | String /* "Gauge" */ | String /* "GeoChart" */ | String /* "Histogram" */ | String /* "LineChart" */ | String /* "Line" */ | String /* "Bar" */ | String /* "Map" */ | String /* "OrgChart" */ | String /* "PieChart" */ | String /* "Sankey" */ | String /* "ScatterChart" */ | String /* "SteppedAreaChart" */ | String /* "Table" */ | String /* "Timeline" */ | String /* "TreeMap" */ | String /* "WaterfallChart" */ | String /* "WordTree" */ */
    var getChartName: () -> String
    var getChart: () -> `T$4`
    var getContainerId: () -> String
    var getQuery: () -> String
    var getRefreshInterval: () -> Number
    var getOption: (key: String, opt_default_value: Any? /*= null*/) -> Any
    var getOptions: () -> Any
    var getSelection: () -> Array<`T$3`>
    var getView: () -> dynamic /* Any? | Array<Any> */
    var setDataSourceUrl: (url: String) -> Unit
    var setDataTable: (table: Any) -> Unit
    var setChartType: (chartType: dynamic /* String /* "AnnotationChart" */ | String /* "AreaChart" */ | String /* "BarChart" */ | String /* "BubbleChart" */ | String /* "Calendar" */ | String /* "CandlestickChart" */ | String /* "ColumnChart" */ | String /* "ComboChart" */ | String /* "DiffChart" */ | String /* "DonutChart" */ | String /* "Gantt" */ | String /* "Gauge" */ | String /* "GeoChart" */ | String /* "Histogram" */ | String /* "LineChart" */ | String /* "Line" */ | String /* "Bar" */ | String /* "Map" */ | String /* "OrgChart" */ | String /* "PieChart" */ | String /* "Sankey" */ | String /* "ScatterChart" */ | String /* "SteppedAreaChart" */ | String /* "Table" */ | String /* "Timeline" */ | String /* "TreeMap" */ | String /* "WaterfallChart" */ | String /* "WordTree" */ */) -> Unit
    var setChartName: (name: String) -> Unit
    var setContainerId: (id: String) -> Unit
    var setQuery: (query_string: String) -> Unit
    var setRefreshInterval: (interval: Number) -> Unit
    var setOption: (key: String, value: Any) -> Unit
    var setOptions: (options_obj: Any?) -> Unit
}

external interface GoogleVizEvents {
    var addListener: (chartWrapper: dynamic /* GoogleChartWrapper | GoogleChartEditor | GoogleChartControl */, name: dynamic /* String /* "ready" */ | String /* "error" */ | String /* "select" */ | String /* "animationfinish" */ | String /* "statechange" */ | String /* "ok" */ | String /* "cancel" */ | String /* "animationstart" */ */, onEvent: (chartWrapper: GoogleChartWrapper) -> Any) -> Any
    var removeListener: (chartWrapper: GoogleChartWrapper, name: dynamic /* String /* "ready" */ | String /* "error" */ | String /* "select" */ | String /* "animationfinish" */ | String /* "statechange" */ | String /* "ok" */ | String /* "cancel" */ | String /* "animationstart" */ */, callback: Function<*>) -> Any
    var removeAllListeners: (chartWrapper: GoogleChartWrapper) -> Any
}

external enum class GoogleDataTableColumnRoleType {
    annotation /* = "annotation" */,
    annotationText /* = "annotationText" */,
    certainty /* = "certainty" */,
    emphasis /* = "emphasis" */,
    interval /* = "interval" */,
    scope /* = "scope" */,
    style /* = "style" */,
    tooltip /* = "tooltip" */,
    domain /* = "domain" */
}

data class Column(
    var type: String, /* String /* "string" */ | String /* "number" */ | String /* "boolean" */ | String /* "date" */ | String /* "datetime" */ | String /* "timeofday" */ */
    var label: String? = null,
//    var role: dynamic /* String /* "annotation" */ | String /* "annotationText" */ | String /* "certainty" */ | String /* "emphasis" */ | String /* "interval" */ | String /* "scope" */ | String /* "style" */ | String /* "tooltip" */ | String /* "domain" */ */
//    var pattern: String?
//    var p: Any?
    var format: String? = null,
    var id: String? = null,
)

external interface `T$6` {
    var v: Any?
    var f: String?
    var p: Any?
}

external interface `T$7` {
    var c: Array<dynamic /* String | Number | Boolean | `T$6` */>
}

external interface GoogleDataTableJS {
    var cols: Array<dynamic /* String | Column */>
    var rows: Array<`T$7`>
    var p: Any?
}

external interface GoogleDataTableRowFilter {
    var column: Number
    var value: Any
    var minValue: Any?
    var maxValue: Any?
}

external interface `T$8` {
    var min: Number?
    var max: Number?
}

external interface `T$9` {
    var column: Number
    var desc: Boolean
}

external interface GoogleDataTable {
    var addColumn: (column: dynamic /* String | Column */) -> Number
    var addRow: (row: Array<dynamic /* String | Number | Boolean | `T$6` */>? /*= null*/) -> Number
    var addRows: (rows: dynamic /* Array<Any> | Array<Array<dynamic /* String | Number | Boolean | `T$6` */>> | Array<Number> */ /*= null*/) -> Number
    var clone: () -> GoogleDataTable
    var getColumnId: (columnIndex: Number) -> String
    var getColumnLabel: (columnIndex: Number) -> String
    var getColumnPattern: (columnIndex: Number) -> String
    var getColumnProperties: (columnIndex: Number) -> Any
    var getColumnProperty: (columnIndex: Number, name: String) -> Any
    var getColumnRange: (columnIndex: Number) -> `T$8`
    var getColumnRole: (columnIndex: Number) -> dynamic /* String /* "annotation" */ | String /* "annotationText" */ | String /* "certainty" */ | String /* "emphasis" */ | String /* "interval" */ | String /* "scope" */ | String /* "style" */ | String /* "tooltip" */ | String /* "domain" */ */
    var getColumnType: (columnIndex: Number) -> dynamic /* String /* "string" */ | String /* "number" */ | String /* "boolean" */ | String /* "date" */ | String /* "datetime" */ | String /* "timeofday" */ */
    var getDistinctValues: (columnIndex: Number) -> Array<Any>
    var getFilteredRows: (filters: Array<GoogleDataTableRowFilter>) -> Array<Number>
    var getFormattedValue: (rowIndex: Number, columnIndex: Number) -> String
    var getNumberOfColumns: () -> Number
    var getNumberOfRows: () -> Number
    var getProperties: (rowIndex: Number, columnIndex: Number) -> Any
    var getProperty: (rowIndex: Number, columnIndex: Number, name: String) -> Any
    var getRowProperties: (rowIndex: Number) -> Any
    var getRowProperty: (rowIndex: Number, name: String) -> Any
    var getSortedRows: (sortColumns: dynamic /* Number | Array<Number> | `T$9` | Array<`T$9`> */) -> Array<Number>
    var getTableProperties: () -> Any
    var getTableProperty: (name: String) -> Any
    var getValue: (rowIndex: Number, columnIndex: Number) -> dynamic /* String | Number | Boolean | Array<Number> | Date | Nothing? */
    var insertColumn: (columnIndex: Number, type: dynamic /* String /* "string" */ | String /* "number" */ | String /* "boolean" */ | String /* "date" */ | String /* "datetime" */ | String /* "timeofday" */ */, label: String? /*= null*/, id: String? /*= null*/) -> Unit
    var insertRows: (rowIndex: Number, numberOrArray: dynamic /* Number | Array<Array<dynamic /* String | Number | Boolean | `T$6` */>> */) -> Unit
    var removeColumn: (columnIndex: Number) -> Unit
    var removeColumns: (columnIndex: Number, numberOfColumns: Number) -> Unit
    var removeRow: (rowIndex: Number) -> Unit
    var removeRows: (rowIndex: Number, numberOfColumns: Number) -> Unit
    var setCell: (rowIndex: Number, columnIndex: Number, value: Any? /*= null*/, formattedValue: String? /*= null*/, properties: Any? /*= null*/) -> Any
    var setColumnLabel: (columnIndex: Number, label: String) -> Unit
    var setColumnProperty: (columnIndex: Number, name: String, value: Any) -> Unit
    var setColumnProperties: (columnIndex: Number, properties: Any?) -> Unit
    var setFormattedValue: (rowIndex: Number, columnIndex: Number, formattedValue: String) -> Unit
    var setProperty: (rowIndex: Number, columnIndex: Number, name: String, value: Any) -> Unit
    var setProperties: (rowIndex: Number, columnIndex: Number, properties: Any?) -> Unit
    var setRowProperty: (rowIndex: Number, name: String, value: Any) -> Unit
    var setRowProperties: (rowIndex: Number, properties: Any?) -> Unit
    var setTableProperties: (properties: Any?) -> Unit
    var setValue: (rowIndex: Number, columnIndex: Number, value: String) -> Unit
    var sort: (sortColumns: dynamic /* Number | Array<Number> | `T$9` | Array<`T$9`> */) -> Unit
    var toJSON: () -> String
}

external interface `T$10` {
    var max: Any?
    var min: Any?
//    operator fun get(otherOptionKey: String): Any?
//    operator fun set(otherOptionKey: String, value: Any)
}

external interface `T$11` {
    var minValue: Any?
    var maxValue: Any?
    var ticks: Array<Number>?
    var title: String?
    var viewWindow: `T$10`?
//    operator fun get(otherOptionKey: String): Any?
//    operator fun set(otherOptionKey: String, value: Any)
}

external interface `T$12` {
    var position: String?
    var maxLines: Number?
//    operator fun get(otherOptionKey: String): Any?
//    operator fun set(otherOptionKey: String, value: Any)
}

external interface `T$13` {
    var groupWidth: String?
}

external interface `T$14` {
    var strokeWidth: Number?
    var fill: String?
}

external interface `T$15` {
    var fallingColor: `T$14`?
    var risingColor: `T$14`?
//    operator fun get(otherOptionKey: String): Any?
//    operator fun set(otherOptionKey: String, value: Any)
}

external interface `T$16` {
    var format: String?
    var word: String?
//    operator fun get(otherOptionKey: String): Any?
//    operator fun set(otherOptionKey: String, value: Any)
}

external interface GoogleChartOptions {
    var width: Number?
    var height: Number?
    var is3D: Boolean?
    var backgroundColor: String
    var title: String?
    var hAxis: `T$11`?
    var vAxis: `T$11`?
    var bubble: Any?
    var pieHole: Number?
    var redFrom: Number?
    var redTo: Number?
    var yellowFrom: Number?
    var yellowTo: Number?
    var minorTicks: Number?
    var legend: dynamic /* String | `T$12` */
    var curveType: String?
    var showTooltip: Boolean?
    var showInfoWindow: Boolean?
    var allowHtml: Boolean?
    var isStacked: dynamic /* String | Boolean */
    var minColor: String?
    var midColor: String?
    var maxColor: String?
    var headerHeight: Number?
    var fontColor: String?
    var showScale: Boolean?
    var bar: `T$13`?
    var candlestick: `T$15`?
    var wordtree: `T$16`?
//    operator fun get(otherOptionKey: String): Any?
//    operator fun set(otherOptionKey: String, value: Any)
}

external interface `T$17` {
    var chartWrapper: GoogleChartWrapper
    var controlWrapper: GoogleChartControl?
    var props: ReactGoogleChartProps
    var google: GoogleViz
    var eventArgs: Any
}

external interface ReactGoogleChartEvent {
    var eventName: dynamic /* String /* "ready" */ | String /* "error" */ | String /* "select" */ | String /* "animationfinish" */ | String /* "statechange" */ | String /* "ok" */ | String /* "cancel" */ | String /* "animationstart" */ */
    var callback: (eventCallbackArgs: `T$17`) -> Unit
}

external interface GoogleChartToolbarItem {
    var type: dynamic /* String /* "igoogle" */ | String /* "html" */ | String /* "csv" */ | String /* "htmlcode" */ */
    var datasource: String
    var gadget: String?
    var userPrefs: Json?
}

external interface DiffData {
    var old: Any
    var new: Any
}

external interface `T$19` {
    var chartEditor: GoogleChartEditor
    var chartWrapper: GoogleChartWrapper
    var google: GoogleViz
}

external interface Formatter {
    var column: Number
    var type: dynamic /* String /* "ArrowFormat" */ | String /* "BarFormat" */ | String /* "ColorFormat" */ | String /* "DateFormat" */ | String /* "NumberFormat" */ | String /* "PatternFormat" */ */
    var options: Any?
}

external interface SpreadSheetQueryParameters {
    var headers: Number
    var gid: dynamic /* String | Number */
    var sheet: String?
    var query: String?
    var access_token: String?
}

external interface `T$22` {
    var control: GoogleChartControl
    var controlProp: GoogleChartControlProp
}

external interface `T$23` {
    var renderControl: (filter: (__0: `T$22`) -> Boolean) -> Any
    var renderChart: () -> Any
    var renderToolbar: () -> Any
}


@JsModule("react-google-charts")
private external val react_google_charts: dynamic


@Suppress("UnsafeCastFromDynamic")
val chartComponent: RComponent<ReactGoogleChartProps, RState> = react_google_charts.Chart


fun RBuilder.chart(rows: dynamic) = createStyled(chartComponent) {
    val timeline1 = kotlinext.js.js {
        tooltipDateFormat = "dd.MM.yyyy HH:mm:ss"
    }
    val maxvalue = Date()
    val hAxis1 = kotlinext.js.js {
        format = "dd.MM.yyyy HH:mm:ss"
//        minValue = minvalue
        maxValue = maxvalue
    }
    attrs {
        width = "100%"
        height = "auto"
        chartType = "Timeline"
        loader = RBuilder().div { +"Loading Chart" }
        data = rows
        options = kotlinext.js.js {
            timeline = timeline1
            hAxis = hAxis1
        }
        chartPackages = arrayOf("corechart", "controls")
        controls = arrayOf(
            GoogleChartControlProp(
                controlType = "ChartRangeFilter",
                options = js(
                    """({
                    'filterColumnIndex': 1,
                    'ui': {
                        'chartType': 'LineChart',
                        'chartOptions': {
                            'width': '100%',
                            'height': 40,
                            'chartArea': {
                                width: '95%', // make sure this is the same for the chart and control so the axes align right
                                height: '100%'
                            },
                            hAxis: {gridlines: {color: 'black'}},
                            backgroundColor: {
                                stroke: 'balck',
                                strokeWidth: 3
                            },
                            lineWidth: 0,
                        },
                        // Display a single series that shows the closing value of the stock.
                        // Thus, this view has two columns: the date (axis) and the stock value (line series).
                        'chartView': {
                            'columns': [1, 1]
                        }
                    }
                    })"""
                ),
                controlPosition = "bottom"
            )
        )
    }
}

fun RBuilder.chart(
    type: String,
    titleLabel: String,
    xLabel: String? = null,
    xFormat: String? = null,
    yLabel: String? = null,
    yFormat: String? = null,
    maxY: Double? = null,
    legendPosition: String = "none",
    isLogScale: Boolean = false,
    columns: Array<Column>,
    rows: Array<Array<Any>>?,
    className: String? = null,
    handler: StyledHandler<ReactGoogleChartProps>? = null,
) = createStyled(chartComponent) {
    css { width = 100.pct; padding(12.px) }
    attrs {
        width = "100%"
        height = "auto"
        chartType = type
        data = if (rows != null)
            arrayOf(columns) + rows
        else
            arrayOf(columns)
        options = js {
            title = titleLabel
            hAxis = js {
                if (xLabel != null) title = xLabel
                if (xFormat != null) format = xFormat
            }
            vAxis = js {
                if (yLabel != null) title = yLabel
                minValue = 0
                if (maxY != null) {
                    viewWindowMode = "explicit"
                    viewWindow = js {
                        max = maxY
                        min = 0
                    }
                }
                if (yFormat != null) format = yFormat
                logScale = isLogScale
            }
            legend = js { position = legendPosition }
        }
//        chartPackages = arrayOf("corechart", "controls")
    }

    setStyledPropsAndRunHandler(className, handler)
}

external interface ReactGoogleChartProps : StyledProps {
    var height: dynamic /* String | Number */
    var width: dynamic /* String | Number */
    var graphID: String?
    var chartType: dynamic /* String /* "AnnotationChart" */ | String /* "AreaChart" */ | String /* "BarChart" */ | String /* "BubbleChart" */ | String /* "Calendar" */ | String /* "CandlestickChart" */ | String /* "ColumnChart" */ | String /* "ComboChart" */ | String /* "DiffChart" */ | String /* "DonutChart" */ | String /* "Gantt" */ | String /* "Gauge" */ | String /* "GeoChart" */ | String /* "Histogram" */ | String /* "LineChart" */ | String /* "Line" */ | String /* "Bar" */ | String /* "Map" */ | String /* "OrgChart" */ | String /* "PieChart" */ | String /* "Sankey" */ | String /* "ScatterChart" */ | String /* "SteppedAreaChart" */ | String /* "Table" */ | String /* "Timeline" */ | String /* "TreeMap" */ | String /* "WaterfallChart" */ | String /* "WordTree" */ */
    var diffdata: DiffData?
    var options: Any?
    var loader: ReactElement?
    var errorElement: Element?
    var data: dynamic /* Any? | Array<Any> */
    var rows: Array<Array<dynamic /* String | Number | Boolean | `T$6` */>>?
    var columns: Array<dynamic /* String | Column */>?
    var chartActions: Array<GoogleChartAction>?
    var chartEvents: Array<ReactGoogleChartEvent>?
    var chartVersion: dynamic /* String /* "current" */ | String /* "upcoming" */ */
    var chartPackages: Array<dynamic /* String /* "corechart" */ | String /* "charteditor" */ | String /* "controls" */ | String /* "calendar" */ | String /* "gantt" */ | String /* "gauge" */ | String /* "geochart" */ | String /* "map" */ | String /* "orgchart" */ | String /* "sankey" */ | String /* "table" */ | String /* "timeline" */ | String /* "treemap" */ | String /* "wordtree" */ */>?
    var chartLanguage: String?
    var mapsApiKey: String?
    var graph_id: String?
    var legendToggle: Boolean?
    var legend_toggle: Boolean?
    var getChartWrapper: ((chartWrapper: GoogleChartWrapper, google: GoogleViz) -> Unit)?
    var getChartEditor: ((args: `T$19`) -> Unit)?

    //    var className: String?
    var style: dynamic
    var formatters: Array<Formatter>?
    var spreadSheetUrl: String?
    var spreadSheetQueryParameters: SpreadSheetQueryParameters?
    var rootProps: Any?
    var controls: Array<GoogleChartControlProp>?
    var render: ((__0: `T$23`) -> Any)?
    var toolbarItems: Array<GoogleChartToolbarItem>?
    var toolbarID: String?
}

external interface GoogleChartDashboard {
    var draw: (data: GoogleDataTable) -> Unit
    var bind: (controlWrapperOrWrappers: dynamic /* GoogleChartControl | Array<GoogleChartControl> */, chartWrapper: GoogleChartWrapper) -> Unit
}

external interface GoogleChartControl {
    var getContainerId: () -> String

    //    var getOptions: () -> GoogleChartControlOptions
    var getState: () -> Any
    var setState: (state: Any) -> Unit

    //    var setOptions: (options: GoogleChartControlOptions) -> Unit
    var setControlType: (controlType: String) -> Unit
}

external interface ReactGoogleChartState {
    var loadingStatus: dynamic /* String /* "ready" */ | String /* "loading" */ | String /* "errored" */ */
    var google: GoogleViz?
}

external interface ReactGoogleChartContext {
    var data: dynamic /* Any? | Array<Any> */
    var rows: Array<Array<dynamic /* String | Number | Boolean | `T$6` */>>?
    var columns: Array<dynamic /* String | Column */>?
    var diffdata: DiffData?
    var options: Any?
    var legend_toggle: Boolean
    var legendToggle: Boolean
    var chartType: dynamic /* String /* "AnnotationChart" */ | String /* "AreaChart" */ | String /* "BarChart" */ | String /* "BubbleChart" */ | String /* "Calendar" */ | String /* "CandlestickChart" */ | String /* "ColumnChart" */ | String /* "ComboChart" */ | String /* "DiffChart" */ | String /* "DonutChart" */ | String /* "Gantt" */ | String /* "Gauge" */ | String /* "GeoChart" */ | String /* "Histogram" */ | String /* "LineChart" */ | String /* "Line" */ | String /* "Bar" */ | String /* "Map" */ | String /* "OrgChart" */ | String /* "PieChart" */ | String /* "Sankey" */ | String /* "ScatterChart" */ | String /* "SteppedAreaChart" */ | String /* "Table" */ | String /* "Timeline" */ | String /* "TreeMap" */ | String /* "WaterfallChart" */ | String /* "WordTree" */ */
    var formatters: Array<Formatter>?
    var spreadSheetUrl: String?
    var spreadSheetQueryParameters: SpreadSheetQueryParameters?
}
