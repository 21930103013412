package de.geomobile.frontend.features.companyProfile

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonSize
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardActions
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.form.MFormControlMargin
import com.ccfraser.muirwik.components.form.MFormControlVariant
import com.ccfraser.muirwik.components.form.margin
import com.ccfraser.muirwik.components.lab.alert.MAlertSeverity
import com.ccfraser.muirwik.components.lab.alert.MAlertVariant
import com.ccfraser.muirwik.components.lab.alert.mAlert
import com.ccfraser.muirwik.components.list.mList
import com.ccfraser.muirwik.components.list.mListItem
import com.ccfraser.muirwik.components.list.mListSubheader
import com.ccfraser.muirwik.components.menu.mMenuItem
import com.ccfraser.muirwik.components.styles.Breakpoint
import de.geomobile.common.permission.Permissions
import de.geomobile.common.portalmodels.UserDTO
import de.geomobile.common.portalmodels.small
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.spacer
import de.geomobile.frontend.utils.*
import de.geomobile.frontend.utils.grid.*
import kotlinext.js.clone
import kotlinx.browser.localStorage
import kotlinx.coroutines.*
import kotlinx.css.*
import kotlinx.html.InputType
import kotlinx.html.js.onChangeFunction
import kotlinx.serialization.json.Json
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.events.Event
import org.w3c.dom.get
import org.w3c.files.FileReader
import org.w3c.files.get
import portalmodels.CompanyProfileDTO
import portalmodels.CompanyProfileSIMSource
import portalmodels.SFTPEncryption
import react.*
import react.router.dom.route
import styled.css
import styled.styledDiv
import styled.styledInput

fun RBuilder.companyProfileSIM() = child(CompanyProfileSIM::class) {}

class CompanyProfileSIM : CComponent<CompanyProfileSIM.Props, CompanyProfileSIM.State>() {

    val fileReader = FileReader()
    private var loadExportTokenJob: Job = Job()

    interface Props : RProps

    class State(
        var currentProfile: CompanyProfileDTO?,
        var editedProfile: CompanyProfileDTO?,
        var deployedRows: List<Row> = emptyList(),
        var currentMQTT: String = "",
        var columns: List<Column>,
        var uploadErrors: MutableList<String> = mutableListOf(),
        var uploadList: MutableList<String> = mutableListOf(),
        var exportToken: String? = null,
        var sftpEditable: Boolean = false, //TODO: Implement SFTP function before enabling input
        var btnSaveEditTitle: String = ""
    ) : RState

    init {
        val columns = listOf(
            Column(name = "iccid", title = "ICC ID"),
            Column(name = "apn", title = "APN"),
            Column(name = "apn_user", title = "APN USER"),
            Column(name = "apn_auth", title = "APN AUTH")
        )
        state = State(
            currentProfile = null,
            editedProfile = null,
            columns = columns
        )
        fileReader.onload = { e ->
            readCSV(e)
        }
        fileReader.onloadend = {
            prepareUpload()
        }
    }

    data class Row(
        val id: String,
        val iccid: String,
        var apn: String,
        var apn_user: String,
        var apn_auth: String,
    )

    private fun loadExportToken() {
        loadExportTokenJob.cancel()
        loadExportTokenJob = launch {
            val token = withContext(Dispatchers.Default) {
                portalRestApi.getRaw("/companyprofile/exporttoken")
            }
            setState {
                this.exportToken = token
            }
        }
    }

    fun State.updateTables() {
        deployedRows = listToRows(editedProfile?.simSettings?.split("\r\n")!!.toMutableList())
    }

    fun listToRows(simList: MutableList<String>): List<Row> {
        var rows = mutableListOf<Row>()
        var counter = 0

        try {
            for (sim in simList) {
                var data = sim.split(",")
                rows.add(
                    Row(
                        id = counter++.toString(),
                        iccid = if (data[0].isEmpty()) "-" else data[0],
                        apn = if (data[2].isEmpty()) "-" else data[2],
                        apn_user = if (data[3].isEmpty()) "-" else data[3],
                        apn_auth = if (data[5].isEmpty()) "-" else data[5]
                    )
                )
            }
        } catch (ex: IndexOutOfBoundsException) {
        }
        return rows
    }

    fun State.resetEdits() {
        currentProfile?.let {
            editedProfile = it.copy()
            updateTables()
        }
    }

    fun readCSV(e: Event) {
        var fr: FileReader = e.target as FileReader
        var result: String = fr.result as String

        setState {
            var simData = result
                .trim()
                .split("\n", "\r\n").toMutableList()
            if (simData[0].lowercase().startsWith("iccid"))
                simData.removeAt(0)
            uploadList.addAll(simData)
            deployedRows = listToRows(uploadList)
        }
    }

    fun prepareUpload() {
        var errors = checkSIMData(state.uploadList)
        if (state.uploadList.groupingBy { it }.eachCount().any { it.value > 1 })
            errors.add("There are duplicates in the list.")
        setState {
            if (errors.isNotEmpty()) {
                uploadErrors = errors
            } else {
                editedProfile?.simSettings = uploadList.joinToString("\r\n")
            }
        }
    }

    fun checkSIMData(simData: List<String>): MutableList<String> {
        var results = mutableListOf<String>()
        var rowCounter = 0
        try {
            for (item in simData) {
                rowCounter++
                if (item.lowercase().startsWith("iccid") or item.isNullOrEmpty()) // ignore header and empty lines
                    continue
                val row = item.split(",")
                if(row.size != 6)
                    results.add("Error in row $rowCounter: Row has wrong number of columns.")

                if (row[0].isNullOrEmpty() || row[2].isNullOrEmpty()) {
                    results.add("Error in row $rowCounter: ICCID and APN are mandatory.")
                }
                if (!((row[3].isNullOrEmpty() == row[4].isNullOrEmpty()) and (row[3].isNullOrEmpty() == row[5].isNullOrEmpty()))
                ) {
                    results.add("Error in row $rowCounter: APN_USER, APN_PASS and APN_AUTH are optional, but mutually dependant.")
                }
                if (!"\\w{15,22}".toRegex().matches(row[0]))
                    results.add("Error in row $rowCounter: ICCID has wrong length (15-22 characters).")
                if (!checkLuhn(row[0]))
                    results.add("Error in row $rowCounter: ICCID Checksum is invalid.")
                if (row[1].length != 4 && row[1].isNotEmpty())
                    results.add("Error in row $rowCounter: SIM_PIN must be 4 digits.")
                if (!"\\d*".toRegex().matches(row[1]))
                    results.add("Error in row $rowCounter: Only numbers are allowed for SIM_PIN.")
//                if ("\\S+".toRegex().matches(row[2])) // Check if APN has whitespaces
//                    results.add("APN does not allow whitespaces.")
                if (!row[5].isNullOrEmpty() and !"PAP|CHAP|BOTH".toRegex(RegexOption.IGNORE_CASE).matches(row[5]))
                    results.add("Error in row $rowCounter: APN_AUTH must be PAP, CHAP or BOTH.")
            }
        } catch (ioobEx: IndexOutOfBoundsException) {
            results.add("CSV is malformed. Check row: $rowCounter")
        } catch (ex: Exception) {
            results.add("CSV is heavily malformed and could not be read")
            return results
        }

        return results
    }

    /**
     * Check if ICCID is valid.
     * Exclude the rightmost digit, then starting from the (new) rightmost digit
     * double every second digit and then take the digital sum of all non-doubled digits
     * and the result of the doubled digits. Modulo 10, the rest is the Checksum.
     *
     * Example:
     * 3728242
     *  3  7  2  8  2  4  2  | Remove rightmost digit.
     *  3  7  2  8  2  4     | Double every second digit starting from the right.
     *  6  7  2  16 2  8     | Now calc the digital sum: 6+7+2+1+6+2+8 = 32 % 10 = 2 which is the 2 we removed earlier -> valid.
     *
     */
    fun checkLuhn(number: String): Boolean {
        var check = 0
        var stripped = number
        while (stripped.last() == 'F')
            stripped = stripped.dropLast(1)

        for (i in stripped.length - 1 downTo 0 step 2)
            check += stripped[i] - '0'

        for (i in stripped.length - 2 downTo 0 step 2) {
            val tmp: Int = (stripped[i] - '0') * 2
            check += if (tmp > 9) tmp - 9 else tmp
        }

        return check % 10 == 0
    }

    fun saveProfile() {
        if (state.editedProfile?.simSource == CompanyProfileSIMSource.NONE) {
            setState {
                editedProfile?.simSettings = ""
            }
        }

        launch {
            var result = withContext(Dispatchers.Default) {
                portalRestApi.put(
                    path = "/companyprofile/profile",
                    body = Json.encodeToString(CompanyProfileDTO.serializer(), state.editedProfile!!),
                    serializer = CompanyProfileDTO.serializer()
                )
            }
            // With the previously cleared settings this removes the retained message from the MQTT broker.
            if (state.editedProfile?.simSource == CompanyProfileSIMSource.NONE)
                result = withContext(Dispatchers.Default) {
                    portalRestApi.get(
                        path = "/companyprofile/${state.editedProfile?.company?.name}/undeploySIM",
                        serializer = CompanyProfileDTO.serializer()
                    )
                }
            else
                publishCSV()

            setState {
                uploadList.clear()
                uploadErrors.clear()
                currentProfile = result
                resetEdits()
                updateTables()
            }
        }
    }

    fun resetProfile() {
        state.currentProfile?.company?.let {
            setState {
                resetEdits()
                updateTables()
            }
        }
    }


    fun publishCSV() {
        state.currentProfile?.company?.let {
            launch {
                withContext(Dispatchers.Default) {
                    portalRestApi.get(
                        path = "/companyprofile/${it.name}/publishSIM",
                        serializer = CompanyProfileDTO.serializer()
                    )
                }
            }
        }
    }

    fun getProfile() {
        launch {
            var companyId = localStorage["CompanyProfile"]

            val deferredProfile = if (isAuthorized(Permissions.CompanyProfileManagement.notRestrictedToCompany)) {
                companyId?.let {
                    async(Dispatchers.Default) {
                        portalRestApi.get("/companyprofile/${it}", CompanyProfileDTO.serializer())
                    }
                }
            } else {
                async {
                    var cid = withContext(Dispatchers.Default) {
                        portalRestApi.get("/user", UserDTO.serializer())
                    }.company.small.id
                    portalRestApi.get("/companyprofile/${cid}", CompanyProfileDTO.serializer())
                }
            }

            val myProfile = deferredProfile?.await()

            var currentMQTT = withContext(Dispatchers.Default) {
                portalRestApi.getRaw("/companyprofile/${myProfile?.company?.id}/currentSIM")
            }

            println(currentMQTT.take(50))

            myProfile?.let {
                setState {
                    this.currentMQTT = currentMQTT
                    currentProfile = myProfile
                    resetEdits()
                }
            }
        }
    }

    override fun componentDidMount() {
        loadExportToken()
        getProfile()
    }

    override fun RBuilder.render() {
        authorize(Permissions.CompanyProfileManagement.profileView) {
            route<RProps>("/companyprofile/network/sim") { _ ->
                spacer()
                mGridContainer2(direction = MGridDirection.column) {
                    mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                        if (isAuthorized(Permissions.CompanyProfileManagement.profileEdit))
                            simSettings()
                    }
                    if (isAuthorized(Permissions.CompanyProfileManagement.profileEdit))
                        if ((state.editedProfile?.simSource == CompanyProfileSIMSource.NONE) and (!state.currentProfile?.simSettings.isNullOrEmpty())) {
                            mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                                mAlert(
                                    variant = MAlertVariant.filled,
                                    severity = MAlertSeverity.warning,
                                    message = "Die SIM Datenverteilung wird beim Ändern deaktiviert."
                                )
                            }
                        }
                    if (state.uploadErrors.isNotEmpty() && isAuthorized(Permissions.CompanyProfileManagement.profileEdit))
                        mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                            simSettingsError()
                        }
                    if (state.editedProfile?.simSource == CompanyProfileSIMSource.REMOTE) {
                        mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                            mGridContainer2(direction = MGridDirection.row) {
                                mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                                    mCard {
                                        css(GlobalStyles.card)
                                        mCardContent {
                                            css(GlobalStyles.cardListItemContent)
                                            mListSubheader(heading = "SFTP Einstellungen")
                                            if (state.sftpEditable) {
                                                styledDiv {
                                                    css { padding(0.spacingUnits, 2.spacingUnits, 2.spacingUnits) }
                                                    sftpFields()
                                                }
                                            } else {
                                                mList {
                                                    attrs.dense = true
                                                    css { padding(0.spacingUnits) }
                                                    mListItem(
                                                        primaryText = "Benutzername",
                                                        secondaryText = state.currentProfile?.sftpProfile?.username
                                                            ?: "Nicht gesetzt"
                                                    ) { attrs.divider = false; attrs.button = false }
                                                    mListItem(
                                                        primaryText = "Passwort",
                                                        secondaryText = "**********"
                                                    ) { attrs.divider = false; attrs.button = false }
                                                    mListItem(
                                                        primaryText = "Server",
                                                        secondaryText = state.currentProfile?.sftpProfile?.server
                                                            ?: "Nicht gesetzt"
                                                    ) { attrs.divider = false; attrs.button = false }
                                                    mListItem(
                                                        primaryText = "Port",
                                                        secondaryText = state.currentProfile?.sftpProfile?.port.toString()
                                                    ) { attrs.divider = false; attrs.button = false }
                                                    mListItem(
                                                        primaryText = "Pfad",
                                                        secondaryText = state.currentProfile?.sftpProfile?.path
                                                            ?: "Nicht gesetzt"
                                                    ) { attrs.divider = false; attrs.button = false }
                                                    mListItem(
                                                        primaryText = "Verschlüsselung",
                                                        secondaryText = state.currentProfile?.sftpProfile?.encryption?.readableName
                                                            ?: "Nicht gesetzt"
                                                    ) { attrs.divider = false; attrs.button = false }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                        mGridContainer2(direction = MGridDirection.row) {
                            simListTables(state.deployedRows)
                        }
                    }
                }
            }
        }
    }

    private fun RBuilder.simSettings() =
        mCard {
            css(GlobalStyles.card)
            mCardContent {
                css(GlobalStyles.cardContent)
                mListSubheader(heading = "Einstellungen")
                mDivider { }
                simSettingsTable()
            }
            mDivider { }
            mCardActions {
                css { padding(2.spacingUnits) }
                simSettingsButtons()
            }
        }

    private fun RBuilder.simSettingsError() =
        styledDiv {
            for (error in state.uploadErrors) {
                mAlert(
                    variant = MAlertVariant.filled,
                    severity = MAlertSeverity.error,
                    message = error
                ) {
                    css {
                        marginBottom = 1.spacingUnits
                        ":last-child" { marginBottom = 0.spacingUnits }
                    }
                }
            }
        }

    private fun RBuilder.simSettingsButtons() =
        mGridContainer2(direction = MGridDirection.row) {
            if ((state.editedProfile?.simSource == CompanyProfileSIMSource.NONE) or (state.editedProfile?.simSource == CompanyProfileSIMSource.REMOTE)) {
                mGridItem2(
                    MGridBreakpoints2(MGridSize2.Auto)
                        .down(Breakpoint.xs, MGridSize2.Cells12)
                ) {
                    simButton(
                        caption = state.btnSaveEditTitle.ifEmpty { "Übernehmen" },
                        disabled = state.currentProfile?.let { cp ->
                            state.editedProfile?.let { ep ->
                                if (ep.simSource == CompanyProfileSIMSource.NONE)
                                    false
                                else cp == ep
                            } ?: true
                        } ?: true,
                        onClick = { saveProfile() }
                    )
                }
            }
            if (state.editedProfile?.simSource == CompanyProfileSIMSource.LOCAL) {
                mDivider { }
                mGridItem2(
                    MGridBreakpoints2(MGridSize2.Auto)
                        .down(Breakpoint.xs, MGridSize2.Cells12)
                ) {
                    simButton(
                        caption = "Hochladen & Veröffentlichen",
                        disabled =
                        (state.uploadList.isEmpty()) &&
                                (state.editedProfile == state.currentProfile) ||
                                (state.uploadErrors.isNotEmpty()),
                        onClick = {
                            saveProfile()
                        }
                    )
                }
            }
        }

    private fun RBuilder.simSettingsTable() =
        mGridContainer2(direction = MGridDirection.row, alignItems = MGridAlignItems.baseline) {
            mGridItem2(
                MGridBreakpoints2(
                    if ((state.editedProfile?.simSource == CompanyProfileSIMSource.NONE) and (!state.currentProfile?.simSettings.isNullOrEmpty())) MGridSize2.Cells12 else MGridSize2.Cells6
                )
                    .down(Breakpoint.sm, MGridSize2.Cells12)
            ) {
                styledDiv {
                    css { padding(2.spacingUnits) }
                    mTypography(text = "Quelle", variant = MTypographyVariant.caption) {
                        css { display = Display.block; marginBottom = 6.px }
                    }
                    mSelect(
                        variant = MFormControlVariant.outlined,
                        value = state.editedProfile?.simSource?.readableName
                            ?: CompanyProfileSIMSource.NONE.readableName,
                        disabled = false,
                        onChange = { event, _ ->
                            val newValue = event.targetValue as String
                            val simSource =
                                CompanyProfileSIMSource.fromReadable(newValue) ?: CompanyProfileSIMSource.NONE
                            setState {
                                editedProfile?.simSource = simSource
                            }
                        }
                    ) {
                        attrs.margin = MFormControlMargin.dense.toString()
                        attrs.fullWidth = true
                        for (mode in CompanyProfileSIMSource.values()) {
                            // TODO: Implement SFTP/Remote CSV loading and enable the option
                            mMenuItem(
                                primaryText = mode.readableName,
                                value = mode.readableName,
                                disabled = (mode == CompanyProfileSIMSource.REMOTE)
                            )
                        }
                    }
                    mTextField(
                        margin = MFormControlMargin.dense,
                        variant = MFormControlVariant.outlined,
//                        placeholder = "FTP Username",
                        label = "FTP Host",
                        value = state.editedProfile?.simBackupFTPHost ?: state.currentProfile?.simBackupFTPHost ?: "",
                        onChange = {
                            val text = it.targetInputValue
                            setState {
                                editedProfile?.simBackupFTPHost = text
                            }
                        }
                    ) {
                        attrs.fullWidth = true
                    }
                    mTextField(
                        margin = MFormControlMargin.dense,
                        variant = MFormControlVariant.outlined,
//                        placeholder = "FTP Username",
                        label = "FTP Port",
                        value = state.editedProfile?.simBackupFTPPort ?: state.currentProfile?.simBackupFTPPort ?: "",
                        onChange = {
                            val text = it.targetInputValue
                            setState {
                                editedProfile?.simBackupFTPPort = text
                            }
                        }
                    ) {
                        attrs.fullWidth = true
                    }
                    mTextField(
                        margin = MFormControlMargin.dense,
                        variant = MFormControlVariant.outlined,
//                        placeholder = "FTP Username",
                        label = "FTP Username",
                        value = state.editedProfile?.simBackupFTPUser ?: state.currentProfile?.simBackupFTPUser ?: "",
                        onChange = {
                            val text = it.targetInputValue
                            setState {
                                editedProfile?.simBackupFTPUser = text
                            }
                        }
                    ) {
                        attrs.fullWidth = true
                    }
                    mTextField(
                        margin = MFormControlMargin.dense,
                        variant = MFormControlVariant.outlined,
//                        placeholder = "FTP Username",
                        label = "FTP Password",
                        value = state.editedProfile?.simBackupFTPPassword ?: state.currentProfile?.simBackupFTPPassword
                        ?: "",
                        onChange = {
                            val text = it.targetInputValue
                            setState {
                                editedProfile?.simBackupFTPPassword = text
                            }
                        }
                    ) {
                        attrs.fullWidth = true
                    }
                    mTextField(
                        margin = MFormControlMargin.dense,
                        variant = MFormControlVariant.outlined,
//                        placeholder = "FTP Username",
                        label = "FTP Pfad",
                        value = state.editedProfile?.simBackupFTPPath ?: state.currentProfile?.simBackupFTPPath ?: "",
                        onChange = {
                            val text = it.targetInputValue
                            setState {
                                editedProfile?.simBackupFTPPath = text
                            }
                        }
                    ) {
                        attrs.fullWidth = true
                    }


                }
            }
            if (state.editedProfile?.simSource == CompanyProfileSIMSource.LOCAL) {
                mGridItem2(
                    MGridBreakpoints2(MGridSize2.Cells6)
                        .down(Breakpoint.sm, MGridSize2.Cells12)
                ) {
                    styledDiv {
                        css { padding(2.spacingUnits) }
                        styledDiv {
                            css {
                                padding(0.spacingUnits)
                                justifyItems = JustifyItems.center
                                display = Display.flex
                                alignItems = Align.flexStart
                            }
                            mTypography(text = "Liste", variant = MTypographyVariant.caption) {
                                css { display = Display.block; marginBottom = 1.spacingUnits }
                            }
                        }
                        styledInput {
                            css {
                                padding(2.spacingUnits)
                                width = 100.pct
                                border = "2px dashed #eeeeee"
                                borderRadius = 3.px
                            }
                            attrs.name = "upload"
                            attrs.type = InputType.file
                            attrs.accept = ".csv,.txt"
                            attrs.multiple = true
                            attrs.onChangeFunction = { event ->
                                var fileEvent = event.target as HTMLInputElement
                                var files = fileEvent.files
                                setState {
                                    uploadList.clear()
                                    uploadErrors.clear()
                                    files?.let {
                                        for (i in 0 until it.length) {
                                            fileReader.readAsText(it[i]!!)
                                        }
                                    }
                                }
                            }
                        }
                        mTypography(
                            text = "Die CSV sollte das folgende Format haben: " +
                                    "ICCID, SIM_PIN, APN, APN_USER, APN_PASS, APN_AUTH [PAP, CHAP, BOTH]. " +
                                    "Eine Header Zeile ist erlaubt, muss aber mit \"ICCID\" beginnen.",
                            variant = MTypographyVariant.caption,
                            color = MTypographyColor.textSecondary
                        ) { css { display = Display.block; marginTop = 2.spacingUnits } }
                    }
                }
            }
        }

    private fun RBuilder.sftpFields() =
        mGridContainer2(direction = MGridDirection.row) {
            mGridItem2(MGridBreakpoints2(MGridSize2.Cells6)) {
                mTextField(
                    label = "Benutzername",
                    value = state.editedProfile?.sftpProfile?.username ?: "",
                    variant = MFormControlVariant.outlined,
                    onChange = { event ->
                        val new = event.targetInputValue
                        setState {
                            state.editedProfile?.sftpProfile?.username = new
                        }
                    }
                ) {
                    attrs.margin = MFormControlMargin.dense
                    attrs.placeholder = "Benutzername"
                    attrs.fullWidth = true
                }
            }
            mGridItem2(MGridBreakpoints2(MGridSize2.Cells6)) {
                mTextField(
                    label = "Password",
                    value = state.editedProfile?.sftpProfile?.password?.replace(".".toRegex(), "*")
                        ?: "",
                    variant = MFormControlVariant.outlined,
                    onChange = { event ->
                        val new = event.targetInputValue
                        setState {
                            state.editedProfile?.sftpProfile?.password = new
                        }
                    }
                ) {
                    attrs.margin = MFormControlMargin.dense
                    attrs.placeholder = "Password"
                    attrs.fullWidth = true
                }
            }
            mGridItem2(MGridBreakpoints2(MGridSize2.Cells6)) {
                mTextField(
                    label = "Server",
                    value = state.editedProfile?.sftpProfile?.server ?: "",
                    variant = MFormControlVariant.outlined,
                    onChange = { event ->
                        val new = event.targetInputValue
                        setState {
                            state.editedProfile?.sftpProfile?.server = new
                        }
                    }
                ) {
                    attrs.margin = MFormControlMargin.dense
                    attrs.placeholder = "Server"
                    attrs.fullWidth = true
                }
            }
            mGridItem2(MGridBreakpoints2(MGridSize2.Cells6)) {
                mTextField(
                    label = "Port",
                    value = state.editedProfile?.sftpProfile?.port.toString(),
                    variant = MFormControlVariant.outlined,
                    onChange = { event ->
                        val new = event.targetInputValue
                        setState {
                            state.editedProfile?.sftpProfile?.port = new.toInt()
                        }
                    }
                ) {
                    attrs.margin = MFormControlMargin.dense
                    attrs.placeholder = "Port"
                    attrs.fullWidth = true
                }
            }
            mGridItem2(MGridBreakpoints2(MGridSize2.Cells8)) {
                mTextField(
                    label = "Pfad",
                    value = state.editedProfile?.sftpProfile?.path ?: "",
                    variant = MFormControlVariant.outlined,
                    onChange = { event ->
                        val new = event.targetInputValue
                        setState {
                            state.editedProfile?.sftpProfile?.server = new
                        }
                    }
                ) {
                    attrs.margin = MFormControlMargin.dense
                    attrs.placeholder = "Pfad"
                    attrs.fullWidth = true
                }
            }
            mGridItem2(MGridBreakpoints2(MGridSize2.Cells4)) {
                mSelect(
                    variant = MFormControlVariant.outlined,
                    value = state.editedProfile?.sftpProfile?.encryption?.readableName
                        ?: SFTPEncryption.AES128.readableName,
                    disabled = false,
                    onChange = { event, _ ->
                        val newValue = event.targetValue as String
                        val encryption = SFTPEncryption.fromReadable(newValue)
                            ?: SFTPEncryption.AES128
                        setState {
                            state.editedProfile?.sftpProfile?.encryption = encryption
                        }
                    }
                ) {
                    css { marginTop = 1.spacingUnits }

                    attrs.margin = MFormControlMargin.dense.toString()
                    attrs.fullWidth = true

                    // mMenuItem(primaryText = if(state.r2pCount == "-") "Bitte auswählen" else "R2P", value = "Bitte auswählen")
                    for (mode in SFTPEncryption.values()) {
                        mMenuItem(primaryText = mode.readableName, value = mode.readableName)
                    }
                }
            }
        }

    private val rowWrapper = rFunction<RowProps>("TableRowWrapper") { rowProps ->
        val newProps = clone(rowProps)
        tableRow(newProps)
    }

    private val listCellStyle = rFunction<RProps>("TableCell") { cellProps ->
        val newProps = clone(cellProps)

        newProps.asDynamic().style = kotlinext.js.js {
            paddingTop = 1.spacingUnits
            paddingBottom = 1.spacingUnits
        }

        tableCell(newProps)
    }

    private fun RBuilder.simListTables(rowData: List<Row>) =
        mGridItem2(
            MGridBreakpoints2(MGridSize2.Cells12)
                .down(Breakpoint.sm, MGridSize2.Cells12)
        ) {
            mCard {
                css(GlobalStyles.card)
                mCardContent {
                    css(GlobalStyles.cardListItemContent)
                    styledDiv {
                        css {
                            display = Display.flex
                            justifyContent = JustifyContent.spaceBetween
                            alignItems = Align.center
                        }
                        mListSubheader(heading = "CDA Liste (Aktuell Version ${state.currentProfile?.simBackupFTPVersion ?: "Keine"})") {
                            css { display = Display.block }
                        }
                        state.currentProfile?.let {
                            mLink(
                                hRefOptions = HRefOptions(
                                    href = "/../companyprofile/download/${it.company.id}/CDASim.csv?token=${state.exportToken}",
                                ),
                                underline = MLinkUnderline.none
                            ) {
                                css {
                                    visibility =
                                        if (!it.simSettings.isNullOrEmpty())
                                            Visibility.visible
                                        else
                                            Visibility.hidden
                                }
                                mButton(
                                    caption = "Download",
                                    size = MButtonSize.small,
                                    color = MColor.secondary,
                                    variant = MButtonVariant.outlined
                                ) {
                                    css { margin(0.spacingUnits, 1.spacingUnits) }
                                }
                            }
                        }
                    }
                    mDivider { }
                    grid(
                        columns = state.columns,
                        rows = rowData
                    ) {
                        table(
                            columnExtensions = listOf(
                                TableProps.ColumnExtension(
                                    columnName = "iccid",
                                    width = 260
                                ),
                                TableProps.ColumnExtension(
                                    columnName = "apn",
                                    width = 180
                                ),
                                TableProps.ColumnExtension(
                                    columnName = "apn_user",
                                    width = 260
                                ),
                                TableProps.ColumnExtension(
                                    columnName = "apn_auth",
                                    width = 180
                                )
                            ),
                            rowComponent = rowWrapper,
                            cellComponent = listCellStyle
                        )
                        tableHeaderRow()
                    }
                }
            }
        }

    private fun RBuilder.simButton(
        caption: String,
        disabled: Boolean = false,
        onClick: ((Event) -> Unit),
        visibility: Visibility = Visibility.visible,
    ) =
        mButton(
            caption = caption,
            variant = MButtonVariant.contained,
            color = MColor.secondary,
            disabled = disabled,
            onClick = onClick
        ) {
            attrs.disableElevation = true
            attrs.fullWidth = true
            css {
                css.visibility = visibility
            }
        }
}

