package de.geomobile.frontend.utils

import de.geomobile.common.portalmodels.TimestampStatus
import kotlinx.css.Color

val TimestampStatus.Status.color: Color
    get() = when (this) {
        TimestampStatus.Status.UNKNOWN,
        TimestampStatus.Status.OFFLINE,
        -> Color.gray

        TimestampStatus.Status.OVERDUE -> Color.yellow.darken(20)
        TimestampStatus.Status.ONLINE -> Color.green
    }

val TimestampStatus.Status.textColor: Color
    get() = when (this) {
        TimestampStatus.Status.UNKNOWN,
        TimestampStatus.Status.OFFLINE,
        -> Color.red.darken(20)

        TimestampStatus.Status.OVERDUE -> Color.yellow.darken(20)
        TimestampStatus.Status.ONLINE -> Color.green
    }