package de.geomobile.frontend.features.device.list

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonSize
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.input.mInput
import com.ccfraser.muirwik.components.styles.Breakpoint
import com.ccfraser.muirwik.components.styles.fade
import com.ccfraser.muirwik.components.styles.up
import de.geomobile.common.permission.Permissions
import de.geomobile.common.portalmodels.DeviceIdentifier
import de.geomobile.common.time.LocalDateTime
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.currentTheme
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.spacer
import de.geomobile.frontend.utils.*
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.css.*
import kotlinx.css.properties.Timing
import kotlinx.css.properties.Transition
import kotlinx.css.properties.ms
import org.w3c.dom.HTMLAnchorElement
import org.w3c.dom.get
import react.*
import styled.StyleSheet
import styled.css
import styled.styledDiv

private object ComponentStyles : StyleSheet("ComponentStyles") {
    val search by css {
        display = Display.flex
        alignItems = Align.center
        position = Position.relative
        borderRadius = currentTheme.shape.borderRadius.px
        backgroundColor = Color(fade(currentTheme.palette.common.white, 0.15))
        hover {
            backgroundColor = Color(fade(currentTheme.palette.common.white, 0.25))
        }
        margin = "2px"
        width = 100.pct
        media(currentTheme.breakpoints.up(Breakpoint.sm)) {
            marginRight = 1.spacingUnits
            width = LinearDimension.auto
        }
    }

    val searchIcon by css {
        width = 9.spacingUnits
        height = 100.pct
        position = Position.absolute
        pointerEvents = PointerEvents.none
        display = Display.flex
        alignItems = Align.center
        justifyContent = JustifyContent.center
        top = 0.px
    }

    val clearIcon by css {
        height = 100.pct
        position = Position.absolute
        padding(1.spacingUnits)
        right = 0.px
        top = 0.px
    }

    val searchInput by css {
        color = Color.inherit
        width = LinearDimension.auto
        input {
            paddingTop = 1.spacingUnits
            paddingRight = 1.spacingUnits
            paddingBottom = 1.spacingUnits
            paddingLeft = 10.spacingUnits
            transition += Transition("width", currentTheme.transitions.duration.standard.ms, Timing.easeInOut, 0.ms)
            width = 100.pct
            media(currentTheme.breakpoints.up(Breakpoint.sm)) {
                width = 120.px
                focus {
                    width = 200.px
                }
            }
        }
    }
}

fun RBuilder.deviceListPage(
    headless: Boolean,
    drawerMenu: ReactElement,
    onDeviceClick: (DeviceIdentifier) -> Unit,
) = child(DeviceListPage::class) {
    attrs.headless = headless
    attrs.drawerMenu = drawerMenu
    attrs.onDeviceClick = onDeviceClick
}

class DeviceListPage : CComponent<DeviceListPage.Props, DeviceListPage.State>() {

    private var downloadJob: Job = Job()

    interface Props : RProps {
        var headless: Boolean
        var drawerMenu: ReactElement
        var onDeviceClick: (DeviceIdentifier) -> Unit
    }

    class State(
        var searchInput: String = "",
        var editMode: Boolean = false,
        var selectedDevices: List<DeviceListItem> = listOf(),
        var showEditDialog: Boolean = false,
        var showEditConfigDialog: Boolean = false,
        var showDeleteConfirmationDialog: Boolean = false,
    ) : RState

    init {
        state = State()
    }

    private fun downloadExport() {
        downloadJob.cancel()
        downloadJob = launch {
            val response = portalRestApi.getResponse("/device/export")
            response.blob().then {
                val objectUrl = window["URL"].createObjectURL(it)

                val anchor = document.createElement("a") as HTMLAnchorElement
                document.body!!.appendChild(anchor)
                anchor.href = objectUrl
                anchor.download = "device_list_export_${LocalDateTime.now().format()}.csv"
                anchor.click()
                window["URL"].revokeObjectURL(objectUrl)
            }
        }
    }

    override fun RBuilder.render() {
        if (!props.headless) {
            mAppBar(position = MAppBarPosition.fixed) {
                css(GlobalStyles.appbar)
                mToolbar {
                    child(props.drawerMenu)
                    mToolbarTitle2("Geräte")

                    mHidden(
                        xsDown = true,
                        implementation = MHiddenImplementation.css
                    ) {
                        searchBar()
                    }

                    authorize(
                        Permissions.DeviceManagement.deviceListExport
                    ) {
                        mButton(
                            caption = "Exportieren",
                            variant = MButtonVariant.outlined,
                            color = MColor.inherit,
                            onClick = { downloadExport() }
                        ) {
                            attrs.endIcon = mIconNoTranslate(
                                iconName = "get_app",
                                addAsChild = false
                            ) {
                                css { fontSize = LinearDimension.inherit }
                            }
                        }
                    }
                }
                mHidden(
                    smUp = true,
                    implementation = MHiddenImplementation.css
                ) {
                    styledDiv {
                        css { padding(0.spacingUnits, 2.spacingUnits, 1.spacingUnits, 2.spacingUnits) }
                        searchBar()
                    }
                }
            }
        }
        spacer()
        if (!props.headless)
            styledDiv {
                css { padding(2.spacingUnits) }
                mGridContainer2(direction = MGridDirection.column) {
                    mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                        mCard {
                            key = "deviceListPageCard"

                            /** Permissions.DeviceManagement.audioConfig or .vehicleTypeWrite */
//                            authorize(Permissions.AdminPermissions.internalAccess) {
//                                styledDiv {
//                                    css { padding(1.spacingUnits) }
//                                    if (state.editMode) {
//                                        mButton(
//                                            caption = "Fertig",
//                                            onClick = {
//                                                setState {
//                                                    editMode = false
//                                                    selectedDevices = listOf()
//                                                }
//                                            })
//                                        mButton(
//                                            caption = "Bearbeiten",
//                                            disabled = state.selectedDevices.isEmpty(),
//                                            onClick = {
//                                                setState {
//                                                    showEditDialog = true
//                                                }
//                                            })
//                                    } else {
//                                        mButton(
//                                            caption = "Auswählen",
//                                            onClick = {
//                                                setState {
//                                                    editMode = true
//                                                    selectedDevices = listOf()
//                                                }
//                                            })
//                                    }
//                                }
//                            }

                            deviceList(
                                headless = props.headless,
                                onDeviceClick = props.onDeviceClick,
                                editMode = state.editMode,
                                searchInput = state.searchInput,
                                onSelectedDevicesChanged = { setState { this.selectedDevices = it } }
                            )
                        }
                    }
                }
            }
    }

    private fun RBuilder.searchBar() {
        styledDiv {
            css(ComponentStyles.search)
            styledDiv {
                css(ComponentStyles.searchIcon)
                mIconNoTranslate("search")
            }

            mInput(
                placeholder = "Suche...",
                disableUnderline = true,
                value = state.searchInput,
                onChange = {
                    val value = it.targetInputValue
                    setState {
                        searchInput = value
                    }
                }
            ) { css(ComponentStyles.searchInput) }

            mIconButtonNoTranslate(
                iconName = "clear",
                size = MButtonSize.small,
                color = MColor.inherit,
                onClick = {
                    setState { searchInput = "" }
                }
            ) {
                css {
                    +ComponentStyles.clearIcon
                    if (state.searchInput.isEmpty()) {
                        visibility = Visibility.hidden
                        pointerEvents = PointerEvents.none
                    }
                }
            }
        }
    }

    /* TODO: Clean up */
//    private fun RBuilder.dialogs() {
//        mDialog(
//            state.showEditDialog,
//            scroll = DialogScroll.paper,
//            onClose = { _, _ -> setState { showEditDialog = false } }
//        )
//        {
//
//            mDialogContent {
//                css { minWidth = 300.px }
//
//                mTable {
//                    mTableBody {
//                        tableRowChooseValue(
//                            label = "Fahrzeugtyp",
//                            loadValues = { getAllVehicleTypes() },
//                            loadSelectedValue = {
//                                state.selectedDevices
//                                    .map { it.vehicleType }
//                                    .distinct()
//                                    .singleOrNull()
//                            },
//                            selectionTitle = { (it as VehicleType).readableName },
//                            allowEdit = isAuthorized(Permissions.DeviceManagement.vehicleTypeWrite),
//                            loadApplyPath = { "/devices/vehicleType" },
//                            loadBody = { newValue ->
//                                Json.stringify(
//                                    ChangeDevicesVehicleTypeDTO.serializer(),
//                                    ChangeDevicesVehicleTypeDTO(
//                                        ids = state.selectedDevices.map { it.id },
//                                        vehicleType = newValue as VehicleType
//                                    )
//                                )
//                            }
//                        )
//                        rowChooseVolume(
//                            label = "Lautstärke",
//                            allowEdit = isAuthorized(Permissions.DeviceManagement.audioConfig),
//                            value = state.selectedDevices
//                                .map { it.volume }
//                                .distinct()
//                                .singleOrNull(),
//                            applyPath = "/devices/audio/volume",
//                            applyBody = {
//                                Json.stringify(
//                                    ChangeDevicesVolumeDTO.serializer(), ChangeDevicesVolumeDTO(
//                                        ids = state.selectedDevices.map { it.id },
//                                        volume = it
//                                    )
//                                )
//                            }
//                        )
//                        authorize(Permissions.AdminPermissions.internalAccess) {
//                            tableRowChooseValue(
//                                label = "Unternehmen",
//                                loadValues = { getAllCompanies() },
//                                loadSelectedValue = {
//                                    state.selectedDevices
//                                        .map { it.company }
//                                        .distinct()
//                                        .singleOrNull()
//                                },
//                                selectionTitle = { (it as CompanySmall).name },
//                                allowEdit = isAuthorized(Permissions.AdminPermissions.internalAccess),
//                                loadApplyPath = { "/devices/company" },
//                                loadBody = { newValue ->
//                                    Json.stringify(
//                                        ChangeDevicesCompanyDTO.serializer(),
//                                        ChangeDevicesCompanyDTO(
//                                            ids = state.selectedDevices.map { it.id },
//                                            companyId = (newValue as CompanySmall).id
//                                        )
//                                    )
//                                }
//                            )
//
//                            tableRowChooseValue(
//                                label = "Stage",
//                                loadValues = { getAllStages() },
//                                loadSelectedValue = {
//                                    state.selectedDevices
//                                        .map { it.stage }
//                                        .distinct()
//                                        .singleOrNull()
//                                },
//                                selectionTitle = { (it as Stage).name },
//                                allowEdit = isAuthorized(Permissions.AdminPermissions.internalAccess),
//                                loadApplyPath = { "/devices/stage" },
//                                loadBody = { newValue ->
//                                    Json.stringify(
//                                        ChangeDevicesStageDTO.serializer(),
//                                        ChangeDevicesStageDTO(
//                                            ids = state.selectedDevices.map { it.id },
//                                            stage = newValue as Stage
//                                        )
//                                    )
//                                }
//                            )
//
//                            tableRow(label = "Config") {
//                                mButton("Edit", onClick = { setState { showEditConfigDialog = true } })
//                            }
//
//
//                        }
//
//                        authorize(Permissions.DeviceManagement.delete) {
//                            tableRow(label = "Löschen") {
//                                mButton("Löschen", onClick = { setState { showDeleteConfirmationDialog = true } })
//                            }
//                        }
//                    }
//                }
//            }
//            mDialogActions {
//                mButton(
//                    "Fertig",
//                    color = MColor.primary,
//                    onClick = { setState { showEditDialog = false } }
//                )
//            }
//        }
//
//        authorize(Permissions.AdminPermissions.internalAccess) {
//            editMultiConfigDialog(
//                ids = state.selectedDevices.map { it.id },
//                open = state.showEditConfigDialog,
//                onComplete = { setState { showEditConfigDialog = false } }
//            )
//        }
//
//        authorize(Permissions.DeviceManagement.delete) {
//            mDialog(open = state.showDeleteConfirmationDialog) {
//                mDialogTitle(text = "Soll das Gerät wirklich gelöscht werden?")
//                mDialogActions {
//                    mButton(
//                        caption = "Abbrechen",
//                        onClick = { setState { showDeleteConfirmationDialog = false } }
//                    )
//                    mButton(
//                        caption = "Ja",
//                        onClick = {
//                            sendDelete()
//                            setState { showDeleteConfirmationDialog = false }
//                        }
//                    ) {
//                        css {
//                            color = Color.red
//                        }
//                    }
//                }
//            }
//        }
//    }

    // TODO: Clean up
//    private fun sendDelete() = launch {
//        withContext(Dispatchers.Default) {
//            portalRestApi.delete(
//                "/devices", body = Json.encodeToString(
//                    DeleteDevicesDTO.serializer(), DeleteDevicesDTO(
//                        ids = state.selectedDevices.map { it.id }
//                    )
//                )
//            )
//        }
//
//        setState { showEditDialog = false }
//    }
}
