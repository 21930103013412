package de.geomobile.frontend.utils

import de.geomobile.common.time.LocalDate
import de.geomobile.common.time.LocalDateTime
import kotlin.js.Date
import kotlin.math.roundToInt

fun ClosedRange<LocalDateTime>.diffString(): String {
    val seconds = (endInclusive.millis - start.millis) / 1000.0
    val minutes = seconds / 60.0
    val hours = seconds / (60.0 * 60.0)
    val days = seconds / (60.0 * 60.0 * 24.0)

    return when {
        days > 1.0 -> days.roundToInt().let { days -> "$days ${"Tag".pluralize(days, "Tagen")}" }
        hours > 1.0 -> hours.roundToInt().let { hours -> "$hours ${"Stunde".pluralize(hours, "Stunden")}" }
        minutes > 1.0 -> minutes.roundToInt().let { minutes -> "$minutes ${"Minute".pluralize(minutes, "Minuten")}" }
        else -> seconds.roundToInt().let { seconds -> "$seconds ${"Sekunde".pluralize(seconds, "Sekunden")}" }
    }
}

enum class TimeUnit(val order: Int)/*:Comparable<TimeUnit> */ {
    SECOND(1),
    MINUTE(2),
    HOUR(3),
    DAY(4),
    MONTH(5),
    YEAR(6)
}

external val Intl: dynamic

val formatCache = mutableMapOf<ClosedRange<TimeUnit>, dynamic>()

/* TODO: Clean up */
//val formatFull = Intl.DateTimeFormat(
//    "de-DE", jsObject<dynamic> {
//        second = "2-digit"
//        minute = "2-digit"
//        hour = "2-digit"
//        day = "2-digit"
//        month = "short"
//        year = "numeric"
//    })
//val formatFromMinute = Intl.DateTimeFormat(
//    "de-DE", jsObject<dynamic> {
//        minute = "2-digit"
//        hour = "2-digit"
//        day = "2-digit"
//        month = "short"
//        year = "numeric"
//    })
//val formatFromMonth = Intl.DateTimeFormat(
//    "de-DE", jsObject<dynamic> {
//        month = "short"
//        year = "numeric"
//    })

fun LocalDateTime.toText(from: TimeUnit = TimeUnit.SECOND, to: TimeUnit = TimeUnit.YEAR): String =
    jsDate.toText(from, to)

fun LocalDate.toText(from: TimeUnit = TimeUnit.DAY, to: TimeUnit = TimeUnit.YEAR): String =
    jsDate.toText(from, to)

@Suppress("UnsafeCastFromDynamic")
fun Date.toText(from: TimeUnit = TimeUnit.SECOND, to: TimeUnit = TimeUnit.YEAR): String {
    val unitRange = from..to
    return formatCache.getOrPut(unitRange) {
        Intl.DateTimeFormat(
            "de-DE", dateLocaleOptions {
                if (TimeUnit.SECOND in unitRange) second = "2-digit"
                if (TimeUnit.MINUTE in unitRange) minute = "2-digit"
                if (TimeUnit.HOUR in unitRange) hour = "2-digit"
                if (TimeUnit.DAY in unitRange) day = "2-digit"
                if (TimeUnit.MONTH in unitRange) month = "2-digit"
                if (TimeUnit.YEAR in unitRange) year = "numeric"
            })
    }
        .format(this)
}