package de.geomobile.frontend.auth

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.form.MFormControlVariant
import com.ccfraser.muirwik.components.form.mFormGroup
import com.ccfraser.muirwik.components.styles.Breakpoint
import com.ccfraser.muirwik.components.styles.up
import de.geomobile.common.portalmodels.RequestPasswordResetDTO
import de.geomobile.frontend.api.ApiException
import de.geomobile.frontend.api.isInvalidSessionError
import de.geomobile.frontend.currentTheme
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.utils.CComponent
import kotlinx.browser.document
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.css.*
import kotlinx.serialization.json.Json
import react.RBuilder
import react.RProps
import react.RState
import react.dom.div
import react.setState
import styled.css
import styled.styledDiv
import styled.styledImg

fun RBuilder.requestPasswordReset(
    email: String,
    goBack: () -> Unit,
) = child(RequestPasswordReset::class) {
    attrs.email = email
    attrs.goBack = goBack
}

class RequestPasswordReset(props: Props) : CComponent<RequestPasswordReset.Props, RequestPasswordReset.State>(props) {

    private var job: Job = Job()

    interface Props : RProps {
        var email: String
        var goBack: () -> Unit
    }

    class State(
        var email: String,
        var sending: Boolean = false,
        var send: Boolean = false,
        var error: String? = null,
    ) : RState

    init {
        state = State(email = props.email)
    }

    override fun RBuilder.render() {
        val sending = state.sending
        val send = state.send
        val error = state.error

        styledDiv {
            css {
                width = 100.pct
                display = Display.block
                marginLeft = 2.spacingUnits
                marginRight = 2.spacingUnits
                marginTop = 80.px
                media(currentTheme.breakpoints.up(Breakpoint.sm)) {
                    width = 400.px
                    marginLeft = LinearDimension.auto
                    marginRight = LinearDimension.auto
                }
            }

            document.onkeydown = {
                if (it.keyCode == 13) // User hits enter
                    trySend()
            }

            mPaper {
                css {
                    marginTop = 8.spacingUnits
                    marginBottom = 1.spacingUnits
                    display = Display.flex
                    flexDirection = FlexDirection.column
                    alignItems = Align.center
                    padding(2.spacingUnits, 3.spacingUnits, 3.spacingUnits)
                }
                styledImg(alt = "ivanto Logo", src = "/portal/static/ivanto_logo_rgb.png") {
                    css {
                        margin(horizontal = LinearDimension.auto)
                        height = 80.px
                        display = Display.block
                    }
                }
                mFormGroup {
                    css {
                        marginTop = 1.spacingUnits
                        width = 100.pct
                    }

                    mTextField(
                        label = "E-Mail",
                        name = "email",
                        id = "email",
                        variant = MFormControlVariant.outlined,
                        autoFocus = true,
                        disabled = sending || send,
                        onChange = {
                            val value = it.targetInputValue
                            setState { email = value }
                        }
                    ) {
                        attrs.autoComplete = "email"
                    }

                    if (sending) {
                        mCircularProgress {
                            css {
                                marginTop = 3.spacingUnits
                                marginLeft = LinearDimension.auto
                                marginRight = LinearDimension.auto
                            }
                        }
                    } else if (!send) {
                        mButton(
                            variant = MButtonVariant.contained,
                            color = MColor.primary,
                            caption = "Senden",
                            onClick = { trySend() }
                        ) {
                            attrs.fullWidth = true
                            attrs.disableElevation = true
                            css { marginTop = 3.spacingUnits }
                        }
                    }

                    mButton(
                        variant = if (send) MButtonVariant.contained else MButtonVariant.text,
                        color = if (send) MColor.primary else MColor.default,
                        caption = "Zurück zum Login",
                        onClick = { props.goBack() }
                    ) {
                        attrs.fullWidth = true

                        css { marginTop = 2.spacingUnits }
                    }

                    if (error != null) {
                        mSnackbar(
                            error,
                            open = true,
                            horizAnchor = MSnackbarHorizAnchor.center,
                            autoHideDuration = 4000
                        ) {
                            attrs.onClose = { _, _ -> setState { this.error = null } }
                            attrs.action = RBuilder().div {
                                mIconButtonNoTranslate(
                                    iconName = "close",
                                    onClick = { setState { this.error = null } },
                                    color = MColor.inherit
                                )
                            }
                        }
                    }
                }
            }
        }
    }

    private fun trySend() {
        job.cancel()
        job = launch {
            setState { sending = true }
            try {
                launch(Dispatchers.Default) {
                    portalRestApi.post(
                        path = "/requestpasswordreset",
                        body = Json.encodeToString(
                            RequestPasswordResetDTO.serializer(),
                            RequestPasswordResetDTO(email = state.email)
                        )
                    )
                }.join()

                setState {
                    this.sending = false
                    this.send = true
                }

            } catch (e: ApiException) {
                println("LOGIN ERROR: $e")

                val error = if (e.apiError.isInvalidSessionError)
                    "Invalid login credentials"
                else
                    e.message

                setState {
                    this.error = error
                    this.sending = false
                    this.send = false
                }
            }

        }
    }
}