package de.geomobile.frontend.api

import de.geomobile.common.errorhandling.ApiError
import de.geomobile.common.errorhandling.ApiHttpStatus

sealed class ApiException(message: String?, open val apiError: ApiError?) : RuntimeException(message) {

    object NetworkError : ApiException("Server nicht erreichbar", null)
    object TimeoutError : ApiException("Zeitüberschreitung bei der Kommunikation mit dem Server", null)

    class ServerError(override val apiError: ApiError?) : ApiException(apiError?.userMessage, apiError)
    class ClientError(override val apiError: ApiError) : ApiException(apiError.userMessage, apiError)

}

val ApiError?.isClientError: Boolean get() = this?.httpStatus != ApiHttpStatus.INTERNAL_SERVER_ERROR
val ApiError?.isInvalidSessionError: Boolean
    get() = when (this?.httpStatus) {
        ApiHttpStatus.UNAUTHORIZED/*, ApiHttpStatus.FORBIDDEN*/ -> true
        else -> false
    }

val ApiError.handleExplicitly: Boolean
    get() = isClientError && !isInvalidSessionError