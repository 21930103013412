package de.geomobile.common.utils

import kotlinx.serialization.Serializable
import kotlinx.serialization.json.*

/** NonStrict Json */
val nonStrictJson: Json = Json {
    isLenient = true;
    ignoreUnknownKeys = true;
    allowSpecialFloatingPointValues = true;
    useArrayPolymorphism = true;
}

/** Indented Json */
val indentedJson: Json = Json {
    prettyPrint = true;
    useArrayPolymorphism = true;
}

fun List<JsonObject>.merge(): JsonObject {
    var result = first()
    for (jsonObject in drop(1)) {
        result = merge(result, jsonObject)
    }
    return result
}

private fun merge(object1: JsonObject, object2: JsonObject): JsonObject {
    val keys = (object1.keys + object2.keys)
    val content = keys
        .associateWith {
            val element1 = object1[it]
            val element2 = object2[it]
            when {
                element1 == null || element2 == null -> JsonPrimitive("<<<multiple>>>")
                element1 == element2 -> element1
                element1 is JsonObject && element2 is JsonObject -> merge(element1, element2)
                else -> JsonPrimitive("<<<multiple>>>")
            }
        }

    return JsonObject(content)
}

fun List<JsonObject>.overlay(): JsonObject = fold(buildJsonObject(fun JsonObjectBuilder.() { })) { acc, json -> acc.overlayWith(json) }

fun JsonObject.overlayWith(other: JsonObject): JsonObject {
    val keys = (this.keys + other.keys)
    val content = keys
        .associateWith {
            val element1 = this[it]
            val element2 = other[it]
            when {
                element1 is JsonObject && element2 is JsonObject -> element1.overlayWith(element2)
                else -> element2 ?: element1!!
            }
        }

    return JsonObject(content)
}

@Serializable
data class JsonDiff(
    val defined: JsonElement? = null,
    val deleted: JsonElement? = null
) {
    @Serializable
    data class Delete(val key: String, val childes: List<Delete>?)
}

fun diff(object1: JsonObject, object2: JsonObject): JsonDiff {
    val keys = (object1.keys + object2.keys)

    val diffs = keys
        .mapNotNull {
            val element1 = object1[it]
            val element2 = object2[it]

            when {
                element2 == null -> it to JsonDiff(deleted = JsonNull)
                element1 is JsonObject && element2 is JsonObject -> it to diff(element1, element2)
                element2 is JsonPrimitive && element2.contentOrNull == "<<<multiple>>>" -> null
                else -> it to JsonDiff(defined = element2)
            }
        }
        .toMap()

    val defined = diffs
        .mapNotNull { (key, value) -> value.defined?.let { key to value.defined } }
        .toMap()
        .takeIf { it.isNotEmpty() }

    val deleted = diffs
        .mapNotNull { (key, value) -> value.deleted?.let { key to value.deleted } }
        .toMap()
        .takeIf { it.isNotEmpty() }

    return JsonDiff(
        defined = defined?.let { JsonObject(it) },
        deleted = deleted?.let { JsonObject(it) }
    )
}

fun applyDiff(jsonObject: JsonObject, diff: JsonDiff): JsonObject {
    val defined = diff.defined?.jsonObject.orEmpty()
    val deleted = diff.deleted?.jsonObject.orEmpty()

    val keys = (jsonObject.keys + defined.keys) - deleted.filter { it.value is JsonNull }.keys

    val result = keys.associateWith { key ->
        val orig = jsonObject[key]
        val new = defined[key]

        when {
            orig is JsonObject && new is JsonObject -> applyDiff(
                orig,
                JsonDiff(defined = new, deleted = deleted[key])
            )
            else -> new ?: orig!!
        }
    }

    return JsonObject(result)
}