package de.geomobile.frontend.features.device.detail

import de.geomobile.frontend.api.TopicSession
import de.geomobile.frontend.portalWebSocketApi
import react.*

fun RBuilder.deviceInfoComponent(
    id: Int,
    showCollapse: Boolean? = true,
    expanded: Boolean = false,
    onExpand: (() -> Unit)? = null,
    subscriptionTopic: String,
    emptyPlaceholder: InfoItem = InfoCard(title = null, content = InfoEmpty, showCollapse = false),
    mapper: (String) -> InfoItem,
) = child(DeviceInfoComponent::class) {
    key = id.toString()
    attrs.id = id
    attrs.showCollapse = showCollapse
    attrs.expanded = expanded
    attrs.onExpand = onExpand
    attrs.subscriptionTopic = subscriptionTopic
    attrs.emptyPlaceholder = emptyPlaceholder
    attrs.mapper = mapper
}

class DeviceInfoComponent : RComponent<DeviceInfoComponent.Props, DeviceInfoComponent.State>() {

    private var session: TopicSession? = null

    interface Props : RProps {
        var id: Int
        var showCollapse: Boolean?
        var expanded: Boolean
        var onExpand: (() -> Unit)?
        var subscriptionTopic: String
        var emptyPlaceholder: InfoItem
        var mapper: (String) -> InfoItem
    }

    class State(
        var item: InfoItem? = null,
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        session?.close()
        session = portalWebSocketApi.subscribe(props.subscriptionTopic, mapOf("id" to props.id.toString()))
        session?.connect {
            onmessage = {

                val item = props.mapper(it)
                setState {
                    this.item = item
                }
            }
        }
    }

    override fun componentWillUnmount() {
        session?.close()
    }

    override fun RBuilder.render() {
        val item = state.item
        infoItem(item = item ?: props.emptyPlaceholder, expanded = props.expanded, onExpand = props.onExpand)
    }
}