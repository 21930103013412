package de.geomobile.frontend.features.print

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.form.MFormControlMargin
import com.ccfraser.muirwik.components.form.MFormControlVariant
import com.ccfraser.muirwik.components.list.mListSubheader
import com.ccfraser.muirwik.components.menu.mMenu
import com.ccfraser.muirwik.components.menu.mMenuItem
import com.ccfraser.muirwik.components.styles.Breakpoint
import de.geomobile.common.portalmodels.AllPrintJobsDTO
import de.geomobile.common.portalmodels.PrintJobDTO
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.baseRestApi
import de.geomobile.frontend.portalWebSocketApi
import de.geomobile.frontend.spacer
import de.geomobile.frontend.utils.*
import kotlinx.browser.document
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import kotlinx.css.*
import kotlinx.serialization.json.Json
import org.w3c.dom.Node
import react.*
import styled.css
import styled.styledDiv

fun RBuilder.printJobList(drawerMenu: ReactElement) = child(PrintJobList::class) {
    attrs.drawerMenu = drawerMenu
}

class PrintJobList : CComponent<PrintJobList.Props, PrintJobList.State>() {

    val socket = portalWebSocketApi.subscribe("/print")

    interface Props : RProps {
        var drawerMenu: ReactElement
    }

    // Bestücker
    // 1 = Team EPS
    // 2 = ESD Electronics
    // 3 = Sonstiger

    class State : RState {
        var openPrintJobs: List<PrintJobDTO> = emptyList()
        var closedPrintJobs: List<PrintJobDTO> = emptyList()
        var menuAnchor: Node? = null
        var printJobToUpdate: PrintJobDTO? = null
        var printJobLimit: Boolean = true
//        var assemblyDirectPrintCode: String = ""
        var assemblyDirectPrintMaterialNumber: String = ""
        var assemblyDirectPrintRevision: String = "010"
        var assemblyDirectPrintProductionDate: String = ""
        var assemblyDirectPrintAssembler: String = "001"
        var assemblyDirectPrintOrderNumber: String = ""
        var assemblyDirectPrintLotNumber: String = ""
        var assemblyDirectPrintSerialNumberStart: String = ""
        var assemblyDirectPrintSerialNumberAmount: Int = 1
        var assemblyDirectPrintError: MutableMap<Int, Boolean> = mutableMapOf()
        var assemblyDirectPrintErrorText : String = ""
        var assemblyLabelCopyCode: String = ""
        var resellerSize: Boolean = false
        var resellerMaterialNumber: String = ""
        var resellerManufacturer: String = ""
        var resellerDate: String = ""
        var resellerProductName: String = ""
        var resellerAmount: Int = 1
        var resellerPrintError: MutableMap<Int, Boolean> = mutableMapOf()
        var resellerPrintErrorText : String = ""
    }

    init {
        state = State()
    }

    override fun componentDidMount() {
        socket.connect {
            onmessage = {
                val allPrintJobs = Json.decodeFromString(AllPrintJobsDTO.serializer(), it)

                setState {
                    this.openPrintJobs = allPrintJobs.openJobs
                    this.closedPrintJobs = allPrintJobs.closedJobs
                }
            }
        }
    }

    override fun componentWillUnmount() {
        socket.close()
    }

    override fun RBuilder.render() {

        val openJobs = state.openPrintJobs
        val closedJobs = state.closedPrintJobs

        document.onkeydown = {
            if (it.keyCode == 13) // User hits enter
                if(state.assemblyLabelCopyCode.length == 54 && state.assemblyLabelCopyCode.all { it.isDigit() }) {
                    createAssemblyPrint(state.assemblyLabelCopyCode)
                    setState {
                        assemblyDirectPrintErrorText = "Gedruckt!"
                        assemblyLabelCopyCode = ""
                    }
                }
        }

        mAppBar(position = MAppBarPosition.fixed) {
            css(GlobalStyles.appbar)
            mToolbar {
                child(props.drawerMenu)
                mToolbarTitle2("Druckaufträge")
            }
        }
        spacer()
        styledDiv {
            css { padding(2.spacingUnits) }
            mGridContainer2(direction = MGridDirection.column) {

                // Assembly direct print

                mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                    mGridContainer2(direction = MGridDirection.row) {
                        mListSubheader(heading = "Elektrische Baugruppe Direktdruck") {
                            css { padding(0.spacingUnits, 1.spacingUnits) }
                        }
                        mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                            css { padding = "0 8px !important" }
                            mTextField(
                                id = "materialNumber",
                                label = "Materialnummer",
                                helperText = "6 Ziffern",
                                value = state.assemblyDirectPrintMaterialNumber,
                                error = state.assemblyDirectPrintError[0] ?: false,
                                margin = MFormControlMargin.none,
                                onChange = { event ->
                                    val text = event.targetInputValue
                                    setState {
                                        assemblyDirectPrintError[0] = text.length != 6 || text.any { !it.isDigit() }
                                        assemblyDirectPrintMaterialNumber = text
                                        assemblyDirectPrintErrorText = ""
                                    }
                                }
                            ) {
                                css {
                                    width = 140.px
                                    paddingRight = 2.px
                                }
                            }

                            mTextField(
                                label = "Revision",
                                helperText = "3 Ziffern (010 = A0)",
                                value = state.assemblyDirectPrintRevision,
                                error = state.assemblyDirectPrintError[1] ?: false,
                                margin = MFormControlMargin.none,
                                onChange = { event ->
                                    val text = event.targetInputValue
                                    setState {
                                        assemblyDirectPrintError[1] = (text.length != 3) || text.any { !it.isDigit() }
                                        assemblyDirectPrintRevision = text
                                        assemblyDirectPrintErrorText = ""
                                    }
                                }
                            ) {
                                css {
                                    width = 120.px
                                    paddingRight = 2.px
                                }
                            }

                            mSelect(
                                value = state.assemblyDirectPrintAssembler,
                                name = "Bestücker",
                                id = "assembler",
                                fullWidth = true,
                                variant = MFormControlVariant.standard,
                                onChange = { event, _ ->
                                    val id = event.targetValue as String
                                    setState {
                                        assemblyDirectPrintAssembler = id
                                        assemblyDirectPrintErrorText = ""
                                    }
                                }
                            ) {
                                css {
                                    width = 200.px
                                    marginTop = 19.px
                                    marginRight = 2.px
                                }
                                attrs.required = true
                                attrs.margin = MFormControlMargin.dense.toString()

                                listOf("Team EPS", "ESD Electronics", "Sonstiger").forEachIndexed { index, company ->
                                    mMenuItem(primaryText = company, value = (index+1).toString().padStart(3, '0'))
                                }
                            }

                            mTextField(
                                label = "Fertigungsdatum",
                                helperText = "ddMMyy (optional)",
                                value = state.assemblyDirectPrintProductionDate,
                                error = state.assemblyDirectPrintError[3] ?: false,
                                margin = MFormControlMargin.none,
                                onChange = { event ->
                                    val text = event.targetInputValue
                                    setState {
                                        assemblyDirectPrintError[3] = (text.length != 6 && text.length != 0) || text.any { !it.isDigit() }
                                        assemblyDirectPrintProductionDate = text.ifBlank { "0" }
                                        assemblyDirectPrintErrorText = ""
                                    }
                                }
                            ) {
                                css {
                                    width = 140.px
                                    paddingRight = 2.px
                                }
                            }

                            mTextField(
                                label = "Auftragsnummer",
                                helperText = "20 Zeichen (optional)",
                                value = state.assemblyDirectPrintOrderNumber,
                                error = state.assemblyDirectPrintError[4] ?: false,
                                margin = MFormControlMargin.none,
                                onChange = { event ->
                                    val text = event.targetInputValue
                                    setState {
                                        assemblyDirectPrintError[4] = (text.length != 20 && text.isNotEmpty())
                                        assemblyDirectPrintOrderNumber = text.ifBlank { "0" }
                                        assemblyDirectPrintErrorText = ""
                                    }
                                }
                            ) {
                                css {
                                    width = 240.px
                                    paddingRight = 2.px
                                }
                            }

                            mTextField(
                                label = "Chargennummer",
                                helperText = "10 Ziffern (optional)",
                                value = state.assemblyDirectPrintLotNumber,
                                error = state.assemblyDirectPrintError[5] ?: false,
                                margin = MFormControlMargin.none,
                                onChange = { event ->
                                    val text = event.targetInputValue
                                    setState {
                                        assemblyDirectPrintError[5] = (text.length != 10 && text.length != 0) || text.any { !it.isDigit() }
                                        assemblyDirectPrintLotNumber = text.ifBlank { "0" }
                                        assemblyDirectPrintErrorText = ""
                                    }
                                }
                            ) {
                                css {
                                    width = 160.px
                                    paddingRight = 2.px
                                }
                            }

                            mTextField(
                                label = "SN Anfang",
                                helperText = "1-6 (leer=auto)",
                                value = state.assemblyDirectPrintSerialNumberStart,
                                error = state.assemblyDirectPrintError[6] ?: false,
                                margin = MFormControlMargin.none,
                                onChange = { event ->
                                    val text = event.targetInputValue
                                    setState {
                                        assemblyDirectPrintError[6] = text.length > 6 || text.any { !it.isDigit() }
                                        assemblyDirectPrintSerialNumberStart = text.ifBlank { "0" }
                                        assemblyDirectPrintErrorText = ""
                                    }
                                }
                            ) {
                                css {
                                    width = 90.px
                                    paddingRight = 2.px
                                }
                            }
                            mTextField(
                                label = "SN Anzahl",
                                helperText = "0 - 500",
                                value = state.assemblyDirectPrintSerialNumberAmount.toString(),
                                error = state.assemblyDirectPrintError[7] ?: false,
                                margin = MFormControlMargin.none,
                                onChange = { event ->
                                    val text = event.targetInputValue
                                    setState {
                                        assemblyDirectPrintError[7] =
                                            text.any { !it.isDigit() } || (text.toIntOrNull() ?: 501) > 500
                                        assemblyDirectPrintSerialNumberAmount = text.toIntOrNull() ?: 1
                                        assemblyDirectPrintErrorText = ""
                                    }
                                }
                            ) {
                                css {
                                    width = 90.px
                                    paddingRight = 2.px
                                }
                            }

                            mButton(
                                caption = "Drucken",
                                variant = MButtonVariant.contained,
                                color = MColor.primary,
                                onClick = {
                                    val start = state.assemblyDirectPrintSerialNumberStart.ifBlank { "0" }.toInt()
                                    val end = state.assemblyDirectPrintSerialNumberStart.ifBlank { "0" }.toInt() + state.assemblyDirectPrintSerialNumberAmount
                                    var hasError = false

                                    val code =
                                        state.assemblyDirectPrintMaterialNumber +
                                        state.assemblyDirectPrintRevision +
                                        state.assemblyDirectPrintAssembler +
                                        state.assemblyDirectPrintProductionDate.ifBlank { "0".repeat(6) } +
                                        state.assemblyDirectPrintOrderNumber.ifBlank { "0".repeat(20) } +
                                        state.assemblyDirectPrintLotNumber.ifBlank { "0".repeat(10) }

                                    if(start == 0) {
                                        createAssemblyPrintNoSerial(code, state.assemblyDirectPrintSerialNumberAmount.toString())
                                    } else {
                                        (start until end).forEach {
                                            val fullCode = code + it.toString().padStart(6, '0')
                                            if (code.length == 54 && code.all { it.isDigit() }) {
                                                createAssemblyPrint(fullCode)
                                            } else {
                                                hasError = true
                                            }

                                        }
                                    }
                                    setState {
                                        if (hasError)
                                            assemblyDirectPrintErrorText = "Fehler, es müssen 48 oder 54 Ziffern sein! Kein Druck!"
                                        else
                                            assemblyDirectPrintErrorText = "Gedruckt!"
                                    }

                                }
                            ) { css { margin(1.spacingUnits) } }
                        }
                        mListSubheader(heading = "Etiketten Kopie") {
                            css { padding(0.spacingUnits, 1.spacingUnits) }
                        }
                        mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                            css { padding = "0 8px !important" }
                            mTextField(
                                id = "labelCopy",
                                label = "",
                                helperText = "54 Ziffern",
                                value = state.assemblyLabelCopyCode,
                                error = state.assemblyDirectPrintError[8] ?: false,
                                margin = MFormControlMargin.none,
                                onChange = { event ->
                                    val text = event.targetInputValue
                                    setState {
                                        assemblyDirectPrintError[8] = text.length != 54 || text.any { !it.isDigit() }
                                        assemblyLabelCopyCode = text
                                        assemblyDirectPrintErrorText = ""
                                    }
                                }
                            ) {
                                css {
                                    width = 1182.px
                                    paddingRight = 2.px
                                }
                            }

                            mButton(
                                caption = "KOPIE",
                                variant = MButtonVariant.contained,
                                color = MColor.primary,
                                onClick = {
                                    var hasError = false
                                    val code = state.assemblyLabelCopyCode
                                    if(code.length == 54 && code.all { it.isDigit() }) {
                                        createAssemblyPrint(code)
                                    } else {
                                        hasError = true
                                    }

                                    setState {
                                        if (hasError)
                                            assemblyDirectPrintErrorText = "Fehler, es müssen 54 Ziffern sein! Kein Druck!"
                                        else
                                            assemblyDirectPrintErrorText = "Gedruckt!"
                                    }
                                }
                            ) { css { margin(1.spacingUnits) } }

                        }

                        mListSubheader(heading = "Reseller Etiketten Direktdruck") {
                            css { padding(0.spacingUnits, 1.spacingUnits) }
                        }
                        mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                            css { padding = "0 8px !important" }
                            mTextField(
                                id = "resellerMaterialNumber",
                                label = "Materialnummer",
                                helperText = "6 Ziffern",
                                value = state.resellerMaterialNumber,
                                error = state.resellerPrintError[0] ?: false,
                                margin = MFormControlMargin.none,
                                onChange = { event ->
                                    val text = event.targetInputValue
                                    setState {
                                        resellerPrintError[0] = text.length != 6 || text.any { !it.isDigit() }
                                        resellerMaterialNumber = text
                                        resellerPrintErrorText = ""
                                    }
                                }
                            ) {
                                css {
                                    width = 140.px
                                    paddingRight = 2.px
                                }
                            }

                            mTextField(
                                id = "resellerManufacturer",
                                label = "Hersteller",
                                helperText = "Freitext",
                                value = state.resellerManufacturer,
                                error = state.resellerPrintError[1] ?: false,
                                margin = MFormControlMargin.none,
                                onChange = { event ->
                                    val text = event.targetInputValue
                                    setState {
//                                        resellerPrintError[1] = text.length != 6 || text.any { !it.isDigit() }
                                        resellerManufacturer = text
//                                        resellerPrintErrorText = ""
                                    }
                                }
                            ) {
                                css {
                                    width = 140.px
                                    paddingRight = 2.px
                                }
                            }

                            mTextField(
                                label = "Fertigungsdatum",
                                helperText = "ddMMyy (optional)",
                                value = state.resellerDate,
                                error = state.resellerPrintError[2] ?: false,
                                margin = MFormControlMargin.none,
                                onChange = { event ->
                                    val text = event.targetInputValue
                                    setState {
                                        resellerPrintError[2] = (text.length != 8 && text.length != 0) || text.any { !it.isDigit() }
                                        resellerDate = text.ifBlank { "0" }
                                        resellerPrintErrorText = ""
                                    }
                                }
                            ) {
                                css {
                                    width = 140.px
                                    paddingRight = 2.px
                                }
                            }

                            mTextField(
                                id = "resellerProductName",
                                label = "Produktname",
                                helperText = "12 Zeichen",
                                value = state.resellerProductName,
                                error = state.resellerPrintError[3] ?: false,
                                margin = MFormControlMargin.none,
                                onChange = { event ->
                                    val text = event.targetInputValue
                                    setState {
                                        resellerPrintError[3] = text.length >= 12
                                        resellerProductName = text
                                        resellerPrintErrorText = ""
                                    }
                                }
                            ) {
                                css {
                                    width = 140.px
                                    paddingRight = 2.px
                                }
                            }

                            mTextField(
                                label = "Anzahl",
                                helperText = "0 - 500",
                                value = state.resellerAmount.toString(),
                                error = state.resellerPrintError[4] ?: false,
                                margin = MFormControlMargin.none,
                                onChange = { event ->
                                    val text = event.targetInputValue
                                    setState {
                                        resellerPrintError[4] =
                                            text.any { !it.isDigit() } || (text.toIntOrNull() ?: 501) > 500
                                        resellerAmount = text.toIntOrNull() ?: 1
                                        resellerPrintErrorText = ""
                                    }
                                }
                            ) {
                                css {
                                    width = 90.px
                                    paddingRight = 2.px
                                }
                            }

                            mCheckboxWithLabel(
                                label = "Groß",
                                checked = state.resellerSize,
                                onChange = { _, checked ->
                                    setState {
                                        resellerSize = checked
                                    }
                                }
                            ) {
                                css {
                                    marginTop = 19.px
                                    marginRight = 2.px
                                }
                            }

                            mButton(
                                caption = "DRUCKEN",
                                variant = MButtonVariant.contained,
                                color = MColor.primary,
                                onClick = {
                                    createResellerPrint()

                                    setState {
                                        resellerPrintErrorText = "Gedruckt!"
                                    }
                                }
                            ) { css { margin(1.spacingUnits) } }

                        }

                        mTypography{
                            css { color = if(state.resellerPrintErrorText == "Gedruckt!") Color.green else Color.red }
                            +state.resellerPrintErrorText
                        }
                        mTypography{
                            css { color = if(state.assemblyDirectPrintErrorText == "Gedruckt!") Color.green else Color.red }
                            +state.assemblyDirectPrintErrorText
                        }
                    }
                }

                // open
                mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                    mGridContainer2(direction = MGridDirection.row) {
                        mListSubheader(heading = "Offen") {
                            css { padding(0.spacingUnits, 1.spacingUnits) }
                        }
                        if (openJobs.none()) {
                            mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                                mTypography(
                                    text = "Keine offenen Aufträge gefunden.", variant = MTypographyVariant.caption
                                )
                            }
                        }
                    }
                    for (job in openJobs) {
                        mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                            printJobRow(job)
                        }
                    }
                }

                // closed
                mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                    mGridContainer2(direction = MGridDirection.row) {
                        mListSubheader(heading = "Abgeschlossen") {
                            css { padding(0.spacingUnits, 1.spacingUnits) }
                            mSwitchWithLabel(
                                label = "Nur die letzten 14 Jobs",
                                checked = state.printJobLimit,
                                onChange = { _, checked ->
                                    setState {
                                        printJobLimit = checked
                                    }
                                }
                            ) { css { marginLeft = 1.spacingUnits } }
                        }
                        if (closedJobs.none()) {
                            mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                                mTypography(
                                    text = "Keine abgeschlossenen Aufträge gefunden.",
                                    variant = MTypographyVariant.caption
                                )
                            }
                        }
                        for (job in closedJobs.take(if (state.printJobLimit) 14 else closedJobs.size)) {
                            mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                                printJobRow(job)
                            }
                        }
                    }
                }

                mMenu(
                    open = state.menuAnchor != null,
                    anchorElement = state.menuAnchor,
                    onClose = { _, _ ->
                        setState {
                            menuAnchor = null
                        }
                    }) {
                    mMenuItem(
                        primaryText = if (state.printJobToUpdate?.printedAt != null) "Erneut Drucken" else "Manuell Schließen",
                        onClick = {
                            updatePrintJob(
                                state.printJobToUpdate!!.serialNumber,
                                state.printJobToUpdate?.printedAt == null
                            )
                            setState {
                                menuAnchor = null
                            }
                        })
                }
            }
        }
    }

    private fun RBuilder.printJobRow(printJob: PrintJobDTO) {
        mCard {
            css {
                if (printJob.printedAt != null)
                    backgroundColor = Color.lightGrey
            }
            css { position = Position.relative }
            mCardContent {
                mGridContainer {
                    mGridItem2(
                        MGridBreakpoints2(MGridSize2.Cells3)
                            .down(Breakpoint.xs, MGridSize2.Cells12)
                    ) {
                        mTypography(text = "Seriennummer", variant = MTypographyVariant.caption)
                        mTypography(
                            text = printJob.serialNumber,
                            variant = MTypographyVariant.subtitle2
                        )
                    }
                    mGridItem2(
                        MGridBreakpoints2(MGridSize2.Cells3)
                            .down(Breakpoint.xs, MGridSize2.Cells12)
                    ) {
                        mTypography(text = "Materialnummer", variant = MTypographyVariant.caption)
                        mTypography(
                            text = printJob.materialNumber.toString().padStart(6, '0'),
                            variant = MTypographyVariant.subtitle2
                        )
                    }
                    mGridItem2(
                        MGridBreakpoints2(MGridSize2.Cells3)
                            .down(Breakpoint.xs, MGridSize2.Cells12)
                    ) {
                        mTypography(text = "Herstellungsdatum", variant = MTypographyVariant.caption)
                        mTypography(
                            text = printJob.manufacturingDate.toText(from = TimeUnit.MONTH),
                            variant = MTypographyVariant.subtitle2
                        )
                    }

                    val printedAt = printJob.printedAt
                    if (printedAt != null) {
                        mGridItem2(
                            MGridBreakpoints2(MGridSize2.Cells3)
                                .down(Breakpoint.xs, MGridSize2.Cells12)
                        ) {
                            mTypography(text = "Gedruckt am", variant = MTypographyVariant.caption)
                            mTypography(
                                text = printedAt.toText(),
                                variant = MTypographyVariant.subtitle2
                            )
                        }
                    }
                }

                mIconButtonNoTranslate(
                    iconName = "more_vert",
                    onClick = {
                        val anchor = it.currentTarget.asDynamic()
                        setState {
                            menuAnchor = anchor
                            printJobToUpdate = printJob
                        }
                    }
                ) {
                    css {
                        position = Position.absolute
                        right = 0.spacingUnits
                        top = 0.spacingUnits
                    }
                }
            }
        }
    }

    private fun updatePrintJob(serialNumber: String, close: Boolean) {
        launch(Dispatchers.Default) {
            if (close)
                closePrintJob(serialNumber)
            else
                openPrintJob(serialNumber)
        }
    }

    private fun createAssemblyPrint(qrcode: String) {
        launch(Dispatchers.Default) {
            createAssemblyPrintJob(qrcode)
        }
    }

    private fun createResellerPrint() {
        launch(Dispatchers.Default) {
            createResellerPrintJob(
                state.resellerMaterialNumber,
                state.resellerManufacturer,
                state.resellerDate,
                state.resellerProductName,
                state.resellerAmount,
                state.resellerSize
            )
            setState {
                resellerMaterialNumber = ""
                resellerManufacturer = ""
                resellerDate = ""
                resellerProductName = ""
                resellerAmount = 1
                resellerSize = false
            }
        }
    }

    private fun createAssemblyPrintNoSerial(qrcode: String, amount: String) {
        launch(Dispatchers.Default) {
            createAssemblyPrintJobNoSerial(qrcode, amount)
        }
    }

    private suspend fun openPrintJob(serialNumber: String) = baseRestApi.put(path = "/print/$serialNumber/open")
    private suspend fun closePrintJob(serialNumber: String) = baseRestApi.put(path = "/print/$serialNumber/close")
    private suspend fun createAssemblyPrintJob(qrcode: String) = baseRestApi.put(path = "/print/assembly/create/$qrcode")
    private suspend fun createAssemblyPrintJobNoSerial(qrcode: String, amount: String) = baseRestApi.put(path = "/print/assembly/create/noserial/$qrcode/$amount")

    private suspend fun createResellerPrintJob(
        materialNumber: String,
        manufacturer: String,
        date: String,
        productName: String,
        amount: Int,
        isBig: Boolean
    ) = baseRestApi.put(path = "/print/resell/open?materialNumber=$materialNumber&printDate=$date&manufacturer=$manufacturer&productName=$productName&big=$isBig&amount=$amount")
}