package de.geomobile.frontend.features.admin.users

import com.ccfraser.muirwik.components.mSelect
import com.ccfraser.muirwik.components.menu.mMenuItem
import com.ccfraser.muirwik.components.spacingUnits
import com.ccfraser.muirwik.components.table.MTableCellVariant
import com.ccfraser.muirwik.components.table.mTableCell
import com.ccfraser.muirwik.components.targetValue
import de.geomobile.common.portalmodels.Company
import de.geomobile.common.portalmodels.UserDTO
import de.geomobile.common.time.LocalDateTime
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.utils.CComponent
import de.geomobile.frontend.utils.grid.Filter
import de.geomobile.frontend.utils.grid.TableFilterRowProps
import de.geomobile.frontend.utils.grid.tableFilterRowCell
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.padding
import kotlinx.css.px
import kotlinx.serialization.builtins.ListSerializer
import react.RBuilder
import react.RState
import react.setState
import styled.css

class AdminUserListFilterCell : CComponent<TableFilterRowProps.CellProps, AdminUserListFilterCell.State>() {

    class State(
        var companies: List<Company> = emptyList(),
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        if (props.column.name == "company") {
            launch {
                val companies = withContext(Dispatchers.Default) {
                    portalRestApi.get("/admin/companies", serializer = ListSerializer(Company.serializer()))
                }

                setState { this.companies = companies }
            }
        }
    }

    override fun RBuilder.render() {
        when (props.column.name) {
            "state" -> filterCellSelect(
                columnName = props.column.name,
                selection = props.filter?.value,
                values = listOf(
                    UserDTO.State.Created,
                    UserDTO.State.Invited(LocalDateTime.now()),
                    UserDTO.State.Active,
                    UserDTO.State.Blocked
                ).map { it.toText(true) }

            )

            "company" -> filterCellSelect(
                columnName = props.column.name,
                selection = props.filter?.value,
                values = state.companies.map { it.name }
            )

            else -> tableFilterRowCell(props)
        }
    }

    private fun RBuilder.filterCellSelect(
        columnName: String,
        operation: String? = null,
        selection: String?,
        values: List<String>,
        itemRenderer: RBuilder.(value: String) -> Unit = { value -> mMenuItem(primaryText = value, value = value) },
    ) {
        mTableCell(variant = MTableCellVariant.head) {
            css { padding(horizontal = 1.spacingUnits) }
            mSelect(
                value = selection ?: "<<all>>",
                fullWidth = true,
                onChange = { event, _ ->
                    props.onFilter(
                        (event.targetValue as String)
                            .takeIf { it != "<<all>>" }
                            ?.let { Filter(columnName = columnName, value = it, operation = operation) }
                    )
                }
            ) {
                mMenuItem(primaryText = "Alle", value = "<<all>>")
                for (value in values) {
                    itemRenderer(value)
                }
            }
        }
    }
}