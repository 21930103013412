package de.geomobile.frontend.features.vehicleProfile

import com.ccfraser.muirwik.components.MColor
import com.ccfraser.muirwik.components.MGridDirection
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.spacingUnits
import de.geomobile.common.feature.Features
import de.geomobile.common.permission.Permissions
import de.geomobile.common.portalmodels.UserDTO
import de.geomobile.common.portalmodels.VehicleProfileDTO
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.spacer
import de.geomobile.frontend.utils.*
import de.geomobile.frontend.utils.grid.*
import kotlinx.browser.localStorage
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.async
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.marginLeft
import kotlinx.serialization.builtins.ListSerializer
import org.w3c.dom.get
import org.w3c.dom.set
import portalmodels.FmsProfileDTO
import react.*
import react.dom.tr
import styled.css

fun RBuilder.vehicleProfilesList(
    profileSelected: (profileId: String) -> Unit,
    createProfile: (duplicateId: String?) -> Unit,
    persistenceId: String = "",
) = child(VehicleProfilesList::class) {
    attrs.profileSelected = profileSelected
    attrs.createProfile = createProfile
    attrs.persistenceId = persistenceId

    key = persistenceId
}

class VehicleProfilesList(props: Props) : CComponent<VehicleProfilesList.Props, VehicleProfilesList.State>(props) {

    interface Props : RProps {
        var profileSelected: (profileId: String) -> Unit
        var createProfile: (duplicateId: String?) -> Unit
        var persistenceId: String
    }

    class State(
        var rows: List<Row> = emptyList(),
        var columns: List<Column>,
        var hiddenColumns: List<String>,
        var columnOrder: List<String>,
        var pageSize: Int,
        var page: Int = 0,

        var filters: Array<Filter> = emptyArray(),
        var expandedGroups: Array<String> = emptyArray(),
        var grouping: Array<Grouping> = emptyArray(),
        var showFilter: Boolean = false,
        var duplicate: Boolean = false,
    ) : RState

    init {
        val columns = listOf(
            Column(name = "name", title = "Name"),
            Column(name = "description", title = "Beschreibung"),
            Column(name = "vehicleModel", title = "Fahrzeugmodell"),
            Column(name = "vehicleType", title = "Fahrzeugtyp"),
            Column(name = "floorType", title = "Flurtechnik"),
            Column(name = "seatingCapacity", title = "Sitzplätze"),
            Column(name = "standingCapacity", title = "Stehplätze"),
            Column(name = "fmsProfile", title = "FMS Profil", feature = Features.VehicleProfileFeatures.fmsProfiles),
            Column(name = "elaAudio", title = "ELA Lautstärke", feature = Features.CompanyFeatures.ela),
            Column(name = "ramp", title = "Rampe"),
            Column(name = "out1", title = "Ausgang 1"),
            Column(name = "out2", title = "Ausgang 2"),
            Column(name = "out3", title = "Ausgang 3"),
            Column(name = "out4", title = "Ausgang 4"),
            Column(name = "out5", title = "Ausgang 5"),
            Column(name = "in1", title = "Eingang 1"),
            Column(name = "in2", title = "Eingang 2"),
            Column(name = "in3", title = "Eingang 3"),
            Column(name = "in4", title = "Eingang 4"),
            Column(name = "speaker1", title = "Lautsprecher 1"),
            Column(name = "speaker2", title = "Lautsprecher 2"),
            Column(name = "speakerVolume", title = "Ausgangslautstärke"),
            Column(name = "can", title = "CAN"),
            Column(name = "ibis", title = "IBIS")
        )
            .filter { isAuthorized(*it.permissions.toTypedArray()) }
            .filter {
                if (it.feature == null) true
                else hasFeature(it.feature)
            }

        state = State(
            columns = columns,
            hiddenColumns = localStorage["vehicleProfileListHiddenColumns${props.persistenceId}"]
                ?.split(";")
                .orEmpty()
                .let {
                    val original = columns.map { it.name }
                    it.intersect(original).toList()
                },
            columnOrder =
            (localStorage["vehicleProfileListColumnOrder${props.persistenceId}"]
                ?.split(";")
                ?: columns.map { it.name }).let {
                val original = columns.map { it.name }
                val newColumns = original - it
                it.intersect(original).toList() + newColumns
            },
            pageSize = localStorage["vehicleProfileListPageSize${props.persistenceId}"]
                ?.toIntOrNull() ?: 20,
            // showFilter = localStorage["vehicleProfileListToggledFilter${props.persistenceId}"].toBoolean()
        )
    }

    data class Row(
        val id: String,
        val name: String,
        var description: String,
        var vehicleModel: String,
        var vehicleType: String,
        var floorType: String,
        var seatingCapacity: String,
        var standingCapacity: String,
        var ramp: String,
        var out1: String,
        var out2: String,
        var out3: String,
        var out4: String,
        var out5: String,
        var in1: String,
        var in2: String,
        var in3: String,
        var in4: String,
        var speaker1: String,
        var speaker2: String,
        var can: String,
        var ibis: String,
        var fmsProfile: String,
        var elaAudioVolume: String,
    )

    override fun componentDidMount() {
        launch {
            val profiles = withContext(Dispatchers.Default) {
                portalRestApi.get("/vehicleprofiles", ListSerializer(VehicleProfileDTO.serializer()))
            }

            // get company from local storage (if authorized and not null) otherwise use own users company
            val companyId =
                if (isAuthorized(Permissions.VehicleProfileManagement.notRestrictedToCompany) && localStorage["VehicleProfileCompany"] != null) {
                    localStorage["VehicleProfileCompany"]
                } else {
                    withContext(Dispatchers.Default) {
                        portalRestApi.get("/user", UserDTO.serializer())
                    }.company.id
                }

            //only show profiles of selected company
            val filteredProfiles = profiles.filter { it.company.id == companyId }

            val filteredRows = filteredProfiles.map {
                Row(
                    id = it.id,
                    name = it.name,
                    description = it.description ?: "-",
                    vehicleModel = it.vehicleModel ?: "-",
                    vehicleType = it.vehicleType?.readableName ?: "-",
                    floorType = it.floorType?.readableName ?: "-",
                    seatingCapacity = it.seatingCapacity?.toString() ?: "-",
                    standingCapacity = it.standingCapacity?.toString() ?: "-",
                    ramp = it.ramp?.readableName ?: "-",
                    out1 = it.out1?.readableName ?: "-",
                    out2 = it.out2?.readableName ?: "-",
                    out3 = it.out3?.readableName ?: "-",
                    out4 = it.out4?.readableName ?: "-",
                    out5 = it.out5?.readableName ?: "-",
                    in1 = it.in1?.readableName ?: "-",
                    in2 = it.in2?.readableName ?: "-",
                    in3 = it.in3?.readableName ?: "-",
                    in4 = it.in4?.readableName ?: "-",
                    speaker1 = it.speaker1?.readableName ?: "-",
                    speaker2 = it.speaker2?.readableName ?: "-",
                    can = it.can?.readableName ?: "-",
                    ibis = it.ibis?.readableName ?: "-",
                    fmsProfile = getFMSProfileName(it.fmsProfile) ?: "-",
                    elaAudioVolume = it.elaAudioVolume?.toString() ?: "-"
                )
            }

            val localStorageCompanyId = try {
                localStorage["vehicleProfileListSelectedFilters"]
            } catch (e: NoSuchElementException) {
                null
            }

            val filters =
                if ((localStorageCompanyId != null) and (localStorageCompanyId != "null")) {
                    localStorage["vehicleProfileListSelectedFilters"]
                } else {
                    localStorage["vehicleProfileListSelectedFilters"]
                }

            setState {
                this.filters = filters?.let { JSON.parse(it) } ?: emptyArray()
                this.rows = filteredRows
            }
        }
    }

    private suspend fun getFMSProfileName(profileId: Int?): String? {
        if (profileId == null)
            return null
        var profileName = "-"
        async {
            profileName =
                portalRestApi.get("/vehicleprofiles/fms/profile/${profileId}", FmsProfileDTO.serializer()).name
        }.await()
        return profileName
    }

    private val filterRowWrapper = rFunction<RProps>("FilterRowWrapper") { props ->
        if (state.showFilter) tableRow(props)
        else tr {}
    }

    private val rowWrapper = rFunction<RowProps>("TableRowWrapper") { rowProps ->
        val newProps = kotlinext.js.clone(rowProps)

        newProps.asDynamic().onClick = {
            if (state.duplicate) {
                props.createProfile((rowProps.tableRow.row as Row).id)
            } else {
                props.profileSelected((rowProps.tableRow.row as Row).id)
            }
        }

        newProps.asDynamic().style = kotlinext.js.js { cursor = "pointer" }
        tableRow(newProps)
    }

    private val listCellStyle = rFunction<RProps>("TableCell") { cellProps ->
        val newProps = kotlinext.js.clone(cellProps)

        newProps.asDynamic().style = kotlinext.js.js {
            paddingTop = 1.spacingUnits
            paddingBottom = 1.spacingUnits
        }

        tableCell(newProps)
    }

    override fun RBuilder.render() {
        spacer()
        mGridContainer2(direction = MGridDirection.row) {
            mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                authorize(Permissions.VehicleProfileManagement.profilesEdit) {
                    mButton(
                        variant = MButtonVariant.contained,
                        caption = "Profil erstellen",
                        color = MColor.secondary,
                        onClick = { props.createProfile(null) }
                    ) {
                        attrs.disableElevation = true
                    }
                    mButton(
                        variant = MButtonVariant.contained,
                        caption = if (state.duplicate) "Zu duplizierendes Profil wählen" else "Profil duplizieren",
                        color = if (state.duplicate) MColor.primary else MColor.secondary,
                        onClick = {
                            setState {
                                duplicate = !duplicate
                            }
                        }
                    ) {
                        css { marginLeft = 1.spacingUnits }
                        attrs.disableElevation = true
                    }
                }
            }
            mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                mCard {
                    grid(
                        columns = state.columns,
                        rows = state.rows
                    ) {
                        searchState()
                        sortingState(
                            defaultSorting = Sorting(columnName = "name", direction = "asc")
                        )
                        filteringState(
                            filters = state.filters,
                            onFiltersChange = {
                                setState { filters = it }
                                // localStorage["vehicleProfileListSelectedFilters"] = JSON.stringify(it)
                            }
                        )
                        groupingState(
                            grouping = state.grouping,
                            expandedGroups = state.expandedGroups,
                            onGroupingChange = { setState { grouping = it } },
                            onExpandedGroupsChange = { setState { expandedGroups = it } }
                        )
                        pagingState(
                            pageSize = state.pageSize,
                            onPageSizeChange = { pageSize ->
                                localStorage["vehicleProfileListPageSize${props.persistenceId}"] = pageSize.toString()
                                setState { this.pageSize = pageSize }
                            },
                            currentPage = state.page,
                            onCurrentPageChange = { setState { page = it } }
                        )
                        integratedGrouping()
                        integratedFiltering()
                        integratedSorting()
                        dragDropProvider()
                        table(
                            cellComponent = listCellStyle,
                            rowComponent = rowWrapper,
                            columnExtensions = listOf(
                                TableProps.ColumnExtension(
                                    columnName = "name",
                                    width = 320
                                ),
                                TableProps.ColumnExtension(
                                    columnName = "state",
                                    width = 120
                                )
                            )
                        )
                        tableColumnReordering(
                            order = state.columnOrder,
                            onOrderChange = { nextOrder ->
                                localStorage["vehicleProfileListColumnOrder${props.persistenceId}"] =
                                    nextOrder.joinToString(";")
                                setState { columnOrder = nextOrder.toList() }
                            }
                        )
                        tableHeaderRow(
                            showSortingControls = true
                        )
                        tableFilterRow(
                            cellComponent = VehicleProfilesListFilterCell::class.js.unsafeCast<RClass<TableFilterRowProps.CellProps>>(),
                            rowComponent = filterRowWrapper
                        )
                        tableColumnVisibility(
                            hiddenColumnNames = state.hiddenColumns,
                            onHiddenColumnNamesChange = { hiddenColumnNames ->
                                localStorage["vehicleProfileListHiddenColumns${props.persistenceId}"] =
                                    hiddenColumnNames.joinToString(";")
                                setState { hiddenColumns = hiddenColumnNames.toList() }
                            }
                        )
                        tableGroupRow()
                        gridToolbar()
                        groupingPanel(
                            showGroupingControls = true,
                            showSortingControls = true
                        )
                        searchPanel()
                        columnChooser()
                        toolbarFilterToggle(
                            active = state.showFilter,
                            onToggle = {
                                setState {
                                    showFilter = !showFilter
                                    // localStorage["vehicleProfileListToggledFilter"] = showFilter.toString()
                                    if (!showFilter)
                                        filters = emptyArray()
                                }
                            },
                        )
                    }
                }
            }
        }
    }
}