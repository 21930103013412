package reactjsonview

import com.ccfraser.muirwik.components.createStyled
import kotlinext.js.js
import react.RBuilder
import react.RComponent
import react.RState
import styled.StyledHandler
import styled.StyledProps
import kotlin.js.json

interface ReactJsonViewProps : StyledProps {
    var src: Any
    var name: String?
    var theme: dynamic
    var style: dynamic //React.CSSProperties? get() = definedExternally; set(value) = definedExternally

    //    var iconStyle: dynamic /* String /* "circle" */ | String /* "triangle" */ | String /* "square" */ */ get() = definedExternally; set(value) = definedExternally
//    var indentWidth: Number? get() = definedExternally; set(value) = definedExternally
//    var collapsed: dynamic /* Number | Boolean */ get() = definedExternally; set(value) = definedExternally
//    var collapseStringsAfterLength: dynamic /* Number | Boolean */ get() = definedExternally; set(value) = definedExternally
//    var shouldCollapse: dynamic /* Boolean | (field: CollapsedFieldProps) -> Boolean */ get() = definedExternally; set(value) = definedExternally
//    var groupArraysAfterLength: Number? get() = definedExternally; set(value) = definedExternally
    var enableClipboard: Boolean
    var displayObjectSize: Boolean
    var displayDataTypes: Boolean
    var onEdit: (InteractionProps) -> Boolean
    var onAdd: (InteractionProps) -> Boolean
    var onDelete: (InteractionProps) -> Boolean
//    var onSelect: dynamic /* Boolean | (select: OnSelectProps) -> Unit */ get() = definedExternally; set(value) = definedExternally
//    var validationMessage: String? get() = definedExternally; set(value) = definedExternally
//    var sortKeys: Boolean? get() = definedExternally; set(value) = definedExternally
//    var defaultValue: dynamic /* String | Number | Boolean | Any? | Array<dynamic /* String | Number | Boolean | Any? */> | Nothing? */ get() = definedExternally; set(value) = definedExternally
}

external interface InteractionProps {
    var updated_src: Any
    var existing_src: Any
    var name: String?
    var namespace: Array<String?>
    var existing_value: dynamic
    var new_value: dynamic
}

@JsModule("react-json-view")
private external val reactJson: dynamic

@Suppress("UnsafeCastFromDynamic")
private val reactJsonComponent: RComponent<ReactJsonViewProps, RState> = reactJson.default

fun RBuilder.reactJson(
    src: String,
    displayDataTypes: Boolean = false,
    darkTheme: Boolean = false,
    disabled: Boolean = false,
    onEdit: ((String) -> Unit)? = null,
    onAdd: ((String) -> Unit)? = null,
    onDelete: ((String) -> Unit)? = null,
    onChange: ((String) -> Unit)? = null,
    handler: StyledHandler<ReactJsonViewProps>? = null,
) = createStyled(reactJsonComponent) {
    attrs.src = try {
        JSON.parse(src)
    } catch (e: Throwable) {
        json("error" to "invalid/malformed Json!")
    }
    attrs.name = null
    val style = js {
        fontSize = "14px"
        padding = "12px"
        minHeight = "64px"
    }
    attrs.style = style
    attrs.enableClipboard = onChange == null
    attrs.displayDataTypes = displayDataTypes
    attrs.displayObjectSize = false
    if (darkTheme || disabled) attrs.theme = when {
        darkTheme && disabled -> "grayscale"
        disabled -> "grayscale:inverted"
        else -> "monokai"
    }
    if (onChange != null) {
        attrs.onEdit = {
            onChange(JSON.stringify(it.updated_src, null, 6))
            true
        }
        attrs.onAdd = {
            onChange(JSON.stringify(it.updated_src, null, 6))
            true
        }
        attrs.onDelete = {
            onChange(JSON.stringify(it.updated_src, null, 6))
            true
        }
    }
    if (onEdit != null)
        attrs.onEdit = {
            onEdit(JSON.stringify(it.updated_src))
            true
        }
    if (onAdd != null)
        attrs.onAdd = {
            onAdd(JSON.stringify(it.updated_src))
            true
        }
    if (onDelete != null)
        attrs.onDelete = {
            onDelete(JSON.stringify(it.updated_src))
            true
        }
    handler?.invoke(this)
}