package de.geomobile.common.portalmodels

import de.geomobile.common.time.LocalDateTime
import kotlinx.serialization.Serializable

@Serializable
data class M2MStatusDTO(
    val lastSystemTime: LocalDateTime? = null,
    val overdue: Boolean = false,
    val messageLog: List<M2MMessageDTO> = emptyList()
)

@Serializable
data class M2MMessageDTO(
        val topic: String,
        val version: String,
        val message: String,
        val timestamp: LocalDateTime,
        val received: LocalDateTime,
        val retained: Boolean
)

fun shortTopic(message: M2MMessageDTO) : String {
    val parts = message.topic.split("/").toMutableList()
    parts.removeAt(0)
    parts.removeAt(0)
    return parts.joinToString("/")
}