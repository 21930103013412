package de.geomobile.frontend.features.device.detail

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonSize
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.form.MFormControlMargin
import com.ccfraser.muirwik.components.form.MFormControlVariant
import com.ccfraser.muirwik.components.form.margin
import com.ccfraser.muirwik.components.list.*
import com.ccfraser.muirwik.components.table.*
import com.ccfraser.muirwik.components.transitions.mCollapse
import components.emptyView
import de.geomobile.common.permission.Permissions
import de.geomobile.common.portalmodels.*
import de.geomobile.common.time.LocalDateTime
import de.geomobile.frontend.currentTheme
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.portalWebSocketApi
import de.geomobile.frontend.utils.*
import de.geomobile.frontend.utils.grid.ValueFormatterProps
import kotlinext.js.js
import kotlinext.js.jsObject
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.callbackFlow
import kotlinx.coroutines.launch
import kotlinx.css.*
import kotlinx.css.properties.Transforms
import kotlinx.html.InputType
import kotlinx.serialization.json.Json
import portalmodels.InternetSource
import portalmodels.WiringProfileDTO
import react.*
import react.dom.textarea
import styled.StyleSheet
import styled.StyledProps
import styled.css
import styled.styledDiv

fun RBuilder.deviceCableCheckV2(
    id: Int,
    isQuickCheck: Boolean = false,
) = child(DeviceDetailCableCheckV2::class) {
    key = id.toString()
    attrs.id = id
    attrs.isQuickCheck = isQuickCheck
}

class DeviceDetailCableCheckV2 : CComponent<DeviceDetailCableCheckV2.Props, DeviceDetailCableCheckV2.State>() {

    private var relayJob: Job = Job()
    private var logCheckJob: Job = Job()

    private val cableCheckSocket by kotlin.lazy {
        portalWebSocketApi.subscribe("/device/cablecheck", mapOf("id" to props.id.toString()))
    }

    interface Props : RProps {
        var id: Int
        var isQuickCheck: Boolean
    }

    class State(
        var cableCheck: CableCheck = CableCheck(),
        var wiringProfileDTO: WiringProfileDTO = WiringProfileDTO(),
        var changedAudioVolume: Int? = null,
        var collapseMap: MutableMap<String, Boolean> = mutableMapOf(),
    ) : RState

    init {
        state = State()
    }

    private fun triggerRelay(relayNumber: Int) {
        relayJob.cancel()
        relayJob = launch(Dispatchers.Default) {
            portalRestApi.post("/device/${props.id}/cablecheck/relay$relayNumber")
        }
    }

    private fun triggerLogCheck(type: String) {
        logCheckJob.cancel()
        logCheckJob = launch(Dispatchers.Default) {
            portalRestApi.post("/device/${props.id}/cablecheck/logcheck/$type")
        }
    }

    override fun componentDidMount() {
        launch {
            val wiring = portalRestApi.get("/vehicleprofiles/wiring/device/${props.id}", WiringProfileDTO.serializer())
            setState {
                wiringProfileDTO = wiring
            }
        }
        cableCheckSocket.connect {
            onmessage = {
                val cableCheck = Json.decodeFromString(CableCheck.serializer(), it)
                setState {
                    this.cableCheck = cableCheck
                }
            }
        }
    }

    override fun componentWillUnmount() {
        super.componentWillUnmount()
        cableCheckSocket.close()
    }

    override fun RBuilder.render() {
        val cableCheck = state.cableCheck
        val audioVolume = state.changedAudioVolume ?: 50

        mList{
            attrs.disablePadding = true
            attrs.dense = true
            mListItem {
                mListItemText(
                    primary = "Bootzeit",
                    secondary = cableCheck.bootTime?.timestamp?.toText() ?: "-",
                ) {
                    attrs.primaryTypographyProps = jsObject {
                        variant = MTypographyVariant.subtitle2
                        color = MTypographyColor.secondary
                    }
                    attrs.secondaryTypographyProps = jsObject {
                        variant = MTypographyVariant.caption
                    }

                }
            }
            mListItem {
                mListItemText (primary = "Eingänge") {
                    attrs.primaryTypographyProps = jsObject {
                        variant = MTypographyVariant.subtitle2
                        color = MTypographyColor.secondary
                    }
                    attrs.secondaryTypographyProps = jsObject {
                        variant = MTypographyVariant.caption
                    }
                }
            }
            mListItem {
                mTableContainer {
                    mTable {
                        css {
                            width = LinearDimension("unset")
                            whiteSpace = WhiteSpace.nowrap
                        }
                        mTableHead {
                            mTableRowSlim {
                                mTableCell(
                                    align = MTableCellAlign.center,
                                    variant = MTableCellVariant.head,
                                    padding = MTableCellPadding.none
                                ) {
                                    css { width = 20.px }
                                    +"#"
                                }
                                mTableCell(
                                    align = MTableCellAlign.left,
                                    variant = MTableCellVariant.head,
                                    padding = MTableCellPadding.none
                                ) {
                                    css {
                                        minWidth = 130.px
                                        padding(1.spacingUnits)
                                    }
                                    +"Funktion"
                                }

                                mTableCell(
                                    align = MTableCellAlign.center,
                                    variant = MTableCellVariant.head,
                                    padding = MTableCellPadding.none
                                ) {
                                    css {
                                        width = 125.px
                                        padding(1.spacingUnits)
                                    }
                                    +"Stecker"
                                }
                                mTableCell(
                                    align = MTableCellAlign.center,
                                    variant = MTableCellVariant.head,
                                    padding = MTableCellPadding.none
                                ) {
                                    css {
                                        width = 90.px
                                        padding(1.spacingUnits)
                                    }
                                    +"Aktiv"
                                }
                                mTableCell(
                                    align = MTableCellAlign.center,
                                    variant = MTableCellVariant.head,
                                    padding = MTableCellPadding.none
                                ) {
                                    css {
                                        width = 90.px
                                        padding(1.spacingUnits)
                                    }
                                    +"Status"
                                }
                            }
                        }
                        mTableBody {
                            state.wiringProfileDTO.let { profile ->
                                val inList = listOf(
                                    Triple(profile.in1, profile.in1SwitchHigh, state.cableCheck.inputs[0]),
                                    Triple(profile.in2, profile.in2SwitchHigh, state.cableCheck.inputs[1]),
                                    Triple(profile.in3, profile.in3SwitchHigh, state.cableCheck.inputs[2]),
                                    Triple(profile.in4, profile.in4SwitchHigh, state.cableCheck.inputs[3]),
                                )
                                inList.forEachIndexed { idx, (inFunction, switch, status) ->
                                    mTableRowSlim {
                                        mTableCell(
                                            align = MTableCellAlign.center,
                                            padding = MTableCellPadding.none
                                        ) {
                                            +"${idx + 1}"
                                        }
                                        mTableCell(
                                            align = MTableCellAlign.left,
                                            padding = MTableCellPadding.none
                                        ) {
                                            css {
                                                minWidth = 130.px
                                                overflow = Overflow.hidden
                                                textOverflow = TextOverflow.ellipsis
                                                padding(1.spacingUnits)
                                            }
                                            +inFunction.readableName
                                        }
                                        mTableCell(
                                            align = MTableCellAlign.center,
                                            padding = MTableCellPadding.none
                                        ) {
                                            css { padding(1.spacingUnits) }
                                            +""
                                        }
                                        mTableCell(
                                            align = MTableCellAlign.center,
                                            padding = MTableCellPadding.none
                                        ) {
                                            css { padding(1.spacingUnits) }
                                            +if (switch) "High" else "Low"
                                        }
                                        mTableCell(
                                            align = MTableCellAlign.center,
                                            padding = MTableCellPadding.none
                                        ) {
                                            mTypography(
                                                text = when (status?.value) {
                                                    CableCheck.ToggleState.ON -> "An"
                                                    CableCheck.ToggleState.OFF -> "Aus"
                                                    CableCheck.ToggleState.ERROR -> "Fehler: ${status.raw}"
                                                    null -> "N/A"
                                                },
                                                variant = MTypographyVariant.subtitle2
                                            ) {
                                                css {
                                                    +CustomStyles.label
                                                    backgroundColor = when (status?.value) {
                                                        CableCheck.ToggleState.ON -> Color.darkGreen
                                                        CableCheck.ToggleState.OFF -> Color.grey
                                                        CableCheck.ToggleState.ERROR -> Color.darkRed
                                                        null -> Color.grey
                                                    }
                                                }
                                            }
                                        }
                                    }

                                }
                            }
                        }
                    }
                }
            }
            mListItem {
                mListItemText (primary = "Ausgänge") {
                    attrs.primaryTypographyProps = jsObject {
                        variant = MTypographyVariant.subtitle2
                        color = MTypographyColor.secondary
                    }
                    attrs.secondaryTypographyProps = jsObject {
                        variant = MTypographyVariant.caption
                    }
                }
            }
            mListItem {
                mTableContainer {
                    mTable {
                        css {
                            width = LinearDimension("unset")
                            whiteSpace = WhiteSpace.nowrap
                        }
                        mTableHead {
                            mTableCell(
                                align = MTableCellAlign.center,
                                variant = MTableCellVariant.head,
                                padding = MTableCellPadding.none
                            ) {
                                css { width = 20.px }
                                +"#"
                            }
                            mTableCell(
                                align = MTableCellAlign.left,
                                variant = MTableCellVariant.head,
                                padding = MTableCellPadding.none
                            ) {
                                css {
                                    minWidth = 130.px
                                    padding(1.spacingUnits)
                                }
                                +"Funktion"
                            }
                            mTableCell(
                                align = MTableCellAlign.center,
                                variant = MTableCellVariant.head,
                                padding = MTableCellPadding.none
                            ) {
                                css {
                                    width = 125.px
                                    padding(1.spacingUnits)
                                }
                                +"Stecker"
                            }
                            mTableCell(
                                align = MTableCellAlign.center,
                                variant = MTableCellVariant.head,
                                padding = MTableCellPadding.none
                            ) {
                                css {
                                    width = 90.px
                                    padding(1.spacingUnits)
                                }
                                +"Schalter"
                            }
                            mTableCell(
                                align = MTableCellAlign.center,
                                variant = MTableCellVariant.head,
                                padding = MTableCellPadding.none
                            ) {
                                css {
                                    width = 90.px
                                    padding(1.spacingUnits)
                                }
                                +"Status"
                            }
                        }
                        mTableBody {
                            state.wiringProfileDTO.let { profile ->
                                val outList = mutableListOf(
                                    profile.out1 to state.cableCheck.relays[0],
                                    profile.out2 to state.cableCheck.relays[1],
                                    profile.out3 to state.cableCheck.relays[2],
                                    profile.out4 to state.cableCheck.relays[3],
                                )
                                state.cableCheck.relays.elementAtOrNull(4)?.let {
                                    outList.add(profile.out5 to it)
                                }
                                outList.forEachIndexed { idx, (outFunction, status) ->
                                    mTableRowSlim {
                                        mTableCell(
                                            align = MTableCellAlign.center,
                                            padding = MTableCellPadding.none
                                        ) {
                                            +"${idx + 1}"
                                        }
                                        mTableCell(
                                            align = MTableCellAlign.left,
                                            padding = MTableCellPadding.none
                                        ) {
                                            css {padding(1.spacingUnits) }
                                            +outFunction.readableName
                                        }
                                        mTableCell(
                                            align = MTableCellAlign.center,
                                            padding = MTableCellPadding.none
                                        ) {
                                            css { padding(1.spacingUnits) }
                                            +""
                                        }
                                        mTableCell(
                                            align = MTableCellAlign.center,
                                            padding = MTableCellPadding.none
                                        ) {
                                            css { padding = "2px" }
                                            mButton(
                                                caption = "Auslösen",
                                                size = MButtonSize.small,
                                                variant = MButtonVariant.contained,
                                                color = MColor.primary,
                                                onClick = {
                                                    triggerRelay(idx+1)
                                                }
                                            ) { css { padding = "2px 4px" } }
                                        }
                                        mTableCell(
                                            align = MTableCellAlign.center,
                                            padding = MTableCellPadding.none
                                        ) {
                                            mTypography(
                                                text = when (status?.value) {
                                                    CableCheck.ToggleState.ON -> "An"
                                                    CableCheck.ToggleState.OFF -> "Aus"
                                                    CableCheck.ToggleState.ERROR -> "Fehler: ${status.raw}"
                                                    null -> "N/A"
                                                },
                                                variant = MTypographyVariant.subtitle2
                                            ) {
                                                css {
                                                    +CustomStyles.label
                                                    backgroundColor = when (status?.value) {
                                                        CableCheck.ToggleState.ON -> Color.darkGreen
                                                        CableCheck.ToggleState.OFF -> Color.grey
                                                        CableCheck.ToggleState.ERROR -> Color.darkRed
                                                        null -> Color.grey
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            mListItem {
                attrs.divider = true
                css {
                    display = Display.flowRoot
                    width = 100.pct
                    maxWidth = 600.px
                }

                styledDiv {
                    mListItemText(
                        primary = "Mobilfunk",
                        secondary = cableCheck.systemTime?.timestamp?.toText() ?: "-",
                    ) {
                        attrs.primaryTypographyProps = jsObject {
                            variant = MTypographyVariant.subtitle2
                            color = MTypographyColor.secondary
                        }
                        attrs.secondaryTypographyProps = jsObject {
                            variant = MTypographyVariant.caption
                        }

                    }
                    mListItemSecondaryAction {
                        css {
                            transform = Transforms.none
                            top = 8.px
                        }
                        mIconButtonNoTranslate(
                            if (state.collapseMap["lte"] == true) "expand_less" else "expand_more",
                            addAsChild = true,
                            onClick = {
                                setState {
                                    collapseMap["lte"] = !(state.collapseMap["lte"] ?: false)
                                }
                            }
                        )
                    }
                }
                mCollapse( show = state.collapseMap["lte"] ?: false ) {
                    mCardContent {
                        css { background = "#eeeeee" }
                        if (cableCheck.lte == null) {
                            mTypography("Keine Mobilfunk Daten verfügbar")
                        } else {
                            mTable {
                                css {
                                    marginTop = 1.spacingUnits
                                    width = 100.pct
                                }
                                mTableHead {
                                    mTableCell {
                                        +""
                                    }
                                    cableCheck.lte?.value?.modules?.forEachIndexed { idx, module ->
                                        mTableCell {
                                            +"Modul ${idx+1}"
                                        }
                                    }
                                }
                                mTableBody {
                                    mTableRowSlim {
                                        mTableCell { +"Provider"}
                                        cableCheck.lte?.value?.modules?.forEachIndexed { idx, module ->
                                            mTableCell { +"${module.providerName}" }
                                        }
                                    }
                                    mTableRowSlim {
                                        mTableCell { +"Typ"}
                                        cableCheck.lte?.value?.modules?.forEachIndexed { idx, module ->
                                            mTableCell { +"${module.providerName}" }
                                        }
                                    }
                                    mTableRowSlim {
                                        mTableCell { +"PIN"}
                                        cableCheck.lte?.value?.modules?.forEachIndexed { idx, module ->
                                            mTableCell { +if (module.pinDisabled == "1") "Deaktiviert" else "Vorhanden" }
                                        }
                                    }
                                    mTableRowSlim {
                                        mTableCell { +"SIM"}
                                        cableCheck.lte?.value?.modules?.forEachIndexed { idx, module ->
                                            mTableCell { +"${module.simStatus}" }
                                        }
                                    }
                                    mTableRowSlim {
                                        mTableCell { +"RSSI"}
                                        cableCheck.lte?.value?.modules?.forEachIndexed { idx, module ->
                                            mTableCell { +"${module.rssi}" }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            mListItem {
                attrs.divider = true
                css {
                    display = Display.flowRoot
                    width = 100.pct
                    maxWidth = 600.px
                }

                styledDiv {
                    mListItemText(
                        primary = "GPS",
                        secondary = cableCheck.systemTime?.timestamp?.toText() ?: "-",
                    ) {
                        attrs.primaryTypographyProps = jsObject {
                            variant = MTypographyVariant.subtitle2
                            color = MTypographyColor.secondary
                        }
                        attrs.secondaryTypographyProps = jsObject {
                            variant = MTypographyVariant.caption
                        }

                    }
                    mListItemSecondaryAction {
                        css {
                            transform = Transforms.none
                            top = 8.px
                        }
                        mIconButtonNoTranslate(
                            if (state.collapseMap["gps"] == true) "expand_less" else "expand_more",
                            addAsChild = true,
                            onClick = {
                                setState {
                                    collapseMap["gps"] = !(state.collapseMap["gps"] ?: false)
                                }
                            }
                        )
                    }
                }
                mCollapse( show = state.collapseMap["gps"] ?: false ) {
                    mCardContent {
                        css { background = "#eeeeee" }
                        if (cableCheck.gps == null) {
                            mTypography("Keine GPS Daten verfügbar")
                        } else {
                            mTable {
                                css {
                                    marginTop = 1.spacingUnits
                                    width = 100.pct
                                }
                                mTableHead {
                                    mTableCell {
                                        +""
                                    }
                                    mTableCell {
                                        +""
                                    }
                                }
                                mTableBody {
                                    mTableRowSlim {
                                        mTableCell { +"Fix"}
                                        cableCheck.gps?.let {
                                            mTableCell { +if(cableCheck.gps?.value?.fix == true) "Ja" else "Nein" }
                                        }
                                    }
                                    if(cableCheck.gps?.value?.fix == true) {
                                        mTableRowSlim {
                                            mTableCell { +"Sateliten"}
                                            cableCheck.gps?.let {
                                                mTableCell { +"${it.value.location?.satellites}" }
                                            }
                                        }
                                        mTableRowSlim {
                                            mTableCell { +"Latitude"}
                                            cableCheck.gps?.let {
                                                mTableCell { +"${it.value.location?.latitude}" }
                                            }
                                        }
                                        mTableRowSlim {
                                            mTableCell { +"Longitude"}
                                            cableCheck.gps?.let {
                                                mTableCell { +"${it.value.location?.longitude}" }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            mListItem {
                attrs.divider = true
                css {
                    display = Display.flowRoot
                    width = 100.pct
                    maxWidth = 600.px
                }

                styledDiv {
                    mListItemText(
                        primary = "Wifi",
                        secondary = cableCheck.systemTime?.timestamp?.toText() ?: "-",
                    ) {
                        attrs.primaryTypographyProps = jsObject {
                            variant = MTypographyVariant.subtitle2
                            color = MTypographyColor.secondary
                        }
                        attrs.secondaryTypographyProps = jsObject {
                            variant = MTypographyVariant.caption
                        }

                    }
                    mListItemSecondaryAction {
                        css {
                            transform = Transforms.none
                            top = 8.px
                        }
                        mIconButtonNoTranslate(
                            if (state.collapseMap["wifi"] == true) "expand_less" else "expand_more",
                            addAsChild = true,
                            onClick = {
                                setState {
                                    collapseMap["wifi"] = !(state.collapseMap["wifi"] ?: false)
                                }
                            }
                        )
                    }
                }
                mCollapse( show = state.collapseMap["wifi"] ?: false ) {
                    mCardContent {
                        css { background = "#eeeeee" }
                        val wifiModule = cableCheck.wifi?.value?.modules?.firstOrNull()?.clientInterfaces?.firstOrNull()
                        if (wifiModule == null) {
                            mTypography("Keine Wifi Daten verfügbar")
                        } else {
                            mTable {
                                css {
                                    marginTop = 1.spacingUnits
                                    width = 100.pct
                                }
                                mTableHead {
                                    mTableCell {
                                        +""
                                    }
                                    mTableCell {
                                        +""
                                    }
                                }
                                mTableBody {
                                    mTableRowSlim {
                                        mTableCell { +"Interface"}
                                        wifiModule.interfaceName?.let {
                                            mTableCell { +"$it" }
                                        }
                                    }
                                    mTableRowSlim {
                                        mTableCell { +"SSID"}
                                        wifiModule.ssid?.let {
                                            mTableCell { +"$it" }
                                        }
                                    }
                                    mTableRowSlim {
                                        mTableCell { +"Aktiviert"}
                                        wifiModule.enabled?.let {
                                            mTableCell { +"$it" }
                                        }
                                    }
                                    mTableRowSlim {
                                        mTableCell { +"IP-Adresse"}
                                        wifiModule.ipAddress?.let {
                                            mTableCell { +"$it" }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            mListItem {
                attrs.divider = true
                css {
                    display = Display.flowRoot
                    width = 100.pct
                    maxWidth = 600.px
                }

                styledDiv {
                    mListItemText(
                        primary = "LAN",
                        secondary = cableCheck.systemTime?.timestamp?.toText() ?: "-",
                    ) {
                        attrs.primaryTypographyProps = jsObject {
                            variant = MTypographyVariant.subtitle2
                            color = MTypographyColor.secondary
                        }
                        attrs.secondaryTypographyProps = jsObject {
                            variant = MTypographyVariant.caption
                        }

                    }
                    mListItemSecondaryAction {
                        css {
                            transform = Transforms.none
                            top = 8.px
                        }
                        mIconButtonNoTranslate(
                            if (state.collapseMap["lan"] == true) "expand_less" else "expand_more",
                            addAsChild = true,
                            onClick = {
                                setState {
                                    collapseMap["lan"] = !(state.collapseMap["lan"] ?: false)
                                }
                            }
                        )
                    }
                }
                mCollapse( show = state.collapseMap["lan"] ?: false ) {
                    mCardContent {
                        css { background = "#eeeeee" }
                        if (cableCheck.lan == null) {
                            mTypography("Keine LAN Daten verfügbar")
                        } else {
                            mTable {
                                css {
                                    marginTop = 1.spacingUnits
                                    width = 100.pct
                                }
                                mTableHead {
                                    mTableCell {
                                        +""
                                    }
                                    mTableCell {
                                        +""
                                    }
                                }
                                mTableBody {
                                    mTableRowSlim {
                                        mTableCell { +"Verbunden"}
                                        cableCheck.lan?.value?.connected?.let {
                                            mTableCell { +if(it) "Ja" else "Nein" }
                                        }
                                    }
                                    mTableRowSlim {
                                        mTableCell { +"Interface"}
                                        cableCheck.lan?.value?.netinterface?.let {
                                            mTableCell { +"$it" }
                                        }
                                    }
                                    mTableRowSlim {
                                        mTableCell { +"IP-Adresse"}
                                        cableCheck.lan?.value?.ip?.let {
                                            mTableCell { +"$it" }
                                        }
                                    }
                                    mTableRowSlim {
                                        mTableCell { +"MAC-Adresse"}
                                        cableCheck.lan?.value?.mac?.let {
                                            mTableCell { +"$it" }
                                        }
                                    }
                                    mTableRowSlim {
                                        mTableCell { +"Gateway"}
                                        cableCheck.lan?.value?.gateway?.let {
                                            mTableCell { +"$it" }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            mListItem {
                attrs.divider = true
                css {
                    display = Display.flowRoot
                    width = 100.pct
                    maxWidth = 600.px
                }

                styledDiv {
                    mListItemText(
                        primary = "Bluetooth",
                        secondary = cableCheck.systemTime?.timestamp?.toText() ?: "-",
                    ) {
                        attrs.primaryTypographyProps = jsObject {
                            variant = MTypographyVariant.subtitle2
                            color = MTypographyColor.secondary
                        }
                        attrs.secondaryTypographyProps = jsObject {
                            variant = MTypographyVariant.caption
                        }

                    }
                    mListItemSecondaryAction {
                        css {
                            transform = Transforms.none
                            top = 8.px
                        }
                        mIconButtonNoTranslate(
                            if (state.collapseMap["ble"] == true) "expand_less" else "expand_more",
                            addAsChild = true,
                            onClick = {
                                setState {
                                    collapseMap["ble"] = !(state.collapseMap["ble"] ?: false)
                                }
                            }
                        )
                    }
                }
                mCollapse( show = state.collapseMap["ble"] ?: false ) {
                    mCardContent {
                        css { background = "#eeeeee" }
                        val bleModule = cableCheck.ble?.value ?: false
                        if (!bleModule) {
                            mTypography("Keine Bluetooth Modul Installiert")
                        } else {
                            mTypography("Bluetooth Modul Installiert")
                        }
                    }
                }
            }

            mListItem {
                attrs.divider = true
                css {
                    display = Display.flowRoot
                    width = 100.pct
                    maxWidth = 600.px
                }

                styledDiv {
                    mListItemText(
                        primary = "IBIS",
                        secondary = cableCheck.systemTime?.timestamp?.toText() ?: "-",
                    ) {
                        attrs.primaryTypographyProps = jsObject {
                            variant = MTypographyVariant.subtitle2
                            color = MTypographyColor.secondary
                        }
                        attrs.secondaryTypographyProps = jsObject {
                            variant = MTypographyVariant.caption
                        }

                    }
                    mListItemSecondaryAction {
                        css {
                            transform = Transforms.none
                            top = 8.px
                        }
                        mIconButtonNoTranslate(
                            if (state.collapseMap["ibis"] == true) "expand_less" else "expand_more",
                            addAsChild = true,
                            onClick = {
                                setState {
                                    collapseMap["ibis"] = !(state.collapseMap["ibis"] ?: false)
                                }
                            }
                        )
                    }
                }
                mCollapse( show = state.collapseMap["ibis"] ?: false ) {
                    mCardContent {
                        css { background = "#eeeeee" }
                        mButton(
                            caption = "Log Anfrage Senden",
                            size = MButtonSize.small,
                            variant = MButtonVariant.contained,
                            color = MColor.primary,
                            onClick = {
                                triggerLogCheck("ibis")
                            }
                        ) { css { padding = "2px 4px" } }
                        mTextFieldMultiLine(
                            label = "",
                            value = cableCheck.ibisLog ?: "",
                            variant = MFormControlVariant.outlined,
                            margin = MFormControlMargin.none,
                            disabled = true,
                            rowsMax = 8
                        ) {
                            css {
                                marginTop = 1.spacingUnits
                                width = 100.pct
                            }
                        }
                    }
                }
            }
            mListItem {
                attrs.divider = true
                css {
                    display = Display.flowRoot
                    width = 100.pct
                    maxWidth = 600.px
                }

                mListItemText(
                    primary = "FMS",
                    secondary = cableCheck.systemTime?.timestamp?.toText() ?: "-",
                ) {
                    attrs.primaryTypographyProps = jsObject {
                        variant = MTypographyVariant.subtitle2
                        color = MTypographyColor.secondary
                    }
                    attrs.secondaryTypographyProps = jsObject {
                        variant = MTypographyVariant.caption
                    }

                }
                mListItemSecondaryAction {
                    css {
                        transform = Transforms.none
                        top = 8.px
                    }
                    mIconButtonNoTranslate(
                        if (state.collapseMap["fms"] == true) "expand_less" else "expand_more",
                        addAsChild = true,
                        onClick = {
                            setState {
                                collapseMap["fms"] = !(state.collapseMap["fms"] ?: false)
                            }
                        }
                    )
                }
                mCollapse(state.collapseMap["fms"] ?: false) {
                    mCardContent {
                        css { background = "#eeeeee" }
                        mButton(
                            caption = "Log Anfrage Senden",
                            size = MButtonSize.small,
                            variant = MButtonVariant.contained,
                            color = MColor.primary,
                            onClick = {
                                triggerLogCheck("fms")
                            }
                        ) { css { padding = "2px 4px" } }
                        mTextFieldMultiLine(
                            label = "",
                            value = cableCheck.fmsLog ?: "",
                            variant = MFormControlVariant.outlined,
                            margin = MFormControlMargin.none,
                            disabled = true,
                            rowsMax = 8
                        ) {
                            css {
                                marginTop = 1.spacingUnits
                                width = 100.pct
                            }
                        }
                    }
                }
            }
            mListItem {
                attrs.divider = true
                attrs.button = false
                css {
                    width = 100.pct
                    maxWidth = 600.px
                }

                mListItemText(
                    primary = "Audio",
                    secondary = cableCheck.systemTime?.timestamp?.toText() ?: "-",
                ) {
                    attrs.primaryTypographyProps = jsObject {
                        variant = MTypographyVariant.subtitle2
                        color = MTypographyColor.secondary
                    }
                    attrs.secondaryTypographyProps = jsObject {
                        variant = MTypographyVariant.caption
                    }
                }
                mListItemSecondaryAction {
                    mIconButtonNoTranslate(
                        "volume_up",
                        addAsChild = true,
                        onClick = {
                            launch(Dispatchers.Default) {
                                portalRestApi.post("/device/${props.id}/cablecheck/playaudio/$audioVolume")
                            }
                        }
                    )
                }
                styledDiv {  } // DO NOT REMOVE this keeps the layout correct
            }
            mListItem {
                attrs.button = false
                attrs.divider = true
                css {
                    width = 100.pct
                    maxWidth = 600.px
                }

                mListItemText {
                    mTypography(
                        text = "Lautstärke",
                        variant = MTypographyVariant.subtitle2,
                        color = MTypographyColor.secondary,
                        component = "div"
                    )
                    mTypography(
                        text = "$audioVolume",
                        variant = MTypographyVariant.subtitle2
                    )
                    mSlider(
                        value = audioVolume,
                        min = 0,
                        max = 100,
                        step = 1,
                        onChange = { value ->
                            setState {
                                this.changedAudioVolume = value
                            }
                        }
                    )
                }
            }
        }
    }
}