package de.geomobile.frontend.features.companyProfile

import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.spacingUnits
import com.ccfraser.muirwik.components.styles.Breakpoint
import de.geomobile.frontend.utils.MGridBreakpoints2
import de.geomobile.frontend.utils.MGridSize2
import de.geomobile.frontend.utils.mGridItem2
import kotlinx.css.*
import react.RBuilder
import react.RComponent
import react.RProps
import react.RState
import react.router.dom.route
import styled.css

fun RBuilder.companyProfileWIFI() = child(CompanyProfileWIFI::class) {}

class CompanyProfileWIFI : RComponent<CompanyProfileWIFI.Props, CompanyProfileWIFI.State>() {

    interface Props : RProps {
        var path: String
        var id: Int
    }

    class State(

    ) : RState

    init {

    }

    override fun componentDidMount() {

    }

    override fun RBuilder.render() {

        route<RProps>("/companyprofile/network/wifi") { _ ->
            mGridItem2(
                MGridBreakpoints2(MGridSize2.Cells10)
                    .down(Breakpoint.sm, MGridSize2.Cells11)
            ) {
                css {
                    //height = 100.pct
                    display = Display.flex
                    flexDirection = FlexDirection.column
                    padding(1.spacingUnits)
                }
                // TODO: Clean up
                //simSettings()
                //simCSVTable()
                mCard {
                    css {
                        height = 200.pt
                    }
                }
            }
        }
    }

}