package de.geomobile.frontend.features.map

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonSize
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.list.mList
import com.ccfraser.muirwik.components.list.mListItem
import com.ccfraser.muirwik.components.list.mListItemAvatar
import com.ccfraser.muirwik.components.list.mListItemText
import com.ccfraser.muirwik.components.styles.Breakpoint
import com.ccfraser.muirwik.components.styles.up
import de.geomobile.common.portalmodels.TimestampStatus
import de.geomobile.common.portalmodels.VehicleType
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.currentTheme
import kotlinx.css.*
import react.*
import styled.css
import styled.styledDiv

fun RBuilder.mapLegend(
    onClose: () -> Unit,
) = child(MapLegendView::class) {
    attrs.onClose = onClose
}

class MapLegendView : RComponent<MapLegendView.Props, RState>() {

    interface Props : RProps {
        var onClose: () -> Unit
    }

    override fun RBuilder.render() {
        mCard {
            css(GlobalStyles.card)
            css {
                display = Display.flex
                borderRadius = 0.px
                media(currentTheme.breakpoints.up(Breakpoint.sm)) {
                    height = 100.vh - 64.px
                    width = 400.px
                }
                height = 100.vh - 56.px
                width = 400.px
                overflow = Overflow.auto
                flexDirection = FlexDirection.column
            }
            mCardContent {
                css(GlobalStyles.cardDeviceSidebarContent)
                styledDiv {
                    css { padding(1.spacingUnits) }
                    mIconButtonNoTranslate(
                        iconName = "close",
                        size = MButtonSize.small,
                        onClick = { props.onClose() }
                    )
                }
                mDivider { css { width = 100.pct } }

                sidebarLegend(
                    label = "Online",
                    description = "GPS ist aktuell",
                    statusLoc = TimestampStatus.Status.ONLINE,
                    statusSeen = TimestampStatus.Status.ONLINE
                )
                sidebarLegend(
                    label = "Online",
                    description = "GPS ist kurzzeitig unterbrochen worden",
                    statusLoc = TimestampStatus.Status.OVERDUE,
                    statusSeen = TimestampStatus.Status.ONLINE
                )
                sidebarLegend(
                    label = "Online",
                    description = "GPS ist veraltet",
                    statusLoc = TimestampStatus.Status.OFFLINE,
                    statusSeen = TimestampStatus.Status.ONLINE
                )
                sidebarLegend(
                    label = "Unbekannt",
                    description = "GPS ist schwach",
                    statusLoc = TimestampStatus.Status.UNKNOWN,
                    statusSeen = TimestampStatus.Status.UNKNOWN,
                    gpsQuality = 0
                )
                sidebarLegend(
                    label = "Offline",
                    description = "GPS ist nicht verfügbar",
                    statusLoc = TimestampStatus.Status.OFFLINE,
                    statusSeen = TimestampStatus.Status.OFFLINE
                )
            }
        }
    }
}

private fun RBuilder.sidebarLegend(
    label: String,
    description: String,
    statusLoc: TimestampStatus.Status,
    statusSeen: TimestampStatus.Status,
    gpsQuality: Int = 100
) {
    mList {
        attrs.dense = true

        mListItem {
            attrs.button = true

            mListItemAvatar {
                css { paddingTop = 1.spacingUnits }
                marker {
                    enableTransform = false
                    currentZoom = 12
                    key = ""
                    lat = 0
                    lng = 0
                    id = 0
                    vehicleId = null
                    serialNumber = null
                    vehicleType = VehicleType.BUS
                    statusLocation = statusLoc
                    statusLastSeen = statusSeen
                    this.gpsQuality = gpsQuality
                    onClick = { }
                }
            }
            mListItemText {
                mTypography(
                    text = label,
                    variant = MTypographyVariant.caption,
                )
                mTypography(
                    text = description,
                    variant = MTypographyVariant.subtitle2,
                    color = MTypographyColor.textPrimary
                )
            }
        }
    }
}