package de.geomobile.frontend.api

import kotlinx.browser.window
import kotlinx.coroutines.await
import kotlinx.coroutines.withTimeout
import kotlinx.serialization.KSerializer
import kotlinx.serialization.json.Json
import org.w3c.fetch.RequestCredentials
import org.w3c.fetch.RequestInit
import org.w3c.fetch.RequestMode
import org.w3c.fetch.Response
import kotlin.js.json

data class RestApi(
    val baseUrl: String,
//    val authorization: String,
    val timeout: Long = 60000,
    val interceptors: List<Interceptor> = emptyList(),
) {

    suspend fun delete(path: String, body: dynamic = null) =
        request(
            Request(
                url = "$baseUrl$path",
                method = "DELETE",
                body = body,
                headers = mapOf("Content-Type" to "application/json; charset=utf-8")
            )
        ) {}

    suspend fun <T : Any> post(path: String, body: dynamic = null, serializer: KSerializer<T>): T =
        request(
            Request(
                url = "$baseUrl$path",
                method = "POST",
                body = body,
                headers = mapOf(
                    "Accept" to "application/json; charset=utf-8",
                    "Content-Type" to "application/json; charset=utf-8"
                )
            )
        ) {
            Json.decodeFromString(serializer, it.text().await())
        }

    suspend fun <T : Any> post(
        path: String,
        header: Map<String, String>,
        body: dynamic = null,
        serializer: KSerializer<T>,
    ): T =
        request(
            Request(
                url = "$baseUrl$path",
                method = "POST",
                body = body,
                headers = header
            )
        ) {
            Json.decodeFromString(serializer, it.text().await())
        }

    suspend fun post(path: String, body: dynamic = null) =
        request(
            Request(
                url = "$baseUrl$path",
                method = "POST",
                body = body,
                headers = mapOf("Content-Type" to "application/json; charset=utf-8")
            )
        ) {}

    suspend fun post(path: String, header: Map<String, String>, body: dynamic = null) =
        request(
            Request(
                url = "$baseUrl$path",
                method = "POST",
                body = body,
                headers = header
            )
        ) {}

    suspend fun <T : Any> put(path: String, body: dynamic = null, serializer: KSerializer<T>): T =
        request(
            Request(
                url = "$baseUrl$path",
                method = "PUT",
                body = body,
                headers = mapOf(
                    "Accept" to "application/json; charset=utf-8",
                    "Content-Type" to "application/json; charset=utf-8"
                )
            )
        ) {
            Json.decodeFromString(serializer, it.text().await())
        }

    suspend fun put(path: String, body: dynamic = null) =
        request(
            Request(
                url = "$baseUrl$path",
                method = "PUT",
                body = body,
                headers = mapOf("Content-Type" to "application/json; charset=utf-8")
            )
        ) {}

    suspend fun <T> get(path: String, serializer: KSerializer<T>): T =
        request(
            Request(
                url = "$baseUrl$path",
                method = "GET",
                headers = mapOf("Accept" to "application/json; charset=utf-8")
            )
        ) {
            Json.decodeFromString(serializer, it.text().await())
        }

    suspend fun <T> get(path: String, serializer: KSerializer<T>, parameter: Map<String, String>? = null): T =
        request(
            Request(
                url = "$baseUrl$path" + parameter?.let { pl ->
                    "?" + pl.map { "${it.key}=${it.value}" }.joinToString("&")
                },
                method = "GET",
                headers = mapOf("Accept" to "application/json; charset=utf-8")
            )
        ) {
            Json.decodeFromString(serializer, it.text().await())
        }

    suspend fun getRaw(path: String, parameter: Map<String, String> = mapOf()): String =
        request(
            Request(
                url = "$baseUrl$path" + parameter?.let { pl ->
                    "?" + pl.map { "${it.key}=${it.value}" }.joinToString("&")
                },
                method = "GET",
                headers = mapOf("Accept" to "application/json; charset=utf-8")
            )
        ) { it.text().await() }!!

    suspend fun getRaw(path: String): String =
        request(
            Request(
                url = "$baseUrl$path",
                method = "GET",
                headers = mapOf("Accept" to "application/json; charset=utf-8")
            )
        ) { it.text().await() }!!

    suspend fun get(path: String) =
        request(Request(url = "$baseUrl$path", method = "GET", headers = emptyMap())) {}

    suspend fun getResponse(path: String): Response =
        request(Request(url = "$baseUrl$path", method = "GET", headers = emptyMap())) { it }

    private suspend fun <T> request(
        originalRequest: Request,
        resultProcessor: suspend (Response) -> T,
    ): T {

        val allInterceptors = interceptors.plus(object : Interceptor {
            override suspend fun intercept(chain: Interceptor.Chain): Response {
                val request = chain.request
                val headers =
                    request.headers.map { (key, value) -> key to value }

                return withTimeout(timeMillis = timeout) {
                    window.fetch(request.url, object : RequestInit {
                        override var method: String? = request.method
                        override var body: dynamic = request.body
                        override var credentials: RequestCredentials? = request.credentials ?: "same-origin".asDynamic()
                        override var headers: dynamic = json(*headers.toTypedArray())
                        override var mode: RequestMode? = request.mode ?: undefined
                    }).await()
                }
            }
        })

        val chain = RealInterceptorChain(originalRequest, allInterceptors, 0)

        val response = chain.proceed(originalRequest)

        return resultProcessor(response)
    }

}

data class Request(
    val url: String,
    val method: String,
    val headers: Map<String, String>,
    val body: dynamic = null,
    val mode: RequestMode? = null,
    val credentials: RequestCredentials? = null,
)

interface Interceptor {

    suspend fun intercept(chain: Chain): Response

    interface Chain {
        val request: Request

        suspend fun proceed(request: Request): Response
    }
}

class RealInterceptorChain(override val request: Request, val interceptors: List<Interceptor>, val index: Int) :
    Interceptor.Chain {

    override suspend fun proceed(request: Request): Response {
        return interceptors[index].intercept(RealInterceptorChain(request, interceptors, index + 1))
    }

}