package de.geomobile.frontend.features.vehicleProfile

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardActionArea
import com.ccfraser.muirwik.components.card.mCardActions
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.form.MFormControlMargin
import com.ccfraser.muirwik.components.form.MFormControlVariant
import com.ccfraser.muirwik.components.lab.alert.MAlertSeverity
import com.ccfraser.muirwik.components.lab.alert.MAlertVariant
import com.ccfraser.muirwik.components.lab.alert.mAlert
import com.ccfraser.muirwik.components.list.mList
import com.ccfraser.muirwik.components.list.mListItem
import com.ccfraser.muirwik.components.list.mListItemText
import com.ccfraser.muirwik.components.list.mListSubheader
import com.ccfraser.muirwik.components.styles.Breakpoint
import com.ccfraser.muirwik.components.transitions.mCollapse
import de.geomobile.common.filter.*
import de.geomobile.common.portalmodels.*
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.features.device.list.DeviceListItem
import de.geomobile.frontend.features.device.list.DeviceListItemFilterList
import de.geomobile.frontend.features.device.list.deviceList
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.spacer
import de.geomobile.frontend.utils.*
import kotlinx.browser.localStorage
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.async
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.*
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.builtins.serializer
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.jsonArray
import kotlinx.serialization.json.jsonPrimitive
import org.w3c.dom.get
import org.w3c.dom.set
import react.RBuilder
import react.RProps
import react.RState
import react.setState
import styled.css
import styled.styledDiv

fun RBuilder.vehicleProfilesAssignEdit(
    profileId: String,
    goBack: () -> Unit,
    onDeviceClick: (id: DeviceIdentifier) -> Unit,
) = child(VehicleProfilesAssignEdit::class) {
    attrs.profileId = profileId
    attrs.cancel = { goBack() }
    attrs.saved = { goBack() }
    attrs.onDeviceClick = onDeviceClick
}

class VehicleProfilesAssignEdit : CComponent<VehicleProfilesAssignEdit.Props, VehicleProfilesAssignEdit.State>() {

    interface Props : RProps {
        var profileId: String?
        var cancel: () -> Unit
        var saved: () -> Unit
        var onDeviceClick: (id: DeviceIdentifier) -> Unit
    }

    class State(
        var name: String? = null,
        var company: CompanySmall? = null,
        var description: String? = null,
        var vehicleType: VehicleType? = VehicleType.NONE,
        var previouslyAssignedDevices: String? = null,
        var devicesNotFound: String = "",

        var saving: Boolean = false,

        var validFilter: Boolean = true,
        var assignedDevices: String? = null,
        var deviceCount: Int = 0,
        var deviceFilter: Map<String, Filter<DeviceListItem, *>> = emptyMap(),
        var deviceIds: List<Int> = emptyList(),
        var devicesWithOtherProfile: List<String> = emptyList(),
        var duplicateAssigned: List<String> = emptyList(),
        var previousDeviceIds: List<Int>? = null,
        var invalidEntries: List<String> = emptyList(),
        var showDeviceList: Boolean = localStorage["vehicleProfilesAssignShowDeviceList"]?.toBoolean() ?: true,
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        launch {
            val profile = if (props.profileId != null) withContext(Dispatchers.Default) {
                portalRestApi.get(
                    "/vehicleprofiles/assign/${props.profileId}", VehicleProfileAssignmentDTO.serializer()
                )
            }
            else null

            val alreadyAssignedDevices = withContext(Dispatchers.Default) {
                portalRestApi.get(
                    "/vehicleprofiles/assign/check/${props.profileId}", ListSerializer(String.serializer())
                )
            }

            val companies = async(Dispatchers.Default) {
                portalRestApi.get("/admin/companies", ListSerializer(Company.serializer()))
            }

            val vehicleProfiles = async(Dispatchers.Default) {
                portalRestApi.get("/vehicleprofiles", ListSerializer(VehicleProfileDTO.serializer()))
            }

            val deviceListItemDtoFilters: Map<String, Filter<DeviceListItem, *>> = DeviceListItemFilterList(
                products = Product.values().asList(),
                companies = companies.await(),
                vehicleProfiles = vehicleProfiles.await()
            ).filters

            setState {
                this.name = profile?.name
                this.company = profile?.company
                this.description = profile?.description
                this.vehicleType = profile?.vehicleType
                this.assignedDevices = profile?.assignedString ?: ""
                this.previouslyAssignedDevices = this.assignedDevices
                this.devicesWithOtherProfile = alreadyAssignedDevices

                this.deviceFilter = deviceListItemDtoFilters
            }
        }
    }

    override fun RBuilder.render() {
        spacer()
        mGridContainer2(direction = MGridDirection.row) {
            mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                if (state.name == null) mCircularProgress {
                    css {
                        display = Display.block; margin(LinearDimension.auto)
                    }
                }
                else mCard {
                    css(GlobalStyles.card)
                    mListSubheader(heading = "Profil")
                    mDivider {}
                    mCardContent {
                        css(GlobalStyles.cardListItemContent)

                        styledDiv {
                            css { padding(2.spacingUnits) }

                            mTextField(margin = MFormControlMargin.dense,
                                label = "Zugewiesene Fahrzeugnummer/n",
                                value = state.assignedDevices,
                                variant = MFormControlVariant.outlined,
                                error = !state.validFilter,
                                fullWidth = true,
                                onChange = {
                                    val value = it.targetInputValue
                                    val filterStr = assignedDevicesToFilterString(value)
                                    val parsed = try {
                                        filterStr?.parseFilterRules(state.deviceFilter)
                                    } catch (e: Throwable) {
                                        null
                                    }
                                    val valueList = value.decomposeRangeValues(false)

                                    // get all entries that are not integer values
                                    val invalidEntries = if (parsed != null) {
                                        valueList.filter {
                                            try {
                                                it.toInt()
                                                false
                                            } catch (e: NumberFormatException) {
                                                true
                                            }
                                        }
                                    } else emptyList()

                                    val duplAssigned = if (parsed != null) {
                                        valueList.filter { state.devicesWithOtherProfile.contains(it) }
                                    } else emptyList()

                                    setState {
                                        this.assignedDevices = value
                                        this.validFilter = parsed != null
                                        this.duplicateAssigned = duplAssigned
                                        this.invalidEntries = invalidEntries
                                    }
                                }
                            )
                        }
                        mDivider {}
                        styledDiv {
                            css { padding(2.spacingUnits) }
                            mList {
                                attrs.disablePadding = true

                                mListItem {
                                    attrs.divider = true
                                    attrs.button = false

                                    mListItemText {
                                        mTypography(
                                            text = "Name",
                                            variant = MTypographyVariant.caption,
                                        )
                                        mTypography(
                                            text = state.name!!,
                                            variant = MTypographyVariant.subtitle2,
                                            color = MTypographyColor.textPrimary
                                        )
                                    }
                                }

                                mListItem {
                                    attrs.divider = true
                                    attrs.button = false

                                    mListItemText {
                                        mTypography(
                                            text = "Unternehmen",
                                            variant = MTypographyVariant.caption,
                                        )
                                        mTypography(
                                            text = state.company!!.name,
                                            variant = MTypographyVariant.subtitle2,
                                            color = MTypographyColor.textPrimary
                                        )
                                    }
                                }

                                mListItem {
                                    attrs.divider = false
                                    attrs.button = false

                                    mListItemText {
                                        mTypography(
                                            text = "Beschreibung",
                                            variant = MTypographyVariant.caption,
                                        )
                                        mTypography(
                                            text = state.description ?: "-",
                                            variant = MTypographyVariant.subtitle2,
                                            color = MTypographyColor.textPrimary
                                        )
                                    }
                                }
                            }

                            // Warning if at least one device has another profile
                            if (state.duplicateAssigned.isNotEmpty()) {
                                val warningText =
                                    if (state.duplicateAssigned.size == 1) "Achtung: Dem Gerät ${state.duplicateAssigned.first()} ist bereits ein Fahrzeugprofil zugewiesen."
                                    else "Achtung: Den Geräten ${
                                        state.duplicateAssigned.joinToString(
                                            ", ", limit = 10
                                        )
                                    } sind bereits Fahrzeugprofile zugewiesen."

                                mTypography(
                                    text = warningText,
                                    variant = MTypographyVariant.caption,
                                    color = MTypographyColor.error,
                                    align = MTypographyAlign.left
                                )
                            }

                            // warning if at least one entry is not an integer
                            if (state.invalidEntries.isNotEmpty()) {
                                val warningText =
                                    if (state.invalidEntries.size == 1) "Achtung: Die eingegebene Fahrzeugnummer ${state.invalidEntries.first()} ist ungültig (nur Ziffern von 0-9 sind erlaubt)."
                                    else "Achtung: Die eingegebene Fahrzeugnummern ${
                                        state.invalidEntries.joinToString(
                                            ", ", limit = 10
                                        )
                                    } sind ungültig (nur Ziffern von 0-9 sind erlaubt)."

                                mTypography(
                                    text = warningText,
                                    variant = MTypographyVariant.caption,
                                    color = MTypographyColor.error,
                                    align = MTypographyAlign.left
                                )
                            }
                        }
                    }
                    mDivider {}
                    mCardActions {
                        css { padding(2.spacingUnits) }
                        mGridContainer2(direction = MGridDirection.row) {
                            mGridItem2(
                                MGridBreakpoints2(MGridSize2.Auto).down(Breakpoint.xs, MGridSize2.Cells12)
                            ) {
                                mButton(caption = if (state.saving) "Lädt" else "Zuweisen",
                                    variant = MButtonVariant.contained,
                                    color = MColor.secondary,
                                    disabled = !state.validFilter || state.duplicateAssigned.isNotEmpty() || state.invalidEntries.isNotEmpty() || state.saving,
                                    onClick = {
                                        setAssignedDevices()
                                    }) {
                                    attrs.disableElevation = true
                                    attrs.fullWidth = true
                                }
                            }
                            mGridItem2(
                                MGridBreakpoints2(MGridSize2.Auto).down(Breakpoint.xs, MGridSize2.Cells12)
                            ) {
                                mButton(variant = MButtonVariant.contained,
                                    caption = "Abbrechen",
                                    color = MColor.default,
                                    onClick = { props.cancel() }) {
                                    attrs.disableElevation = true
                                    attrs.fullWidth = true
                                }
                            }
                        }
                    }
                }
            }
            if (state.devicesNotFound != "") mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                mAlert(
                    variant = MAlertVariant.filled,
                    severity = MAlertSeverity.error,
                    message = "Nicht gefunden: ${state.devicesNotFound}"
                )
            }
            mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                filteredDevices()
            }
        }
    }

    private fun RBuilder.filteredDevices() {
        mCard {
            val useFilter = if (state.validFilter) {
                state.assignedDevices
            } else {
                state.previouslyAssignedDevices
            }

            val idsInUseFilter = useFilter?.decomposeRangeValues(false)

            val filterString = assignedDevicesToFilterString(useFilter)

            if (state.deviceFilter.isNotEmpty() && filterString != null) {
                val filter = filterString.parseFilterRules(state.deviceFilter)

                val matcher = FilterMatcher(
                    filterRules = FilterRules(rules = filter.rules),
                    filters = state.deviceFilter
                )

                mCardActionArea(
                    onClick = {
                        localStorage["vehicleProfilesAssignShowDeviceList"] = (!state.showDeviceList).toString()
                        setState { this.showDeviceList = !this.showDeviceList }
                    }
                ) {
                    attrs.component = "div"
                    mCardHeaderExtended(
                        title = "${state.deviceCount} von ${idsInUseFilter?.count()} Geräte gefunden",
                        action = mIconButtonNoTranslate(
                            if (state.showDeviceList) "expand_less" else "expand_more",
                            onClick = {
                                localStorage["vehicleProfilesAssignShowDeviceList"] = (!state.showDeviceList).toString()
                                setState { this.showDeviceList = !this.showDeviceList }
                            },
                            addAsChild = false
                        )
                    )
                }
                mCollapse(state.showDeviceList) {
                    mDivider { }
                    deviceList(
                        persistenceId = "VehicleProfile",
                        onDeviceClick = { props.onDeviceClick(it) },
                        filter = matcher,
                        onFilteredDevicesChanged = {
                            setState {
                                //save devices for the old filter (on the first run)
                                if (previousDeviceIds == null) previousDeviceIds = it.toList()
                                else deviceIds = it.toList()

                                if (deviceCount != it.count()) deviceCount = it.count()
                            }
                        },
                        onGetNotMatchedRules = { notMatched ->
                            setState {
                                devicesNotFound =
                                    notMatched.rules.first { it.filterId == "vehicleId" && it.rule.operatorId == "IN" }.rule.ref.jsonArray.flatMap {
                                        listOf(it.jsonPrimitive.content)
                                    }.joinToString(", ")
                            }
                        }
                    )
                }
            }
        }
    }

    private fun assignedDevicesToFilterString(filter: String?) =
        if (filter != null && state.company != null) "Unternehmen == ${state.company!!.name} AND Fahrzeugnummer in [$filter]"
        else null

    private fun setAssignedDevices() {
        setState { this.saving = true }
        launch {
            withContext(Dispatchers.Default) {
                val deviceList = state.assignedDevices?.decomposeRangeValues(false) ?: emptyList()
                //save assignedDevices
                portalRestApi.put(
                    "/vehicleprofiles/assign/${props.profileId}",
                    body = Json.encodeToJsonElement(ListSerializer(String.serializer()), deviceList)
                )
            }
            props.saved()
        }
    }
}
