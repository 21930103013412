package de.geomobile.common.portalmodels

import DeviceLANStatusDTO
import de.geomobile.common.time.LocalDateTime
import kotlinx.serialization.Serializable

@Serializable
data class CableCheck(
    val systemTime: Entry<LocalDateTime>? = null,
    val bootTime: Entry<LocalDateTime>? = null,
    val inputs: List<Entry<ToggleState>?> = (1..4).map { null },
    val relays: List<Entry<ToggleState>?> = (1..5).map { null },
    val lte: Entry<DeviceCellularStatusDTO>? = null,
    val gps: Entry<Position>? = null,
    val wifi: Entry<DeviceWifiStatusDTO>? = null,
    val lan: Entry<DeviceLANStatusDTO>? = null,
    val ble: Entry<Boolean>? = null,
    val ibis: Entry<IbisState>? = null,
    val ibisLog: String? = null,
    val fmsLog: String? = null,
    val audio: Entry<ToggleState>? = null,
    val overshoot: Entry<ToggleState>? = null
) {

    @Serializable
    data class Entry<T>(
        val value: T,
        val raw: String,
        val timestamp: LocalDateTime
    )

    enum class ToggleState { ON, OFF, ERROR }
    enum class IbisState { ON, OFF, CHECKING, ERROR }

}
