package portalmodels

import kotlinx.serialization.Serializable


enum class SnippetType(val readableName: String) {
    TEXT("TEXT"),
    PLACEHOLDER("PLATZHALTER"),
    SOUND("SOUND"),
    DOORSIGNAL("TÜRSIGNAL"),
    WARNSIGNAL("WARNSIGNAL"),
    PAUSE("PAUSE"),
}

val placeholder = mapOf(
    "line" to "Linie",
    "station" to "Haltestelle",
    "time" to "Uhrzeit",
    "delay" to "Verspätung",
    "destination" to "Richtung",
    "interstop" to "Zwischenstop",
)

val placeholderRandom = mapOf(
    "line" to listOf("4 6 5", "Uh 79", "Ess 1"),
    "station" to listOf("Dortmund Hbf", "Königsallee", "Lindemannstr."),
    "time" to listOf("6:00 Uhr", "12:00 Uhr", "18:00 Uhr"),
    "delay" to listOf("5 Minuten", "1 Stunde", "1 Stunde und 30 Minuten"),
    "destination" to listOf("Hauptbahnhof", "Universität", "Hörde"),
    "interstop" to listOf("Huckarde", "klein Möhringen", "Yorkstraße"),
)

@Serializable
data class TtsWordReplacementDTO(
    val id: Int,
    val regex: String,
    val replacement: String,
)

@Serializable
data class TtsSymbolReplacementDTO(
    val id: Int,
//    var companyId: String,
    var symbolId: String,
    var symbolName: String,
    var symbolText: String,

)

@Serializable
data class TtsPhrasesDTO(
    val phraseId: Int,
    val companyId: String,
    val snippets: List<TtsSnippetDTO>
)

@Serializable
data class TtsSnippetDTO(
    val snippetId: Int? = null,
    val position: Int,
    val type: SnippetType,
    val content: String? = null,
    val volume: Int? = null,
    val durationMillis: Int? = null
)

@Serializable
data class TtsStationDTO(
    val name: String,
    val ibisName: String,
    val volume: Int
)

@Serializable
data class TtsConfigDTO (
    val companyId: String,
    val phrases: List<TtsPhrasesDTO>,
    val schedule: TtsScheduleDTO,
    val stations: List<TtsStationDTO>,
    val soundList: List<TtsSoundFileDTO>,
    val doorSignalId: Int? = null,
    val phraseLength: Int? = null,
)

@Serializable
data class TtsScheduleDTO(
    val scheduleId: Int? = null,
    val defaultVolume: Int,
    val monday:    List<TtsScheduleEntryDTO>,
    val tuesday:   List<TtsScheduleEntryDTO>,
    val wednesday: List<TtsScheduleEntryDTO>,
    val thursday:  List<TtsScheduleEntryDTO>,
    val friday:    List<TtsScheduleEntryDTO>,
    val saturday:  List<TtsScheduleEntryDTO>,
    val sunday:    List<TtsScheduleEntryDTO>
) {
    companion object {
        val empty: TtsScheduleDTO
            get() = TtsScheduleDTO(
                scheduleId = null,
                defaultVolume = 1,
                monday = emptyList(),
                tuesday = emptyList(),
                wednesday = emptyList(),
                thursday = emptyList(),
                friday = emptyList(),
                saturday = emptyList(),
                sunday = emptyList()
            )
    }
}



@Serializable
data class TtsScheduleEntryDTO(
    val from: String,
    val to: String,
    val volume: Int
)

@Serializable
data class TtsSoundFileDTO(
    val soundFileId: Int,
    val name: String,
    val companyId: String,
    val isDoorSignal: Boolean = false,
    val content: ByteArray? = null
)

@Serializable
data class TtsAnnouncementRequestDTO(
    val placeholder: Map<String, String>
)

data class TtsWordCorrectionDTO(
    val word: String,
    val replacement: String
)