package de.geomobile.common.filter

import kotlinx.serialization.json.JsonArray
import kotlinx.serialization.json.JsonNull
import kotlinx.serialization.json.JsonPrimitive


fun String.parseFilterRules(filters: Map<String, Filter<*, *>>): FilterRules {
    val rules = split(Regex("AND(?![^\\[]*\\])", RegexOption.IGNORE_CASE))
            .map { it.trim().parseFilterRule(filters) }
    return FilterRules(rules)
}

fun String.parseFilterRule(filters: Map<String, Filter<*, *>>): FilterRule {
    var line = this.trim()
    val filter = filters.values.single { line.startsWith(it.queryName + " ", ignoreCase = true) }

    line = line.drop(filter.queryName.length).trim()
    val operator = filter.operators.single {
        if (it.relational)
            line.startsWith(it.operator + " ", ignoreCase = true)
        else
            line.equals(it.operator, ignoreCase = true)
    }

    line = line.drop(operator.operator.length).trim()
    val value = when {
        !operator.relational -> {
            check(line.isBlank())
            JsonNull
        }

        operator.arrayOperation -> {
            check(line.contains(Regex("\\[.*\\]")))
            JsonArray(
                    line.removeSurrounding("[", "]")
                            .decomposeRangeValues(true)
                            .map {
                                when (filter) {
                                    is FilterLong -> JsonPrimitive(filter.stringToValue(it.trim()))
                                    is FilterDouble -> JsonPrimitive(filter.stringToValue(it.trim()))
                                    is FilterString -> JsonPrimitive(filter.stringToValue(it.trim()))
                                    is FilterEnumerableInt -> JsonPrimitive(filter.stringToValue(it.trim()))
                                    is FilterEnumerableString -> JsonPrimitive(filter.stringToValue(it.trim()))
                                }
                            }
            )
        }

        else -> {
            check(!line.contains(Regex("[\\[,\\]]")))
            when (filter) {
                is FilterLong -> JsonPrimitive(filter.stringToValue(line))
                is FilterDouble -> JsonPrimitive(filter.stringToValue(line))
                is FilterString -> JsonPrimitive(filter.stringToValue(line))
                is FilterEnumerableInt -> JsonPrimitive(filter.stringToValue(line))
                is FilterEnumerableString -> JsonPrimitive(filter.stringToValue(line))
            }
        }
    }
    return FilterRule(filterId = filter.id, rule = Filter.Rule(ref = value, operatorId = operator.id))
}

fun String.decomposeRangeValues(useChecks: Boolean = false) =
        this.split(",")
                .flatMap { element ->
                    // if the element contains "-" it might be a range
                    if (element.contains("-")) {
                        val range = element.split("-")
                                .map {
                                    it.trim()
                                }
                        //check if its a range between two numbers
                        if (range.size == 2) {
                            try {
                                val from = range[0].toInt()
                                val to = range[1].toInt()
                                // only allow ranges up to 2000 elements
                                // (otherwise it will take too long to load the devices)
                                if (useChecks) {
                                    check(to - from < 2000)
                                    check(to >= from)
                                }
                                (from..to).map { it.toString() }
                            } catch (e: NumberFormatException) {
                                // if at least one of the interval limits is not a number -> its not a range
                                listOf(element)
                            }
                        } else listOf(element)
                    } else listOf(element)
                }
                .distinct()
                .map { it.trim()}
                .filter { it != "" }
