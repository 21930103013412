import de.geomobile.common.portalmodels.TimestampStatus
import kotlinx.serialization.Serializable

@Serializable
data class DeviceLANStatusDTO (
    val timestampStatus: TimestampStatus ? = null,
    val ip: String? = null,
    val sm: String? = null,
    val gateway: String? = null,
    val netinterface: String? = null,
    val mac: String? = null,
    val connected: Boolean,
)