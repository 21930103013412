package de.geomobile.frontend.features.tts

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.menu.mMenuItem
import de.geomobile.common.permission.Permission
import de.geomobile.common.permission.Permissions
import de.geomobile.common.portalmodels.Company
import de.geomobile.common.portalmodels.CompanySmall
import de.geomobile.common.portalmodels.UserDTO
import de.geomobile.common.portalmodels.small
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.spacer
import de.geomobile.frontend.utils.CComponent
import de.geomobile.frontend.utils.isAuthorized
import de.geomobile.frontend.utils.mToolbarTitle2
import kotlinx.browser.localStorage
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.async
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.*
import kotlinx.serialization.builtins.ListSerializer
import org.w3c.dom.get
import org.w3c.dom.set
import react.*
import react.router.dom.redirect
import react.router.dom.route
import react.router.dom.switch
import styled.css
import styled.styledDiv

fun RBuilder.textToSpeechDashboard(
    path: String,
    drawerMenu: ReactElement,
) = child(TextToSpeechDashboard::class) {
    attrs.path = path
    attrs.drawerMenu = drawerMenu
}

class TextToSpeechDashboard : CComponent<TextToSpeechDashboard.Props, TextToSpeechDashboard.State>() {

    private val tabs = listOf(
        Tab(
            label = "Neu",
            route = "/new",
            viewPermission = Permissions.StatisticsManagement.wifiStatView // TODO add correct viewPermission
        ) { textToSpeechNew() },
    )

    private data class Tab(
        val label: String,
        val route: String,
        val viewPermission: Permission? = Permissions.StatisticsManagement.statView, // TODO add correct viewPermission
        val builder: RBuilder.(path: String) -> ReactElement,
    )

    interface Props : RProps {
        var path: String
        var drawerMenu: ReactElement
    }

    class State(
        var company: CompanySmall? = null,
        var companies: List<Company>? = null,
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        launch {
            val deferredCompanies = if (isAuthorized(Permissions.CompanyProfileManagement.notRestrictedToCompany))
                async(Dispatchers.Default) {
                    portalRestApi.get("/admin/companies", ListSerializer(Company.serializer()))
                }
            else {
                null
            }

            val ownUser = withContext(Dispatchers.Default) {
                portalRestApi.get("/user", UserDTO.serializer())
            }

            val companies = deferredCompanies?.await()

            val localStorageCompanyId = try {
                localStorage["TextToSpeechCompany"]
            } catch (e: NoSuchElementException) {
                null
            }

            val company =
                if ((localStorageCompanyId != null) and (localStorageCompanyId != "null")) {
                    companies?.first { it.id == localStorageCompanyId }?.small
                } else {
                    localStorage["TextToSpeechCompany"] = ownUser.company.id
                    ownUser.company.small
                }

            setState {
                this.company = company
                this.companies = companies
            }
        }
    }

    override fun RBuilder.render() {

        val companies = state.companies
        val permittedTabs = tabs.filter { isAuthorized(*listOfNotNull(it.viewPermission).toTypedArray()) }

        if (permittedTabs.isNotEmpty())
            route<RProps>(props.path) { routeProps ->
                mAppBar(position = MAppBarPosition.fixed) {
                    css(GlobalStyles.appbar)

                    val currentRoute = routeProps.history.location.pathname
                    val selectedTab =
                        permittedTabs.firstOrNull { currentRoute.startsWith(props.path + it.route) }
                            ?: permittedTabs.first()

                    mToolbar {
                        child(props.drawerMenu)
                        mToolbarTitle2("TTS")
                        if (companies != null) {
                            mTypography(text = "Unternehmen", color = MTypographyColor.inherit) {
                                css {
                                    display = Display.inline; marginRight = 1.spacingUnits
                                }
                            }
                            mSelect(
                                value = state.company?.id ?: "",
                                name = "company",
                                id = "company",
                                onChange = { event, _ ->
                                    val id = event.targetValue as String
                                    localStorage["TextToSpeechCompany"] = id
                                    routeProps.history.push(props.path)
                                    setState { this.company = companies.first { it.id == id }.small }
                                }
                            ) {
                                css {
                                    marginTop = 2.pt
                                    color = Color.white
                                }
                                for (company in companies) {
                                    mMenuItem(primaryText = company.name, value = company.id)
                                }
                            }
                        }
                    }
                    mTabs(
                        value = props.path + selectedTab.route,
                        centered = true,
                        onChange = { _, value -> routeProps.history.push(value as String) }
                    ) {
                        for (tab in permittedTabs) {
                            mTab(tab.label, props.path + tab.route)
                        }
                    }
                }
            }
        spacer()
        styledDiv {
            css { padding(2.spacingUnits) }
            switch {
                if (permittedTabs.isNotEmpty()) {
                    for (tab in permittedTabs) {
                        this.route<RProps>(props.path + tab.route) { routeProps ->
                            tab.builder(this, routeProps.match.path)
                        }
                    }
                    redirect(from = props.path, to = props.path + permittedTabs.first().route, exact = true)
                } else
                    redirect(from = props.path, to = "", exact = true)
            }
        }
    }
}