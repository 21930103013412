package de.geomobile.common.portalmodels

import kotlinx.serialization.Serializable

@Serializable
data class IbisSniffer(
    val status: TimestampStatus? = null,
    val stop_ann: List<ToggleState>? = null, //Ansagegeräte
    val display: List<ToggleState>? = null, //Anzeigen
    val validator: List<ToggleState>? = null, //Entwerter
    val counter: List<ToggleState>? = null, //Fahrgastzähler
    val eks: List<ToggleState>? = null, // EKS (Einstiegsskontrollsystem)
    val lsa: List<ToggleState>? = null // LSA (Ampelbeeinflussung)
) {
    enum class ToggleState { ON, OFF, NA }
}

@Serializable
data class IbisSnifferJSON(
        val status: TimestampStatus = TimestampStatus(),
        val stop_ann: List<String>? = null, //Ansagegeräte
        val display: List<String>? = null, //Anzeigen
        val validator: List<String>? = null, //Entwerter
        val counter: List<String>? = null, //Fahrgastzähler
        val eks: List<String>? = null, // EKS (Einstiegsskontrollsystem)
        val lsa: List<String>? = null // LSA (Ampelbeeinflussung)
)
