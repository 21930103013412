package de.geomobile.frontend.features.device.detail.selftest

import com.ccfraser.muirwik.components.mDivider
import com.ccfraser.muirwik.components.spacingUnits
import de.geomobile.common.portalmodels.SelfTestDTO
import de.geomobile.common.time.LocalDateTime
import de.geomobile.frontend.portalWebSocketApi
import kotlinx.css.padding
import kotlinx.serialization.json.Json
import react.*
import styled.css
import styled.styledDiv

fun RBuilder.deviceSelfTest(
    id: Int,
) = child(DeviceSelfTestComponent::class) {
    key = id.toString()
    attrs.id = id
}

class DeviceSelfTestComponent : RComponent<DeviceSelfTestComponent.Props, DeviceSelfTestComponent.State>() {

    private val deviceM2MSocket by kotlin.lazy {
        portalWebSocketApi.subscribe("/device/selftest", mapOf("id" to props.id.toString()))
    }

    interface Props : RProps {
        var id: Int
    }

    class State(
        var timestamp: LocalDateTime? = null,
        var selfTestResultJson: String? = null,
        var selfTestResult: SelfTestResult? = null,
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        deviceM2MSocket.connect {
            onmessage = {
                val dto = Json.decodeFromString(SelfTestDTO.serializer(), it)

                val result = dto.rawJson?.let {
                    try {
                        Json.decodeFromString(SelfTestResult.serializer(), it)
                    } catch (e: Throwable) {
                        null
                    }

                }
                setState {
                    this.timestamp = dto.timestamp
                    this.selfTestResultJson = dto.rawJson
                    this.selfTestResult = result
                }
            }
        }
    }

    override fun componentWillUnmount() {
        deviceM2MSocket.close()
    }

    override fun RBuilder.render() {
        styledDiv {
            css { padding(2.spacingUnits) }
            deviceSelfTestHeader(
                id = props.id,
                timestamp = state.timestamp,
                selfTestResult = state.selfTestResult,
                selfTestResultJson = state.selfTestResultJson
            )
        }
        mDivider { }
        deviceSelfTestResult(state.selfTestResult)
    }
}