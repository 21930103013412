package portalmodels

import de.geomobile.common.portalmodels.CompanySmall
import kotlinx.serialization.Serializable

@Serializable
data class CompanyDepotDTO(
    var company: CompanySmall,
    var name: String,
    var lat: Double,
    var lon: Double,
    var radius: Double
)

@Serializable
data class CompanyDepotApiDTO(
    var name: String,
    var lat: Double,
    var lon: Double,
    var radius: Double
)