package portalmodels

import hardware.AssemblyLifecycle
import kotlinx.serialization.Serializable

@Serializable
data class ProductAssemblyDTO(
    val id: Int,
    val parentId: Int,
    val name: String,
    val materialNumber: Int,
    val revision: String,
    val lifecycle: AssemblyLifecycle,
    val isCategory: Boolean,
)