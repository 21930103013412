package de.geomobile.common.portalmodels

import kotlinx.serialization.Serializable


@Serializable
data class DeviceWifiStatusDTO(
    val status: TimestampStatus = TimestampStatus(),
    val modules: List<DeviceWifiModuleDTO>? = null
)

@Serializable
data class DeviceWifiModuleDTO(
    val number: Int? = null,
    val clientInterfaces: List<ClientInterfaceDTO>? = null,
    val accessPointInterfaces: List<AccessPointInterfaceDTO>? = null
)

@Serializable
data class ClientInterfaceDTO(
    val interfaceName: String? = null,
    val freq: String? = null,
    val ssid: String? = null,
    val mode: String? = null,
    val keyMgmt: String? = null,
    val wpaState: String? = null,
    val ipAddress: String? = null,
    val rssi: String? = null,
    val bssid: String? = null,
    val address: String? = null,
    val linkSpeed: String? = null,
    val noise: String? = null,
    val width: String? = null,
    val enabled: Boolean? = null
)

@Serializable
data class AccessPointInterfaceDTO(
    val interfaceName: String? = null,
    val freq: String? = null,
    val ssid: String? = null,
    val bssid: String? = null,
    val state: String? = null,
    val channel: Int? = null,
    val numSta: Int? = null,
    val keyMgmt: String? = null,
    val groupCipher: String? = null,
    val wpaPairwiseCipher: String? = null,
    val enabled: Boolean? = null
)