package de.geomobile.frontend.features.device.detail.internal

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonSize
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.dialog.DialogScroll
import com.ccfraser.muirwik.components.dialog.mDialog
import com.ccfraser.muirwik.components.dialog.mDialogActions
import com.ccfraser.muirwik.components.dialog.mDialogContent
import com.ccfraser.muirwik.components.form.MFormControlMargin
import com.ccfraser.muirwik.components.form.MFormControlVariant
import com.ccfraser.muirwik.components.list.mList
import com.ccfraser.muirwik.components.list.mListItem
import com.ccfraser.muirwik.components.list.mListItemSecondaryAction
import com.ccfraser.muirwik.components.list.mListItemText
import com.ccfraser.muirwik.components.menu.mMenuItem
import de.geomobile.common.portalmodels.M2MStatusDTO
import de.geomobile.common.portalmodels.shortTopic
import de.geomobile.frontend.currentTheme
import de.geomobile.frontend.portalWebSocketApi
import de.geomobile.frontend.utils.toText
import kotlinx.css.*
import kotlinx.serialization.json.Json
import react.*
import reactjsonview.reactJson
import styled.css
import styled.styledDiv

fun RBuilder.m2mLog(
    id: Int,
) = child(M2MLogComponent::class) {
    key = id.toString()
    attrs.id = id
}

class M2MLogComponent : RComponent<M2MLogComponent.Props, M2MLogComponent.State>() {

    private val deviceM2MSocket by kotlin.lazy {
        portalWebSocketApi.subscribe("/device/m2m", mapOf("id" to props.id.toString()))
    }

    enum class SortMode(val text: String) {
        TOPIC("Topic"), TIMESTAMP("Timestamp")
    }

    interface Props : RProps {
        var id: Int
    }

    class State(
        var m2mStatus: M2MStatusDTO = M2MStatusDTO(),
        var expanded: List<String> = emptyList(),
        var sortMode: SortMode = SortMode.TOPIC,
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        deviceM2MSocket.connect {
            onmessage = {
                val status = Json.decodeFromString(M2MStatusDTO.serializer(), it)
                setState {
                    this.m2mStatus = status
                }
            }
        }
    }

    override fun componentWillUnmount() {
        deviceM2MSocket.close()
    }

    override fun RBuilder.render() {
        val m2mStatus = state.m2mStatus

        styledDiv {
            css { padding(2.spacingUnits) }
            mTypography(text = "Sortieren nach") { css { display = Display.inline; marginRight = 1.spacingUnits } }
            mSelect(
                variant = MFormControlVariant.outlined,
                value = state.sortMode.toString(),
                onChange = { event, _ ->
                    val mode = SortMode.valueOf(event.targetValue as String)
                    setState { this.sortMode = mode }
                }
            ) {
                attrs.margin = MFormControlMargin.dense.toString()

                for (mode in SortMode.values()) {
                    mMenuItem(primaryText = mode.text, value = mode.toString())
                }
            }
        }
        mDivider {}
        styledDiv {
            css { padding(2.spacingUnits) }

            if (m2mStatus.messageLog.isEmpty()) {
                mTypography(
                    text = "Keine Daten verfügbar",
                    variant = MTypographyVariant.body1,
                    align = MTypographyAlign.center
                ) { css { padding(2.spacingUnits) } }
            } else {
                mList {
                    attrs.dense = true

                    for ((index, message) in m2mStatus.messageLog
                        .sortedWith(
                            when (state.sortMode) {
                                SortMode.TOPIC -> compareBy { it.topic }
                                SortMode.TIMESTAMP -> compareByDescending { it.timestamp.millis }
                            }
                        )
                        .withIndex()) {

                        val expanded =
                            if (message.message.lines().filter { it.isNotBlank() }.count() > 1)
                                state.expanded.contains(message.topic)
                            else
                                null

                        mListItem {
                            attrs.button = false
                            attrs.divider = true

                            mListItemText {
                                mTypography(
                                    text = shortTopic(message),
                                    variant = MTypographyVariant.subtitle2,
                                    component = "div"
                                ) { css { textTransform = TextTransform.uppercase } }
                                mTypography(
                                    text = message.message,
                                    variant = MTypographyVariant.caption,
                                    component = "div"
                                ) { css { paddingTop = 3.px; } }
                                mTypography(
                                    text = "API Level: ${message.version}, Datum: ${message.timestamp.toText()}",
                                    variant = MTypographyVariant.caption
                                ) { css { color = Color.grey } }
                            }
                            if (expanded != null)
                                mListItemSecondaryAction {
                                    css {
                                        top = 4.spacingUnits
                                        right = 0.px
                                        position = Position.absolute
                                    }
                                    mIconButtonNoTranslate(
                                        iconName = "visibility",
                                        onClick = {
                                            setState {
                                                this.expanded = if (expanded) this.expanded - message.topic
                                                else this.expanded + message.topic
                                            }
                                        },
                                        color = MColor.inherit,
                                        size = MButtonSize.small,
                                        addAsChild = true
                                    ) {
                                        css {
                                            color = Color.white
                                            background = currentTheme.palette.secondary.main
                                        }
                                    }
                                }
                        }

                        mDialog(open = expanded == true, scroll = DialogScroll.paper) {
                            mDialogContent {
                                try {
                                    reactJson(src = message.message)
                                } catch (e: Exception) {
                                    mTypography(text = "Invalid JSON")
                                }
                            }

                            mDialogActions {
                                mButton(
                                    caption = "Schließen",
                                    color = MColor.primary,
                                    onClick = {
                                        setState {
                                            this.expanded = emptyList()
                                        }
                                    }
                                )
                            }
                        }
                    }
                }
            }
        }
    }
}