package de.geomobile.common.portalmodels


// Order (ordinal) important for other service member like apps and livemap.
enum class VehicleType(val readableName: String) {
    NONE("-"),
    BUS("Bus"),
    TRAIN("Bahn"),
    SUBWAY("U-Bahn"),
    TRAM("Tram"),
    TRAM_GER("Straßenbahn"),
    LIGHT_RAIL("Stadtbahn");
}