package de.geomobile.frontend.features.admin.companies

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonSize
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.table.*
import de.geomobile.common.permission.Permissions
import de.geomobile.common.portalmodels.Company
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.utils.*
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.Color
import kotlinx.css.background
import kotlinx.css.color
import kotlinx.css.margin
import kotlinx.serialization.builtins.ListSerializer
import react.RBuilder
import react.RProps
import react.RState
import react.setState
import styled.css

fun RBuilder.adminCompanyList(
    companySelected: (companyId: String) -> Unit,
    createCompany: () -> Unit,
) = child(AdminCompanyList::class) {
    attrs.companySelected = companySelected
    attrs.createCompany = createCompany
}

class AdminCompanyList : CComponent<AdminCompanyList.Props, AdminCompanyList.State>() {

    interface Props : RProps {
        var companySelected: (companyId: String) -> Unit
        var createCompany: () -> Unit
    }

    class State(
        var companies: List<Company>? = null,
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        launch {
            val companies = withContext(Dispatchers.Default) {
                portalRestApi.get("/admin/companies", ListSerializer(Company.serializer()))
            }

            setState { this.companies = companies }
        }
    }

    override fun RBuilder.render() {
        val companies = state.companies

        mGridContainer2(direction = MGridDirection.row) {
            mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                authorize(Permissions.CompanyManagement.companiesEdit) {
                    mButton(
                        caption = "Unternehmen erstellen",
                        variant = MButtonVariant.contained,
                        color = MColor.secondary,
                        onClick = { props.createCompany() }
                    ) {
                        attrs.disableElevation = true
                    }
                }
            }
            mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                mCard {
                    mTableContainer {
                        mTable {
                            attrs.asDynamic().style = kotlinext.js.js { cursor = "pointer" }
                            mTableHead {
                                mTableRowSlim {
                                    mTableCell(align = MTableCellAlign.left) { +"Name" }
                                    mTableCell(align = MTableCellAlign.left) { +"Beschreibung" }
                                    mTableCell(align = MTableCellAlign.right) { }
                                }
                            }
                            mTableBody {
                                if (companies.isNullOrEmpty()) {
                                    mTableRowSlim {
                                        mTableCell(
                                            colSpan = 3,
                                            align = MTableCellAlign.center
                                        ) {
                                            if (companies == null)
                                                mCircularProgress { css { margin(1.spacingUnits) } }
                                            else
                                                +"empty"
                                        }
                                    }
                                } else {
                                    for (company in companies) {
                                        mTableRowSlim {
                                            attrs.key = company.id
                                            attrs.hover = true
                                            attrs.onClick = { props.companySelected(company.id) }

                                            mTableCell(
                                                align = MTableCellAlign.left,
                                                size = MTableCellSize.small
                                            ) {
                                                +company.name
                                            }
                                            mTableCell(
                                                align = MTableCellAlign.left,
                                                size = MTableCellSize.small
                                            ) {
                                                +(company.description ?: "-")
                                            }
                                            mTableCell(
                                                align = MTableCellAlign.right,
                                                size = MTableCellSize.small
                                            ) {
                                                authorize(Permissions.CompanyManagement.companiesEdit) {
                                                    mIconButtonNoTranslate(
                                                        iconName = "edit",
                                                        size = MButtonSize.small,
                                                        color = MColor.inherit,
                                                        onClick = { props.companySelected(company.id) }
                                                    ) {
                                                        css { background = "#2196f3"; color = Color.white; }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}