package de.geomobile.frontend.features.tts

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonSize
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.form.MFormControlMargin
import com.ccfraser.muirwik.components.form.MFormControlVariant
import com.ccfraser.muirwik.components.list.mListSubheader
import com.ccfraser.muirwik.components.table.*
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.spacer
import de.geomobile.frontend.utils.*
import kotlinext.js.assign
import kotlinx.browser.localStorage
import kotlinx.coroutines.launch
import kotlinx.css.*
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.json.Json
import org.w3c.dom.get
import portalmodels.TtsSymbolReplacementDTO
import react.RBuilder
import react.RProps
import react.RState
import react.router.dom.route
import react.setState
import styled.css
import styled.styledDiv

fun RBuilder.textToSpeechSymbolReplacement() = child(TextToSpeechSymbolReplacement::class) {}

class TextToSpeechSymbolReplacement : CComponent<TextToSpeechSymbolReplacement.Props, TextToSpeechSymbolReplacement.State>() {
    interface Props : RProps

    class State(
        var company: String? = null,
        var replacements: List<TtsSymbolReplacementDTO> = listOf(),
        var newReplacement: TtsSymbolReplacementDTO? = null,
    ) : RState

    init {
        state = State()
    }

    fun fetchReplacements(company: String) {
        launch {
            val replacements = portalRestApi.get("/tts/symbol/replacements/${company}", ListSerializer(TtsSymbolReplacementDTO.serializer()))
            setState {
                this.replacements = replacements
            }
        }
    }

    fun saveReplacement() {
        val replacements = state.newReplacement?.let {
            state.replacements + it
        } ?: state.replacements
        launch {
            portalRestApi.post("/tts/symbol/replacements/${state.company}/save", Json.encodeToString(ListSerializer(TtsSymbolReplacementDTO.serializer()), replacements))
        }.invokeOnCompletion {
            state.company?.let {
                fetchReplacements(it)
            }
        }
    }

    override fun componentDidMount() {
        localStorage["TextToSpeechCompany"]?.let {
            fetchReplacements(it)
            setState {
                company = it
            }
        }
    }

    override fun RBuilder.render() {
        route<RProps>("/tts/new/symbolreplacement") { _ ->
            spacer()
            mGridContainer2(direction = MGridDirection.row) {
                mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                    mCard {
                        css(GlobalStyles.card)
                        mCardContent {
                            createReplacement()
                        }
                    }
                }
                mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                    mCard {
                        css(GlobalStyles.card)
                        mCardContent {
                            correctionsListTable()
                        }
                    }
                }
            }
        }
    }

    private fun RBuilder.correctionsListTable() =
        mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
            styledDiv {
                css {
                    display = Display.flex
                    justifyContent = JustifyContent.spaceBetween
                    alignItems = Align.center
                }
                mListSubheader(heading = "Symbolliste")
            }
            mDivider { }
            mTableContainer {
                css { paddingRight = 1.spacingUnits }
                mTable {
                    mTableHead {
                        mTableRowSlim {
                            mTableCell(align = MTableCellAlign.left) {
                                css { padding(2.spacingUnits) }
                                +"Symbol Name"
                            }
                            mTableCell(align = MTableCellAlign.left) {
                                css { padding(2.spacingUnits) }
                                +"Symbol ID"
                            }
                            mTableCell(align = MTableCellAlign.left) {
                                css { padding(2.spacingUnits) }
                                +"Symbol Korrektur"
                            }
                            mTableCell(align = MTableCellAlign.left) {
                                css { padding(2.spacingUnits) }
                                +"Abspielen"
                            }
                            mTableCell(align = MTableCellAlign.left) {
                                css { padding(2.spacingUnits) }
                                +"Löschen"
                            }
                        }
                    }
                    mTableBody {
                        state.replacements.forEach { replacement ->
                            mTableRowSlim {
                                mTableCell(align = MTableCellAlign.left) {
                                    css { padding(0.spacingUnits, 2.spacingUnits) }
                                    +replacement.symbolName
                                }
                                mTableCell(align = MTableCellAlign.left) {
                                    css { padding(0.spacingUnits, 2.spacingUnits) }
                                    +replacement.symbolId
                                }
                                mTableCell(align = MTableCellAlign.left) {
                                    css { padding(0.spacingUnits, 2.spacingUnits) }
                                    +replacement.symbolText
                                }
                                mTableCell(align = MTableCellAlign.left) {
                                    css { padding(0.spacingUnits, 2.spacingUnits) }
                                    audioPlayer(state.company ?: "", replacement.symbolText)
                                }
                                mTableCell(align = MTableCellAlign.left) {
                                    css { padding(0.spacingUnits, 2.spacingUnits) }
                                    mIconButtonNoTranslate(
                                        iconName = "delete",
                                        size = MButtonSize.small,
                                        color = MColor.inherit,
                                        onClick = { _ ->
                                            setState({
                                                assign(it) {
                                                    newReplacement = null
                                                    replacements = replacements.filter { r ->
                                                        r.symbolId != replacement.symbolId ||
                                                        r.symbolName != replacement.symbolName ||
                                                        r.symbolText != replacement.symbolText
                                                    }
                                                }
                                            }) {
                                                saveReplacement()
                                            }
                                        }
                                    ) {
                                        css {
                                            backgroundColor = Color.red; color = Color.white
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

    private fun RBuilder.createReplacement() =
        mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
            styledDiv {
                css { padding(2.spacingUnits, 2.spacingUnits, 0.px, 2.spacingUnits) }
                css {
                    padding(0.spacingUnits)
                    justifyItems = JustifyItems.left
                }
                styledDiv {
                    mTypography(
                        text = "Neues Symbol:",
                        variant = MTypographyVariant.h6
                    ) {
                        css {
                            minWidth = 200.px
                            display = Display.block
                            marginTop = 2.spacingUnits
                            paddingRight = 1.spacingUnits
                        }
                    }
                }
                mTextField(
                    margin = MFormControlMargin.dense,
                    variant = MFormControlVariant.outlined,
                    label = "Symbol Name",
                    value = state.newReplacement?.symbolName ?: "",
                    onChange = {
                        val text = it.targetInputValue
                        setState {
                            if (state.newReplacement == null) {
                                newReplacement = TtsSymbolReplacementDTO(0, "", text, "")
                            } else {
                                newReplacement = newReplacement?.copy(symbolName = text)
                            }
                        }
                    }
                ) {
                    attrs.fullWidth = true
                }
                mTextField(
                    margin = MFormControlMargin.dense,
                    variant = MFormControlVariant.outlined,
                    label = "Symbol ID",
                    value = state.newReplacement?.symbolId ?: "",
                    onChange = {
                        val text = it.targetInputValue
                        setState {
                            if (state.newReplacement == null) {
                                newReplacement = TtsSymbolReplacementDTO(0, text, "", "")
                            } else {
                                newReplacement = newReplacement?.copy(symbolId = text)
                            }
                        }
                    }
                ) {
                    attrs.fullWidth = true
                }
                mTextField(
                    margin = MFormControlMargin.dense,
                    variant = MFormControlVariant.outlined,
                    label = "Symbol Korrektur",
                    value = state.newReplacement?.symbolText ?: "",
                    onChange = {
                        val text = it.targetInputValue
                        setState {
                            if (state.newReplacement == null) {
                                newReplacement = TtsSymbolReplacementDTO(0, "", "", text)
                            } else {
                                newReplacement = newReplacement?.copy(symbolText = text)
                            }
                        }
                    }
                ) {
                    attrs.fullWidth = true
                }
                audioPlayer(state.company ?: "", state.newReplacement?.symbolText ?: "")
                mDivider()
                mButton(
                    caption = "Hinzufügen",
                    variant = MButtonVariant.contained,
                    color = MColor.secondary,
                    disabled = state.newReplacement == null || state.newReplacement?.symbolId.isNullOrBlank() || state.newReplacement?.symbolText.isNullOrBlank(),
                    onClick = {
                        saveReplacement()
                        setState {
                            newReplacement = null
                        }
                    }
                ) {
                    css {
                        marginTop = 2.spacingUnits
                    }
                }
//                    mTypography(
//                        text = "Die CSV-Datei sollte folgende Daten enthalten: Haltestelle (Normal), Haltestelle (IBIS), Latitude und Longitude. Die Dezimaltrennung der Koordinaten muss mit einem Punkt erfolgen. Die CSV-Datei muss das UTF-8 Format haben und Komma separiert sein.",
//                        variant = MTypographyVariant.caption,
//                        color = MTypographyColor.textSecondary
//                    ) { css { display = Display.block; marginTop = 2.spacingUnits } }
            }
        }


}
