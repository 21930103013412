package de.geomobile.frontend.features.companyProfile

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardActions
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.dialog.mDialog
import com.ccfraser.muirwik.components.dialog.mDialogActions
import com.ccfraser.muirwik.components.dialog.mDialogContent
import com.ccfraser.muirwik.components.form.MFormControlVariant
import com.ccfraser.muirwik.components.list.mListSubheader
import de.geomobile.common.permission.Permissions
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.spacer
import de.geomobile.frontend.utils.*
import kotlinx.browser.localStorage
import kotlinx.coroutines.*
import kotlinx.css.*
import kotlinx.serialization.json.Json
import org.w3c.dom.get
import portalmodels.NetworkSettingsDTO
import portalmodels.ConnectionType
import react.*
import react.router.dom.route
import styled.css
import styled.styledDiv

fun RBuilder.companyProfileInteractNetwork() = child(CompanyProfileInteractNetwork::class) {}

class CompanyProfileInteractNetwork : CComponent<CompanyProfileInteractNetwork.Props, CompanyProfileInteractNetwork.State>() {

    private var loadExportTokenJob: Job = Job()
    val ipRegex = Regex("^(?:[0-9]{1,3}\\.){3}[0-9]{1,3}\$")

    interface Props : RProps

    class State(
//        var currentProfile: CompanyProfileInteractNetworkDTO?,
        var editedProfile: NetworkSettingsDTO = NetworkSettingsDTO(),
        var warnDialog: Boolean = false
    ) : RState

    init {
        state = State()
    }

    fun saveProfile() {
        launch {
            val companyId = localStorage["CompanyProfile"]
            if(companyId == state.editedProfile.companyId) {
                val body = Json.encodeToString(NetworkSettingsDTO.serializer(), state.editedProfile)
                portalRestApi.put("/companyprofile/$companyId/interact/settings", body)
            }
        }.invokeOnCompletion {
            getProfile()
        }
    }

    fun getProfile() {
        launch {
            val companyId = localStorage["CompanyProfile"]
            val response = portalRestApi.get("/companyprofile/$companyId/interact/settings", NetworkSettingsDTO.serializer())
            setState {
                editedProfile = response.copy(companyId = response.companyId ?: companyId)
            }
        }
    }

    override fun componentDidMount() {
        getProfile()
    }

    override fun RBuilder.render() {
        authorize(Permissions.CompanyProfileManagement.profileView) {
            route<RProps>("/companyprofile/network/interact") { _ ->
                spacer()
                mGridContainer2(direction = MGridDirection.column) {
                    mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                        if (isAuthorized(Permissions.CompanyProfileManagement.profileEdit))
                            interactSettings()
                    }
                }
            }
        }
        mDialog(
            open = state.warnDialog,
            onClose = { _, _ -> setState { warnDialog = false } }
        ) {
            val malformed =
                !state.editedProfile.lanSettings.useDHCP && !listOf(
                state.editedProfile.lanSettings.ipAddress,
                state.editedProfile.lanSettings.netmask,
                state.editedProfile.lanSettings.gateway)
                .all { ipRegex.matches(it) }

            mDialogContent {
                if (malformed) {
                    mTypography("Achtung, IP-Adressen haben das falsche Format! Speichern abgebrochen!")
                } else {
                    mTypography("Achtung, eine falsche Konfiguration kann dazu führen dass die Geräte nicht mehr Online kommen.")
                }
            }
            mDialogActions {
                if(malformed) {
                    mButton(
                        caption = "Abbrechen",
                        onClick = { setState { warnDialog = false } }
                    )
                } else {
                    mButton(
                        caption = "Speichern",
                        variant = MButtonVariant.contained,
                        color = MColor.secondary,
                        onClick = {
                            saveProfile()
                            setState { warnDialog = false }
                        }
                    )
                    mButton(
                        caption = "Abbrechen",
                        onClick = { setState { warnDialog = false } }
                    )
                }

            }
        }
    }

    private fun RBuilder.interactSettings() =
        mCard {
            css(GlobalStyles.card)
            mCardContent {
                css(GlobalStyles.cardContent)
                mListSubheader(heading = "Interact Netzwerk Einstellungen")
                mDivider { }
                styledDiv {
                    css { padding(1.spacingUnits, 2.spacingUnits) }
                    mTypography(
                        text = "Internetverbindung",
                        color = MTypographyColor.secondary,
                        variant = MTypographyVariant.h6,
                    )
                    mRadioGroup {
                        mRadioWithLabel(
                            label = "LAN",
                            checked = state.editedProfile.connectionType == ConnectionType.LAN,
                            onChange = { _, checked ->
                                if (checked)
                                    setState {
                                        editedProfile.connectionType = ConnectionType.LAN
                                    }
                            }
                        )
                        mRadioWithLabel(
                            label = "LTE",
                            checked = state.editedProfile.connectionType == ConnectionType.LTE,
                            onChange = { _, checked ->
                                if (checked)
                                    setState {
                                        editedProfile.connectionType = ConnectionType.LTE
                                    }
                            }
                        )
                        mRadioWithLabel(
                            label = "OHNE VERBINDUNG",
                            checked = state.editedProfile.connectionType == ConnectionType.NONE,
                            onChange = { _, checked ->
                                if (checked)
                                    setState {
                                        editedProfile.connectionType = ConnectionType.NONE
                                    }
                            }
                        )
                    }
                }
                mDivider { }
                styledDiv {
                    css { padding(1.spacingUnits, 2.spacingUnits) }
                    mTypography(
                        text = "LAN-Einstellungen",
                        color = MTypographyColor.secondary,
                        variant = MTypographyVariant.h6,
                    )
                    mCheckboxWithLabel(
                        label = "IP-Adresse ermitteln",
                        checked = state.editedProfile.lanSettings.useDHCP,
                        onChange = { _, checked ->
                            setState {
                                editedProfile.lanSettings.useDHCP = checked
                            }
                        }
                    )
                    mTypography(
                        text = "Die IP-Adresse soll automatisch per DHCP ermittelt werden.",
                        color = MTypographyColor.primary,
                        variant = MTypographyVariant.subtitle1
                    ) {
                        css {
                            marginTop = -2.spacingUnits
                            paddingLeft = 30.px
                        }
                    }
                    mDivider { css { height = 0.px } }
                    mTextField(
                        label = "IP-Adresse",
                        value = state.editedProfile.lanSettings.ipAddress,
                        disabled = state.editedProfile.lanSettings.useDHCP,
                        variant = MFormControlVariant.outlined,
                        error = state.editedProfile.lanSettings.ipAddress.isNotBlank() && !ipRegex.matches(state.editedProfile.lanSettings.ipAddress),
                        onChange = { event ->
                            val text = event.targetInputValue
                            setState {
                                state.editedProfile.lanSettings.ipAddress = text
                            }
                        }
                    ) { css { paddingRight = 2.spacingUnits } }
                    mTextField(
                        label = "Netmask",
                        value = state.editedProfile.lanSettings.netmask,
                        disabled = state.editedProfile.lanSettings.useDHCP,
                        variant = MFormControlVariant.outlined,
                        error = state.editedProfile.lanSettings.netmask.isNotBlank() && !ipRegex.matches(state.editedProfile.lanSettings.netmask),
                        onChange = { event ->
                            val text = event.targetInputValue
                            setState {
                                state.editedProfile.lanSettings.netmask = text
                            }
                        }
                    ) { css { paddingRight = 2.spacingUnits } }
                    mTextField(
                        label = "Gateway",
                        value = state.editedProfile.lanSettings.gateway,
                        disabled = state.editedProfile.lanSettings.useDHCP,
                        variant = MFormControlVariant.outlined,
                        error = state.editedProfile.lanSettings.gateway.isNotBlank() && !ipRegex.matches(state.editedProfile.lanSettings.gateway),
                        onChange = { event ->
                            val text = event.targetInputValue
                            setState {
                                state.editedProfile.lanSettings.gateway = text
                            }
                        }
                    ) { css { paddingRight = 2.spacingUnits } }
                }
                mDivider { }
                styledDiv {
                    css { padding(1.spacingUnits, 2.spacingUnits) }
                    mTypography(
                        text = "SIM-Einstellungen",
                        color = MTypographyColor.secondary,
                        variant = MTypographyVariant.h6,
                    )
                    mTextField(
                        label = "PIN",
                        value = state.editedProfile.simSettings.PIN,
                        variant = MFormControlVariant.outlined,
                        onChange = { event ->
                            val text = event.targetInputValue
                            setState {
                                state.editedProfile.simSettings.PIN = text
                            }
                        }
                    ) { css { paddingRight = 2.spacingUnits } }
                    mTextField(
                        label = "APN",
                        value = state.editedProfile.simSettings.APN,
                        variant = MFormControlVariant.outlined,
                        onChange = { event ->
                            val text = event.targetInputValue
                            setState {
                                state.editedProfile.simSettings.APN = text
                            }
                        }
                    ) { css { paddingRight = 2.spacingUnits } }
                }
                mDivider { }
                mCardActions {
                    css {
                        paddingLeft = 2.spacingUnits
                        paddingTop = 2.spacingUnits
                        paddingBottom = 0.spacingUnits
                    }
                    mButton(
                        caption = "Speichern",
                        variant = MButtonVariant.contained,
                        color = MColor.secondary,
                        onClick = {
                            setState {
                                warnDialog = true
                            }
                        }
                    )
                }
            }
        }
}


