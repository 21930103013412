package de.geomobile.frontend.features.device.detail

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardActionArea
import com.ccfraser.muirwik.components.card.mCardActions
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.dialog.DialogScroll
import com.ccfraser.muirwik.components.dialog.mDialog
import com.ccfraser.muirwik.components.dialog.mDialogActions
import com.ccfraser.muirwik.components.dialog.mDialogContent
import com.ccfraser.muirwik.components.form.MFormControlMargin
import com.ccfraser.muirwik.components.form.MFormControlVariant
import com.ccfraser.muirwik.components.menu.mMenuItem
import com.ccfraser.muirwik.components.styles.Breakpoint
import com.ccfraser.muirwik.components.table.mTable
import com.ccfraser.muirwik.components.table.mTableBody
import com.ccfraser.muirwik.components.table.mTableContainer
import com.ccfraser.muirwik.components.transitions.mCollapse
import de.geomobile.common.permission.Permissions
import de.geomobile.common.portalmodels.CompanySmall
import de.geomobile.common.portalmodels.DeviceAudioDTO
import de.geomobile.common.portalmodels.TimestampStatus
import de.geomobile.common.portalmodels.changeValue
import de.geomobile.common.time.LocalDateTime
import de.geomobile.frontend.api.TopicSession
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.utils.*
import kotlinx.browser.document
import kotlinx.browser.localStorage
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.css.*
import kotlinx.html.InputType
import kotlinx.serialization.json.Json
import org.w3c.dom.get
import portalmodels.*
import react.RBuilder
import react.RProps
import react.RState
import react.setState
import styled.css
import styled.styledDiv
import kotlin.math.max
import kotlin.math.min

fun RBuilder.elaAudioPanel(
    elaAudioUpdateExpanded: Boolean = false,
    onElaAudioUpdateExpanded: () -> Unit,
    elaAudioExpanded: Boolean = false,
    onElaAudioExpanded: () -> Unit,
    label: String,
    allowEdit: Boolean,
    value: DeviceAudioDTO,
    device: Int,
    applyPath: String,
    offsetPath: String,
    testPath: String? = null,
) = child(ChooseElaAudioComponent::class) {
    attrs.elaAudioUpdateExpanded = elaAudioUpdateExpanded
    attrs.onElaAudioUpdateExpanded = onElaAudioUpdateExpanded
    attrs.elaAudioExpanded = elaAudioExpanded
    attrs.onElaAudioExpanded = onElaAudioExpanded
    attrs.label = label
    attrs.elaAudio = value
    attrs.device = device
    attrs.allowEdit = allowEdit
    attrs.applyPath = applyPath
    attrs.offsetPath = offsetPath
    attrs.testPath = testPath
}

class ChooseElaAudioComponent : CComponent<ChooseElaAudioComponent.Props, ChooseElaAudioComponent.State>() {

    private var editValue: Job = Job()
    private var loadElaStatusJob: Job = Job()
    private var overwriteElaUpdateJob: Job = Job()
    private var session: TopicSession? = null

    interface Props : RProps {
        var elaAudioUpdateExpanded: Boolean
        var onElaAudioUpdateExpanded: () -> Unit
        var elaAudioExpanded: Boolean
        var onElaAudioExpanded: () -> Unit
        var label: String
        var allowEdit: Boolean
        var elaAudio: DeviceAudioDTO //MutableMap<String, Int?>
        var device: Int
        var applyPath: String
        var offsetPath: String
        var applyBody: String
        var testPath: String?
    }

    class State(
        var editState: EditValueState = EditValueState.Idle,
        var elaUpdateId: Int = 0,
        var elaUpdates: List<ElaUpdateHistoryDTO> = listOf(),
        var elaDebugUpdate: ElaUpdateHistoryDTO? = null,
        var company: String = "",
        var elaDebugResult: String = "",
        var elaDebugApplying: Boolean = false,
    ) : RState

    sealed class EditValueState {
        object Idle : EditValueState()
        data class Editing(val newValue: DeviceAudioDTO, val applying: Boolean = false) : EditValueState()
    }

    init {
        state = State()
    }

    suspend fun loadCompany() {
        //var companyIdDevice = props.device
        var companyIdLocal = localStorage["CompanyProfile"] ?: null
        var companyIdCurrent = portalRestApi.get("/device/${props.device}/company", CompanySmall.serializer()).id
        val companyId = if (!companyIdCurrent.isNullOrBlank()) companyIdCurrent else companyIdLocal
        companyId?.let {
            setState {
                company = it
            }
        }
    }

    // TODO: Clean up
//    fun loadElaStatusWS() {
//        loadElaStatusJob.cancel()
//        loadElaStatusJob = launch {
//            loadCompany()
//            session?.close()
//            session = portalWebSocketApi.subscribe("/companyprofile/device/ela", mapOf("id" to props.device.toString()))
//            session?.connect {
//                onmessage = {
//                    var lst = mutableListOf<ElaUpdateHistoryDTO>()
//                    val status = Json.parse(ElaUpdateDTO.serializer(), it)
//                    status?.let {
//                        if (it.deployedUpdates.isNotEmpty())
//                            lst.addAll(it.deployedUpdates)
//                        if (it.stashedUpdates.isNotEmpty())
//                            lst.addAll(it.stashedUpdates)
//                        setState {
//                            elaUpdates = lst.toList()
//                            elaDebugUpdate = it.debugUpdate
//                        }
//                    }
//                }
//            }
//        }
//    }

    fun loadElaStatus() {
        loadElaStatusJob.cancel()
        loadElaStatusJob = launch {
            loadCompany()
            var lst = mutableListOf<ElaUpdateHistoryDTO>()
            val deployed =
                portalRestApi.get("/companyprofile/${state.company}/ela/deployed", ElaDeployedDTO.serializer())
            val stashed = portalRestApi.get("/companyprofile/${state.company}/ela/stashed", ElaStashedDTO.serializer())
            val debug = portalRestApi.get(
                "/companyprofile/${state.company}/ela/${props.device}/debug",
                ElaUpdateHistoryDTO.serializer()
            )
            deployed?.let {
                if (it.deployedUpdates.isNotEmpty())
                    lst.addAll(it.deployedUpdates)
            }
            stashed?.let {
                if (it.stashedUpdates.isNotEmpty())
                    lst.addAll(it.stashedUpdates)

            }
            setState {
                elaUpdates = lst.toList()
                debug.filename?.let {
                    elaDebugUpdate = debug
                }
            }
        }
    }

    private fun volumeWithOffset(dto: DeviceAudioDTO): Int {
        val value = dto.volume ?: 0
        val offset: Int = (dto.deviceElaStatus["volume_offset"] ?: "0").toInt()
        return value + offset
    }

    private fun trebleWithOffset(dto: DeviceAudioDTO): Int {
        val value = dto.treble ?: 0
        val offset: Int = (dto.deviceElaStatus["treble_offset"] ?: "0").toInt()
        return value + offset
    }

    private fun bassWithOffset(dto: DeviceAudioDTO): Int {
        val value = dto.bass ?: 0
        val offset: Int = (dto.deviceElaStatus["bass_offset"] ?: "0").toInt()
        return value + offset
    }

    fun constructSelectLabel(update: ElaUpdateHistoryDTO): String {
        val label = "${update.status?.readableName ?: "-"}, " +
                "ID: ${update.updateId}, " +
                "Upload: ${update.timestamp?.toText(from = TimeUnit.DAY) ?: "-"}, " +
                "Typ: ${update.updateType}, " +
                "SW: ${update.software}, " +
                "INI: ${update.ini}, " +
                "MP3: ${update.mp3}"
        return label
    }

    fun overwriteElaUpdate() {
        setState {
            elaDebugApplying = true
            elaDebugResult = ""
        }
        overwriteElaUpdateJob.cancel()
        overwriteElaUpdateJob = launch {
            loadCompany()
            val status = portalRestApi.get(
                "/companyprofile/${state.company}/ela/${props.device}/${state.elaUpdateId}/debug",
                GenericResponseDTO.serializer()
            )
            status?.let {
                setState {
                    elaDebugApplying = false
                    elaDebugResult = status.message
                }
            }
        }
    }

    override fun componentDidMount() {
        loadElaStatus()
    }

    override fun RBuilder.render() {
        styledDiv {
            mCard {
                mCardActionArea(
                    onClick = { props.onElaAudioUpdateExpanded() },
                ) {
                    attrs.component = "div"
                    mCardHeaderExtended(
                        title = "ELA Update",
                        action = mIconButtonNoTranslate(
                            if (props.elaAudioUpdateExpanded) "expand_less" else "expand_more",
                            onClick = { event ->
                                run {
                                    props.onElaAudioUpdateExpanded()
                                    event.stopPropagation()
                                }
                            },
                            addAsChild = false
                        ) {
                            attrs.component = "div"
                        }
                    )
                }
                mCollapse(props.elaAudioUpdateExpanded) {
                    mDivider {}
                    mCardContent {

                        if (props.elaAudio != null) {
                            val ldt = props.elaAudio!!.deviceElaStatus["timestamp"]?.let {
                                LocalDateTime.parse(it)
                            }
                            val tss = TimestampStatus(ldt, intervalSec = 60)

                            props.elaAudio!!.deviceElaStatus["online"]?.let {
                                if (it.lowercase() == "true")
                                    styledDiv {
                                        css.paddingLeft = 2.spacingUnits
                                        timestampStatus(tss)
                                    }
                            }
                        }

                        styledDiv {
                            css { padding(2.spacingUnits) }
                            authorize(Permissions.DeviceManagement.ela_config) {
                                mSelect(
                                    value = state.elaUpdateId.toString(),
                                    variant = MFormControlVariant.outlined,
                                    disabled = state.elaUpdates.isNullOrEmpty(),
                                    onChange = { event, _ ->
                                        val newValue = event.targetValue as String
                                        setState {
                                            elaUpdateId = newValue.toInt()
                                        }
                                    }
                                ) {
                                    attrs.margin = MFormControlMargin.dense.toString()
                                    attrs.fullWidth = true

                                    //mMenuItem(primaryText = if(state.r2pCount == "-") "Bitte auswählen" else "R2P", value = "Bitte auswählen")
                                    mMenuItem(primaryText = "Nicht Überschreiben", value = "0")
                                    for (update in state.elaUpdates) {
                                        mMenuItem(
                                            primaryText = constructSelectLabel(update),
                                            value = update.updateId.toString()
                                        )
                                    }
                                }
                            }
                        }
                    }
                    mDivider {}
                    mCardActions {
                        css { padding(2.spacingUnits) }
                        mButton(
                            variant = MButtonVariant.contained,
                            caption = if (state.elaDebugApplying) "Laden..." else "Übernehmen",
                            color = if (state.elaDebugApplying) MColor.default else MColor.secondary,
                            disabled = state.elaDebugApplying || state.elaUpdates.isNullOrEmpty(),
                            onClick = { if (!state.elaDebugApplying) overwriteElaUpdate() }
                        ) {
                            attrs.disableElevation = true
                        }
                        if (state.elaDebugResult == "OK") {
                            mIconNoTranslate(
                                iconName = "check_circle",
                            ) {
                                css { color = Color.green }
                            }
                        } else if (state.elaDebugResult.isNotBlank()) {
                            mTooltip(state.elaDebugResult) {
                                mTypography(
                                    text = "Ergebnis",
                                    color = MTypographyColor.error
                                ) {
                                    css {
                                        marginRight = LinearDimension.auto
                                    }
                                }
                            }
                        }
                    }
                }
            }

            mCard {
                css { marginTop = 2.spacingUnits }
                mCardActionArea(
                    onClick = { props.onElaAudioExpanded() },
                ) {
                    attrs.component = "div"
                    mCardHeaderExtended(
                        title = "ELA Audio",
                        action = mIconButtonNoTranslate(
                            if (props.elaAudioExpanded) "expand_less" else "expand_more",
                            onClick = { event ->
                                run {
                                    props.onElaAudioExpanded()
                                    event.stopPropagation()
                                }
                            },
                            addAsChild = false
                        ) {
                            attrs.component = "div"
                        }
                    )
                }
                mCollapse(props.elaAudioExpanded) {
                    mDivider {}
                    mCardContent {
                        styledDiv {
                            css { padding(2.spacingUnits) }
                            mTableContainer {
                                mTable {
                                    mTableBody {
                                        tableRow(label = "", wrapHandler = false) {
                                            mNoBorderCell {
                                                mTypography(
                                                    text = "Fahrzeugprofil",
                                                    variant = MTypographyVariant.subtitle2
                                                )
                                            }
                                            mNoBorderCell {
                                                mTypography(text = "Lokal", variant = MTypographyVariant.subtitle2)
                                            }
                                        }
                                        tableRow(label = "Lautstärke", wrapHandler = false) {
                                            mNoBorderCell {
                                                mTypography(
                                                    text = (props.elaAudio.volume ?: 0).toString(),
                                                    variant = MTypographyVariant.body2
                                                )
                                            }
                                            mNoBorderCell {
                                                mTypography(
                                                    text = props.elaAudio.deviceElaStatus["volume_offset"] ?: "-",
                                                    variant = MTypographyVariant.body2
                                                )
                                            }
                                        }
                                        tableRow(label = "Treble", wrapHandler = false) {
                                            mNoBorderCell {
                                                mTypography(
                                                    text = (props.elaAudio.treble ?: 0).toString(),
                                                    variant = MTypographyVariant.body2
                                                )
                                            }
                                            mNoBorderCell {
                                                mTypography(
                                                    text = props.elaAudio.deviceElaStatus["treble_offset"] ?: "-",
                                                    variant = MTypographyVariant.body2
                                                )
                                            }
                                        }
                                        tableRow(label = "Bass", wrapHandler = false) {
                                            mNoBorderCell {
                                                mTypography(
                                                    text = (props.elaAudio.bass ?: 0).toString(),
                                                    variant = MTypographyVariant.body2
                                                )
                                            }
                                            mNoBorderCell {
                                                mTypography(
                                                    text = props.elaAudio.deviceElaStatus["bass_offset"] ?: "-",
                                                    variant = MTypographyVariant.body2
                                                )
                                            }
                                        }
                                    }
                                }
                            }
                            mDivider { css { margin(3.spacingUnits, 0.spacingUnits) } }
                            mTableContainer {
                                mTable {
                                    mTableBody {
                                        tableRow("ELA Kassette zur Zeit", wrapHandler = false) {
                                            mNoBorderCell {
                                                var isOnline =
                                                    props.elaAudio.deviceElaStatus["online"]?.lowercase() == "true"
                                                if (isOnline) {
                                                    val tss = props.elaAudio.deviceElaStatus["timestamp"]?.let {
                                                        LocalDateTime.parse(it)
                                                    }
                                                    isOnline =
                                                        tss?.let { tss > LocalDateTime.now().minusMinutes(15) }
                                                            ?: isOnline
                                                }
                                                mTypography(
                                                    text = if (isOnline) "Online" else "Offline",
                                                    variant = MTypographyVariant.body2,
                                                    color = if (isOnline) MTypographyColor.secondary else MTypographyColor.error
                                                )
                                            }
                                            if (state.elaUpdates.filter { it.status == ElaUpdateStatus.LIVE }
                                                    .isNotEmpty()) {
                                                mNoBorderCell {
                                                    mTypography(
                                                        text = "Soll Zustand",
                                                        variant = MTypographyVariant.body2
                                                    )
                                                }
                                            }
                                            state.elaDebugUpdate?.let {
                                                mNoBorderCell {
                                                    mTypography(
                                                        text = "Debug override",
                                                        variant = MTypographyVariant.body2
                                                    )
                                                }
                                            }
                                        }
                                        tableRow("Version Software", wrapHandler = false) {
                                            mNoBorderCell {
                                                mTypography(
                                                    text = props.elaAudio.deviceElaStatus["software"] ?: "-",
                                                    variant = MTypographyVariant.body2
                                                )
                                            }
                                            if (state.elaUpdates.filter { it.status == ElaUpdateStatus.LIVE }
                                                    .isNotEmpty()) {
                                                mNoBorderCell {
                                                    mTypography(
                                                        text = (state.elaUpdates.last { it.status == ElaUpdateStatus.LIVE }.software
                                                            ?: "").replace(Regex("\\b0\\b"), ""),
                                                        variant = MTypographyVariant.body2
                                                    )
                                                }
                                            }
                                            state.elaDebugUpdate?.let {
                                                mNoBorderCell {
                                                    mTypography(
                                                        text = it.software,
                                                        variant = MTypographyVariant.body2
                                                    )
                                                }
                                            }
                                        }
                                        tableRow("Version INI", wrapHandler = false) {
                                            mNoBorderCell {
                                                mTypography(
                                                    text = props.elaAudio.deviceElaStatus["ini"] ?: "-",
                                                    variant = MTypographyVariant.body2
                                                )
                                            }
                                            if (state.elaUpdates.filter { it.status == ElaUpdateStatus.LIVE }
                                                    .isNotEmpty()) {
                                                mNoBorderCell {
                                                    mTypography(
                                                        text = (state.elaUpdates.last { it.status == ElaUpdateStatus.LIVE }.ini
                                                            ?: "").replace(Regex("\\b0\\b"), ""),
                                                        variant = MTypographyVariant.body2
                                                    )
                                                }
                                            }
                                            state.elaDebugUpdate?.let {
                                                mNoBorderCell {
                                                    mTypography(
                                                        text = it.ini,
                                                        variant = MTypographyVariant.body2
                                                    )
                                                }
                                            }
                                        }
                                        tableRow("Version MP3", wrapHandler = false) {
                                            mNoBorderCell {
                                                mTypography(
                                                    text = props.elaAudio.deviceElaStatus["mp3"] ?: "-",
                                                    variant = MTypographyVariant.body2
                                                )
                                            }
                                            if (state.elaUpdates.filter { it.status == ElaUpdateStatus.LIVE }
                                                    .isNotEmpty()) {
                                                mNoBorderCell {
                                                    mTypography(
                                                        text = (state.elaUpdates.last { it.status == ElaUpdateStatus.LIVE }.mp3
                                                            ?: "").replace(Regex("\\b0\\b"), "-"),
                                                        variant = MTypographyVariant.body2
                                                    )
                                                }
                                            }
                                            state.elaDebugUpdate?.let {
                                                mNoBorderCell {
                                                    mTypography(
                                                        text = it.mp3,
                                                        variant = MTypographyVariant.body2
                                                    )
                                                }
                                            }
                                        }
                                        tableRow("ELA Seriennummer", wrapHandler = false) {
                                            mNoBorderCell {
                                                mTypography(
                                                    text = props.elaAudio.deviceElaStatus["serialNumber"] ?: "-",
                                                    variant = MTypographyVariant.body2
                                                )
                                            }
                                        }
                                    }
                                }
                            }

                        }
                    }
                    if (props.allowEdit) {
                        mDivider { }
                        mCardActions {
                            css { padding(2.spacingUnits) }
                            mButton(
                                variant = MButtonVariant.contained,
                                caption = "Bearbeiten",
                                color = MColor.secondary,
                                onClick = { show() },
                                addAsChild = true
                            ) {
                                attrs.disableElevation = true
                            }
                        }
                    }
                }
            }
        }

        val open = state.editState is EditValueState.Editing
        //val currentState = state.editState
        mDialog(
            open,
            scroll = DialogScroll.paper,
            maxWidth = Breakpoint.md,
            onClose = { _, _ -> hide() }
        ) {
            attrs.disableEscapeKeyDown = true

            //mDialogTitle(props.label)
            mDialogContent {
                css {

                    overflow = Overflow.hidden
                    display = Display.flex
                    flexWrap = FlexWrap.wrap
                    alignItems = Align.baseline
                }

                if (state.editState is EditValueState.Editing) {
                    if ((state.editState as EditValueState.Editing).applying) {
                        mTypography(text = "Wird abgespielt") {
                            css {
                                width = 100.pct
                                margin(
                                    horizontal = LinearDimension.auto
                                )
                            }
                        }
                        mCircularProgress {
                            css {
                                display = Display.block
                                margin(
                                    vertical = 2.spacingUnits, horizontal = LinearDimension.auto
                                )
                            }
                        }
                    } else {
                        mTypography(text = "Lautstärke") {
                            css { width = 15.pct }
                        }


                        mSlider(
                            value = (state.editState as EditValueState.Editing).newValue.volume
                                ?: props.elaAudio.deviceElaStatus["volume_offset"]?.toInt() ?: 0,
                            min = 0,
                            max = 100,
                            step = 1,
                            onChange = { value ->
                                changeValue(
                                    (state.editState as EditValueState.Editing).newValue.copy()
                                        .changeValue(volume = min(100, max(0, value)))
                                )
                            }
                        ) {
                            css {
                                paddingRight = 2.spacingUnits
                                width = 77.pct
                            }
                        }
                        mTextField(
                            id = "volume",
                            label = "",
                            type = InputType.number,
                            value = ((state.editState as EditValueState.Editing).newValue.volume
                                ?: props.elaAudio.deviceElaStatus["volume_offset"]).toString(),
                            onChange = {
                                changeValue(
                                    (state.editState as EditValueState.Editing).newValue.copy()
                                        .changeValue(volume = min(100, max(0, it.targetInputValue.toInt())))
                                )
                            },
                            autoFocus = true
                        ) {
                            css { width = 8.pct }
                        }
                        mTypography(text = "Treble") {
                            css { width = 15.pct }
                        }
                        mSlider(
                            value = (state.editState as EditValueState.Editing).newValue.treble
                                ?: props.elaAudio.deviceElaStatus["treble_offset"]?.toInt() ?: 0,
                            min = 0,
                            max = 100,
                            step = 1,
                            onChange = { value ->
                                changeValue(
                                    (state.editState as EditValueState.Editing).newValue.copy()
                                        .changeValue(treble = min(100, max(0, value)))
                                )
                            }
                        ) {
                            css {
                                paddingRight = 2.spacingUnits
                                width = 77.pct
                            }
                        }
                        mTextField(
                            id = "treble",
                            label = "",
                            type = InputType.number,
                            value = ((state.editState as EditValueState.Editing).newValue.treble
                                ?: props.elaAudio.deviceElaStatus["treble_offset"]).toString(),
                            onChange = {
                                changeValue(
                                    (state.editState as EditValueState.Editing).newValue.copy()
                                        .changeValue(treble = min(100, max(0, it.targetInputValue.toInt())))
                                )
                            },
                            autoFocus = true
                        ) {
                            css { width = 8.pct }
                        }
                        mTypography(text = "Bass") {
                            css { width = 15.pct }
                        }
                        mSlider(
                            value = (state.editState as EditValueState.Editing).newValue.bass
                                ?: props.elaAudio.deviceElaStatus["bass_offset"]?.toInt() ?: 0,
                            min = 0,
                            max = 100,
                            step = 1,
                            onChange = { value ->
                                changeValue(
                                    (state.editState as EditValueState.Editing).newValue.copy()
                                        .changeValue(bass = min(100, max(0, value)))
                                )
                            }
                        ) {
                            css {
                                paddingRight = 2.spacingUnits
                                width = 77.pct
                            }
                        }
                        mTextField(
                            id = "bass",
                            label = "",
                            type = InputType.number,
                            value = ((state.editState as EditValueState.Editing).newValue.bass
                                ?: props.elaAudio.deviceElaStatus["bass_offset"]).toString(),
                            onChange = {
                                changeValue(
                                    (state.editState as EditValueState.Editing).newValue.copy()
                                        .changeValue(bass = min(100, max(0, it.targetInputValue.toInt())))
                                )
                            },
                            autoFocus = true
                        ) {
                            css { width = 8.pct }
                        }
                    }
                }
            }
            mDialogActions {
                mButton(
                    "Abbrechen",
                    color = MColor.primary,
                    onClick = {
                        hide()
                    }
                )
                if (props.testPath != null) {
                    val st = props.elaAudio.deviceElaStatus["online"]?.let {
                        it.lowercase() != "true"
                    } ?: true

                    mTooltip(if (st) "Das Gerät ist offline :(" else "") {
                        mButton(
                            caption = "Test",
                            color = MColor.secondary,
                            disabled = st,
                            onClick = {
                                if (state.editState is EditValueState.Editing)
                                    test()
                            }
                        ) {
                            css { marginLeft = LinearDimension.auto }
                        }
                    }
                }

                mTooltip("Stellt die Lautstärke aus dem Fahrzeuzprofil, sofern vorhanden, wieder her") {
                    mButton(
                        "Offset entfernen",
                        disabled = false, //!props.value.hasProfile || state.editState !is EditValueState.Editing,
                        color = MColor.primary,
                        onClick = {
                            if (state.editState is EditValueState.Editing) {
                                removeOffset()
                                hide()
                            }

                        }
                    )
                }
                mTooltip("Speichert die Werte nur für dieses eine Gerät.") {
                    mButton(
                        "Als Offset speichern",
                        disabled = false, //!props.value.hasProfile || state.editState !is EditValueState.Editing,
                        color = MColor.primary,
                        onClick = {
                            if (state.editState is EditValueState.Editing) {
                                applyOffset()
                                hide()
                            }
                        }
                    )
                }
                val ttp = if (!props.elaAudio.hasProfile)
                    "Dieses Gerät ist nicht mit einem Fahrzeugprofil verknüpft!"
                else
                    "Speicher die Werte für alle Geräte im Fahrzeugprofil."
                mTooltip(ttp) {
                    mButton(
                        "Für Fahrzeugprofil speichern",
                        disabled = !props.elaAudio.hasProfile || state.editState !is EditValueState.Editing,
                        color = MColor.primary,
                        onClick = {
                            if (state.editState is EditValueState.Editing)
                                applyAll()
                        }
                    )
                }

            }
            document.onkeydown = {
                if (open && it.keyCode == 13) // User hits enter
                    applyAll()
            }
        }
    }

    private fun show() {
        setState {
            var value = if (props.elaAudio.deviceElaStatus["volume_offset"] != null &&
                props.elaAudio.deviceElaStatus["treble_offset"] != null &&
                props.elaAudio.deviceElaStatus["bass_offset"] != null
            )
                DeviceAudioDTO(
                    props.elaAudio.deviceElaStatus["volume_offset"]!!.toInt(),
                    props.elaAudio.deviceElaStatus["treble_offset"]!!.toInt(),
                    props.elaAudio.deviceElaStatus["bass_offset"]!!.toInt()
                )
            else
                props.elaAudio
            editState = EditValueState.Editing(value)
        }
    }

    private fun hide() {
        setState {
            editState = EditValueState.Idle
        }
    }

    private fun changeValue(value: DeviceAudioDTO) {
        (state.editState as? EditValueState.Editing)?.let {
            setState {
                editState = it.copy(newValue = value)
            }
        }
    }

    private fun applyAll() {
        (state.editState as? EditValueState.Editing)?.let {
            setState { editState = it.copy(applying = true) }
            editValue.cancel()
            editValue = launch {
                launch(Dispatchers.Default) {

                    val path = props.applyPath
                    val body = Json.encodeToString(DeviceAudioDTO.serializer(), it.newValue)
                    portalRestApi.put(path, body)

                }.join()

                setState {
//                    props.elaAudio.volume = it.newValue.volume
//                    props.elaAudio.treble = it.newValue.treble
//                    props.elaAudio.bass = it.newValue.bass
                    editState = EditValueState.Idle
                }
            }
        }
    }

    private fun removeOffset() {
        (state.editState as? EditValueState.Editing)?.let {
            changeValue(
                it.newValue.copy().changeValue(
                    volume = min(100, max(0, 0)),
                    treble = min(100, max(0, 0)),
                    bass = min(100, max(0, 0))
                )
            )
        }
        launch {
            portalRestApi.get("/device/${props.device}/elaaudio/volume_offset/delete")
        }

    }

    private fun applyOffset() {
        (state.editState as? EditValueState.Editing)?.let {
            setState { editState = it.copy(applying = true) }
            editValue.cancel()
            editValue = launch {
                launch(Dispatchers.Default) {
                    val path = props.offsetPath
                    val body = Json.encodeToString(DeviceAudioDTO.serializer(), it.newValue)
                    portalRestApi.put(path, body)

                }.join()

                // TODO: Clean up
                setState {
//                    props.elaAudio.volume = it.newValue.volume
//                    props.elaAudio.treble = it.newValue.treble
//                    props.elaAudio.bass = it.newValue.bass
                    editState = EditValueState.Idle
                }
            }
        }
    }

    private fun test() {
        (state.editState as? EditValueState.Editing)?.let { edit ->
            props.testPath?.let { path ->
                launch(Dispatchers.Default) {
                    portalRestApi.put(path, Json.encodeToString(DeviceAudioDTO.serializer(), edit.newValue))
                }
            }
            launch {
                setState {
                    editState = edit.copy(applying = true)
                }
                delay(32000)
                setState {
                    editState = edit.copy(applying = false)
                }
            }
        }
    }
}