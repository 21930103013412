package portalmodels

import kotlinx.serialization.EncodeDefault
import kotlinx.serialization.Serializable

@Serializable
data class FmsProfileDTO(
        val id: Int? = null,
        var name: String
)

@Serializable
data class FmsEntriesDTO(
        val pgn: Int,
        val bmsInterval: Int,
        val portalInterval: Int,
        @EncodeDefault
        val calibration: String = "none"
)

@Serializable
data class FmsTelegramsDTO(
        val telegrams: Map<Int, Pair<String, String>>
)

object FmsTelegrams {
    private val myTelegrams = mapOf(
            65257 to Pair("FuelConsumption", "Kraftstoffverbrauch"),
            65276 to Pair("DashDisplay1", "Armaturenbrett 1"),
            61444 to Pair("ElectronicEngineController1", "Elektronische Motorsteuerung 1"),
            65253 to Pair("EngineHours", "Motorstunden"),
            65260 to Pair("VehicleIdentification", "Fahrzeugidentifikation"),
            64977 to Pair("FMSStandardInterfaceIdentity", "FMS Standard Schnitstellenidentifikation"),
            65217 to Pair("HighResolutionVehicleDistance", "Fahrzeugdistanz (Hochauflösend)"),
            65132 to Pair("Tachograph", "Tacho"),
            65262 to Pair("EngineTemperature1", "Motortemperatur 1"),
            65269 to Pair("AmbientConditions", "Umgebungsbedingungen"),
            65131 to Pair("DriversIdentification", "Treiberidentifikation"),
            65266 to Pair("FuelEconomy", "Kraftstoffverbrauch"),
            65198 to Pair("AirSupplyPressure", "Druck Luftvorrat"),
            64777 to Pair("HighResolutionFuelConsumption", "Kraftstoffverbrauch (Hochauflösend)"),
            64777 to Pair("Aftertreatment1DieselExhaustFluidTank1Information", "Dieselausflusstank 1 Informationen (Nach Behandlung)"),
            64893 to Pair("FMSTellTaleStatus", "FMS Kontrollstatus"),
            61441 to Pair("ElectronicBrakeController1", "Elektronische Bremssteuerung 1"),
            64962 to Pair("ElectronicEngineController14", "Elektronische Motorsteuerung 14"),
            65199 to Pair("FuelConsumptionGaseous", "Kraftstoffverbrauch Gas"),
            61440 to Pair("ElectronicRetarderController1", "Elektronisches Ströhmungsbremsensteuergerät"),
            65265 to Pair("CruiseControlVehicleSpeed1", "Tempomat Geschwindigkeitskontrolle 1"),
            61443 to Pair("ElectronicEngineController2", "Elektronische Motorsteuerung 2"),
            61443 to Pair("ServiceInformation", "Service Informationen"),
            64932 to Pair("PTODriveEngagement", "PTO Drive Engagement"),
            65136 to Pair("CombinationVehicleWeight", "Fahrzeuggewicht (kombiniert)"),
            65102 to Pair("DoorControl1", "Türsteuerung 1"),
            64933 to Pair("DoorControl2", "Türsteuerung 2"),
            65254 to Pair("TimeDate", "Zeit / Datum"),
            65237 to Pair("AlternatorSpeed", "Geschwindigkeit Lichtmaschine"),
            65112 to Pair("AirSuspensionControl4", "Steuerung Luftfederung"),
            61445 to Pair("ElectronicTransmissionController2", "Elektronische Getriebesteuerung"),
            64695 to Pair("HybridBatteryPackRemainingCharge", "Restaufladung Hybridbatterie"),
            61449 to Pair("VehicleDynamicStabilityControl2", "Dynamische Fahrzeugstabilisierungskontrolle 2")
    )

    val telegrams = FmsTelegramsDTO(myTelegrams.keys.sorted().associateWith { myTelegrams[it]!! })
}