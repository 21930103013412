package de.geomobile.frontend.api

import de.geomobile.common.errorhandling.ApiError
import de.geomobile.common.errorhandling.ApiHttpStatus
import de.geomobile.common.errorhandling.toApiHttpStatus
import kotlinx.coroutines.TimeoutCancellationException
import kotlinx.coroutines.await
import kotlinx.serialization.json.Json
import org.w3c.fetch.Response

object ApiErrorInterceptor : Interceptor {
    override suspend fun intercept(chain: Interceptor.Chain): Response {
        val response = try {
            chain.proceed(chain.request)
        } catch (e: TimeoutCancellationException) {
            console.error(e)
            throw ApiException.TimeoutError
        } catch (e: Throwable) {
            console.error(e)
            throw ApiException.NetworkError
        }

        // error
        if (!response.ok) {
            val code = response.status.toInt().takeIf { it != 0 }?.toApiHttpStatus()
            val responseBody = response.text().await().also { println("response: $it") }
            val apiError = try {
                Json.decodeFromString(ApiError.serializer(), responseBody)
            } catch (e: Exception) {
                console.error("Error body parsing: $e", e)
                throw ApiException.ServerError(null)
            }

            throw if (code == ApiHttpStatus.INTERNAL_SERVER_ERROR)
                ApiException.ServerError(apiError)
            else
                ApiException.ClientError(apiError)
        }

        return response
    }
}