package de.geomobile.frontend.features.admin.roles

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonSize
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.table.*
import de.geomobile.common.permission.Permissions
import de.geomobile.common.permission.Role
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.utils.*
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.Color
import kotlinx.css.background
import kotlinx.css.color
import kotlinx.css.margin
import kotlinx.serialization.builtins.ListSerializer
import react.RBuilder
import react.RProps
import react.RState
import react.setState
import styled.css

fun RBuilder.adminRoleList(
    roleSelected: (roleId: String) -> Unit,
    createRole: () -> Unit,
) = child(AdminRoleList::class) {
    attrs.roleSelected = roleSelected
    attrs.createRole = createRole
}

class AdminRoleList : CComponent<AdminRoleList.Props, AdminRoleList.State>() {

    interface Props : RProps {
        var roleSelected: (roleId: String) -> Unit
        var createRole: () -> Unit
    }

    class State(
        var roles: List<Role>? = null,
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        launch {
            val roles = withContext(Dispatchers.Default) {
                portalRestApi.get("/admin/roles", ListSerializer(Role.serializer()))
            }

            setState { this.roles = roles }
        }
    }

    override fun RBuilder.render() {
        val roles = state.roles

        mGridContainer2(direction = MGridDirection.row) {
            authorize(Permissions.RoleManagement.rolesEdit) {
                mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                    mButton(
                        caption = "Rolle erstellen",
                        variant = MButtonVariant.contained,
                        color = MColor.secondary,
                        onClick = { props.createRole() }
                    ) {
                        attrs.disableElevation = true
                    }
                }
                mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                    mCard {
                        mTableContainer {
                            mTable {
                                attrs.asDynamic().style = kotlinext.js.js { cursor = "pointer" }
                                mTableHead {
                                    mTableRowSlim {
                                        mTableCell(align = MTableCellAlign.left) { +"Name" }
                                        mTableCell(align = MTableCellAlign.left) { +"Unternehmen" }
                                        mTableCell(align = MTableCellAlign.right) { }
                                    }
                                }
                                mTableBody {
                                    if (roles.isNullOrEmpty()) {
                                        mTableRowSlim {
                                            mTableCell(
                                                colSpan = 3,
                                                align = MTableCellAlign.center
                                            ) {
                                                if (roles == null)
                                                    mCircularProgress { css { margin(1.spacingUnits) } }
                                                else
                                                    +"empty"
                                            }
                                        }
                                    } else {
                                        for (role in roles) {
                                            mTableRowSlim {
                                                attrs.key = role.id
                                                attrs.hover = true
                                                attrs.onClick = { props.roleSelected(role.id) }

                                                mTableCell(
                                                    align = MTableCellAlign.left,
                                                    size = MTableCellSize.small
                                                ) { +role.name }
                                                mTableCell(
                                                    align = MTableCellAlign.left,
                                                    size = MTableCellSize.small
                                                ) {
                                                    +(role.company?.name ?: "-")
                                                }
                                                mTableCell(
                                                    align = MTableCellAlign.right,
                                                    size = MTableCellSize.small
                                                ) {
                                                    authorize(Permissions.RoleManagement.rolesEdit) {
                                                        mIconButtonNoTranslate(
                                                            iconName = "edit",
                                                            size = MButtonSize.small,
                                                            color = MColor.inherit,
                                                            onClick = { props.roleSelected(role.id) }
                                                        ) {
                                                            css { background = "#2196f3"; color = Color.white; }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}