package de.geomobile.frontend.features.softwareManagement.assignment

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.form.MFormControlMargin
import com.ccfraser.muirwik.components.form.MFormControlVariant
import com.ccfraser.muirwik.components.form.mFormControl
import com.ccfraser.muirwik.components.menu.mMenuItem
import com.ccfraser.muirwik.components.styles.Breakpoint
import de.geomobile.common.portalmodels.Company
import de.geomobile.common.portalmodels.CompanySmall
import de.geomobile.common.portalmodels.Product
import de.geomobile.common.portalmodels.small
import de.geomobile.common.softwaremgmt.SoftwareBundle
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.features.softwareManagement.bundle.bundleSoftwareList
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.utils.*
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.*
import kotlinx.serialization.builtins.ListSerializer
import react.RBuilder
import react.RProps
import react.RState
import react.setState
import styled.css
import styled.styledDiv

fun RBuilder.bundleSelect(
    bundle: SoftwareBundle?,
    enabled: Boolean = true,
    product: Product,
    onBundleChanged: (bundle: SoftwareBundle?) -> Unit,
) = child(BundleSelect::class) {
    attrs.bundle = bundle
    attrs.enabled = enabled
    attrs.onBundleChanged = onBundleChanged
    attrs.product = product
}

class BundleSelect : CComponent<BundleSelect.Props, BundleSelect.State>() {

    private var loadCompaniesJob: Job = Job()
    private var loadBundlesJob: Job = Job()

    interface Props : RProps {
        var bundle: SoftwareBundle?
        var enabled: Boolean
        var onBundleChanged: (bundle: SoftwareBundle?) -> Unit
        var product: Product
    }

    class State(
        var companies: List<CompanySmall> = emptyList(),
        var bundles: List<SoftwareBundle> = emptyList(),
        var newCompany: CompanySmall? = null,
        var newBundle: SoftwareBundle? = null
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        loadCompanies()
        props.bundle?.let {
            loadBundles(it.company.id)
        }
    }

    override fun componentWillReceiveProps(nextProps: Props) {
        val company = state.newCompany ?: props.bundle?.company
        if (nextProps.bundle != null && nextProps.bundle!!.company != company) {
            loadBundles(nextProps.bundle!!.company.id)
        }
        if (nextProps.bundle != null && nextProps.bundle != props.bundle) setState {
            newBundle = null
            newCompany = null
        }
    }

    override fun RBuilder.render() {
        val originalCompany = props.bundle?.company
        val company = state.newCompany ?: originalCompany

        val originalBundle = props.bundle
        val bundle = (state.newBundle ?: originalBundle)?.takeIf { it.company == company }

        styledDiv {
            css { padding(2.spacingUnits, 1.spacingUnits) }
            mGridContainer2 {
                mGridItem2(
                    MGridBreakpoints2(MGridSize2.Cells6)
                        .down(Breakpoint.sm, MGridSize2.Cells12)
                ) {
                    styledDiv {
                        css(GlobalStyles.cardSelectListContent)
                        mTypography(
                            text = "Unternehmen",
                            variant = MTypographyVariant.caption
                        ) {
                            css { display = Display.block; marginBottom = 6.px }
                        }
                        mFormControl(
                            fullWidth = true,
                            disabled = !props.enabled
                        ) {
                            mSelect(
                                value = company?.id ?: "-",
                                name = "Unternehmen",
                                variant = MFormControlVariant.outlined,
                                id = "company",
                                onChange = { event, _ ->
                                    val id = event.targetValue as String
                                    val newCompany = state.companies.first { it.id == id }
                                    setState {
                                        this.newCompany = newCompany.takeIf { it != originalCompany }
                                        this.bundles = emptyList()
                                        this.newBundle = null
                                    }
                                    loadBundles(id)
                                    props.onBundleChanged(originalBundle?.takeIf { it.company == newCompany })
                                }
                            ) {
                                attrs.margin = MFormControlMargin.dense.toString()

                                for (company in state.companies) {
                                    mMenuItem(primaryText = company.name, value = company.id)
                                }
                            }
                        }
                    }
                }
                mGridItem2(
                    MGridBreakpoints2(MGridSize2.Cells6)
                        .down(Breakpoint.sm, MGridSize2.Cells12)
                ) {
                    styledDiv {
                        css(GlobalStyles.cardSelectListContent)
                        mTypography(
                            text = "Version",
                            variant = MTypographyVariant.caption
                        ) {
                            css { display = Display.block; marginBottom = 6.px }
                        }
                        mFormControl(
                            fullWidth = true,
                            disabled = !props.enabled || company == null
                        ) {
                            mSelect(
                                value = bundle?.id?.toString() ?: "-",
                                name = "Version",
                                variant = MFormControlVariant.outlined,
                                id = "version",
                                onChange = { event, _ ->
                                    val id = (event.targetValue as String).toIntOrNull()
                                    val newBundle = state.bundles.first { it.id == id }
                                    setState {
                                        this.newBundle = newBundle.takeIf { it != originalBundle }
                                    }
                                    props.onBundleChanged(newBundle)
                                }
                            ) {
                                attrs.margin = MFormControlMargin.dense.toString()

                                for (version in state.bundles.asReversed()) {
                                    mMenuItem(
                                        primaryText = version.internalVersion + " - Ext.: " + version.externalVersion,
                                        value = version.id.toString()
                                    )
                                }
                            }
                        }
                    }
                }
            }
        }

        if (bundle != null) {
            mDivider { }
            mGridContainer2 {
                mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                    bundleSoftwareList(bundle, props.product)
                }
            }
        }
    }

    private fun loadCompanies() {
        loadCompaniesJob.cancel()
        loadCompaniesJob = launch {
            val companies = withContext(Dispatchers.Default) {
                portalRestApi.get("/admin/companies", serializer = ListSerializer(Company.serializer()))
                    .map { it.small }
            }

            setState { this.companies = companies }
        }
    }

    private fun loadBundles(companyId: String) {
        loadBundlesJob.cancel()
        loadBundlesJob = launch {
            val bundles = withContext(Dispatchers.Default) {
                portalRestApi.get(
                    path = "/software/bundle/$companyId",
                    serializer = ListSerializer(SoftwareBundle.serializer()),
                    parameter = mapOf("product" to props.product.readableName)
                )
            }

            setState { this.bundles = bundles }
        }
    }
}