// Code generated by Wire protocol buffer compiler, do not edit.
// Source: de.geomobile.common.portalmodels.SocketMessage in portalmodels/socket_message.proto
package de.geomobile.common.portalmodels

import com.squareup.wire.FieldEncoding
import com.squareup.wire.Message
import com.squareup.wire.ProtoAdapter
import com.squareup.wire.ProtoReader
import com.squareup.wire.ProtoWriter
import com.squareup.wire.ReverseProtoWriter
import com.squareup.wire.Syntax.PROTO_2
import com.squareup.wire.WireField
import com.squareup.wire.`internal`.missingRequiredFields
import com.squareup.wire.`internal`.sanitize
import kotlin.Any
import kotlin.AssertionError
import kotlin.Boolean
import kotlin.Deprecated
import kotlin.DeprecationLevel
import kotlin.Int
import kotlin.Long
import kotlin.Nothing
import kotlin.String
import kotlin.Unit
import kotlin.jvm.JvmField
import okio.ByteString

public class SocketMessage(
  @field:WireField(
    tag = 1,
    adapter = "com.squareup.wire.ProtoAdapter#STRING",
    label = WireField.Label.REQUIRED
  )
  public val subscriptionPath: String,
  @field:WireField(
    tag = 2,
    adapter = "com.squareup.wire.ProtoAdapter#BYTES",
    label = WireField.Label.REQUIRED
  )
  public val message: ByteString,
  unknownFields: ByteString = ByteString.EMPTY
) : Message<SocketMessage, Nothing>(ADAPTER, unknownFields) {
  @Deprecated(
    message = "Shouldn't be used in Kotlin",
    level = DeprecationLevel.HIDDEN
  )
  public override fun newBuilder(): Nothing = throw
      AssertionError("Builders are deprecated and only available in a javaInterop build; see https://square.github.io/wire/wire_compiler/#kotlin")

  public override fun equals(other: Any?): Boolean {
    if (other === this) return true
    if (other !is SocketMessage) return false
    if (unknownFields != other.unknownFields) return false
    if (subscriptionPath != other.subscriptionPath) return false
    if (message != other.message) return false
    return true
  }

  public override fun hashCode(): Int {
    var result = super.hashCode
    if (result == 0) {
      result = unknownFields.hashCode()
      result = result * 37 + subscriptionPath.hashCode()
      result = result * 37 + message.hashCode()
      super.hashCode = result
    }
    return result
  }

  public override fun toString(): String {
    val result = mutableListOf<String>()
    result += """subscriptionPath=${sanitize(subscriptionPath)}"""
    result += """message=$message"""
    return result.joinToString(prefix = "SocketMessage{", separator = ", ", postfix = "}")
  }

  public fun copy(
    subscriptionPath: String = this.subscriptionPath,
    message: ByteString = this.message,
    unknownFields: ByteString = this.unknownFields
  ): SocketMessage = SocketMessage(subscriptionPath, message, unknownFields)

  public companion object {
    @JvmField
    public val ADAPTER: ProtoAdapter<SocketMessage> = object : ProtoAdapter<SocketMessage>(
      FieldEncoding.LENGTH_DELIMITED, 
      SocketMessage::class, 
      "type.googleapis.com/de.geomobile.common.portalmodels.SocketMessage", 
      PROTO_2, 
      null, 
      "portalmodels/socket_message.proto"
    ) {
      public override fun encodedSize(`value`: SocketMessage): Int {
        var size = value.unknownFields.size
        size += ProtoAdapter.STRING.encodedSizeWithTag(1, value.subscriptionPath)
        size += ProtoAdapter.BYTES.encodedSizeWithTag(2, value.message)
        return size
      }

      public override fun encode(writer: ProtoWriter, `value`: SocketMessage): Unit {
        ProtoAdapter.STRING.encodeWithTag(writer, 1, value.subscriptionPath)
        ProtoAdapter.BYTES.encodeWithTag(writer, 2, value.message)
        writer.writeBytes(value.unknownFields)
      }

      public override fun encode(writer: ReverseProtoWriter, `value`: SocketMessage): Unit {
        writer.writeBytes(value.unknownFields)
        ProtoAdapter.BYTES.encodeWithTag(writer, 2, value.message)
        ProtoAdapter.STRING.encodeWithTag(writer, 1, value.subscriptionPath)
      }

      public override fun decode(reader: ProtoReader): SocketMessage {
        var subscriptionPath: String? = null
        var message: ByteString? = null
        val unknownFields = reader.forEachTag { tag ->
          when (tag) {
            1 -> subscriptionPath = ProtoAdapter.STRING.decode(reader)
            2 -> message = ProtoAdapter.BYTES.decode(reader)
            else -> reader.readUnknownField(tag)
          }
        }
        return SocketMessage(
          subscriptionPath = subscriptionPath ?: throw missingRequiredFields(subscriptionPath,
              "subscriptionPath"),
          message = message ?: throw missingRequiredFields(message, "message"),
          unknownFields = unknownFields
        )
      }

      public override fun redact(`value`: SocketMessage): SocketMessage = value.copy(
        unknownFields = ByteString.EMPTY
      )
    }

    private const val serialVersionUID: Long = 0L
  }
}
