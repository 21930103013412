package de.geomobile.frontend.features.softwareManagement.bundle

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardActions
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.card.mCardHeader
import com.ccfraser.muirwik.components.dialog.DialogScroll
import com.ccfraser.muirwik.components.dialog.mDialog
import com.ccfraser.muirwik.components.dialog.mDialogContent
import com.ccfraser.muirwik.components.dialog.mDialogTitle
import com.ccfraser.muirwik.components.form.MFormControlMargin
import com.ccfraser.muirwik.components.form.MFormControlVariant
import com.ccfraser.muirwik.components.input.MInputProps
import com.ccfraser.muirwik.components.list.mList
import com.ccfraser.muirwik.components.list.mListItem
import com.ccfraser.muirwik.components.styles.Breakpoint
import com.ccfraser.muirwik.components.table.mTable
import com.ccfraser.muirwik.components.table.mTableBody
import com.ccfraser.muirwik.components.table.mTableContainer
import de.geomobile.common.portalmodels.Product
import de.geomobile.common.softwaremgmt.CreateBundleDTO
import de.geomobile.common.softwaremgmt.Software
import de.geomobile.common.softwaremgmt.SoftwareBundle
import de.geomobile.common.softwaremgmt.SoftwareVersion
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.utils.*
import kotlinext.js.jsObject
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.*
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.json.Json
import react.*
import styled.css
import styled.styledDiv
import kotlin.math.max
import kotlin.math.min

fun RBuilder.bundleEdit(
    companyId: String,
    initial: SoftwareBundle?,
    createNew: Boolean,
    onSave: () -> Unit,
    product: Product
) = child(BundleEdit::class) {
    attrs.companyId = companyId
    attrs.initial = initial
    attrs.createNew = createNew
    attrs.onSave = onSave
    attrs.key = initial?.internalVersion.orEmpty()
    attrs.product = product
}

class BundleEdit : CComponent<BundleEdit.Props, BundleEdit.State>() {

    private var loadSoftwareJob: Job = Job()
    private var updateJob: Job = Job()

    interface Props : RProps {
        var companyId: String
        var initial: SoftwareBundle?
        var createNew: Boolean
        var onSave: () -> Unit
        var product: Product
    }

    data class Entry(
        val software: Software,
        val softwareVersion: SoftwareVersion?,
        val deleted: Boolean = false,
    )

    data class State(
        var internalVersion: String? = null,
        var externalVersion: String? = null,
        var editExternalVersion: Boolean? = null,
        var entries: List<Entry>? = null,
        var deletedSoftwareIds: List<String>? = null,
        var software: List<Software> = emptyList(),
        var addingSoftware: Boolean = false,
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        loadSoftware()
    }

    private fun loadSoftware() {
        loadSoftwareJob.cancel()
        loadSoftwareJob = launch {
            val software = withContext(Dispatchers.Default) {
                portalRestApi.get(
                    "/software/management/get", ListSerializer(Software.serializer()),
                    parameter = mapOf("product" to props.product.readableName)
                )
            }

            setState {
                this.software = software
            }
        }
    }

    override fun RBuilder.render() {
        val initial = props.initial

        val old = initial?.software?.map {
            Entry(
                software = it.software,
                softwareVersion = it.softwareVersion
            )
        }
            ?: listOf(
                Entry(
                    software =
                    if (props.product == Product.INTERACT)
                        state.software.lastOrNull() ?: return
                    else
                        state.software.singleOrNull { it.updater } ?: return,
                    softwareVersion = null
                )
            )

        val new: List<Entry> = state.entries ?: old

        val internalVersion = when {
            props.createNew -> state.internalVersion ?: ""
            else -> state.internalVersion ?: initial?.internalVersion ?: ""
        }

        val editExternalVersion = state.editExternalVersion ?: (initial?.externalVersion != null)
        val externalVersion = when {
            editExternalVersion -> state.externalVersion ?: initial?.externalVersion ?: internalVersion
            else -> internalVersion
        }

        val available = state.software.minus(new.map { it.software })

        mCard {
            css(GlobalStyles.card)

            mCardHeader(
                title = if (props.createNew)
                    "Neu: $internalVersion (External: $externalVersion)"
                else
                    "Bearbeiten: $internalVersion (Extern: $externalVersion)",
                subHeader = if (props.createNew || initial == null) null else "${initial.createdBy} (${initial.createdAt.toText()})"
            ) {
                attrs.titleTypographyProps = jsObject<MTypographyProps> {
                    variant = MTypographyVariant.subtitle2
                    color = MTypographyColor.secondary
                }
                attrs.subheaderTypographyProps = jsObject<MTypographyProps> {
                    variant = MTypographyVariant.caption
                }
            }
            mDivider { }

            mCardContent {
                css(GlobalStyles.cardBoxContent)

                styledDiv {
                    css { padding(2.spacingUnits) }

                    mGridContainer2(
                        direction = MGridDirection.row,
                        alignItems = MGridAlignItems.center,
                    ) {
                        mGridItem2(MGridBreakpoints2(MGridSize2.Cells5)) {
                            mTextField(
                                label = "Interne Version",
                                value = internalVersion,
                                variant = MFormControlVariant.outlined,
                                margin = MFormControlMargin.dense,
                                onChange = {
                                    val value = it.targetInputValue
                                    setState {
                                        this.internalVersion = value
                                    }
                                }
                            ) {
                                attrs.fullWidth = true
                                attrs.inputProps = jsObject<MInputProps> {
                                    endAdornment = buildElement {
                                        mIconNoTranslate(
                                            fontSize = MIconFontSize.small,
                                            iconName = "fiber_manual_record",
                                        ) {
                                            css {
                                                fontSize = LinearDimension.inherit
                                                when {
                                                    initial == null -> color = Color.lightGreen
                                                    props.createNew -> color = Color.lightBlue
                                                    internalVersion != initial.internalVersion -> color =
                                                        Color.lightBlue
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        mGridItem2(MGridBreakpoints2(MGridSize2.Cells2)) {
                            css { textAlign = TextAlign.center }
                            mIconButtonNoTranslate(
                                if (editExternalVersion) "link_off" else "link",
                                onClick = {
                                    setState { this.editExternalVersion = !editExternalVersion }
                                }
                            )
                        }
                        mGridItem2(MGridBreakpoints2(MGridSize2.Cells5)) {
                            mTextField(
                                label = "Externe Version",
                                value = externalVersion,
                                disabled = !editExternalVersion,
                                variant = MFormControlVariant.outlined,
                                margin = MFormControlMargin.dense,
                                onChange = {
                                    val value = it.targetInputValue
                                    setState {
                                        this.externalVersion = value
                                    }
                                }
                            ) {
                                attrs.fullWidth = true

                                css {
                                    fontSize = LinearDimension.inherit
                                    when {
                                        initial == null -> backgroundColor = Color.lightGreen
                                        editExternalVersion != (initial.externalVersion != null) -> backgroundColor =
                                            Color.lightBlue

                                        externalVersion != initial.externalVersion -> backgroundColor = Color.lightBlue
                                    }
                                }
                            }
                        }
                    }

                    styledDiv {
                        css { padding(2.spacingUnits, 0.spacingUnits) }
                        mTableContainer {
                            mTable {
                                mTableBody {
                                    for ((index, current) in new.withIndex()) {

                                        val previous = old.singleOrNull { it.software == current.software }

                                        newBundledSoftware(
                                            software = current.software,
                                            previousVersion = previous?.softwareVersion,
                                            currentVersion = current.softwareVersion,
                                            deleted = current.deleted,
                                            first = index == 1,
                                            last = index == new.lastIndex,
                                            onChange = { newVersion ->
                                                setState {
                                                    this.entries = new.toMutableList().apply {
                                                        removeAt(index)
                                                        add(index, current.copy(softwareVersion = newVersion))
                                                    }
                                                }
                                            },
                                            onMove = { direction ->
                                                setState {
                                                    entries = new.toMutableList().apply {
                                                        removeAt(index)
                                                        add(min(new.lastIndex, max(0, index + direction)), current)
                                                    }
                                                }
                                            },
                                            onDelete = { deleted ->
                                                setState {
                                                    this.entries = if (previous != null) {
                                                        new.toMutableList().apply {
                                                            removeAt(index)
                                                            add(index, current.copy(deleted = deleted))
                                                        }
                                                    } else {
                                                        new - current
                                                    }
                                                }
                                            }
                                        )
                                    }
                                }
                            }
                        }
                    }

                    styledDiv {
                        css { padding(2.spacingUnits, 0.spacingUnits) }

                        mButton(
                            caption = "Software hinzufügen",
                            disabled = available.none(),
                            variant = MButtonVariant.outlined,
                            color = MColor.secondary,
                            onClick = {
                                setState {
                                    addingSoftware = true
                                }
                            }
                        ) {
                            attrs.fullWidth = true
                        }
                    }
                }

                mDialog(
                    open = available.any() && state.addingSoftware,
                    scroll = DialogScroll.paper,
                    onClose = { _, _ -> setState { addingSoftware = false } }
                ) {
                    mDialogTitle("Software hinzufügen")
                    mDialogContent {
                        mList {
                            for (software in available) {
                                mListItem(software.name,
                                    divider = false,
                                    onClick = {
                                        setState {
                                            addingSoftware = false
                                            entries = new + Entry(
                                                software = software,
                                                softwareVersion = null
                                            )
                                        }
                                    })
                            }
                        }
                    }
                }
            }

            mDivider {}

            mCardActions {
                css { padding(2.spacingUnits) }

                val newSoftware = new.mapNotNull { entry ->
                    entry.softwareVersion?.takeIf { !entry.deleted }?.let {
                        SoftwareBundle.BundledSoftware(
                            software = entry.software,
                            softwareVersion = it
                        )
                    }
                }

                val validSave = new.all { it.softwareVersion != null }

                mGridContainer2(direction = MGridDirection.row) {
                    mGridItem2(
                        MGridBreakpoints2(MGridSize2.Auto)
                            .down(Breakpoint.xs, MGridSize2.Cells12)
                    ) {
                        mButton(
                            caption = "Übernehmen",
                            variant = MButtonVariant.contained,
                            color = MColor.secondary,
                            disabled = !validSave ||
                                    newSoftware == initial?.software ||
                                    internalVersion.isBlank() ||
                                    (props.createNew && internalVersion == initial?.internalVersion),
                            onClick = {
                                if (props.createNew) {
                                    saveBundle(
                                        internalVersion,
                                        externalVersion,
                                        newSoftware
                                    )
                                } else {
                                    updateBundle(
                                        initial!!.id,
                                        internalVersion,
                                        externalVersion,
                                        newSoftware
                                    )
                                }
                            }
                        ) {
                            attrs.disableElevation = true
                            attrs.fullWidth = true
                        }
                    }
                    mGridItem2(
                        MGridBreakpoints2(MGridSize2.Auto)
                            .down(Breakpoint.xs, MGridSize2.Cells12)
                    ) {
                        mButton(
                            caption = "Abbrechen",
                            onClick = {
                                props.onSave()
                            },
                            color = MColor.default,
                            variant = MButtonVariant.contained,
                        ) {
                            attrs.disableElevation = true
                            attrs.fullWidth = true
                        }
                    }
                }
            }
        }
    }

    // TODO: Cleanup
//    private fun clearState() {
//        setState {
//            entries = null
//            deletedSoftwareIds = null
//        }
//    }

    private fun saveBundle(
        internalVersion: String,
        externalVersion: String,
        newSoftware: List<SoftwareBundle.BundledSoftware>,
    ) {
        updateJob.cancel()
        updateJob = launch {
            withContext(Dispatchers.Default) {
                val body = CreateBundleDTO(
                    internalVersion = internalVersion,
                    externalVersion = externalVersion,
                    software = newSoftware
                )
                portalRestApi.put(
                    "/software/bundle/${props.companyId}",
                    body = Json.encodeToString(CreateBundleDTO.serializer(), body)
                )
            }

            props.onSave()
        }
    }

    private fun updateBundle(
        id: Int,
        internalVersion: String,
        externalVersion: String,
        newSoftware: List<SoftwareBundle.BundledSoftware>,
    ) {
        updateJob.cancel()
        updateJob = launch {
            withContext(Dispatchers.Default) {
                val body = CreateBundleDTO(
                    internalVersion = internalVersion,
                    externalVersion = externalVersion,
                    software = newSoftware
                )
                portalRestApi.post(
                    "/software/bundle/${props.companyId}/$id",
                    body = Json.encodeToString(CreateBundleDTO.serializer(), body)
                )
            }

            props.onSave()
        }
    }

}