package de.geomobile.frontend.features.softwareManagement.software

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.list.mList
import com.ccfraser.muirwik.components.list.mListItem
import com.ccfraser.muirwik.components.list.mListItemSecondaryAction
import com.ccfraser.muirwik.components.list.mListItemText
import de.geomobile.common.softwaremgmt.SoftwareFile
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.utils.CComponent
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.padding
import kotlinx.serialization.builtins.ListSerializer
import react.RBuilder
import react.RProps
import react.RState
import react.setState
import styled.css
import utils.humanReadableByteCount

fun RBuilder.softwareVersionFiles(
    softwareId: String,
    softwareVersion: String,
    downloadToken: String,
) = child(SoftwareVersionFiles::class) {
    attrs.softwareId = softwareId
    attrs.softwareVersion = softwareVersion
    attrs.downloadToken = downloadToken
}

class SoftwareVersionFiles : CComponent<SoftwareVersionFiles.Props, SoftwareVersionFiles.State>() {

    private var loadFilesJob: Job = Job()

    interface Props : RProps {
        var softwareId: String
        var softwareVersion: String
        var downloadToken: String
    }

    class State : RState {
        var files: List<SoftwareFile> = emptyList()
    }

    init {
        state = State()
    }

    override fun componentDidMount() {
        loadFiles()
    }

    override fun componentWillReceiveProps(nextProps: Props) {
        if (nextProps.softwareId != props.softwareId || nextProps.softwareVersion != props.softwareVersion)
            loadFiles()
    }

    private fun loadFiles() {
        loadFilesJob.cancel()
        loadFilesJob = launch {
            val files = withContext(Dispatchers.Default) {
                portalRestApi.get(
                    path = "/software/management/${props.softwareId}/${props.softwareVersion}",
                    serializer = ListSerializer(SoftwareFile.serializer())
                )
            }

            setState {
                this.files = files
            }
        }
    }

    override fun RBuilder.render() {
        mList {
            attrs.dense = true
            css { padding(0.spacingUnits) }

            for (file in state.files) {
                mLink(
                    hRefOptions = HRefOptions(
                        href = "/../software/download/${props.softwareId}/${props.softwareVersion}/${file.id}?token=${props.downloadToken}"
                    ),
                    underline = MLinkUnderline.none
                ) {
                    attrs.asDynamic().download =
                        props.softwareId +
                                "__${props.softwareVersion.replace(".", "_")}" +
                                "__${file.filename.takeLastWhile { it != '/' }}"

                    mListItem {
                        attrs.button = true

                        mListItemText(
                            primary = file.filename,
                            secondary = "${file.size.humanReadableByteCount()} (${file.md5})"
                        )

                        mListItemSecondaryAction {
                            mIconButtonNoTranslate(
                                iconName = "get_app",
                                href = "/../software/download/${props.softwareId}/${props.softwareVersion}/${file.id}?token=${props.downloadToken}"
                            ) {
                                attrs.asDynamic().download =
                                    props.softwareId +
                                            "__${props.softwareVersion.replace(".", "_")}" +
                                            "__${file.filename.takeLastWhile { it != '/' }}"
                            }
                        }
                    }
                }
            }
        }
    }
}