package de.geomobile.frontend.utils

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.form.MFormControlMargin
import com.ccfraser.muirwik.components.form.MFormControlVariant
import de.geomobile.frontend.reverseTheme
import kotlinx.css.*
import kotlinx.css.properties.border
import react.*
import reactjsonview.reactJson
import styled.css
import styled.styledDiv

fun RBuilder.jsonEditor(
    json: String,
    edit: Boolean = false,
    onChange: (String) -> Unit = {},
) = child(JsonEditor::class) {
    attrs.json = json
    attrs.edit = edit
    attrs.onChange = onChange
}

class JsonEditor : RComponent<JsonEditor.Props, JsonEditor.State>() {

    interface Props : RProps {
        var json: String
        var edit: Boolean
        var onChange: (String) -> Unit
    }

    class State(
        var editRaw: Boolean = false,
    ) : RState

    init {
        state = State()
    }

    override fun RBuilder.render() {
        val json = props.json
        val valid = try {
            JSON.parse<Any>(json).let { true }
        } catch (e: Throwable) {
            false
        }

        styledDiv {
            css {
                position = Position.relative
                borderRadius = 4.px
                border(
                    width = 2.px,
                    style = BorderStyle.solid,
                    color = when {
                        !valid -> Color.red
//                        props.edit -> Color("#272822")
                        else -> Color.transparent
                    }
                )
                overflow = Overflow.hidden
            }

            when {
                !props.edit -> reactJson(src = json)
                state.editRaw -> mTextFieldMultiLine(
                    label = "",
                    value = json,
                    variant = MFormControlVariant.outlined,
                    margin = MFormControlMargin.none,
                    fullWidth = true,
                    onChange = {
                        val json = it.targetInputValue
                        props.onChange(json)
                    }
                ) {
                    css {
                        descendants {
                            color = Color.white
                            borderStyle = BorderStyle.none
                        }
                        backgroundColor = Color("#272822")
                    }
                }

                else -> reactJson(
                    src = json,
                    displayDataTypes = true,
                    darkTheme = true,
                    onChange = { json -> props.onChange(json) }
                )
            }

            if (props.edit)
                mThemeProvider(reverseTheme) {
                    mTooltip("Raw") {
                        mSwitch(
                            checked = state.editRaw,
                            color = MOptionColor.secondary,
                            onChange = { _, checked -> setState { this.editRaw = checked } }) {
                            css {
                                marginLeft = LinearDimension.auto
                                position = Position.absolute
                                top = 0.spacingUnits
                                right = 0.spacingUnits
                            }
                        }
                    }
                }
        }
    }
}