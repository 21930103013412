package de.geomobile.frontend.features.map

import de.geomobile.common.portalmodels.DeviceIdentifier
import de.geomobile.common.portalmodels.TimestampStatus
import de.geomobile.common.portalmodels.VehicleType
import kotlinx.css.*
import kotlinx.css.properties.transform
import kotlinx.css.properties.translate
import kotlinx.html.js.onClickFunction
import kotlinx.html.unsafe
import react.RBuilder
import react.RProps
import react.RPureComponent
import react.RState
import styled.css
import styled.styledDiv

class MapMarker : RPureComponent<MapMarker.Props, RState>() {

    interface Props : RProps {
        var enableTransform: Boolean?
        var currentZoom: Number

        var lat: Number
        var lng: Number

        var id: Int

        var vehicleId: String?
        var serialNumber: Int?
        var vehicleType: VehicleType
        var statusLocation: TimestampStatus.Status
        var statusLastSeen: TimestampStatus.Status
        var gpsQuality: Int
        var onClick: (DeviceIdentifier) -> Unit
    }

    override fun RBuilder.render() {
        var svg = ""
        var tx = 0.0
        var ty = 0.pct
        if (props.currentZoom.toInt() <= 11) {

            val zoom = 24 - (20 - props.currentZoom.toInt() * 2)
            val width = zoom * 1.1
            val height = zoom * 1.1

            val circleRadius = height / 2

            tx = width.toDouble() * -1
            ty = (-100).pct
            svg =
                "<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='${width}' height='${height}' viewBox='0 0 $width $height'>" +
                        "<g id='Ebene_2' data-name='Ebene 2'>" +
                        "<circle class='cls-1 circle' cx='${width}' cy='${height}' r='${circleRadius}' fill='${
                            if (props.statusLocation == TimestampStatus.Status.ONLINE)
                                Color.green.value
                            else if (props.statusLocation == TimestampStatus.Status.OVERDUE)
                                Color.green.lighten(60).value
                            // red if box is online but has no gps fix
                            else if (props.statusLocation == TimestampStatus.Status.OFFLINE && props.statusLastSeen == TimestampStatus.Status.ONLINE)
                                Color.orange.value
                            else if (props.statusLocation == TimestampStatus.Status.UNKNOWN && props.statusLastSeen == TimestampStatus.Status.UNKNOWN)
                                Color.black.value
                            else
                                Color.gray.value
                        }'/>" +
                        "</svg>"
        } else {
            val height = 32
            val textBoxHeight = 16

            val fontSize = 12
            val alertFontSize = 24
            val spacing = 2

            val strokeWidth = 1
            val cornerRadius = textBoxHeight / 2

            val textLeft = props.vehicleId
                ?.takeIf { it.isNotBlank() }
                ?.let { if (it.length > 13) it.take(10) + "..." else it }
                ?: "-"
            val textRight = props.serialNumber?.toString() ?: "-"

            val circleRadius = height / 2 - strokeWidth

            val textBoxWidthLeft = textLeft.length * fontSize * 0.62 + circleRadius + 2 * spacing
            val textBoxWidthRight = textRight.length * fontSize * 0.62 + circleRadius + 2 * spacing

            val width = textBoxWidthLeft + textBoxWidthRight + 4 * strokeWidth + spacing

            val textBoxY = height / 2 - (textBoxHeight / 2)

            val leftTextBoxX = strokeWidth
            val rightTextBoxX = leftTextBoxX + strokeWidth + textBoxWidthLeft

            val textY = height / 2 + fontSize / 2 - 2

            val imageSize = circleRadius * 1.5
            val imageX =
                if (props.gpsQuality < 70)
                    textBoxWidthLeft + 2 * strokeWidth - imageSize / 2 + 2
                else
                    (textBoxWidthLeft + 2 * strokeWidth - imageSize / 2)

            val gpsQualityAlert =
                if (props.gpsQuality < 70)
                    "<text class='cls-3' transform='translate(${imageX - 7} ${textY + 3.5})'>!</text>"
                else
                    ""

            val image = when (props.vehicleType) {
                VehicleType.TRAIN -> "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAgY0hSTQAAeiYAAICEAAD6AAAAgOgAAHUwAADqYAAAOpgAABdwnLpRPAAAAAZiS0dEAAAAAAAA+UO7fwAAAAlwSFlzAAAASAAAAEgARslrPgAAAddJREFUaN7tmb9KHFEUxn8nCiaoARMMiRYihBBS+gTaxMIuWKQRgo2NYrmWihbp8yp5hOQJrBS3DyL+SyAqJl+KjKDDOHNm987eBe8PbjGXcw7fd+Ysd7kDicTDxjpNlDQGzALvgLfAFPAceAYMAU+BgZtw4BT4Cxxn6wewl63vZrbXuFtJg5I+Sfom6VphOZC0kzWmEfHvJbUDiy7iRNJKaPEfJf3pgfjbtDzaKn8DkqaBXWC4kVd7P9fAjJntlgU9chRajyAeYBBYrQryGJiPIP6GuaoAzwj9Bh5HMnBhZk+6NaA7CWaus6NXeZ4R6muSgdgkA7FJBmJT+xzoucB0DvQ5yUBskoHYPEgDV0ALmMzWRrYXq045ntsCSa0Qtw5FdZow8Kog5mUHBlx1qvR5Ruhn7rnoaB9w1MnjqXMewkA797xUELPkqOPJye+1PYVKkbSde6uX2axOZKuV7dXFU2erSp/n3+gL4AAY7bob9TgDXpvZUVlQ5QiZ2SGwzP8r8l4hYLlKfL2K0qKksw5GpS6nkj54ddX6wCFpHFgDFoA3wEig/vwC9oGvwJegnS8xs1nQvX1JkxV5RYfe58YFhxDfFya6FR/dRAjxZSYaN5BIJBJB+QfIY+6/QieCMQAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxOS0wNC0wMVQxMToyNjo0MyswMDowMDNbZG4AAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTktMDQtMDFUMTE6MjY6NDMrMDA6MDBCBtzSAAAAKHRFWHRzdmc6YmFzZS11cmkAZmlsZTovLy90bXAvbWFnaWNrLW42dzNNbGc4/SwtGwAAAABJRU5ErkJggg=="
                VehicleType.BUS -> "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAgY0hSTQAAeiYAAICEAAD6AAAAgOgAAHUwAADqYAAAOpgAABdwnLpRPAAAAAZiS0dEAAAAAAAA+UO7fwAAAAlwSFlzAAAASAAAAEgARslrPgAAAaZJREFUaN7tmD0vBFEUhp/DJqJD4bNHJ5FoaOj4EWoi1Ctqf41GVKpNtkKBAlEh4tVcCZOxc2Z2Zi9xn+QWe3PuO+87c3c+DiQS/xurulDSIrAGzAMLwDQwEcYQMPZFX8Aj8A7ch3EDdMI4MbNO42klTUg6ltRV/XSD9nhT5vckPTZgPMuDpJ26zR8NwHiWtsdb4X9A0hJwDgw3cml/5g1YNrOLXkVDDqGDCOYBWsB+UZEnwHoE859sFBV4ttAzMBIpwLOZjfYbQN8WmFV+dngoezzPFvrVpACxSQFikwLEJgWITavsguyTMjZ//gqkALFJAWJTJcAr0AbmwjgMc7F0euPpFkhq19F1yNNpIsBMTs10hQAunSJ/VbZQ3idela5FLTqeAE+Z39s5NdsOHc+a7NyTR6gnkk4zV/Ul7NXZMNphriwenZM6AuxWMFcXu0X+PG2VFnAKrPR9NspxBqya2VtfAUKISeB2wAGmzOyuqMjdpPLc0urE20Ar/T1Q9UA/0e+J+VfvQtc5c1c1eGhK9zuStiRdfbnFXUra/K26iUQiMRg+AGWObMEFWAalAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDE5LTA0LTAxVDExOjI2OjMyKzAwOjAwn+lmwwAAACV0RVh0ZGF0ZTptb2RpZnkAMjAxOS0wNC0wMVQxMToyNjozMiswMDowMO603n8AAAAodEVYdHN2ZzpiYXNlLXVyaQBmaWxlOi8vL3RtcC9tYWdpY2stUExVdlZSYlBo95UeAAAAAElFTkSuQmCC"
                VehicleType.SUBWAY -> "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAQAAAD9CzEMAAABb0lEQVR4Ae3WJXhcQRRA4VtmZlTVZdRlZrL1rjVtw2yjwqjWm6z/wpyooA8z0wmozN6FN2F4vxs8ybK4NjbO84kYPBTRQhcjTAMwzQidNFOEh2g+ck7scYw/VOPMDFX85qQ4xX5i6cfWILEclNC4RSNL1cQtCY4nDLIcwzyVwLjBMMs1zB3xjwO0shJa8f9cEMFKiRKN/fSizVBGEj+4z1WOsHth5y4OcYEbvOMfBYyj9XJIB36g5XNNQuAUiUzi66femIVphl/iEC9VIldvqseUJRZIxlSvt3RiuicWuIWpT21R/+QBscABTNN6i4+FuSmc2a3Pb5CAG3ADbsDCxg3wmS46+SAGtbaMQBcAHWLSa/YBji36lmgXxVzjpH3g/cLsBzpo550o5hqf7AOl7BKH2EWFfQBS2enw+nQA+wCU8pETEgQn+UQFWAQUL/sX1h8x4Pvbjf14AZYT0NerxPIC+nqdwD5gzw3Ykw3DNQu27Dv3GJXJOQAAAABJRU5ErkJggg=="
                VehicleType.TRAM, VehicleType.TRAM_GER -> "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAQAAAD9CzEMAAABDUlEQVR4Ae3NJVglARRA4buS1t369/pKXKfTK+5u9SUk4RHvFXdIaMEh4u4OB5/05M7gMH+9cuRms6F0fwMu0MSJP3IZ8OFEvVwOWjnxWy4KDvzJp4JeJljjzCZzjNBKGSn84rFYwUMC6UZjhjReizl8oh0zxvgmejylF7OmeC9aJGBFhmjRjhV9osUCVmyKFhaZDoiSHbADduDmBsyzAxuE8ZwXRLB5OYEwYx55OYHnxvzleQOruPLCmL/ClQ3RohZXIox5DK70iha+uLJJJC95RQxbuJIjehRi1jYO0eMRWeyjt0+ImMUPitnBuyWq+CdW4OTMIF/czpyio3+vT1h/f/6Ei/ceE3Jj2Q4AhrN4x65zJHcAAAAASUVORK5CYII="
                VehicleType.LIGHT_RAIL -> "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAQAAAD9CzEMAAABJElEQVR4Ae3Xz0oCURSA8etWh6SVYc9gPYgoboReoAh6gKCVC6No5zLoKeo1CpSQoWghRFuhf9wW6tfKgYG5nPtngqj7neUczs/lVf3lqLDDASNuuGPGHM0SgCWaOTNuuWbEPi0qKNOYPmxzwQu2PXNO0wU44hPXPji0BU7w7dgG2GWBbwtaMnBFSJcy8ERIjzLwRUhaBtYpx1n3m4AIRCAC7kVALgJT2lSp0SH9CWDKRrZTJy0faOe2uuUD1dxWUj5QCwaEV0Unt9Xzf1XcU1xKPdvZ5IHixjJwBkaiS0JCz3gehjLQ5B3fXmnIgGKPFT6t6KMEICPePH593+UfzhanTNDYpBkzpIEyAeYZYNMAZZ7w8wIRdF4gBMA1GQifCPxT4Bsp8wIDX26g+wAAAABJRU5ErkJggg=="
                VehicleType.NONE -> "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAgY0hSTQAAeiYAAICEAAD6AAAAgOgAAHUwAADqYAAAOpgAABdwnLpRPAAAAAZiS0dEAAAAAAAA+UO7fwAAAAlwSFlzAAAASAAAAEgARslrPgAAAPZJREFUaN7tmTEOwjAMRRPUFXEBWIGrgwRiZeBGCNRygMeSAUpLEhqTRvVb7Vj+duM0rTGKoig5sakCAbwFttbG2H9lJlmdf6ACchMlANgCJ6ChRYdvlN3FPAJrEaUu+Tvy3EREuMr/i0NoXsGjDGiMMXOR9n7SWGsXqQWIzPGh8ac1hcZI8QKqPkPX7P5GrL/vGQ/dE8V3QAXkpncP+N7nfaRe30fxHVABuUl2Dkiv13NgrBQvQO8DuVEBuakifB/m5VI/dM57qEMdYzpwEUy4zTl5RGDjPjpJcwWWImUBVsAeqAUSr4FdbPL6fyA3KkBRFGXaPAEYvkF2Rw+E3wAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxOS0wNC0wM1QwNzoxNjo0MCswMDowMCEbAzoAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTktMDQtMDNUMDc6MTY6NDArMDA6MDBQRruGAAAAKHRFWHRzdmc6YmFzZS11cmkAZmlsZTovLy90bXAvbWFnaWNrLXBka1BiU0xRH6kAsQAAAABJRU5ErkJggg=="
            }

            val renderBox =
                if (props.currentZoom.toInt() >= 14)
                    "<g id='Ebene_1' data-name='Ebene 1'>" +
                            "<rect class='cls-1' x='$leftTextBoxX' y='$textBoxY' width='$textBoxWidthLeft' height='$textBoxHeight' rx='$cornerRadius' ry='$cornerRadius' fill='#fff'/>" +
                            "<rect class='cls-1' x='$rightTextBoxX' y='$textBoxY' width='$textBoxWidthRight' height='$textBoxHeight' rx='$cornerRadius' ry='$cornerRadius' fill='#fff'/>" +
                            "</g>"
                else null

            val renderLabel =
                if (props.currentZoom.toInt() >= 14)
                    "<text class='cls-2' transform='translate(${leftTextBoxX + 2 * spacing} $textY)'>$textLeft</text>" +
                            "<text class='cls-2' transform='translate(${rightTextBoxX + circleRadius + spacing} $textY)'>$textRight</text>"
                else null

            tx = (-textBoxWidthLeft - 2 * strokeWidth)
            ty = (-50).pct
            svg =
                "<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='${width}' height='${height}' viewBox='0 0 $width $height'>" +
                        "<defs>" +
                        "<style>" +
                        ".cls-1{stroke: #000;stroke-miterlimit: 50;stroke-width: ${strokeWidth}px;}" +
                        ".cls-2{font-size:${fontSize}px;font-family:Courier;}" +
                        ".cls-3{font-size:${alertFontSize}px;font-family:Courier;fill:white}" +
                        "</style>" +
                        "</defs>" +
                        renderBox +
                        "<g id='Ebene_2' data-name='Ebene 2'>" +
                        "<circle class='cls-1 circle' cx='${textBoxWidthLeft + 2 * strokeWidth}' cy='${height / 2}' r='${circleRadius}' fill='${
                            if (props.statusLocation == TimestampStatus.Status.ONLINE)
                                Color.green.value
                            else if (props.statusLocation == TimestampStatus.Status.OVERDUE)
                                Color.green.lighten(60).value
                            // red if box is online but has no gps fix
                            else if (props.statusLocation == TimestampStatus.Status.OFFLINE && props.statusLastSeen == TimestampStatus.Status.ONLINE)
                                Color.orange.value
                            else if (props.statusLocation == TimestampStatus.Status.UNKNOWN && props.statusLastSeen == TimestampStatus.Status.UNKNOWN)
                                Color.black.value
                            else
                                Color.gray.value
                        }'/>" +
                        gpsQualityAlert +
                        "</g>" +
                        "<g id='Ebene_3' data-name='Ebene 3'>" +
                        "<image width='$imageSize' height='$imageSize' transform='translate($imageX ${imageSize / 4 - 1} )' xlink:href='$image'/>" +
                        renderLabel +
                        "</g>" +
                        "</svg>"
        }

        styledDiv {
            if (props.enableTransform == true) {
                css {
                    position = Position.absolute
                    transform {
                        translate(tx.px, ty)
                    }
                    cursor = Cursor.pointer
                }
            }
            attrs.onClickFunction = {
                props.onClick(DeviceIdentifier.Id(props.id))
            }
            attrs.unsafe {
                +svg
            }
        }
    }
}