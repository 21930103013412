package de.geomobile.frontend.features.softwareManagement.bundle

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonSize
import com.ccfraser.muirwik.components.form.MFormControlMargin
import com.ccfraser.muirwik.components.form.MFormControlVariant
import com.ccfraser.muirwik.components.menu.mMenuItem
import com.ccfraser.muirwik.components.table.MTableCellAlign
import com.ccfraser.muirwik.components.table.MTableCellPadding
import com.ccfraser.muirwik.components.table.mTableCell
import com.ccfraser.muirwik.components.table.mTableRow
import de.geomobile.common.softwaremgmt.Software
import de.geomobile.common.softwaremgmt.SoftwareVersion
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.utils.CComponent
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.Color
import kotlinx.css.backgroundColor
import kotlinx.css.color
import kotlinx.css.properties.TextDecoration
import kotlinx.css.properties.TextDecorationLine
import kotlinx.css.textDecoration
import kotlinx.serialization.builtins.ListSerializer
import react.*
import styled.css

fun RBuilder.newBundledSoftware(
    software: Software,
    previousVersion: SoftwareVersion?,
    currentVersion: SoftwareVersion?,
    deleted: Boolean,
    first: Boolean,
    last: Boolean,
    onChange: (SoftwareVersion) -> Unit,
    onMove: (direction: Int) -> Unit,
    onDelete: (deleted: Boolean) -> Unit,
) = child(NewBundledSoftware::class) {
    attrs.software = software
    attrs.previousVersion = previousVersion
    attrs.currentVersion = currentVersion
    attrs.deleted = deleted
    attrs.first = first
    attrs.last = last
    attrs.onChange = onChange
    attrs.onMove = onMove
    attrs.onDelete = onDelete

    attrs.key = software.id
}

class NewBundledSoftware(props: Props) : CComponent<NewBundledSoftware.Props, NewBundledSoftware.State>(props) {
    private var loadingJob: Job = Job()

    interface Props : RProps {
        var software: Software
        var previousVersion: SoftwareVersion?
        var currentVersion: SoftwareVersion?
        var deleted: Boolean
        var first: Boolean
        var last: Boolean
        var onChange: (SoftwareVersion) -> Unit
        var onMove: (direction: Int) -> Unit
        var onDelete: (deleted: Boolean) -> Unit
    }

    class State(
        var versions: List<SoftwareVersion> = emptyList(),
    ) : RState

    init {
        state = State()
    }

    override fun componentWillMount() {
        loadVersions(props.software.id)
    }

    private fun loadVersions(softwareId: String) {
        loadingJob.cancel()
        loadingJob = launch {
            val versions = withContext(Dispatchers.Default) {
                portalRestApi.get(
                    path = "/software/management/$softwareId",
                    serializer = ListSerializer(SoftwareVersion.serializer())
                )
            }

            setState {
                this.versions = versions
            }
        }
    }

    override fun RBuilder.render() {
        mTableRow(key = props.software.id) {
            css {
                when {
                    props.previousVersion == null -> backgroundColor = Color.lightGreen
                    props.deleted -> backgroundColor = Color.lightCoral
                }
            }

            mTableCell(
                padding = MTableCellPadding.none
            ) {
                mIconButtonNoTranslate(
                    iconName = "keyboard_arrow_up",
                    color = MColor.inherit,
                    disabled = props.first || props.software.updater,
                    size = MButtonSize.small,
                    onClick = { props.onMove(-1) }
                )
                mIconButtonNoTranslate(
                    iconName = "keyboard_arrow_down",
                    color = MColor.inherit,
                    disabled = props.last || props.software.updater,
                    size = MButtonSize.small,
                    onClick = { props.onMove(1) }
                )
            }

            mTableCell(align = MTableCellAlign.left) {
                mTypography(
                    text = props.software.name,
                    variant = MTypographyVariant.subtitle2,
                    color = MTypographyColor.primary
                ) {
                    css {
                        if (props.deleted)
                            textDecoration = TextDecoration(setOf(TextDecorationLine.lineThrough))
                    }
                }
            }

            mTableCell {
                mSelect(
                    value = props.currentVersion?.version ?: "-",
                    disabled = props.deleted,
                    fullWidth = true,
                    variant = MFormControlVariant.outlined,
                    onChange = { event, _ ->
                        val version = event.targetValue as String
                        props.onChange(state.versions.single { it.version == version })
                    }
                ) {
                    attrs.margin = MFormControlMargin.dense.toString()

                    css {
                        if (props.previousVersion != null && !props.deleted &&
                            props.currentVersion != props.previousVersion
                        )
                            backgroundColor = Color.lightBlue
                    }
                    for (version in state.versions.asReversed()) {
                        mMenuItem(
                            primaryText = version.version,
                            value = version.version
                        )
                    }
                }
            }
            mTableCell(align = MTableCellAlign.right) {
                mIconButtonNoTranslate(
                    iconName = "delete",
                    disabled = props.software.updater,
                    size = MButtonSize.small,
                    color = if (props.deleted) MColor.secondary else MColor.inherit,
                    onClick = {
                        props.onDelete(!props.deleted)
                    }
                ) { css { backgroundColor = Color.red; color = Color.white } }
            }
        }
    }
}