package de.geomobile.frontend.features.map

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonSize
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.styles.Breakpoint
import com.ccfraser.muirwik.components.styles.up
import de.geomobile.common.permission.Permissions
import de.geomobile.common.portalmodels.DeviceIdentifier
import de.geomobile.common.portalmodels.DevicePositionDTO
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.currentTheme
import de.geomobile.frontend.features.device.detail.deviceStatus
import de.geomobile.frontend.features.device.detail.internal.deviceInternalStatus
import de.geomobile.frontend.features.device.detail.tabledInfo
import de.geomobile.frontend.features.device.deviceIdResolver
import de.geomobile.frontend.utils.TableDisplayable
import de.geomobile.frontend.utils.authorize
import kotlinx.css.*
import kotlinx.serialization.KSerializer
import react.RBuilder
import react.RComponent
import react.RProps
import react.RState
import styled.css
import styled.styledDiv

fun RBuilder.mapDetail(
    selectedId: DeviceIdentifier,
    onClose: () -> Unit,
) = deviceIdResolver(selectedId) { id ->
    child(MapDetailView::class) {
        attrs.selectedId = id.id
        attrs.onClose = onClose
    }
}

class MapDetailView : RComponent<MapDetailView.Props, RState>() {

    interface Props : RProps {
        var selectedId: Int
        var onClose: () -> Unit
    }

    override fun RBuilder.render() {
        mCard {
            css(GlobalStyles.card)
            css {
                display = Display.flex
                borderRadius = 0.px
                media(currentTheme.breakpoints.up(Breakpoint.sm)) {
                    height = 100.vh - 64.px
                    width = 400.px
                }
                height = 100.vh - 56.px
                width = 400.px
                overflow = Overflow.auto
                flexDirection = FlexDirection.column
            }
            mCardContent {
                css(GlobalStyles.cardDeviceSidebarContent)
                styledDiv {
                    css { padding(1.spacingUnits) }
                    mIconButtonNoTranslate(
                        iconName = "close",
                        size = MButtonSize.small,
                        onClick = { props.onClose() }
                    )
                }
                mDivider { css { width = 100.pct } }
                mTypography(
                    text = "Eigenschaften",
                    variant = MTypographyVariant.h6,
                    color = MTypographyColor.secondary,
                    component = "div"
                ) { css { padding(2.spacingUnits, 2.spacingUnits, 1.spacingUnits, 2.spacingUnits) } }
                deviceStatus(
                    id = props.selectedId,
                    onDeviceDetailPage = false
                )
                authorize(Permissions.AdminPermissions.internalAccess) {
                    mTypography(
                        text = "Intern",
                        variant = MTypographyVariant.h6,
                        color = MTypographyColor.secondary,
                        component = "div"
                    ) { css { padding(2.spacingUnits, 2.spacingUnits, 1.spacingUnits, 2.spacingUnits) } }
                    deviceInternalStatus(
                        id = props.selectedId,
                        onDeviceDetailPage = false
                    )
                }
                mTypography(
                    text = "GPS",
                    variant = MTypographyVariant.h6,
                    color = MTypographyColor.secondary,
                    component = "div"
                ) { css { padding(2.spacingUnits, 2.spacingUnits, 1.spacingUnits, 2.spacingUnits) } }
                tabledInfo(
                    subscriptionPath = "/device/position",
                    id = props.selectedId,
                    serializer = DevicePositionDTO.serializer() as KSerializer<TableDisplayable>,
                    onDeviceDetailPage = false
                )
            }
        }
    }
}