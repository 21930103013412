package de.geomobile.common.portalmodels

import de.geomobile.common.time.LocalDateTime
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import portalmodels.IbisFunction

@Serializable
data class DeviceIbisInfoDTO(
    val status: TimestampStatus = TimestampStatus(),
    val line: IbisTelegramInfo? = null,
    val destination: IbisTelegramInfo? = null,
    val nextStop: IbisTelegramInfo? = null,
    val course: IbisTelegramInfo? = null
)

@Serializable
data class IbisTelegramInfo(
    val value: String? = null,
    val rawValue: String? = null,
    val telegram: String? = null
)

@Serializable
data class DeviceIbisV18InfoRawDTO(
    val timestamp: LocalDateTime,
    val function: String,
    val type: String,
    val telegram: String
)

@Serializable
data class DeviceIbisV18InfoDTO(
    val timestamp: LocalDateTime,
    val function: IbisFunction,
    val type: String,
    val telegram: IbisTelegram
)

@Serializable
data class DeviceGenericInfoDTO(
    val timestamp: LocalDateTime,
    val source: String,
    val function: String,
    val messageList: List<String>
)

enum class IbisTelegramSerializer(val serializer: KSerializer<out IbisTelegram>) {
    DIRECTION(LineTelegram.serializer()),
    DESTINATION(LineTelegram.serializer()),
    STATIONS(ListMapTelegram.serializer()),
    LOGGING(LoggingTelegramSerializer),
    LINE(LineTelegram.serializer()),
//    DS001(ValueTelegram.serializer()),
//    DS002(ValueTelegram.serializer()),
//    DS003A(ValueTelegram.serializer()),
//    DS004(MapTelegram.serializer()),
//    DS010B(ValueTelegram.serializer()),
//    DS021(MapTelegram.serializer()),
//    DS021A(DS021a.serializer());
}

@Serializable
sealed class IbisTelegram {
//    abstract val value: Any
}

@Serializable
class ValueTelegram(
    val value: String,
    val rawTelegram: String
) : IbisTelegram()

@Serializable
class MapTelegram(
    val value: Map<String,String>,
    val rawTelegram: String,
) : IbisTelegram()


@Serializable
class ListMapTelegram(
    val value: List<MapTelegram>,
) : IbisTelegram()

@Serializable
class LineTelegram(
    val value: String,
    val ibisValue: String,
    val rawTelegram: String
) : IbisTelegram()

@Serializable
class DS021a(
    val value: Map<String,MapTelegram>,
) : IbisTelegram()

@Serializable(with = LoggingTelegramSerializer::class)
class LoggingTelegram(
    val log: String
) : IbisTelegram()

object LoggingTelegramSerializer : KSerializer<LoggingTelegram> {
    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("LoggingTelegram", PrimitiveKind.STRING)

    override fun serialize(encoder: Encoder, obj: LoggingTelegram) = encoder.encodeString(obj.log)

    override fun deserialize(decoder: Decoder): LoggingTelegram =
        LoggingTelegram(decoder.decodeString())

}

