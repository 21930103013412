package de.geomobile.frontend.features.softwareManagement.factoryreset

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardActions
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.dialog.mDialog
import com.ccfraser.muirwik.components.dialog.mDialogActions
import com.ccfraser.muirwik.components.dialog.mDialogContent
import com.ccfraser.muirwik.components.form.MFormControlMargin
import com.ccfraser.muirwik.components.form.MFormControlVariant
import com.ccfraser.muirwik.components.list.*
import com.ccfraser.muirwik.components.menu.mMenuItem
import com.ccfraser.muirwik.components.styles.Breakpoint
import components.emptyView
import de.geomobile.common.portalmodels.*
import de.geomobile.common.stage.Stage
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.spacer
import de.geomobile.frontend.utils.*
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.*
import kotlinx.serialization.builtins.ListSerializer
import portalmodels.GenericResponseDTO
import react.RBuilder
import react.RProps
import react.RState
import react.setState
import styled.css
import kotlin.collections.set

fun RBuilder.factoryReset() = child(FactoryReset::class) {}

class FactoryReset : CComponent<FactoryReset.Props, FactoryReset.State>() {

    private var job: Job = Job()

    interface Props : RProps

    class State(
        var deviceList: List<DeviceDTO> = emptyList(),
        var selectedDevice: String? = null,
        var selectedDeviceDetail: DeviceDetailDTO? = null,
        var companyId: String? = null,
        var companyList: List<CompanySmall> = emptyList(),
        var selectedNewCompany: String? = null,
        var selectedNewStage: Stage = Stage.ASSEMBLED,
        var errorMessage: String? = null,
        var errorOverride: Boolean = false,
        var viewStatus: MutableMap<String, Boolean?> = mutableMapOf(
            "confirmReset" to null,
            "resetConfirmed" to null,
            "confirmWithError" to null,
            "checkSound" to null,
            "checkLED" to null,
            "confirmTriggerSound" to null,
            "confirmTriggerLED" to null,
        ),
        var selectedDeviceStatus: MutableMap<String, GenericResponseDTO?> = mutableMapOf(
            "isOnline" to null,
            "isBackupOnline" to null,
            "hasNotMoved" to null,
            "changeCompany" to null,
            "remMQTT" to null,
            "resetBox" to null,
            "isValidStage" to null
        ),
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        launch {
            val companyId = withContext(Dispatchers.Default) {
                portalRestApi.get(
                    path = "/user",
                    serializer = UserDTO.serializer()
                )
            }.company.id

            val deviceList = withContext(Dispatchers.Default) {
                portalRestApi.get(
                    path = "/software/factoryreset/$companyId/devices",
                    serializer = ListSerializer(DeviceDTO.serializer())
                )
            }

            val companies = withContext(Dispatchers.Default) {
                portalRestApi.get(
                    path = "/admin/companies",
                    serializer = ListSerializer(Company.serializer())
                )
            }.map { it.small }

            setState {
                this.companyId = companyId
                this.deviceList = deviceList
                this.companyList = companies
            }
        }
    }

    fun getPreflight(id: String) {
        getStatus(id, "isOnline")
        getStatus(id, "isBackupOnline")
        getStatus(id, "hasNotMoved")
        getStatus(id, "isValidStage")
        //getStatus(id, "connectedSSH")
        connectSSH(id)
    }

    fun connectSSH(id: String) {
        launch {
            portalRestApi.get(
                path = "/software/factoryreset/device/$id/connectedSSH",
                serializer = GenericResponseDTO.serializer()
            )?.let {
                setState { selectedDeviceStatus["connectedSSH"] = it }
            }
        }
    }

    fun triggerSound(id: String) {
        launch {
            portalRestApi.get(
                path = "/software/factoryreset/device/$id/triggerSound",
                serializer = GenericResponseDTO.serializer()
            )?.let {
                setState { selectedDeviceStatus["triggerSound"] = it }
            }
        }
    }

    fun triggerLED(id: String) {
        launch {
            portalRestApi.get(
                path = "/software/factoryreset/device/$id/triggerLED",
                serializer = GenericResponseDTO.serializer()
            )?.let {
                setState { selectedDeviceStatus["triggerLED"] = it }
            }
        }
    }

    fun getStatus(id: String, toCheck: String) {
        launch {
            portalRestApi.get(
                path = "/software/factoryreset/device/$id/$toCheck",
                serializer = GenericResponseDTO.serializer()
            )?.let {
                setState { selectedDeviceStatus[toCheck] = it }
            }
        }
    }

    fun resetView() {
        launch {
            setState {
                selectedDeviceStatus.forEach {
                    selectedDeviceStatus[it.key] = null
                }
                viewStatus.forEach {
                    viewStatus[it.key] = null
                }
            }
        }
    }

    fun startReset() {
        setState {
            viewStatus["confirmReset"] = false
            viewStatus["resetConfirmed"] = true
        }
        launch {
            var withError = false
            portalRestApi.get(
                path = "/software/factoryreset/device/${state.selectedDevice}/reset/box",
                serializer = GenericResponseDTO.serializer()
            ).let {
                setState {
                    selectedDeviceStatus["resetBox"] = it
                    if (it.isError) {
                        withError = true
                        errorMessage = it.message
                    }
                }
            }
            if (withError)
                setState { viewStatus["confirmWithError"] = true }
            else {
                resetDBAndMQTT()
                closeSSH()
            }

        }
    }

    suspend fun closeSSH(device: String? = state.selectedDevice) {
        portalRestApi.get(
            path = "/software/factoryreset/device/$device/sshclose",
            serializer = GenericResponseDTO.serializer()
        )
    }

    fun resetDBAndMQTT() {
        launch {
            portalRestApi.get(
                path = "/software/factoryreset/device/${state.selectedDevice}/newcompany/${state.selectedNewCompany}/${state.selectedNewStage.readableName}",
                serializer = GenericResponseDTO.serializer()
            )?.let {
                setState {
                    if (!it.isError)
                        selectedDeviceStatus["changeCompany"] = it
                    else {
                        errorMessage = it.message
                        selectedDeviceStatus["changeCompany"] = it
                    }
                }
            }
            portalRestApi.get(
                path = "/software/factoryreset/device/${state.selectedDevice}/reset/mqtt",
                serializer = GenericResponseDTO.serializer()
            )?.let {
                setState {
                    if (!it.isError)
                        selectedDeviceStatus["remMQTT"] = it
                    else {
                        errorMessage = it.message
                        selectedDeviceStatus["remMQTT"] = it
                    }
                }
            }
        }
    }

    override fun componentWillUnmount() {

    }

    fun RBuilder.preFlightStatus(status: GenericResponseDTO?) {
        when {
            status == null -> mCircularProgress(size = 20.px)
            !status.isError -> mIconNoTranslate("check_circle") {
                css { color = Color.green }
            }

            status.isError -> mTooltip(status.message) {
                mIconNoTranslate("info") {
                    css { color = Color.red }
                }
            }
        }
    }

    override fun RBuilder.render() {
        spacer()
        mGridContainer2(direction = MGridDirection.row) {
            mGridItem2(
                MGridBreakpoints2(MGridSize2.Cells6)
                    .down(Breakpoint.md, MGridSize2.Cells12)
            ) {
                mCard {
                    css(GlobalStyles.card)
                    mListSubheader(heading = "Box zurücksetzen")
                    mDivider { }
                    mCardContent {
                        css(GlobalStyles.cardListContent)

                        mList {
                            mListItem {
                                attrs.divider = true
                                attrs.button = false

                                mListItemText {
                                    mTypography(
                                        text = "Seriennummer",
                                        variant = MTypographyVariant.caption,
                                    )
                                    mSelect(
                                        variant = MFormControlVariant.outlined,
                                        value = state.selectedDevice?.ifEmpty { "k.A." } ?: "k.A.",
                                        name = "Box",
                                        id = "device",
                                        onChange = { event, _ ->
                                            resetView()
                                            val id = event.targetValue as String
                                            setState { this.selectedDevice = id }
                                            getPreflight(id)
                                        }
                                    ) {
                                        attrs.margin = MFormControlMargin.dense.toString()
                                        attrs.fullWidth = true

                                        for (device in state.deviceList) {
                                            mMenuItem(
                                                primaryText = device.serialNumber.toString(),
                                                value = device.id.toString()
                                            )
                                        }
                                    }
                                }
                            }

                            if (state.selectedDevice != null) {
                                val device = state.deviceList.first { it.id.toString() == state.selectedDevice }

                                mListItem {
                                    attrs.divider = true
                                    attrs.button = false

                                    mListItemText {
                                        mTypography(
                                            text = "Neue Firma",
                                            variant = MTypographyVariant.caption,
                                        )
                                        mSelect(
                                            variant = MFormControlVariant.outlined,
                                            value = state.selectedNewCompany ?: "k.A.",
                                            name = "Box",
                                            id = "device",
                                            onChange = { event, _ ->
                                                val id = event.targetValue as String
                                                setState { this.selectedNewCompany = id }
                                            }
                                        ) {
                                            attrs.margin = MFormControlMargin.dense.toString()
                                            attrs.fullWidth = true

                                            for (company in state.companyList) {
                                                mMenuItem(primaryText = company.name, value = company.id)
                                            }
                                        }
                                    }
                                }

                                mListItem {
                                    attrs.divider = true
                                    attrs.button = false

                                    mListItemText {
                                        mTypography(
                                            text = "Neue Stage",
                                            variant = MTypographyVariant.caption,
                                        )
                                        mSelect(
                                            variant = MFormControlVariant.outlined,
                                            value = state.selectedNewStage.readableName.ifEmpty { "-" },
                                            name = "Box",
                                            id = "device",
                                            onChange = { event, _ ->
                                                val id = event.targetValue as String
                                                setState { this.selectedNewStage = Stage.fromReadable(id)!! }
                                            }
                                        ) {
                                            attrs.margin = MFormControlMargin.dense.toString()
                                            attrs.fullWidth = true

                                            mMenuItem(
                                                primaryText = Stage.CREATED.readableName,
                                                value = Stage.CREATED.readableName
                                            )
                                            mMenuItem(
                                                primaryText = Stage.ASSEMBLED.readableName,
                                                value = Stage.ASSEMBLED.readableName
                                            )
                                        }
                                    }
                                }

                                if (
                                    state.selectedDeviceStatus.any { it.value?.isError == true } ||
                                    state.viewStatus.any { it.value == false }
                                ) {
                                    mListItem {
                                        attrs.divider = true
                                        attrs.button = false

                                        mListItemText {
                                            mTypography(
                                                text = "Admin Override",
                                                variant = MTypographyVariant.caption,
                                            )
                                        }
                                        mListItemIcon {
                                            css { minWidth = 5.spacingUnits }

                                            attrs.disabled = true
                                            attrs.disableRipple = true

                                            mCheckbox(
                                                checked = state.viewStatus["adminOverride"] == true,
                                                onChange = { _, checked ->
                                                    setState {
                                                        viewStatus["adminOverride"] = checked
                                                    }
                                                }
                                            )
                                        }
                                    }
                                }

                                mListItem {
                                    attrs.divider = true
                                    attrs.button = false

                                    mListItemText {
                                        mTypography(
                                            text = "Firma: " + device.company.name,
                                            variant = MTypographyVariant.caption,
                                        )
                                    }
                                }

                                mListItem {
                                    attrs.divider = true
                                    attrs.button = false

                                    mListItemText {
                                        mTypography(
                                            text = "Produkt: " + device.product.readableName,
                                            variant = MTypographyVariant.caption,
                                        )
                                    }
                                }

                                mListItem {
                                    attrs.divider = true
                                    attrs.button = false

                                    mListItemText {
                                        mTypography(
                                            text = "Hergestellt am " + device.installDate.format(),
                                            variant = MTypographyVariant.caption,
                                        )
                                    }
                                }

                                mListItem {
                                    attrs.divider = true
                                    attrs.button = false

                                    mListItemText {
                                        mTypography(
                                            text = "Fahrzeugnummer: " + device.vehicleId.ifEmpty { "k.A." },
                                            variant = MTypographyVariant.caption,
                                        )
                                    }
                                }

                                mListItem {
                                    attrs.divider = true
                                    attrs.button = false

                                    mListItemIcon {
                                        css { minWidth = 5.spacingUnits }
                                        attrs.disabled = true
                                        attrs.disableRipple = true
                                        preFlightStatus(state.selectedDeviceStatus["isOnline"])
                                    }

                                    mListItemText {
                                        mTypography(
                                            text = "Box ist Online (Normal)",
                                            variant = MTypographyVariant.caption,
                                        )
                                    }
                                }

                                mListItem {
                                    attrs.divider = true
                                    attrs.button = false

                                    mListItemIcon {
                                        css { minWidth = 5.spacingUnits }
                                        attrs.disabled = true
                                        attrs.disableRipple = true
                                        preFlightStatus(state.selectedDeviceStatus["isBackupOnline"])
                                    }

                                    mListItemText {
                                        mTypography(
                                            text = "Box ist Online (Beta)",
                                            variant = MTypographyVariant.caption,
                                        )
                                    }
                                }

                                mListItem {
                                    attrs.divider = true
                                    attrs.button = false

                                    mListItemIcon {
                                        css { minWidth = 5.spacingUnits }
                                        attrs.disabled = true
                                        attrs.disableRipple = true
                                        preFlightStatus(state.selectedDeviceStatus["hasNotMoved"])
                                    }

                                    mListItemText {
                                        mTypography(
                                            text = "Box ist stationär",
                                            variant = MTypographyVariant.caption,
                                        )
                                    }
                                }

                                mListItem {
                                    attrs.divider = true
                                    attrs.button = false

                                    mListItemIcon {
                                        css { minWidth = 5.spacingUnits }
                                        attrs.disabled = true
                                        attrs.disableRipple = true
                                        preFlightStatus(state.selectedDeviceStatus["isValidStage"])
                                    }

                                    mListItemText {
                                        mTypography(
                                            text = "Box hat gültige Stage",
                                            variant = MTypographyVariant.caption,
                                        )
                                    }
                                }

                                mListItem {
                                    attrs.divider = true
                                    attrs.button = false

                                    mListItemIcon {
                                        css { minWidth = 5.spacingUnits }
                                        attrs.disabled = true
                                        attrs.disableRipple = true
                                        preFlightStatus(state.selectedDeviceStatus["connectedSSH"])
                                    }

                                    mListItemText {
                                        mTypography(
                                            text = "SSH Verbindung",
                                            variant = MTypographyVariant.caption,
                                        )
                                    }
                                }

                                mListItem {
                                    attrs.divider = true
                                    attrs.button = false

                                    mListItemIcon {
                                        css { minWidth = 5.spacingUnits }
                                        attrs.disabled = true
                                        attrs.disableRipple = true

                                        state.selectedDeviceStatus["connectedSSH"]?.let {
                                            if (!it.isError && (state.selectedDeviceStatus["triggerSound"]?.message
                                                    ?: "OK") == "OK"
                                            )
                                            else
                                                mTooltip(
                                                    title = it.takeIf { it.isError }?.message
                                                        ?: state.selectedDeviceStatus["triggerSound"]?.message ?: ""
                                                ) {
                                                    mIconNoTranslate("info") {
                                                        css { color = Color.red }
                                                    }
                                                }
                                        } ?: mCircularProgress(size = 20.px)
                                    }

                                    mListItemText {
                                        mTypography(
                                            text = "Ton abgespielt",
                                            variant = MTypographyVariant.caption,
                                        )
                                    }

                                    state.selectedDeviceStatus["connectedSSH"]?.let {
                                        if (!it.isError && (state.selectedDeviceStatus["triggerSound"]?.message
                                                ?: "OK") == "OK"
                                        )
                                            mButton(
                                                caption = "Abspielen",
                                                disabled = state.viewStatus["confirmTriggerSound"] == true,
                                                onClick = {
                                                    setState {
                                                        triggerSound(state.selectedDevice!!)
                                                        viewStatus["confirmTriggerSound"] = true
                                                    }
                                                }
                                            )
                                    }

                                    mListItemIcon {
                                        css { minWidth = 5.spacingUnits }

                                        attrs.disabled = true
                                        attrs.disableRipple = true

                                        state.selectedDeviceStatus["connectedSSH"]?.let {
                                            if (!it.isError && (state.selectedDeviceStatus["triggerSound"]?.message
                                                    ?: "OK") == "OK"
                                            )
                                                mCheckbox(
                                                    checked = state.viewStatus["checkSound"] == true,
                                                    disabled = !((state.viewStatus["confirmTriggerSound"]
                                                        ?: false) && state.selectedDeviceStatus["triggerSound"]?.isError == false),
                                                    onChange = { _, checked ->
                                                        setState {
                                                            viewStatus["checkSound"] = checked
                                                        }
                                                    }
                                                )
                                        }
                                    }
                                }

                                mListItem {
                                    attrs.divider = true
                                    attrs.button = false

                                    state.selectedDeviceStatus["connectedSSH"]?.let {
                                        if (!it.isError && (state.selectedDeviceStatus["triggerLED"]?.message
                                                ?: "OK") == "OK"
                                        )
                                            mButton(
                                                caption = "Blinken",
                                                disabled = state.viewStatus["confirmTriggerLED"] == true,
                                                onClick = {
                                                    setState {
                                                        triggerLED(state.selectedDevice!!)
                                                        viewStatus["confirmTriggerLED"] = true
                                                    }
                                                }
                                            )
                                    }

                                    mListItemIcon {
                                        css { minWidth = 5.spacingUnits }
                                        attrs.disabled = true
                                        attrs.disableRipple = true

                                        state.selectedDeviceStatus["connectedSSH"]?.let {
                                            if (!it.isError && (state.selectedDeviceStatus["triggerLED"]?.message
                                                    ?: "OK") == "OK"
                                            )
                                                mCheckbox(
                                                    checked = state.viewStatus["checkLED"] == true,
                                                    disabled = !((state.viewStatus["confirmTriggerLED"]
                                                        ?: false) && state.selectedDeviceStatus["triggerLED"]?.isError == false),
                                                    onChange = { _, checked ->
                                                        setState {
                                                            viewStatus["checkLED"] = checked
                                                        }
                                                    }
                                                )
                                            else
                                                mTooltip(
                                                    title = it.takeIf { it.isError }?.message
                                                        ?: state.selectedDeviceStatus["triggerLED"]?.message ?: ""
                                                ) {
                                                    mIconNoTranslate("info") {
                                                        css { color = Color.red }
                                                    }
                                                }
                                        } ?: mCircularProgress(size = 20.px)
                                    }

                                    mListItemText {
                                        mTypography(
                                            text = "Status LED blinken abwechselnd",
                                            variant = MTypographyVariant.caption,
                                        )
                                    }
                                }
                            }
                        }
                    }
                    mCardActions {
                        css { padding(1.spacingUnits, 2.spacingUnits, 2.spacingUnits, 2.spacingUnits) }
                        mGridContainer2(direction = MGridDirection.row) {
                            mGridItem2(
                                MGridBreakpoints2(MGridSize2.Auto)
                                    .down(Breakpoint.xs, MGridSize2.Cells12)
                            ) {
                                mButton(
                                    variant = MButtonVariant.contained,
                                    color = MColor.secondary,
                                    caption = "Fortsetzen",
                                    onClick = { setState { viewStatus["confirmReset"] = true } },
                                    disabled = !(state.viewStatus["adminOverride"] == true &&
                                            state.selectedNewCompany != null ||
                                            state.viewStatus["checkSound"] == true &&
                                            state.viewStatus["checkLED"] == true &&
                                            state.selectedDeviceStatus["hasNotMoved"]?.isError == false &&
                                            state.selectedDeviceStatus["isBackupOnline"]?.isError == false &&
                                            state.selectedNewCompany != null
                                            )
                                ) {
                                    attrs.disableElevation = true
                                    attrs.fullWidth = true
                                }
                            }
                            mGridItem2(
                                MGridBreakpoints2(MGridSize2.Auto)
                                    .down(Breakpoint.xs, MGridSize2.Cells12)
                            ) {
                                mButton(
                                    color = MColor.default,
                                    variant = MButtonVariant.contained,
                                    caption = "Abbrechen",
                                    onClick = {
                                        state.selectedDevice.let {
                                            launch {
                                                closeSSH(it)
                                            }
                                        }
                                        setState {
                                            resetView()
                                            selectedDevice = null
                                            viewStatus["confirmReset"] = false
                                            errorMessage = null
                                        }
                                    }
                                ) {
                                    attrs.disableElevation = true
                                    attrs.fullWidth = true
                                }
                            }
                        }
                    }
                }
            }

            mGridItem2(
                MGridBreakpoints2(MGridSize2.Cells6)
                    .down(Breakpoint.md, MGridSize2.Cells12)
            ) {
                if (state.selectedDevice != null) {
                    if (state.viewStatus["resetConfirmed"] == true) {
                        mCard {
                            css(GlobalStyles.card)
                            mListSubheader(heading = "Status")
                            mDivider { }
                            mCardContent {
                                css(GlobalStyles.cardListContent)

                                mList {
                                    mListItem {
                                        attrs.divider = true
                                        attrs.button = false

                                        mListItemIcon {
                                            css { minWidth = 5.spacingUnits }
                                            attrs.disabled = true
                                            attrs.disableRipple = true
                                            preFlightStatus(state.selectedDeviceStatus["changeCompany"])
                                        }

                                        mListItemText {
                                            mTypography(
                                                text = "Box wird intern umgestellt",
                                                variant = MTypographyVariant.caption,
                                            )
                                        }
                                    }

                                    mListItem {
                                        attrs.divider = true
                                        attrs.button = false

                                        mListItemIcon {
                                            css { minWidth = 5.spacingUnits }
                                            attrs.disabled = true
                                            attrs.disableRipple = true
                                            preFlightStatus(state.selectedDeviceStatus["resetBox"])
                                        }

                                        mListItemText {
                                            mTypography(
                                                text = "ivanto Box Reset Skript wird ausgeführt",
                                                variant = MTypographyVariant.caption,
                                            )
                                        }
                                    }

                                    mListItem {
                                        attrs.divider = true
                                        attrs.button = false

                                        mListItemIcon {
                                            css { minWidth = 5.spacingUnits }
                                            attrs.disabled = true
                                            attrs.disableRipple = true
                                            preFlightStatus(state.selectedDeviceStatus["remMQTT"])
                                        }

                                        mListItemText {
                                            mTypography(
                                                text = "MQTT Einträge entfernt",
                                                variant = MTypographyVariant.caption,
                                            )
                                        }
                                    }

                                    if (state.selectedDeviceStatus.filter { it.key != "isOnline" }.values.all { it?.isError ?: false == true }) {
                                        mListItem {
                                            attrs.divider = true
                                            attrs.button = false

                                            mListItemText {
                                                mTypography(
                                                    text = "Die Box wurde erfolgreich auf ein anderes Unternehmen zurückgesetzt",
                                                    variant = MTypographyVariant.caption,
                                                    component = "div"
                                                ) { css { color = Color.green } }
                                            }
                                        }
                                    }

                                    state.selectedDeviceStatus["resetBox"]?.let {
                                        mListItem {
                                            attrs.divider = true
                                            attrs.button = false

                                            mListItemIcon {
                                                css { minWidth = 5.spacingUnits }
                                                attrs.disabled = true
                                                attrs.disableRipple = true
                                                preFlightStatus(state.selectedDeviceStatus["resetBox"])
                                            }

                                            mListItemText {
                                                mTypography(
                                                    text = "Box Zurückgesetzt",
                                                    variant = MTypographyVariant.caption,
                                                )
                                                mTypography(
                                                    text = "Status: ${it.message}",
                                                    variant = MTypographyVariant.caption,
                                                    component = "div"
                                                ) { css { color = Color.green } }
                                            }
                                        }
                                    }

                                    state.errorMessage?.let {
                                        mListItem {
                                            attrs.divider = true
                                            attrs.button = false

                                            mListItemText {
                                                mTypography(
                                                    text = "Beim Zurücksetzen ist ein Fehler aufgetreten. Bitte informieren sie einen Entwickler.",
                                                    variant = MTypographyVariant.caption,
                                                )
                                                mTypography(
                                                    text = "Fehler: $it",
                                                    color = MTypographyColor.error,
                                                    variant = MTypographyVariant.caption,
                                                    component = "div"
                                                )
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    } else {
                        emptyView(
                            title = "Reset wurde noch nicht bestätigt",
                            caption = "Box wurde noch nicht zurückgesetzt",
                            addButton = false,
                        )
                    }
                } else {
                    emptyView(
                        title = "Seriennummer",
                        caption = "Wählen Sie die Seriennummer aus",
                        addButton = false,
                    )
                }
            }
        }

        mDialog(
            open = state.viewStatus["confirmReset"] == true,
            onClose = { _, _ ->
                setState { viewStatus["confirmReset"] = false }
            }
        ) {
            mDialogContent {
                mTypography(
                    text = "Möchten Sie dieses Gerät wirklich zurücksetzen \nund auf die neue Firma buchen?"
                )
            }
            mDialogActions {
                mButton(
                    caption = "Nein, Abbrechen",
                    onClick = {
                        setState { viewStatus["confirmReset"] = false;viewStatus["resetConfirmed"] = false }
                    }
                )
                mButton(
                    caption = "Ja, Zurücksetzen",
                    onClick = { startReset() }
                ) {
                    css { color = Color.red }
                }
            }
        }

        mDialog(
            open = state.viewStatus["confirmWithError"] == true,
            onClose = { _, _ ->
                setState { viewStatus["confirmWithError"] = false }
            }
        ) {
            mDialogContent {
                mTypography(
                    text = "Beim Reset ist folgender Fehler aufgetreten: ${state.errorMessage}"
                )
                mTypography(
                    text = "Möchten Sie trotzdem Fortfahren und die Box intern und im MQTT ändern?"
                )
            }
            mDialogActions {
                mButton(
                    caption = "Nein, Abbrechen",
                    onClick = {
                        setState {
                            viewStatus["confirmWithError"] = false
                        }
                        //resetView()
                    }
                )
                mButton(
                    caption = "Ja, Zurücksetzen",
                    onClick = {
                        resetDBAndMQTT()
                        setState { viewStatus["confirmWithError"] = false }
                    }
                ) {
                    css { color = Color.red }
                }
            }
        }
    }
}