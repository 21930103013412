package de.geomobile.frontend.features.dashboard

import com.ccfraser.muirwik.components.MGridDirection
import com.ccfraser.muirwik.components.styles.Breakpoint
import de.geomobile.common.permission.Permissions
import de.geomobile.common.portalmodels.UserDTO
import de.geomobile.frontend.api.TopicSession
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.portalWebSocketApi
import de.geomobile.frontend.spacer
import de.geomobile.frontend.utils.*
import kotlinx.browser.localStorage
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.serialization.json.Json
import org.w3c.dom.get
import portalmodels.DashboardStatusSummaryDTO
import react.RBuilder
import react.RProps
import react.RState
import react.setState

fun RBuilder.dashboardStatus(
    path: String
) = child(DashboardStatus::class) {
    attrs.path = path
}

class DashboardStatus : CComponent<DashboardStatus.Props, DashboardStatus.State>() {

    private var session: TopicSession? = null

    interface Props : RProps {
        var path: String
    }

    class State(
        var company: String? = null,
        var loading: Boolean = true,
        var summary: DashboardStatusSummaryDTO? = null
    ) : RState

    init {
        state = State()
    }

    suspend fun loadCompany() {
        val companyId =
            if (isAuthorized(Permissions.VehicleProfileManagement.notRestrictedToCompany) && localStorage["StatusDashboard"] != null) {
                localStorage["StatusDashboard"]
            } else {
                withContext(Dispatchers.Default) {
                    portalRestApi.get("/user", UserDTO.serializer())
                }.company.id
            }

        setState {
            company = companyId
        }
    }

    private fun loadSummary() {
        session?.close()
        session = portalWebSocketApi.subscribe("/dashboard/status", mapOf("company" to (state.company.toString())))
        session?.connect {
            onmessage = { msg ->
                val response = Json.decodeFromString(DashboardStatusSummaryDTO.serializer(), msg)
//                    .associateBy { it.deviceId }
//                val update =
//                    response.filter { !state.summaries.containsKey(it.key) } +
//                            state.summaries.mapValues {
//                                it.value.copy(
//                                    device = response[it.key]?.device ?: it.value.device,
//                                    ivantoOnlineStatus = response[it.key]?.ivantoOnlineStatus
//                                        ?: it.value.ivantoOnlineStatus,
//                                    linuxOnlineStatus = response[it.key]?.linuxOnlineStatus
//                                        ?: it.value.linuxOnlineStatus,
//                                    updateStatus = response[it.key]?.updateStatus ?: it.value.updateStatus,
//                                    ibisStatus = response[it.key]?.ibisStatus ?: it.value.ibisStatus,
//                                    fmsStatus = response[it.key]?.fmsStatus ?: it.value.fmsStatus,
//                                    gpsStatus = response[it.key]?.gpsStatus ?: it.value.gpsStatus,
//                                )
//                            }

                setState {
                    summary = response
                    loading = false
                }
            }
        }
    }

    override fun componentDidMount() {
        launch {
            loadCompany()
            loadSummary()
        }
    }

    override fun componentWillUnmount() {
        super.componentWillUnmount()
        session?.close()
    }

    override fun RBuilder.render() {
        spacer()
        mGridContainer2(direction = MGridDirection.row) {
            mGridItem2(
                MGridBreakpoints2(MGridSize2.Cells7)
                    .down(Breakpoint.md, MGridSize2.Cells12)
            ) {
                mGridContainer2(direction = MGridDirection.column) {
                    mGridItem2(
                        MGridBreakpoints2(MGridSize2.Cells12)
                    ) {
                        dashboardHardware(
                            company = state.company,
                            loading = state.loading,
                            summaries = state.summary
                        )
                    }
                    mGridItem2(
                        MGridBreakpoints2(MGridSize2.Cells12)
                    ) {
                        dashboardVehicles(
                            company = state.company,
                            loading = state.loading,
                            summaries = state.summary
                        )
                    }
                }
            }
            mGridItem2(
                MGridBreakpoints2(MGridSize2.Cells5)
                    .down(Breakpoint.md, MGridSize2.Cells12)
            ) {
                mGridContainer2(direction = MGridDirection.column) {
                    mGridItem2(
                        MGridBreakpoints2(MGridSize2.Cells12)
                    ) {
                        dashboardSoftware(
                            company = state.company,
                            loading = state.loading,
                            summaries = state.summary
                        )
                    }
                }
            }
        }
    }
}