package de.geomobile.frontend.features.tts

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonSize
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.list.mListSubheader
import com.ccfraser.muirwik.components.table.*
import de.geomobile.common.portalmodels.RequestPasswordResetDTO
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.spacer
import de.geomobile.frontend.utils.*
import kotlinext.js.assign
import kotlinx.browser.localStorage
import kotlinx.browser.window
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.*
import kotlinx.html.AUDIO
import kotlinx.html.InputType
import kotlinx.html.js.onChangeFunction
import kotlinx.html.js.onClickFunction
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.json.Json
import org.w3c.dom.Audio
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.events.Event
import org.w3c.dom.get
import org.w3c.files.FileReader
import org.w3c.files.get
import portalmodels.FakeGpsDTO
import portalmodels.TtsStationDTO
import react.RBuilder
import react.RProps
import react.RState
import react.dom.audio
import react.router.dom.route
import react.setState
import styled.css
import styled.styledDiv
import styled.styledInput

fun RBuilder.textToSpeechStations() = child(TextToSpeechStations::class) {}

class TextToSpeechStations : CComponent<TextToSpeechStations.Props, TextToSpeechStations.State>() {

    interface Props : RProps

    val fileReader = FileReader()

    class State(
        var company: String? = null,
        var stations: MutableList<TtsStationDTO> = mutableListOf(),
    ) : RState

    init {
        state = State()

        fileReader.onloadend = { e ->
            readCSV(e)
        }
    }

    fun fetchStations(company: String) {
        launch {
            val stations = portalRestApi.get("/tts/stations/${company}", ListSerializer(TtsStationDTO.serializer()))
            setState {
                this.stations = stations.toMutableList()
            }
        }
    }

    fun deleteStations(company: String) {
        launch {
            portalRestApi.delete("/tts/stations/${company}")
        }.invokeOnCompletion {
            setState {
                stations = mutableListOf()
            }
        }
    }

    fun setStations(stations: List<TtsStationDTO>) {
        launch {
            portalRestApi.post(
                path = "/tts/stations/${state.company}",
                header = kotlinx.html.emptyMap,
                body = Json.encodeToString(
                    ListSerializer(TtsStationDTO.serializer()),
                    stations
                )
            )
        }.invokeOnCompletion {
            state.company?.let {
                fetchStations(it)
            }
        }
    }

    fun readCSV(e: Event) {
        val fr: FileReader = e.target as FileReader
        val result: String = fr.result as String
        val stationData = result
            .trim()
            .split("\n", "\r\n")
            .toMutableList()
        val stationList = stationData.map {
            val station = it.split(";")
            TtsStationDTO(
                name = station[0],
                ibisName = station[1],
                volume = station[2].replace(" ", "").toInt()
            )
        }
        setStations(stationList)
    }

    override fun componentDidMount() {
        localStorage["TextToSpeechCompany"]?.let {
            setState {
                this.company = it
            }
            fetchStations(it)
        }
    }

    override fun RBuilder.render() {
        route<RProps>("/tts/new/stations") { _ ->
            spacer()
            mGridContainer2(direction = MGridDirection.row) {
                mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                    mCard {
                        css(GlobalStyles.card)
                        mCardContent {
                            stationsUpload()
                        }
                    }
                }
                mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                    mCard {
                        css(GlobalStyles.card)
                        mCardContent {
                            stationListTable()
                        }
                    }
                }
            }
        }
    }

    private fun RBuilder.stationListTable() =
        mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
            styledDiv {
                css {
                    display = Display.flex
                    justifyContent = JustifyContent.spaceBetween
                    alignItems = Align.center
                }
                mListSubheader(heading = "Stationsliste")
                        mButton(
                            caption = "Löschen",
                            size = MButtonSize.small,
                            color = MColor.inherit,
                            variant = MButtonVariant.outlined,
                            onClick = {
                                state.company?.let {
                                    deleteStations(it)
                                }
                            }
                        ) {
                            attrs.disableElevation = true
                            css { margin(0.spacingUnits, 1.spacingUnits); color = Color.red }
                        }
            }
            mDivider { }
            mTableContainer {
                css { paddingRight = 1.spacingUnits }
                mTable {
                    mTableHead {
                        mTableRowSlim {
                            mTableCell(align = MTableCellAlign.left) {
                                css { padding(2.spacingUnits) }
                                +"Haltestelle (Normal)"
                            }
                            mTableCell(align = MTableCellAlign.left) {
                                css { padding(2.spacingUnits) }
                                +"Haltestelle (IBIS)"
                            }
                            mTableCell(align = MTableCellAlign.left) {
                                css { padding(2.spacingUnits) }
                                +"Lautstärke"
                            }
                        }
                    }
                    mTableBody {
                        state.stations.forEachIndexed { idx, station ->
                            mTableRowSlim {
                                mTableCell(align = MTableCellAlign.left) {
                                    css { padding(0.spacingUnits, 2.spacingUnits) }
                                    +station.name
                                }
                                station.ibisName.let {
                                    mTableCell(align = MTableCellAlign.left) {
                                        css { padding(0.spacingUnits, 2.spacingUnits) }
                                        +it
                                    }
                                }
                                mTableCell(align = MTableCellAlign.left) {
                                    css { padding(0.spacingUnits, 2.spacingUnits) }
                                    mSlider(
                                        value = station.volume,
                                        min = 0,
                                        max = 6,
                                        step = 1,
                                        onChange = { value ->
                                            if(value != station.volume) {
                                                setState({
                                                    assign(it) {
                                                        stations[idx] = station.copy(volume = value)
                                                    }
                                                }) {
                                                    setStations(state.stations)
                                                }
                                            }
                                        }
                                    )
                                }
                            }
                        }
                    }
                }
            }
        }

    private fun RBuilder.stationsUpload() =
        mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
            styledDiv {
                css { padding(2.spacingUnits, 2.spacingUnits, 0.px, 2.spacingUnits) }
                css {
                    padding(0.spacingUnits)
                    justifyItems = JustifyItems.center
                    display = Display.flex
                    alignItems = Align.flexStart
                }
                styledDiv {
                    mTypography(
                        text = "Stationsliste Upload:",
                        variant = MTypographyVariant.h6
                    ) {
                        css {
                            minWidth = 200.px
                            display = Display.block
                            marginTop = 2.spacingUnits
                            paddingRight = 1.spacingUnits
                        }
                    }
                }
                styledInput {
                    css {
                        padding(2.spacingUnits)
                        width = 100.pct
                        border = "2px dashed #eeeeee"
                        borderRadius = 3.px
                    }
                    attrs.name = "upload"
                    attrs.type = InputType.file
                    attrs.accept = ".csv,.txt"
                    attrs.multiple = false
                    attrs.onChangeFunction = { event ->
                        var fileEvent = event.target as HTMLInputElement
                        var file = fileEvent.files?.get(0)
                        file?.let {
                            fileReader.readAsText(it)
                        }
                    }
                    attrs.onClickFunction = {
                        kotlinext.js.js { it.target.asDynamic().value = null }
                    }
                }
//                    mTypography(
//                        text = "Die CSV-Datei sollte folgende Daten enthalten: Haltestelle (Normal), Haltestelle (IBIS), Latitude und Longitude. Die Dezimaltrennung der Koordinaten muss mit einem Punkt erfolgen. Die CSV-Datei muss das UTF-8 Format haben und Komma separiert sein.",
//                        variant = MTypographyVariant.caption,
//                        color = MTypographyColor.textSecondary
//                    ) { css { display = Display.block; marginTop = 2.spacingUnits } }
            }
        }


}
