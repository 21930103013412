package de.geomobile.frontend.device.detail

import com.ccfraser.muirwik.components.MTypographyVariant
import com.ccfraser.muirwik.components.mTypography
import com.ccfraser.muirwik.components.spacingUnits
import de.geomobile.frontend.utils.mIconNoTranslate
import kotlinx.css.*
import kotlinx.css.properties.boxShadow
import kotlinx.css.properties.deg
import kotlinx.css.properties.rotate
import kotlinx.css.properties.transform
import react.RBuilder
import styled.css
import styled.styledDiv

fun RBuilder.trialRibbon() = styledDiv {
    css {
        width = 80.px
        height = 80.px
        overflow = Overflow.hidden
        position = Position.absolute
        top = (-10).px
        right = (-10).px
        pointerEvents = PointerEvents.none
        zIndex = 999
    }

    mTypography(
        "TRIAL",
        variant = MTypographyVariant.caption
    ) {
        css {
            position = Position.absolute
            display = Display.block
            width = 100.px
            padding(vertical = 3.px)
            boxShadow(Color.black.withAlpha(0.1), offsetY = 5.px, blurRadius = 10.px)
            backgroundColor = Color("#bb1717")
            color = Color.white
            fontSize = 11.px
            fontWeight = FontWeight.w700
            textAlign = TextAlign.center
            left = 0.spacingUnits
            top = 20.px
            transform { rotate(45.deg) }
        }
    }
}

fun RBuilder.trialBadge() = mTypography(
    "TRIAL",
    variant = MTypographyVariant.caption
) {
    css {
        padding(vertical = 3.px, horizontal = 6.px)
        borderRadius = 3.px
        boxShadow(Color.black.withAlpha(0.1), offsetY = 5.px, blurRadius = 10.px)
        backgroundColor = Color("#bb1717")
        color = Color.white
        fontSize = 11.px
        fontWeight = FontWeight.w700
        textAlign = TextAlign.center
    }
}

fun RBuilder.notification() = mIconNoTranslate(
    "toll"
) {
    css {
        borderRadius = 3.px
        color = Color.red
        textAlign = TextAlign.center
    }
}

fun RBuilder.alert() = mIconNoTranslate(
    "warning"
) {
    css {
        borderRadius = 3.px
        color = Color.red
        textAlign = TextAlign.center
    }
}