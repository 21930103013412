package de.geomobile.frontend.features.repair

import com.ccfraser.muirwik.components.*
import de.geomobile.common.permission.Permission
import de.geomobile.common.permission.Permissions
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.spacer
import de.geomobile.frontend.utils.CComponent
import de.geomobile.frontend.utils.isAuthorized
import de.geomobile.frontend.utils.mToolbarTitle2
import kotlinx.css.height
import kotlinx.css.padding
import kotlinx.css.pct
import react.RBuilder
import react.RProps
import react.RState
import react.ReactElement
import react.router.dom.redirect
import react.router.dom.route
import react.router.dom.switch
import styled.css
import styled.styledDiv

fun RBuilder.repairDashboard(
    path: String,
    drawerMenu: ReactElement,
) = child(RepairDashboard::class) {
    attrs.path = path
    attrs.drawerMenu = drawerMenu
}

class RepairDashboard : CComponent<RepairDashboard.Props, RState>() {

    private val tabs = listOf(
        Tab(
            label = "Neu", route = "/new", viewPermission = Permissions.DeviceManagement.repairHistoryUser
        ) { repairView(openRepairsOnly = true, newRepairsOnly = true) },
        Tab(
            label = "Offene Aufträge",
            route = "/orders",
            viewPermission = Permissions.DeviceManagement.repairHistoryUser
        ) { repairView(true) },
        Tab(
            label = "Historie", route = "/history", viewPermission = Permissions.DeviceManagement.repairHistoryUser
        ) { repairView(false) },
    )

    private data class Tab(
        val label: String,
        val route: String,
        val viewPermission: Permission? = Permissions.StatisticsManagement.statView,
        val builder: RBuilder.(path: String) -> ReactElement,
    )

    interface Props : RProps {
        var path: String
        var drawerMenu: ReactElement
    }

    override fun RBuilder.render() {
        val permittedTabs = tabs.filter { isAuthorized(*listOfNotNull(it.viewPermission).toTypedArray()) }

        if (permittedTabs.isNotEmpty())
            route<RProps>(props.path) { routeProps ->
                mAppBar(position = MAppBarPosition.fixed) {
                    css(GlobalStyles.appbar)

                    val currentRoute = routeProps.history.location.pathname
                    val selectedTab = permittedTabs.firstOrNull {
                        currentRoute.startsWith(props.path + it.route)
                    } ?: permittedTabs.first()

                    mToolbar {
                        child(props.drawerMenu)
                        mToolbarTitle2("Reparaturen")
                    }
                    mTabs(
                        value = props.path + selectedTab.route,
                        centered = true,
                        onChange = { _, value -> routeProps.history.push(value as String) }
                    ) {
                        css {
                            height = 100.pct
                        }
                        for (tab in permittedTabs) {
                            mTab(tab.label, props.path + tab.route)
                        }
                    }
                }
            }
        spacer()
        styledDiv {
            css { padding(2.spacingUnits) }
            switch {
                if (permittedTabs.isNotEmpty()) {
                    for (tab in permittedTabs) {
                        this.route<RProps>(props.path + tab.route) { routeProps ->
                            tab.builder(this, routeProps.match.path)
                        }
                    }
                    redirect(from = props.path, to = props.path + permittedTabs.first().route, exact = true)
                } else
                    redirect(from = props.path, to = "", exact = true)
            }
        }
    }
}