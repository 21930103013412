package de.geomobile.frontend.features.device.detail.internal

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonSize
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.list.mList
import com.ccfraser.muirwik.components.list.mListItem
import com.ccfraser.muirwik.components.list.mListItemText
import com.ccfraser.muirwik.components.styles.Breakpoint
import com.ccfraser.muirwik.components.table.*
import de.geomobile.common.permission.Permissions
import de.geomobile.common.portalmodels.Product
import de.geomobile.common.softwaremgmt.*
import de.geomobile.common.time.LocalDateTime
import de.geomobile.frontend.features.softwareManagement.assignment.bundleSelect
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.portalWebSocketApi
import de.geomobile.frontend.utils.*
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.*
import kotlinx.css.properties.TextDecoration
import kotlinx.css.properties.TextDecorationLine
import kotlinx.serialization.builtins.nullable
import kotlinx.serialization.json.Json
import react.RBuilder
import react.RProps
import react.RState
import react.setState
import styled.css
import styled.styledDiv

fun RBuilder.softwareInternalStatus(
    id: Int,
    product: Product,
    softwareInternalExpanded: Boolean = false,
    onSoftwareInternalExpanded: () -> Unit,
) = child(SoftwareInternalStatusComponent::class) {
    key = id.toString()
    attrs.id = id
    attrs.product = product
    attrs.softwareInternalExpanded = softwareInternalExpanded
    attrs.onSoftwareInternalExpanded = onSoftwareInternalExpanded
}

class SoftwareInternalStatusComponent :
    CComponent<SoftwareInternalStatusComponent.Props, SoftwareInternalStatusComponent.State>() {

    private var saveJob: Job = Job()

    private val deviceSoftwareSocket by kotlin.lazy {
        portalWebSocketApi.subscribe("/device/software/internal", mapOf("id" to props.id.toString()))
    }

    interface Props : RProps {
        var id: Int
        var product: Product
        var softwareInternalExpanded: Boolean
        var onSoftwareInternalExpanded: () -> Unit
    }

    class State(
        var updateState: AssignmentUpdateState = AssignmentUpdateState(),
        var edit: Boolean = false,
        var newOverwriteBundle: SoftwareBundle? = null,
        var newOverwriteEnabled: Boolean? = null,
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        deviceSoftwareSocket.connect {
            onmessage = {
                val state = Json.decodeFromString(AssignmentUpdateState.serializer(), it)
                setState {
                    this.updateState = state
                }
            }
        }
    }

    override fun componentWillUnmount() {
        deviceSoftwareSocket.close()
    }

    override fun RBuilder.render() {
        val updateState = state.updateState
        val assignment = updateState.assignment
        val currentBundle = assignment?.currentAssignment?.bundle
        val overwriteBundle = state.newOverwriteBundle ?: assignment?.assignmentOverwrite?.bundle
        val overwriteEnabled = state.newOverwriteEnabled ?: (assignment?.assignmentOverwrite != null)
        val onlyAssign = overwriteBundle == null || !overwriteEnabled || state.newOverwriteBundle != null

        authorize(Permissions.AdminPermissions.internalAccess) {
            styledDiv {
                css {
                    padding(2.spacingUnits);
                    display = Display.flex;
                    alignItems = Align.baseline
                }
                mGridContainer2(alignItems = MGridAlignItems.baseline) {
                    when {
                        state.edit -> {
                            if (!onlyAssign)
                                mGridItem2(
                                    MGridBreakpoints2(MGridSize2.Auto)
                                        .down(Breakpoint.xs, MGridSize2.Cells12)
                                ) {
                                    mButton(
                                        caption = "Übernehmen",
                                        size = MButtonSize.medium,
                                        disabled = when {
                                            overwriteEnabled && overwriteBundle == null -> true
                                            !overwriteEnabled && state.newOverwriteEnabled == null -> true
                                            state.newOverwriteEnabled == null && state.newOverwriteBundle == null -> true
                                            else -> false
                                        },
                                        color = MColor.secondary,
                                        variant = MButtonVariant.contained,
                                        onClick = { saveOverwrite(assign = false, cancel = false) }) {
                                        attrs.fullWidth = true
                                        attrs.disableElevation = true
                                    }
                                }

                            mGridItem2(
                                MGridBreakpoints2(MGridSize2.Auto)
                                    .down(Breakpoint.xs, MGridSize2.Cells12)
                            ) {
                                mTooltip(
                                    when {
                                        onlyAssign -> "Übernehmen & Zuweisen"
                                        else -> "Übernehmen & Erneut Zuweisen"
                                    }
                                ) {
                                    mButton(
                                        when {
                                            onlyAssign -> "Bestätigen"
                                            else -> "Bestätigen"
                                        },
                                        size = MButtonSize.medium,
                                        disabled = when {
                                            overwriteEnabled && overwriteBundle == null -> true
                                            !overwriteEnabled && state.newOverwriteEnabled == null -> true
                                            else -> false
                                        },
                                        color = MColor.secondary,
                                        variant = MButtonVariant.contained,
                                        onClick = { saveOverwrite(assign = true, cancel = false) }) {
                                        attrs.fullWidth = true
                                        attrs.disableElevation = true
                                    }
                                }
                            }

                            if (assignment?.assignmentOverwrite?.canceled == false)
                                mGridItem2(
                                    MGridBreakpoints2(MGridSize2.Auto)
                                        .down(Breakpoint.xs, MGridSize2.Cells12)
                                ) {
                                    mButton(
                                        caption = "Überschreiben abbrechen",
                                        size = MButtonSize.medium,
                                        color = MColor.inherit,
                                        variant = MButtonVariant.contained,
                                        onClick = {
                                            saveOverwrite(false, true)
                                        }) {
                                        attrs.fullWidth = true
                                        attrs.disableElevation = true
                                    }
                                }

                            mGridItem2(
                                MGridBreakpoints2(MGridSize2.Auto)
                                    .down(Breakpoint.xs, MGridSize2.Cells12)
                            ) {
                                mButton(
                                    caption = "Zuweisung neu zuordnen",
                                    size = MButtonSize.medium,
                                    color = MColor.inherit,
                                    variant = MButtonVariant.contained,
                                    onClick = {
                                        reassign()
                                    }) {
                                    attrs.fullWidth = true
                                    attrs.disableElevation = true
                                }
                            }
                            mGridItem2(
                                MGridBreakpoints2(MGridSize2.Auto)
                                    .down(Breakpoint.xs, MGridSize2.Cells12)
                            ) {
                                mButton(
                                    caption = "Abbrechen",
                                    variant = MButtonVariant.contained,
                                    color = MColor.default,
                                    onClick = {
                                        setState {
                                            this.edit = false
                                            this.newOverwriteBundle = null
                                            this.newOverwriteEnabled = null
                                        }
                                    }) {
                                    attrs.fullWidth = true
                                    attrs.disableElevation = true
                                }
                            }
                        }

                        else -> mGridItem2(
                            MGridBreakpoints2(MGridSize2.Auto)
                                .down(Breakpoint.xs, MGridSize2.Cells12)
                        ) {
                            mButton(
                                caption = "Bearbeiten",
                                color = MColor.secondary,
                                variant = MButtonVariant.contained,
                                onClick = { setState { this.edit = true } }
                            ) {
                                attrs.fullWidth = true
                                attrs.disableElevation = true
                            }
                        }
                    }
                }
            }
            mDivider {}
            if (state.edit) {
                editOverwrite(assignment, overwriteEnabled, overwriteBundle)
                mDivider {}
            }

            styledDiv {
                css { padding(2.spacingUnits) }

                mList {
                    attrs.dense = true

                    if (assignment != null) {
                        val currentIndex = assignment.assignmentFilterBranch.indexOfLast {
                            it.assignment.bundle == assignment.currentAssignment.bundle && it.assignment.assignedAt == assignment.currentAssignment.assignedAt
                        }

                        assignment.assignmentFilterBranch.forEachIndexed { index, assignmentFilter ->
                            run {
                                mListItem {
                                    attrs.divider = true
                                    attrs.button = false

                                    mListItemText {
                                        mTypography(
                                            text = "Assignment Filter",
                                            variant = MTypographyVariant.caption,
                                        )
                                        mTypography {
                                            attrs.component = "div"
                                            attrs.variant = MTypographyVariant.subtitle2
                                            attrs.color = MTypographyColor.textPrimary

                                            if (index > currentIndex) {
                                                css.color = Color.grey
                                            }

                                            styledDiv {
                                                css {
                                                    display = Display.flex
                                                }

                                                styledDiv {
                                                    styledRouteLink(
                                                        if (props.product == Product.INTERACT) "/software/${Product.INTERACT.readableName}/assignments/${assignmentFilter.id}"
                                                        else "/software/${Product.CONNECT.readableName}/assignments/${assignmentFilter.id}"
                                                    ) {
                                                        css.color = Color.inherit
                                                        css.marginRight = 1.spacingUnits
                                                        +"${assignmentFilter.name}"
                                                    }
                                                }
                                                styledDiv {
                                                    css.marginRight = 1.spacingUnits
                                                    +"${assignmentFilter.assignment.bundle.company.name} ${assignmentFilter.assignment.bundle.internalVersion}"
                                                }
                                                styledDiv {
                                                    css.marginRight = 1.spacingUnits
                                                    +assignmentFilter.assignment.assignedAt.toText()
                                                }
                                                styledDiv {
                                                    css.marginRight = 1.spacingUnits

                                                    if (assignmentFilter.assignment.canceled)
                                                        styledDiv {
                                                            css.marginRight = 1.spacingUnits
                                                            css { color = Color.red; }
                                                            +"(canceled)"
                                                        }
                                                    if (index == currentIndex)
                                                        +"(current)"
                                                }
                                            }
                                        }
                                    }
                                }
                                val overwrite = assignment.assignmentOverwrite
                                if (overwrite != null) {
                                    mListItem {
                                        attrs.divider = true
                                        attrs.button = false

                                        mListItemText {
                                            mTypography(
                                                text = "Overwrite Assignment Filter",
                                                variant = MTypographyVariant.caption,
                                            )
                                            mTypography {
                                                attrs.component = "div"
                                                attrs.variant = MTypographyVariant.subtitle2
                                                attrs.color = MTypographyColor.textPrimary

                                                css {
                                                    if (currentIndex >= 0) {
                                                        color = Color.grey
                                                    }
                                                }

                                                styledDiv {
                                                    css {
                                                        display = Display.flex
                                                    }

                                                    styledDiv {
                                                        css.marginRight = 1.spacingUnits
                                                        +"${overwrite.bundle.company.name} ${overwrite.bundle.internalVersion}"
                                                    }

                                                    styledDiv {
                                                        css.marginRight = 1.spacingUnits
                                                        +overwrite.assignedAt.toText()
                                                    }

                                                    styledDiv {
                                                        css.marginRight = 1.spacingUnits

                                                        if (overwrite.canceled)
                                                            styledDiv {
                                                                css { color = Color.red; }
                                                                +"(canceled)"
                                                            }
                                                        if (currentIndex < 0)
                                                            +"(current)"
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    mListItem {
                        attrs.divider = true
                        attrs.button = false

                        mListItemText {
                            mTypography(
                                text = "Assigned Version",
                                variant = MTypographyVariant.caption,
                            )
                            mTypography(
                                text = currentBundle?.let { bundle ->
                                    "${bundle.company.name} ${bundle.internalVersion} (Ext.: ${bundle.externalVersion})"
                                } ?: "-",
                                variant = MTypographyVariant.subtitle2,
                                color = MTypographyColor.textPrimary
                            )
                        }
                    }
                    mListItem {
                        attrs.divider = true
                        attrs.button = false

                        mListItemText {
                            mTypography(
                                text = "Assigned at",
                                variant = MTypographyVariant.caption,
                            )
                            mTypography(
                                text = assignment?.currentAssignment?.assignedAt?.toText()?.let {
                                    it + if (assignment.reassignment != null) " (reassinged at ${assignment.reassignment!!.reassignedAt.toText()})" else ""
                                } ?: "-",
                                variant = MTypographyVariant.subtitle2,
                                color = MTypographyColor.textPrimary
                            )
                        }
                    }
                    mListItem {
                        attrs.divider = true
                        attrs.button = false

                        mListItemText {
                            mTypography(
                                text = "Assigned by",
                                variant = MTypographyVariant.caption,
                            )
                            mTypography(
                                text = assignment?.currentAssignment?.createdBy?.let {
                                    it + if (assignment.reassignment != null) " (reassinged by ${assignment.reassignment!!.reassignedBy})" else ""
                                } ?: "-",
                                variant = MTypographyVariant.subtitle2,
                                color = MTypographyColor.textPrimary
                            )
                        }
                    }
                    mListItem {
                        attrs.divider = true
                        attrs.button = false

                        mListItemText {
                            mTypography(
                                text = "Status",
                                variant = MTypographyVariant.caption,
                            )
                            mTypography(
                                text = updateState.state.readableName,
                                variant = MTypographyVariant.subtitle2,
                                color = MTypographyColor.textPrimary
                            )
                        }
                    }
                    mListItem {
                        attrs.divider = true
                        attrs.button = false

                        mListItemText {
                            mTypography(
                                text = "Fortschritt",
                                variant = MTypographyVariant.caption,
                            )
                            mTypography(
                                text = if(updateState.progress != -1) "${updateState.progress}%" else "-",
                                variant = MTypographyVariant.subtitle2,
                                color = MTypographyColor.textPrimary
                            )
                        }
                    }
                    mListItem {
                        attrs.divider = true
                        attrs.button = false

                        mListItemText {
                            mTypography(
                                text = "Last Poll",
                                variant = MTypographyVariant.caption,
                                component = "div"
                            )
                            timestampStatus(updateState.lastPollTimestamp)
                        }
                    }
                }

                if (currentBundle != null || updateState.softwareUpdateStates.any())
                    mTableContainer {
                        css { marginTop = 2.spacingUnits }
                        mTable {
                            mTableHead {
                                mTableRow {
                                    mTableCell(variant = MTableCellVariant.head) { +"Installiert" }
                                    mTableCell(variant = MTableCellVariant.head) { +"Status" }
                                    mTableCell(variant = MTableCellVariant.head) { +"Zugewiesen" }
                                }
                            }
                            mTableBody {

                                val softwareIds = currentBundle?.software?.map { it.software.id }.orEmpty()
                                    .toSet() + updateState.softwareUpdateStates.map { it.state.softwareId }

                                for (softwareId in softwareIds) {
                                    mTableRow {
                                        val assigned =
                                            currentBundle?.software?.firstOrNull { it.software.id == softwareId }
                                        val update =
                                            updateState.softwareUpdateStates.firstOrNull { it.state.softwareId == softwareId }
                                        val current = update?.state?.current
                                        val updating =
                                            assigned?.softwareVersion?.version != update?.state?.current?.version
                                        mTableCell {
                                            css { width = LinearDimension.inherit }
                                            mTypography(
                                                text = if (current != null) "${current.softwareId} ${current.version}" else "-",
                                                variant = MTypographyVariant.body2
                                            )
                                        }
                                        mTableCell {
                                            val text = when (update?.state) {
                                                is UpdateState.Installed -> if (updating) "Zugewiesen" else "Installiert"
                                                is UpdateState.Updating.WaitingInQueue -> "Warteschlange"
                                                is UpdateState.Updating.Downloading -> "Downloading"
                                                is UpdateState.Updating.Downloaded -> "Entpacken"
                                                is UpdateState.Updating.Unpacked -> "Initialisierung"
                                                is UpdateState.Updating.Initialized -> "Cleanup"
                                                is UpdateState.Updating.WaitingForRestart -> "Warten auf Neustart"
                                                is UpdateState.Canceled -> "Abgebrochen"
                                                is UpdateState.Failed -> "Fehlgeschlagen"
                                                null -> "Zugewiesen"
                                            }
                                            mTypography(
                                                text = text,
                                                variant = MTypographyVariant.body2
                                            )
                                        }
                                        mTableCell {
                                            if (updating) css {
                                                when {
                                                    current == null && assigned != null -> color =
                                                        Color.green

                                                    current != null && assigned == null -> {
                                                        color = Color.red
                                                        textDecoration =
                                                            TextDecoration(setOf(TextDecorationLine.lineThrough))
                                                    }

                                                    else -> color = Color.dodgerBlue
                                                }
                                            }
                                            when {
                                                assigned != null -> mTypography(
                                                    text = "${assigned.software.id} ${assigned.softwareVersion.version}",
                                                    variant = MTypographyVariant.body2
                                                )

                                                current != null -> mTypography(
                                                    text = "${current.softwareId} ${current.version}",
                                                    variant = MTypographyVariant.body2
                                                )
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
            }
        }
    }

    private fun RBuilder.editOverwrite(
        assignment: DeviceSoftwareAssignment?,
        overwriteEnabled: Boolean,
        overwriteBundle: SoftwareBundle?,
    ) {
        styledDiv {
            css { padding(1.spacingUnits, 3.spacingUnits); display = Display.flex }

            mSwitchWithLabel(
                label = "Überschreiben",
                checked = overwriteEnabled,
                onChange = { _, checked ->
                    setState {
                        newOverwriteEnabled = checked.takeIf { it != (assignment?.assignmentOverwrite != null) }
                    }
                }
            )
            if (assignment?.assignmentOverwrite != null) {
                styledDiv {
                    css { display = Display.flex; alignItems = Align.center }

                    mTooltip(
                        title = "Zugewiesen: ${assignment.assignmentOverwrite!!.assignedAt.toText()}"
                    ) {
                        css { marginLeft = 3.px }
                        mIconNoTranslate(
                            iconName = "help_outline",
                            primary = false,
                            MIconColor.disabled,
                            MIconFontSize.small
                        )
                    }
                }
            }
        }
        mDivider {}
        bundleSelect(
            bundle = overwriteBundle,
            enabled = overwriteEnabled,
            onBundleChanged = {
                setState {
                    newOverwriteBundle = it?.takeIf { it != assignment?.assignmentOverwrite?.bundle }
                }
            },
            // Core is being handled as Connect in bundle product field
            product = if (props.product == Product.CORE) Product.CONNECT else props.product
        )
    }

    private fun reassign() {
        saveJob.cancel()
        saveJob = launch(Dispatchers.Default) {
            portalRestApi.put(path = "/software/assignment/reassign/${props.id}")
        }
    }

    private fun saveOverwrite(assign: Boolean, cancel: Boolean) {
        val bundle =
            (state.newOverwriteBundle ?: state.updateState.assignment?.assignmentOverwrite?.bundle)?.takeIf {
                state.newOverwriteEnabled ?: (state.updateState.assignment?.assignmentOverwrite != null)
            }

        val assignment = bundle?.let {
            SoftwareAssignment(
                bundle = it,
                pollingIntervalInSec = null,
                canceled = cancel,
                createdAt = LocalDateTime.now(),
                createdBy = state.updateState.assignment?.assignmentOverwrite?.createdBy ?: "",
                assignedAt = state.updateState.assignment?.assignmentOverwrite?.assignedAt ?: LocalDateTime.now(),
                assignedBy = state.updateState.assignment?.assignmentOverwrite?.assignedBy ?: ""
            )
        }

        saveJob.cancel()
        saveJob = launch {
            withContext(Dispatchers.Default) {
                portalRestApi.put(
                    path = "/software/assignment/overwrite/${props.id}/${if (assign) "assign" else "save"}",
                    body = Json.encodeToString(SoftwareAssignment.serializer().nullable, assignment)
                )
            }

            setState {
                newOverwriteEnabled = null
                newOverwriteBundle = null
                edit = false
            }
        }
    }
}