package de.geomobile.frontend.features.upload

import react.*
import react.router.dom.route

fun RBuilder.upload(
    path: String,
    basePath: String,
    drawerMenu: ReactElement,
) = child(UploadComponent::class) {
    attrs.path = path
    attrs.basePath = basePath
    attrs.drawerMenu = drawerMenu
}

class UploadComponent : RComponent<UploadComponent.Props, UploadComponent.State>() {

    interface Props : RProps {
        var path: String
        var basePath: String
        var drawerMenu: ReactElement
    }

    class State : RState {
        var previousPath: String = ""
    }

    init {
        state = State()
    }

    override fun componentWillReceiveProps(nextProps: Props) {
        if (nextProps.path != props.path)
            setState { previousPath = props.path }
    }

    override fun RBuilder.render() {
        route<RProps>(props.basePath) { routeProps ->
            uploadEmbed(
                headless = !routeProps.match.isExact,
                drawerMenu = props.drawerMenu
            )
        }
    }
}