package de.geomobile.frontend.features.admin.sharedDevices

import com.ccfraser.muirwik.components.MGridSpacing
import com.ccfraser.muirwik.components.card.mCardHeader
import com.ccfraser.muirwik.components.list.mList
import com.ccfraser.muirwik.components.list.mListItem
import com.ccfraser.muirwik.components.mPaper
import com.ccfraser.muirwik.components.spacingUnits
import com.ccfraser.muirwik.components.styles.Breakpoint
import de.geomobile.common.portalmodels.Company
import de.geomobile.common.portalmodels.CompanySmall
import de.geomobile.common.portalmodels.DeviceIdentifier
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.utils.*
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.*
import react.RBuilder
import react.RProps
import react.RState
import react.setState
import styled.StyledElementBuilder
import styled.css
import styled.styledDiv

fun RBuilder.sharedDevices(
    onDeviceClick: (id: DeviceIdentifier) -> Unit,
    company: CompanySmall?,
) = child(SharedDevices::class) {
    attrs.onDeviceClick = onDeviceClick
    attrs.company = company
}

class SharedDevices : CComponent<SharedDevices.Props, SharedDevices.State>() {

    interface Props : RProps {
        var onDeviceClick: (id: DeviceIdentifier) -> Unit
        var company: CompanySmall?
    }

    class State(
        var selectList: Set<Pair<String, CompanySmall?>> = emptySet(),
        var selectedGroup: Pair<String, CompanySmall?> = Pair("Eigene Geräte", null),

        ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        launch {
            val company = if (props.company != null)
                withContext(Dispatchers.Default) {
                    portalRestApi.get("/admin/companies/${props.company!!.id}", Company.serializer())
                }
            else
                null

            val standardEntries: List<Pair<String, CompanySmall?>> =
                listOf(Pair("Eigene Geräte", null), Pair("Mir geteilte Geräte", null))
            setState {
                this.selectList =
                    standardEntries.union(company?.shareDevicesWith?.map { Pair(it.name, it) }?.toList() ?: emptyList())
            }
        }
    }

    override fun componentWillReceiveProps(nextProps: SharedDevices.Props) {
        if (nextProps.company != props.company)
            componentDidMount()
    }

    override fun RBuilder.render() {

        mGridContainer2(
            justify = MGridJustify.spaceAround,
            spacing = MGridSpacing.spacing0
        )
        {
            css {
                height = 100.pct
                overflow = Overflow.hidden
            }
            sideBar()
            detail()
        }
    }

    private fun StyledElementBuilder<MGridProps2>.detail() {
        mGridItem2(
            MGridBreakpoints2(MGridSize2.Cells9)
                .down(Breakpoint.sm, MGridSize2.Cells11)
        ) {
            css {
                height = 100.pct
                display = Display.flex
                flexDirection = FlexDirection.column
                padding(2.spacingUnits)
            }

            sharedDevicesDetail(
                selectedGroup = state.selectedGroup,
                onDeviceClick = props.onDeviceClick,
                company = props.company
            )


        }
    }

    private fun StyledElementBuilder<MGridProps2>.sideBar() {
        mGridItem2(
            MGridBreakpoints2(MGridSize2.Cells3)
                .down(Breakpoint.sm, MGridSize2.Cells11)
        ) {
            css {
                height = 100.pct
                padding(1.spacingUnits)
            }
            mPaper {
                css {
                    height = 100.pct
                    display = Display.flex
                    flexDirection = FlexDirection.column
                }
                mCardHeader(
                    title = "Filter",
                    action = RBuilder().styledDiv {
                        css {
                            marginRight = 1.spacingUnits
                            marginTop = 1.spacingUnits
                        }
                    }
                )
                mList {
                    css {
                        flex(1.0, 1.0, FlexBasis.auto)
                        overflowY = Overflow.auto
                    }
                    for (group in state.selectList) {
                        mListItem(
                            group.first,
                            divider = group.first == "Mir geteilte Geräte",
                            selected = group == state.selectedGroup,
                            onClick = {
                                setState {
                                    this.selectedGroup = group
                                }
                            }
                        )
                    }
                }
            }
        }

    }
}