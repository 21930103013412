package de.geomobile.frontend.features.device.detail

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardActionArea
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.styles.Breakpoint
import com.ccfraser.muirwik.components.transitions.mCollapse
import de.geomobile.common.feature.Features
import de.geomobile.frontend.api.TopicSession
import de.geomobile.frontend.device.detail.trialRibbon
import de.geomobile.frontend.portalWebSocketApi
import de.geomobile.frontend.utils.*
import de.geomobile.frontend.utils.MGridJustify
import kotlinx.css.*
import kotlinx.serialization.json.Json
import portalmodels.DeviceFmsDTO
import react.*
import styled.css
import styled.styledDiv

fun RBuilder.fmsStatus(
    id: Int,
) = child(DeviceFmsComponent::class) {
    key = id.toString()
    attrs.id = id
}

class DeviceFmsComponent : RComponent<DeviceFmsComponent.Props, DeviceFmsComponent.State>() {

    private var session: TopicSession? = null

    interface Props : RProps {
        var id: Int
        var fmsExpanded: Boolean
        var onFmsExpanded: () -> Unit
    }

    class State(
        var item: DeviceFmsDTO? = null,
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        session?.close()
        session = portalWebSocketApi.subscribe("/device/fms", mapOf("id" to props.id.toString()))
        session?.connect {
            onmessage = {

                val item = Json.decodeFromString(DeviceFmsDTO.serializer(), it)

                setState {
                    this.item = item
                }
            }
        }
    }

    override fun componentWillUnmount() {
        session?.close()
    }

    override fun RBuilder.render() {
        val item = state.item?.data ?: emptyList()

        styledDiv {
            css { padding(2.spacingUnits) }

            mGridContainer2(
                alignItems = MGridAlignItems.baseline,
                justify = MGridJustify.flexEnd
            ) {

                if (item.isEmpty()) {
                    mGridItem(MGridBreakpoints(MGridSize.cells12)) {
                        mTypography(
                            text = "Keine Daten verfügbar",
                            variant = MTypographyVariant.body1,
                            align = MTypographyAlign.center
                        )
                    }
                }

                for ((index, row) in item.withIndex()) {
                    if (index != 0)
                        mGridItem(MGridBreakpoints(MGridSize.cells12)) {
                            mDivider {
                                css {
                                    width = 100.pct
                                    margin(vertical = 4.px)
                                }
                            }
                        }
                    mGridItem(
                        MGridBreakpoints(MGridSize.cells1)
                            .down(Breakpoint.xs, MGridSize.cells12)
                    ) {
                        mTypography(text = row.pgnId, variant = MTypographyVariant.caption)
                    }

                    mGridItem(
                        MGridBreakpoints(MGridSize.cells3)
                            .down(Breakpoint.xs, MGridSize.cells12)
                    ) {
                        mTypography(text = row.title, variant = MTypographyVariant.caption) {
                            css {
                                wordBreak = WordBreak.breakWord
                            }
                        }
                    }

                    mGridItem(
                        MGridBreakpoints(MGridSize.cells8)
                            .down(Breakpoint.xs, MGridSize.cells12)
                    ) {
                        if (row.msg.isEmpty()) {
                            mTypography(
                                text = "Noch keine Daten empfangen",
                                variant = MTypographyVariant.body2
                            ) {
                                css {
                                    wordBreak = WordBreak.breakWord
                                }
                            }
                        } else {
                            for (entry in row.msg) {
                                mTypography(
                                    text = if (entry.entryName == "") entry.entryValue else "${entry.entryName}: ${entry.entryValue}",
                                    variant = MTypographyVariant.body2
                                ) {
                                    css {
                                        wordBreak = WordBreak.breakWord
                                    }
                                }
                            }
                        }
                    }

                    mGridItem(
                        MGridBreakpoints(MGridSize.cells8)
                            .down(Breakpoint.xs, MGridSize.cells12)
                    ) {
                        mTypography(
                            text = row.timestamp?.toText(),
                            variant = MTypographyVariant.caption
                        ) {
                            css {
                                color = Color.gray
                            }
                        }
                    }
                }
            }
        }
    }
}
