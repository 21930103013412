package de.geomobile.frontend.features.device.list

import de.geomobile.common.portalmodels.*
import de.geomobile.common.softwaremgmt.AssignmentUpdateState
import de.geomobile.common.stage.Stage
import de.geomobile.common.time.LocalDate
import utils.millisToLocalDate

data class DeviceListItem(
    val id: Int,
    val product: Product,
    val productVariant: String? = null,
    val serialNumber: Int? = null,
    val cpuId: String? = null,
    val vehicleId: String? = null,
    val vehicleType: VehicleType,
    val hardwareId: String,
    val vehicleProfile: DeviceListItemDelta.VehicleProfile?,
    val company: DeviceListItemDelta.Company,
    val stage: Stage,
    val inceptionDate: LocalDate,
    val installDate: LocalDate,
    val shippingDate: LocalDate?,
    val status: TimestampStatus,
    val betaStatus: TimestampStatus,
    val tagNames: List<String> = emptyList(),
    val volume: Int? = null,
    val description: String? = null,
    val updateProgress: Int,
    val updateState: AssignmentUpdateState.State,
    val externalUpdateState: AssignmentUpdateState.State,
    val line: String? = null,
    val destination: String? = null,
    val iccid: String? = null,
    val sharingWith: List<DeviceListItemDelta.Company> = emptyList(),
) {
    fun identifierById(): DeviceIdentifier.Id = DeviceIdentifier.Id(id = id)

    fun identifierBySerialNumber(): DeviceIdentifier.SerialNumber? = serialNumber?.let {
        DeviceIdentifier.SerialNumber(
            product = product,
            serialNumber = serialNumber
        )
    }

    fun identifierByCpuId(): DeviceIdentifier.CpuId? = cpuId?.let { cpuId ->
        DeviceIdentifier.CpuId(
            product = product,
            cpuId = cpuId
        )
    }

    fun primaryIdentifier(): DeviceIdentifier =
        identifierBySerialNumber()
            ?: identifierByCpuId()
            ?: identifierById()
}

fun DeviceListItemDTO.toListItem(
    companies: List<DeviceListItemDelta.Company>,
    vehicleProfiles: List<DeviceListItemDelta.VehicleProfile>,
): DeviceListItem = DeviceListItem(
    id = id,
    product = Product.values()[productOrdinal],
    productVariant = productVariant,
    serialNumber = serialNumber,
    cpuId = cpuId?.padStart(8, '0'),
    vehicleId = vehicleId.takeUnless { it.isNullOrBlank() },
    vehicleType = VehicleType.values()[vehicleTypeOrdinal ?: 0],
    hardwareId = hardwareId,
    vehicleProfile = vehicleProfiles.getOrNull(vehicleProfileIndex),
    description = description,
    company = companies[companyIndex],
    stage = Stage.values()[stageOrdinal],
    inceptionDate = inceptionDate.millisToLocalDate(),
    installDate = installDate.millisToLocalDate(),
    shippingDate = shippingDate?.millisToLocalDate(),
    updateProgress = updateStateProgress ?: -1,
    updateState = AssignmentUpdateState.State.values()[updateStateOrdinal ?: 0],
    externalUpdateState = AssignmentUpdateState.State.values()[externalUpdateStateOrdinal ?: 0],
    status = status.old,
    betaStatus = betaStatus.old,
    line = line,
    destination = destination,
    iccid = iccid,
    sharingWith = sharingWithIndex.map { companies[it] }
)
