package de.geomobile.frontend.features.device.detail.internal

import com.ccfraser.muirwik.components.MTypographyColor
import com.ccfraser.muirwik.components.MTypographyVariant
import com.ccfraser.muirwik.components.list.mList
import com.ccfraser.muirwik.components.list.mListItem
import com.ccfraser.muirwik.components.list.mListItemText
import com.ccfraser.muirwik.components.mTypography
import com.ccfraser.muirwik.components.spacingUnits
import de.geomobile.common.permission.Permissions
import de.geomobile.common.portalmodels.Company
import de.geomobile.common.portalmodels.CompanySmall
import de.geomobile.common.portalmodels.DeviceDTO
import de.geomobile.common.portalmodels.small
import de.geomobile.common.stage.Stage
import de.geomobile.frontend.api.TopicSession
import de.geomobile.frontend.features.device.detail.tableRowChooseValue
import de.geomobile.frontend.features.device.detail.tableRowMultilineEditValue
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.portalWebSocketApi
import de.geomobile.frontend.utils.*
import kotlinx.css.padding
import kotlinx.css.px
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.json.Json
import react.*
import styled.css
import styled.styledDiv

fun RBuilder.deviceInternalStatus(
    id: Int,
    onDeviceDetailPage: Boolean,
) = child(DeviceInternalStatusComponent::class) {
    key = id.toString()
    attrs.id = id
    attrs.onDeviceDetailPage = onDeviceDetailPage
}

class DeviceInternalStatusComponent :
    RComponent<DeviceInternalStatusComponent.Props, DeviceInternalStatusComponent.State>() {

    private var session: TopicSession? = null

    interface Props : RProps {
        var id: Int
        var onDeviceDetailPage: Boolean
    }

    class State(
        var device: DeviceDTO? = null,
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        session?.close()
        session = portalWebSocketApi.subscribe("/device", mapOf("id" to props.id.toString()))
        session?.connect {
            onmessage = {
                val device = Json.decodeFromString(DeviceDTO.serializer(), it)
                setState {
                    this.device = device
                }
            }
        }
    }

    override fun componentWillUnmount() {
        session?.close()
    }

    override fun RBuilder.render() {
        styledDiv {
            if (state.device == null) styledDiv {
                css { padding(2.spacingUnits) }
                mSkeleton(
                    height = 100.px,
                    animation = MSkeletonAnimation.wave,
                    variant = MSkeletonVariant.rect
                )
            } else {
                val device = state.device ?: return

                styledDiv {
                    if (props.onDeviceDetailPage) {
                        css { padding(2.spacingUnits) }
                    }
                    mList {
                        attrs.dense = true

                        mListItem {
                            attrs.button = false
                            attrs.divider = true

                            mListItemText {
                                mTypography(
                                    text = "Produkt",
                                    variant = MTypographyVariant.caption,
                                )
                                mTypography(
                                    text = device.product.readableName,
                                    variant = MTypographyVariant.subtitle2,
                                    color = MTypographyColor.textPrimary
                                )
                            }
                        }

                        mListItem {
                            attrs.button = false
                            attrs.divider = true

                            mListItemText {
                                mTypography(
                                    text = "CPU ID",
                                    variant = MTypographyVariant.caption,
                                )
                                mTypography(
                                    text = device.cpuId ?: "-",
                                    variant = MTypographyVariant.subtitle2,
                                    color = MTypographyColor.textPrimary
                                )
                            }
                        }

                        mListItem {
                            attrs.button = false
                            attrs.divider = true

                            mListItemText {
                                mTypography(
                                    text = "Hardware ID",
                                    variant = MTypographyVariant.caption,
                                )
                                mTypography(
                                    text = device.hardwareId?.toString() ?: "-",
                                    variant = MTypographyVariant.subtitle2,
                                    color = MTypographyColor.textPrimary
                                )
                            }
                        }

                        mListItem {
                            attrs.button = false
                            attrs.divider = true

                            mListItemText {
                                mTypography(
                                    text = "Materialnummer",
                                    variant = MTypographyVariant.caption,
                                )
                                mTypography(
                                    text = device.materialNumber.toString().padStart(6, '0'),
                                    variant = MTypographyVariant.subtitle2,
                                    color = MTypographyColor.textPrimary
                                )
                            }
                        }

                        tableRowMultilineEditValue(
                            label = "Revision",
                            loadValue = { device.revision?.takeIf { it.isNotBlank() } },
                            loadApplyPath = { "/device/${device.id}/revision" },
                            allowEdit = isAuthorized(Permissions.AdminPermissions.internalAccess)
                        )

                        mListItem {
                            attrs.button = false
                            attrs.divider = true

                            mListItemText {
                                mTypography(
                                    text = "Erstellt",
                                    variant = MTypographyVariant.caption,
                                )
                                mTypography(
                                    text = device.createdAt.toText(from = TimeUnit.MINUTE),
                                    variant = MTypographyVariant.subtitle2,
                                    color = MTypographyColor.textPrimary
                                )
                            }
                        }

                        tableRowChooseValue(
                            label = "Unternehmen",
                            loadValues = { getAllCompanies() },
                            loadSelectedValue = { device.company },
                            selectionTitle = { (it as CompanySmall).name },
                            allowEdit = isAuthorized(Permissions.AdminPermissions.internalAccess),
                            loadApplyPath = { "/device/${device.id}/company" },
                            loadBody = { newValue -> (newValue as CompanySmall).id.toString() }
                        )

                        tableRowChooseValue(
                            label = "Stage",
                            loadValues = { Stage.values().toList() },
                            loadSelectedValue = { state.device?.stage },
                            selectionTitle = { (it as Stage).readableName },
                            allowEdit = isAuthorized(Permissions.AdminPermissions.internalAccess),
                            loadApplyPath = { "/device/${device.id}/stage" },
                            loadBody = { newValue -> (newValue as Stage).name }
                        )

                        tableRowMultilineEditValue(
                            label = "Beschreibung",
                            loadValue = { device.internalDescription?.takeIf { it.isNotBlank() } },
                            loadApplyPath = { "/device/${device.id}/internalDescription" },
                            allowEdit = isAuthorized(Permissions.AdminPermissions.internalAccess)
                        )
                    }
                }
            }
        }
    }

    private suspend fun getAllCompanies(): List<CompanySmall> =
        portalRestApi.get("/admin/companies", serializer = ListSerializer(Company.serializer()))
            .map { it.small }
}