package de.geomobile.common.time

import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.Serializer
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

@Serializable(with = LocalDateTimeSerializer::class)
expect class LocalDateTime(millis: Long) : Comparable<LocalDateTime> {

    companion object {
        fun now(): LocalDateTime
        fun parse(encoded: String): LocalDateTime
    }

    val millis: Long

    fun format(): String

    fun minusSeconds(amount: Int): LocalDateTime
    fun minusMinutes(amount: Int): LocalDateTime
    fun minusHours(amount: Int): LocalDateTime
    fun minusDays(amount: Int): LocalDateTime
    fun minusMonths(amount: Int): LocalDateTime
    fun minusYears(amount: Int): LocalDateTime

    fun plusSeconds(amount: Int): LocalDateTime
    fun plusMinutes(amount: Int): LocalDateTime
    fun plusHours(amount: Int): LocalDateTime
    fun plusDays(amount: Int): LocalDateTime
    fun plusMonths(amount: Int): LocalDateTime
    fun plusYears(amount: Int): LocalDateTime
    fun dayAsName(): String
    fun monthAsName(): String
    fun year(): String
    fun dayOfWeek(): Int
    fun dayOfMonth(): Int
    fun month(): Int
    //TODO: Fix atStartOfDay implementation for both jvm and js
    fun atStartOfDay(): LocalDateTime
    fun hour(): Int
}

@Serializer(forClass = LocalDateTime::class)
object LocalDateTimeSerializer : KSerializer<LocalDateTime> {

    override val descriptor: SerialDescriptor =
        PrimitiveSerialDescriptor("WithCustomDefault", PrimitiveKind.STRING)

    override fun serialize(encoder: Encoder, obj: LocalDateTime) {
        encoder.encodeString(obj.format())
    }

    override fun deserialize(decoder: Decoder): LocalDateTime {
        return LocalDateTime.parse(decoder.decodeString())
    }
}