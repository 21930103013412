package de.geomobile.frontend.features.vehicleProfile

import com.ccfraser.muirwik.components.mSelect
import com.ccfraser.muirwik.components.menu.mMenuItem
import com.ccfraser.muirwik.components.spacingUnits
import com.ccfraser.muirwik.components.table.MTableCellVariant
import com.ccfraser.muirwik.components.table.mTableCell
import com.ccfraser.muirwik.components.targetValue
import de.geomobile.common.portalmodels.*
import de.geomobile.frontend.features.device.detail.deviceStatusLed
import de.geomobile.frontend.utils.CComponent
import de.geomobile.frontend.utils.grid.Filter
import de.geomobile.frontend.utils.grid.TableFilterRowProps
import de.geomobile.frontend.utils.grid.tableFilterRowCell
import kotlinx.css.padding
import kotlinx.css.px
import react.RBuilder
import react.RState
import styled.css

class VehicleProfilesListFilterCell : CComponent<TableFilterRowProps.CellProps, VehicleProfilesListFilterCell.State>() {

    class State : RState

    init {
        state = State()
    }

    override fun RBuilder.render() {
        when (props.column.name) {

            "status" -> filterCellSelect(
                columnName = props.column.name,
                operation = "selectStatus",
                selection = props.filter?.value,
                values = listOf(
                    TimestampStatus.Status.ONLINE,
                    TimestampStatus.Status.OFFLINE
                ).map { it.ordinal.toString() },
                itemRenderer = { value ->
                    mMenuItem(
                        value = value,
                        button = true
                    ) {
                        deviceStatusLed(TimestampStatus.Status.values()[value.toInt()])
                    }
                }
            )

            "vehicleType" -> filterCellSelect(
                columnName = props.column.name,
                selection = props.filter?.value,
                values = VehicleType.values().map { it.readableName }
            )

            "floorType" -> filterCellSelect(
                columnName = props.column.name,
                selection = props.filter?.value,
                values = FloorType.values().map { it.readableName }
            )

            "ramp" -> filterCellSelect(
                columnName = props.column.name,
                selection = props.filter?.value,
                values = AvailableType.values().map { it.readableName }
            )

            "out1", "out2", "out3", "out4", "out5" -> filterCellSelect(
                columnName = props.column.name,
                selection = props.filter?.value,
                values = OutFunction.values().map { it.readableName }
            )

            "in1", "in2", "in3", "in4" -> filterCellSelect(
                columnName = props.column.name,
                selection = props.filter?.value,
                values = InFunction.values().map { it.readableName }
            )

            "speaker1", "speaker2" -> filterCellSelect(
                columnName = props.column.name,
                selection = props.filter?.value,
                values = WiredType.values().map { it.readableName }
            )

            else -> tableFilterRowCell(props)
        }
    }

    private fun RBuilder.filterCellSelect(
        columnName: String,
        operation: String? = null,
        selection: String?,
        values: List<String>,
        itemRenderer: RBuilder.(value: String) -> Unit = { value ->
            mMenuItem(
                primaryText = value,
                value = value
            )
        },
    ) {
        mTableCell(
            variant = MTableCellVariant.head
        ) {
            css { padding(horizontal = 1.spacingUnits) }
            mSelect(
                value = selection ?: "<<all>>",
                fullWidth = true,
                onChange = { event, _ ->
                    props.onFilter(
                        (event.targetValue as String)
                            .takeIf { it != "<<all>>" }
                            ?.let {
                                Filter(
                                    columnName = columnName,
                                    value = it,
                                    operation = operation
                                )
                            }
                    )
                }
            ) {
                mMenuItem(
                    primaryText = "Alle",
                    value = "<<all>>"
                )
                for (value in values) {
                    itemRenderer(value)
                }
            }
        }
    }
}