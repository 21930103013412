package de.geomobile.frontend.features.config

import com.ccfraser.muirwik.components.list.mListSubheader
import com.ccfraser.muirwik.components.spacingUnits
import com.ccfraser.muirwik.components.styles.Breakpoint
import de.geomobile.common.utils.indentedJson
import de.geomobile.common.utils.overlay
import de.geomobile.frontend.utils.*
import kotlinx.css.padding
import kotlinx.serialization.json.*
import react.RBuilder
import react.RComponent
import react.RProps
import react.RState
import styled.css
import styled.styledDiv

fun RBuilder.deviceConfigOverlay(
    overlayTitle: String = "Config Overlay",
    mergedTitle: String = "Merged Config Overlay",
    overlay: JsonObject,
    overlayEdit: String? = null,
    preceding: List<JsonObject>,
    onChange: ((valid: Boolean, changed: JsonObject?, raw: String) -> Unit)? = null,
) = child(DeviceConfigOverlay::class) {
    attrs.overlayTitle = overlayTitle
    attrs.mergedTitle = mergedTitle
    attrs.overlay = overlay
    attrs.overlayEdit = overlayEdit
    attrs.preceding = preceding
    attrs.onChange = onChange ?: { _, _, _ -> }
}

class DeviceConfigOverlay : RComponent<DeviceConfigOverlay.Props, RState>() {

    interface Props : RProps {
        var overlayTitle: String
        var mergedTitle: String
        var overlay: JsonObject
        var overlayEdit: String?
        var preceding: List<JsonObject>
        var onChange: (valid: Boolean, changed: JsonObject?, raw: String) -> Unit
    }

    override fun RBuilder.render() {
        mGridContainer2 {
            mGridItem2(
                MGridBreakpoints2(MGridSize2.Cells6)
                    .down(Breakpoint.sm, MGridSize2.Cells11)
            ) {
                mListSubheader(heading = props.overlayTitle)
                styledDiv {
                    css { padding(0.spacingUnits, 2.spacingUnits) }

                    jsonEditor(
                        json = props.overlayEdit ?: indentedJson.encodeToString(
                            JsonObject.serializer(),
                            props.overlay
                        ),
                        edit = props.overlayEdit != null,
                        onChange = {
                            val parsed = try {
                                Json.decodeFromString(JsonObject.serializer(), it)
                            } catch (e: Exception) {
                                null
                            }

                            props.onChange(
                                it.isValidJson(),
                                parsed?.takeIf { parsed != props.overlay },
                                it
                            )
                        }
                    )
                }
            }
            mGridItem2(
                MGridBreakpoints2(MGridSize2.Cells6)
                    .down(Breakpoint.sm, MGridSize2.Cells11)
            ) {
                mListSubheader(heading = props.mergedTitle)
                val merged = if (props.overlayEdit?.isValidJson() == false)
                    buildJsonObject(fun JsonObjectBuilder.() {
                        this.put("error", "invalid/malformed Json!")
                    })
                else
                    props.preceding
                        .plusElement(props.overlayEdit
                            ?.let { Json.decodeFromString(JsonObject.serializer(), it) }
                            ?: props.overlay
                        )
                        .overlay()

                styledDiv {
                    css { padding(0.spacingUnits, 2.spacingUnits) }
                    jsonEditor(
                        json = Json.encodeToString(JsonObject.serializer(), merged),
                        edit = false
                    )
                }
            }
        }
    }
}
