package de.geomobile.frontend.features.repair

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.*
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.dialog.mDialog
import com.ccfraser.muirwik.components.dialog.mDialogActions
import com.ccfraser.muirwik.components.dialog.mDialogContent
import com.ccfraser.muirwik.components.form.MFormControlMargin
import com.ccfraser.muirwik.components.form.MFormControlVariant
import com.ccfraser.muirwik.components.form.margin
import com.ccfraser.muirwik.components.input.MInputProps
import com.ccfraser.muirwik.components.list.mList
import com.ccfraser.muirwik.components.list.mListItem
import com.ccfraser.muirwik.components.list.mListItemSecondaryAction
import com.ccfraser.muirwik.components.list.mListItemText
import com.ccfraser.muirwik.components.menu.mMenuItem
import com.ccfraser.muirwik.components.styles.Breakpoint
import com.ccfraser.muirwik.components.table.*
import com.ccfraser.muirwik.components.transitions.mCollapse
import de.geomobile.common.permission.Permissions
import de.geomobile.common.portalmodels.UserDTO
import de.geomobile.common.time.LocalDateTime
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.utils.*
import kotlinext.js.assign
import kotlinext.js.jsObject
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.*
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.json.Json
import portalmodels.*
import react.*
import styled.css
import styled.styledDiv

fun RBuilder.repairJob(
    openRepairsOnly: Boolean = true,
    newRepairsOnly: Boolean = false,
    repair: RepairDTO,
    assemblies: List<ProductAssemblyDTO>,
    updateTrigger: () -> Unit,
) = child(RepairJob::class) {
    attrs.openRepairsOnly = openRepairsOnly
    attrs.newRepairsOnly = newRepairsOnly
    attrs.updateTrigger = updateTrigger
    attrs.assemblies = assemblies
    attrs.repair = repair
}

class RepairJob : CComponent<RepairJob.Props, RepairJob.State>() {

    private var fetchUsersJob: Job = Job()
    private var fetchAssembliesJob: Job = Job()

    interface Props : RProps {
        var updateTrigger: () -> Unit
        var assemblies: List<ProductAssemblyDTO>
        var repair: RepairDTO
        var openRepairsOnly: Boolean
        var newRepairsOnly: Boolean
    }

    class State(
        var currentUser: UserDTO? = null,
        var expandMap: MutableMap<Int, Boolean> = mutableMapOf(),
        var assemblyExpand: Boolean = true,
        var assemblyMap: MutableMap<ProductAssemblyDTO, Boolean> = mutableMapOf(),
        var changeMap: MutableMap<Int, RepairStepDTO> = mutableMapOf(),
        var comment: String? = null,
        var commentOK: Boolean = false,
        var newEntry: Pair<RepairStepType?, String?> = Pair(null, null),
        var deleteDTO: RepairDTO? = null,
        var deleteDialog: Boolean = false,
        var warnDialog: String = "",
        var userMap: Map<String, UserDTO> = mapOf()
    ) : RState

    init {
        state = State()
    }

    private fun fetchUsers() {
        try {
            fetchUsersJob.cancel()
            fetchUsersJob = launch {
                val users = portalRestApi.get("/admin/users", ListSerializer(UserDTO.serializer()))

                users.let { u ->
                    setState {
                        userMap = u.associateBy { it.id }
                    }
                }
            }
        } catch (ex: Exception) {

        }
    }

    private fun fetchAssemblyRelations() {
        try {
            fetchAssembliesJob.cancel()
            fetchAssembliesJob = launch {
                val repairAssemblyLists = portalRestApi.get(
                    "/portalsettings/assemblies/repair/${props.repair.id}",
                    ListSerializer(ProductAssemblyDTO.serializer())
                )
                setState {
                    assemblyMap = props.assemblies
                        .associateWith { assembly -> assembly in repairAssemblyLists }
                        .toMutableMap()
                }
            }
        } catch (ex: Exception) {

        }
    }

    fun newRepairStep(dto: RepairStepDTO) {
        launch {
            withContext(Dispatchers.Default) {
                val body = Json.encodeToString(RepairStepDTO.serializer(), dto)
                portalRestApi.put("/repair/insert/step", body, GenericResponseDTO.serializer())
            }

        }.invokeOnCompletion {
            props.updateTrigger()
        }
    }

    fun changeRepairStep(dto: RepairStepDTO) {
        launch {
            withContext(Dispatchers.Default) {
                val body = Json.encodeToString(RepairStepDTO.serializer(), dto)
                portalRestApi.put("/repair/change/step", body, GenericResponseDTO.serializer())
            }
        }.invokeOnCompletion {
            props.updateTrigger()
        }
    }

    fun changeRepair(dto: RepairDTO) {
        launch {
            withContext(Dispatchers.Default) {
                val body = Json.encodeToString(RepairDTO.serializer(), dto)
                val response = portalRestApi.put("/repair/change", body, GenericResponseDTO.serializer())
                setState {
                    commentOK = !response.isError
                }
            }
        }.invokeOnCompletion {
            props.updateTrigger()
        }
    }

    fun deleteRepairStep(dto: RepairStepDTO) {
        launch {
            withContext(Dispatchers.Default) {
                val body = Json.encodeToString(RepairStepDTO.serializer(), dto)
                portalRestApi.put("/repair/delete/step", body, GenericResponseDTO.serializer())
            }
        }.invokeOnCompletion {
            props.updateTrigger()
        }
    }

    fun deleteRepair(dto: RepairDTO) {
        launch {
            withContext(Dispatchers.Default) {
                val body = Json.encodeToString(RepairDTO.serializer(), dto)
                portalRestApi.put("/repair/delete", body, GenericResponseDTO.serializer())
            }
        }.invokeOnCompletion {
            props.updateTrigger()
        }
    }

    fun saveAssemblies(repairId: Int) {
        launch {
            portalRestApi.put(
                "/portalsettings/assemblies/repair/$repairId/save",
                Json.encodeToString(
                    ListSerializer(ProductAssemblyDTO.serializer()),
                    state.assemblyMap.filter { it.value }.keys.toList()
                ),
                GenericResponseDTO.serializer())
        }
    }

    fun disableNotification(dto: RepairDTO) {
        launch {
            withContext(Dispatchers.Default) {
                val body = Json.encodeToString(RepairDTO.serializer(), dto)
                portalRestApi.put("/repair/notification/disable", body, GenericResponseDTO.serializer())
            }
        }.invokeOnCompletion {
            props.updateTrigger()
        }
    }

    override fun componentDidMount() {
        if (isAuthorized(Permissions.AdminPermissions.internalAccess)) {
            fetchAssemblyRelations()
            fetchUsers()

        }

        if (isAuthorized(Permissions.DeviceManagement.repairHistoryUser)) {
            launch {
                val user = portalRestApi.get("/user", UserDTO.serializer())
                setState {
                    currentUser = user
                }
            }
        }
    }

    override fun RBuilder.render() {
        val repair = props.repair
        val repairDate = repair.repairSteps.firstOrNull()?.timestamp
        val repairDateText = repairDate?.toText(TimeUnit.DAY)

        dialogues()
        mList {
            attrs.disablePadding = true

            mListItem {
                attrs.key = repair.id.toString()
                attrs.divider = true
                attrs.button = true
                attrs.onClick = {
                    setState {
                        expandMap[repair.id] = state.expandMap[repair.id]?.let { !it } ?: true
                    }
                }
                css {
                    if( (props.openRepairsOnly || props.newRepairsOnly ) &&
                        repairDate != null &&
                        repairDate.millis < (LocalDateTime.now().millis - 86400000 * 14)) // 14 Days
                        backgroundColor = Color("lightpink")
                }

                mListItemText(
                    primary = "Seriennummer: ${repair.serialNumber}",
                    secondary =
                    if (repair.comment
                            ?.contains(
                                other = "abgebrochen",
                                ignoreCase = true
                            ) == true && !repair.active
                    )
                        "Reparaturvorgang abgebrochen."
                    else
                        "Erstellt: ${repairDateText ?: "k.A."}"
                        + ", Fahrzeugnummer: ${if (repair.vehicleId?.length != 0) repair.vehicleId else "k.A"}"
                        + ", Typ: ${repair.serviceType.readableName}"
                        + ", Status: ${if (repair.active) "Aktiv" else "Abgeschlossen"}"
                        + ", Schritt: ${repair.repairSteps.lastOrNull()?.step?.readableName ?: "k.A"}"
                ) {
                    attrs.primaryTypographyProps = jsObject {
                        variant = MTypographyVariant.subtitle2
                        color = MTypographyColor.secondary
                    }
                    attrs.secondaryTypographyProps = jsObject {
                        variant = MTypographyVariant.caption
                    }
                }
                mListItemSecondaryAction {
                    mIconButtonNoTranslate(
                        if (state.expandMap[repair.id] == true) "expand_less" else "expand_more",
                        onClick = {
                            setState {
                                expandMap[repair.id] = state.expandMap[repair.id]?.let { !it } ?: true
                            }
                        },
                        addAsChild = true
                    )
                    if (props.newRepairsOnly) {
                        if (isAuthorized(Permissions.AdminPermissions.internalAccess)) {
                            mTooltip("Als gesehen markieren.") {
                                mIconButtonNoTranslate(
                                    size = MButtonSize.small,
                                    addAsChild = true,
                                    iconName = "checkcircle",
                                    iconColor = MIconColor.inherit,
                                    onClick = {
                                        if (isAuthorized(Permissions.AdminPermissions.internalAccess))
                                            disableNotification(repair)
                                    }
                                ) {
                                    css {
                                        margin(0.spacingUnits, 1.spacingUnits)
                                        color = Color.white
                                        backgroundColor = Color.green
                                    }
                                }
                            }
                        } else {
                            mTooltip("Wurde noch nicht bearbeitet.") {
                                mIconButtonNoTranslate(
                                    iconName = "visibility",
                                    iconColor = MIconColor.secondary
                                )
                            }
                        }
                    }
                }
            }
            mCollapse(state.expandMap[repair.id] ?: false) {
                authorize(Permissions.AdminPermissions.internalAccess) {
                    mCardContent {
                        css {
                            padding(0.px)
                            paddingLeft = 1.spacingUnits
                            display = Display.flex
                            flexWrap = FlexWrap.wrap
                        }
                        mIconButtonNoTranslate(
                            if (state.assemblyExpand) "expand_less" else "chevron_right",
                            onClick = {
                                setState {
                                    assemblyExpand = state.assemblyExpand.let { !it } ?: true
                                }
                            },
                            addAsChild = true
                        ) { css { padding(0.px) } }
                        mTypography(
                            text = "Baugruppen",
                            variant = MTypographyVariant.body1,
                            color = MTypographyColor.textPrimary
                        ) { css { padding(2.px) } }
                        mDivider { css { width = 100.pct } }
                        mCollapse(state.assemblyExpand) {
                            css {
                                paddingLeft = 1.spacingUnits
                                maxWidth = 80.pct
                            }
                            for(assembly in props.assemblies.filter { !it.isCategory }) {
                                mCheckboxWithLabelPadding(
                                    label = assembly.name,
                                    checked = state.assemblyMap[assembly] ?: false,
                                    paddingCheckbox = "4px",
                                    onChange = { _, checked ->
                                        setState({
                                            assign(it) {
                                                assemblyMap[assembly] = checked
                                                newEntry = Pair(state.newEntry.first, assembly.name)
                                            }
                                        }) { saveAssemblies(repair.id) }
                                    }
                                )
                            }
                        }
                    }
                }
                mCardContent {
                    css { background = "#eeeeee" }
                    repairCard(repair)
                }
            }
        }
    }

    private fun RBuilder.repairCard(repair: RepairDTO) {
        val deviceId = repair.deviceId

        val returnable = (repair.repairSteps.any {
            it.step >= RepairStepType.SENT_BACK
        } && props.openRepairsOnly)

        authorize(Permissions.AdminPermissions.internalAccess) {
            mGridContainer2(direction = MGridDirection.row) {
                mGridItem2(MGridBreakpoints2(MGridSize2.Auto)) {
                    mSwitchWithLabel(
                        label = "Garantie",
                        checked = repair.serviceType == ServiceType.WARRANTY,
                        onChange = { _, checked ->
                            setState {
                                if (checked)
                                    changeRepair(repair.copy(serviceType = ServiceType.WARRANTY))
                                else
                                    changeRepair(repair.copy(serviceType = ServiceType.SERVICE))
                            }
                        }
                    ) { css { padding(0.spacingUnits, 1.spacingUnits) } }
                }
                mGridItem2(MGridBreakpoints2(MGridSize2.Auto)) {
                    mSwitchWithLabel(
                        label = "Abgeschlossen",
                        checked = !repair.active,
                        onChange = { _, checked ->
                            if (repair.repairSteps.none { it.step == RepairStepType.EXAMINATION_BY_MANUFACTURER })
                                setState {
                                    warnDialog = "Zum Abschließen muss der Schritt \"Prüfung durch Hersteller\" ausgefüllt sein."
                                }
                            else {
                                setState {
                                    changeRepair(repair.copy(active = !checked, notified = true))
                                }
                            }
                        }
                    ) { css { padding(0.spacingUnits, 1.spacingUnits) } }
                }
            }
        }
        mGridContainer2(direction = MGridDirection.row) {
            mGridItem2(
                MGridBreakpoints2(MGridSize2.Cells12)
                    .down(Breakpoint.sm, MGridSize2.Cells12)
            ) {
                mTextField(
                    margin = MFormControlMargin.dense,
                    variant = MFormControlVariant.outlined,
                    placeholder = "Informationen oder Anfragen zu dieser Reparatur",
                    label = "Kommentar",
                    value = state.comment ?: repair.comment ?: "",
                    onChange = {
                        val text = it.targetInputValue
                        setState {
                            comment = text
                            commentOK = false
                        }
                    }
                ) {
                    attrs.fullWidth = true
                    attrs.inputProps =
                        if (state.comment?.isNotBlank() == true && repair.comment != state.comment) {
                            jsObject<MInputProps> {
                                endAdornment = buildElement {
                                    if (state.commentOK)
                                        mIconNoTranslate(
                                            iconName = "check",
                                        ) { css { color = Color.green } }
                                    else if (!state.commentOK) {
                                        mIconNoTranslate(
                                            iconName = "error",
                                        ) { css { color = Color.red } }
                                    } else {
                                        mIconButtonNoTranslate(
                                            size = MButtonSize.small,
                                            addAsChild = true,
                                            iconName = "send",
                                            onClick = {
                                                changeRepair(
                                                    repair.copy(
                                                        comment = state.comment,
                                                        notified = false
                                                    )
                                                )
                                            }
                                        ) {
                                            css { fontSize = 14.px }
                                            attrs.size = MIconButtonSize.small
                                        }
                                    }
                                }
                            }
                        } else jsObject {}
                }
            }
        }
        mGridItem2(
            MGridBreakpoints2(MGridSize2.Cells12)
                .down(Breakpoint.sm, MGridSize2.Cells12)
        ) {
            styledDiv {
                mTable {
                    css {
                        tableLayout = TableLayout.fixed
                        width = 100.pct
                        paddingTop = 2.spacingUnits
//                        display = Display.inlineBlock
                        overflow = Overflow.auto
                        whiteSpace = WhiteSpace.nowrap
                    }
                    mTableHead {
                        mTableRowSlim {
                            mTableCell(align = MTableCellAlign.left) {
                                css { width = 140.px; padding(0.spacingUnits, 2.spacingUnits) }
                                +"Datum"
                            }
                            mTableCell(align = MTableCellAlign.left) {
                                css { width = 200.px; padding(0.spacingUnits, 2.spacingUnits) }
                                +"Schritt"
                            }
                            authorize(Permissions.AdminPermissions.internalAccess) {
                                mTableCell(align = MTableCellAlign.left) {
                                    css { width = 270.px; padding(0.spacingUnits, 2.spacingUnits) }
                                    +"Erstellt von"
                                }
                            }
                            mTableCell(align = MTableCellAlign.left) {
                                css { width = 100.pct; padding(0.spacingUnits, 2.spacingUnits) }
                                +"Beschreibung"
                            }
                            authorize(Permissions.AdminPermissions.internalAccess) {
                                mTableCell(align = MTableCellAlign.left) {
                                    css { width = 100.px; padding(0.spacingUnits, 2.spacingUnits) }
                                    +""
                                }
                            }
                        }
                    }
                    mTableBody {
                        repair.repairSteps.forEachIndexed { index, step ->
                            mTableRowSlim {
                                mTableCell(align = MTableCellAlign.left) {
                                    css { padding(0.spacingUnits, 1.spacingUnits) }
                                    +step.timestamp.toText()
                                }
                                mTableCell(align = MTableCellAlign.left) {
                                    css { minWidth = 200.px; padding(0.spacingUnits, 1.spacingUnits) }
                                    mTextField(
                                        variant = MFormControlVariant.outlined,
                                        label = "",
                                        value = step.step.readableName,
                                    ) {
                                        attrs.margin = MFormControlMargin.dense
                                        attrs.fullWidth = true
                                        attrs.disabled = true
                                    }
                                }
                                if (isAuthorized(Permissions.AdminPermissions.internalAccess)) {
                                    mTableCell(align = MTableCellAlign.left) {
                                        css { padding(0.spacingUnits, 1.spacingUnits) }
                                        +"${
                                            state.userMap[step.createdBy]?.email
                                                ?: state.userMap[step.createdBy]?.lastName
                                                ?: step.createdBy
                                        }"
                                    }
                                    mTableCell(align = MTableCellAlign.left) {
                                        css { padding(0.spacingUnits, 1.spacingUnits) }
                                        mTextFieldMultiLine(
                                            variant = MFormControlVariant.outlined,
                                            label = "",
                                            value = state.changeMap[index]?.description ?: step.description,
                                            onChange = {
                                                val text = it.targetInputValue

                                                setState {
                                                    changeMap[index] = step.copy(description = text)
                                                }
                                            }
                                        ) {
                                            attrs.margin = MFormControlMargin.dense
                                            attrs.fullWidth = true
                                            attrs.title = state.changeMap[index]?.description ?: step.description
                                        }
                                    }
                                    mTableCell(align = MTableCellAlign.left) {
                                        css { padding(0.spacingUnits, 1.spacingUnits) }
                                        mTooltip("Löschen") {
                                            mIconButtonNoTranslate(
                                                iconName = "delete",
                                                size = MButtonSize.small,
                                                color = MColor.inherit,
                                                onClick = {
                                                    deleteRepairStep(step)
                                                }
                                            ) { css { backgroundColor = Color.red; color = Color.white } }
                                        }
                                        if (state.changeMap[index] != null && step.description != state.changeMap[index]?.description) {
                                            mTooltip("Übernehmen") {
                                                mIconButtonNoTranslate(
                                                    iconName = "edit",
                                                    size = MButtonSize.small,
                                                    color = MColor.inherit,
                                                    onClick = {
                                                        if((step.step == RepairStepType.IN_REPAIR ||
                                                            step.step == RepairStepType.EXAMINATION_BY_MANUFACTURER) &&
                                                            step.description.isBlank()) {
                                                                setState {
                                                                    warnDialog = "Bitte geben Sie eine Beschreibung ein."
                                                                }
                                                            } else {
                                                            val dto = step.copy(
                                                                description = state.changeMap[index]?.description ?: "",
                                                                timestamp = LocalDateTime.now(),
                                                                createdBy = state.currentUser?.id
                                                            )
                                                            changeRepairStep(dto)
                                                        }
                                                    }
                                                ) {
                                                    css {
                                                        background = "#2196f3"
                                                        color = Color.white
                                                        margin(0.spacingUnits, 1.spacingUnits)
                                                    }
                                                }
                                            }
                                            mTooltip("Abbrechen") {
                                                mIconButtonNoTranslate(
                                                    iconName = "clear",
                                                    size = MButtonSize.small,
                                                    color = MColor.inherit,
                                                    onClick = {
                                                        setState {
                                                            changeMap[index] = step
                                                        }
                                                    }
                                                ) { css { backgroundColor = Color.grey; color = Color.white } }
                                            }
                                        }
                                    }
                                } else {
                                    mTableCell(align = MTableCellAlign.left) {
                                        css { padding(0.spacingUnits, 1.spacingUnits) }
                                        mTextField(
                                            label = "",
                                            variant = MFormControlVariant.outlined,
                                            value = step.description,
                                            disabled = true,
                                        ) {
                                            attrs.margin = MFormControlMargin.dense
                                            attrs.fullWidth = true
                                        }
                                    }
                                }
                            }
                        }
                        if (isAuthorized(Permissions.AdminPermissions.internalAccess) || (repair.repairSteps.none { it.step > RepairStepType.ANNOUNCED } && repair.active)) {
                            mTableRowSlim {
                                mTableCell(align = MTableCellAlign.left) {
                                    css { minWidth = 200.px; padding(0.spacingUnits, 1.spacingUnits) }
                                    +"${LocalDateTime.now().toText()}"
                                }
                                mTableCell(align = MTableCellAlign.left) {
                                    css { minWidth = 200.px; padding(0.spacingUnits, 1.spacingUnits) }
                                    mSelect(
                                        variant = MFormControlVariant.outlined,
                                        value = state.newEntry.first ?: RepairStepType.EXAMINATION_BY_CUSTOMER,
                                        onChange = { event, _ ->
                                            val newValue = RepairStepType.valueOf(event.targetValue as String)
                                            setState {
                                                newEntry = Pair(newValue, state.newEntry.second)
                                            }
                                        }
                                    ) {
                                        attrs.margin = MFormControlMargin.dense.toString()
                                        attrs.fullWidth = true
                                        if (isAuthorized(Permissions.AdminPermissions.internalAccess))
                                            for (mode in RepairStepType.values()) {
                                                mMenuItem(
                                                    primaryText = mode.readableName,
                                                    value = mode.name,
                                                )
                                            }
                                        else {
                                            mMenuItem(
                                                primaryText = RepairStepType.EXAMINATION_BY_CUSTOMER.readableName,
                                                value = RepairStepType.EXAMINATION_BY_CUSTOMER.name,
                                            )
                                        }
                                    }
                                }
                                authorize(Permissions.AdminPermissions.internalAccess) {
                                    mTableCell(align = MTableCellAlign.left) {
                                        css { minWidth = 200.px; padding(0.spacingUnits, 1.spacingUnits) }
                                        +""
                                    }
                                }
                                mTableCell(align = MTableCellAlign.left) {
                                    css { minWidth = 200.px; padding(0.spacingUnits, 1.spacingUnits) }
                                    mTextField(
                                        label = "",
                                        variant = MFormControlVariant.outlined,
                                        value = state.newEntry?.second ?: "",
                                        onChange = {
                                            val text = it.targetInputValue
                                            setState {
                                                newEntry = Pair(state.newEntry.first, text)
                                            }
                                        }
                                    ) { attrs.fullWidth = true; attrs.margin = MFormControlMargin.dense }
                                }
                                mTableCell(align = MTableCellAlign.left) {
                                    css { minWidth = 200.px; padding(0.spacingUnits, 1.spacingUnits) }
                                    mTooltip("Übernehmen") {
                                        mIconButtonNoTranslate(
                                            iconName = "edit",
                                            size = MButtonSize.small,
                                            color = MColor.inherit,
                                            onClick = {
                                                if((state.newEntry.first == RepairStepType.IN_REPAIR ||
                                                    state.newEntry.first == RepairStepType.EXAMINATION_BY_MANUFACTURER) &&
                                                    state.newEntry.second?.isBlank() ?: true ) {
                                                    setState {
                                                        warnDialog = "Bitte geben Sie eine Beschreibung ein."
                                                    }
                                                } else {
                                                    val dto = RepairStepDTO(
                                                        id = repair.id,
                                                        createdBy = state.currentUser?.id,
                                                        timestamp = LocalDateTime.now(),
                                                        step = state.newEntry.first ?: RepairStepType.ANNOUNCED,
                                                        description = state.newEntry.second ?: ""
                                                    )
                                                    setState {
                                                        newEntry = Pair(null, null)
                                                    }
                                                    newRepairStep(dto)
                                                }
                                            }
                                        ) {
                                            css {
                                                background = "#2196f3"
                                                color = Color.white
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        authorize(Permissions.AdminPermissions.internalAccess) {
            styledDiv {
                css { padding(1.spacingUnits, 0.spacingUnits) }
                mGridContainer2(direction = MGridDirection.row, alignItems = MGridAlignItems.center) {
                    if (returnable) {
                        mGridItem2(
                            MGridBreakpoints2(MGridSize2.Auto)
                                .down(Breakpoint.xs, MGridSize2.Cells12)
                        ) {
                            mButton(
                                caption = "Abschließen",
                                variant = MButtonVariant.contained,
                                size = MButtonSize.small,
                                color = MColor.inherit,
                                disabled = !returnable,
                                onClick = {
                                    changeRepair(repair.copy(active = false, notified = true))
                                }
                            ) {
                                attrs.fullWidth = true
                                attrs.disableElevation = true
                                css {
                                    backgroundColor = Color.green; color = Color.white
                                }
                            }
                        }
                    }
                    mGridItem2(
                        MGridBreakpoints2(MGridSize2.Auto)
                            .down(Breakpoint.xs, MGridSize2.Cells12)
                    ) {
                        mButton(
                            caption = "Löschen",
                            variant = MButtonVariant.contained,
                            size = MButtonSize.small,
                            color = MColor.inherit,
                            onClick = {
                                setState {
                                    deleteDTO = repair
                                    deleteDialog = true
                                }
                            }
                        ) {
                            attrs.fullWidth = true
                            attrs.disableElevation = true
                            css {
                                backgroundColor = Color.red; color = Color.white
                            }
                        }
                    }
                    if (props.openRepairsOnly) {
                        val noReturns = repair.repairSteps.any { it.step > RepairStepType.EXAMINATION_BY_CUSTOMER }
                        mGridItem2(
                            MGridBreakpoints2(MGridSize2.Auto)
                                .down(Breakpoint.xs, MGridSize2.Cells12)
                        ) {
                            if (!noReturns)
                                mButton(
                                    caption = "Widerrufen",
                                    variant = MButtonVariant.contained,
                                    size = MButtonSize.small,
                                    color = MColor.inherit,
                                    onClick = {
                                        changeRepair(
                                            repair.copy(
                                                active = false,
                                                notified = true,
                                                comment = "${repair.comment ?: ""} Reparatur abgebrochen."
                                            )
                                        )
                                    }
                                ) {
                                    attrs.fullWidth = true
                                    attrs.disableElevation = true
                                    css {
                                        backgroundColor = Color.grey; color = Color.white
                                    }
                                }
                        }
                    }
                }
            }
        }
    }

    private fun RBuilder.dialogues() {
        mDialog(
            open = state.warnDialog.isNotEmpty(),
            onClose = { _, _ -> setState { warnDialog = "" } }
        ) {
            mDialogContent { mTypography(state.warnDialog) }
            mDialogActions {
                mButton("Okay", onClick = { setState { warnDialog = "" } })
            }
        }
        mDialog(
            open = state.deleteDialog,
            onClose = { _, _ -> setState { deleteDialog = false } }
        ) {
            mDialogContent { mTypography("Reparatur unwiderruflich löschen? Sicher?") }
            mDialogActions {
                mButton("Nein, Abbrechen", onClick = { setState { deleteDialog = false } })
                mButton(
                    caption = "Ja, Bestätigen",
                    onClick = {
                        state.deleteDTO?.let { deleteRepair(it) }
                        setState { deleteDialog = false }
                    }
                ) { css { color = Color.red } }
            }
        }
    }
}