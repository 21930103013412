package de.geomobile.frontend.features.admin.roles

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardActions
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.form.MFormControlMargin
import com.ccfraser.muirwik.components.form.MFormControlVariant
import com.ccfraser.muirwik.components.menu.mMenuItem
import de.geomobile.common.permission.ImmutableRoles
import de.geomobile.common.permission.Permission
import de.geomobile.common.permission.Permissions
import de.geomobile.common.permission.Role
import de.geomobile.common.portalmodels.Company
import de.geomobile.common.portalmodels.UserDTO
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.utils.*
import kotlinext.js.jsObject
import kotlinx.coroutines.*
import kotlinx.css.*
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.json.Json
import react.RBuilder
import react.RProps
import react.RState
import react.setState
import styled.css
import styled.styledDiv

fun RBuilder.adminRoleCreate(
    cancel: () -> Unit,
    created: (roleId: String) -> Unit,
) = child(AdminRoleEdit::class) {
    attrs.roleId = null
    attrs.mode = AdminRoleEdit.Mode.CREATE
    attrs.cancel = cancel
    attrs.saved = created
}

fun RBuilder.adminRoleEdit(
    roleId: String,
    goBack: () -> Unit,
) = child(AdminRoleEdit::class) {
    attrs.roleId = roleId
    attrs.mode = AdminRoleEdit.Mode.EDIT
    attrs.cancel = { goBack() }
    attrs.saved = { goBack() }
}

class AdminRoleEdit : CComponent<AdminRoleEdit.Props, AdminRoleEdit.State>() {

    private var saveJob: Job = Job()

    enum class Mode {
        CREATE, EDIT
    }

    interface Props : RProps {
        var roleId: String?
        var mode: Mode
        var cancel: () -> Unit
        var saved: (roleId: String) -> Unit
    }

    class State(
        var name: String? = null,
        var company: Company? = null,
        var mutable: Boolean = false,
        var allVisible: Boolean = false,
        var selectedPermissions: Set<Permission> = emptySet(),

        var companies: List<Company>? = null,
        var saving: Boolean = false,
    ) : RState


    init {
        state = State()
    }

    override fun componentDidMount() {
        launch {
            var name: String? = null
            val company: Company?
            var mutable = true
            var permissions: Set<Permission> = emptySet()

            val user = async(Dispatchers.Default) {
                portalRestApi.get("/user", UserDTO.serializer())
            }

            val deferredCompanies = if (isAuthorized(Permissions.RoleManagement.notRestrictedToCompany))
                async(Dispatchers.Default) {
                    portalRestApi.get("/admin/companies", ListSerializer(Company.serializer()))
                }
            else
                null

            if (props.roleId != null) {
                val role = ImmutableRoles.findById(props.roleId!!)
                    ?: withContext(Dispatchers.Default) {
                        portalRestApi.get("/admin/roles/${props.roleId}", Role.serializer())
                    }
                name = role.name
                company = role.company
                permissions = role.permissions
                mutable = role.mutable
            } else {
                company = user.await().company
            }

            val allVisible = !user.await().role.visibleForCompany
            val companies = deferredCompanies?.await()

            setState {
                this.name = name
                this.company = company
                this.mutable = mutable && isAuthorized(Permissions.RoleManagement.rolesEdit)
                this.allVisible = allVisible
                this.selectedPermissions = permissions
                this.companies = companies
            }
        }
    }

    override fun RBuilder.render() {
        val companies = state.companies

        mCard {
            mCardHeaderExtended(
                when (props.mode) {
                    Mode.CREATE -> "Erstellen"
                    Mode.EDIT -> "Bearbeiten"
                },
                subHeader = state.name ?: "",
                titleTextTransform = TextTransform.uppercase,
                avatar = RBuilder().mIconButtonNoTranslate(
                    iconName = "arrow_back",
                    onClick = { props.cancel() }
                )
            ) {
                attrs.titleTypographyProps = jsObject<MTypographyProps> {
                    variant = MTypographyVariant.subtitle2
                    color = MTypographyColor.secondary
                }
                attrs.subheaderTypographyProps = jsObject<MTypographyProps> {
                    variant = MTypographyVariant.caption
                }
            }
            mDivider { }
            mCardContent {
                if (props.mode == Mode.EDIT && state.name == null) {
                    mCircularProgress { css { display = Display.block; margin(LinearDimension.auto) } }
                } else {
                    mGridContainer2(direction = MGridDirection.row) {
                        mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                            mTypography(
                                text = "Name *",
                                variant = MTypographyVariant.caption
                            ) {
                                css { display = Display.block }
                            }
                            mTextField(
                                label = "",
                                value = state.name ?: "",
                                name = "name",
                                fullWidth = true,
                                required = true,
                                disabled = !state.mutable,
                                margin = MFormControlMargin.dense,
                                variant = MFormControlVariant.outlined,
                                id = "name",
                                onChange = {
                                    val value = it.targetInputValue
                                    setState { this.name = value }
                                }
                            )
                        }

                        if (companies != null) {
                            mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                                mTypography(
                                    text = "Unternehmen *",
                                    variant = MTypographyVariant.caption
                                ) {
                                    css { display = Display.block; marginBottom = 6.px }
                                }
                                mSelect(
                                    value = state.company?.id ?: "",
                                    name = "company",
                                    id = "company",
                                    fullWidth = true,
                                    variant = MFormControlVariant.outlined,
                                    onChange = { event, _ ->
                                        val id = event.targetValue as String
                                        setState { this.company = companies.first { it.id == id } }
                                    }
                                ) {
                                    attrs.required = true
                                    attrs.margin = MFormControlMargin.dense.toString()

                                    for (company in companies) {
                                        mMenuItem(primaryText = company.name, value = company.id)
                                    }
                                }
                            }
                        }

                        mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                            styledDiv {
                                css { marginTop = 1.spacingUnits }

                                permissionSelection(
                                    allVisible = state.allVisible,
                                    mutable = state.mutable,
                                    features = state.company?.features,
                                    permissions = state.selectedPermissions
                                ) { permissions -> setState { this.selectedPermissions = permissions } }
                            }
                        }
                    }
                }
            }

            if (state.mutable) {
                if (state.saving) {
                    mCircularProgress(size = 30.px) {
                        css {
                            display = Display.block
                            margin(vertical = 1.spacingUnits, horizontal = 3.spacingUnits)
                            marginLeft = LinearDimension.auto
                        }
                    }
                } else {
                    mDivider { }
                    mCardActions {
                        css { padding(2.spacingUnits) }

                        val disabled = state.name == null

                        mButton(
                            caption = when (props.mode) {
                                Mode.CREATE -> "Erstellen"
                                Mode.EDIT -> "Übernehmen"
                            },
                            variant = MButtonVariant.contained,
                            color = MColor.secondary,
                            disabled = disabled,
                            onClick = {
                                val role = Role(
                                    id = props.roleId ?: "",
                                    name = state.name!!,
                                    company = state.company,
                                    mutable = true,
                                    permissions = state.selectedPermissions
                                )
                                saveRole(role)
                            }
                        ) {
                            attrs.disableElevation = true
                        }

                        mButton(
                            caption = "Abbrechen",
                            variant = MButtonVariant.contained,
                            color = MColor.default,
                            onClick = { props.cancel() }
                        ) {
                            attrs.disableElevation = true
                        }
                    }
                }
            }
        }
    }

    private fun saveRole(role: Role) {
        setState {
            this.saving = true
        }

        saveJob.cancel()
        saveJob = launch {
            val newRole = withContext(Dispatchers.Default) {
                when (props.mode) {
                    Mode.CREATE ->
                        portalRestApi.post(
                            path = "/admin/roles",
                            body = Json.encodeToString(Role.serializer(), role),
                            serializer = Role.serializer()
                        )

                    Mode.EDIT ->
                        portalRestApi.put(
                            path = "/admin/roles/${role.id}",
                            body = Json.encodeToString(Role.serializer(), role),
                            serializer = Role.serializer()
                        )
                }
            }

            props.saved(newRole.id)
        }
    }
}