package de.geomobile.frontend.features.portalSettings

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonSize
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.form.MFormControlMargin
import com.ccfraser.muirwik.components.form.MFormControlVariant
import com.ccfraser.muirwik.components.list.mList
import com.ccfraser.muirwik.components.list.mListItem
import com.ccfraser.muirwik.components.list.mListItemSecondaryAction
import com.ccfraser.muirwik.components.list.mListSubheader
import com.ccfraser.muirwik.components.menu.mMenuItem
import de.geomobile.common.permission.Permissions
import de.geomobile.common.portalmodels.Company
import de.geomobile.common.portalmodels.UserDTO
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.navigator
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.utils.*
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.css.*
import kotlinx.html.InputType
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.json.Json
import portalmodels.TicketTokenDTO
import portalmodels.generateToken
import react.RBuilder
import react.RProps
import react.RState
import react.dom.div
import react.setState
import styled.StyledElementBuilder
import styled.css
import styled.styledDiv

fun RBuilder.vehicleApiAccess() = child(VehicleApiAccess::class) {}

class VehicleApiAccess : CComponent<VehicleApiAccess.Props, VehicleApiAccess.State>() {

    private var fetchTokenJob: Job = Job()

    interface Props : RProps

    class State(
        var token: MutableList<TicketTokenDTO> = mutableListOf(),
        var companies: List<String> = listOf(),
        var saveOK: Boolean = false,
        var errorText: String? = null
    ) : RState

    init {
        state = State()
    }

    private fun fetchToken() {
        fetchTokenJob.cancel()
        fetchTokenJob = launch {
            val token = portalRestApi.get("/tickettoken", ListSerializer(TicketTokenDTO.serializer()))
            setState {
                this.token = token.toMutableList()
            }
        }
    }

    private fun saveToken() {
        launch {
            val token = Json.encodeToString(ListSerializer(TicketTokenDTO.serializer()), state.token)
            portalRestApi.put("/tickettoken", token)
            setState {
                saveOK = true
            }
        }.invokeOnCompletion {
            fetchToken()
        }
    }

    private fun fetchCompanies() {
        launch {
            val companies = if(isAuthorized(Permissions.AdminPermissions.internalAccess))
                portalRestApi.get("/admin/companies", ListSerializer(Company.serializer()))
            else
                listOf(portalRestApi.get("/user", UserDTO.serializer()).company)
            setState {
                this.companies = companies.map { it.id }
            }
        }
    }

    override fun componentDidMount() {
        fetchCompanies()
        fetchToken()
    }

    override fun RBuilder.render() {
        mGridContainer2(direction = MGridDirection.row) {
            mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                detail()
            }
        }
    }

    private fun StyledElementBuilder<MGridProps2>.detail() {
        mGridContainer2(direction = MGridDirection.row) {
            mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                mCard {
                    css(GlobalStyles.card)
                    mCardContent {
                        css(GlobalStyles.cardContent)
                        mListSubheader(heading = "Fahrzeug API Token")
                        mDivider {}
                        authorize(Permissions.CompanyProfileManagement.ticketApiEdit) {
                            styledDiv {
                                css { padding(2.spacingUnits) }
                                mButton(
                                    caption = "Übernehmen",
                                    color = MColor.secondary,
                                    variant = MButtonVariant.contained,
                                    onClick = {
                                        saveToken()
                                    }
                                ) {
                                    attrs.disableElevation = true
                                }
                                if (state.saveOK)
                                    mIconNoTranslate("check") {
                                        css {
                                            color = Color.green
                                        }
                                    }
                                if (state.errorText != null) {
                                    mIconNoTranslate("error") {
                                        css {
                                            color = Color.red
                                        }
                                    }
                                    mTypography(state.errorText!!, MTypographyVariant.body2) {
                                        css {
                                            color = Color.red
                                        }
                                    }
                                }
                            }
                        }
                        mDivider {}
                        styledDiv {
                            css { padding(0.spacingUnits, 2.spacingUnits) }
                            mList {
                                css { padding(0.spacingUnits) }
                                state.token
                                    .plus(TicketTokenDTO("", "", 0, 0))
                                    .forEachIndexed { idx, token ->
                                        mListItem(
                                            divider = false,
                                            button = false,
                                            dense = true
                                        ) {
                                            attrs.disableGutters = true
                                            authorize(Permissions.CompanyProfileManagement.ticketApiEdit) {
                                                mIconButtonNoTranslate(
                                                    iconName = "refresh",
                                                    size = MButtonSize.small,
                                                    color = MColor.inherit,
                                                    onClick = { _ ->
                                                        setState {
                                                            if(idx >= this.token.size)
                                                                this.token.add(TicketTokenDTO(generateToken(), "", 0, 0))
                                                            else
                                                                this.token[idx] = token.copy(token = generateToken())
                                                        }
                                                    }
                                                ) { css {
                                                    height = 36.px
                                                    width = 36.px
                                                    backgroundColor = Color.lightGray
                                                } }
                                            }
                                            mIconButtonNoTranslate(
                                                iconName = "content_copy",
                                                size = MButtonSize.small,
                                                color = MColor.inherit,
                                                onClick = { _ ->
                                                    navigator.clipboard.writeText(token.token)
                                                }
                                            ) { css {
                                                marginLeft = 1.spacingUnits
                                                height = 36.px
                                                width = 36.px
                                                backgroundColor = Color.lightGray
                                            } }

                                            mTextField(
                                                label = "Token",
                                                value = token.token.substring(0, 16) + "…",
                                                disabled = true
                                            ) {
                                                css {
                                                    margin(1.spacingUnits)
//                                                    width = 20.pct
                                                }
                                            }
                                            mSelect(
                                                variant = MFormControlVariant.outlined,
                                                value = token.companyId,
                                                disabled = !isAuthorized(Permissions.CompanyProfileManagement.ticketApiEdit),
                                                onChange = { event, _ ->
                                                    val newValue = event.targetValue as String
                                                    setState {
                                                        if(idx >= this.token.size)
                                                            this.token.add(TicketTokenDTO(generateToken(), newValue, 0, 0))
                                                        else
                                                            this.token[idx] = token.copy(companyId = newValue)
                                                    }
                                                }
                                            ) {
                                                css {
                                                    marginTop = 1.spacingUnits
                                                    width = 260.px
                                                }

                                                attrs.margin = MFormControlMargin.dense.toString()

                                                for (mode in state.companies) {
                                                    mMenuItem(primaryText = mode, value = mode)
                                                }
                                            }
                                            if(token.token.isNotEmpty() && token.companyId.isNotEmpty()) {
                                                mTextField(
                                                    label = "Abfragen pro Sekunde",
                                                    value = token.maxRatePerSecond.toString(),
                                                    type = InputType.number,
                                                    disabled = !isAuthorized(Permissions.CompanyProfileManagement.ticketApiEdit),
                                                    onChange = { event ->
                                                        val newValue = (event.targetValue as String).toIntOrNull()
                                                        newValue?.let {
                                                            setState {
                                                                if(idx >= this.token.size)
                                                                    this.token.add(TicketTokenDTO("", "", newValue.toInt(), 0))
                                                                else
                                                                    this.token[idx] = token.copy( maxRatePerSecond = newValue.toInt())
                                                            }
                                                        }
                                                    }
                                                ) {
                                                    css {
                                                        margin(1.spacingUnits)
                                                        width = 180.px
                                                    }
                                                }
                                                mTextField(
                                                    label = "Abfragen pro Minute",
                                                    value = token.maxRatePerMinute.toString(),
                                                    type = InputType.number,
                                                    disabled = !isAuthorized(Permissions.CompanyProfileManagement.ticketApiEdit),
                                                    onChange = { event ->
                                                        val newValue = (event.targetValue as String).toIntOrNull()
                                                        newValue?.let {
                                                            setState {
                                                                if(idx >= this.token.size)
                                                                    this.token.add(TicketTokenDTO("", "", 0, newValue.toInt()))
                                                                else
                                                                    this.token[idx] = token.copy( maxRatePerMinute = newValue.toInt())
                                                            }
                                                        }
                                                    }
                                                ) {
                                                    css {
                                                        margin(1.spacingUnits)
                                                        width = 180.px
                                                    }
                                                }
                                                mTextField(
                                                    label = "Ablaufdatum",
                                                    value = token.expirationDate?.toText() ?: "",
                                                    disabled = true
                                                ) {
                                                    css {
                                                        margin(1.spacingUnits)
                                                        width = 160.px
                                                    }
                                                }
                                                mTooltip(
                                                    title = "Ersetzte Token können nicht mehr benutzt werden und werden kurze Zeit nach ihrem Ablaufdatum gelöscht."
                                                ) {
                                                    div {
                                                        mCheckboxWithLabel(
                                                            label = "Ersetzt",
                                                            checked = token.exchangedForNew,
                                                            disabled = true,
                                                        ) { css { margin(1.spacingUnits) } }
                                                        mIconButtonNoTranslate("help_outline")
                                                    }
                                                }
                                                authorize(Permissions.CompanyProfileManagement.ticketApiEdit) {
                                                    mListItemSecondaryAction {
                                                        mIconButtonNoTranslate(
                                                            iconName = "delete",
                                                            size = MButtonSize.small,
                                                            color = MColor.inherit,
                                                            onClick = { _ ->
                                                                setState {
                                                                    this.token.removeAt(idx)
                                                                }
                                                            }
                                                        ) {
                                                            css {
                                                                height = 36.px
                                                                width = 36.px
                                                                backgroundColor = Color.red
                                                                color = Color.white
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                }
                            }
                        }

                    }
                }
            }
        }
    }
}