package de.geomobile.frontend.features.portalSettings

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonSize
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardActions
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.dialog.DialogScroll
import com.ccfraser.muirwik.components.dialog.mDialog
import com.ccfraser.muirwik.components.dialog.mDialogActions
import com.ccfraser.muirwik.components.form.MFormControlMargin
import com.ccfraser.muirwik.components.form.MFormControlVariant
import com.ccfraser.muirwik.components.list.*
import com.ccfraser.muirwik.components.menu.mMenuItem
import com.ccfraser.muirwik.components.styles.Breakpoint
import components.emptyView
import de.geomobile.common.permission.Permissions
import de.geomobile.common.utils.Tree
import de.geomobile.common.utils.emptyTree
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.spacer
import de.geomobile.frontend.utils.*
import hardware.AssemblyLifecycle
import hardware.HardwareFeature
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.css.*
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.builtins.MapSerializer
import kotlinx.serialization.builtins.serializer
import kotlinx.serialization.json.Json
import portalmodels.GenericResponseDTO
import portalmodels.HardwareVersionFeatureDTO
import portalmodels.ProductAssemblyDTO
import portalmodels.ProductVariantDTO
import react.RBuilder
import react.RProps
import react.RState
import react.setState
import styled.css
import styled.styledDiv
import kotlin.math.max
import kotlin.math.min

fun RBuilder.productVariants() = child(PortalSettingsProductVariants::class) {}

class PortalSettingsProductVariants :
    CComponent<RProps, PortalSettingsProductVariants.State>() {

    private var fetchListJob: Job = Job()
    private var fetchMatNumJob: Job = Job()

    class State(
        var assemblyTree: Tree<ProductAssemblyDTO> = emptyTree(),
        var assemblyList: List<ProductAssemblyDTO> = listOf(),

        var variantTree: Tree<ProductVariantDTO> = emptyTree(),
        var variantList: List<ProductVariantDTO> = listOf(),

        var selectedAssembly: ProductAssemblyDTO? = null,
        var variantAssemblyMap: MutableMap<ProductVariantDTO, List<ProductAssemblyDTO>> = mutableMapOf(),

        var selectedVariant: ProductVariantDTO? = null,
        var variantFeatureMap: MutableMap<ProductVariantDTO, List<HardwareFeature>> = mutableMapOf(),
        var saveOK: Boolean = false,
        var deleteProfileDialog: Boolean = false,
//        var deleteProfileCandidate: ProductVariantDTO = ProductVariantDTO(id = 0, materialNumber = 0, name = "", revision = ""),
    ) : RState

    init {
        state = State()
    }

    private fun fetchProductVariantsAndFeatures() {
        fetchMatNumJob.cancel()
        fetchMatNumJob = launch {
            val variants =
                portalRestApi.get("/devices/variants", ListSerializer(ProductVariantDTO.serializer()))

            val featureLists =
                portalRestApi.get("/portalsettings/material/getlist", HardwareVersionFeatureDTO.serializer())

            val list = portalRestApi.get("/portalsettings/assemblies/list", ListSerializer(ProductAssemblyDTO.serializer()))
            val relations = portalRestApi.get(
                "/portalsettings/assemblies/variant/relations",
                MapSerializer(
                    Int.serializer(), // variant ID
                    ListSerializer(Int.serializer()) // assembly IDs
                )
            )

            val relationsFilled = relations
                .filter { (variantId, _) ->
                    variants.any { it.id == variantId }
                }
                .mapKeys { (variantId, _) ->
                    variants.find { it.id == variantId }!!
                }
                .mapValues { (_, assemblies) ->
                    assemblies.map { assembly ->
                        list.find { it.id == assembly }!!
                    }
                }

            val variantNodes = generateVariantTree(variants)
            val assemblyNodes = generateAssemblyTree(list, 0)

            setState {
                assemblyList = list
                assemblyTree = Tree(assemblyNodes)
                variantAssemblyMap = relationsFilled.toMutableMap()
                variantTree = Tree(variantNodes)
                variantList = variants
                variantFeatureMap =
                    variants.associateWith { featureLists.settings[it.id] ?: listOf() }.toMutableMap()
            }
        }
    }


    private fun saveVariantsAndFeatures() {
        launch {
            val variantBody = Json.encodeToString(
                ListSerializer(ProductVariantDTO.serializer()),
                state.variantFeatureMap.keys.toList()
            )
            val featureBody = Json.encodeToString(
                HardwareVersionFeatureDTO.serializer(),
                HardwareVersionFeatureDTO(state.variantFeatureMap.mapKeys { it.key.id })
            )
            val assemblyBodies = state.variantAssemblyMap.mapValues { (_, assemblies) ->
                Json.encodeToString(
                    ListSerializer(ProductAssemblyDTO.serializer()),
                    assemblies
                )
            }

            val responseFeature =
                portalRestApi.put("/portalsettings/material/setlist", featureBody, GenericResponseDTO.serializer())
            val responseVariant =
                portalRestApi.put("/devices/variants", variantBody, GenericResponseDTO.serializer())
            val responseAssembly = assemblyBodies.map { (variant, body) ->
                portalRestApi.put("/portalsettings/assemblies/variant/${variant.id}/save", body, GenericResponseDTO.serializer())
            }

            if (!responseFeature.isError && !responseVariant.isError && !responseAssembly.any { it.isError })
                setState {
                    saveOK = true
                }
        }.invokeOnCompletion {
            fetchProductVariantsAndFeatures()
        }
    }

    private fun generateVariantTree(list: List<ProductVariantDTO>): List<Tree.Node<ProductVariantDTO>> {
        return list
            .distinctBy { it.materialNumber }
            .map { variant ->
                Tree.Node(
                    ProductVariantDTO(
                        id = 0,
                        materialNumber = variant.materialNumber,
                        name = variant.name,
                        revision = ""
                    ),
                    children = list
                        .filter { variant.materialNumber == it.materialNumber  }
                        .map {
                            Tree.Node(it)
                        }
                )
            }
    }

    private fun generateAssemblyTree(list: List<ProductAssemblyDTO>, rootId:Int = 0): List<Tree.Node<ProductAssemblyDTO>> {
        return list
            .filter { it.parentId == rootId }
            .map {
                Tree.Node(
                    it,
                    children = generateAssemblyTree(list, it.id)
                )
            }
    }


    private fun saveAssembly(assembly: ProductAssemblyDTO) {
        launch {
            val assemblyBody = Json.encodeToString(ProductAssemblyDTO.serializer(), assembly)
            val responseAssembly =
                portalRestApi.put("/portalsettings/assemblies/save", assemblyBody, GenericResponseDTO.serializer())

            if (!responseAssembly.isError)
                setState {
                    saveOK = true
                }
        }.invokeOnCompletion {
            fetchProductVariantsAndFeatures()
        }
    }

    private fun deleteAssembly(id: Int) {
        launch {
            portalRestApi.delete("/portalsettings/assemblies/delete/$id")
        }.invokeOnCompletion {
            fetchProductVariantsAndFeatures()
        }
    }

    private fun deleteVariant(id: Int) {
        launch {
            portalRestApi.delete("/devices/variants/$id")
        }.invokeOnCompletion {
            fetchProductVariantsAndFeatures()
        }
    }

    private fun saveAssemblyList() {
        launch {
            val assemblyBody = Json.encodeToString(
                ListSerializer(ProductAssemblyDTO.serializer()),
                state.assemblyList
            )
            val responseAssembly =
                portalRestApi.put("/devices/variants", assemblyBody, GenericResponseDTO.serializer())

            if (!responseAssembly.isError)
                setState {
                    saveOK = true
                }
        }.invokeOnCompletion {
            fetchProductVariantsAndFeatures()
        }
    }


    override fun componentDidMount() {
        fetchProductVariantsAndFeatures()
    }

    override fun RBuilder.render() {
        authorize(Permissions.AdminPermissions.internalAccess) {
            spacer()
            mGridContainer2(direction = MGridDirection.row) {
                if (isAuthorized(Permissions.AdminPermissions.internalAccess)) {
                    mGridItem2(
                        MGridBreakpoints2(MGridSize2.Cells3)
                            .down(Breakpoint.md, MGridSize2.Cells12)
                    ) {
                        sideBarProductVariants()
                        sideBarProductAssemblies()
                    }
                }
                mGridItem2(
                    MGridBreakpoints2(
                        if (isAuthorized(Permissions.AdminPermissions.internalAccess))
                            MGridSize2.Cells9
                        else
                            MGridSize2.Cells12
                    )
                        .down(Breakpoint.md, MGridSize2.Cells12)
                ) {
                    detail()
                }
            }
        }
    }

    private fun RBuilder.sideBarProductVariants() {
        mDialog(state.deleteProfileDialog, scroll = DialogScroll.paper, maxWidth = Breakpoint.md, onClose = { _, _ ->
            setState {
                deleteProfileDialog = false
            }
        }) {
            attrs.disableEscapeKeyDown = true
            mTypography(text = "Produktvariante \"${state.selectedVariant?.name}\" löschen?") {
                css {
                    margin(
                        horizontal = LinearDimension.auto, vertical = 6.pt
                    )
                }
            }
            mDialogActions {
                mButton("Ja, Profil löschen.", color = MColor.secondary, onClick = {
                    setState {
                        variantFeatureMap.remove(selectedVariant)
                        deleteProfileDialog = false
                        saveVariantsAndFeatures()
                        selectedVariant = variantFeatureMap.keys.firstOrNull()
                    }
                })
                mButton("Abbrechen", color = MColor.primary, onClick = {
                    setState {
                        deleteProfileDialog = false
                    }
                })
            }
        }

        val tree: Tree<ProductVariantDTO?> = state.variantTree as Tree<ProductVariantDTO?>

        val entryTree = tree
            .mapByBranch { branch, (value) ->
                TreeEntry(
                    key = if(value?.id == 0) value.id.toString() + value.materialNumber else value?.id.toString(),
                    name = if(value?.id == 0) "MatNr.: ${value.materialNumber.toString().padStart(6, '0')}" else "${value?.name}, Revision: ${value?.revision}" ?: "Neue Variante",
                    new = value == null,
                    selected = value == state.selectedVariant,
                    expandedByDefault = branch.size <= 0,
                    onClick = {
                        setState {
                            if(value?.id != 0)
                                selectedVariant = value
                            else
                                selectedVariant = null
                            selectedAssembly = null
                        }
                    }
                )
            }

        mGridContainer2(direction = MGridDirection.column) {
            mGridItem2 {
                mCard {
                    css(GlobalStyles.card)
                    mCardContent {
                        css(GlobalStyles.cardListContent)
                        tree(heading = "Produktvarianten", tree = entryTree)
                    }
                    mDivider { }
                    mCardActions {
                        css { justifyContent = JustifyContent.spaceEvenly }
                        treeOperations(
                            tree = entryTree,
                            onAddTopLevel = {
                                setState {
                                    val variant = ProductVariantDTO.empty()
                                    variantFeatureMap[variant] = listOf()
                                    selectedVariant = variant
                                    selectedAssembly = null
                                }
                            },
                            onDelete = {
                                setState {
                                    selectedVariant?.let {
                                        deleteVariant(it.id)
                                    }
                                }
                            }
                        )
                    }
                }
            }
        }
    }

    private fun RBuilder.sideBarProductAssemblies() {
        val tree: Tree<ProductAssemblyDTO> = state.assemblyTree

        val entryTree = tree
            .mapByBranch { branch, (value) ->
                value.let {
                    val matNr = it.materialNumber.toString().padStart(6, '0')
                    val rev = it.revision

                    TreeEntry(
                        key = it.id.toString(),
                        name = if(it.isCategory) it.name else "$matNr $rev - ${it.name}",
                        new = false,
                        selected = it == state.selectedAssembly,
                        expandedByDefault = branch.size <= 0,
                        onClick = {
                            setState {
                                selectedAssembly = it
                                selectedVariant = null
                            }
                        }
                    )
                }
            }

        mGridContainer2(direction = MGridDirection.column) {
            mGridItem2 {
                mCard {
                    css(GlobalStyles.card)
                    mCardContent {
                        css(GlobalStyles.cardListContent)
                        tree(heading = "Baugruppen", tree = entryTree)
                    }
                    mDivider { }
                    mCardActions {
                        css { justifyContent = JustifyContent.spaceEvenly }
                        treeOperations(
                            tree = entryTree,
                            onAddTopLevel = {
                                saveAssembly(
                                    ProductAssemblyDTO(
                                        id = 0,
                                        parentId = 0,
                                        name = "Neue Baugruppe",
                                        materialNumber = 0,
                                        revision = "A0",
                                        lifecycle = AssemblyLifecycle.IN_PRODUCTION,
                                        isCategory = false,
                                    )
                                )
                            },
                            onAddChild = {
                                state.selectedAssembly?.let {
                                    saveAssembly(
                                        ProductAssemblyDTO(
                                            id = 0,
                                            parentId = it.id,
                                            name = "Neue Baugruppe",
                                            materialNumber = 0,
                                            revision = "A0",
                                            lifecycle = AssemblyLifecycle.IN_PRODUCTION,
                                            isCategory = false,
                                        )
                                    )
                                }
                            },
                            onAddSibling = {
                                state.selectedAssembly?.let {
                                    saveAssembly(
                                        ProductAssemblyDTO(
                                            id = 0,
                                            parentId = it.parentId,
                                            name = "Neue Baugruppe",
                                            materialNumber = 0,
                                            revision = "A0",
                                            lifecycle = AssemblyLifecycle.IN_PRODUCTION,
                                            isCategory = false,
                                        )
                                    )
                                }
                            },
                            onSelectNewParent = { parent ->
                                state.selectedAssembly?.let {
                                    saveAssembly(
                                        it.copy(
                                            parentId = (parent?.key ?: "0").toInt()
                                        )
                                    )
                                }
                            },
                            onDelete = {
                                state.selectedAssembly?.let { deleteAssembly(it.id) }
                            }
                        )
                    }
                }
            }
        }
    }

    private fun RBuilder.detail() {
        if (state.selectedVariant == null && state.selectedAssembly == null) {
            emptyView(
                title = "Keine Auswahl",
                caption = "Es wurde keine Produktvariante oder Baugruppe ausgewählt",
                addButton = false
            )
        } else {
            if(state.selectedVariant != null)
                detailProductVariants()
            if(state.selectedAssembly != null)
                detailProductAssemblies()
        }
    }

    private fun RBuilder.detailProductVariants() = mGridContainer2(direction = MGridDirection.row) {
        mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
            mCard {
                css(GlobalStyles.card)

                mCardContent {
                    css(GlobalStyles.cardBoxContent)
                    mListSubheader(heading = "Produktvariante")
                    mDivider { }
                    styledDiv {
                        css { padding(2.spacingUnits) }
                        mTextField(
                            label = "Name",
                            fullWidth = true,
                            margin = MFormControlMargin.dense,
                            value = state.selectedVariant?.name ?: "Neue Variante",
                            variant = MFormControlVariant.outlined,
                            onChange = { event ->
                                val value = event.targetInputValue
                                setState {
                                    selectedVariant = selectedVariant?.let { sv ->
                                        val tmp = variantFeatureMap[sv] ?: listOf()
                                        variantFeatureMap.remove(sv)
                                        sv.copy(name = value).also { variantFeatureMap[it] = tmp }
                                    } ?: run {
                                        ProductVariantDTO(
                                            id = 0,
                                            materialNumber = 0,
                                            name = "Neue Variante",
                                            revision = "A0"
                                        ).also { variantFeatureMap[it] = listOf() }
                                    }
                                    saveOK = false
                                }
                            }
                        )
                        mTextField(
                            label = "Materialnummer",
                            fullWidth = true,
                            margin = MFormControlMargin.dense,
                            value = state.selectedVariant?.let { it.materialNumber.toString().padStart(6, '0') }
                                ?: "0",
                            variant = MFormControlVariant.outlined,
                            onChange = { event ->
                                val value = try {
                                    event.targetInputValue.toInt()
                                } catch (e: Exception) {
                                    0
                                }
                                setState {
                                    selectedVariant = selectedVariant?.let { sv ->
                                        val tmp = variantFeatureMap[sv] ?: listOf()
                                        variantFeatureMap.remove(sv)
                                        sv.copy(
                                            materialNumber = value
                                        ).also { variantFeatureMap[it] = tmp }
                                    } ?: run {
                                        ProductVariantDTO(
                                            id = 0,
                                            materialNumber = 0,
                                            name = "Neue Variante",
                                            revision = "A0"
                                        ).also { variantFeatureMap[it] = listOf() }
                                    }
                                    saveOK = false
                                }
                            }
                        )
                        mTextField(
                            label = "Revision",
                            fullWidth = true,
                            margin = MFormControlMargin.dense,
                            value = state.selectedVariant?.revision ?: "A0",
                            variant = MFormControlVariant.outlined,
                            onChange = { event ->
                                val value = try {
                                    event.targetInputValue
                                } catch (e: Exception) {
                                    "A0"
                                }
                                setState {
                                    selectedVariant = selectedVariant?.let { sv ->
                                        val tmp = variantFeatureMap[sv] ?: listOf()
                                        variantFeatureMap.remove(sv)
                                        sv.copy(
                                            revision = value
                                        ).also { variantFeatureMap[it] = tmp }
                                    } ?: run {
                                        ProductVariantDTO(
                                            id = 0,
                                            materialNumber = 0,
                                            name = "Neue Variante",
                                            revision = "A0"
                                        ).also { variantFeatureMap[it] = listOf() }
                                    }
                                    saveOK = false
                                }
                            }
                        )
                    }
                }
            }
        }
        mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
            mCard {
                css(GlobalStyles.card)
                css {
                    display = Display.flex
                    flexWrap = FlexWrap.wrap
                }
                mCardContent {
                    css(GlobalStyles.cardContent)
                    css {
                        width = 50.pct
                    }
                    mListSubheader(heading = "Unterstützte Features")
                    mDivider {}
                    styledDiv {
                        css { padding(1.spacingUnits) }
                        HardwareFeature.values().forEach { hf ->
                            val variant = state.selectedVariant
                            mCheckboxWithLabelPadding(
                                label = hf.readableName,
                                checked = hf in (state.variantFeatureMap[variant] ?: listOf()),
                                paddingCheckbox = "4px",
                                onChange = { _, checked ->
                                    setState {
                                        variant?.let { sv ->
                                            if (checked)
                                                variantFeatureMap[sv] =
                                                    variantFeatureMap[sv]!!.plusElement(hf).distinct()
                                            else
                                                variantFeatureMap[sv] =
                                                    variantFeatureMap[sv]!!.filter { hf != it }
                                        }
                                        saveOK = false
                                    }
                                }
                            ) {
                                css {
                                    width = 100.pct
                                    padding(0.px, 2.spacingUnits)
                                }
                            }
                        }
                    }
                }
                mCardContent {
                    css(GlobalStyles.cardContent)
                    css {
                        width = 50.pct
                    }
                    mListSubheader(heading = "Verbaute Baugruppen")
                    mDivider {}
                    styledDiv {
                        css { padding(1.spacingUnits) }
                        state.assemblyList.filter { !it.isCategory }.forEach { ass -> // hehe
                            val variant = state.selectedVariant
                            mCheckboxWithLabelPadding(
                                label = ass.name,
                                checked = state.variantAssemblyMap[variant]?.contains(ass) ?: false,
                                paddingCheckbox = "4px",
                                onChange = { _, checked ->
                                    setState {
                                        variant?.let { sv ->
                                            if (checked)
                                                variantAssemblyMap[sv] =
                                                    (variantAssemblyMap[sv] ?: listOf()).plusElement(ass).distinct()
                                            else
                                                variantAssemblyMap[sv] =
                                                    (variantAssemblyMap[sv] ?: listOf()).filter { ass != it }
                                        }
                                        saveOK = false
                                    }
                                }
                            ) {
                                css {
                                    width = 100.pct
                                    padding(0.px, 2.spacingUnits)
                                }
                            }
                        }
                    }
                }
                mDivider {}
                mCardActions {
                    css { padding(2.spacingUnits) }
                    mButton(
                        caption = "Übernehmen",
                        color = MColor.secondary,
                        variant = MButtonVariant.contained,
                        onClick = {
                            saveVariantsAndFeatures()
                        }
                    ) {
                        attrs.disableElevation = true
                    }
                    if (state.saveOK)
                        mIconNoTranslate("check") {
                            css {
                                color = Color.green
                            }
                        }
                }
            }
        }
    }

    private fun RBuilder.detailProductAssemblies() = mGridContainer2(direction = MGridDirection.row) {
        mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
            mCard {
                css(GlobalStyles.card)

                mCardContent {
                    css(GlobalStyles.cardBoxContent)
                    mListSubheader(heading = "Baugruppen")
                    mDivider { }
                    styledDiv {
                        css { padding(2.spacingUnits) }
                        mTextField(
                            label = "Name",
                            fullWidth = true,
                            margin = MFormControlMargin.dense,
                            value = state.selectedAssembly?.name ?: "Neue Baugruppe",
                            variant = MFormControlVariant.outlined,
                            onChange = { event ->
                                val value = event.targetInputValue
                                setState {
                                    selectedAssembly?.let {
                                        selectedAssembly = it.copy(name = value)
                                    }
                                    saveOK = false
                                }
                            }
                        )
                        mTextField(
                            label = "Materialnummer",
                            fullWidth = true,
                            margin = MFormControlMargin.dense,
                            value = state.selectedAssembly?.let { it.materialNumber.toString().padStart(6, '0') }
                                ?: "000000",
                            variant = MFormControlVariant.outlined,
                            onChange = { event ->
                                val value = try {
                                    event.targetInputValue.toInt()
                                } catch (e: Exception) {
                                    0
                                }
                                setState {
                                    selectedAssembly?.let {
                                        selectedAssembly = it.copy(materialNumber = value)
                                    }
                                    saveOK = false
                                }
                            }
                        )
                        mTextField(
                            label = "Revision",
                            fullWidth = true,
                            margin = MFormControlMargin.dense,
                            value = state.selectedAssembly?.revision ?: "A0",
                            variant = MFormControlVariant.outlined,
                            onChange = { event ->
                                val value = event.targetInputValue
                                setState {
                                    selectedAssembly?.let {
                                        selectedAssembly = it.copy(revision = value)
                                    }
                                    saveOK = false
                                }
                            }
                        )
                        mSelect(
                            value = state.selectedAssembly?.lifecycle ?: AssemblyLifecycle.IN_PRODUCTION,
                            name = "lifecycle",
                            id = "lifecycle",
                            fullWidth = true,
                            variant = MFormControlVariant.outlined,
                            onChange = { event, _ ->
                                val id = event.targetValue as String
                                setState {
                                    selectedAssembly?.let {
                                        selectedAssembly = it.copy(lifecycle = AssemblyLifecycle.valueOf(id))
                                    }
                                    saveOK = false
                                }
                            }
                        ) {
                            attrs.required = true
                            attrs.margin = MFormControlMargin.dense.toString()

                            for (element in AssemblyLifecycle.values()) {
                                mMenuItem(primaryText = element.readableName, value = element.name)
                            }
                        }
                        mCheckboxWithLabelPadding(
                            label = "Ist Kategorie",
                            checked = state.selectedAssembly?.isCategory ?: false,
                            paddingCheckbox = "4px",
                            onChange = { _, checked ->
                                setState {
                                    selectedAssembly?.let {
                                        selectedAssembly = it.copy(isCategory = checked)
                                    }
                                    saveOK = false
                                }
                            }
                        ) {
                            css {
                                width = 100.pct
                                padding(0.px, 2.spacingUnits)
                            }
                        }
                        mDivider {}
                        mCardActions {
                            css { padding(2.spacingUnits) }
                            mButton(
                                caption = "Übernehmen",
                                color = MColor.secondary,
                                variant = MButtonVariant.contained,
                                onClick = {
                                    state.selectedAssembly?.let {
                                        saveAssembly(it)
                                    }
                                }
                            ) {
                                attrs.disableElevation = true
                            }
                            if (state.saveOK)
                                mIconNoTranslate("check") {
                                    css {
                                        color = Color.green
                                    }
                                }
                        }
                    }
                }
            }
        }
    }
}