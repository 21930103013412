package de.geomobile.frontend.utils.grid

import com.ccfraser.muirwik.components.MColor
import com.ccfraser.muirwik.components.mIconButtonNoTranslate
import com.ccfraser.muirwik.components.mTooltip
import react.RBuilder
import react.RComponent
import react.RProps
import react.RState

fun RBuilder.toolbarFilterToggle(
    active: Boolean,
    onToggle: () -> Unit
) = child(ToolbarFilterToggle::class) {
    attrs.active = active
    attrs.onToggle = onToggle
}

class ToolbarFilterToggle : RComponent<ToolbarFilterToggle.Props, RState>() {

    interface Props : RProps {
        var active: Boolean
        var onToggle: () -> Unit
    }

    override fun RBuilder.render() {
        plugin(
            name = "ToolbarFilterToggle"
        ) {
            template(
                name = "toolbarContent"
            ) {
                templatePlaceholder()
                mTooltip("Filter", enterDelay = 300) {
                    mIconButtonNoTranslate(
                        iconName = "filter_list",
                        color = if (props.active) MColor.secondary else MColor.inherit,
                        onClick = { props.onToggle() }
                    )
                }
            }
        }
    }
}