package de.geomobile.frontend.features.device.detail

import com.ccfraser.muirwik.components.MIconProps
import de.geomobile.common.portalmodels.TimestampStatus
import de.geomobile.frontend.utils.IntervalComponent
import de.geomobile.frontend.utils.color
import de.geomobile.frontend.utils.mIconNoTranslate
import kotlinx.css.*
import react.RBuilder
import react.RProps
import react.RState
import react.setState
import styled.StyledHandler
import styled.css
import styled.styledDiv

fun RBuilder.deviceStatusLed(
    interval: Long,
    status: TimestampStatus,
    outline: Color? = null,
) = child(DeviceStatusLedStatic::class) {
    attrs.interval = interval
    attrs.status = status
    attrs.outline = outline
}

fun RBuilder.deviceStatusLed(
    status: TimestampStatus.Status,
    outline: Color? = null
) {
    styledDiv {
        css { display = Display.flex }

        led(status, outline) {
            css { position = Position.relative; backgroundColor = Color.white; borderRadius = 100.pct }
        }
    }
}

private fun RBuilder.led(
    status: TimestampStatus.Status,
    outline: Color?,
    handler: StyledHandler<MIconProps>? = null,
) {
    mIconNoTranslate(
        when {
            status == TimestampStatus.Status.UNKNOWN && outline != null -> "help"
            status == TimestampStatus.Status.UNKNOWN -> "help_outline"
            else -> "brightness_1"
        }
    ) {
        css { color = status.color }

        if (handler != null) {
            handler()
        }
    }
}

class DeviceStatusLedStatic(props: Props) :
    IntervalComponent<DeviceStatusLedStatic.Props, DeviceStatusLedStatic.State>(props) {

    interface Props : RProps {
        var interval: Long
        var status: TimestampStatus
        var outline: Color?
    }

    class State(
        var status: TimestampStatus.Status,
    ) : RState

    override val interval: Long
        get() = props.interval


    init {
        state = State(status = props.status.status)
    }

    override fun onTick() {
        val status = props.status.status
        if (state.status != status)
            setState { }
    }

    override fun RBuilder.render() {
        deviceStatusLed(status = props.status.status, outline = props.outline)
    }
}