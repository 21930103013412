package de.geomobile.frontend.utils.grid

import com.ccfraser.muirwik.components.createStyled
import de.geomobile.common.feature.Feature
import de.geomobile.common.permission.Permission
import kotlinx.css.CssValue
import kotlinx.css.LinearDimension
import react.RBuilder
import react.RClass
import react.RComponent
import react.RState
import styled.StyledProps
import styled.css

@JsModule("@devexpress/dx-react-grid")
private external val dx_react_grid: dynamic

// TODO: Clean up
//external interface ChangeSet {
//    var added: Array<Any>?
//    var changed: Json?
//    var deleted: Array<dynamic>?
//}

data class Column(
    val name: String,
    val title: String?,
    val permissions: List<Permission> = emptyList(),
    val feature: Feature? = null,
    val getCellValue: ((row: Any, columnName: String) -> Any)? = null,
)

//external interface ColumnBands {
//    var children: Array<ColumnBands>?
//    var columnName: String?
//    var title: String?
//}

//external var ColumnChooser: React.ComponentType<ColumnChooserProps> = definedExternally

//external interface ColumnChooserItem {
//    var column: Column
//    var hidden: Boolean
//}
//external interface ColumnChooserProps {
//    var containerComponent: React.ComponentType<ColumnChooser.ContainerProps>
//    var itemComponent: React.ComponentType<ColumnChooser.ItemProps>
//    var messages: ColumnChooser.LocalizationMessages? get() = definedExternally; set(value) = definedExternally
//    var overlayComponent: React.ComponentType<ColumnChooser.OverlayProps>
//    var toggleButtonComponent: React.ComponentType<ColumnChooser.ToggleButtonProps>
//}
//external interface `T$0` {
//    var getRows: (skip: Number, count: Number) -> Array<Any>
//    var setRows: (skip: Number, rows: ReadonlyArray<Any>) -> Unit
//    var invalidate: () -> Unit
//}
//external var createRowCache: (pageSize: Number, capacity: Number? /*= null*/) -> `T$0` = definedExternally
//external var CustomGrouping: React.ComponentType<CustomGroupingProps> = definedExternally
//external interface `T$1` {
//    var key: dynamic /* String | Number */
//    var value: Any? get() = definedExternally; set(value) = definedExternally
//    var childRows: Array<Any>? get() = definedExternally; set(value) = definedExternally
//}
//external interface CustomGroupingProps {
//    var expandedGroups: Array<String>? get() = definedExternally; set(value) = definedExternally
//    var getChildGroups: (currentRows: Array<Any>, grouping: Grouping, rootRows: Array<Any>) -> Array<`T$1`>
//    var grouping: Array<Grouping>? get() = definedExternally; set(value) = definedExternally
//}
//external var CustomPaging: React.ComponentType<CustomPagingProps> = definedExternally
//external interface CustomPagingProps {
//    var totalCount: Number? get() = definedExternally; set(value) = definedExternally
//}
//external var CustomSummary: React.ComponentType<CustomSummaryProps> = definedExternally
//external interface `T$2` {
//    @nativeGetter
//    operator fun get(key: String): Array<Any>?
//    @nativeSetter
//    operator fun set(key: String, value: Array<Any>)
//}
//external interface CustomSummaryProps {
//    var groupValues: `T$2`? get() = definedExternally; set(value) = definedExternally
//    var totalValues: Array<Any>? get() = definedExternally; set(value) = definedExternally
//    var treeValues: `T$2`? get() = definedExternally; set(value) = definedExternally
//}
//external var CustomTreeData: React.ComponentType<CustomTreeDataProps> = definedExternally
//external interface CustomTreeDataProps {
//    var getChildRows: (currentRow: Any?, rootRows: Array<Any>) -> Array<Any>?
//}
//external var DataTypeProvider: React.ComponentType<DataTypeProviderProps> = definedExternally


@Suppress("UnsafeCastFromDynamic")
private val dataTypeProviderComponent: RComponent<DataTypeProviderProps, RState> = dx_react_grid.DataTypeProvider

fun RBuilder.dataTypeProvider(
    columns: List<String>,
    formatterComponent: RClass<ValueFormatterProps>,
) = createStyled(dataTypeProviderComponent) {
    attrs.`for` = columns.toTypedArray()
    attrs.formatterComponent = formatterComponent
}


interface DataTypeProviderProps : StyledProps {
    //    var availableFilterOperations: Array<String>? get() = definedExternally; set(value) = definedExternally
//    var editorComponent: React.ComponentType<DataTypeProvider.ValueEditorProps>? get() = definedExternally; set(value) = definedExternally
    var `for`: Array<String>
    var formatterComponent: RClass<ValueFormatterProps>
}

interface ValueFormatterProps : StyledProps {
    var column: Column
    var row: Any?
    var value: Any
}
//external var DragDropProvider: React.ComponentType<DragDropProviderProps> = definedExternally
//external interface DragDropProviderProps {
//    var columnComponent: React.ComponentType<DragDropProvider.ColumnProps>
//    var containerComponent: React.ComponentType<DragDropProvider.ContainerProps>
//}
//external interface EditingColumnExtension {
//    var columnName: String
//    var createRowChange: ((row: Any, value: Any, columnName: String) -> Any)? get() = definedExternally; set(value) = definedExternally
//    var editingEnabled: Boolean? get() = definedExternally; set(value) = definedExternally
//}
//external var EditingState: React.ComponentType<EditingStateProps> = definedExternally
//external interface EditingStateProps {
//    var addedRows: Array<Any>? get() = definedExternally; set(value) = definedExternally
//    var columnEditingEnabled: Boolean? get() = definedExternally; set(value) = definedExternally
//    var columnExtensions: Array<EditingState.ColumnExtension>? get() = definedExternally; set(value) = definedExternally
//    var createRowChange: ((row: Any, value: dynamic /* String | Number */, columnName: String) -> Any)? get() = definedExternally; set(value) = definedExternally
//    var defaultAddedRows: Array<Any>? get() = definedExternally; set(value) = definedExternally
//    var defaultEditingRowIds: Array<dynamic /* String | Number */>? get() = definedExternally; set(value) = definedExternally
//    var defaultRowChanges: Json? get() = definedExternally; set(value) = definedExternally
//    var editingRowIds: Array<dynamic /* String | Number */>? get() = definedExternally; set(value) = definedExternally
//    var onAddedRowsChange: ((addedRows: Array<Any>) -> Unit)? get() = definedExternally; set(value) = definedExternally
//    var onCommitChanges: (changes: ChangeSet) -> Unit
//    var onDeletedRowIdsChange: ((deletedRowIds: Array<dynamic /* String | Number */>) -> Unit)? get() = definedExternally; set(value) = definedExternally
//    var onEditingRowIdsChange: ((editingRowIds: Array<dynamic /* String | Number */>) -> Unit)? get() = definedExternally; set(value) = definedExternally
//    var onRowChangesChange: ((rowChanges: Json) -> Unit)? get() = definedExternally; set(value) = definedExternally
//    var rowChanges: Json? get() = definedExternally; set(value) = definedExternally
//}
//external interface Filter {
//    var columnName: String
//    var operation: String? get() = definedExternally; set(value) = definedExternally
//    var value: String? get() = definedExternally; set(value) = definedExternally
//}
//external interface FilterExpression {
//    var filters: Array<dynamic /* FilterExpression | Filter */>
//    var operator: dynamic /* String /* "and" */ | String /* "or" */ */
//}
//external var FilteringState: React.ComponentType<FilteringStateProps> = definedExternally

@Suppress("UnsafeCastFromDynamic")
private val filteringStateComponent: RComponent<FilteringStateProps, RState> = dx_react_grid.FilteringState

fun RBuilder.filteringState(
    filters: Array<Filter>? = null,
    onFiltersChange: ((filters: Array<Filter>) -> Unit)? = null,
) = createStyled(filteringStateComponent) {
    if (filters != null) attrs.filters = filters
    if (onFiltersChange != null) attrs.onFiltersChange = onFiltersChange
}

interface FilteringStateProps : StyledProps {
    //    var columnExtensions: Array<FilteringState.ColumnExtension>? get() = definedExternally; set(value) = definedExternally
//    var columnFilteringEnabled: Boolean?
//    var defaultFilters: Array<Filter>? get() = definedExternally; set(value) = definedExternally
    var filters: Array<Filter>?
    var onFiltersChange: ((filters: Array<Filter>) -> Unit)?
}

//external var Grid: React.ComponentType<GridProps> = definedExternally
//external interface GridProps {
//    var columns: Array<Column>
//    var getCellValue: ((row: Any, columnName: String) -> Any)? get() = definedExternally; set(value) = definedExternally
//    var getRowId: ((row: Any) -> dynamic /* String | Number */)? get() = definedExternally; set(value) = definedExternally
//    var rootComponent: React.ComponentType<Grid.RootProps>
//    var rows: Array<Any>
//}
data class Grouping(
    var columnName: String,
)
//external var GroupingPanel: React.ComponentType<GroupingPanelProps> = definedExternally
//external interface GroupingPanelItem {
//    var column: Column
//    var draft: Boolean? get() = definedExternally; set(value) = definedExternally
//}
//external interface GroupingPanelProps {
//    var containerComponent: React.ComponentType<GroupingPanel.ContainerProps>
//    var emptyMessageComponent: React.ComponentType<GroupingPanel.EmptyMessageProps>
//    var itemComponent: React.ComponentType<GroupingPanel.ItemProps>
//    var messages: GroupingPanel.LocalizationMessages? get() = definedExternally; set(value) = definedExternally
//    var showGroupingControls: Boolean? get() = definedExternally; set(value) = definedExternally
//    var showSortingControls: Boolean? get() = definedExternally; set(value) = definedExternally
//}
//external var GroupingState: React.ComponentType<GroupingStateProps> = definedExternally

@Suppress("UnsafeCastFromDynamic")
private val groupingStateComponent: RComponent<GroupingStateProps, RState> = dx_react_grid.GroupingState

fun RBuilder.groupingState(
    defaultExpandedGroups: Array<String>? = null,
    defaultGrouping: Array<Grouping>? = null,
    expandedGroups: Array<String>? = null,
    grouping: Array<Grouping>? = null,
    onExpandedGroupsChange: ((expandedGroups: Array<String>) -> Unit)? = null,
    onGroupingChange: ((grouping: Array<Grouping>) -> Unit)? = null,
) = createStyled(groupingStateComponent) {
    css {
        setCustomProperty("order", LinearDimension("-2"))
    }
    if (defaultExpandedGroups != null) attrs.defaultExpandedGroups = defaultExpandedGroups
    if (defaultGrouping != null) attrs.defaultGrouping = defaultGrouping
    if (expandedGroups != null) attrs.expandedGroups = expandedGroups
    if (grouping != null) attrs.grouping = grouping
    if (onExpandedGroupsChange != null) attrs.onExpandedGroupsChange = onExpandedGroupsChange
    if (onGroupingChange != null) attrs.onGroupingChange = onGroupingChange
}

interface GroupingStateProps : StyledProps {
    //    var columnExtensions: Array<GroupingState.ColumnExtension>? get() = definedExternally; set(value) = definedExternally
//    var columnGroupingEnabled: Boolean? get() = definedExternally; set(value) = definedExternally
    var defaultExpandedGroups: Array<String>?
    var defaultGrouping: Array<Grouping>?
    var expandedGroups: Array<String>?
    var grouping: Array<Grouping>?
    var onExpandedGroupsChange: ((expandedGroups: Array<String>) -> Unit)?
    var onGroupingChange: ((grouping: Array<Grouping>) -> Unit)?
}

//external interface GroupRow {
//    var key: dynamic /* String | Number */
//    var value: Any
//}
//external interface `T$5` {
//    var defaultPredicate: (value: Any, filter: Filter, row: Any) -> Boolean
//}
//external var IntegratedFiltering: React.ComponentType<IntegratedFilteringProps> /* React.ComponentType<IntegratedFilteringProps> & `T$5` */ = definedExternally

@Suppress("UnsafeCastFromDynamic")
private val integratedFilteringComponent: RComponent<IntegratedFilteringProps, RState> =
    dx_react_grid.IntegratedFiltering

fun RBuilder.integratedFiltering(
    columnExtensions: List<IntegratedFilteringProps.ColumnExtension>? = null,
) = createStyled(integratedFilteringComponent) {
    if (columnExtensions != null) attrs.columnExtensions = columnExtensions?.toTypedArray()
}

interface IntegratedFilteringProps : StyledProps {
    var columnExtensions: Array<ColumnExtension>?

    data class ColumnExtension(
        val columnName: String,
        val predicate: (value: Any, filter: Filter, row: Any) -> Boolean,
    )
}


data class Filter(
    val columnName: String,
    val operation: String?,
    val value: String?,
)

//external var IntegratedGrouping: React.ComponentType<IntegratedGroupingProps> = definedExternally

@Suppress("UnsafeCastFromDynamic")
private val integratedGroupingComponent: RComponent<IntegratedGroupingProps, RState> = dx_react_grid.IntegratedGrouping

fun RBuilder.integratedGrouping() = createStyled(integratedGroupingComponent) {
    css {
        setCustomProperty("order", LinearDimension("-2"))
    }
}

interface IntegratedGroupingProps : StyledProps {
//    var columnExtensions: Array<IntegratedGrouping.ColumnExtension>? get() = definedExternally; set(value) = definedExternally
}
//external var IntegratedPaging: React.ComponentType<IntegratedPagingProps> = definedExternally

@Suppress("UnsafeCastFromDynamic")
private val integratedPagingComponent: RComponent<IntegratedPagingProps, RState> = dx_react_grid.IntegratedPaging

fun RBuilder.integratedPaging() = createStyled(integratedPagingComponent) {}

interface IntegratedPagingProps : StyledProps
//external var IntegratedSelection: React.ComponentType<IntegratedSelectionProps> = definedExternally

@Suppress("UnsafeCastFromDynamic")
private val integratedSelectionComponent: RComponent<IntegratedSelectionProps, RState> =
    dx_react_grid.IntegratedSelection

fun RBuilder.integratedSelection() = createStyled(integratedSelectionComponent) {}

interface IntegratedSelectionProps : StyledProps
//external var IntegratedSorting: React.ComponentType<IntegratedSortingProps> = definedExternally

@Suppress("UnsafeCastFromDynamic")
private val integratedSortingComponent: RComponent<IntegratedSortingProps, RState> = dx_react_grid.IntegratedSorting

fun RBuilder.integratedSorting(columnExtensions: List<IntegratedSortingProps.ColumnExtension>? = null) =
    createStyled(integratedSortingComponent) {
        if (columnExtensions != null) attrs.columnExtensions = columnExtensions.toTypedArray()
    }

interface IntegratedSortingProps : StyledProps {
    var columnExtensions: Array<ColumnExtension>?

    data class ColumnExtension(
        val columnName: String,
        val compare: ((a: Any, b: Any) -> Int)?,
    )
}

//external interface `T$7` {
//    var defaultCalculator: (type: String, rows: Array<Any>, getValue: (row: Any) -> Any) -> Any
//}
//external var IntegratedSummary: React.ComponentType<IntegratedSummaryProps> /* React.ComponentType<IntegratedSummaryProps> & `T$7` */ = definedExternally
//external interface IntegratedSummaryProps {
//    var calculator: ((type: String, rows: Array<Any>, getValue: (row: Any) -> Any) -> Any)? get() = definedExternally; set(value) = definedExternally
//}
//external var PagingPanel: React.ComponentType<PagingPanelProps> = definedExternally
//external interface PagingPanelProps {
//    var containerComponent: React.ComponentType<PagingPanel.ContainerProps>
//    var messages: PagingPanel.LocalizationMessages? get() = definedExternally; set(value) = definedExternally
//    var pageSizes: Array<Number>? get() = definedExternally; set(value) = definedExternally
//}
//external var PagingState: React.ComponentType<PagingStateProps> = definedExternally


@Suppress("UnsafeCastFromDynamic")
private val pagingStateComponent: RComponent<PagingStateProps, RState> = dx_react_grid.PagingState

fun RBuilder.pagingState(
    defaultPageSize: Int? = null,
    onPageSizeChange: ((pageSize: Int) -> Unit)? = null,
    pageSize: Int? = null,
    currentPage: Int? = null,
    onCurrentPageChange: ((currentPage: Int) -> Unit)? = null,
) = createStyled(pagingStateComponent) {
    attrs.defaultPageSize = defaultPageSize
    attrs.onPageSizeChange = onPageSizeChange
    attrs.pageSize = pageSize
    attrs.currentPage = currentPage
    attrs.onCurrentPageChange = onCurrentPageChange
}

interface PagingStateProps : StyledProps {
    //    var defaultCurrentPage: Number? get() = definedExternally; set(value) = definedExternally
    var currentPage: Int?
    var onCurrentPageChange: ((currentPage: Int) -> Unit)?
    var defaultPageSize: Int?
    var onPageSizeChange: ((pageSize: Int) -> Unit)?
    var pageSize: Int?
}
//external var RowDetailState: React.ComponentType<RowDetailStateProps> = definedExternally
//external interface RowDetailStateProps {
//    var defaultExpandedRowIds: Array<dynamic /* String | Number */>? get() = definedExternally; set(value) = definedExternally
//    var expandedRowIds: Array<dynamic /* String | Number */>? get() = definedExternally; set(value) = definedExternally
//    var onExpandedRowIdsChange: ((expandedRowIds: Array<dynamic /* String | Number */>) -> Unit)? get() = definedExternally; set(value) = definedExternally
//}
//external var SearchPanel: React.ComponentType<SearchPanelProps> = definedExternally
//external interface SearchPanelProps {
//    var inputComponent: React.ComponentType<SearchPanel.InputProps>
//    var messages: SearchPanel.LocalizationMessages? get() = definedExternally; set(value) = definedExternally
//}
//external var SearchState: React.ComponentType<SearchStateProps> = definedExternally

@Suppress("UnsafeCastFromDynamic")
private val searchStateComponent: RComponent<SearchStateProps, RState> = dx_react_grid.SearchState

fun RBuilder.searchState(
    defaultValue: String? = null,
    onValueChange: ((value: String) -> Unit)? = null,
    value: String? = null,
) = createStyled(searchStateComponent) {
    if (defaultValue != null) attrs.defaultValue = defaultValue
    if (onValueChange != null) attrs.onValueChange = onValueChange
    if (value != null) attrs.value = value
}

interface SearchStateProps : StyledProps {
    var defaultValue: String?
    var onValueChange: ((value: String) -> Unit)?
    var value: String?
}
//external var SelectionState: React.ComponentType<SelectionStateProps> = definedExternally

@Suppress("UnsafeCastFromDynamic")
private val selectionStateComponent: RComponent<SelectionStateProps, RState> = dx_react_grid.SelectionState

fun RBuilder.selectionState(
    onSelectionChange: ((selection: Array<Int>) -> Unit)? = null,
    selection: List<Int>? = null,
) = createStyled(selectionStateComponent) {
    attrs.onSelectionChange = onSelectionChange
    attrs.selection = selection?.toTypedArray()
}

interface SelectionStateProps : StyledProps {
    //    var defaultSelection: Array<dynamic /* String | Number */>? get() = definedExternally; set(value) = definedExternally
    var onSelectionChange: ((selection: Array<Int>) -> Unit)?
    var selection: Array<Int>?
}

data class Sorting(
    val columnName: String,
    val direction: String,
)
//external var SortingState: React.ComponentType<SortingStateProps> = definedExternally


@Suppress("UnsafeCastFromDynamic")
private val sortingStateComponent: RComponent<SortingStateProps, RState> = dx_react_grid.SortingState

fun RBuilder.sortingState(
    defaultSorting: Sorting? = null,
    onSortingChange: ((sorting: Array<Sorting>) -> Unit)? = null,
    sorting: Array<Sorting>? = null,
) = createStyled(sortingStateComponent) {
    if (defaultSorting != null) attrs.defaultSorting = arrayOf(defaultSorting)
    if (sorting != null) attrs.sorting = sorting
    if (onSortingChange != null) attrs.onSortingChange = onSortingChange
}

interface SortingStateProps : StyledProps {
    //    var columnExtensions: Array<SortingState.ColumnExtension>? get() = definedExternally; set(value) = definedExternally
//    var columnSortingEnabled: Boolean? get() = definedExternally; set(value) = definedExternally
    var defaultSorting: Array<Sorting>?
    var onSortingChange: ((sorting: Array<Sorting>) -> Unit)?
    var sorting: Array<Sorting>?
}

//external interface SummaryItem {
//    var columnName: String
//    var type: String
//}
//external var SummaryState: React.ComponentType<SummaryStateProps> = definedExternally
//external interface SummaryStateProps {
//    var groupItems: Array<SummaryItem>? get() = definedExternally; set(value) = definedExternally
//    var totalItems: Array<SummaryItem>? get() = definedExternally; set(value) = definedExternally
//    var treeItems: Array<SummaryItem>? get() = definedExternally; set(value) = definedExternally
//}
//external interface `T$9` {
//    var COLUMN_TYPE: Any?
//    var ROW_TYPE: Any?
//    var NODATA_ROW_TYPE: Any?
//}
//external var Table: React.ComponentType<TableProps> /* React.ComponentType<TableProps> & `T$9` */ = definedExternally
//external interface `T$10` {
//    var ROW_TYPE: Any?
//}
//external var TableBandHeader: React.ComponentType<TableBandHeaderProps> /* React.ComponentType<TableBandHeaderProps> & `T$10` */ = definedExternally
//external interface TableBandHeaderProps {
//    var cellComponent: React.ComponentType<TableBandHeader.CellProps>
//    var columnBands: Array<TableBandHeader.ColumnBands>
//    var rowComponent: React.ComponentType<Table.RowProps>
//}
//external interface TableColumn {
//    var align: dynamic /* String /* "left" */ | String /* "right" */ | String /* "center" */ */ get() = definedExternally; set(value) = definedExternally
//    var column: Column? get() = definedExternally; set(value) = definedExternally
//    var fixed: dynamic /* String /* "left" */ | String /* "right" */ */ get() = definedExternally; set(value) = definedExternally
//    var key: String
//    var type: Any?
//    var width: Number? get() = definedExternally; set(value) = definedExternally
//}
//external var TableColumnReordering: React.ComponentType<TableColumnReorderingProps> = definedExternally
//external interface TableColumnReorderingProps {
//    var defaultOrder: Array<String>? get() = definedExternally; set(value) = definedExternally
//    var onOrderChange: ((nextOrder: Array<String>) -> Unit)? get() = definedExternally; set(value) = definedExternally
//    var order: Array<String>? get() = definedExternally; set(value) = definedExternally
//}
//external var TableColumnResizing: React.ComponentType<TableColumnResizingProps> = definedExternally
//external interface TableColumnResizingProps {
//    var columnWidths: Array<TableColumnWidthInfo>? get() = definedExternally; set(value) = definedExternally
//    var defaultColumnWidths: Array<TableColumnWidthInfo>? get() = definedExternally; set(value) = definedExternally
//    var minColumnWidth: Number? get() = definedExternally; set(value) = definedExternally
//    var onColumnWidthsChange: ((nextColumnWidths: Array<TableColumnWidthInfo>) -> Unit)? get() = definedExternally; set(value) = definedExternally
//}
//external var TableColumnVisibility: React.ComponentType<TableColumnVisibilityProps> = definedExternally
//external interface TableColumnVisibilityProps {
//    var columnExtensions: Array<TableColumnVisibility.ColumnExtension>? get() = definedExternally; set(value) = definedExternally
//    var columnTogglingEnabled: Boolean? get() = definedExternally; set(value) = definedExternally
//    var defaultHiddenColumnNames: Array<String>? get() = definedExternally; set(value) = definedExternally
//    var emptyMessageComponent: React.ComponentType<TableColumnVisibility.EmptyMessageProps>
//    var hiddenColumnNames: Array<String>? get() = definedExternally; set(value) = definedExternally
//    var messages: TableColumnVisibility.LocalizationMessages? get() = definedExternally; set(value) = definedExternally
//    var onHiddenColumnNamesChange: ((hiddenColumnNames: Array<String>) -> Unit)? get() = definedExternally; set(value) = definedExternally
//}
data class TableColumnWidthInfo(
    val columnName: String,
    val width: Int,
)

//external interface `T$11` {
//    var COLUMN_TYPE: Any?
//}
//external var TableEditColumn: React.ComponentType<TableEditColumnProps> /* React.ComponentType<TableEditColumnProps> & `T$11` */ = definedExternally
//external interface TableEditColumnProps {
//    var cellComponent: React.ComponentType<TableEditColumn.CellProps>
//    var commandComponent: React.ComponentType<TableEditColumn.CommandProps>
//    var headerCellComponent: React.ComponentType<TableEditColumn.HeaderCellProps>
//    var messages: TableEditColumn.LocalizationMessages? get() = definedExternally; set(value) = definedExternally
//    var showAddCommand: Boolean? get() = definedExternally; set(value) = definedExternally
//    var showDeleteCommand: Boolean? get() = definedExternally; set(value) = definedExternally
//    var showEditCommand: Boolean? get() = definedExternally; set(value) = definedExternally
//    var width: dynamic /* String | Number */ get() = definedExternally; set(value) = definedExternally
//}
//external interface `T$12` {
//    var ADDED_ROW_TYPE: Any?
//    var EDIT_ROW_TYPE: Any?
//}
//external var TableEditRow: React.ComponentType<TableEditRowProps> /* React.ComponentType<TableEditRowProps> & `T$12` */ = definedExternally
//external interface TableEditRowProps {
//    var cellComponent: React.ComponentType<TableEditRow.CellProps>
//    var rowComponent: React.ComponentType<TableEditRow.RowProps>
//    var rowHeight: Number? get() = definedExternally; set(value) = definedExternally
//}
//external var TableFilterRow: React.ComponentType<TableFilterRowProps> /* React.ComponentType<TableFilterRowProps> & `T$10` */ = definedExternally
//external interface TableFilterRowProps {
//    var cellComponent: React.ComponentType<TableFilterRow.CellProps>
//    var editorComponent: React.ComponentType<TableFilterRow.EditorProps>
//    var filterSelectorComponent: React.ComponentType<TableFilterRow.FilterSelectorProps>
//    var iconComponent: React.ComponentType<TableFilterRow.IconProps>
//    var messages: TableFilterRow.LocalizationMessages? get() = definedExternally; set(value) = definedExternally
//    var rowComponent: React.ComponentType<Table.RowProps>
//    var rowHeight: Number? get() = definedExternally; set(value) = definedExternally
//    var showFilterSelector: Boolean? get() = definedExternally; set(value) = definedExternally
//    var toggleButtonComponent: React.ComponentType<TableFilterRow.ToggleButtonProps>
//}
//external var TableFixedColumns: React.ComponentType<TableFixedColumnsProps> = definedExternally
//external interface TableFixedColumnsProps {
//    var cellComponent: React.ComponentType<TableFixedColumns.CellProps>
//    var leftColumns: Array<dynamic /* String | Any? */>? get() = definedExternally; set(value) = definedExternally
//    var rightColumns: Array<dynamic /* String | Any? */>? get() = definedExternally; set(value) = definedExternally
//}
//external interface `T$13` {
//    var COLUMN_TYPE: Any?
//    var ROW_TYPE: Any?
//}
//external var TableGroupRow: React.ComponentType<TableGroupRowProps> /* React.ComponentType<TableGroupRowProps> & `T$13` */ = definedExternally
//external interface TableGroupRowProps {
//    var cellComponent: React.ComponentType<TableGroupRow.CellProps>
//    var columnExtensions: Array<TableGroupRow.ColumnExtension>? get() = definedExternally; set(value) = definedExternally
//    var contentComponent: React.ComponentType<TableGroupRow.ContentProps>
//    var iconComponent: React.ComponentType<TableGroupRow.IconProps>
//    var indentCellComponent: React.ComponentType<TableGroupRow.IndentCellProps>? get() = definedExternally; set(value) = definedExternally
//    var indentColumnWidth: Number
//    var rowComponent: React.ComponentType<TableGroupRow.RowProps>
//    var showColumnsWhenGrouped: Boolean? get() = definedExternally; set(value) = definedExternally
//}
//external var TableHeaderRow: React.ComponentType<TableHeaderRowProps> /* React.ComponentType<TableHeaderRowProps> & `T$10` */ = definedExternally
//external interface TableHeaderRowProps {
//    var cellComponent: React.ComponentType<TableHeaderRow.CellProps>
//    var contentComponent: React.ComponentType<TableHeaderRow.ContentProps>
//    var groupButtonComponent: React.ComponentType<TableHeaderRow.GroupButtonProps>
//    var messages: TableHeaderRow.LocalizationMessages? get() = definedExternally; set(value) = definedExternally
//    var rowComponent: React.ComponentType<Table.RowProps>
//    var showGroupingControls: Boolean? get() = definedExternally; set(value) = definedExternally
//    var showSortingControls: Boolean? get() = definedExternally; set(value) = definedExternally
//    var sortLabelComponent: React.ComponentType<TableHeaderRow.SortLabelProps>
//    var titleComponent: React.ComponentType<Any>
//}
//external interface TableProps {
//    var bodyComponent: React.ComponentType<Any>
//    var cellComponent: React.ComponentType<Table.DataCellProps>
//    var columnExtensions: Array<Table.ColumnExtension>? get() = definedExternally; set(value) = definedExternally
//    var containerComponent: React.ComponentType<Any>
//    var footerComponent: React.ComponentType<Any>
//    var headComponent: React.ComponentType<Any>
//    var messages: Table.LocalizationMessages? get() = definedExternally; set(value) = definedExternally
//    var noDataCellComponent: React.ComponentType<Table.NoDataCellProps>
//    var noDataRowComponent: React.ComponentType<Table.RowProps>
//    var rowComponent: React.ComponentType<Table.DataRowProps>
//    var stubCellComponent: React.ComponentType<Table.CellProps>
//    var stubHeaderCellComponent: React.ComponentType<Table.CellProps>
//    var stubRowComponent: React.ComponentType<Table.RowProps>
//    var tableComponent: React.ComponentType<Table.InnerTableProps>
//}
interface TableRow {
    var height: Number?
    var key: String
    var row: Any?
    var rowId: Any?
    var type: Any?
}
//external var TableRowDetail: React.ComponentType<TableRowDetailProps> /* React.ComponentType<TableRowDetailProps> & `T$13` */ = definedExternally
//external interface TableRowDetailProps {
//    var cellComponent: React.ComponentType<TableRowDetail.CellProps>
//    var contentComponent: React.ComponentType<TableRowDetail.ContentProps>? get() = definedExternally; set(value) = definedExternally
//    var rowComponent: React.ComponentType<TableRowDetail.RowProps>
//    var rowHeight: Number? get() = definedExternally; set(value) = definedExternally
//    var toggleCellComponent: React.ComponentType<TableRowDetail.ToggleCellProps>
//    var toggleColumnWidth: Number
//}
//external var TableSelection: React.ComponentType<TableSelectionProps> /* React.ComponentType<TableSelectionProps> & `T$11` */ = definedExternally
//external interface TableSelectionProps {
//    var cellComponent: React.ComponentType<TableSelection.CellProps>
//    var headerCellComponent: React.ComponentType<TableSelection.HeaderCellProps>
//    var highlightRow: Boolean? get() = definedExternally; set(value) = definedExternally
//    var rowComponent: React.ComponentType<TableSelection.RowProps>
//    var selectByRowClick: Boolean? get() = definedExternally; set(value) = definedExternally
//    var selectionColumnWidth: Number
//    var showSelectAll: Boolean? get() = definedExternally; set(value) = definedExternally
//    var showSelectionColumn: Boolean? get() = definedExternally; set(value) = definedExternally
//}
//external interface `T$16` {
//    var TREE_ROW_TYPE: Any?
//    var GROUP_ROW_TYPE: Any?
//    var TOTAL_ROW_TYPE: Any?
//}
//external var TableSummaryRow: React.ComponentType<TableSummaryRowProps> /* React.ComponentType<TableSummaryRowProps> & `T$16` */ = definedExternally
//external interface TableSummaryRowProps {
//    var formatlessSummaryTypes: Array<String>
//    var groupCellComponent: React.ComponentType<TableSummaryRow.CellProps>
//    var groupRowComponent: React.ComponentType<Table.RowProps>
//    var itemComponent: React.ComponentType<TableSummaryRow.ItemProps>
//    var messages: TableSummaryRow.LocalizationMessages? get() = definedExternally; set(value) = definedExternally
//    var totalCellComponent: React.ComponentType<TableSummaryRow.CellProps>
//    var totalRowComponent: React.ComponentType<Table.RowProps>
//    var treeCellComponent: React.ComponentType<TableSummaryRow.CellProps>
//    var treeColumnCellComponent: React.ComponentType<TableSummaryRow.CellProps>
//    var treeColumnContentComponent: React.ComponentType<TableSummaryRow.ContentProps>
//    var treeColumnIndentComponent: React.ComponentType<TableSummaryRow.IndentProps>
//    var treeRowComponent: React.ComponentType<Table.RowProps>
//}
//external var TableTreeColumn: React.ComponentType<TableTreeColumnProps> = definedExternally
//external interface TableTreeColumnProps {
//    var cellComponent: React.ComponentType<TableTreeColumn.CellProps>
//    var checkboxComponent: React.ComponentType<TableTreeColumn.CheckboxProps>
//    var contentComponent: React.ComponentType<TableTreeColumn.ContentProps>
//    var expandButtonComponent: React.ComponentType<TableTreeColumn.ExpandButtonProps>
//    var `for`: String
//    var indentComponent: React.ComponentType<TableTreeColumn.IndentProps>
//    var showSelectAll: Boolean? get() = definedExternally; set(value) = definedExternally
//    var showSelectionControls: Boolean? get() = definedExternally; set(value) = definedExternally
//}
//external var Toolbar: React.ComponentType<ToolbarProps> = definedExternally
//external interface ToolbarProps {
//    var rootComponent: React.ComponentType<Toolbar.RootProps>
//}
//external var TreeDataState: React.ComponentType<TreeDataStateProps> = definedExternally
//external interface TreeDataStateProps {
//    var defaultExpandedRowIds: Array<dynamic /* String | Number */>? get() = definedExternally; set(value) = definedExternally
//    var expandedRowIds: Array<dynamic /* String | Number */>? get() = definedExternally; set(value) = definedExternally
//    var onExpandedRowIdsChange: ((expandedRowIds: Array<dynamic /* String | Number */>) -> Unit)? get() = definedExternally; set(value) = definedExternally
//}
//external interface TreeDataStateState {
//    var expandedRowIds: Array<dynamic /* String | Number */>
//}
//external var VirtualTable: React.ComponentType<VirtualTableProps> /* React.ComponentType<VirtualTableProps> & `T$9` */ = definedExternally
//external interface VirtualTableProps {
//    var bodyComponent: React.ComponentType<Any>
//    var cellComponent: React.ComponentType<Table.DataCellProps>
//    var columnExtensions: Array<Table.ColumnExtension>? get() = definedExternally; set(value) = definedExternally
//    var containerComponent: React.ComponentType<Any>
//    var estimatedRowHeight: Number
//    var footerComponent: React.ComponentType<Any>
//    var headComponent: React.ComponentType<Any>
//    var height: dynamic /* String | Number */
//    var messages: Table.LocalizationMessages? get() = definedExternally; set(value) = definedExternally
//    var noDataCellComponent: React.ComponentType<Table.NoDataCellProps>
//    var noDataRowComponent: React.ComponentType<Table.RowProps>
//    var rowComponent: React.ComponentType<Table.DataRowProps>
//    var skeletonCellComponent: React.ComponentType<Table.CellProps>
//    var stubCellComponent: React.ComponentType<Table.CellProps>
//    var stubHeaderCellComponent: React.ComponentType<Table.CellProps>
//    var stubRowComponent: React.ComponentType<Table.RowProps>
//    var tableComponent: React.ComponentType<Any>
//}
//external var VirtualTableState: React.ComponentType<VirtualTableStateProps> = definedExternally
//external interface VirtualTableStateProps {
//    var getRows: (skip: Number, take: Number) -> Unit
//    var infiniteScrolling: Boolean
//    var loading: Boolean
//    var pageSize: Number? get() = definedExternally; set(value) = definedExternally
//    var skip: Number
//    var totalRowCount: Number
//}
