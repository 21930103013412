package de.geomobile.frontend.api

import de.geomobile.common.errorhandling.ApiError
import de.geomobile.common.portalmodels.*
import de.geomobile.frontend.UserStore
import de.geomobile.frontend.utils.nextString
import kotlinx.browser.window
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.serialization.json.Json
import okio.ByteString
import org.khronos.webgl.ArrayBuffer
import org.khronos.webgl.Int8Array
import org.w3c.dom.ARRAYBUFFER
import org.w3c.dom.BinaryType
import org.w3c.dom.WebSocket
import kotlin.random.Random

class WebSocketApi(
    val url: String,
    val authTokenProvider: () -> String?,
) {

    private val socket: SocketWrapper

    val socketStateObservers get() = socket.socketStateObservers

    init {
        val resolvedUrl = if (!url.startsWith("ws")) {
            val loc = window.location
            var relativeBaseUrl: String = when {
                loc.protocol == "https:" -> "wss:"
                else -> "ws:"
            }
            relativeBaseUrl += "//" + loc.host
            relativeBaseUrl + url
        } else {
            url
        }

        socket = SocketWrapper(resolvedUrl)
    }

    fun subscribe(topic: String, parameter: Map<String, String> = emptyMap()): TopicSession {
        return RealTopicSession(topic, parameter, authTokenProvider, socket)
    }

}

enum class SocketState {
    CONNECTING,
    CONNECTED,
    DISCONNECTED
}

// WebSocket with reconnection logic
private class SocketWrapper(val url: String) {

    var socket: WebSocket? = null
    var session: Session? = null
    var retryNumber = -1
    val socketStateObservers: MutableList<(SocketState) -> Unit> = mutableListOf()
    val socketSessionObservers: MutableList<(Session) -> Unit> = mutableListOf()

    init {
        bind()
    }

    private fun broadcastState() {
        val state = when {
            retryNumber > 2 && socket?.readyState != 1.toShort() -> SocketState.DISCONNECTED
            retryNumber < 0 && socket?.readyState == 1.toShort() -> SocketState.CONNECTED
            else -> SocketState.CONNECTING
        }
        for (observer in socketStateObservers) {
            observer(state)
        }
    }

    fun bind() {
        session?.socketListeners?.clear()
        socket?.onopen = null
        socket?.onerror = null
        socket?.onclose = null
        socket?.onmessage = null

        retryNumber++
        broadcastState()

        val newSocket = WebSocket(url)
        socket = newSocket
        newSocket.binaryType = BinaryType.ARRAYBUFFER

        newSocket.onopen = {
            println("socket open")
            retryNumber = -1
            broadcastState()

            val session = Session(newSocket)
            this.session = session

            for (observer in socketSessionObservers) {
                observer(session)
            }

            Unit
        }
        newSocket.onclose = {
            GlobalScope.launch {
                println("try reconnect")
                delay(2000)
                bind()
            }
        }
    }

    class Session(private val socket: WebSocket) {
        val socketListeners: MutableList<(WebSocketMessage) -> Unit> = mutableListOf()

        init {
            socket.onmessage = {
                // TODO: Clean up
//                println(it.data as String)
//                val buffer = it.data as ArrayBuffer
//                val byteArray = Int8Array(buffer).unsafeCast<ByteArray>()

                val message = when (val data = it.data) {
                    is String -> data.toMessage()
                    is ArrayBuffer -> Int8Array(data).unsafeCast<ByteArray>().toMessage()
                    else -> error("unknown data type ${jsTypeOf(data)}")
                }

                for (listener in socketListeners) {
                    listener(message)
                }
            }
        }

        fun send(message: String) {
            socket.send(message)
        }
    }
}

interface TopicSession {
    fun connect(connectionHandler: Connection.() -> Unit): TopicSession
    fun close()

    interface Connection {
        var onmessage: ((String) -> Unit)?
        var onbytemessage: ((ByteString) -> Unit)?
        var onerror: ((ApiError) -> Unit)?
    }
}

private class RealTopicSession(
    val topic: String,
    val parameter: Map<String, String>,
    val authTokenProvider: () -> String?,
    val socket: SocketWrapper,
) : TopicSession {

    private var handle: Int = -1
    private var listener: (WebSocketMessage) -> Unit = {}

    private var closed = false

    class RealConnection : TopicSession.Connection {
        override var onmessage: ((String) -> Unit)? = null
        override var onbytemessage: ((ByteString) -> Unit)? = null
        override var onerror: ((ApiError) -> Unit)? = null

    }

    var socketSessionObserver: (SocketWrapper.Session) -> Unit = {}

    override fun connect(connectionHandler: TopicSession.Connection.() -> Unit): TopicSession {
        check(!closed)

        socketSessionObserver = {
            val connection = RealConnection()
            connectionHandler.invoke(connection)
            subscribe(connection, it)
        }

        socket.socketSessionObservers.add(socketSessionObserver)

        if (socket.session != null) {
            socketSessionObserver(socket.session!!)
        }
        return this
    }

    override fun close() {
        closed = true
        socket.socketSessionObservers.remove(socketSessionObserver)
        socket.session?.send("UNSUB:$handle")
        socket.session?.socketListeners?.remove(listener)
        listener = {}
    }

    private fun subscribe(connection: TopicSession.Connection, session: SocketWrapper.Session) {

        val requestId = Random.nextString(20)
        handle = -1
        var path: String? = null


        listener = { message: WebSocketMessage ->
            when {
                handle < 0 && message is WebSocketMessage.SubscribeOk && message.requestId == requestId -> {
                    path = message.path
                    handle = message.handle
                }

                handle < 0 && message is WebSocketMessage.SubscribeError && message.requestId == requestId -> {
                    if (message.error.isInvalidSessionError)
                        UserStore.authenticationRequiredObserver.onAuthenticationRequired()

                    connection.onerror?.invoke(message.error)
                }

                path != null && message is WebSocketMessage.Event && message.path == path -> {
                    when (val payload = message.payload) {
                        is WebSocketPayload.Text -> connection.onmessage?.invoke(payload.data)
                        is WebSocketPayload.Bytes -> connection.onbytemessage?.invoke(payload.data)
                    }
                }
            }
        }

        session.socketListeners.add(listener)

        val request = TopicSubscriptionRequest(
            requestId = requestId,
            topic = topic,
            parameter = parameter,
            metadata = authTokenProvider()?.let { mapOf("Authorization" to it) }.orEmpty()
        )
        session.send("SUB:${Json.encodeToString(TopicSubscriptionRequest.serializer(), request)}")
    }
}

private fun ByteArray.toMessage(): WebSocketMessage {
    val message = SocketMessage.ADAPTER.decode(this)

    return WebSocketMessage.Event(
        path = message.subscriptionPath,
        payload = WebSocketPayload.Bytes(message.message)
    )
}

private fun String.toMessage(): WebSocketMessage {
    val type = this.substringBefore(":")
    val rest = this.drop(type.length + 1)

    return when (type) {
        "SUB_OK" -> {
            val response = Json.decodeFromString(TopicSubscriptionResponse.serializer(), rest.substringAfter(":"))
            WebSocketMessage.SubscribeOk(
                requestId = rest.substringBefore(":"),
                handle = response.handle,
                path = response.path
            )
        }

        "SUB_ERROR" -> {
            val error = Json.decodeFromString(ApiError.serializer(), rest.substringAfter(":"))
            WebSocketMessage.SubscribeError(
                requestId = rest.substringBefore(":"),
                error = error
            )
        }

        "MES" -> {
            WebSocketMessage.Event(
                path = rest.substringBefore(":"),
                payload = WebSocketPayload.Text(rest.substringAfter(":"))
            )
        }

        else -> error("unknown message: $this")
    }
}