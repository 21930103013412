package de.geomobile.common.portalmodels

import de.geomobile.common.time.LocalDateTime
import kotlinx.serialization.Serializable


@Serializable
data class SelfTestDTO(
    val timestamp: LocalDateTime? = null,
    val rawJson: String? = null
)