package de.geomobile.common.portalmodels

import kotlinx.serialization.Serializable

@Serializable
data class LoginDTO(
    val email:String,
    val password: String
)

@Serializable
data class LoginResponseDTO(
    val userDTO: UserDTO,
    val token: String
)