package de.geomobile.frontend.features.admin.companies

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.styles.Breakpoint
import de.geomobile.common.portalmodels.Company
import de.geomobile.common.portalmodels.CompanySmall
import de.geomobile.common.portalmodels.small
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.utils.CComponent
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.serialization.builtins.ListSerializer
import react.RBuilder
import react.RProps
import react.RState
import react.setState

fun RBuilder.companySelection(
    mutable: Boolean,
    selectedCompanies: Set<CompanySmall>,
    onChanged: (Set<CompanySmall>) -> Unit,
) = child(CompanySelectionComponent::class) {
    attrs.mutable = mutable
    attrs.selectedCompanies = selectedCompanies
    attrs.onChanged = onChanged
}

class CompanySelectionComponent : CComponent<CompanySelectionComponent.Props, CompanySelectionComponent.State>() {

    interface Props : RProps {
        var mutable: Boolean
        var selectedCompanies: Set<CompanySmall>
        var onChanged: (Set<CompanySmall>) -> Unit
    }

    class State(
        var companies: List<CompanySmall> = listOf(),
    ) : RState

    init {
        state = CompanySelectionComponent.State()
    }

    override fun componentDidMount() {
        launch {
            val companies = withContext(Dispatchers.Default) {
                portalRestApi.get("/admin/companies", ListSerializer(Company.serializer()))
            }

            setState { this.companies = companies.map { it.small } }
        }
    }

    override fun RBuilder.render() {
        val selectedCompanies = props.selectedCompanies

        val allCompanies = state.companies

        mGridContainer(alignItems = MGridAlignItems.baseline, spacing = MGridSpacing.spacing0) {

            for (company in allCompanies) {
                mGridItem(
                    MGridBreakpoints(MGridSize.cells2)
                        .down(Breakpoint.xs, MGridSize.cells12)

                ) {
                    mCheckboxWithLabel(
                        label = company.name,
                        checked = company in selectedCompanies,
                        disabled = !props.mutable,
                        onChange = { _, checked ->
                            props.onChanged(
                                if (checked) props.selectedCompanies + company
                                else props.selectedCompanies - company
                            )
                        }
                    )
                }
            }
        }
    }
}