package de.geomobile.frontend.features.tts

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonSize
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.form.mFormControl
import com.ccfraser.muirwik.components.list.mListSubheader
import com.ccfraser.muirwik.components.table.*
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.spacer
import de.geomobile.frontend.utils.*
import kotlinext.js.assign
import kotlinx.browser.localStorage
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.*
import kotlinx.html.InputType
import kotlinx.html.js.onChangeFunction
import kotlinx.html.js.onClickFunction
import kotlinx.serialization.builtins.ListSerializer
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.get
import org.w3c.files.get
import org.w3c.xhr.FormData
import portalmodels.*
import react.RBuilder
import react.RProps
import react.RState
import react.dom.audio
import react.router.dom.route
import react.setState
import styled.css
import styled.styledDiv
import styled.styledInput

fun RBuilder.textToSpeechCustomSounds() = child(TextToSpeechCustomSounds::class) {}

class TextToSpeechCustomSounds : CComponent<TextToSpeechCustomSounds.Props, TextToSpeechCustomSounds.State>() {
    interface Props : RProps

    class State(
        var company: String = "",
        var sounds: List<TtsSoundFileDTO> = listOf(),
    ) : RState

    init {
        state = State()
    }

    fun fetchSounds(company: String) {
        launch {
            val sounds = portalRestApi.get("/tts/sounds/${company}", ListSerializer(TtsSoundFileDTO.serializer()))
            setState {
                this.sounds = sounds
            }
        }
    }

    fun changeDoorSignal(soundId: Int?) {
        launch {
            portalRestApi.put("/tts/sounds/${state.company}/${soundId}", GenericResponseDTO.serializer())
        }.invokeOnCompletion {
            fetchSounds(state.company)
        }
    }

    fun deleteSound(id: Int) {
        launch {
            portalRestApi.delete("/tts/sounds/${id}/delete", GenericResponseDTO.serializer())
        }.invokeOnCompletion {
            fetchSounds(state.company)
        }
    }

    override fun componentDidMount() {
        val company = localStorage["TextToSpeechCompany"] ?: "GEOMOBILE"
        setState {
            this.company = company
        }
        fetchSounds(company)
    }

    override fun RBuilder.render() {
        route<RProps>("/tts/new/sounds") { _ ->
            spacer()
            mGridContainer2(direction = MGridDirection.row) {
                mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                    mCard {
                        css(GlobalStyles.card)
                        mCardContent {
                            soundUpload()
                        }
                    }
                }
                mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                    mCard {
                        css(GlobalStyles.card)
                        mCardContent {
                            soundListTable()
                        }
                    }
                }
            }
        }
    }

    private fun RBuilder.soundListTable() =
        mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
            styledDiv {
                css {
                    display = Display.flex
                    justifyContent = JustifyContent.spaceBetween
                    alignItems = Align.center
                }
                mListSubheader(heading = "Eigene Töne") {
                    mTypography(
                        text = "Es kann immer nur ein Ton das Aktive Türsignal sein.",
                        variant = MTypographyVariant.subtitle2
                    )
                }
//                        mButton(
//                            caption = "Löschen",
//                            size = MButtonSize.small,
//                            color = MColor.inherit,
//                            variant = MButtonVariant.outlined,
//                            onClick = {
//                                setState {
//                                    fakeGPSList = listOf()
//                                    changed = true
//                                }
//                            }
//                        ) {
//                            attrs.disableElevation = true
//                            css { margin(0.spacingUnits, 1.spacingUnits); color = Color.red }
//                        }
            }
            mDivider { }
            mTableContainer {
                css { paddingRight = 1.spacingUnits }
                mTable {
                    mTableHead {
                        mTableRowSlim {
                            mTableCell(align = MTableCellAlign.left) {
                                css { padding(2.spacingUnits) }
                                +"Aktives Türsignal"
                            }
                            mTableCell(align = MTableCellAlign.left) {
                                css { padding(2.spacingUnits) }
                                +"Name"
                            }
                            mTableCell(align = MTableCellAlign.left) {
                                css { padding(2.spacingUnits) }
                                +"Abspielen"
                            }
                            mTableCell(align = MTableCellAlign.left) {
                                css { padding(2.spacingUnits) }
                                +"Löschen"
                            }
                        }
                    }
                    mTableBody {
                        state.sounds.forEach { sound ->
                            mTableRowSlim {
                                mTableCell(align = MTableCellAlign.left) {
                                    css { padding(0.spacingUnits, 2.spacingUnits) }
                                    mCheckbox(
                                        checked = sound.isDoorSignal,
                                        onChange = { _, checked ->
                                            if (checked)
                                                changeDoorSignal(sound.soundFileId)
                                            else
                                                changeDoorSignal(null)
                                        }
                                    )
                                }
                                sound.name.let {
                                    mTableCell(align = MTableCellAlign.left) {
                                        css { padding(0.spacingUnits, 2.spacingUnits) }
                                        +it
                                    }
                                }
                                mTableCell(align = MTableCellAlign.left) {
                                    css { padding(0.spacingUnits, 2.spacingUnits) }
                                    audioPlayer(state.company, "${sound.soundFileId}", controls = true, sound = true)
                                }
                                mTableCell(align = MTableCellAlign.left) {
                                    css { padding(0.spacingUnits, 2.spacingUnits) }
                                    mIconButtonNoTranslate(
                                        iconName = "delete",
                                        size = MButtonSize.small,
                                        color = MColor.inherit,
                                        onClick = { _ ->
                                            deleteSound(sound.soundFileId)
                                        }
                                    ) {
                                        css {
                                            backgroundColor = Color.red; color = Color.white
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

    private fun RBuilder.soundUpload() =
        mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
            styledDiv {
                css { padding(2.spacingUnits, 2.spacingUnits, 0.px, 2.spacingUnits) }
                css {
                    padding(0.spacingUnits)
                    justifyItems = JustifyItems.center
                    display = Display.flex
                    alignItems = Align.flexStart
                }
                styledDiv {
                    mTypography(
                        text = "Sound Upload:",
                        variant = MTypographyVariant.h6
                    ) {
                        css {
                            minWidth = 160.px
                            display = Display.block
                            marginTop = 2.spacingUnits
                            paddingRight = 1.spacingUnits
                        }
                    }
                }
                styledInput {
                    css {
                        padding(2.spacingUnits)
                        marginTop = 4.px
                        width = 80.pct
                        border = "2px dashed #eeeeee"
                        borderRadius = 3.px
                        borderColor = Color.lightGray
                    }
                    attrs.name = "upload"
                    attrs.type = InputType.file
                    attrs.accept = ".wav"
                    attrs.multiple = false
                    attrs.onChangeFunction = { event ->
                        var fileEvent = event.target as HTMLInputElement
                        var files = fileEvent.files
                        files?.get(0)?.let {
                            val formData = FormData()
                            formData.append(name = it.name, value = it)
                            launch {
                                withContext(Dispatchers.Default) {
                                    portalRestApi.post(
                                        path = "/tts/${state.company}/upload?name=${it.name}",
                                        header = kotlinx.html.emptyMap,
                                        body = formData,
                                        serializer = GenericResponseDTO.serializer()
                                    )
                                }
                            }.invokeOnCompletion {
                                fetchSounds(state.company)
                            }
                        }
                    }
                    attrs.onClickFunction = {
                        kotlinext.js.js { it.target.asDynamic().value = null }
                    }
                }
//                    mTypography(
//                        text = "Die CSV-Datei sollte folgende Daten enthalten: Haltestelle (Normal), Haltestelle (IBIS), Latitude und Longitude. Die Dezimaltrennung der Koordinaten muss mit einem Punkt erfolgen. Die CSV-Datei muss das UTF-8 Format haben und Komma separiert sein.",
//                        variant = MTypographyVariant.caption,
//                        color = MTypographyColor.textSecondary
//                    ) { css { display = Display.block; marginTop = 2.spacingUnits } }
            }
        }

}
