package de.geomobile.frontend.features.device.detail

import com.ccfraser.muirwik.components.MTypographyColor
import com.ccfraser.muirwik.components.MTypographyVariant
import com.ccfraser.muirwik.components.list.mList
import com.ccfraser.muirwik.components.list.mListItem
import com.ccfraser.muirwik.components.list.mListItemText
import com.ccfraser.muirwik.components.mTypography
import com.ccfraser.muirwik.components.spacingUnits
import de.geomobile.common.portalmodels.TimestampStatus
import de.geomobile.common.time.LocalDateTime
import de.geomobile.frontend.api.TopicSession
import de.geomobile.frontend.portalWebSocketApi
import de.geomobile.frontend.utils.*
import kotlinx.css.padding
import kotlinx.css.px
import kotlinx.serialization.KSerializer
import kotlinx.serialization.SerializationException
import kotlinx.serialization.json.Json
import react.*
import styled.css
import styled.styledDiv

fun RBuilder.tabledInfo(
    subscriptionPath: String,
    id: Int,
    serializer: KSerializer<TableDisplayable>,
    onDeviceDetailPage: Boolean
) = child(TabledInfoComponent::class) {
    key = id.toString()
    attrs.id = id
    attrs.serializer = serializer
    attrs.subscriptionPath = subscriptionPath
    attrs.onDeviceDetailPage = onDeviceDetailPage
}

class TabledInfoComponent : RComponent<TabledInfoComponent.Props, TabledInfoComponent.State>() {

    private var session: TopicSession? = null

    interface Props : RProps {
        var id: Int
        var subscriptionPath: String
        var serializer: KSerializer<TableDisplayable>
        var onDeviceDetailPage: Boolean
    }

    class State(
        var infoObject: TableDisplayable? = null,
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        session?.close()
        session = portalWebSocketApi.subscribe(props.subscriptionPath, mapOf("id" to props.id.toString()))
        session?.connect {
            onmessage = {
                val infoObject = try {
                    Json.decodeFromString(props.serializer, it)
                } catch (e: SerializationException) {
                    null
                }
                setState {
                    this.infoObject = infoObject
                }
            }
        }
    }

    override fun componentWillUnmount() {
        session?.close()
    }

    override fun RBuilder.render() {
        if (state.infoObject == null) styledDiv {
            css { padding(2.spacingUnits) }
            mSkeleton(
                height = 100.px,
                animation = MSkeletonAnimation.wave,
                variant = MSkeletonVariant.rect
            )
        } else {
            styledDiv {
                if (props.onDeviceDetailPage) {
                    css { padding(2.spacingUnits) }
                }

                mList {
                    attrs.dense = true

                    state.infoObject!!.asList().forEach { entry ->
                        mListItem {
                            attrs.button = false
                            attrs.divider = true

                            mListItemText {
                                when (val value = entry.value) {
                                    null -> styledDiv {
                                        mTypography(
                                            text = entry.key,
                                            variant = MTypographyVariant.caption,
                                        )
                                        mTypography(
                                            text = "-",
                                            variant = MTypographyVariant.subtitle2,
                                            color = MTypographyColor.textPrimary
                                        )
                                    }

                                    is String -> styledDiv {
                                        mTypography(
                                            text = entry.key,
                                            variant = MTypographyVariant.caption,
                                        )
                                        mTypography(
                                            text = value,
                                            variant = MTypographyVariant.subtitle2,
                                            color = MTypographyColor.textPrimary
                                        )
                                    }

                                    is Boolean -> styledDiv {
                                        mTypography(
                                            text = entry.key,
                                            variant = MTypographyVariant.caption,
                                        )
                                        mTypography(
                                            text = if (value == true) "Ja" else "Nein",
                                            variant = MTypographyVariant.subtitle2,
                                            color = MTypographyColor.textPrimary
                                        )
                                    }

                                    is LocalDateTime -> styledDiv {
                                        mTypography(
                                            text = entry.key,
                                            variant = MTypographyVariant.caption,
                                        )
                                        mTypography(
                                            text = value.toText(),
                                            variant = MTypographyVariant.subtitle2,
                                            color = MTypographyColor.textPrimary
                                        )
                                    }

                                    is TimestampStatus -> styledDiv {
                                        mTypography(
                                            text = entry.key,
                                            variant = MTypographyVariant.caption,
                                        )
                                        styledDiv {
                                            timestampStatus(value)
                                        }
                                    }

                                    else -> styledDiv {
                                        mTypography(
                                            text = entry.key,
                                            variant = MTypographyVariant.caption,
                                        )
                                        mTypography(
                                            text = value.toString(),
                                            variant = MTypographyVariant.subtitle2,
                                        )
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}