package de.geomobile.frontend.features.device.detail

import com.ccfraser.muirwik.components.spacingUnits
import de.geomobile.common.permission.Permissions
import de.geomobile.common.portalmodels.DeviceAudioDTO
import de.geomobile.frontend.api.TopicSession
import de.geomobile.frontend.portalWebSocketApi
import de.geomobile.frontend.utils.isAuthorized
import kotlinx.css.padding
import kotlinx.serialization.json.Json
import react.*
import styled.css
import styled.styledDiv

fun RBuilder.deviceAudio(
    id: Int,
) = child(DeviceAudio::class) {
    key = id.toString()
    attrs.id = id
}

class DeviceAudio : RComponent<DeviceAudio.Props, DeviceAudio.State>() {

    private var session: TopicSession? = null

    interface Props : RProps {
        var id: Int
    }

    class State(
        var audio: DeviceAudioDTO? = null,
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        session?.close()
        session = portalWebSocketApi.subscribe("/device/audio", mapOf("id" to props.id.toString()))
        session?.connect {
            onmessage = {
                val audio = Json.decodeFromString(DeviceAudioDTO.serializer(), it)
                setState {
                    this.audio = audio
                }
            }
        }
    }

    override fun componentWillUnmount() {
        session?.close()
    }

    override fun RBuilder.render() {
        val audio = state.audio ?: return

        styledDiv {
            css { padding(2.spacingUnits) }
            rowChooseVolume(
                label = "Lautstärke",
                value = audio.volume,
                applyPath = "/device/${props.id}/audio/volume",
                testPath = "/device/${props.id}/cablecheck/playaudio",
                allowEdit = isAuthorized(Permissions.DeviceManagement.audioConfig) && audio.volume != null
            )
        }
    }
}