package de.geomobile.frontend.utils

import react.router.dom.RouteResultLocation

external fun encodeURIComponent(uri: String): String
external fun decodeURIComponent(uri: String): String

fun String.urlEncode() = encodeURIComponent(this.replace(".", "%dot%"))

fun String.urlDecode() = try {
    decodeURIComponent(this).replace("%dot%", ".")
} catch (e: dynamic) {
    decodeURIComponent(this.replace("%dot%", "."))
}

val RouteResultLocation.params: Map<String, String>
    get() = Regex("[\\?&]([^&#=]*)=([^&#]*)").findAll(search).associate { it.groupValues[1] to it.groupValues[2] }