package de.geomobile.common.statistics

import de.geomobile.common.time.LocalDateTime
import kotlinx.serialization.Serializable

@Serializable
data class Days(
    val days: List<LocalDateTime>
)

// TODO: Clean up
//val Day.text: String
//    get() {
//        val weekdayText = when (dayInWeek) {
//            1 -> "Mo"
//            2 -> "Di"
//            3 -> "Mi"
//            4 -> "Do"
//            5 -> "Fr"
//            6 -> "Sa"
//            7 -> "So"
//            else -> error("invalid day $this")
//        }
//
//        return "$day. ($weekdayText)"
//    }

