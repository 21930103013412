package de.geomobile.common.filter

import kotlinx.serialization.Serializable
import kotlinx.serialization.json.*

sealed class Filter<ELEMENT, VALUE>(
        val stringToValue: (String) -> VALUE,
        val valueToString: (VALUE) -> String,
        val operators: List<Operator<VALUE>>
) {
    abstract val id: String
    abstract val name: String
    abstract val queryName: String
    abstract val elementToValue: (ELEMENT) -> VALUE

    data class Operator<VALUE>(
            val id: String,
            val operator: String,
            val relational: Boolean,
            val arrayOperation: Boolean,
            val eval: (ref: JsonElement, value: VALUE) -> Boolean
    )

    @Serializable
    data class Rule(
            val ref: JsonElement,
            val operatorId: String
    )
}

private fun <VALUE> op(
        id: String,
        operator: String,
        relational: Boolean = true,
        arrayOperation: Boolean = false,
        eval: (ref: JsonElement, value: VALUE) -> Boolean
) =
        Filter.Operator(id, operator, relational, arrayOperation, eval)

class FilterLong<ELEMENT>(
        override val id: String,
        override val name: String,
        override val queryName: String = name,
        override val elementToValue: (ELEMENT) -> Long?
) : Filter<ELEMENT, Long?>(
        stringToValue = { it.toLong() },
        valueToString = { it.toString() },
        operators = listOf(
                op(id = "EQUALS", operator = "==") { ref, value -> value == ref.jsonPrimitive.long },
                op(id = "NOT_EQUALS", operator = "!=") { ref, value -> value != ref.jsonPrimitive.long },
                op(id = "LESS", operator = "<") { ref, value -> value != null && value < ref.jsonPrimitive.long },
                op(id = "LESS_OR_EQUALS", operator = "<=") { ref, value -> value != null && value <= ref.jsonPrimitive.long },
                op(id = "GREATER", operator = ">") { ref, value -> value != null && value > ref.jsonPrimitive.long },
                op(id = "GREATER_OR_EQUALS", operator = ">=") { ref, value -> value != null && value >= ref.jsonPrimitive.long },
                op(id = "IS_NULL", operator = "is null", relational = false) { _, value -> value == null },
                op(id = "IS_NOT_NULL", operator = "!is null", relational = false) { _, value -> value != null },
                op(id = "IN", operator = "in", arrayOperation = true) { ref, value -> value in ref.jsonArray.map { it.jsonPrimitive.long } },
                op(id = "NOT_IN", operator = "!in", arrayOperation = true) { ref, value -> value !in ref.jsonArray.map { it.jsonPrimitive.long } }
        )
)

class FilterDouble<ELEMENT>(
        override val id: String,
        override val name: String,
        override val queryName: String = name,
        override val elementToValue: (ELEMENT) -> Double
) : Filter<ELEMENT, Double>(
        stringToValue = { it.toDouble() },
        valueToString = { it.toString() },
        operators = listOf(
                op(id = "EQUALS", operator = "==") { ref, value -> value == ref.jsonPrimitive.double },
                op(id = "NOT_EQUALS", operator = "!=") { ref, value -> value != ref.jsonPrimitive.double },
                op(id = "LESS", operator = "<") { ref, value -> value < ref.jsonPrimitive.double },
                op(id = "LESS_OR_EQUALS", operator = "<=") { ref, value -> value <= ref.jsonPrimitive.double },
                op(id = "GREATER", operator = ">") { ref, value -> value > ref.jsonPrimitive.double },
                op(id = "GREATER_OR_EQUALS", operator = ">=") { ref, value -> value >= ref.jsonPrimitive.double }
        )
)

class FilterString<ELEMENT>(
        override val id: String,
        override val name: String,
        override val queryName: String = name,
        override val elementToValue: (ELEMENT) -> String?
) : Filter<ELEMENT, String?>(
        stringToValue = { it },
        valueToString = { it.toString() },
        operators = listOf(
                op(id = "EQUALS", operator = "==") { ref, value -> value == ref.jsonPrimitive.content },
                op(id = "NOT_EQUALS", operator = "!=") { ref, value -> value != ref.jsonPrimitive.content },
                op(id = "CONTAINS", operator = "contains") { ref, value ->
                    value?.contains(ref.jsonPrimitive.content, ignoreCase = true) ?: false
                },
                op(id = "REGEX", operator = "regex")
                { ref, value -> if (value != null) ref.jsonPrimitive.content.toRegex().matches(value) else false },
                op(id = "IS_NULL", operator = "is null", relational = false) { _, value -> value == null },
                op(id = "IS_NOT_NULL", operator = "!is null", relational = false) { _, value -> value != null },
                op(id = "IN", operator = "in", arrayOperation = true) { ref, value -> value in ref.jsonArray.map { it.jsonPrimitive.content.trim() } },
                op(id = "NOT_IN", operator = "!in", arrayOperation = true) { ref, value -> value !in ref.jsonArray.map { it.jsonPrimitive.content.trim() } }
        )
)

class FilterEnumerableInt<ELEMENT>(
        override val id: String,
        override val name: String,
        override val queryName: String = name,
        override val elementToValue: (ELEMENT) -> Int,
        val values: Map<String, Int>
) : Filter<ELEMENT, Int>(
        stringToValue = { values.mapKeys { it.key.lowercase() }.getValue(it.lowercase()) },
        valueToString = { value -> values.filterValues { it == value }.keys.single() },
        operators = listOf(
                op(id = "EQUALS", operator = "==") { ref, value -> value == ref.jsonPrimitive.int },
                op(id = "NOT_EQUALS", operator = "!=") { ref, value -> value != ref.jsonPrimitive.int },
                op(id = "IN", operator = "in", arrayOperation = true) { ref, value -> value in ref.jsonArray.map { it.jsonPrimitive.int } },
                op(id = "NOT_IN", operator = "!in", arrayOperation = true) { ref, value -> value !in ref.jsonArray.map { it.jsonPrimitive.int } }
        )
)

class FilterEnumerableString<ELEMENT>(
        override val id: String,
        override val name: String,
        override val queryName: String = name,
        override val elementToValue: (ELEMENT) -> String,
        val values: Map<String, String>
) : Filter<ELEMENT, String>(
        stringToValue = { values.mapKeys { it.key.lowercase() }.getValue(it.lowercase()) },
        valueToString = { value -> values.filterValues { it == value }.keys.single() },
        operators = listOf(
                op(id = "EQUALS", operator = "==") { ref, value -> value == ref.jsonPrimitive.content },
                op(id = "NOT_EQUALS", operator = "!=") { ref, value -> value != ref.jsonPrimitive.content },
                op(id = "IN", operator = "in", arrayOperation = true) { ref, value -> value in ref.jsonArray.map { it.jsonPrimitive.content } },
                op(id = "NOT_IN", operator = "!in", arrayOperation = true) { ref, value -> value !in ref.jsonArray.map { it.jsonPrimitive.content } },
                op(id = "CONTAINS", operator = "contains") { ref, value -> value.contains(ref.jsonPrimitive.content, ignoreCase = true) }
        )
)