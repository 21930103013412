package de.geomobile.frontend.components

import com.ccfraser.muirwik.components.*
import de.geomobile.common.portalmodels.Product
import de.geomobile.frontend.utils.*
import kotlinx.css.*
import kotlinx.css.properties.scale
import kotlinx.css.properties.transform
import react.*
import styled.css

fun RBuilder.renderDivider() {
    mDivider(
        orientation = MDividerOrientation.vertical,
        variant = MDividerVariant.middle
    ) {
        css {
            height = 5.spacingUnits
            width = 1.px
            marginLeft = 0.px
            marginRight = 0.px
        }
    }
}

fun RBuilder.renderCountItem(
    count: Number,
    countColor: Color? = null,
    caption: String? = null,
    variant: MTypographyVariant = MTypographyVariant.h5,
    size: LinearDimension? = null,
    css: CSSBuilder.() -> Unit = {}
) = mGridItem2(MGridBreakpoints2(MGridSize2.Auto)) {
    css {
        css()
    }
    mTypography(
        text = count.toString(),
        variant = variant,
        color = if (countColor !== null) MTypographyColor.inherit else MTypographyColor.textSecondary,
        align = MTypographyAlign.center
    ) {
        size?.let {
            css { fontSize = it }
        }
        css { letterSpacing = 1.px; }
        css { fontWeight = FontWeight.bolder }
        css { transform { scale(1.33, 1.75) } }

        if (countColor !== null) {
            css { color = countColor }
        }
    }

    if (caption !== null) {
        mTypography(
            text = caption,
            variant = MTypographyVariant.caption,
            color = MTypographyColor.textSecondary,
            align = MTypographyAlign.center
        ) {
            css {
                letterSpacing = (-0.5).px
                fontSize = 11.px
                fontWeight = FontWeight.w500
            }
        }
    }
}

fun RBuilder.statusCountView(
    breakpoints: MGridBreakpoints2,
    title: String? = null,
    product: Product? = null,
    iconName: String? = null,
    children: ReactElement,
    css: CSSBuilder.() -> Unit = {}
) = child(StatusCountView::class) {
    attrs.breakpoints = breakpoints
    attrs.title = title
    attrs.product = product
    attrs.iconName = iconName
    attrs.children = children
    attrs.css = css
}

class StatusCountView : RComponent<StatusCountView.Props, StatusCountView.State>() {

    interface Props : RProps {
        var breakpoints: MGridBreakpoints2
        var product: Product?
        var title: String?
        var iconName: String?
        var children: ReactElement
        var css: CSSBuilder.() -> Unit
    }

    class State : RState

    override fun RBuilder.render() {
        mGridItem2(props.breakpoints) {
            props.css(css)
            props.product?.let {
                mTypography(
                    text = it.readableName,
                    variant = MTypographyVariant.body1,
                    color = MTypographyColor.textSecondary,
                    align = MTypographyAlign.center
                ) { css { fontWeight = FontWeight.w500 } }
            }

            props.iconName?.let {
                mIconNoTranslate(
                    fontSize = MIconFontSize.large,
                    iconName = it,
                    color = MIconColor.disabled
                ) { css { margin(1.spacingUnits) } }
            }

            props.title?.let {
                mTypography(
                    text = it.uppercase(),
                    variant = MTypographyVariant.h6,
                    color = MTypographyColor.primary,
                    align = MTypographyAlign.center
                )
            }

            props.children()
        }
    }
}