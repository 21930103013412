package de.geomobile.common.portalmodels

import de.geomobile.common.time.LocalDateTime
import kotlinx.serialization.Serializable
import kotlin.math.abs

@Serializable
data class Position(
    val lastSeen: TimestampStatus,
    val lastLocation: TimestampStatus,
    val fix: Boolean,
    val location: Location? = null
) {
    @Serializable
    data class Location(
        val longitude: Double,
        val latitude: Double,
        val timestamp: LocalDateTime? = null,
        val satellites: Int? = null
    )
}

fun Position.Location.isSameLocation(other: Position.Location): Boolean =
    (abs(this.latitude - other.latitude) < 0.000005) &&
    (abs(this.longitude - other.longitude) < 0.00005)


data class RawLocation(
    val longitude: Float,
    val latitude: Float
)