package de.geomobile.frontend.features.device.detail

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardActionArea
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.list.mList
import com.ccfraser.muirwik.components.list.mListItem
import com.ccfraser.muirwik.components.list.mListItemText
import com.ccfraser.muirwik.components.transitions.mCollapse
import de.geomobile.common.portalmodels.TimestampStatus
import de.geomobile.common.time.LocalDate
import de.geomobile.common.time.LocalDateTime
import de.geomobile.frontend.device.detail.trialRibbon
import de.geomobile.frontend.utils.mCardHeaderExtended
import de.geomobile.frontend.utils.mIconNoTranslate
import de.geomobile.frontend.utils.timestampStatus
import de.geomobile.frontend.utils.toText
import kotlinx.css.*
import react.RBuilder
import react.buildElement
import styled.css
import styled.styledDiv
import styled.styledImg
import kotlin.math.floor

///// MODEL

sealed class InfoItem

object InfoEmpty : InfoItem()

data class InfoList(
    val items: List<InfoItem>,
) : InfoItem() {
    constructor(vararg items: InfoItem) : this(items.asList())
}

data class InfoGrid(
    val columns: Int,
    val items: List<InfoItem>,
) : InfoItem()

data class InfoRow(
    val title: String?,
    val value: Any?,
) : InfoItem()

data class RssiValue(val rssi: Int) {
    companion object {
        fun parse(raw: String) = RssiValue(raw.removeSuffix(" dBm").toInt())
    }

//    val level: Int get() = when {
//        rssi > -50 -> 4
//        rssi in -50..-60 -> 3
//    }
}

data class InfoGroup(
    val enabledIcon: String? = null,
    val disabledIcon: String? = null,
    val iconColor: Color = Color.green,
    val title: String?,
    val enabled: Boolean? = null,
    val content: InfoItem,
) : InfoItem()

data class InfoCard(
    val index: Int = 0,
    val title: String?,
    val status: TimestampStatus? = null,
    val isCDA: Boolean = false,
    val trial: Boolean = false,
    val content: InfoItem,
    val showCollapse: Boolean? = true,
    val expanded: Boolean? = false,
    val onExpand: ((Int?) -> Unit?)? = null
) : InfoItem()

/////// VIEW

fun RBuilder.infoItem(
    item: InfoItem,
    showCollapse: Boolean? = true,
    expanded: Boolean = false,
    onExpand: (() -> Unit)? = null
) {
    when (item) {
        InfoEmpty -> infoEmpty()
        is InfoList -> infoList(list = item, expanded = true, showCollapse = showCollapse)
        is InfoGrid -> infoGrid(grid = item)
        is InfoRow -> infoRow(row = item)
        is InfoGroup -> infoGroup(group = item)
        is InfoCard -> infoCard(card = item, expanded = expanded, onExpand = onExpand)
    }.let {}
}

fun RBuilder.infoCard(
    card: InfoCard,
    expanded: Boolean = false,
    onExpand: (() -> Unit)? = null
) {
    mCard {
        css {
            position = Position.relative
            marginTop = if (card.index >= 1) 2.spacingUnits else 0.px
        }
        if (card.trial) trialRibbon()
        mCardActionArea(
            onClick = {
                onExpand?.invoke()
            },
        ) {
            attrs.component = "div"
            mCardHeaderExtended(
                title = card.title ?: "",
                action = if (card.showCollapse == true) {
                    mIconButtonNoTranslate(
                        if (expanded) "expand_less" else "expand_more",
                        onClick = { event ->
                            run {
                                onExpand?.invoke()
                                event.stopPropagation()
                            }
                        },
                        addAsChild = false
                    ) {
                        attrs.component = "div"
                    }
                } else card.status?.let { status ->
                    buildElement {
                        styledDiv {
                            css {
                                marginTop = 1.spacingUnits
                                height = 32.px
                                marginRight = 1.spacingUnits
                                display = Display.flex
                                alignItems = Align.center
                            }
                            timestampStatus(status)
                        }
                    }
                }
            )
        }

        mCollapse(expanded) {
            mDivider {}
            mCardContent {
                if (card.isCDA)
                    card.status?.let { status ->
                        styledDiv {
                            css { padding(0.spacingUnits, 2.spacingUnits, 1.spacingUnits, 2.spacingUnits) }
                            timestampStatus(status)
                        }
                    }

                infoItem(item = card.content)
            }
        }
    }
}

fun RBuilder.infoEmpty() {
    mTypography(
        text = "Keine Daten verfügbar",
        variant = MTypographyVariant.body1,
        align = MTypographyAlign.center
    ) { css { padding(2.spacingUnits) } }
}

fun RBuilder.infoList(
    list: InfoList,
    showCollapse: Boolean?,
    expanded: Boolean,
) {
    for (item in list.items) {
        infoItem(item = item, expanded = expanded, showCollapse = showCollapse)
    }
}

fun RBuilder.infoGrid(
    grid: InfoGrid,
) {
    styledDiv {
        css {
            display = Display.flex
            flexDirection = FlexDirection.row
            position = Position.relative
            flexWrap = FlexWrap.wrap
            children {
                flex(0.0, 1.0, floor(100.0 / grid.columns).pct)
            }
        }

        for (item in grid.items) {
            infoItem(item = item)
        }
    }
}

fun RBuilder.infoGroup(
    group: InfoGroup,
) {
    styledDiv {
        styledDiv {
            css {
                display = Display.flex
                alignItems = Align.center
                padding(1.spacingUnits, 2.spacingUnits)
            }
            group.enabledIcon?.let { icon ->
                mIconNoTranslate(
                    when {
                        group.disabledIcon == null -> icon
                        group.enabled == true -> icon
                        else -> group.disabledIcon!!
                    }
                ) {
                    css {
                        float = Float.left
                        marginRight = 10.px
                        color = when (group.enabled) {
                            null -> Color.black
                            true -> group.iconColor
                            else -> Color.black
                        }
                    }
                }
            }

            mTypography(
                text = group.title,
                variant = MTypographyVariant.subtitle2,
                color = MTypographyColor.initial
            )
        }

        infoItem(item = group.content)
    }
}

fun RBuilder.simpleText(text: String) = mTypography(
    text = text,
    variant = MTypographyVariant.subtitle2,
    color = MTypographyColor.textPrimary
)

fun RBuilder.infoRow(row: InfoRow) {
    mList {
        attrs.dense = true
        attrs.disablePadding = true

        mListItem {
            attrs.button = false
            attrs.divider = true

            mListItemText {
                mTypography(
                    text = row.title,
                    variant = MTypographyVariant.caption,
                )

                when (val value = row.value) {
                    null -> simpleText("-")
                    is String -> simpleText(value)
                    is LocalDateTime -> simpleText(value.toText())
                    is LocalDate -> simpleText(value.toText())
                    is RssiValue -> {
                        mTypography(
                            text = value.rssi.toString() + " dBm",
                            variant = MTypographyVariant.subtitle2,
                            color = MTypographyColor.textPrimary
                        ) {
                            css {
                                flexGrow = 1.0
                                flexShrink = 1.0
                                flexBasis = FlexBasis.auto
                            }
                        }
                        styledImg(src = "/portal/static/wifi_3.svg") {}
                    }

                    else -> simpleText(value.toString())
                }
            }
        }
    }
}