package de.geomobile.frontend.features.device.detail

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import de.geomobile.common.permission.Permissions
import de.geomobile.common.portalmodels.DeviceDTO
import de.geomobile.common.portalmodels.DeviceDetailDTO
import de.geomobile.common.portalmodels.TimestampStatus
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.api.TopicSession
import de.geomobile.frontend.features.device.detail.internal.deviceDelete
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.portalWebSocketApi
import de.geomobile.frontend.utils.CComponent
import de.geomobile.frontend.utils.authorize
import de.geomobile.frontend.utils.isAuthorized
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.*
import kotlinx.serialization.builtins.serializer
import kotlinx.serialization.json.Json
import react.RBuilder
import react.RProps
import react.RState
import react.setState
import styled.css
import styled.styledDiv

fun RBuilder.deviceDetailToolbar(
    id: Int,
    goBack: () -> Unit,
) = child(DeviceDetailToolbar::class) {
    key = id.toString()
    attrs.id = id
    attrs.goBack = goBack
}

class DeviceDetailToolbar : CComponent<DeviceDetailToolbar.Props, DeviceDetailToolbar.State>() {

    private var statusSession: TopicSession? = null
    private var internalSession: TopicSession? = null

    interface Props : RProps {
        var id: Int
        var goBack: () -> Unit
    }

    class State(
        var deviceDetail: DeviceDetailDTO? = null,
        var deviceInternalDetail: DeviceDTO? = null,
        var pong: Boolean? = null,
        var showPong: Boolean = false,
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        statusSession?.close()
        statusSession = portalWebSocketApi
            .subscribe("/device/detail", mapOf("id" to props.id.toString()))
            .connect {
                onmessage = {
                    val deviceDetail = Json.decodeFromString(DeviceDetailDTO.serializer(), it)
                    setState {
                        this.deviceDetail = deviceDetail
                    }
                }
            }

        if (isAuthorized(Permissions.AdminPermissions.internalAccess)) {
            internalSession?.close()
            internalSession = portalWebSocketApi
                .subscribe("/device", mapOf("id" to props.id.toString()))
                .connect {
                    onmessage = {
                        val device = Json.decodeFromString(DeviceDTO.serializer(), it)
                        setState {
                            this.deviceInternalDetail = device
                        }
                    }
                }
        }
    }

    override fun componentWillUnmount() {
        super.componentWillUnmount()
        statusSession?.close()
        internalSession?.close()
    }

    override fun RBuilder.render() {
        mAppBar(position = MAppBarPosition.fixed) {
            css(GlobalStyles.appbar)
            mToolbar {
                mIconButtonNoTranslate(
                    iconName = "arrow_back",
                    color = MColor.inherit,
                    onClick = { props.goBack() }
                )
                deviceStatusLed(
                    interval = 1000,
                    status = state.deviceDetail?.lastSeen ?: TimestampStatus(),
                    outline = Color.white
                )
                if (state.deviceDetail?.serialNumber != null) mHidden(mdDown = false) {
                    mTypography(
                        text = "SN: ${state.deviceDetail?.serialNumber!!}",
                        variant = MTypographyVariant.h6,
                        color = MTypographyColor.inherit,
                        noWrap = true
                    ) { css { marginLeft = 2.spacingUnits; marginTop = 2.px } }
                }
                if (state.deviceDetail?.vehicleId?.isNotBlank() == true) mHidden(mdDown = true) {
                    mTypography(
                        text = "FN: ${state.deviceDetail?.vehicleId}",
                        variant = MTypographyVariant.h6,
                        color = MTypographyColor.inherit,
                        noWrap = true
                    ) { css { marginLeft = 2.spacingUnits; marginTop = 2.px } }
                }
                authorize(Permissions.AdminPermissions.internalAccess) {
                    if (state.deviceInternalDetail?.cpuId != null) mHidden(mdDown = true) {
                        mTypography(
                            text = "CPU: ${state.deviceInternalDetail?.cpuId}",
                            variant = MTypographyVariant.h6,
                            color = MTypographyColor.inherit,
                            noWrap = true
                        ) { css { marginLeft = 2.spacingUnits; marginTop = 2.px } }
                        mTypography(
                            text = "HWID: ${state.deviceInternalDetail?.hardwareId}",
                            variant = MTypographyVariant.h6,
                            color = MTypographyColor.inherit,
                            noWrap = true
                        ) { css { marginLeft = 2.spacingUnits; marginTop = 2.px } }
                    }
                    styledDiv {
                        css {
                            marginLeft = LinearDimension.auto
                            marginRight = 0.spacingUnits
                        }
                        mButton(
                            caption = "PING",
                            color = MColor.inherit,
                            variant = MButtonVariant.outlined,
                            onClick = { sendPing() }
                        )
                        deviceDelete(props.id, onDeleted = { props.goBack() })
                    }
                }
            }
        }
        mSnackbar(
            message = when (state.pong) {
                null -> "Ping..."
                true -> "PONG"
                else -> "Timeout"
            },
            open = state.showPong,
            vertAnchor = MSnackbarVertAnchor.top,
            autoHideDuration = 5000,
        ) {
            attrs.action = RBuilder().mIconButtonNoTranslate(
                iconName = "close",
                onClick = { setState { showPong = false } },
                color = MColor.inherit
            )
            attrs.onClose = { _, _ -> setState { showPong = false } }

            css {
                marginTop = 70.px
                if (state.pong != null)
                    children { backgroundColor = if (state.pong == true) Color.green else Color.red }
            }
        }
    }

    private fun sendPing() {
        launch {

            setState {
                this.pong = null
                this.showPong = true
            }

            val pong = withContext(Dispatchers.Default) {
                portalRestApi.post("/device/${props.id}/pingpong", null, Boolean.serializer())
            }

            setState {
                this.pong = pong
                this.showPong = true
            }
        }
    }
}