package de.geomobile.frontend.features.device.detail

import com.ccfraser.muirwik.components.MTypographyVariant
import com.ccfraser.muirwik.components.mTypography
import com.ccfraser.muirwik.components.spacingUnits
import com.ccfraser.muirwik.components.table.*
import de.geomobile.common.portalmodels.DeviceIbisInfoDTO
import de.geomobile.common.portalmodels.IbisTelegramInfo
import de.geomobile.common.utils.nonStrictJson
import de.geomobile.frontend.api.TopicSession
import de.geomobile.frontend.portalWebSocketApi
import de.geomobile.frontend.utils.timestampStatus
import kotlinx.css.padding
import kotlinx.css.paddingLeft
import react.*
import styled.css
import styled.styledDiv
import styled.styledTd

fun RBuilder.ibisStatus(
    id: Int,
) = child(DeviceIbisComponent::class) {
    key = id.toString()
    attrs.id = id
}

class DeviceIbisComponent :
    RComponent<DeviceIbisComponent.Props, DeviceIbisComponent.State>() {

    private var session: TopicSession? = null

    interface Props : RProps {
        var id: Int
    }

    class State(
        var item: DeviceIbisInfoDTO? = null,
    ) : RState

    init {
        state = State()
    }

    data class Row(
        val title: String,
        val telegram: IbisTelegramInfo?,
    )

    override fun componentDidMount() {
        session?.close()
        session = portalWebSocketApi.subscribe("/device/ibis_info", mapOf("id" to props.id.toString()))
        session?.connect {
            onmessage = {

                val item = nonStrictJson.decodeFromString(DeviceIbisInfoDTO.serializer(), it)

                setState {
                    this.item = item
                }
            }
        }
    }

    override fun componentWillUnmount() {
        session?.close()
    }

    override fun RBuilder.render() {
        val item = state.item
        val rows = listOfNotNull(
            Row("Linie", item?.line),
            Row("Ziel", item?.destination),
            Row("Nächste Haltestelle", item?.nextStop),
            Row("Kurs", item?.course)
        )

        item?.status?.let { status ->
            styledDiv {
                css.paddingLeft = 2.spacingUnits
                timestampStatus(status)
            }
        }

        styledDiv {
            css { padding(2.spacingUnits) }
            mTableContainer {
                mTable {
                    mTableHead {
                        mTableRow {
                            mTableCell(
                                variant = MTableCellVariant.head,
                                padding = MTableCellPadding.none
                            ) { }
                            mTableCell(
                                variant = MTableCellVariant.head,
                                padding = MTableCellPadding.none
                            ) { +"Telegram" }
                            mTableCell(
                                variant = MTableCellVariant.head,
                                padding = MTableCellPadding.none
                            ) { +"Text" }
                            mTableCell(
                                variant = MTableCellVariant.head,
                                padding = MTableCellPadding.none
                            ) { +"Rohdaten" }
                        }
                    }

                    mTableBody {
                        for (row in rows) {
                            mTableRow {
                                styledTd {
                                    mTypography(
                                        text = row.title,
                                        variant = MTypographyVariant.caption
                                    )
                                }
                                styledTd {
                                    mTypography(
                                        text = row.telegram?.telegram ?: "-",
                                        variant = MTypographyVariant.body2
                                    )
                                }
                                styledTd {
                                    mTypography(
                                        text = row.telegram?.value ?: "-",
                                        variant = MTypographyVariant.body2
                                    )
                                }
                                styledTd {
                                    mTypography(
                                        text = row.telegram?.rawValue ?: "-",
                                        variant = MTypographyVariant.body2
                                    )
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
