package de.geomobile.frontend.features.map

import de.geomobile.common.portalmodels.DeviceIdentifier
import kotlinx.css.*
import kotlinx.css.properties.transform
import kotlinx.css.properties.translate
import kotlinx.html.js.onClickFunction
import kotlinx.html.unsafe
import react.RBuilder
import react.RProps
import react.RPureComponent
import react.RState
import styled.css
import styled.styledDiv
import kotlin.math.max

class MapCircleMarker : RPureComponent<MapCircleMarker.Props, RState>() {

    interface Props : RProps {
        var lat: Number
        var lng: Number

        var id: Int

        var radius: Number
        var onClick: (DeviceIdentifier) -> Unit
    }

    override fun RBuilder.render() {
        val strokeWidth = 1
        val markerSize = 30
        val size = max((props.radius.toDouble() + strokeWidth) * 2, markerSize.toDouble() * 2)

        val image = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAABeElEQVR4Ae3VgWZCURzH8QVjs7FnCBBB2ACu0EgA6zUGgYBArzDGniEF0AJU9ACrWhgAkTbDrvHbF4HRzl3d/7k37pcPgJ/LOfecZGVlxVoJLfSxwMfWAn20UELqqmMGRTRDHYmXxxja0xh5JFKAFXSgFQJ4LUAIxSRE4PPYrKCYrZCHaTmMICMj5GDWHWSLDcOmkLEpTCpBEfUQ4GIrQA+Khi2DWlAETeyqCbmxZdAAcujCVRdyGCR1/gO4CpK6B++QwyVcXUJ/Y8ugT38fwJZBb/6OEFsGPfu7xGwZ1Pb3G2XLoFt/DxlbBp1iDRlbgy2bniBbbBh2A9liw7gJZGQC86qQkSq8NIRiNoS3ylDMyvDaAIrJAN4rIoQOFKKIRGpDB2ojsc6whPa0xBkSrQLtqYJU9Aj90wNS0wVeoYhecI5UdY0QcvhCCamsATk0kNpy6EA7dJBDqrvCHPpljiscRQVsoK0NCjiqavjequEouwesysrK+gH0cKRwibPjMQAAAABJRU5ErkJggg=="
        // TODO: Clean up
        // marker in white:
        // "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAQAAAD9CzEMAAABbElEQVRYCe3Bv0pCcRjH4a9LUBzBa3AUWs5Q6w+HAnGt22hpcnPyFoKgawhdy7ECLyD7N7QLkdLQIfhEQyCvJ31/x9MQ9Dz691eQ0uWCB2bMeOCCLqnKwiF35LnjUOuizg3L3FBXcQQmrDIhqBgCGR4ZQfGoM8FrQl1xqHBNjGsqisEBsQ4UgzGxxvIjJc+AQEJCYECeVF50WdTRHDos6sqLIVZfBn2sobwYYwUZBKyxvJhiVWVQxZrKizesqgyqWG/y4hkryCBgPcuLS6y+DPpYl/Kix6KO5tBhUU9e7JNnQCAhITAgz7682OCFWC9syI8zYp0pBrvE2lUcRsQYKRYtYrQUjyu8rlQETbyaKoYhHkMVxTYZq2Rsqzh6rNLTOtjkiWWe2NR62GOZPa2PU35yojKQ8EieW7ZUDnbIsN5JVR6OsY5VJiqcM++cispFjXu+3VNT+WjwypdXGvodtPngg7Z+D0cc6d8f8wmMNY+vlv8KgAAAAABJRU5ErkJggg=="
        val svg = if (props.radius != 0)
            "<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='$size' height='${size}' viewBox='${-size / 2} ${-size / 2} $size $size'>" +
                    "<g id='Ebene_1' data-name='Ebene 1'>" +
                    "<circle class='cls-1 circle' cx='0' cy='0' r='${props.radius}' fill-opacity='0.5' fill='${
                        Color.lightGreen.value
                    }'/>" +
                    "</g>" +
                    "<g id='Ebene_2' data-name='Ebene 2'>" +
                    "<image width='$markerSize' height='$markerSize' opacity='1.0' transform='translate(${-markerSize / 2} ${-markerSize})' xlink:href='$image'/>" +
                    "</g>" +
                    "</svg>"
        else
            "<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='${size}' height='${size}' viewBox='${-size / 2} ${-size / 2} $size $size'>" +
                    "<g id='Ebene_1' data-name='Ebene 1'>" +
                    "<image width='$markerSize' height='$markerSize' opacity='1.0' transform='translate(${-markerSize / 2} ${-markerSize})' xlink:href='$image'/>" +
                    "</g>" +
                    "</svg>"


        styledDiv {
            css {
                position = Position.absolute
                transform {
                    translate((-size / 2).px, (-size / 2).px)
                }
                cursor = Cursor.pointer
            }
            attrs.onClickFunction = {
                props.onClick(DeviceIdentifier.Id(props.id))
            }
            attrs.unsafe {
                +svg
            }
        }

    }
}