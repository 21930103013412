package de.geomobile.frontend.utils

import de.geomobile.frontend.REST_API_BASE_URL
import kotlinx.html.id
import react.RBuilder
import react.dom.audio

fun RBuilder.audioPlayer(companyId: String, content: String, classId: String = "audio", controls:Boolean = true, sound: Boolean = false ) = audio {
    if(!sound)
        attrs.src = "$REST_API_BASE_URL/tts/preview/$companyId/voice?word=$content"
    else
        attrs.src = "$REST_API_BASE_URL/tts/preview/sound?id=$content"
    attrs.controls = controls
    attrs.id = classId
}
