package de.geomobile.frontend.features.device.detail

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonSize
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.dialog.DialogScroll
import com.ccfraser.muirwik.components.dialog.mDialog
import com.ccfraser.muirwik.components.dialog.mDialogActions
import com.ccfraser.muirwik.components.dialog.mDialogContent
import com.ccfraser.muirwik.components.form.MFormControlVariant
import com.ccfraser.muirwik.components.input.MInputProps
import com.ccfraser.muirwik.components.list.mListItem
import com.ccfraser.muirwik.components.list.mListItemSecondaryAction
import com.ccfraser.muirwik.components.list.mListItemText
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.utils.CComponent
import kotlinext.js.jsObject
import kotlinx.browser.document
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.css.*
import kotlinx.html.InputType
import react.RBuilder
import react.RProps
import react.RState
import react.setState
import styled.css
import styled.styledDiv

fun RBuilder.tableRowEditValue(
    label: String,
    loadValue: () -> String?,
    loadApplyPath: () -> String,
    allowEdit: Boolean,
    type: InputType = InputType.text,
) {
    child(EditValueComponent::class) {
        attrs.label = label
        attrs.loadValue = loadValue
        attrs.allowEdit = allowEdit
        attrs.loadApplyPath = loadApplyPath
        attrs.multiline = false
        attrs.type = type
    }
}

// TODO: Clean up
//fun RBuilder.rowEditValue(
//    label: String,
//    loadValue: () -> String?,
//    loadApplyPath: () -> String,
//    allowEdit: Boolean,
//    captionSize: MGridSize,
//    contentSize: MGridSize,
//    type: InputType = InputType.text,
//) = row(label, captionSize = captionSize, contentSize = contentSize) {
//
//    child(EditValueComponent::class) {
//        attrs.label = label
//        attrs.loadValue = loadValue
//        attrs.allowEdit = allowEdit
//        attrs.loadApplyPath = loadApplyPath
//        attrs.multiline = false
//        attrs.type = type
//    }
//}

// TODO: Clean up
//fun RBuilder.rowMultilineEditValue(
//    label: String,
//    loadValue: () -> String?,
//    loadApplyPath: () -> String,
//    allowEdit: Boolean,
//    captionSize: MGridSize = MGridSize.cells2,
//    contentSize: MGridSize = MGridSize.cells10,
//    type: InputType = InputType.text,
//) = row(label, captionSize = captionSize, contentSize = contentSize) {
//
//    child(EditValueComponent::class) {
//        attrs.label = label
//        attrs.loadValue = loadValue
//        attrs.allowEdit = allowEdit
//        attrs.loadApplyPath = loadApplyPath
//        attrs.multiline = true
//        attrs.type = type
//    }
//}

fun RBuilder.tableRowMultilineEditValue(
    label: String,
    loadValue: () -> String?,
    loadApplyPath: () -> String,
    allowEdit: Boolean,
    type: InputType = InputType.text,
) {
    child(EditValueComponent::class) {
        attrs.label = label
        attrs.loadValue = loadValue
        attrs.allowEdit = allowEdit
        attrs.loadApplyPath = loadApplyPath
        attrs.multiline = true
        attrs.asTableCell = true
        attrs.type = type
    }
}

interface EditValueComponentProps : RProps {
    var label: String
    var loadValue: () -> String?
    var loadApplyPath: () -> String
    var multiline: Boolean
    var allowEdit: Boolean
    var asTableCell: Boolean
    var type: InputType
}

class EditValueComponent : CComponent<EditValueComponentProps, RState>() {

    sealed class EditValueState {
        object Idle : EditValueState()
        data class Editing(val newValue: String, val applying: Boolean = false) : EditValueState()
    }

    private var editValue: Job = Job()
    private var renderState: EditValueState = EditValueState.Idle

    override fun RBuilder.render() {
        mListItem {
            attrs.button = false
            attrs.divider = true

            mListItemText {
                mTypography(
                    text = props.label,
                    variant = MTypographyVariant.caption,
                )
                mTypography(
                    text = props.loadValue() ?: "-",
                    variant = MTypographyVariant.subtitle2,
                    color = MTypographyColor.textPrimary
                )
            }
            if (props.allowEdit)
                mListItemSecondaryAction {
                    mTooltip("Bearbeiten") {
                        mIconButtonNoTranslate(
                            iconName = "edit",
                            color = MColor.inherit,
                            size = MButtonSize.small,
                            onClick = { show() },
                            addAsChild = true
                        ) {
                            css { background = "#2196f3"; color = Color.white; }
                        }
                    }
                }
        }

        val open = renderState is EditValueState.Editing
        val currentState = renderState

        mDialog(
            open = open,
            scroll = DialogScroll.paper,
            onClose = { _, _ -> hide() }
        ) {
            attrs.fullWidth = true
            attrs.disableEscapeKeyDown = true

            mDialogContent {
                if (currentState is EditValueState.Editing) {
                    if (props.multiline) {
                        mTextFieldMultiLine(
                            variant = MFormControlVariant.outlined,
                            label = props.label,
                            value = currentState.newValue,
                            onChange = {
                                changeValue(it.targetInputValue)
                            },
                            autoFocus = true
                        ) {
                            css { padding(0.spacingUnits) }
                            attrs.fullWidth = true
                            attrs.disabled = currentState.applying
                            attrs.inputProps = if (currentState.applying) {
                                jsObject<MInputProps> {
                                    endAdornment = styledDiv {
                                        mCircularProgress(color = MCircularProgressColor.secondary, size = 30.px)
                                    }
                                }
                            } else jsObject {}
                        }
                    } else {
                        mTextField(
                            variant = MFormControlVariant.outlined,
                            label = props.label,
                            value = currentState.newValue,
                            type = props.type,
                            onChange = {
                                changeValue(it.targetInputValue)
                            },
                            autoFocus = true
                        ) {
                            attrs.fullWidth = true
                            attrs.disabled = currentState.applying
                            attrs.inputProps = if (currentState.applying) {
                                jsObject<MInputProps> {
                                    endAdornment = styledDiv {
                                        css {
                                            marginLeft = 12.px
                                            marginTop = 5.px
                                        }
                                        mCircularProgress(color = MCircularProgressColor.secondary, size = 30.px)
                                    }
                                }
                            } else jsObject {}
                        }
                    }
                }
            }
            mDialogActions {
                mButton(
                    "Abbrechen",
                    color = MColor.primary,
                    onClick = {
                        hide()
                    }
                )
                mButton(
                    "Speichern",
                    disabled = currentState !is EditValueState.Editing,
                    color = MColor.primary,
                    onClick = {
                        if (currentState is EditValueState.Editing)
                            apply()
                    }
                )
            }
        }

        document.onkeydown = {
            if (open && it.keyCode == 13) // User hits enter
                apply()
        }
    }

    private fun show() {
        setState {
            val value = props.loadValue() ?: ""
            renderState = EditValueState.Editing(value)
        }
    }

    private fun hide() {
        setState {
            renderState = EditValueState.Idle
        }
    }

    private fun changeValue(newValue: String) {
        (renderState as? EditValueState.Editing)?.let {
            setState {
                renderState = it.copy(newValue = newValue)
            }
        }
    }

    private fun apply() {
        (renderState as? EditValueState.Editing)?.let {
            setState { renderState = it.copy(applying = true) }
            editValue.cancel()
            editValue = launch {
                launch(Dispatchers.Default) {

                    val path = props.loadApplyPath()
                    val body = it.newValue
                    portalRestApi.put(path, body)

                }.join()

                setState {
                    renderState = EditValueState.Idle
                }
            }
        }
    }
}