package de.geomobile.frontend.features.device.list

import com.ccfraser.muirwik.components.mSelect
import com.ccfraser.muirwik.components.menu.mMenuItem
import com.ccfraser.muirwik.components.spacingUnits
import com.ccfraser.muirwik.components.table.MTableCellVariant
import com.ccfraser.muirwik.components.table.mTableCell
import com.ccfraser.muirwik.components.targetValue
import de.geomobile.common.portalmodels.*
import de.geomobile.common.softwaremgmt.AssignmentUpdateState
import de.geomobile.common.stage.Stage
import de.geomobile.frontend.features.device.detail.deviceStatusLed
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.utils.CComponent
import de.geomobile.frontend.utils.grid.Filter
import de.geomobile.frontend.utils.grid.TableFilterRowProps
import de.geomobile.frontend.utils.grid.tableFilterRowCell
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.padding
import kotlinx.css.px
import kotlinx.serialization.builtins.ListSerializer
import react.RBuilder
import react.RState
import react.setState
import styled.css

class DeviceListFilterCell : CComponent<TableFilterRowProps.CellProps, DeviceListFilterCell.State>() {

    class State(
        var companies: List<Company> = emptyList(),
        var vehicleProfiles: List<VehicleProfileDTO> = emptyList(),
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        if (props.column.name == "company") {
            launch {
                val companies = withContext(Dispatchers.Default) {
                    portalRestApi.get("/admin/companies", serializer = ListSerializer(Company.serializer()))
                }

                setState { this.companies = companies }
            }
        }
        if (props.column.name == "vehicleProfile") {
            launch {
                val vehicleProfiles = withContext(Dispatchers.Default) {
                    portalRestApi.get("/vehicleprofiles", ListSerializer(VehicleProfileDTO.serializer()))
                }

                setState { this.vehicleProfiles = vehicleProfiles }
            }
        }
    }

    override fun RBuilder.render() {
        when (props.column.name) {
            "product" -> filterCellSelect(
                columnName = props.column.name,
                selection = props.filter?.value,
                values = Product.values().map { it.readableName }
            )

            "status", "betaStatus" -> filterCellSelect(
                columnName = props.column.name,
                operation = "selectStatus",
                selection = props.filter?.value,
                values = TimestampStatus.Status.values().map { it.ordinal.toString() },
                itemRenderer = { value ->
                    mMenuItem(value = value, button = true) {
                        deviceStatusLed(TimestampStatus.Status.values()[value.toInt()])
                    }
                }
            )

            "vehicleType" -> filterCellSelect(
                columnName = props.column.name,
                selection = props.filter?.value,
                values = VehicleType.values().map { it.readableName }
            )

            "company" -> filterCellSelect(
                columnName = props.column.name,
                selection = props.filter?.value,
                values = state.companies.map { it.name }
            )

            "vehicleProfile" -> filterCellSelect(
                columnName = props.column.name,
                selection = props.filter?.value,
                values = state.vehicleProfiles.map { it.name }
            )

            "stage" -> filterCellSelect(
                columnName = "stage",
                selection = props.filter?.value,
                values = Stage.values().map { it.readableName }
            )

            "updateState" -> filterCellSelect(
                columnName = "updateState",
                selection = props.filter?.value,
                values = AssignmentUpdateState.State.values().map { it.readableName }
            )

            else -> tableFilterRowCell(props)
        }
    }

    private fun RBuilder.filterCellSelect(
        columnName: String,
        operation: String? = null,
        selection: String?,
        values: List<String>,
        itemRenderer: RBuilder.(value: String) -> Unit = { value -> mMenuItem(primaryText = value, value = value) },
    ) {
        mTableCell(variant = MTableCellVariant.head) {
            css { padding(horizontal = 1.spacingUnits) }
            mSelect(
                value = selection ?: "<<all>>",
                fullWidth = true,
                onChange = { event, _ ->
                    props.onFilter(
                        (event.targetValue as String)
                            .takeIf { it != "<<all>>" }
                            ?.let { Filter(columnName = columnName, value = it, operation = operation) }
                    )
                }
            ) {
                mMenuItem(primaryText = "Alle", value = "<<all>>")
                for (value in values) {
                    itemRenderer(value)
                }
            }
        }
    }
}