package de.geomobile.frontend.features.repair

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.dialog.mDialog
import com.ccfraser.muirwik.components.dialog.mDialogActions
import com.ccfraser.muirwik.components.dialog.mDialogContent
import com.ccfraser.muirwik.components.form.MFormControlMargin
import com.ccfraser.muirwik.components.form.MFormControlVariant
import com.ccfraser.muirwik.components.form.margin
import com.ccfraser.muirwik.components.list.mListSubheader
import com.ccfraser.muirwik.components.menu.mMenuItem
import com.ccfraser.muirwik.components.styles.Breakpoint
import components.emptyView
import de.geomobile.common.permission.Permissions
import de.geomobile.common.portalmodels.*
import de.geomobile.common.time.LocalDateTime
import de.geomobile.common.utils.Tree
import de.geomobile.common.utils.emptyTree
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.spacer
import de.geomobile.frontend.utils.*
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.*
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.builtins.MapSerializer
import kotlinx.serialization.builtins.serializer
import kotlinx.serialization.json.Json
import portalmodels.*
import react.RBuilder
import react.RProps
import react.RState
import react.setState
import styled.StyledElementBuilder
import styled.css
import styled.styledDiv

fun RBuilder.repairView(
    openRepairsOnly: Boolean = true,
    newRepairsOnly: Boolean = false
) = child(RepairHistory::class) {
    attrs.openRepairsOnly = openRepairsOnly
    attrs.newRepairsOnly = newRepairsOnly
}

class RepairHistory : CComponent<RepairHistory.Props, RepairHistory.State>() {

    private var fetchCompaniesJob: Job = Job()
    private var fetchCompanyDataJob: Job = Job()
    private var fetchUsersJob: Job = Job()
    private var fetchAssembliesJob: Job = Job()

    private var commonRepairs =
        listOf("Kein WLAN", "Boot Schleife", "Schaltet nicht an", "Schaltet an aber ohne Funktion")

    interface Props : RProps {
        var openRepairsOnly: Boolean
        var newRepairsOnly: Boolean
    }

    class State(
        var companyTree: Tree<CompanySmall>? = null,
        var selectedCompany: CompanySmall? = null,
        var currentUser: UserDTO? = null,
        var repairData: List<RepairDTO>? = null,
        var variantAssemblyMap: Map<Int, List<ProductAssemblyDTO>> = mapOf(),
        var newRepairSerial: String? = null,
        var newRepairDescription: String? = null,
        var deleteDTO: RepairDTO? = null,
        var newRepairStep: RepairStepType? = null,
        var deleteDialog: Boolean = false,
        var warnDialog: String = "",
        var serialInputError: Boolean = false,
        var selectedCompanyDevices: List<DeviceDTO> = listOf(),
        var userMap: Map<String, UserDTO> = mapOf()
    ) : RState

    init {
        state = State()
    }

    private fun changeSelection(id: CompanySmall? = state.selectedCompany) {
        setState {
            selectedCompany = id
        }
        id?.let {
            fetchCompanyData()
        }
    }

    private fun fetchUsers() {
        try {
            fetchUsersJob.cancel()
            fetchUsersJob = launch {
                val users = portalRestApi.get("/admin/users", ListSerializer(UserDTO.serializer()))

                users.let { u ->
                    setState {
                        userMap = u.associateBy { it.id }
                    }
                }
            }
        } catch (ex: Exception) {
        }
    }

    private fun fetchAssemblyRelations() {
        try {
            fetchAssembliesJob.cancel()
            fetchAssembliesJob = launch {
                val assemblyList = portalRestApi.get(
                    "/portalsettings/assemblies/list",
                    ListSerializer(ProductAssemblyDTO.serializer())
                )
                val relations = portalRestApi.get(
                    "/portalsettings/assemblies/variant/relations",
                    MapSerializer(
                        Int.serializer(), // materialnumber/variant
                        ListSerializer(Int.serializer()) // assemblies
                    )
                )
                setState {
                    variantAssemblyMap = relations.mapValues { entry ->
                        entry.value.mapNotNull {
                            assemblyList.find { assembly -> assembly.id == it }
                        }
                    }
                }
            }
        } catch (ex: Exception) {

        }
    }

    private fun fetchCompanies() {
        fetchCompaniesJob.cancel()
        fetchCompaniesJob = launch {
            val companies =
                if (isAuthorized(Permissions.CompanyProfileManagement.notRestrictedToCompany))
                    portalRestApi.get("/admin/companies", ListSerializer(Company.serializer()))
                else
                    null

            companies?.let { c ->
                val treeNodes = c.map {
                    Tree.Node(it.small)
                }.toMutableList()
                treeNodes.add(0, Tree.Node(CompanySmall("all", "Alle")))

                setState {
                    companyTree = Tree(treeNodes)
                }
            } ?: setState { companyTree = emptyTree() }
        }
    }

    private fun fetchCompanyData() {
        fetchCompanyDataJob.cancel()
        fetchCompanyDataJob = launch {
            val companyRepairData = withContext(Dispatchers.Default) {
                state.selectedCompany?.let {
                    portalRestApi.get("/repair/${it.id}/companyId", ListSerializer(RepairDTO.serializer()))
                        .sortedBy { l -> l.serialNumber }
                }
            }
            val companyDevices = withContext(Dispatchers.Default) {
                state.selectedCompany?.let {
                    portalRestApi.get("/device/company/${it.id}", ListSerializer(DeviceDTO.serializer()))
                }
            }

            val variants = withContext(Dispatchers.Default) {
                portalRestApi.get("/devices/variants", ListSerializer(ProductVariantDTO.serializer()))
            }

            val repairsWithVariant = companyRepairData
                ?.map { repair ->
                    val device = companyDevices?.find { it.serialNumber == repair.serialNumber }
                    val variant = variants.find { it.materialNumber == device?.materialNumber && it.revision == device.revision }

                    variant?.let {
                        repair.copy(
                            productVariantId = it.id
                        )
                    } ?: repair
                }
                ?.sortedBy { it.repairSteps.firstOrNull()?.timestamp }

            companyDevices?.let {
                setState {
                    selectedCompanyDevices = it
                        .filter { it.serialNumber != null }
                        .sortedBy { it.serialNumber }
                }
            }
            companyRepairData?.let {
                setState {
                    repairData = repairsWithVariant
                }
            } ?: setState {
                repairData = listOf()
            }
        }
    }

    fun newRepairEntry(dto: RepairDTO) {
        launch {
            val response = withContext(Dispatchers.Default) {
                val body = Json.encodeToString(RepairDTO.serializer(), dto)
                portalRestApi.put("/repair/insert/new", body, GenericResponseDTO.serializer())
            }

            setState {
                if (response.isError)
                    warnDialog = response.message
            }
        }.invokeOnCompletion {
            fetchCompanyData()
        }
    }

    fun deleteRepair(dto: RepairDTO) {
        launch {
            withContext(Dispatchers.Default) {
                val body = Json.encodeToString(RepairDTO.serializer(), dto)
                portalRestApi.put("/repair/delete", body, GenericResponseDTO.serializer())
            }
        }.invokeOnCompletion {
            fetchCompanyData()
        }
    }

    override fun componentDidMount() {
        if (isAuthorized(Permissions.AdminPermissions.internalAccess)) {
            fetchUsers()
            fetchCompanies()
            fetchAssemblyRelations()
        }
        if (isAuthorized(Permissions.DeviceManagement.repairHistoryUser)) {
            launch {
                val user = portalRestApi.get("/user", UserDTO.serializer())
                if (user.company.id == "GEOMOBILE")
                    changeSelection(CompanySmall("all", "Alle"))
                else
                    changeSelection(user.company.small)
                setState {
                    currentUser = user
                }
            }
        }
    }

    override fun RBuilder.render() {
        dialogues()
        authorize(Permissions.DeviceManagement.repairHistoryUser) {
            spacer()
            mGridContainer2(direction = MGridDirection.row) {
                if (isAuthorized(Permissions.AdminPermissions.internalAccess)) {
                    mGridItem2(
                        MGridBreakpoints2(MGridSize2.Cells3)
                            .down(Breakpoint.md, MGridSize2.Cells12)
                    ) {
                        sideFilter()
                    }
                }
                mGridItem2(
                    MGridBreakpoints2(
                        if (isAuthorized(Permissions.AdminPermissions.internalAccess)) MGridSize2.Cells9 else MGridSize2.Cells12
                    )
                        .down(Breakpoint.md, MGridSize2.Cells12)
                ) {
                    detail()
                }
            }
        }
    }

    private fun StyledElementBuilder<MGridProps2>.sideFilter() {

        val tree: Tree<CompanySmall?>? = state.companyTree as Tree<CompanySmall?>?

        val entryTree = tree
            ?.mapByBranch { branch, (value) ->
                TreeEntry(
                    key = value?.id ?: "",
                    name = value?.name ?: "Neuer Filter",
                    new = value == null,
                    selected = value == state.selectedCompany,
                    expandedByDefault = branch.size <= 0,
                    onClick = {
                        value?.let {
                            changeSelection(it)
                        }
                    }
                )
            }

        mGridContainer2(direction = MGridDirection.column) {
            mGridItem2 {
                if (state.companyTree == null)
                    mSkeleton(
                        height = 100.px,
                        animation = MSkeletonAnimation.wave,
                        variant = MSkeletonVariant.rect
                    )
                else {
                    mCard {
                        css(GlobalStyles.card)
                        mCardContent {
                            css(GlobalStyles.cardListContent)
                            if (tree?.rootNodes?.isEmpty() == true)
                                emptyView(
                                    boxShadow = false,
                                    iconName = "menu",
                                    title = "Unternehmen",
                                    caption = "Es wurden keine Unternehmen gefunden",
                                    addButton = false
                                )
                            else {
                                tree(heading = "Unternehmen", tree = entryTree!!)
                            }
                        }
                    }
                }
            }
        }
    }

    private fun RBuilder.newRepair() {
        mGridContainer2(direction = MGridDirection.column) {
            mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                mCard {
                    css(GlobalStyles.card)
                    mCardContent {
                        css(GlobalStyles.cardBoxContent)
                        mListSubheader(heading = "Neue Reparatur")
                        mDivider { }
                        styledDiv {
                            css { padding(2.spacingUnits) }
                            mGridContainer2(direction = MGridDirection.row) {
                                mGridItem2(
                                    MGridBreakpoints2(MGridSize2.Cells8)
                                        .down(Breakpoint.xs, MGridSize2.Cells6)
                                        .up(Breakpoint.sm, MGridSize2.Cells6)
                                        .up(Breakpoint.md, MGridSize2.Cells8)
                                ) {
                                    mTextField(
                                        label = "",
                                        value = state.newRepairSerial,
                                        variant = MFormControlVariant.outlined,
                                        error = state.serialInputError,
                                        onChange = {
                                            val text = it.targetInputValue
                                            setState {
                                                selectedCompanyDevices
                                                    .sortedBy { s -> s.serialNumber }
                                                    .firstOrNull { f ->
                                                        f.serialNumber.toString() == text
                                                    }?.let { d ->
                                                        serialInputError = false
                                                        newRepairSerial = d.serialNumber.toString()
                                                    } ?: run {
                                                    serialInputError = true
                                                    newRepairSerial = text
                                                }
                                            }
                                        }
                                    ) {
                                        attrs.margin = MFormControlMargin.dense
                                        attrs.placeholder = "Seriennummer"
                                        attrs.fullWidth = true
                                        css { marginTop = 0.spacingUnits; paddingRight = 6.px }
                                    }
                                }
                                mGridItem2(
                                    MGridBreakpoints2(MGridSize2.Cells1)
                                        .down(Breakpoint.xs, MGridSize2.Cells2)
                                        .up(Breakpoint.sm, MGridSize2.Cells2)
                                        .up(Breakpoint.md, MGridSize2.Cells1)
                                ) {
                                    mSelect(
                                        variant = MFormControlVariant.outlined,
                                        value = "_select",
                                        onChange = { event, _ ->
                                            val newValue = event.targetValue as String
                                            setState {
                                                if (newValue != "_select")
                                                    newRepairSerial = newValue
                                            }
                                        }
                                    ) {
                                        attrs.margin = MFormControlMargin.dense.toString()
                                        attrs.fullWidth = true
                                        mMenuItem(
                                            primaryText = "#",
                                            value = "_select"
                                        )
                                        for (mode in state.selectedCompanyDevices) {
                                            mMenuItem(
                                                primaryText = mode.serialNumber.toString(),
                                                value = mode.serialNumber.toString()
                                            )
                                        }
                                    }
                                }
                                mGridItem2(
                                    MGridBreakpoints2(MGridSize2.Cells3)
                                        .down(Breakpoint.xs, MGridSize2.Cells4)
                                        .up(Breakpoint.xs, MGridSize2.Cells4)
                                        .up(Breakpoint.md, MGridSize2.Cells3)
                                ) {
                                    mSelect(
                                        variant = MFormControlVariant.outlined,
                                        value = state.newRepairStep ?: RepairStepType.ANNOUNCED,
                                        onChange = { event, _ ->
                                            val newValue = RepairStepType.valueOf(event.targetValue as String)
                                            setState {
                                                newRepairStep = newValue
                                            }
                                        }
                                    ) {
                                        attrs.margin = MFormControlMargin.dense.toString()
                                        attrs.fullWidth = true
                                        for (mode in RepairStepType.values()) {
                                            mMenuItem(
                                                primaryText = mode.readableName,
                                                value = mode.name,
                                                disabled =
                                                !isAuthorized(Permissions.AdminPermissions.internalAccess) &&
                                                        mode > RepairStepType.ANNOUNCED
                                            )
                                        }
                                    }
                                }
                                mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                                    mGridContainer2(direction = MGridDirection.row) {
                                        mGridItem2(
                                            MGridBreakpoints2(MGridSize2.Cells8)
                                                .down(Breakpoint.xs, MGridSize2.Cells6)
                                                .down(Breakpoint.sm, MGridSize2.Cells6)
                                                .up(Breakpoint.md, MGridSize2.Cells8)
                                        ) {
                                            mTextField(
                                                fullWidth = true,
                                                label = "",
                                                variant = MFormControlVariant.outlined,
                                                value = state.newRepairDescription,
                                                autoComplete = "on",
                                                margin = MFormControlMargin.dense,
                                                onChange = {
                                                    val text = it.targetInputValue
                                                    setState {
                                                        newRepairDescription = text
                                                    }
                                                }
                                            ) {
                                                attrs.margin = MFormControlMargin.dense
                                                attrs.placeholder = "Beschreibung"
                                                css { marginTop = 0.spacingUnits; paddingRight = 6.px }
                                            }
                                        }
                                        mGridItem2(
                                            MGridBreakpoints2(MGridSize2.Cells4)
                                                .down(Breakpoint.xs, MGridSize2.Cells6)
                                                .up(Breakpoint.sm, MGridSize2.Cells6)
                                                .up(Breakpoint.md, MGridSize2.Cells4)
                                        ) {
                                            mSelect(
                                                variant = MFormControlVariant.outlined,
                                                value = "_select",
                                                onChange = { event, _ ->
                                                    val text = event.targetValue as String
                                                    setState {
                                                        if (text != "_select")
                                                            newRepairDescription = text
                                                    }
                                                }
                                            ) {
                                                attrs.margin = MFormControlMargin.dense.toString()
                                                attrs.fullWidth = true
                                                mMenuItem(
                                                    primaryText = "Auswahl",
                                                    value = "_select",
                                                )
                                                val knownRepairs = commonRepairs + (state.repairData
                                                    ?.flatMap { it.repairSteps }
                                                    ?.distinctBy { it.description }
                                                    ?.map { it.description }
                                                    ?: listOf())

                                                for (mode in knownRepairs.distinct()) {
                                                    mMenuItem(
                                                        primaryText = mode,
                                                        value = mode,
                                                    )
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        mDivider { }
                        styledDiv {
                            css { padding(2.spacingUnits) }
                            mButton(
                                caption = "Übernehmen",
                                variant = MButtonVariant.contained,
                                color = MColor.secondary,
                                disabled = state.newRepairDescription.isNullOrBlank(),
                                onClick = {
                                    val dto = RepairDTO(
                                        id = 0,
                                        deviceId = 0,
                                        serialNumber = state.newRepairSerial?.toInt(),
                                        cpuId = "0",
                                        notified = false,
                                        repairSteps = listOf(
                                            RepairStepDTO(
                                                id = 0,
                                                createdBy = state.currentUser?.id,
                                                timestamp = LocalDateTime.now(),
                                                step = state.newRepairStep ?: RepairStepType.ANNOUNCED,
                                                description = state.newRepairDescription ?: "",
                                            )
                                        )
                                    )
                                    setState {
                                        if (newRepairDescription.isNullOrBlank())
                                            warnDialog = "Es muss eine Beschreibung angegeben werden."
                                        if (state.repairData?.any { (it.serialNumber == newRepairSerial?.toInt()) && it.active } == true)
                                            warnDialog =
                                                "Es gibt bereits einen aktiven Reparaturvorgang für dieses Gerät."
                                        else
                                            newRepairEntry(dto)
                                        newRepairSerial = ""
                                        newRepairDescription = ""
                                        newRepairStep = RepairStepType.ANNOUNCED
                                    }
                                }
                            ) {
                                attrs.disableElevation = true
                            }
                        }
                    }
                }
            }
        }
    }

    private fun RBuilder.detail() {
        mGridContainer2(direction = MGridDirection.column) {
            if (props.newRepairsOnly && state.selectedCompany?.id != "all")
                mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                    newRepair()
                }
            val splitList = state.repairData
                ?.filter {
                    (props.newRepairsOnly && !it.notified) || !props.newRepairsOnly
                }
                ?.filter {
                    props.openRepairsOnly && it.active || !props.openRepairsOnly && !it.active
                }
                ?.ifEmpty {
                    if (props.openRepairsOnly)
                        mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                            mCard {
                                css(GlobalStyles.card)
                                mCardContent {
                                    css(GlobalStyles.cardBoxContent)
                                    mListSubheader(heading = "Keine offenen Aufträge unter diesem Kunden")
                                }
                            }
                        }
                    else
                        mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                            mCard {
                                css(GlobalStyles.card)
                                mCardContent {
                                    css(GlobalStyles.cardBoxContent)
                                    mListSubheader(heading = "Keine geschlossenen Aufträge unter diesem Kunden")
                                }
                            }
                        }
                    null
                }
                ?.groupBy { it.serviceType }
            mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                mCard {
                    css(GlobalStyles.card)
                    mCardContent {
                        css(GlobalStyles.cardBoxContent)
                        mListSubheader(heading = "Garantiefall Reparaturen")
                        mDivider { }
                        splitList?.get(ServiceType.WARRANTY)?.forEach {
                            repairList(it)
                        }
                    }
                }
            }
            mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                mCard {
                    css(GlobalStyles.card)
                    mCardContent {
                        css(GlobalStyles.cardBoxContent)
                        mListSubheader(heading = "Servicefall Reparaturen")
                        mDivider { }
                        splitList?.get(ServiceType.SERVICE)?.forEach {
                            repairList(it)
                        }
                    }
                }
            }
        }
    }
    //TODO: Finish this
    private fun RBuilder.repairList(repair: RepairDTO) {
//        println("VariantMap: ${state.variantAssemblyMap}, Repair: ${repair.id}")
//        println("Repair Variant: ${repair.productVariantId}, eligible: ${state.variantAssemblyMap[repair.productVariantId]}")
        repairJob(
            openRepairsOnly = props.openRepairsOnly,
            newRepairsOnly = props.newRepairsOnly,
            repair = repair,
            assemblies = state.variantAssemblyMap[repair.productVariantId] ?: listOf(),
            updateTrigger = { fetchCompanyData() },
        )
    }

    private fun RBuilder.dialogues() {
        mDialog(
            open = state.warnDialog.isNotEmpty(),
            onClose = { _, _ -> setState { warnDialog = "" } }
        ) {
            mDialogContent { mTypography(text = state.warnDialog) }
            mDialogActions {
                mButton("Okay", onClick = { setState { warnDialog = "" } })
            }
        }
        mDialog(
            open = state.deleteDialog,
            onClose = { _, _ -> setState { deleteDialog = false } }
        ) {
            mDialogContent { mTypography("Reparatur unwiderruflich löschen? Sicher?") }
            mDialogActions {
                mButton("Nein, Abbrechen", onClick = { setState { deleteDialog = false } })
                mButton(
                    caption = "Ja, Bestätigen",
                    onClick = {
                        state.deleteDTO?.let { deleteRepair(it) }
                        setState { deleteDialog = false }
                    }
                ) { css { color = Color.red } }
            }
        }
    }
}

