package de.geomobile.frontend.features.device.detail

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.form.MFormControlMargin
import com.ccfraser.muirwik.components.form.MFormControlVariant
import com.ccfraser.muirwik.components.form.margin
import com.ccfraser.muirwik.components.input.MInputProps
import com.ccfraser.muirwik.components.list.mList
import com.ccfraser.muirwik.components.list.mListItem
import com.ccfraser.muirwik.components.list.mListItemText
import com.ccfraser.muirwik.components.list.mListSubheader
import com.ccfraser.muirwik.components.table.*
import de.geomobile.common.time.LocalDate
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.utils.CComponent
import de.geomobile.frontend.utils.mTableRowSlim
import de.geomobile.frontend.utils.toText
import kotlinext.js.jsObject
import kotlinx.coroutines.launch
import kotlinx.css.*
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.json.Json
import portalmodels.DeviceIndividualPartDTO
import react.*
import styled.css
import styled.styledDiv

fun RBuilder.registerParts(
    id: Int,
) = child(DeviceRegisterParts::class) {
    key = id.toString()
    attrs.id = id
}

class DeviceRegisterParts : CComponent<DeviceRegisterParts.Props, DeviceRegisterParts.State>() {

    private val CHARACTER_LIMIT = 54

    interface Props : RProps {
        var id: Int
    }

    class State(
        var identification: String = "",
        var data: List<DeviceIndividualPartDTO> = emptyList()
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        fetchParts()
    }

    fun fetchParts() {
        launch {
            val parts = portalRestApi.get("/device/${props.id}/individualparts", ListSerializer(DeviceIndividualPartDTO.serializer()))
            setState {
                data = parts
            }
        }
    }

    private fun String.onlyDigitsOrNull(): String? {
        if(this.any { !it.isDigit() })
            return null
        else
            return this
    }

    fun extractPartInformationFromCode(code: String): DeviceIndividualPartDTO? {

        val materialNumber  = code.substring(0, 6)  .toIntOrNull()      ?: return null
        val revision        = code.substring(6, 8)  .toIntOrNull()      ?: return null
        val subRevision     = code.substring(8, 9)  .toIntOrNull()      ?: return null
        val assembler       = code.substring(9, 12) .toIntOrNull()      ?: return null
        val codeDate        = code.substring(12, 18).onlyDigitsOrNull() ?: return null
        val orderNumber     = code.substring(18, 38).onlyDigitsOrNull() ?: return null
        val lotNumber       = code.substring(38, 48).onlyDigitsOrNull() ?: return null
        val serialNumber    = code.substring(48, 54).toIntOrNull()      ?: return null

        val day = codeDate.substring(0, 2)
        val month = codeDate.substring(2, 4)
        val year = "20${codeDate.substring(4, 6)}"
        val productionDate = "$year-$month-$day"

        return DeviceIndividualPartDTO(
            id = 0,
            deviceId = props.id,
            materialNumber = materialNumber,
            revision = revision,
            revisionChar = null,
            subRevision = subRevision,
            productionDate = LocalDate.parse(productionDate),
            circuitBoardAssembler = assembler,
            orderNumber = orderNumber,
            lotNumber = lotNumber,
            serialNumber = serialNumber
        )
    }

    fun saveIndividualPart(part: DeviceIndividualPartDTO) {
        launch {
            val body = Json.encodeToString(DeviceIndividualPartDTO.serializer(), part)
            portalRestApi.post("/device/${props.id}/registerpart", body)
        }.invokeOnCompletion {
            fetchParts()
        }
    }

    override fun RBuilder.render() {
        styledDiv {
            css { padding(2.spacingUnits) }

            styledDiv {
                css { padding(1.spacingUnits, 2.spacingUnits) }

                mTextField(
                    label = "Identifikationsnummer",
                    fullWidth = true,
                    variant = MFormControlVariant.outlined,
                    value = state.identification,
                    onChange = { event ->
                        val newValue = event.target.asDynamic().value as String
                        val digitsOnly = newValue.replace(Regex("[^\\d]"), "")

                        setState { identification = digitsOnly }
                    },
                    helperText = "${state.identification.length}/$CHARACTER_LIMIT"
                ) {
                    attrs.margin = MFormControlMargin.dense
                    attrs.inputProps = jsObject<MInputProps> {
                        inputProps = kotlinext.js.js {
                            maxLength = CHARACTER_LIMIT
                        }
                    }
                }
            }

            styledDiv {
                css { padding(1.spacingUnits, 2.spacingUnits) }
                mButton(
                    caption = "Erfassen",
                    color = MColor.secondary,
                    variant = MButtonVariant.contained,
                    disabled = state.identification.length < 54,
                    onClick = {
                        extractPartInformationFromCode(state.identification)?.let {
                            saveIndividualPart(it)
                        }
                        setState {
                            identification = ""
                        }
                    }
                ) {
                    attrs.disableElevation = true
                }
            }

            val lst = state.data
                .sortedBy { it.productionDate }
                .reversed()
                .distinctBy { it.materialNumber }

            mListSubheader(heading = "Elektrische Baugruppen (${lst.size})")

            if (state.data.isEmpty()) {
                mList {
                    attrs.disablePadding = true
                    attrs.dense = true

                    mListItem {
                        attrs.button = false

                        mListItemText(
                            primary = "Bisher keine einzelnen elektrischen Baugruppen verknüpft"
                        ) {
                            attrs.primaryTypographyProps = jsObject {
                                variant = MTypographyVariant.caption
                                color = MTypographyColor.textSecondary
                            }
                        }
                    }
                }
            }

            if (state.data.isNotEmpty()) {
                styledDiv {
                    css { padding(1.spacingUnits, 2.spacingUnits) }

                    mTableContainer {
                        mTable {
                            css {
                                width = LinearDimension.auto
                                whiteSpace = WhiteSpace.nowrap
                            }
                            mTableHead {
                                mTableRowSlim {
                                    mTableCell(
                                        align = MTableCellAlign.left,
                                        variant = MTableCellVariant.head,
                                        padding = MTableCellPadding.none,
                                    ) {
                                        css { padding(1.spacingUnits) }
                                        +"Materialnummer"
                                    }
                                    mTableCell(
                                        align = MTableCellAlign.left,
                                        variant = MTableCellVariant.head,
                                        padding = MTableCellPadding.none,
                                    ) {
                                        css { padding(1.spacingUnits) }
                                        +"Seriennummer"
                                    }
                                    mTableCell(
                                        align = MTableCellAlign.left,
                                        variant = MTableCellVariant.head,
                                        padding = MTableCellPadding.none,
                                    ) {
                                        css { padding(1.spacingUnits) }
                                        +"Produktionsdatum"
                                    }
                                }
                            }
                            mTableBody {

                                for (part in lst) {
                                    mTableRowSlim {
                                        mTableCell {
                                            css { padding(1.spacingUnits) }
                                            mTypography(
                                                text = part.materialNumber.toString().padStart(6, '0'),
                                                variant = MTypographyVariant.body2
                                            )
                                        }
                                        mTableCell {
                                            css { padding(1.spacingUnits) }
                                            mTypography(
                                                text = part.serialNumber.toString(),
//                                                text = part.serialNumber.toString().padStart(6, '0'),
                                                variant = MTypographyVariant.body2
                                            )
                                        }
                                        mTableCell {
                                            css { padding(1.spacingUnits) }
                                            mTypography(
                                                text = part.productionDate.toText(),
//                                                text = part.serialNumber.toString().padStart(6, '0'),
                                                variant = MTypographyVariant.body2
                                            )
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}