package portalmodels

import de.geomobile.common.portalmodels.InFunction
import de.geomobile.common.portalmodels.OutFunction
import de.geomobile.common.portalmodels.WiredType
import kotlinx.serialization.Serializable

@Serializable
data class WiringProfileDTO(
    val id: Int = -1,
    val name: String = "",
    val companyId: String = "",
    val in1: InFunction = InFunction.NONE,
    val in1SwitchHigh: Boolean = true,
    val in2: InFunction = InFunction.NONE,
    val in2SwitchHigh: Boolean = true,
    val in3: InFunction = InFunction.NONE,
    val in3SwitchHigh: Boolean = true,
    val in4: InFunction = InFunction.NONE,
    val in4SwitchHigh: Boolean = true,
    val out1: OutFunction = OutFunction.NONE,
    val out2: OutFunction = OutFunction.NONE,
    val out3: OutFunction = OutFunction.NONE,
    val out4: OutFunction = OutFunction.NONE,
    val out5: OutFunction = OutFunction.NONE,
    val speaker1: WiredType = WiredType.NONE,
    val speaker2: WiredType = WiredType.NONE,
    val speakerVolume: Int = 0,
    val can: WiredType = WiredType.NONE,
    val canBaud: Int = 0,
    val canTermination: Boolean = false,
    val ibisIP: Boolean = false,
    val ibisVDV300: WiredType = WiredType.NONE,
    val ibisReceive: Boolean = false,
    val ibisTransmit: Boolean = false,
    val ethernet: WiredType = WiredType.NONE,
    val gps: WiredType = WiredType.NONE,
)

@Serializable
data class WiringProfileMQTT(
    val id: Int = -1,
    val name: String = "",
    val companyId: String = "",
    val in1: InFunction = InFunction.NONE,
    val in1SwitchHigh: Boolean = true,
    val in2: InFunction = InFunction.NONE,
    val in2SwitchHigh: Boolean = true,
    val in3: InFunction = InFunction.NONE,
    val in3SwitchHigh: Boolean = true,
    val in4: InFunction = InFunction.NONE,
    val in4SwitchHigh: Boolean = true,
    val out1: List<OutFunction> = listOf(),
    val out2: List<OutFunction> = listOf(),
    val out3: List<OutFunction> = listOf(),
    val out4: List<OutFunction> = listOf(),
    val out5: List<OutFunction> = listOf(),
    val speaker1: WiredType = WiredType.NONE,
    val speaker2: WiredType = WiredType.NONE,
    val speakerVolume: Int = 0,
    val can: WiredType = WiredType.NONE,
    val canBaud: Int = 0,
    val canTermination: Boolean,
    val ibisIP: Boolean = false,
    val ibisVDV300: WiredType = WiredType.NONE,
    val ibisReceive: Boolean = false,
    val ibisTransmit: Boolean = false,
    val ethernet: WiredType = WiredType.NONE,
    val gps: WiredType = WiredType.NONE,
)

fun WiringProfileDTO.toMQTT() = WiringProfileMQTT(
    id = id,
    name = name,
    companyId = companyId,
    in1 = in1,
    in1SwitchHigh = in1SwitchHigh,
    in2 = in2,
    in2SwitchHigh = in2SwitchHigh,
    in3 = in3,
    in3SwitchHigh = in3SwitchHigh,
    in4 = in4,
    in4SwitchHigh = in4SwitchHigh,
    out1 = out1.toList(),
    out2 = out2.toList(),
    out3 = out3.toList(),
    out4 = out4.toList(),
    out5 = out5.toList(),
    speaker1 = speaker1,
    speaker2 = speaker2,
    speakerVolume = speakerVolume,
    can = can,
    canBaud = canBaud,
    canTermination = canTermination,
    ibisIP = ibisIP,
    ibisVDV300 = ibisVDV300,
    ibisReceive = ibisReceive,
    ibisTransmit = ibisTransmit,
    ethernet = ethernet,
    gps = gps
)

fun OutFunction.toList(): List<OutFunction> {
    if (this == OutFunction.SERVICE_STOP_REQUEST) {
        return listOf(OutFunction.SERVICE_REQUEST, OutFunction.STOP_REQUEST)
    } else
        return listOf(this)
}

@Serializable
data class LogCheck(
    val type: String,
    val duration: Int,
)

enum class InternetSource(val readableName: String) {
    NONE("Ohne Internet"),
    ETHERNET("Ethernet"),
    LTE("LTE"),
}


