package de.geomobile.frontend.features.tts

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonSize
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardActions
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.form.mFormControl
import com.ccfraser.muirwik.components.input.mInput
import com.ccfraser.muirwik.components.input.mInputLabel
import com.ccfraser.muirwik.components.lab.alert.MAlertSeverity
import com.ccfraser.muirwik.components.lab.alert.MAlertVariant
import com.ccfraser.muirwik.components.lab.alert.mAlert
import com.ccfraser.muirwik.components.menu.mMenuItem
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.spacer
import de.geomobile.frontend.utils.*
import kotlinext.js.assign
import kotlinx.browser.localStorage
import kotlinx.coroutines.launch
import kotlinx.css.*
import kotlinx.css.properties.LineHeight
import kotlinx.css.properties.borderTop
import kotlinx.datetime.DayOfWeek
import kotlinx.datetime.LocalTime
import kotlinx.html.js.onClickFunction
import kotlinx.serialization.json.Json
import org.w3c.dom.get
import portalmodels.GenericResponseDTO
import portalmodels.TtsScheduleDTO
import portalmodels.TtsScheduleEntryDTO
import react.RBuilder
import react.RProps
import react.RState
import react.dom.span
import react.router.dom.route
import react.setState
import styled.css
import styled.styledDiv
import kotlin.collections.set

fun RBuilder.textToSpeechSchedule() = child(TextToSpeechSchedule::class) {}

enum class ViewState {
    DETAILS, DEFAULT
}

data class TtsScheduleEntry(
    val day: DayOfWeek,
    val start: LocalTime,
    val end: LocalTime,
    val volume: Int
)

private val weekdays = mapOf(
    DayOfWeek.MONDAY to "Montag",
    DayOfWeek.TUESDAY to "Dienstag",
    DayOfWeek.WEDNESDAY to "Mittwoch",
    DayOfWeek.THURSDAY to "Donnerstag",
    DayOfWeek.FRIDAY to "Freitag",
    DayOfWeek.SATURDAY to "Samstag",
    DayOfWeek.SUNDAY to "Sonntag"
)

class TextToSpeechSchedule : CComponent<TextToSpeechSchedule.Props, TextToSpeechSchedule.State>() {
    interface Props : RProps

    class State : RState {
        var company: String? = null
        var schedule: TtsScheduleDTO = TtsScheduleDTO.empty
        var saveError: Boolean? = null
        var entries: MutableList<TtsScheduleEntry> = mutableListOf()
        var activeEntry: TtsScheduleEntry? = null
        var isDuplicateEntry: Boolean? = null
        var viewState: ViewState = ViewState.DEFAULT
        var selectedDay: DayOfWeek = DayOfWeek.MONDAY
        var startTime: String = "00:00"
        var endTime: String = "00:00"
        var volume: Int = 6
        var defaultVolume: Int = 1
    }

    init {
        state = State()
    }

    override fun componentDidMount() {
        val company = localStorage["TextToSpeechCompany"] ?: "GEOMOBILE"
        setState {
            this.company = company
        }
        fetchSchedule(company)
    }

    fun fetchSchedule(company: String?) {
        launch {
            val schedule = portalRestApi.get("/tts/schedule/${company}", TtsScheduleDTO.serializer())
            setState {
                this.schedule = schedule
                this.entries = convertFromDTO(schedule)
            }
        }
    }
    fun setSchedule() {
        launch {
            val body = Json.encodeToString(
                TtsScheduleDTO.serializer(), convertToDTO(state.entries)
            )
            val result = portalRestApi.post("/tts/schedule/${state.company}", body = body, GenericResponseDTO.serializer())
            setState({
                assign(it) {
                    saveError = result.isError
                    this.schedule = TtsScheduleDTO.empty
                    this.entries = mutableListOf()
                }
            }) {
                fetchSchedule(state.company)
            }
        }
    }

    private fun convertToDTO(entryList: List<TtsScheduleEntry>): TtsScheduleDTO {
        val entryMap = mutableMapOf<DayOfWeek, MutableList<TtsScheduleEntryDTO>>()

        DayOfWeek.values().forEach {
            entryMap[it] = mutableListOf()
        }

        entryList.forEach {
            entryMap[it.day]?.add(TtsScheduleEntryDTO("${it.start}", "${it.end}", it.volume))
        }

        return TtsScheduleDTO(
            scheduleId = state.schedule.scheduleId,
            defaultVolume = state.defaultVolume,
            monday    = entryMap[DayOfWeek.MONDAY]?.toList() ?: emptyList(),
            tuesday   = entryMap[DayOfWeek.TUESDAY]?.toList() ?: emptyList(),
            wednesday = entryMap[DayOfWeek.WEDNESDAY]?.toList() ?: emptyList(),
            thursday  = entryMap[DayOfWeek.THURSDAY]?.toList() ?: emptyList(),
            friday    = entryMap[DayOfWeek.FRIDAY]?.toList() ?: emptyList(),
            saturday  = entryMap[DayOfWeek.SATURDAY]?.toList() ?: emptyList(),
            sunday    = entryMap[DayOfWeek.SUNDAY]?.toList() ?: emptyList(),
        )
    }

    private fun convertFromDTO(schedule: TtsScheduleDTO): MutableList<TtsScheduleEntry> {
        val entryList: MutableList<Pair<DayOfWeek,TtsScheduleEntryDTO>> = mutableListOf()

        schedule.monday.forEach { entryList.add(Pair(DayOfWeek.MONDAY,it)) }
        schedule.tuesday.forEach { entryList.add(Pair(DayOfWeek.TUESDAY,it)) }
        schedule.wednesday.forEach { entryList.add(Pair(DayOfWeek.WEDNESDAY,it)) }
        schedule.thursday.forEach { entryList.add(Pair(DayOfWeek.THURSDAY,it)) }
        schedule.friday.forEach { entryList.add(Pair(DayOfWeek.FRIDAY,it)) }
        schedule.saturday.forEach { entryList.add(Pair(DayOfWeek.SATURDAY,it)) }
        schedule.sunday.forEach { entryList.add(Pair(DayOfWeek.SUNDAY,it)) }

        return entryList.map { (day, entry) ->
            TtsScheduleEntry(
                day = day,
                start = LocalTime.parse(entry.from),
                end = LocalTime.parse(entry.to),
                volume = entry.volume
            )
        }.toMutableList()
    }

    private fun generateTimeIntervals(): List<String> {
        val intervals = mutableListOf<String>()
        for (hour in 0..23) {
            for (minute in 0..45 step 15) {
                val formattedHour = if (hour < 10) "0$hour" else hour.toString()
                val formattedMinute = if (minute < 10) "0$minute" else minute.toString()
                intervals.add("$formattedHour:$formattedMinute")
            }
        }
        return intervals
    }

    private fun isOverlapping(day: DayOfWeek, startTime: LocalTime, endTime: LocalTime): Boolean {
        return state.entries.any { event ->
            event.day == day
                    && ((startTime.toSecondOfDay() >= event.start.toSecondOfDay()
                    && startTime.toSecondOfDay() < event.end.toSecondOfDay())
                    || (endTime.toSecondOfDay() > event.start.toSecondOfDay() && endTime.toSecondOfDay() <= event.end.toSecondOfDay()))
        }
    }

    private fun RBuilder.eventDetails() {
        state.activeEntry?.let { event ->
            mCard {
                mCardContent {
                    mFormControl {
                        css {
                            paddingRight = 1.spacingUnits
                            width = 100.px
                            margin = "0"
                        }
                        mInputLabel("Tag")
                        mInput(
                            value = weekdays[event.day],
                            disabled = true
                        )
                    }
                    mFormControl {
                        css {
                            paddingRight = 1.spacingUnits
                            width = 75.px
                            margin = "0"
                        }
                        mInputLabel("Start")
                        mInput(
                            value = event.start.toString(),
                            disabled = true
                        )
                    }
                    mFormControl {
                        css {
                            paddingRight = 1.spacingUnits
                            width = 75.px
                            margin = "0"
                        }
                        mInputLabel("Ende")
                        mInput(
                            value = event.end.toString(),
                            disabled = true
                        )
                    }
                    mFormControl {
                        css {
                            width = 75.px
                            margin = "0"
                        }
                        mInputLabel("Lautstärke")
                        mInput(
                            value = event.volume.toString(),
                            disabled = true
                        )
                    }
                }
                mDivider { }
                mCardActions {
                    mButton(
                        caption = "Löschen",
                        color = MColor.inherit,
                        size = MButtonSize.small,
                        variant = MButtonVariant.contained,
                        onClick = {
                            val eventToDelete = state.activeEntry
                            if (eventToDelete != null) {
                                setState({
                                    assign(it) {
                                        entries.remove(eventToDelete)
                                        activeEntry = null
                                        viewState = ViewState.DEFAULT
                                    }
                                }) {
                                    setSchedule()
                                }
                            }
                        }
                    ) {
                        attrs.disableElevation = true
                        css {
                            marginRight = 1.spacingUnits
                            backgroundColor = Color.red
                            color = Color.white
                        }
                    }
                    mButton(
                        caption = "Abbrechen",
                        size = MButtonSize.small,
                        variant = MButtonVariant.contained,
                        color = MColor.default,
                        onClick = {
                            setState {
                                activeEntry = null
                                viewState = ViewState.DEFAULT
                            }
                        }
                    ) {
                        attrs.disableElevation = true
                    }
                }
            }
        }
    }

    private fun RBuilder.eventCreate() {
        mCard {
            mCardContent {
                mFormControl {
                    css {
                        width = 75.px
                        margin = "0"
                        paddingBottom = 1.spacingUnits
                    }
                    mInputLabel("Standardlauststärke")
                    mSelect(
                        value = state.defaultVolume.toString(),
                        onChange = { event, _ ->
                            val value = event.target.asDynamic().value as String
                            setState({
                                assign(it) {
                                    saveError = null
                                    defaultVolume = value.toInt()
                                }
                            }) {
                                setSchedule()
                            }
                        }
                    ) {
                        (0..6).forEach { volumeLevel ->
                            mMenuItem(value = volumeLevel.toString()) {
                                +volumeLevel.toString()
                            }
                        }
                    }
                }
                mDivider {  }
                mFormControl {
                    css {
                        paddingRight = 1.spacingUnits
                    }
                    mInputLabel("Tag")
                    mSelect(
                        value = state.selectedDay.name,
                        onChange = { event, child ->
                            val value = event.target.asDynamic().value as String
                            setState {
                                saveError = null
                                selectedDay = DayOfWeek.valueOf(value)
                            }
                        }
                    ) {
                        DayOfWeek.values().forEach { day ->
                            mMenuItem(value = day.name) {
                                +weekdays[day].toString()
                            }
                        }
                    }
                }
                mFormControl {
                    css {
                        paddingRight = 1.spacingUnits
                        width = 75.px
                        margin = "0"
                    }
                    mInputLabel("Start")
                    mSelect(
                        value = state.startTime,
                        onChange = { event, _ ->
                            val value = event.target.asDynamic().value as String
                            setState {
                                saveError = null
                                startTime = value
                            }
                        }
                    ) {
                        generateTimeIntervals().forEach { timeInterval ->
                            mMenuItem(value = timeInterval) {
                                +timeInterval
                            }
                        }
                    }
                }
                mFormControl {
                    css {
                        paddingRight = 1.spacingUnits
                        width = 75.px
                        margin = "0"
                    }
                    mInputLabel("Ende")
                    mSelect(
                        value = state.endTime,
                        onChange = { event, _ ->
                            val value = event.target.asDynamic().value as String
                            setState {
                                saveError = null
                                endTime = value
                            }
                        }
                    ) {
                        generateTimeIntervals().forEach { timeInterval ->
                            mMenuItem(value = timeInterval) {
                                +timeInterval
                            }
                        }
                    }
                }
                mFormControl {
                    css {
                        width = 75.px
                        margin = "0"
                    }
                    mInputLabel("Lautstärke")
                    mSelect(
                        value = state.volume.toString(),
                        onChange = { event, _ ->
                            val value = event.target.asDynamic().value as String
                            setState {
                                saveError = null
                                volume = value.toInt()
                            }
                        }
                    ) {
                        (0..6).forEach { volumeLevel ->
                            mMenuItem(value = volumeLevel.toString()) {
                                +volumeLevel.toString()
                            }
                        }
                    }
                }
            }
            mDivider { }
            mCardActions {
                mButton(
                    caption = "Erstellen",
                    color = MColor.secondary,
                    variant = MButtonVariant.contained,
                    size = MButtonSize.small,
                    onClick = {
                        val newEvent = TtsScheduleEntry(
                            day = state.selectedDay,
                            start = LocalTime.parse(state.startTime),
                            end = LocalTime.parse(state.endTime),
                            volume = state.volume
                        )

                        if (isOverlapping(newEvent.day, newEvent.start, newEvent.end)) {
                            setState { isDuplicateEntry = true }
                        } else {
                            setState({
                                assign(it) {
                                    entries.add(newEvent)
                                    isDuplicateEntry = false
                                    viewState = ViewState.DEFAULT
                                    startTime = "00:00"
                                    endTime = "00:00"
                                }
                            }) {
                                setSchedule()
                            }
                        }
                    }
                ) {
                    attrs.disableElevation = true
                }
                when (state.saveError) {
                    true ->
                        mIconNoTranslate("error") {
                            css {
                                color = Color.red
                            }
                        }
                    false ->
                        mIconNoTranslate("check") {
                            css {
                                color = Color.green
                            }
                        }
                    else -> { }
                }
            }
        }
    }

    private fun RBuilder.weeklyCalendar() {
        mCard {
            css {
                paddingLeft = 1.spacingUnits
                display = Display.flex
                flexDirection = FlexDirection.column
                width = 100.pct
                maxHeight = 800.px
                overflowY = Overflow.auto
            }

            styledDiv {
                css {
                    display = Display.flex
                    flexDirection = FlexDirection.row
                    borderBottom = "1px solid #ccc"
                    height = 32.px
                }

                styledDiv {
                    css {
                        width = 50.px
                    }
                }

                DayOfWeek.values().forEach { day ->
                    styledDiv {
                        css {
                            width = 50.px
                            flex(1.0)
                            fontWeight = FontWeight.bolder
                            overflow = Overflow.hidden
                            textOverflow = TextOverflow.ellipsis
                            whiteSpace = WhiteSpace.nowrap
                            textAlign = TextAlign.center
                            lineHeight = LineHeight("30px")
                        }
                        +weekdays[day].toString()
                    }
                }
            }

            styledDiv {
                css {
                    display = Display.flex
                    flexDirection = FlexDirection.row
                    width = 100.pct
                    height = 768.px
                }

                styledDiv {
                    css {
                        width = 50.px
                        borderRight = "1px solid #ccc"
                    }

                    styledDiv {
                        css { height = 32.px }
                    }

                    (1..23).forEach { hour ->
                        styledDiv {
                            css {
                                height = 32.px
                                if (hour == 23) {
                                    borderBottom = "none"
                                }
                            }
                            span {
                                styledDiv {
                                    css {
                                        position = Position.relative
                                        display = Display.block
                                        top = (-6).px
                                    }
                                    +if (hour < 10) "0$hour:00" else "$hour:00"
                                }
                            }
                        }
                    }
                }

                DayOfWeek.values().forEach { day ->
                    styledDiv {
                        css {
                            flex(1.0)
                            borderRight = "1px solid #ccc"
                            position = Position.relative
                        }

                        (0..23).forEach { hour ->
                            styledDiv {
                                css {
                                    height = 32.px
                                    width = 100.pct
                                    backgroundColor = if (hour % 2 == 0) Color("#f5f5f5") else Color.white
                                }
                            }
                        }

                        val dayEvents = state.entries.filter { it.day == day }
                        dayEvents.forEach { event ->
                            val topPosition = (event.start.hour * 32) + (event.start.minute * 32 / 60)
                            val endOfDayInSeconds = 24 * 60 * 60
                            val eventDurationInMinutes =
                                if (event.end == LocalTime.parse("00:00")) {
                                    endOfDayInSeconds - event.start.toSecondOfDay()
                                } else {
                                    event.end.toSecondOfDay() - event.start.toSecondOfDay()
                                } / 60
                            val eventHeight = eventDurationInMinutes * 32 / 60

                            styledDiv {
                                attrs.onClickFunction = {
                                    setState {
                                        activeEntry = event
                                        viewState = ViewState.DETAILS
                                        isDuplicateEntry = false
                                    }
                                }
                                css {
                                    position = Position.absolute
                                    top = topPosition.px
                                    this.height = eventHeight.px
                                    borderTop(1.px, BorderStyle.solid, Color.white)
                                    width = 100.pct
                                    fontSize = 11.px
                                    padding(0.px, 3.px)
                                    borderRadius = 3.px
                                    overflow = Overflow.hidden
                                    cursor = Cursor.pointer
                                    backgroundColor = Color("#ee7203")
                                    hover {
                                        backgroundColor = Color("#d46b0d")
                                    }
                                }

                                when {
                                    eventDurationInMinutes == 15 -> {
                                    }

                                    eventDurationInMinutes <= 45 -> {
                                        styledDiv {
                                            css {
                                                color = Color.white
                                                fontSize = 12.px
                                                fontWeight = FontWeight.bold
                                                overflow = Overflow.hidden
                                                textOverflow = TextOverflow.ellipsis
                                                whiteSpace = WhiteSpace.nowrap
                                            }
                                            +"Lautstärke: ${event.volume}"
                                        }
                                    }

                                    else -> {
                                        styledDiv {
                                            css {
                                                color = Color.white
                                                fontSize = 12.px
                                                fontWeight = FontWeight.bold
                                                overflow = Overflow.hidden
                                                textOverflow = TextOverflow.ellipsis
                                                whiteSpace = WhiteSpace.nowrap
                                            }
                                            +"Lautstärke: ${event.volume}"
                                        }

                                        styledDiv {
                                            css {
                                                color = Color.white
                                                fontSize = 11.px
                                                fontWeight = FontWeight.bold
                                                overflow = Overflow.hidden
                                                textOverflow = TextOverflow.ellipsis
                                                whiteSpace = WhiteSpace.nowrap
                                            }
                                            +"${event.start} - ${event.end} Uhr"
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    override fun RBuilder.render() {
        route<RProps>("/tts/new/schedule") { _ ->
            spacer()
            mGridContainer2(direction = MGridDirection.row) {
                mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                    when (state.viewState) {
                        ViewState.DEFAULT -> eventCreate()
                        ViewState.DETAILS -> eventDetails()
                    }
                }
                if (state.isDuplicateEntry == true) {
                    mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                        mAlert(
                            message = "Dieser Zeitraum hat möglicherweise eine Überschneidung mit einer bestehenden TTS-Sequenz.",
                            variant = MAlertVariant.outlined,
                            severity = MAlertSeverity.warning
                        )
                    }
                }
                mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                    weeklyCalendar()
                }
            }
        }
    }

}