package de.geomobile.frontend.features.dashboard

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.list.mListSubheader
import com.ccfraser.muirwik.components.styles.Breakpoint
import com.ccfraser.muirwik.components.table.*
import de.geomobile.common.softwaremgmt.UpdateState
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.components.renderCountItem
import de.geomobile.frontend.components.statusCountView
import de.geomobile.frontend.utils.*
import de.geomobile.frontend.utils.MGridJustify
import de.geomobile.frontend.utils.charts.Column
import de.geomobile.frontend.utils.charts.chart
import kotlinx.css.*
import portalmodels.DashboardStatusSummaryDTO
import react.RBuilder
import react.RProps
import react.RState
import react.buildElement
import styled.css

//TODO: finish this, summaries.updateStatus is null
fun RBuilder.dashboardSoftware(
    company: String?,
    loading: Boolean,
    summaries: DashboardStatusSummaryDTO?
) = child(DashboardSoftware::class) {
    attrs.company = company
    attrs.loading = loading
    attrs.summaries = summaries
}

class DashboardSoftware : CComponent<DashboardSoftware.Props, DashboardSoftware.State>() {

    interface Props : RProps {
        var company: String?
        var loading: Boolean
        var summaries: DashboardStatusSummaryDTO?
    }

    class State : RState

    init {
        state = State()
    }

    private fun RBuilder.donutChart(data: Array<Array<Any>>?) {
        if (data!!.isEmpty())
            return

        chart(
            type = "PieChart",
            titleLabel = "",
            columns = arrayOf(
                Column(type = "string", label = "Task"),
                Column(type = "number", label = "Hours")
            ),
            rows = data
        ) {
            css {
                zIndex = 0
//                margin(2.spacingUnits)
            }
            attrs.options = js(
                """(
                    { 
                        'legend': { 
                            position: "right",
                            textStyle: { 
                                color: "#4d545d",
                                fontSize: "14",
                                bold: true
                            }
                        }, 
                        'colors': ["green", "#478a84", "orange", "red"], 
                        'sliceVisibilityThreshold': 0, 
                        'chartArea': { left: '3%', top: '3%', width: '100%', height: '94%' }
                    }
                )"""
            )
        }
    }

    fun RBuilder.softwareTableRow(title: String, color: Color, type: UpdateState.Type?, count: Int) =
        mTableRow {
            mTableCell(
                padding = MTableCellPadding.none,
                align = MTableCellAlign.left
            ) {
                mTypography(
                    text = title,
                    variant = MTypographyVariant.h6,
                    color = MTypographyColor.primary,
                    align = MTypographyAlign.left
                )
            }
            mTableCell(
                padding = MTableCellPadding.none,
                align = MTableCellAlign.center
            ) {
                renderCountItem(
                    count = count,
                    countColor = color,
                    size = 1.0.rem
                )
            }
            props.summaries?.softwareStatus?.forEach { (_, status) ->
                mTableCell(
                    padding = MTableCellPadding.none,
                    align = MTableCellAlign.center
                ) {
                    renderCountItem(
                        count = when(type) {
                            UpdateState.Type.INSTALLED -> status.installed
                            UpdateState.Type.WAITING_IN_QUEUE -> status.waitingInQueue
                            UpdateState.Type.FAILED -> status.failed
                            null -> status.outdated
                            else -> { 0 }
                        },
                        countColor = color,
                        size = 1.0.rem
                    )
                }
            }
        }

    override fun RBuilder.render() {
        if (props.loading && props.summaries == null)
            mSkeleton(
                height = 150.px,
                animation = MSkeletonAnimation.wave,
                variant = MSkeletonVariant.rect
            )
        else {
            mCard {
                css(GlobalStyles.card)
                mListSubheader(heading = "Software") {
                    css { textAlign = TextAlign.center }
                }
                mDivider { }
                mCardContent {
                    css(GlobalStyles.cardDashboardContent)

                    mGridContainer2(
                        direction = MGridDirection.column,
                        alignItems = MGridAlignItems.center,
                        spacing = MGridSpacing.spacing0
                    ) {
                        mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                            donutChart(
                                arrayOf(
                                    arrayOf("Aktuell", props.summaries?.softwareStatus?.values?.sumOf { it.installed } ?: 0),
                                    arrayOf("Ausstehend", props.summaries?.softwareStatus?.values?.sumOf { it.waitingInQueue } ?: 0),
                                    arrayOf("Veraltet", props.summaries?.softwareStatus?.values?.sumOf { it.outdated } ?: 0),
                                    arrayOf("Fehlerhaft", props.summaries?.softwareStatus?.values?.sumOf { it.failed } ?: 0),
                                )
                            )
                        }
                    }

                    mTable {
                        mTableHead {
                            mTableCell(
                                padding = MTableCellPadding.none,
                                align = MTableCellAlign.left
                            ) { css { width = 25.pct } }
                            mTableCell(
                                padding = MTableCellPadding.none,
                                align = MTableCellAlign.center
                            ) {
                                css { width = (75 / ((props.summaries?.softwareStatus?.size ?: 1) + 1)).pct }
                                mTypography(
                                    text = "GESAMT",
                                    variant = MTypographyVariant.h6,
                                    color = MTypographyColor.primary,
                                    align = MTypographyAlign.center
                                )
                            }
                            props.summaries?.softwareStatus?.forEach { (version, _) ->
                                mTableCell(
                                    padding = MTableCellPadding.none,
                                    align = MTableCellAlign.center
                                ) {
                                    css { width = (75 / ((props.summaries?.softwareStatus?.size ?: 1) + 1)).pct }
                                    mTypography(
                                        text = version,
                                        variant = MTypographyVariant.h6,
                                        color = MTypographyColor.primary,
                                        align = MTypographyAlign.center
                                    )
                                }
                            }
                        }
                        mTableBody {
                            softwareTableRow(
                                title = "AKTUELL",
                                count = props.summaries?.softwareStatus?.values?.sumOf { it.installed } ?: 0,
                                type  = UpdateState.Type.INSTALLED,
                                color = Color.green,
                            )
                            softwareTableRow(
                                title = "AUSSTEHEND",
                                count = props.summaries?.softwareStatus?.values?.sumOf { it.waitingInQueue } ?: 0,
                                type  = UpdateState.Type.WAITING_IN_QUEUE,
                                color = Color("#478a84"),
                            )
                            softwareTableRow(
                                title = "VERALTET",
                                count = props.summaries?.softwareStatus?.values?.sumOf { it.outdated } ?: 0,
                                type  = null,
                                color = Color.orange,
                            )
                            softwareTableRow(
                                title = "FEHLERHAFT",
                                count = props.summaries?.softwareStatus?.values?.sumOf { it.failed } ?: 0,
                                type  = UpdateState.Type.FAILED,
                                color = Color.red,
                            )
//                            mTableRow {
//                                mTableCell(
//                                    padding = MTableCellPadding.none,
//                                    align = MTableCellAlign.center
//                                ) { mTypography(text = "FEHLERHAFT", variant = MTypographyVariant.body1) }
//                                mTableCell(
//                                    padding = MTableCellPadding.none,
//                                    align = MTableCellAlign.center
//                                ) {
//                                    statusCountView(
//                                        breakpoints = MGridBreakpoints2(MGridSize2.Cells2),
//                                        children = buildElement {
//                                            renderCountItem(
//                                                count = props.summaries?.softwareStatus?.values?.sumOf { it.failed }
//                                                    ?: 0,
//                                                countColor = Color.red,
//                                                size = 1.0.rem
//                                            )
//                                        }
//                                    )
//                                }
//                                props.summaries?.softwareStatus?.forEach { (_, status) ->
//                                    mTableCell(
//                                        padding = MTableCellPadding.none,
//                                        align = MTableCellAlign.center
//                                    ) {
//                                        statusCountView(
//                                            breakpoints = MGridBreakpoints2(MGridSize2.Cells2),
//                                            children = buildElement {
//                                                renderCountItem(
//                                                    count = status.outdated,
//                                                    countColor = Color.red,
//                                                    size = 1.0.rem
//                                                )
//                                            }
//                                        )
//                                    }
//                                }
//                            }
                        }
                    }
                }
            }
        }
    }
}