package de.geomobile.frontend.features.softwareManagement.bundle

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonSize
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.card.mCardHeader
import com.ccfraser.muirwik.components.list.mList
import com.ccfraser.muirwik.components.list.mListItem
import com.ccfraser.muirwik.components.list.mListSubheader
import de.geomobile.common.portalmodels.DeviceIdentifier
import de.geomobile.common.portalmodels.Product
import de.geomobile.common.softwaremgmt.SoftwareAssignment
import de.geomobile.common.softwaremgmt.SoftwareAssignmentFilter
import de.geomobile.common.softwaremgmt.SoftwareBundle
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.utils.styledRouteLink
import de.geomobile.frontend.utils.toText
import kotlinext.js.jsObject
import kotlinx.css.*
import kotlinx.css.properties.TextDecoration
import react.*
import styled.css
import styled.styledDiv

fun RBuilder.bundleDetail(
    softwareBundle: SoftwareBundle,
    assignments: List<SoftwareAssignmentFilter>,
    overwrites: Map<DeviceIdentifier.Id, SoftwareAssignment>,
    product: Product
) = child(BundleDetail::class) {
    attrs.softwareBundle = softwareBundle
    attrs.assignments = assignments
    attrs.overwrites = overwrites
    attrs.key = softwareBundle.internalVersion
    attrs.product = product
}

class BundleDetail : RComponent<BundleDetail.Props, RState>() {

    interface Props : RProps {
        var softwareBundle: SoftwareBundle
        var assignments: List<SoftwareAssignmentFilter>
        var overwrites: Map<DeviceIdentifier.Id, SoftwareAssignment>
        var product: Product
    }

    override fun RBuilder.render() {
        mCard {
            css(GlobalStyles.card)
            mCardHeader(
                title = "${props.softwareBundle.internalVersion} (External: ${props.softwareBundle.externalVersion})",
                subHeader = "${props.softwareBundle.createdBy} (${props.softwareBundle.createdAt.toText()})",
                action = buildElement {
                    if (props.assignments.any() || props.overwrites.any())
                        styledDiv {}
                    else
                        styledRouteLink(
                            to = "/software/${props.product.readableName}/bundles/${props.softwareBundle.company.id}/${props.softwareBundle.internalVersion}/edit"
                        ) {
                            css {
                                textDecoration = TextDecoration.none
                                color = Color("#4d545d")
                            }
                            mIconButtonNoTranslate(
                                iconName = "edit",
                                size = MButtonSize.small,
                                color = MColor.inherit,
                            ) {
                                css {
                                    background = "#2196f3"
                                    color = Color.white
                                }
                            }
                        }
                }
            ) {
                attrs.titleTypographyProps = jsObject<MTypographyProps> {
                    variant = MTypographyVariant.subtitle2
                    color = MTypographyColor.secondary
                }
                attrs.subheaderTypographyProps = jsObject<MTypographyProps> {
                    variant = MTypographyVariant.caption
                }
            }
            mDivider { }
            bundleSoftwareList(
                softwareBundle = props.softwareBundle,
                product = props.product
            )
            mDivider { }
            mCardContent {
                css(GlobalStyles.cardContent)

                mListSubheader(heading = "Assignments")
                mList {
                    attrs.dense = true
                    css { padding(0.spacingUnits) }

                    if (props.assignments.isEmpty())
                        mListItem(primaryText = "Keine Assignments gefunden") {
                            attrs.disabled = true
                            attrs.divider = false
                        }

                    for (assignment in props.assignments) {
                        mListItem(
                            primaryText = assignment.name,
                            hRefOptions = HRefOptions(
                                href = "/software/${props.product.readableName}/assignments/${assignment.id}"
                            ),
                        ) { attrs.divider = false }
                    }
                }

                mListSubheader(heading = "Assignment Overwrites")
                mList {
                    attrs.dense = true
                    css { padding(0.spacingUnits) }

                    if (props.overwrites.isEmpty())
                        mListItem(primaryText = "Keine Assignment Overwrites gefunden") {
                            attrs.disabled = true
                            attrs.divider = false
                        }

                    for ((deviceId, assignment) in props.overwrites) {
                        mListItem(
                            primaryText = deviceId.id.toString(),
                            hRefOptions = HRefOptions(
                                href = "/portal/devices/${deviceId.id}"
                            ),
                        ) { attrs.divider = false }
                    }
                }
            }
        }
    }
}

fun RBuilder.bundleSoftwareList(
    softwareBundle: SoftwareBundle,
    product: Product
) = child(BundleSoftwareList::class) {
    attrs.softwareBundle = softwareBundle
    attrs.key = softwareBundle.internalVersion
    attrs.product = product
}

class BundleSoftwareList : RComponent<BundleSoftwareList.Props, RState>() {

    interface Props : RProps {
        var softwareBundle: SoftwareBundle
        var product: Product
    }

    override fun RBuilder.render() {
        mListSubheader(heading = "Liste")

        mList {
            css { padding(0.spacingUnits) }
            attrs.dense = true

            if (props.softwareBundle.software.isEmpty()) {
                mListItem(
                    primaryText = "Keine Software gefunden",
                    divider = false
                )
            } else {
                for (software in props.softwareBundle.software) {
                    styledRouteLink("/software/${props.product.readableName}/management/${software.software.id}/${software.softwareVersion.version}") {
                        css {
                            textDecoration = TextDecoration.none
                            color = Color("#4d545d")
                        }
                        mListItem(
                            primaryText = "${software.software.name} ${software.softwareVersion.version}",
                            divider = false
                        )
                    }
                }
            }
        }
    }
}