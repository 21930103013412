package de.geomobile.common.portalmodels

import kotlinx.serialization.Serializable

@Serializable
data class ResetPasswordDTO(
    val token: String,
    val password: String
)

@Serializable
data class RequestPasswordResetDTO(
    val email: String
)