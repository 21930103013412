package de.geomobile.frontend

import de.geomobile.frontend.api.ApiErrorInterceptor
import de.geomobile.frontend.api.RestApi
import de.geomobile.frontend.api.WebSocketApi
import de.geomobile.frontend.auth.AuthenticationProviderInterceptor
import kotlinx.browser.document
import kotlinx.browser.window
import react.dom.render

val REST_API_BASE_URL: String = window.asDynamic()._env_.REST_API_BASE_URL
val WEB_SOCKET_API_BASE_URL: String = window.asDynamic()._env_.WEB_SOCKET_API_BASE_URL
val PORTAL_ENV: String = window.asDynamic()._env_.PORTAL_ENV

external val navigator: dynamic

fun main() {
    window.onload = {
        val root = document.getElementById("root") ?: throw IllegalStateException()
        render(root) {
            println("REST_API_BASE_URL: $REST_API_BASE_URL")
            println("WEB_SOCKET_API_BASE_URL: $WEB_SOCKET_API_BASE_URL")
            println("PORTAL_ENV: $PORTAL_ENV")

            app()
        }
    }
}

val baseRestApi = RestApi(
    baseUrl = REST_API_BASE_URL,
    interceptors = listOf(
        UserStore.authenticationRequiredObserver,
        ApiErrorInterceptor,
        AuthenticationProviderInterceptor { UserStore.token }
    )
)

/* TODO: Clean up */
//val externalRestApi = RestApi(
//    baseUrl = "",
//    interceptors = listOf(
//        //ApiErrorInterceptor,
//        ExternalRestApiInterceptor
//    ),
//    timeout = 120000L
//)

val portalRestApi = baseRestApi.copy(baseUrl = "${baseRestApi.baseUrl}/portalapi")
/* TODO: Clean up */
//val externalRestApi = baseRestApi.copy(baseUrl = "")

val portalWebSocketApi = WebSocketApi(
    url = "$WEB_SOCKET_API_BASE_URL/portalapi/socket",
    authTokenProvider = { UserStore.token }
)