package de.geomobile.common.softwaremgmt

import de.geomobile.common.time.LocalDateTime
import kotlinx.serialization.Serializable

@Serializable
data class QueueDTO(
    val updateSlotCount:Int,
    val items: List<Item>
) {

    @Serializable
    data class Item(
        val id : String,
        val state: State,
        val since: LocalDateTime,
        val blockedUntil: LocalDateTime? = null
    )

    enum class State{
        WAIT, UPDATE, BLOCKED
    }
}