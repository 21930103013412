package de.geomobile.common.portalmodels

import de.geomobile.common.filter.FilterRules
import de.geomobile.server.device.config.DeviceConfigFilter
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonObject

@Serializable
data class AddDeviceConfigFilterDTO(
    val parentId: Int? = null,
    val name: String,
    val filter: FilterRules,
    val configOverlay: JsonObject,
    val deactivateConfigs: Boolean,
    val comment: String,
    val position: Int
)

@Serializable
data class ChangeDeviceConfigFilterDTO(
    val configFilter: DeviceConfigFilter,
    val comment: String,
    val parentId: Int? = null,
    val position: Int? = null
)