package de.geomobile.frontend.features.companyProfile

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonSize
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardActions
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.form.mFormControl
import com.ccfraser.muirwik.components.lab.alert.MAlertSeverity
import com.ccfraser.muirwik.components.lab.alert.MAlertVariant
import com.ccfraser.muirwik.components.lab.alert.mAlert
import com.ccfraser.muirwik.components.list.mListSubheader
import com.ccfraser.muirwik.components.styles.Breakpoint
import com.ccfraser.muirwik.components.table.*
import de.geomobile.common.permission.Permissions
import de.geomobile.common.portalmodels.UserDTO
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.spacer
import de.geomobile.frontend.utils.*
import kotlinext.js.js
import kotlinx.browser.localStorage
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.*
import kotlinx.html.InputType
import kotlinx.html.js.onChangeFunction
import kotlinx.html.js.onClickFunction
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.builtins.serializer
import kotlinx.serialization.json.Json
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.events.Event
import org.w3c.dom.get
import org.w3c.files.FileReader
import org.w3c.files.get
import portalmodels.FakeGpsDTO
import react.RBuilder
import react.RProps
import react.RState
import react.router.dom.route
import react.setState
import styled.css
import styled.styledDiv
import styled.styledInput

fun RBuilder.companyProfileGPS() = child(CompanyProfileGPS::class) {}

class CompanyProfileGPS : CComponent<CompanyProfileGPS.Props, CompanyProfileGPS.State>() {

    private var fetchFakeGPS: Job = Job()
    val fileReader = FileReader()

    interface Props : RProps

    class State(
        var company: String? = null,
        var fakeGPSList: List<FakeGpsDTO> = listOf(),
        var uploadError: Exception? = null,
        var uploadValue: String = "",
        var saveSuccess: Boolean = false,
        var isEnabled: Boolean = false,
        var changed: Boolean = false,
    ) : RState

    init {
        fileReader.onload = { e ->
            readCSV(e)
        }
    }

    override fun componentDidMount() {
        launch {
            loadCompany()
        }.invokeOnCompletion {
            fetchFakeGPS(state.company)
        }
    }

    suspend fun loadCompany() {
        val companyId =
            if (isAuthorized(Permissions.CompanyProfileManagement.notRestrictedToCompany) && localStorage["CompanyProfile"] != null) {
                localStorage["CompanyProfile"]
            } else {
                withContext(Dispatchers.Default) {
                    portalRestApi.get("/user", UserDTO.serializer())
                }.company.id
            }

        setState { company = companyId }
    }

    private fun fetchFakeGPS(companyId: String?) {
        fetchFakeGPS.cancel()
        fetchFakeGPS = launch {
            val fakeGPS = companyId?.let {
                portalRestApi.get("/companyprofile/fakegps/${companyId}", ListSerializer(FakeGpsDTO.serializer()))
            } ?: listOf()

            val status = companyId?.let {
                portalRestApi.get("/companyprofile/fakegps/${companyId}/enabled", Boolean.serializer())
            }

            setState {
                this.fakeGPSList = fakeGPS
                this.isEnabled = status == true
            }
        }
    }

    fun readCSV(e: Event) {
        try {
            val fr: FileReader = e.target as FileReader
            val result: String = fr.result as String

            val entries = result
                .trim()
                .split("\n", "\r\n")
                .toMutableList()

            if (entries[0].lowercase().startsWith("station"))
                entries.removeAt(0)

            val dtoList =
                state.company?.let { company ->
                    entries.mapIndexed { line, entry ->
                        entry
                            .split(",")
                            .let {
                                if (it.size != 4)
                                    throw Exception("Zeile ${line + 1}, hat ${it.size} Elemente. Erwartet: 4.")
                                if (it[0].isNullOrBlank() ||
                                    it[2].isNullOrBlank() ||
                                    it[3].isNullOrBlank()
                                ) throw Exception("Zeile ${line + 1} fehlen Pflichtelemente.")
                                if (it[2].toDoubleOrNull() == null ||
                                    it[3].toDoubleOrNull() == null
                                ) throw Exception("Line ${line + 1}, GPS Koordinaten ungültig.")
                                FakeGpsDTO(
                                    companyId = company,
                                    stationNormal = it[0],
                                    stationIbis = it[1].ifBlank { null },
                                    latitude = it[2].toDouble(),
                                    longitude = it[3].toDouble()
                                )
                            }
                    }
                } ?: listOf()

            setState {
                fakeGPSList = dtoList
                changed = true
            }

        } catch (ex: Exception) {
            setState {
                uploadError = ex
                changed = false
            }
        }
    }

    private fun saveFakeGPS(companyId: String?) {
        launch {
            if (state.fakeGPSList.isNotEmpty()) {
                companyId?.let {
                    val bodyProfile =
                        Json.encodeToJsonElement(ListSerializer(FakeGpsDTO.serializer()), state.fakeGPSList)
                    portalRestApi.put("/companyprofile/fakegps/${companyId}", bodyProfile, String.serializer())
                }
            }
            setState {
                saveSuccess = true
                state.fakeGPSList = state.fakeGPSList
            }
        }
    }

    private fun removeFakeGps(companyId: String?) {
        launch {
            companyId?.let {
                portalRestApi.getRaw("/companyprofile/fakegps/${companyId}/remove")
            }
            setState { saveSuccess = true }
        }
    }

    private fun setFakeGpsStatus(companyId: String?, enabled: Boolean) = launch {
        if (state.fakeGPSList.isNotEmpty()) {
            companyId?.let {
                val bodyProfile =
                    Json.encodeToJsonElement(Boolean.serializer(), enabled)
                portalRestApi.put("/companyprofile/fakegps/${companyId}/status", bodyProfile)
            }
        }
        setState {
            saveSuccess = true
        }
    }

    private fun RBuilder.gpsSettingsButtons() =
        mGridContainer2(direction = MGridDirection.row) {
            mGridItem2(
                MGridBreakpoints2(MGridSize2.Auto)
                    .down(Breakpoint.xs, MGridSize2.Cells12)
            ) {
                mButton(
                    variant = MButtonVariant.contained,
                    caption = "Übernehmen",
                    disabled = state.uploadError != null || !state.changed,
                    color = MColor.secondary,
                    onClick = {
                        setFakeGpsStatus(state.company, state.isEnabled).invokeOnCompletion {
                            if (state.fakeGPSList.isNotEmpty() && state.isEnabled) {
                                saveFakeGPS(state.company)
                            } else {
                                removeFakeGps(state.company)
                            }
                            setState {
                                changed = false
                            }
                        }
                    }
                ) {
                    attrs.disableElevation = true
                    attrs.fullWidth = true
                }
            }
        }

    private fun RBuilder.gpsSettingsTable() =
        mGridContainer2(
            direction = MGridDirection.row,
            alignItems = MGridAlignItems.baseline
        ) {
            mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                styledDiv {
                    css { padding(2.spacingUnits, 2.spacingUnits, 0.px, 2.spacingUnits) }
                    styledDiv {
                        css {
                            padding(0.spacingUnits)
                            justifyItems = JustifyItems.center
                            display = Display.flex
                            alignItems = Align.flexStart
                        }
                        mTypography(
                            text = "Liste",
                            variant = MTypographyVariant.caption
                        ) {
                            css { display = Display.block; marginBottom = 6.px }
                        }
                    }
                    styledInput {
                        css {
                            padding(2.spacingUnits)
                            width = 100.pct
                            border = "2px dashed #eeeeee"
                            borderRadius = 3.px
                        }
                        attrs.name = "upload"
                        attrs.type = InputType.file
                        attrs.accept = ".csv,.txt"
                        attrs.multiple = false
                        attrs.onChangeFunction = { event ->
                            var fileEvent = event.target as HTMLInputElement
                            var files = fileEvent.files
                            setState {
                                fakeGPSList = emptyList()
                                uploadError = null
                                files?.let {
                                    for (i in 0 until it.length) {
                                        fileReader.readAsText(it[i]!!)
                                    }
                                }

                            }
                        }
                        attrs.onClickFunction = {
                            js { it.target.asDynamic().value = null }
                        }
                    }
                    mTypography(
                        text = "Die CSV-Datei sollte folgende Daten enthalten: Haltestelle (Normal), Haltestelle (IBIS), Latitude und Longitude. Die Dezimaltrennung der Koordinaten muss mit einem Punkt erfolgen. Die CSV-Datei muss das UTF-8 Format haben und Komma separiert sein.",
                        variant = MTypographyVariant.caption,
                        color = MTypographyColor.textSecondary
                    ) { css { display = Display.block; marginTop = 2.spacingUnits } }
                }
            }
            mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                styledDiv {
                    css { padding(0.px, 2.spacingUnits, 1.spacingUnits, 3.spacingUnits) }
                    mFormControl(fullWidth = true) {
                        mSwitchWithLabel(
                            label = if (state.isEnabled) "Aktiviert" else "Deaktiviert",
                            checked = state.isEnabled,
                            onChange = { _, checked ->
                                setState {
                                    isEnabled = checked
                                    changed = true
                                }
                            }
                        )
                    }
                }
            }
        }

    private fun RBuilder.gpsSettings() =
        mCard {
            css(GlobalStyles.card)
            mCardContent {
                css(GlobalStyles.cardContent)
                mListSubheader(heading = "Einstellungen")
                mDivider { }
                gpsSettingsTable()
            }
            mDivider { }
            mCardActions {
                css { padding(2.spacingUnits) }
                gpsSettingsButtons()
            }
        }

    private fun RBuilder.gpsListTable(fakeGPS: List<FakeGpsDTO>) =
        mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
            mCard {
                css(GlobalStyles.card)
                mCardContent {
                    css(GlobalStyles.cardListItemContent)
                    styledDiv {
                        css {
                            display = Display.flex
                            justifyContent = JustifyContent.spaceBetween
                            alignItems = Align.center
                        }
                        mListSubheader(heading = "GPS Daten")
                        mButton(
                            caption = "Löschen",
                            size = MButtonSize.small,
                            color = MColor.inherit,
                            variant = MButtonVariant.outlined,
                            onClick = {
                                setState {
                                    fakeGPSList = listOf()
                                    changed = true
                                }
                            }
                        ) {
                            attrs.disableElevation = true
                            css { margin(0.spacingUnits, 1.spacingUnits); color = Color.red }
                        }
                    }
                    mDivider { }

                    mTableContainer {
                        mTable {
                            mTableHead {
                                mTableRowSlim {
                                    mTableCell(align = MTableCellAlign.left) {
                                        css { padding(2.spacingUnits) }
                                        +"Haltestelle (Normal)"
                                    }
                                    mTableCell(align = MTableCellAlign.left) {
                                        css { padding(2.spacingUnits) }
                                        +"Haltestelle (IBIS)"
                                    }
                                    mTableCell(align = MTableCellAlign.left) {
                                        css { padding(2.spacingUnits) }
                                        +"Latitude"
                                    }
                                    mTableCell(align = MTableCellAlign.left) {
                                        css { padding(2.spacingUnits) }
                                        +"Longitude"
                                    }
                                }
                            }
                            mTableBody {
                                fakeGPS?.forEach { gps ->
                                    mTableRowSlim {
                                        mTableCell(align = MTableCellAlign.left) {
                                            css { padding(0.spacingUnits, 2.spacingUnits) }
                                            +gps.stationNormal
                                        }
                                        gps.stationIbis?.let {
                                            mTableCell(align = MTableCellAlign.left) {
                                                css { padding(0.spacingUnits, 2.spacingUnits) }
                                                +it
                                            }
                                        } ?: mTableCell { "" }
                                        mTableCell(align = MTableCellAlign.left) {
                                            css { padding(0.spacingUnits, 2.spacingUnits) }
                                            +gps.latitude.toString()
                                        }
                                        mTableCell(align = MTableCellAlign.left) {
                                            css { padding(0.spacingUnits, 2.spacingUnits) }
                                            +gps.longitude.toString()
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

    private fun RBuilder.gpsSettingsError() =
        styledDiv {
            mAlert(
                variant = MAlertVariant.filled,
                severity = MAlertSeverity.error,
                message = state.uploadError?.message ?: "Es ist ein Fehler aufgetreten"
            ) {
                css {
                    marginBottom = 1.spacingUnits
                    ":last-child" { marginBottom = 0.px }
                }
            }
        }

    override fun RBuilder.render() {
        authorize(Permissions.CompanyProfileManagement.profileView) {
            route<RProps>("/companyprofile/system/fakegps") { _ ->
                spacer()
                mGridContainer2(direction = MGridDirection.column) {
                    mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                        gpsSettings()
                    }
                    if (state.uploadError != null)
                        mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                            gpsSettingsError()
                        }

                    if (!state.fakeGPSList.isNullOrEmpty()) {
                        mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                            mGridContainer2(direction = MGridDirection.row) {
                                gpsListTable(state.fakeGPSList)
                            }
                        }
                    }
                }
            }
        }
    }
}
