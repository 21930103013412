package de.geomobile.frontend.features.vehicleProfile

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonSize
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardActions
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.dialog.DialogScroll
import com.ccfraser.muirwik.components.dialog.mDialog
import com.ccfraser.muirwik.components.dialog.mDialogActions
import com.ccfraser.muirwik.components.dialog.mDialogContent
import com.ccfraser.muirwik.components.form.MFormControlMargin
import com.ccfraser.muirwik.components.form.MFormControlVariant
import com.ccfraser.muirwik.components.form.margin
import com.ccfraser.muirwik.components.list.*
import com.ccfraser.muirwik.components.menu.mMenuItem
import com.ccfraser.muirwik.components.styles.Breakpoint
import com.ccfraser.muirwik.components.table.*
import components.emptyView
import de.geomobile.common.permission.Permissions
import de.geomobile.common.portalmodels.UserDTO
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.spacer
import de.geomobile.frontend.utils.*
import kotlinx.browser.localStorage
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.*
import kotlinx.html.InputType
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.json.Json
import org.w3c.dom.get
import portalmodels.*
import react.RBuilder
import react.RProps
import react.RState
import react.setState
import styled.css
import styled.styledDiv

fun RBuilder.vehicleProfilesIBIS(
    profileId: String,
) = child(VehicleProfilesIBIS::class) {
    attrs.profileId = profileId
}

class VehicleProfilesIBIS : CComponent<VehicleProfilesIBIS.Props, VehicleProfilesIBIS.State>() {

    private var companyJob: Job = Job()
    private var fetchIbisTelegramsJob: Job = Job()
    private var fetchIbisProfilesJob: Job = Job()
    private var loadJob: Job = Job()
    private var saveJob: Job = Job()
//    private var telegramList = listOf(
//        "DS001",
//        "DS001c",
//        "DS001d",
//        "DS001e",
//        "DS001f",
//        "DS002",
//        "DS003a",
//        "DS003g",
//        "DS003h",
//        "DS004",
//        "DS004a",
//        "DS010b",
//        "DS021",
//        "DS021a",
//        "DS055",
//        "DS060",
//        "DS063",
//        "DS064",
//        "DS064b",
//        "DS066c",
//        "DS066d",
//        "DS066e",
//        "DS100a",
//        "DS202",
//        "DS201",
//        "DS220",
//    )

    interface Props : RProps {
        var profileId: String
    }

    class State(
        var company: String? = null,
        var telegramList: List<IbisTelegramSettingsDTO>? = null,
        var profiles: MutableList<IbisProfileDTO> = mutableListOf(),
        var selectedEntries: MutableMap<Pair<IbisFunction,String>, IbisProfileEntryDTO> = mutableMapOf(),
        var selectedProfile: IbisProfileDTO? = null,
        var newProfileName: String? = null,
//        var selectedEntries: MutableList<IbisProfileDTO> = mutableListOf(),
        var saveSuccess: Boolean = false,
        var editable: Boolean = true,
        var deleteProfileDialog: Boolean = false,
        var deleteProfileCandidate: IbisProfileDTO? = null,
        var warnDialog: String? = null,
        var telegramFilterList: List<IbisTelegramFilterDTO> = emptyList(),
        var selectedTelegramFilter: IbisTelegramFilterDTO? = null,
        var deleteTelegramFilterCandidate: IbisTelegramFilterDTO? = null,
        var deleteTelegramFilterDialog: Boolean = false,
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        loadCompany()
        fetchIbisTelegrams()
    }

    fun loadCompany() {
        companyJob.cancel()
        companyJob = launch {
            val companyId =
                if (isAuthorized(Permissions.VehicleProfileManagement.notRestrictedToCompany) && localStorage["VehicleProfileCompany"] != null) {
                    localStorage["VehicleProfileCompany"]
                } else {
                    withContext(Dispatchers.Default) {
                        portalRestApi.get("/user", UserDTO.serializer())
                    }.company.id
                }
            setState {
                company = companyId
            }
            companyId?.let {
                fetchProfiles(companyId)
                fetchFilter(companyId)
            }
        }
    }

    private fun fetchIbisTelegrams() {
        fetchIbisTelegramsJob.cancel()
        fetchIbisTelegramsJob = launch {
            val settings = portalRestApi.get(
                path = "/portalsettings/ibis/getlist",
                serializer = ListSerializer(IbisTelegramSettingsDTO.serializer())
            )
            val sorted = settings.sortedBy { it.telegram }

            setState {
                telegramList = sorted
            }
        }
    }

    private fun fetchFilter(companyId: String?) {
        launch {
            val filters = portalRestApi.get(
                path = "/vehicleprofiles/ibis/filter/${companyId}",
                serializer = ListSerializer(IbisTelegramFilterDTO.serializer())
            )
            setState {
                telegramFilterList = filters
                selectedTelegramFilter = filters.lastOrNull()
            }
        }
    }

    private fun deleteFilter(filter: IbisTelegramFilterDTO?) {
        launch {
            filter?.let {
                val bodyProfile = Json.encodeToJsonElement(IbisTelegramFilterDTO.serializer(), filter)
                portalRestApi.delete(
                    path = "/vehicleprofiles/ibis/filter",
                    body = bodyProfile
                )
            }
        }.invokeOnCompletion {
            fetchFilter(state.company)
        }
    }

    private fun fetchEntries(profileId: Int) {
        fetchIbisProfilesJob.cancel()
        fetchIbisProfilesJob = launch {
            val entries = portalRestApi.get(
                path = "/vehicleprofiles/ibis/entries/${profileId}",
                serializer = ListSerializer(IbisProfileEntryDTO.serializer())
            )

            setState {
                selectedEntries = entries.associateBy { Pair(it.ibisFunction,it.telegramType) }.toMutableMap()
            }

        }
    }

    private fun fetchProfiles(companyId: String?) {
        fetchIbisProfilesJob.cancel()
        fetchIbisProfilesJob = launch {
            val profiles = portalRestApi.get(
                path = "/vehicleprofiles/ibis/profiles/${companyId}",
                serializer = ListSerializer(IbisProfileDTO.serializer())
            )

            setState {
                this.profiles = profiles.toMutableList()
                if (selectedProfile == null) {
                    selectedProfile = this.profiles.lastOrNull()
                    newProfileName = selectedProfile?.profileName
                }
            }
        }
        fetchIbisProfilesJob.invokeOnCompletion {
            state.selectedProfile?.let {
                fetchEntries(it.profileId)
            }
        }
    }

    private fun deleteProfile(profile: IbisProfileDTO?) {
        launch {
            portalRestApi.delete(
                path = "/vehicleprofiles/ibis/profiles/${profile?.profileId}/delete",
                body = GenericResponseDTO.serializer()
            )
        }.invokeOnCompletion {
            fetchProfiles(state.company)
        }
    }

    private fun saveProfile(profile: IbisProfileDTO?) {
        launch {
            profile?.let {
                val bodyProfile = Json.encodeToJsonElement(IbisProfileDTO.serializer(), profile)
                portalRestApi.put(
                    path = "/vehicleprofiles/ibis/profile/${state.company}",
                    body = bodyProfile,
                    serializer = IbisProfileDTO.serializer()
                )
            }
        }.invokeOnCompletion {
            fetchProfiles(state.company)
        }
    }

    private fun saveFilter(filter: IbisTelegramFilterDTO?) {
        launch {
            filter?.let {
                val bodyProfile = Json.encodeToJsonElement(IbisTelegramFilterDTO.serializer(), filter)
                portalRestApi.put(
                    path = "/vehicleprofiles/ibis/filter",
                    body = bodyProfile,
                    serializer = IbisTelegramFilterDTO.serializer()
                )
            }
        }.invokeOnCompletion {
            fetchFilter(state.company)
        }
    }

    private fun saveEntries(profileId: Int, entries: List<IbisProfileEntryDTO>?) {
        launch {
            val modified = entries?.map { it.copy(profileId = profileId)}
            modified?.let {
                val bodyEntries = Json.encodeToJsonElement(ListSerializer(IbisProfileEntryDTO.serializer()), it)
                portalRestApi.put(
                    "/vehicleprofiles/ibis/entries/$profileId",
                    bodyEntries,
                    GenericResponseDTO.serializer()
                )
            }
            setState {
                saveSuccess = true
            }
        }
    }

    override fun RBuilder.render() {
        authorize(Permissions.VehicleProfileManagement.ibisProfileView) {
            spacer()
            mGridContainer2(direction = MGridDirection.row) {
                mGridItem2(
                    MGridBreakpoints2(MGridSize2.Cells3).down(Breakpoint.md, MGridSize2.Cells12)
                ) {
                    sideBar()
                    filterBar()
                }

                mGridItem2(
                    MGridBreakpoints2(MGridSize2.Cells9).down(Breakpoint.md, MGridSize2.Cells12)
                ) {
                    detail()
                }
            }
        }
    }

    fun RBuilder.detail() {
        mGridContainer2(direction = MGridDirection.row) {
            mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                mCard {
                    css(GlobalStyles.card)
                    mCardContent {
                        css(GlobalStyles.cardListContent)
                        if (state.profiles.isEmpty()) {
                            emptyView(
                                title = "IBIS Profil",
                                caption = "Es wurden keine Profile gefunden",
                                addButton = false,
                            )
                        } else {
                            mListSubheader(
                                heading = "Profil: ${state.selectedProfile?.profileName ?: "k.A."}"
                            )
                            mDivider { }
                            styledDiv {
                                css { padding(2.spacingUnits) }

                                mTextField(
                                    id = "name",
                                    variant = MFormControlVariant.outlined,
                                    label = "Name",
                                    disabled = !state.editable,
                                    type = InputType.text,
                                    value = state.newProfileName ?: state.selectedProfile?.profileName ?: "",
                                    onChange = {
                                        val value = it.targetInputValue
                                        //val newProfile = state.selectedProfile?.copy(profileName = value)
                                        setState {
                                            newProfileName = value
                                        }
                                    },
                                    autoFocus = true
                                ) {
                                    attrs.margin = MFormControlMargin.dense;
                                    attrs.fullWidth = true
                                }
                            }
                            mDivider { }

                            styledDiv {
                                css {
                                    maxWidth = 800.px
                                    padding(2.spacingUnits)
                                }
                                mTypography(
                                    text = "Sondereinstellungen",
                                    variant = MTypographyVariant.subtitle2,
                                    color = MTypographyColor.textSecondary,
                                )
                                jsonEditor(
                                    json = state.selectedProfile?.customOptions ?: "{}",
                                    edit = true,
                                    onChange = {
                                        setState {
                                            selectedProfile = selectedProfile?.copy(customOptions = it)
                                        }
                                    }
                                )
                            }


                            if (state.telegramList?.isNotEmpty() == true) {
                                mDivider { }
                                styledDiv {
                                    css { padding(2.spacingUnits) }
                                    mTableContainer {
                                        mTable {
                                            css { whiteSpace = WhiteSpace.nowrap }
                                            mTableHead {
                                                mTableRowSlim {
                                                    mTableCell(
                                                        align = MTableCellAlign.left,
                                                        variant = MTableCellVariant.head,
                                                        padding = MTableCellPadding.none
                                                    ) {
                                                        css {
                                                            width = 280.px
                                                            padding(1.spacingUnits)
                                                        }
                                                        +"Funktion"
                                                    }
                                                    mTableCell(
                                                        align = MTableCellAlign.left,
                                                        variant = MTableCellVariant.head,
                                                        padding = MTableCellPadding.none
                                                    ) {
                                                        css {
                                                            width = 80.px
                                                            padding(1.spacingUnits)
                                                        }
                                                        +"Telegram"
                                                    }
                                                    mTableCell(
                                                        align = MTableCellAlign.center,
                                                        variant = MTableCellVariant.head,
                                                        padding = MTableCellPadding.none
                                                    ) {
                                                        css {
                                                            width = 140.px
                                                            padding(1.spacingUnits)
                                                        }
                                                        +"Connect Mapping"
                                                    }
                                                    mTableCell(
                                                        align = MTableCellAlign.center,
                                                        variant = MTableCellVariant.head,
                                                        padding = MTableCellPadding.none
                                                    ) {
                                                        css {
                                                            width = 140.px
                                                            padding(1.spacingUnits)
                                                        }
                                                        +"Portal Anzeige"
                                                    }
                                                    mTableCell(
                                                        align = MTableCellAlign.left,
                                                        variant = MTableCellVariant.head,
                                                        padding = MTableCellPadding.none
                                                    ) {
                                                        css {
                                                            width = 100.px
                                                            padding(1.spacingUnits)
                                                        }
                                                        +"Filter"
                                                    }
                                                    mTableCell(
                                                        align = MTableCellAlign.right,
                                                        variant = MTableCellVariant.head,
                                                        padding = MTableCellPadding.none
                                                    ) {
                                                        css {
//                                                            width = 100.pct
                                                            padding(1.spacingUnits)
                                                        }
                                                    }
                                                }
                                            }
                                            mTableBody {
                                                state.selectedEntries
                                                    .plus(mapOf(Pair(IbisFunction.NONE,"") to IbisProfileEntryDTO(
                                                        profileId = -1,
                                                        entryId = -1,
                                                        ibisFunction = IbisFunction.NONE,
                                                        telegramType = "",
                                                        isAppMapping = false,
                                                        isPortalMapping = false,
                                                        filter = emptyList()
                                                    )))
                                                    .forEach { (keyPair, entry) ->
                                                    //val profileMapping = state.selectedProfile?.mapping?.firstOrNull { it.telegramType == telegram }
                                                    mTableRowSlim {
                                                        mTableCell(
                                                            align = MTableCellAlign.left,
                                                            variant = MTableCellVariant.body,
                                                            padding = MTableCellPadding.none
                                                        ) {
                                                            css {
                                                                width = 280.px
                                                                padding(1.spacingUnits)
                                                            }
                                                            mSelect(
                                                                value = entry.ibisFunction
                                                                    ?: IbisFunction.NONE.name,
                                                                onChange = { event, child ->
                                                                    val selection = event.targetValue as String
                                                                    setState {
                                                                        if(selection == "REMOVE") {
                                                                            selectedEntries.remove(keyPair)
                                                                        } else {
                                                                            val ibisFunction = IbisFunction.valueOf(selection)
                                                                            val editedEntry = entry.copy(
                                                                                ibisFunction = ibisFunction
                                                                            )
                                                                            if(selectedEntries.containsKey(keyPair))
                                                                                selectedEntries.remove(keyPair)

                                                                            selectedEntries[Pair(ibisFunction,keyPair.second)] = editedEntry
                                                                        }
                                                                        saveSuccess = false
                                                                    }
                                                                }
                                                            ) {
                                                                attrs.margin = MFormControlMargin.dense.toString()
                                                                attrs.fullWidth = true

                                                                mMenuItem(
                                                                    primaryText = "Entfernen",
                                                                    value = "REMOVE"
                                                                )
                                                                for (element in IbisFunction.values()) {
                                                                    mMenuItem(
                                                                        primaryText = element.readableName,
                                                                        value = element.name
                                                                    )
                                                                }
                                                            }
                                                        }
                                                        mTableCell(
                                                            align = MTableCellAlign.left,
                                                            variant = MTableCellVariant.body,
                                                            padding = MTableCellPadding.none
                                                        ) {
                                                            css {
                                                                width = 80.px
                                                                padding(1.spacingUnits)
                                                            }
                                                            mSelect(
                                                                value = entry.telegramType
                                                                    ?: IbisFunction.NONE.name,
                                                                onChange = { event, child ->
                                                                    val selection = event.targetValue as String
                                                                    setState {
                                                                        val editedEntry = entry.copy(
                                                                             telegramType = selection
                                                                        )
                                                                        if(selectedEntries.containsKey(keyPair))
                                                                            selectedEntries.remove(keyPair)

                                                                        selectedEntries[Pair(keyPair.first, selection)] = editedEntry
                                                                        saveSuccess = false
                                                                    }
                                                                }
                                                            ) {
                                                                attrs.margin = MFormControlMargin.dense.toString()
                                                                attrs.fullWidth = true

                                                                state.telegramList
                                                                    ?.filter { it.active }
                                                                    ?.forEach {
                                                                        mMenuItem(
                                                                            primaryText = it.telegram,
                                                                            value = it.telegram
                                                                        )
                                                                    }
                                                            }
//                                                            mTypography(
//                                                                text = entry.telegramType,
//                                                                variant = MTypographyVariant.body1
//                                                            )
                                                        }
                                                        mTableCell(
                                                            align = MTableCellAlign.center,
                                                            variant = MTableCellVariant.body,
                                                            padding = MTableCellPadding.none
                                                        )  {
                                                            css {
                                                                width = 140.px
                                                                padding(1.spacingUnits)
                                                            }
                                                            mCheckbox(
                                                                checked = entry.isAppMapping
                                                                    ?: false,
                                                                onChange = { _, checked ->
                                                                    setState {
                                                                        selectedEntries[keyPair] =
                                                                            entry.copy(
                                                                                isAppMapping = checked
                                                                            )
                                                                        saveSuccess = false
                                                                    }
                                                                }
                                                            )
                                                        }
                                                        mTableCell(
                                                            align = MTableCellAlign.center,
                                                            variant = MTableCellVariant.body,
                                                            padding = MTableCellPadding.none
                                                        )  {
                                                            css {
                                                                width = 140.px
                                                                padding(1.spacingUnits)
                                                            }
                                                            mCheckbox(
                                                                checked = entry.isPortalMapping
                                                                    ?: false,
                                                                onChange = { _, checked ->
                                                                    setState {
                                                                        selectedEntries[keyPair] =
                                                                            entry.copy(
                                                                                isPortalMapping = checked
                                                                            )
                                                                        saveSuccess = false
                                                                    }
                                                                }
                                                            )
                                                        }
                                                        mTableCell(
                                                            align = MTableCellAlign.left,
                                                            variant = MTableCellVariant.body,
                                                            padding = MTableCellPadding.none
                                                        )  {
                                                            css {
                                                                width = 120.px
                                                                padding(0.spacingUnits, 1.spacingUnits)
                                                            }
                                                            entry.filter.forEach {
                                                                mSelect(
                                                                    value = it.id.toString(),
                                                                    onChange = { event, child ->
                                                                        val selection = event.targetValue as String
                                                                        setState {
                                                                            val tmpList = selectedEntries[keyPair]
                                                                                ?.filter
                                                                                ?.filter { f -> f.id != it.id }
                                                                                ?.toMutableList()
                                                                                ?: mutableListOf()

                                                                            if(selection != "-1") {
                                                                                tmpList.add(state.telegramFilterList.first { it.id.toString() == selection})
                                                                            }
                                                                            selectedEntries[keyPair] = entry.copy(
                                                                                filter = tmpList
                                                                            )
                                                                            saveSuccess = false
                                                                        }
                                                                    }
                                                                ) {
                                                                    css { margin(0.spacingUnits, 1.spacingUnits) }
                                                                    mMenuItem(
                                                                        primaryText = "Entfernen",
                                                                        value = "-1"
                                                                    )
                                                                    for (filter in state.telegramFilterList) {
                                                                        mMenuItem(
                                                                            primaryText = filter.name,
                                                                            value = filter.id.toString()
                                                                        )
                                                                    }
                                                                }
                                                            }
                                                            mSelect(
                                                                value = "-1",
                                                                onChange = { event, child ->
                                                                    val selection = event.targetValue as String
                                                                    setState {
                                                                        val tmpList = selectedEntries[keyPair]
                                                                            ?.filter
                                                                            ?.toMutableList()
                                                                            ?: mutableListOf()

                                                                        if(selection != "-1") {
                                                                            tmpList.add(state.telegramFilterList.first { it.id.toString() == selection})
                                                                        }
                                                                        selectedEntries[keyPair] = entry.copy(
                                                                            filter = tmpList
                                                                        )
                                                                        saveSuccess = false
                                                                    }
                                                                }
                                                            ) {
                                                                css { margin(0.spacingUnits, 1.spacingUnits) }
                                                                mMenuItem(
                                                                    primaryText = "",
                                                                    value = "-1"
                                                                )
                                                                for (filter in state.telegramFilterList) {
                                                                    mMenuItem(
                                                                        primaryText = filter.name,
                                                                        value = filter.id.toString()
                                                                    )
                                                                }
                                                            }
                                                        }
                                                    }

                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    if (state.profiles.isNotEmpty()) {
                        mDivider { }
                        mCardActions {
                            css { padding(2.spacingUnits) }
                            mGridContainer2(
                                direction = MGridDirection.row,
                                alignItems = MGridAlignItems.center
                            ) {
                                mGridItem2(
                                    MGridBreakpoints2(MGridSize2.Auto)
                                        .down(Breakpoint.xs, MGridSize2.Cells12)
                                ) {
                                    mButton(
                                        caption = "Übernehmen",
                                        variant = MButtonVariant.contained,
                                        color = MColor.secondary,
                                        onClick = {
                                            if (state.profiles
                                                    .filter { it.profileName != state.selectedProfile?.profileName }
                                                    .any { it.profileName == state.newProfileName }
                                            ) {
                                                setState { warnDialog = "Es gibt bereits ein Profil mit diesem Namen." }
                                            } else
                                                state.selectedProfile?.let {
                                                    val profile = it.copy(profileName = state.newProfileName ?: "")
                                                    saveEntries(
                                                        profile.profileId,
                                                        state.selectedEntries.values.toList()
                                                    )
                                                    saveProfile(profile)
                                                }
                                        }
                                    ) {
                                        attrs.disableElevation = true
                                    }
                                    if (state.saveSuccess == true) {
                                        mIconNoTranslate("check_circle") {
                                            css { color = Color.green }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        mDialog(
            open = state.warnDialog != null,
            onClose =
            { _, _ -> setState { warnDialog = null } }
        )
        {
            mDialogContent { mTypography(state.warnDialog) }
            mDialogActions {
                mButton(
                    caption = "Okay",
                    onClick = { setState { warnDialog = null } }
                )
            }
        }
    }

    private fun RBuilder.sideBar() {
        mDialog(
            open = state.deleteProfileDialog,
            scroll = DialogScroll.paper,
            maxWidth = Breakpoint.md,
            onClose = { _, _ ->
                setState {
                    deleteProfileDialog = false
                    deleteProfileCandidate = null
                }
            }
        ) {
            attrs.disableEscapeKeyDown = true
            mTypography(
                text = "Profil \"${state.deleteProfileCandidate?.profileName}\" löschen?"
            ) { css { margin(horizontal = LinearDimension.auto, vertical = 6.pt) } }
            mDialogActions {
                mButton(
                    caption = "Ja, Profil löschen.",
                    color = MColor.secondary,
                    onClick = {
                        deleteProfile(state.deleteProfileCandidate)
                        setState {
                            deleteProfileDialog = false
                            deleteProfileCandidate = null
                        }
                    }
                )
                mButton(
                    caption = "Abbrechen",
                    color = MColor.primary,
                    onClick = {
                        setState {
                            deleteProfileDialog = false
                            deleteProfileCandidate = null
                        }
                    }
                )
            }
        }

        mGridContainer2(direction = MGridDirection.column) {
            mGridItem2 {
                if (state.profiles.isEmpty()) {
                    if (isAuthorized(Permissions.VehicleProfileManagement.ibisProfileEdit)) {
                        emptyView(
                            iconName = "menu",
                            title = "IBIS Profile",
                            caption = "Es wurden keine IBIS Profile gefunden",
                            addButton = true,
                            actionBtnCaption = "Erstellen"
                        ) {
                            setState {
                                selectedProfile = null
                                state.company?.let {
                                    val newProfile = IbisProfileDTO(
                                        profileId = 0,
                                        profileName = "Neu",
                                        companyId = it,
                                        customOptions = "{}"
                                    )
                                    saveProfile(newProfile)
                                }
                            }
                        }
                    }
                } else {
                    mCard {
                        css(GlobalStyles.card)
                        mCardContent {
                            css(GlobalStyles.cardListContent)

                            styledDiv {
                                css {
                                    display = Display.flex
                                    justifyContent = JustifyContent.spaceBetween
                                    alignItems = Align.center
                                }
                                mListSubheader(heading = "IBIS Profile")
                                if (isAuthorized(Permissions.VehicleProfileManagement.ibisProfileEdit)) {
                                    mButton(
                                        caption = "Neu",
                                        size = MButtonSize.small,
                                        color = MColor.secondary,
                                        variant = MButtonVariant.outlined,
                                        onClick = {
                                            setState {
                                                selectedProfile = null
                                                state.company?.let {
                                                    val newProfile = IbisProfileDTO(
                                                        profileId = 0,
                                                        profileName = "Neu",
                                                        companyId = it,
                                                        customOptions = "{}"
                                                    )
                                                    saveProfile(newProfile)
                                                }
                                            }
                                        }
                                    ) {
                                        css { margin(0.spacingUnits, 1.spacingUnits) }
                                    }
                                }
                            }
                            mDivider { }
                            mList {
                                state.profiles.forEach { ibis ->
                                    mListItem(
                                        button = true,
                                        dense = true,
                                        onClick = {
                                            launch {
                                                fetchEntries(ibis.profileId)
                                            }
                                            setState {
                                                selectedProfile = ibis
                                                newProfileName = ibis.profileName
                                            }
                                        }
                                    ) {
                                        mListItemText(
                                            primary = ibis.profileName,
                                        )

                                        if (isAuthorized(Permissions.VehicleProfileManagement.ibisProfileEdit))
                                            mListItemSecondaryAction {
                                                mIconButtonNoTranslate(
                                                    iconName = "delete",
                                                    color = MColor.primary,
                                                    onClick = {
                                                        setState {
                                                            deleteProfileCandidate = ibis
                                                            deleteProfileDialog = true
                                                        }
                                                    }
                                                )
                                            }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    private fun RBuilder.filterBar() {
        mDialog(
            open = state.deleteTelegramFilterDialog,
            scroll = DialogScroll.paper,
            maxWidth = Breakpoint.md,
            onClose = { _, _ ->
                setState {
                    deleteTelegramFilterDialog = false
                    deleteTelegramFilterCandidate = null
                }
            }
        ) {
            attrs.disableEscapeKeyDown = true
            mTypography(
                text = "Filter \"${state.deleteTelegramFilterCandidate?.name}\" löschen? Der Filter wird aus allen Profilen entfernt."
            ) { css { margin(horizontal = LinearDimension.auto, vertical = 6.pt) } }
            mDialogActions {
                mButton(
                    caption = "Ja, Filter löschen.",
                    color = MColor.secondary,
                    onClick = {
                        deleteFilter(state.deleteTelegramFilterCandidate)
                        setState {
                            deleteTelegramFilterDialog = false
                            deleteTelegramFilterCandidate = null
                        }
                    }
                )
                mButton(
                    caption = "Abbrechen",
                    color = MColor.primary,
                    onClick = {
                        setState {
                            deleteTelegramFilterDialog = false
                            deleteTelegramFilterCandidate = null
                        }
                    }
                )
            }
        }
        mGridContainer2(direction = MGridDirection.column) {
            mGridItem2 {
                if (state.telegramFilterList.isEmpty()) {
                    if (isAuthorized(Permissions.VehicleProfileManagement.ibisProfileEdit)) {
                        emptyView(
                            iconName = "menu",
                            title = "Telegram Filter",
                            caption = "Es wurden keine Telegram Filter gefunden",
                            addButton = true,
                            actionBtnCaption = "Erstellen"
                        ) {
                            setState {
                                selectedTelegramFilter = null
                                state.company?.let {
                                    val newFilter = IbisTelegramFilterDTO(
                                        id = -1,
                                        companyId = it,
                                        name = "Neu",
                                        type = IbisFilterType.NONE,
                                        text = ""
                                    )
                                    saveFilter(newFilter)
                                }
                            }
                        }
                    }
                } else {
                    mCard {
                        css(GlobalStyles.card)
                        mCardContent {
                            css(GlobalStyles.cardListContent)

                            styledDiv {
                                css {
                                    display = Display.flex
                                    justifyContent = JustifyContent.spaceBetween
                                    alignItems = Align.center
                                }
                                mListSubheader(heading = "Telegram Filter")
                                if (isAuthorized(Permissions.VehicleProfileManagement.ibisProfileEdit)) {
                                    mButton(
                                        caption = "Neu",
                                        size = MButtonSize.small,
                                        color = MColor.secondary,
                                        variant = MButtonVariant.outlined,
                                        onClick = {
                                            setState {
                                                selectedTelegramFilter = null
                                                state.company?.let {
                                                    val newFilter = IbisTelegramFilterDTO(
                                                        id = -1,
                                                        companyId = it,
                                                        name = "Neu",
                                                        type = IbisFilterType.NONE,
                                                        text = ""
                                                    )
                                                    saveFilter(newFilter)
                                                }
                                            }
                                        }
                                    ) {
                                        css { margin(0.spacingUnits, 1.spacingUnits) }
                                    }
                                }
                            }
                            mDivider { }
                            mList {
                                state.telegramFilterList.forEach { filter ->
                                    mListItem(
                                        button = true,
                                        dense = true,
                                        onClick = {
                                            setState {
                                                selectedTelegramFilter = filter
                                            }
                                        }
                                    ) {
                                        mListItemText(
                                            primary = filter.name,
                                        )

                                        if (isAuthorized(Permissions.VehicleProfileManagement.ibisProfileEdit))
                                            mListItemSecondaryAction {
                                                mIconButtonNoTranslate(
                                                    iconName = "delete",
                                                    color = MColor.primary,
                                                    onClick = {
                                                        setState {
                                                            deleteTelegramFilterCandidate = filter
                                                            deleteTelegramFilterDialog = true
                                                        }
                                                    }
                                                )
                                            }
                                    }
                                }
                            }

                            mDivider { }

                            mCard {
                                mCardContent {
                                    mTextField(
                                        id = "filterText",
                                        variant = MFormControlVariant.outlined,
                                        label = "Filter",
                                        type = InputType.text,
                                        value = state.selectedTelegramFilter?.text ?: "",
                                        onChange = {
                                            val value = it.targetInputValue
                                            //val newProfile = state.selectedProfile?.copy(profileName = value)
                                            setState {
                                                val changedFilter = state.selectedTelegramFilter?.copy(text = value)
                                                selectedTelegramFilter = changedFilter
                                            }
                                        }
                                    ) {
                                        attrs.fullWidth = true
                                        attrs.margin = MFormControlMargin.dense;
                                    }

                                    mTextField(
                                        id = "filterName",
                                        variant = MFormControlVariant.outlined,
                                        label = "Name",
                                        type = InputType.text,
                                        value = state.selectedTelegramFilter?.name ?: "",
                                        onChange = {
                                            val value = it.targetInputValue
                                            val changedFilter = state.selectedTelegramFilter?.copy(name = value)
                                            setState {
                                                selectedTelegramFilter = changedFilter
                                            }
                                        },
                                        autoFocus = true
                                    ) {
                                        css {
                                            width = 47.pct
                                        }
                                        attrs.margin = MFormControlMargin.dense;
                                    }
                                    mSelect(
                                        value = state.selectedTelegramFilter?.type?.name ?: IbisFilterType.BLACKLIST.name,
                                        onChange = { event, child ->
                                            val selection = event.targetValue as String
                                            setState {
                                                val changedFilter = state.selectedTelegramFilter?.copy(type = IbisFilterType.valueOf(selection))
                                                selectedTelegramFilter = changedFilter
                                            }
                                        }
                                    ) {
                                        css {
                                            width = 21.pct
                                            margin(1.spacingUnits, 1.spacingUnits)
                                        }
                                        for (filter in IbisFilterType.values()) {
                                            if(filter != IbisFilterType.NONE)
                                                mMenuItem(
                                                    primaryText = filter.readableName,
                                                    value = filter.name
                                                )
                                        }
                                    }
                                    mButton(
                                        caption = "Speichern",
                                        variant = MButtonVariant.contained,
                                        color = MColor.secondary,
                                        onClick = {
                                            saveFilter(state.selectedTelegramFilter)
                                        }
                                    ) {
                                        css {
//                                            width = 23.pct
                                            margin(1.spacingUnits, 0.spacingUnits)
                                        }
                                        attrs.disableElevation = true
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}