package de.geomobile.frontend.features.documentation

import de.geomobile.frontend.utils.CComponent
import kotlinx.css.*
import react.RBuilder
import react.RProps
import react.RState
import react.ReactElement
import react.dom.attrs
import styled.css
import styled.styledDiv
import styled.styledIframe

fun RBuilder.documentationEmbed(
    headless: Boolean,
    drawerMenu: ReactElement,
) = child(DocumentationEmbed::class) {
    attrs.headless = headless
    attrs.drawerMenu = drawerMenu
}

class DocumentationEmbed : CComponent<DocumentationEmbed.Props, DocumentationEmbed.State>() {

    interface Props : RProps {
        var headless: Boolean
        var drawerMenu: ReactElement
    }

    class State() : RState

    init {
        state = State()
    }

    override fun RBuilder.render() {
        styledDiv {
            css {
                display = Display.flex
                flexDirection = FlexDirection.column

                width = 100.pct
                height = 100.vh
            }
            styledIframe {
                attrs {
                    height = "100%"
                    width = "100%"
                    src = "https://joomla.geomobile.de/"
                }
                css {
                    border = "none"
                }
            }
        }
    }
}
