package de.geomobile.frontend.features.debug

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.dialog.mDialogContent
import com.ccfraser.muirwik.components.form.MFormControlMargin
import com.ccfraser.muirwik.components.form.mFormControl
import com.ccfraser.muirwik.components.input.mInputLabel
import com.ccfraser.muirwik.components.menu.mMenuItem
import com.ccfraser.muirwik.components.styles.Breakpoint
import de.geomobile.common.feature.Feature
import de.geomobile.common.permission.Permissions
import de.geomobile.common.permission.Role
import de.geomobile.common.portalmodels.Company
import de.geomobile.common.portalmodels.UserDTO
import de.geomobile.common.stage.Stage
import de.geomobile.frontend.UserStore
import de.geomobile.frontend.api.ApiErrorInterceptor
import de.geomobile.frontend.auth.AuthenticationProviderInterceptor
import de.geomobile.frontend.features.admin.features.featureSelection
import de.geomobile.frontend.features.admin.roles.permissionSelection
import de.geomobile.frontend.features.admin.stages.stageSelection
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.utils.*
import kotlinx.coroutines.*
import kotlinx.css.TextTransform
import kotlinx.css.margin
import kotlinx.css.px
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.json.Json
import react.RBuilder
import react.RProps
import react.RState
import react.setState
import styled.css
import styled.styledDiv

fun RBuilder.debugSettings() = child(DebugSettingsComponent::class) {}

class DebugSettingsComponent : CComponent<RProps, DebugSettingsComponent.State>() {

    private var saveJob: Job = Job()

    private val realApi = portalRestApi.copy(
        interceptors = listOf(
            ApiErrorInterceptor,
            AuthenticationProviderInterceptor { UserStore.realToken }
        )
    )

    class State(
        var savingDebugUser: Boolean = false,
        var debugUser: UserDTO? = null,
        var companies: List<Company> = emptyList(),
        var roles: List<Role> = emptyList(),
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        launch {
            val deferredCompanies = async(Dispatchers.Default) {
                realApi.get("/admin/companies", ListSerializer(Company.serializer()))
            }
            val deferredRoles = async(Dispatchers.Default) {
                realApi.get("/admin/roles", ListSerializer(Role.serializer()))
            }

            val user = UserStore.debugUser ?: withContext(Dispatchers.Default) {
                realApi.get("/user", UserDTO.serializer())
            }

            val companies = deferredCompanies.await()
            val roles = deferredRoles.await()

            setState {
                this.debugUser = user
                this.companies = companies
                this.roles = roles
            }
        }
    }

    override fun RBuilder.render() {
        val debugUser = UserStore.debugUser ?: UserStore.user
        val companies = state.companies
        val roles = state.roles
            .filter { it.company == null || it.company == debugUser?.company }
            .filter { it.visibleForCompany || debugUser?.company?.originator == true }

        if (debugUser != null)
            styledDiv {
                css { margin(2.spacingUnits) }

                mCardHeaderExtended(
                    title = "Demo-Modus",
                    titleTextTransform = TextTransform.uppercase,
                    avatar = RBuilder().mSwitch(
                        color = MOptionColor.primary,
                        checked = UserStore.debugMode,
                        onChange = { _, checked ->
                            if (checked) {
                                saveDebugUser()
                            } else {
                                UserStore.debugMode = false
                                UserStore.triggerReload()
                            }
                        }
                    )
                )

                mDialogContent {

                    mTypography(
                        text = "Eigenschaften",
                        variant = MTypographyVariant.h6
                    ) {
                        css.margin(1.spacingUnits, 0.spacingUnits)
                    }

                    mGridContainer2(direction = MGridDirection.row) {
                        mGridItem2(
                            MGridBreakpoints2(MGridSize2.Cells6)
                                .down(Breakpoint.sm, MGridSize2.Cells12)
                        ) {
                            mFormControl(
                                required = true,
                                fullWidth = true,
                                disabled = !UserStore.debugMode
                            ) {
                                mFormControl(margin = MFormControlMargin.normal, required = true, fullWidth = true) {
                                    mInputLabel(caption = "Unternehmen", htmlFor = "company")
                                    mSelect(
                                        value = debugUser.company.id,
                                        name = "company",
                                        id = "company",
                                        disabled = !UserStore.debugMode,
                                        onChange = { event, _ ->
                                            val id = event.targetValue as String
                                            saveDebugUser(company = companies.first { it.id == id })
                                        }
                                    ) {
                                        for (company in companies) {
                                            mMenuItem(primaryText = company.name, value = company.id)
                                        }
                                    }
                                }
                            }
                        }
                        mGridItem2(
                            MGridBreakpoints2(MGridSize2.Cells6)
                                .down(Breakpoint.sm, MGridSize2.Cells12)
                        ) {
                            mFormControl(
                                required = true,
                                fullWidth = true,
                                disabled = !UserStore.debugMode
                            ) {
                                mFormControl(margin = MFormControlMargin.normal, required = true, fullWidth = true) {
                                    mInputLabel(caption = "Rolle kopieren von", htmlFor = "role")
                                    mSelect(
                                        value = debugUser.role.id,
                                        name = "role",
                                        id = "role",
                                        disabled = !UserStore.debugMode,
                                        onChange = { event, _ ->
                                            val id = event.targetValue as String
                                            saveDebugUser(role = roles.first { it.id == id })
                                        }
                                    ) {
                                        for (role in roles.groupBy { it.company }.flatMap { it.value }) {
                                            mMenuItem(
                                                primaryText = role.name,
                                                secondaryText = role.company?.name,
                                                value = role.id
                                            )
                                        }
                                    }
                                }
                            }
                        }
                    }

                    mTypography(
                        text = "Stage",
                        variant = MTypographyVariant.h6
                    ) {
                        css.margin(1.spacingUnits, 0.spacingUnits)
                    }

                    stageSelection(
                        mutable = true,
                        stages = debugUser.company.stages
                    ) { stages ->
                        saveDebugUser(stages = stages)
                    }

                    mTypography(
                        text = "Funktionen",
                        variant = MTypographyVariant.h6
                    ) {
                        css.margin(1.spacingUnits, 0.spacingUnits)
                    }

                    featureSelection(
                        mutable = true,
                        features = debugUser.company.features.associateBy { it.id }
                    ) { features ->
                        saveDebugUser(features = features.values.toSet())
                    }

                    mTypography(
                        text = "Rechte",
                        variant = MTypographyVariant.h6
                    ) {
                        css.margin(1.spacingUnits, 0.spacingUnits)
                    }
                    permissionSelection(
                        allVisible = true,
                        mutable = UserStore.debugMode,
                        features = debugUser.company.features,
                        permissions = debugUser.role.permissions
                    ) { permissions ->
                        saveDebugUser(
                            role = debugUser.role.copy(
                                id = "",
                                name = "DEBUG-ROLE",
                                mutable = true,
                                visibleForCompany = false,
                                company = debugUser.company,
                                permissions = permissions
                            )
                        )
                    }
                }

            }
    }


    private fun saveDebugUser(
        company: Company? = null,
        features: Set<Feature>? = null,
        stages: Set<Stage>? = null,
        role: Role? = null,
    ) {
        val debugUser = (UserStore.debugUser ?: UserStore.user)!!.let { debugUser ->
            debugUser.copy(
                id = "DEBUG",
                email = "DEBUG-USER",
                role = role?.copy(permissions = role.permissions + Permissions.AdminPermissions.debugAccess)
                    ?: debugUser.role,
                company = (company ?: debugUser.company).let { company ->
                    company.copy(
                        features = features ?: company.features,
                        stages = stages ?: company.stages
                    )
                }
            )
        }


        setState {
            this.savingDebugUser = true
        }

        saveJob.cancel()
        saveJob = launch {
            launch(Dispatchers.Default) {

                realApi.post(
                    "/debug/user",
                    body = Json.encodeToString(UserDTO.serializer(), debugUser)
                )

            }.join()

            setState { this.savingDebugUser = false }

            UserStore.debugUser = debugUser
            UserStore.debugMode = true

            UserStore.triggerReload()
        }

    }
}