package de.geomobile.frontend.features.vehicleProfile

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonSize
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardActions
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.dialog.DialogScroll
import com.ccfraser.muirwik.components.dialog.mDialog
import com.ccfraser.muirwik.components.dialog.mDialogActions
import com.ccfraser.muirwik.components.form.MFormControlMargin
import com.ccfraser.muirwik.components.form.MFormControlVariant
import com.ccfraser.muirwik.components.lab.alert.MAlertSeverity
import com.ccfraser.muirwik.components.lab.alert.MAlertVariant
import com.ccfraser.muirwik.components.lab.alert.mAlert
import com.ccfraser.muirwik.components.list.*
import com.ccfraser.muirwik.components.menu.mMenuItem
import com.ccfraser.muirwik.components.styles.Breakpoint
import com.ccfraser.muirwik.components.table.*
import components.emptyView
import de.geomobile.common.permission.Permissions
import de.geomobile.common.portalmodels.UserDTO
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.spacer
import de.geomobile.frontend.utils.*
import kotlinx.browser.localStorage
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.*
import kotlinx.html.InputType
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.json.Json
import org.w3c.dom.get
import portalmodels.*
import react.RBuilder
import react.RProps
import react.RState
import react.setState
import styled.css
import styled.styledDiv

fun RBuilder.vehicleProfileFMS(
    profileId: String,
) = child(VehicleProfileFMS::class) {
    attrs.profileId = profileId
}

class VehicleProfileFMS : CComponent<VehicleProfileFMS.Props, VehicleProfileFMS.State>() {

    private var companyJob: Job = Job()
    private var loadJob: Job = Job()
    private var saveJob: Job = Job()
    private val intervals: List<String> = listOf(
        "Aus",
        "Push",
        "15 Sekunden",
        "30 Sekunden",
        "1 Minute",
        "3 Minuten",
        "5 Minuten",
        "10 Minuten",
        "15 Minuten",
        "30 Minuten",
        "1 Stunde"
    )

    private val calibrationOptions = mapOf(
        "none" to "Keine", "max" to "Maximalwert", "0" to "Manuell"
    )

    interface Props : RProps {
        var profileId: String
    }

    class State(
        var company: String? = null,
        var profiles: MutableList<FmsProfileDTO> = mutableListOf(),
        var selectedProfile: FmsProfileDTO? = null,
        var selectedEntries: MutableList<FmsEntriesDTO> = mutableListOf(),
        var telegrams: FmsTelegramsDTO? = null,
        var noPush: List<Int>,
        var saveSuccess: Boolean = false,
        var editable: Boolean = false,
        var deleteProfileDialog: Boolean = false,
        var deleteProfileCandidate: FmsProfileDTO = FmsProfileDTO(name = ""),
    ) : RState

    init {
        state = State(
            noPush = listOf(
                64933,
                61440,
                61444,
                61440,
                61444,
                65237,
                61443,
                61443,
                61441,
                65112,
                65198,
                65102,
                65265,
                65132,
                65266,
                65253,
                64893,
                61445,
                61449
            ).sorted()
        )
    }

    override fun componentDidMount() {
        loadCompany()
        loadProfiles()
    }

    fun selectProfileFromProps() {
        try {
            if (props.profileId.isNotBlank()) {
                val profile = state.profiles.first { it.id == props.profileId.toInt() }
                setState {
                    selectedProfile = profile
                }
            }
        } catch (ex: Exception) {

        }
    }

    fun loadCompany() {
        companyJob.cancel()
        companyJob = launch {
            val companyId =
                if (isAuthorized(Permissions.VehicleProfileManagement.notRestrictedToCompany) && localStorage["VehicleProfileCompany"] != null) {
                    localStorage["VehicleProfileCompany"]
                } else {
                    withContext(Dispatchers.Default) {
                        portalRestApi.get("/user", UserDTO.serializer())
                    }.company.id
                }
            setState {
                company = companyId
            }
        }
    }

    private fun loadProfiles() {
        launch {
            companyJob.join()
            val newProfiles = state.company?.let {
                portalRestApi.get(
                    path = "/vehicleprofiles/fms/profiles/${state.company}",
                    serializer = ListSerializer(
                        FmsProfileDTO.serializer()
                    )
                )
            }

            newProfiles?.let {
                setState {
                    profiles = it.toMutableList()
                    if (selectedProfile !in newProfiles) selectedProfile = profiles.firstOrNull()
                    saveSuccess = false
                }
                selectProfileFromProps()
                loadEntries()
            }
            setState {
                telegrams = FmsTelegrams.telegrams
            }
        }
    }

    private suspend fun loadEntries() {
        val newEntries = state.selectedProfile?.let {
            portalRestApi.get(
                path = "/vehicleprofiles/fms/entries/${state.selectedProfile?.id}",
                serializer = ListSerializer(
                    FmsEntriesDTO.serializer()
                )
            )
        }
        newEntries?.let {
            setState {
                saveSuccess = false
                selectedEntries = it.toMutableList()
            }
        }
    }

    private fun saveProfile(profile: FmsProfileDTO? = null) {
        launch {
            val tmpProfile = profile ?: FmsProfileDTO(name = "Neu")
            val body = Json.encodeToJsonElement(FmsProfileDTO.serializer(), tmpProfile)
            val newProfile = portalRestApi.put(
                path = "/vehicleprofiles/fms/profile/${state.company}",
                body = body,
                serializer = FmsProfileDTO.serializer()
            )

            newProfile?.let {
                setState {
                    selectedProfile = newProfile
                }
            }
        }.invokeOnCompletion {
            loadProfiles()
        }
    }

    private fun deleteProfile(profile: FmsProfileDTO) {
        launch {
            portalRestApi.delete(
                path = "/vehicleprofiles/fms/profiles/${profile.id}/delete",
                body = GenericResponseDTO.serializer()
            )
            loadProfiles()
        }
    }

    private fun saveChanges() {
        launch {
            state.selectedProfile?.let {
                val entryBody = Json.encodeToJsonElement(
                    ListSerializer(FmsEntriesDTO.serializer()),
                    state.selectedEntries
                )
                val resultEntry = portalRestApi.put(
                    path = "/vehicleprofiles/fms/entries/${it.id}",
                    body = entryBody,
                    serializer = GenericResponseDTO.serializer()
                )

                val profileBody = Json.encodeToJsonElement(FmsProfileDTO.serializer(), it)
                val resultProfile = portalRestApi.put(
                    path = "/vehicleprofiles/fms/profile/${state.company}",
                    body = profileBody,
                    serializer = FmsProfileDTO.serializer()
                )

                if (resultEntry.isError == false && resultProfile.id != null) {
                    setState {
                        saveSuccess = true
                    }
                }
            }
        }
    }

    override fun RBuilder.render() {
        authorize(Permissions.VehicleProfileManagement.fmsProfileView) {
            spacer()
            mGridContainer2(direction = MGridDirection.row) {
                mGridItem2(
                    MGridBreakpoints2(MGridSize2.Cells3).down(Breakpoint.md, MGridSize2.Cells12)
                ) {
                    sideBar()
                }
                mGridItem2(
                    MGridBreakpoints2(MGridSize2.Cells9).down(Breakpoint.md, MGridSize2.Cells12)
                ) {
                    detail()
                }
            }
        }
    }

    fun RBuilder.detail() {
        mGridContainer2(direction = MGridDirection.row) {
            mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                mCard {
                    css(GlobalStyles.card)
                    mCardContent {
                        css(GlobalStyles.cardListContent)
                        if (state.profiles.isEmpty()) {
                            emptyView(
                                title = "FMS Profil",
                                caption = "Es wurden keine Profile gefunden",
                                addButton = false
                            )
                        } else {
                            mListSubheader(
                                heading = "Profil: ${state.selectedProfile?.name ?: "k.A."}"
                            )
                            mDivider { }
                            styledDiv {
                                css { padding(2.spacingUnits) }

                                mTextField(
                                    variant = MFormControlVariant.outlined,
                                    id = "name",
                                    label = "Name",
                                    margin = MFormControlMargin.dense,
                                    disabled = !state.editable,
                                    type = InputType.text,
                                    value = state.selectedProfile?.name ?: "",
                                    onChange = {
                                        val value = it.targetInputValue
                                        setState {
                                            selectedProfile?.name = value
                                        }
                                    },
                                    fullWidth = true,
                                    autoFocus = true
                                )
                            }
                            if (state.selectedEntries.isNotEmpty()) {
                                mDivider { }
                                styledDiv {
                                    css { padding(2.spacingUnits) }
                                    mTableContainer {
                                        mTable {
                                            mTableHead {
                                                mTableRow {
                                                    mTableCell(
                                                        align = MTableCellAlign.left,
                                                        padding = MTableCellPadding.default
                                                    ) {
                                                        css { whiteSpace = WhiteSpace.nowrap; maxWidth = 200.px }
                                                        +"PGN"
                                                    }
                                                    mTableCell(
                                                        align = MTableCellAlign.left,
                                                        padding = MTableCellPadding.default
                                                    ) {
                                                        css { whiteSpace = WhiteSpace.nowrap }
                                                        +"Interval externe Schnittstelle"
                                                    }
                                                    mTableCell(
                                                        align = MTableCellAlign.left,
                                                        padding = MTableCellPadding.default
                                                    ) {
                                                        css { whiteSpace = WhiteSpace.nowrap }
                                                        +"Interval Portal"
                                                    }
                                                    mTableCell(
                                                        align = MTableCellAlign.left,
                                                        padding = MTableCellPadding.default
                                                    ) { +" " }
                                                }
                                            }
                                            mTableBody {
                                                state.selectedEntries.forEach {
                                                    mTableRow {
                                                        mTableCell {
                                                            css { padding(4.px, 2.spacingUnits) }
                                                            css { maxWidth = 325.px }
                                                            mSelect(
                                                                fullWidth = true,
                                                                value = it.pgn.toString(),
                                                                disabled = !state.editable,
                                                                onChange = { event, _ ->
                                                                    val newPgn = event.targetValue as String
                                                                    val idx =
                                                                        state.selectedEntries.indexOfFirst { lam -> lam.pgn == it.pgn }
                                                                    val newValue = FmsEntriesDTO(
                                                                        newPgn.toInt(),
                                                                        it.bmsInterval,
                                                                        it.portalInterval
                                                                    )
                                                                    setState {
                                                                        selectedEntries[idx] = newValue
                                                                        saveSuccess = false
                                                                    }
                                                                }) {
                                                                state.telegrams?.let { tel ->
                                                                    for (mode in tel.telegrams.filter { f -> (f.key == it.pgn) || (f.key !in state.selectedEntries.mapNotNull { it.pgn }) }) {
                                                                        mMenuItem(
                                                                            primaryText = mode.toFormatted(),
                                                                            value = mode.key.toString()
                                                                        )
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        mTableCell {
                                                            css { padding(4.px, 2.spacingUnits) }
                                                            mSelect(
                                                                fullWidth = true,
                                                                value = getIntervalDescriptor(it.bmsInterval ?: 0),
                                                                disabled = !state.editable,
                                                                onChange = { event, _ ->
                                                                    val newInterval = event.targetValue as String
                                                                    val idx =
                                                                        state.selectedEntries.indexOfFirst { lam -> lam.pgn == it.pgn }
                                                                    val newValue = FmsEntriesDTO(
                                                                        it.pgn,
                                                                        getIntervalValue(newInterval),
                                                                        it.portalInterval,
                                                                        it.calibration
                                                                    )
                                                                    setState {
                                                                        selectedEntries[idx] = newValue
                                                                        saveSuccess = false
                                                                    }
                                                                }) {
                                                                for (mode in intervals) {
                                                                    if ((mode == "Push") && (it.pgn in state.noPush)) {
                                                                        mMenuItem(
                                                                            primaryText = mode,
                                                                            secondaryText = "PGN zu hochfrequent für Push",
                                                                            value = mode,
                                                                            disabled = true
                                                                        )
                                                                    } else {
                                                                        mMenuItem(primaryText = mode, value = mode)
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        mTableCell {
                                                            css { padding(4.px, 2.spacingUnits) }
                                                            mSelect(
                                                                fullWidth = true,
                                                                value = getIntervalDescriptor(it.portalInterval ?: 0),
                                                                disabled = !state.editable,
                                                                onChange = { event, _ ->
                                                                    val newInterval = event.targetValue as String
                                                                    val idx =
                                                                        state.selectedEntries.indexOfFirst { lam -> lam.pgn == it.pgn }
                                                                    val newValue = FmsEntriesDTO(
                                                                        it.pgn,
                                                                        it.bmsInterval,
                                                                        getIntervalValue(newInterval),
                                                                        it.calibration
                                                                    )
                                                                    setState {
                                                                        selectedEntries[idx] = newValue
                                                                        saveSuccess = false
                                                                    }
                                                                }) {
                                                                for (mode in intervals) {
                                                                    mMenuItem(
                                                                        primaryText = mode,
                                                                        value = mode,
                                                                        disabled = mode == "Push" && it.pgn in state.noPush
                                                                    )
                                                                }
                                                            }
                                                        }
                                                        mTableCell {
                                                            css { padding(4.px, 2.spacingUnits) }
                                                            mIconButtonNoTranslate(
                                                                iconName = "delete",
                                                                disabled = !state.editable,
                                                                color = if (state.editable) MColor.primary else MColor.inherit,
                                                                onClick = { _ ->
                                                                    setState {
                                                                        selectedEntries.remove(it)
                                                                    }
                                                                }
                                                            )
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    if (state.profiles.isNotEmpty()) {
                        mDivider {}
                        if (isAuthorized(Permissions.VehicleProfileManagement.fmsProfileEdit)) {
                            mCardActions {
                                css { padding(2.spacingUnits) }
                                mGridContainer2(
                                    direction = MGridDirection.row,
                                    alignItems = MGridAlignItems.center
                                ) {
                                    mGridItem2(
                                        MGridBreakpoints2(MGridSize2.Auto)
                                            .down(Breakpoint.xs, MGridSize2.Cells12)
                                    ) {
                                        mButton(
                                            variant = MButtonVariant.contained,
                                            caption = if (state.editable) "Übernehmen" else "Bearbeiten",
                                            color = MColor.secondary,
                                            onClick = {
                                                if (state.editable) {
                                                    setState {
                                                        editable = false
                                                    }
                                                    saveChanges()
                                                } else {
                                                    setState {
                                                        editable = true
                                                        saveSuccess = false
                                                    }
                                                }
                                            }
                                        ) {
                                            attrs.disableElevation = true
                                        }
                                    }

                                    val tel = state.telegrams?.telegrams?.entries
                                        ?.firstOrNull { f -> f.key !in state.selectedEntries.map { it.pgn } }

                                    if (state.saveSuccess == true) {
                                        mIconNoTranslate(iconName = "check_circle") {
                                            css { color = Color.green }
                                        }
                                    }

                                    if (state.editable) {
                                        mButton(
                                            caption = "PGN erstellen",
                                            variant = MButtonVariant.contained,
                                            color = MColor.secondary,
                                            disabled = tel?.let { false } ?: true,
                                            onClick = {
                                                setState {
                                                    tel?.let { t ->
                                                        selectedEntries.add(FmsEntriesDTO(t.key, -1, -1))
                                                    }
                                                    saveSuccess = false
                                                }
                                            }
                                        ) {
                                            attrs.disableElevation = true
                                            css { marginLeft = 8.pt }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                if (state.editable)
                    mAlert(
                        variant = MAlertVariant.filled,
                        severity = MAlertSeverity.warning,
                        message = "Die Push Option ist für hochfrequente PGN deaktiviert"
                    ) { css { marginTop = 2.spacingUnits } }
            }
        }
    }

    private fun RBuilder.sideBar() {
        mDialog(state.deleteProfileDialog, scroll = DialogScroll.paper, maxWidth = Breakpoint.md, onClose = { _, _ ->
            setState {
                deleteProfileDialog = false
                deleteProfileCandidate = FmsProfileDTO(name = "")
            }
        }) {
            attrs.disableEscapeKeyDown = true
            mTypography(text = "Profil \"${state.deleteProfileCandidate.name}\" löschen?") {
                css {
                    margin(
                        horizontal = LinearDimension.auto, vertical = 6.pt
                    )
                }
            }
            mDialogActions {
                mButton("Ja, Profil löschen.", color = MColor.secondary, onClick = {
                    deleteProfile(state.deleteProfileCandidate)
                    setState {
                        deleteProfileDialog = false
                        deleteProfileCandidate = FmsProfileDTO(name = "")
                    }
                })
                mButton("Abbrechen", color = MColor.primary, onClick = {
                    setState {
                        deleteProfileDialog = false
                        deleteProfileCandidate = FmsProfileDTO(name = "")
                    }
                })
            }
        }

        mGridContainer2(direction = MGridDirection.column) {
            mGridItem2 {
                if (state.profiles.isEmpty()) {
                    if (isAuthorized(Permissions.VehicleProfileManagement.fmsProfileEdit)) {
                        emptyView(
                            iconName = "menu",
                            title = "FMS Profile",
                            caption = "Es wurden keine FMS Profile gefunden",
                            addButton = true,
                            actionBtnCaption = "Erstellen"
                        ) { saveProfile() }
                    }
                } else {
                    mCard {
                        css(GlobalStyles.card)
                        mCardContent {
                            css(GlobalStyles.cardListContent)

                            styledDiv {
                                css {
                                    display = Display.flex
                                    justifyContent = JustifyContent.spaceBetween
                                    alignItems = Align.center
                                }
                                mListSubheader(heading = "FMS Profile")
                                if (isAuthorized(Permissions.VehicleProfileManagement.fmsProfileEdit)) {
                                    mButton(
                                        caption = "Neu",
                                        size = MButtonSize.small,
                                        color = MColor.secondary,
                                        variant = MButtonVariant.outlined,
                                        onClick = { saveProfile() }
                                    ) {
                                        css { margin(0.spacingUnits, 1.spacingUnits) }
                                    }
                                }
                            }
                            mDivider { }
                            mList {
                                state.profiles.forEach { fms ->
                                    mListItem(
                                        button = true,
                                        dense = true,
                                        onClick = {
                                            launch { loadEntries() }
                                            setState { selectedProfile = fms }
                                        }
                                    ) {
                                        mListItemText(primary = fms?.name ?: "")

                                        if (isAuthorized(Permissions.VehicleProfileManagement.fmsProfileEdit))
                                            mListItemSecondaryAction {
                                                mIconButtonNoTranslate(
                                                    iconName = "delete",
                                                    color = MColor.inherit,
                                                    onClick = {
                                                        setState {
                                                            deleteProfileCandidate = fms
                                                            deleteProfileDialog = true
                                                        }
                                                    }
                                                )
                                            }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    private fun getIntervalValue(interval: String): Int {
        return when (interval) {
            "Aus" -> -1
            "Push" -> 0
            "15 Sekunden" -> 15
            "30 Sekunden" -> 30
            "1 Minute" -> 60
            "3 Minuten" -> 180
            "5 Minuten" -> 300
            "10 Minuten" -> 600
            "15 Minuten" -> 900
            "30 Minuten" -> 1800
            "1 Stunde" -> 3600
            else -> -1
        }
    }

    private fun getIntervalDescriptor(interval: Int): String {
        return when (interval) {
            -1 -> intervals[0]
            0 -> intervals[1]
            15 -> intervals[2]
            30 -> intervals[3]
            60 -> intervals[4]
            180 -> intervals[5]
            300 -> intervals[6]
            600 -> intervals[7]
            900 -> intervals[8]
            1800 -> intervals[9]
            3600 -> intervals[10]
            else -> "Error"
        }
    }

    private fun Map.Entry<Int, Pair<String, String>>.toFormatted(): String {
        return "${this.key} | ${this.value.second} | ${this.value.first}"
    }
}