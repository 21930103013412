package de.geomobile.frontend.features.softwareManagement.assignment

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.list.mList
import com.ccfraser.muirwik.components.list.mListItem
import de.geomobile.common.filter.Filter
import de.geomobile.common.filter.queryString
import de.geomobile.common.portalmodels.Product
import de.geomobile.common.softwaremgmt.SoftwareAssignmentFilterHistory
import de.geomobile.frontend.features.device.list.DeviceListItem
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.utils.*
import de.geomobile.frontend.utils.MGridJustify
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.*
import kotlinx.serialization.builtins.ListSerializer
import react.RBuilder
import react.RProps
import react.RState
import react.setState
import styled.css

fun RBuilder.assignmentHistory(
    id: Int,
    deviceFilter: Map<String, Filter<DeviceListItem, *>>,
    product: Product,
) = child(AssignmentHistory::class) {
    attrs.id = id
    attrs.deviceFilter = deviceFilter
    attrs.product = product
}

class AssignmentHistory : CComponent<AssignmentHistory.Props, AssignmentHistory.State>() {

    private var loadJob: Job = Job()

    interface Props : RProps {
        var id: Int
        var deviceFilter: Map<String, Filter<DeviceListItem, *>>
        var product: Product
    }

    class State(
        var history: List<SoftwareAssignmentFilterHistory> = emptyList(),
        var selected: SoftwareAssignmentFilterHistory? = null,
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        loadFilterHistory()
    }

    private fun loadFilterHistory() {
        loadJob.cancel()
        loadJob = launch {
            val history = withContext(Dispatchers.Default) {
                portalRestApi.get(
                    "/software/assignment/${props.id}/history",
                    ListSerializer(SoftwareAssignmentFilterHistory.serializer())
                )
            }

            setState {
                this.history = history.sortedByDescending { it.createdAt.millis }
                this.selected = history.maxByOrNull { it.createdAt.millis }
            }
        }
    }

    override fun RBuilder.render() {

        mGridContainer2(
            justify = MGridJustify.spaceAround,
            spacing = MGridSpacing.spacing2
        ) {
            css {
                margin(0.spacingUnits)
                height = 100.pct
            }

            mGridItem2(MGridBreakpoints2(MGridSize2.Cells5)) {
                css {
                    overflow = Overflow.auto
                    height = 100.pct
                }
                mList {
                    for (entry in state.history) {
                        mListItem(
                            primaryText = "${entry.createdAt.toText()} (${entry.createdBy})",
                            selected = entry == state.selected,
                            onClick = { setState { selected = entry } }
                        )
                    }
                }
            }

            mGridItem2(MGridBreakpoints2(MGridSize2.Cells7)) {
                css {
                    overflow = Overflow.auto
                    height = 100.pct
                }
                val selected = state.selected
                if (selected != null) {
                    mTypography("Name", variant = MTypographyVariant.caption, color = MTypographyColor.textSecondary)
                    mTypography(selected.name, gutterBottom = true)
                    mTypography("Filter", variant = MTypographyVariant.caption, color = MTypographyColor.textSecondary)
                    mTypography(selected.filterRules.queryString(props.deviceFilter), gutterBottom = true)
                    mTypography(
                        "Company",
                        variant = MTypographyVariant.caption,
                        color = MTypographyColor.textSecondary
                    )
                    mTypography(
                        selected.bundle.company.name,
                        gutterBottom = true
                    )
                    mTypography(
                        "Version",
                        variant = MTypographyVariant.caption,
                        color = MTypographyColor.textSecondary
                    )
                    mTypography(
                        "${selected.bundle.internalVersion} (Ext.: ${selected.bundle.externalVersion})",
                        gutterBottom = true
                    )
                    if (selected.canceled)
                        mTypography(
                            "CANCELED",
                            variant = MTypographyVariant.caption,
                            color = MTypographyColor.error
                        )
                }
            }

        }

    }

}