package de.geomobile.common.time

import kotlinx.serialization.Serializable
import kotlin.js.Date

@Serializable(with = LocalDateTimeSerializer::class)
actual data class LocalDateTime actual constructor(actual val millis: Long) : Comparable<LocalDateTime> {

    actual companion object {
        actual fun now(): LocalDateTime = LocalDateTime(Date.now().toLong())
        actual fun parse(encoded: String) = LocalDateTime(Date.parse(encoded).toLong())
    }

//    val milliMap = mapOf(
//        "seconds" to 1000,
//        "minutes" to 60 * 1000,
//        "hours" to 60 * 60 * 1000,
//        "days" to 24 * 60 * 60 * 1000
//    )

    actual fun format(): String = jsDate.toISOString()

    override fun compareTo(other: LocalDateTime): Int = millis.compareTo(other.millis)

    val jsDate: Date get() = Date(millis)
    val seconds: Long get() = millis / 1000
    val minutes: Long get() = seconds / 60

    actual fun hour(): Int = jsDate.getHours()

    actual fun dayOfWeek(): Int {
        return (jsDate.getDay() - 1) % 7
    }

    actual fun dayOfMonth(): Int {
        return jsDate.getDate()
    }

    actual fun dayAsName(): String {
        val date = jsDate
        return when(date.getDay()) {
            0 -> "Sonntag"
            1 -> "Montag"
            2 -> "Dienstag"
            3 -> "Mittwoch"
            4 -> "Donnerstag"
            5 -> "Freitag"
            6 -> "Samstag"
            else -> "Error"
        }
    }

    actual fun month(): Int {
        return jsDate.getMonth() + 1
    }

    actual fun monthAsName(): String {
        val date = jsDate
        return when(date.getMonth()) {
            0 -> "Januar"
            1 -> "Februar"
            2 -> "März"
            3 -> "April"
            4 -> "Mai"
            5 -> "Juni"
            6 -> "Juli"
            7 -> "August"
            8 -> "September"
            9  -> "Oktober"
            10 -> "November"
            11 -> "Dezember"
            else -> "Error"
        }
    }

    actual fun year(): String {
        val date = jsDate
        return date.getFullYear().toString()
    }

    actual fun atStartOfDay(): LocalDateTime {
        val date = jsDate

        return LocalDateTime(
            Date(
                year = date.getFullYear(),
                month = date.getMonth(),
                day = date.getDay(),
                hour = date.getHours(),
                minute = date.getMinutes(),
                second = date.getSeconds(),
                millisecond = date.getMilliseconds()
            ).getUTCMilliseconds().toLong()
        )
    }

    actual fun minusSeconds(amount: Int): LocalDateTime = LocalDateTime(millis - (amount * 1000))
    actual fun minusMinutes(amount: Int): LocalDateTime = LocalDateTime(millis - (amount * 60 * 1000))
    actual fun minusHours(amount: Int): LocalDateTime = LocalDateTime(millis - (amount * 60 * 60 * 1000))
    actual fun minusDays(amount: Int): LocalDateTime = LocalDateTime(millis - (amount * 24 * 60 * 60 * 1000))

    actual fun minusMonths(amount: Int): LocalDateTime {
        val date = jsDate

        return LocalDateTime(
            Date(
                year = date.getFullYear(),
                month = date.getMonth() - amount,
                day = date.getDay(),
                hour = date.getHours(),
                minute = date.getMinutes(),
                second = date.getSeconds(),
                millisecond = date.getMilliseconds()
            ).getUTCMilliseconds().toLong()
        )
    }

    actual fun minusYears(amount: Int): LocalDateTime {
        val date = jsDate

        return LocalDateTime(
            Date(
                year = date.getFullYear() - amount,
                month = date.getMonth(),
                day = date.getDay(),
                hour = date.getHours(),
                minute = date.getMinutes(),
                second = date.getSeconds(),
                millisecond = date.getMilliseconds()
            ).getUTCMilliseconds().toLong()
        )
    }

    actual fun plusSeconds(amount: Int): LocalDateTime = LocalDateTime(millis + (amount * 1000))
    actual fun plusMinutes(amount: Int): LocalDateTime = LocalDateTime(millis + (amount * 60 * 1000))
    actual fun plusHours(amount: Int): LocalDateTime = LocalDateTime(millis + (amount * 60 * 60 * 1000))
    actual fun plusDays(amount: Int): LocalDateTime = LocalDateTime(millis + (amount * 24 * 60 * 60 * 1000))

    actual fun plusMonths(amount: Int): LocalDateTime {
        val date = jsDate
        val change = (amount + date.getMonth()) % 12

        return LocalDateTime(
            Date(
                year = date.getFullYear(),
                month = change,
                day = date.getDay(),
                hour = date.getHours(),
                minute = date.getMinutes(),
                second = date.getSeconds(),
                millisecond = date.getMilliseconds()
            ).getUTCMilliseconds().toLong()
        )
    }
    actual fun plusYears(amount: Int): LocalDateTime {
        val date = jsDate

        return LocalDateTime(
            Date(
                year = date.getFullYear() + amount,
                month = date.getMonth(),
                day = date.getDay(),
                hour = date.getHours(),
                minute = date.getMinutes(),
                second = date.getSeconds(),
                millisecond = date.getMilliseconds()
            ).getUTCMilliseconds().toLong()
        )
    }
    operator fun minus(other: LocalDateTime): ClosedRange<LocalDateTime> = other..this
    override fun toString(): String = format()
}