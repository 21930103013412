package portalmodels

import kotlinx.serialization.Serializable

@Serializable
data class NetworkSettingsDTO(
    var companyId: String? = null,
    var connectionType: ConnectionType = ConnectionType.LAN,
    var lanSettings: NetworkLanSettings = NetworkLanSettings(),
    var simSettings: NetworkSimSettings = NetworkSimSettings()
)

@Serializable
data class NetworkLanSettings(
    var useDHCP: Boolean = false,
    var ipAddress: String = "",
    var netmask: String = "",
    var gateway: String = "",
)

@Serializable
data class NetworkSimSettings(
    var PIN: String = "",
    var APN: String = ""
)
enum class ConnectionType {
    NONE,
    LAN,
    LTE
}