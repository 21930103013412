package de.geomobile.frontend.utils

import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.SupervisorJob
import react.RComponent
import react.RProps
import react.RState
import kotlin.coroutines.CoroutineContext

abstract class CComponent<P : RProps, S : RState> : RComponent<P, S>, CoroutineScope {

    private var scopeJob: Job = SupervisorJob()
    override val coroutineContext: CoroutineContext = scopeJob

    constructor() : super()

    constructor(props: P) : super(props)

    override fun componentWillUnmount() {
        scopeJob.cancel()
    }
}