package de.geomobile.common.softwaremgmt

import de.geomobile.common.filter.FilterRules
import de.geomobile.common.portalmodels.Product
import kotlinx.serialization.Serializable

@Serializable
data class AddSoftwareAssignmentFilterDTO(
    val parentId: Int? = null,
    val name: String,
    val filter: FilterRules,
    val bundleId: Int,
    val position: Int
)

@Serializable
data class ChangeSoftwareAssignmentFilterDTO(
    val assignmentFilter: SoftwareAssignmentFilter,
    val parentId: Int? = null,
    val position: Int? = null,
    val product: Product = Product.CONNECT
)

@Serializable
data class OverwriteSoftwareAssignmentDTO(
    val enabled: Boolean,
    val assignment: SoftwareAssignment
)