package de.geomobile.frontend.features.device.detail.internal

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.list.mList
import com.ccfraser.muirwik.components.list.mListItem
import com.ccfraser.muirwik.components.list.mListItemText
import com.ccfraser.muirwik.components.styles.Breakpoint
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.portalWebSocketApi
import de.geomobile.frontend.utils.*
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.css.padding
import kotlinx.css.px
import react.RBuilder
import react.RProps
import react.RState
import react.setState
import styled.css
import styled.styledDiv

fun RBuilder.sshStatus(
    id: Int,
    mqtt_topic: String = "ssh"
) = child(SshStatus::class) {
    key = id.toString()
    attrs.id = id
    attrs.topic = mqtt_topic
}

class SshStatus : CComponent<SshStatus.Props, SshStatus.State>() {

    private var sendJob: Job = Job()

    interface Props : RProps {
        var id: Int
        var topic: String
    }

    private val socket by lazy {
        portalWebSocketApi.subscribe("/device/${props.topic}", mapOf("id" to props.id.toString()))
    }

    class State(
        var sshStatus: String = "-"
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        socket.connect {
            onmessage = {
                val status = it
                setState {
                    this.sshStatus = status
                }
            }
        }
    }

    override fun componentWillUnmount() {
        super.componentWillUnmount()
        socket.close()
    }

    override fun RBuilder.render() {
        styledDiv {
            styledDiv {
                css { padding(0.spacingUnits, 2.spacingUnits) }
                mList {
                    attrs.disablePadding = true

                    mListItem {
                        attrs.divider = false
                        attrs.button = false

                        mListItemText {
                            mTypography(
                                text = "Status",
                                variant = MTypographyVariant.caption,
                            )
                            mTypography(
                                text = state.sshStatus.replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() },
                                variant = MTypographyVariant.subtitle2,
                                color = MTypographyColor.textPrimary
                            )
                        }
                    }
                }
            }
            mDivider {}
            styledDiv {
                css { padding(2.spacingUnits) }
                mGridContainer2(direction = MGridDirection.row) {
                    mGridItem2(
                        MGridBreakpoints2(MGridSize2.Auto)
                            .down(Breakpoint.xs, MGridSize2.Cells12)
                    ) {
                        mButton(
                            variant = MButtonVariant.contained,
                            caption = "Tunnel Öffnen",
                            color = MColor.secondary,
                            onClick = { changeSsh("open") }
                        ) {
                            attrs.disableElevation = true
                            attrs.fullWidth = true
                        }
                    }
                    mGridItem2(
                        MGridBreakpoints2(MGridSize2.Auto)
                            .down(Breakpoint.xs, MGridSize2.Cells12)
                    ) {
                        mButton(
                            variant = MButtonVariant.contained,
                            caption = "Tunnel Schließen",
                            color = MColor.default,
                            onClick = { changeSsh("close") }
                        ) {
                            attrs.disableElevation = true
                            attrs.fullWidth = true
                        }
                    }
                }
            }
        }
    }

    private fun changeSsh(action: String) {
        sendJob.cancel()
        sendJob = launch(Dispatchers.Default) {
            portalRestApi.post("/device/${props.id}/${props.topic}/$action")
        }
    }
}