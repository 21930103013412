package de.geomobile.frontend.features.vehicleProfile

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonSize
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardActions
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.dialog.DialogScroll
import com.ccfraser.muirwik.components.dialog.mDialog
import com.ccfraser.muirwik.components.dialog.mDialogActions
import com.ccfraser.muirwik.components.dialog.mDialogContent
import com.ccfraser.muirwik.components.form.MFormControlMargin
import com.ccfraser.muirwik.components.form.MFormControlVariant
import com.ccfraser.muirwik.components.form.margin
import com.ccfraser.muirwik.components.list.*
import com.ccfraser.muirwik.components.menu.mMenuItem
import com.ccfraser.muirwik.components.styles.Breakpoint
import com.ccfraser.muirwik.components.table.*
import components.emptyView
import de.geomobile.common.permission.Permissions
import de.geomobile.common.portalmodels.InFunction
import de.geomobile.common.portalmodels.OutFunction
import de.geomobile.common.portalmodels.UserDTO
import de.geomobile.common.portalmodels.WiredType
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.spacer
import de.geomobile.frontend.utils.*
import kotlinx.browser.localStorage
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.*
import kotlinx.html.InputType
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.json.Json
import org.w3c.dom.get
import portalmodels.GenericResponseDTO
import portalmodels.InternetSource
import portalmodels.WiringProfileDTO
import react.RBuilder
import react.RProps
import react.RState
import react.setState
import styled.css
import styled.styledDiv

fun RBuilder.vehicleProfilesWiring(
    profileId: String,
) = child(VehicleProfilesWiring::class) {
    attrs.profileId = profileId
}

class VehicleProfilesWiring : CComponent<VehicleProfilesWiring.Props, VehicleProfilesWiring.State>() {

    private var companyJob: Job = Job()
    private var fetchWiringProfilesJob: Job = Job()
    private val canBauds = listOf("125000", "250000", "500000", "1000000")

    interface Props : RProps {
        var profileId: String
    }

    class State(
        var company: String? = null,
        var profiles: MutableList<WiringProfileDTO> = mutableListOf(),
        var selectedProfile: WiringProfileDTO? = null,
//        var newProfileName: String? = null,
//        var selectedEntries: MutableList<IbisProfileDTO> = mutableListOf(),
        var saveSuccess: Boolean = false,
        var editable: Boolean = true,
        var deleteProfileDialog: Boolean = false,
        var deleteProfileCandidate: WiringProfileDTO? = null,
        var warnDialog: String? = null,
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        loadCompany()
        fetchWiringProfiles()

    }

    fun loadCompany() {
        companyJob.cancel()
        companyJob = launch {
            val companyId =
                if (isAuthorized(Permissions.VehicleProfileManagement.notRestrictedToCompany) && localStorage["VehicleProfileCompany"] != null) {
                    localStorage["VehicleProfileCompany"]
                } else {
                    withContext(Dispatchers.Default) {
                        portalRestApi.get("/user", UserDTO.serializer())
                    }.company.id
                }
            setState {
                company = companyId
            }
            companyId?.let {
                fetchWiringProfiles()
            }
        }
    }

    private fun fetchWiringProfiles() {
        fetchWiringProfilesJob.cancel()
        fetchWiringProfilesJob = launch {
            val profiles = portalRestApi.get(
                path = "/vehicleprofiles/wiring/profiles",
                serializer = ListSerializer(WiringProfileDTO.serializer())
            )

            setState {
                company?.let {company ->
                    this.profiles = profiles.filter { it.companyId == company }.toMutableList()
                } ?: run {
                    this.profiles = profiles.toMutableList()
                }
                if(props.profileId.isNotBlank())
                    setState {
                        selectedProfile = profiles.find { it.id == props.profileId.toInt() } ?: selectedProfile
                    }
            }
        }
    }

    private fun deleteProfile(profile: WiringProfileDTO?) {
        launch {
            portalRestApi.delete(
                path = "/vehicleprofiles/wiring/profile/${profile?.id}",
                body = GenericResponseDTO.serializer()
            )
        }.invokeOnCompletion {
            fetchWiringProfiles()
        }
    }

    private fun saveProfile(profile: WiringProfileDTO?) {
        launch {
            profile?.let {
                val bodyProfile = Json.encodeToJsonElement(WiringProfileDTO.serializer(), it)
                val response = portalRestApi.put(
                    path = "/vehicleprofiles/wiring/profile/${state.company}",
                    body = bodyProfile,
                    serializer = WiringProfileDTO.serializer()
                )
                setState {
                    saveSuccess = true
                    selectedProfile = response
                }
            }
        }.invokeOnCompletion {
            fetchWiringProfiles()
        }
    }


    override fun RBuilder.render() {
        authorize(Permissions.VehicleProfileManagement.wiringProfileView) {
            spacer()
            mGridContainer2(direction = MGridDirection.row) {
                mGridItem2(
                    MGridBreakpoints2(MGridSize2.Cells3).down(Breakpoint.md, MGridSize2.Cells12)
                ) {
                    sideBar()
                }
                mGridItem2(
                    MGridBreakpoints2(MGridSize2.Cells9).down(Breakpoint.md, MGridSize2.Cells12)
                ) {
                    detail()
                }
            }
        }
    }



    fun  RBuilder.ibisTypeSelect(
        label: String,
        value: () -> String,
        transmit: () -> Boolean,
        receive: () -> Boolean,
        setValueState: (String) -> Unit,
        setTransmitState: (Boolean) -> Unit,
        setReceiveState: (Boolean) -> Unit,
    ) {
        mTableCell {
            css { padding(4.px) }
            mTypography(
                text = label,
                variant = MTypographyVariant.body1
            )
        }
        mTableCell {
            css { padding(4.px) }
            mSelect(
                value = value(),
                onChange = { event, child ->
                    val selection = event.targetValue as String
                    setValueState(selection)
                }
            ) {
                css {
                    minWidth = 80.px
                    maxWidth = 200.px
                }
                attrs.fullWidth = true

                for (element in WiredType.values()) {
                    mMenuItem(
                        primaryText = element.readableName,
                        value = element.name
                    )
                }
            }
        }
        mTableCell {
            css { padding(4.px) }
            mCheckboxWithLabelPadding(
                label = "Senden",
                checked = transmit(),
                disabled = value() != WiredType.WIRED.name,
                onChange = { _, checked ->
                    setTransmitState(checked)
                },
                paddingCheckbox = ""
            )
        }
        mTableCell {
            css { padding(4.px) }
            mCheckboxWithLabelPadding(
                label = "Empfangen",
                checked = receive(),
                disabled = value() != WiredType.WIRED.name,
                onChange = { _, checked ->
                    setReceiveState(checked)
                },
                paddingCheckbox = ""
            )
        }
    }
    fun  RBuilder.canTypeSelect(
        label: String,
        value: () -> String,
        baud: () -> String,
        termination: () -> Boolean,
        setValueState: (String) -> Unit,
        setBaudState: (String) -> Unit,
        setTermination: (Boolean) -> Unit,
    ) {
        mTableCell {
            css { padding(4.px) }
            mTypography(
                text = label,
                variant = MTypographyVariant.body1
            )
        }
        mTableCell {
            css { padding(4.px) }
            mSelect(
                value = value(),
                onChange = { event, child ->
                    val selection = event.targetValue as String
                    setValueState(selection)
                }
            ) {
                css {
                    minWidth = 80.px
                    maxWidth = 200.px
                }
                attrs.fullWidth = true

                for (element in WiredType.values()) {
                    mMenuItem(
                        primaryText = element.readableName,
                        value = element.name
                    )
                }
            }
        }
        mTableCell {
            css { padding(4.px) }
            mTextFieldSelect(
                label = "Baud",
                value = baud(),
                onChange = { event ->
                    val selection = event.targetValue as String
                    setBaudState(selection)
                }
            ) {
                css {
                    height = 0.px
                    position = Position.relative
                    top = -36.px
                }
                attrs.fullWidth = true
                canBauds.forEach {
                    mMenuItem(
                        primaryText = it,
                        value = it
                    )
                }
            }
        }
        mTableCell {
            css { padding(4.px) }
            mCheckboxWithLabel(
                label = "Terminierung Einschalten",
                checked = termination(),
                onChange = { _, checked ->
                    setTermination(checked)
                }
            ) {
                css {
                    height = 0.px
                    position = Position.relative
//                    top = -36.px
                }
            }
        }
    }
    fun  RBuilder.internetTypeSelect(
        label: String,
        value: () -> String,
        setValueState: (String) -> Unit,
    ) {
        mTableCell {
            css { padding(4.px) }
            mTypography(
                text = label,
                variant = MTypographyVariant.body1
            )
        }
        mTableCell {
            css { padding(4.px) }
            mSelect(
                value = value(),
                onChange = { event, child ->
                    val selection = event.targetValue as String
                    setValueState(selection)
                }
            ) {
                css {
                    minWidth = 80.px
                    maxWidth = 200.px
                }
                attrs.fullWidth = true

                for (element in InternetSource.values()) {
                    mMenuItem(
                        primaryText = element.readableName,
                        value = element.name
                    )
                }
            }
        }
    }
    fun  RBuilder.wireTypeSelect(
        label: String,
        value: () -> String,
        setValueState: (String) -> Unit,
    ) {
        mTableCell {
            css { padding(4.px) }
            mTypography(
                text = label,
                variant = MTypographyVariant.body1
            )
        }
        mTableCell {
            css {
                padding(4.px)
            }
            mSelect(
                value = value(),
                onChange = { event, child ->
                    val selection = event.targetValue as String
                    setValueState(selection)
                }
            ) {
                css {
                    minWidth = 80.px
                    maxWidth = 200.px
                }
                attrs.fullWidth = true

                for (element in WiredType.values()) {
                    mMenuItem(
                        primaryText = element.readableName,
                        value = element.name
                    )
                }
            }
        }
    }

    fun RBuilder.volumeSelect(
        label: String,
        value: () -> Int,
        setValueState: (Int) -> Unit,
    ) {
        mTableCell {
            css { padding(4.px) }
            mTypography(
                text = label,
                variant = MTypographyVariant.body1
            )
        }
        mTableCell {
            css {
                padding(4.px)
            }
            mSlider(
                label = "Ausgangslautstärke",
                value = value(),
                min = 0,
                max = 100,
                step = 1,
                valueLabelDisplay = MSliderValueLabelDisplay.auto,
                onChange = { _, value ->
                    setValueState(value.toInt())
//                    setState {
//                        selectedProfile =
//                            profile.copy(speakerVolume = value.toInt())
//                        saveSuccess = false
//                    }
                }
            )
        }
    }

    fun RBuilder.ethernetSelect(
        label: String,
        value: () -> String,
        setValueState: (String) -> Unit,
        ibisIP: () -> Boolean,
        setIbisIPState: (Boolean) -> Unit,
    ) {
        mTableCell {
            css { padding(4.px) }
            mTypography(
                text = label,
                variant = MTypographyVariant.body1
            )
        }
        mTableCell {
            css {
                padding(4.px)
            }
            mSelect(
                value = value(),
                onChange = { event, child ->
                    val selection = event.targetValue as String
                    setValueState(selection)
                }
            ) {
                css {
                    minWidth = 80.px
                    maxWidth = 200.px
                }
                attrs.fullWidth = true

                for (element in WiredType.values()) {
                    mMenuItem(
                        primaryText = element.readableName,
                        value = element.name
                    )
                }
            }
        }
        mTableCell {
            css { padding(4.px) }
            mCheckboxWithLabelPadding(
                label = "IBIS IP",
                checked = ibisIP(),
                disabled = value() != WiredType.WIRED.name,
                onChange = { _, checked ->
                    setIbisIPState(checked)
                },
                paddingCheckbox = ""
            )
        }
    }

    fun  RBuilder.outFunctionSelect(
        label: String,
        value: () -> String,
        setValueState: (String) -> Unit,
    ) {
        mTableCell {
            css { padding(4.px) }
            mTypography(
                text = label,
                variant = MTypographyVariant.body1
            )
        }
        mTableCell {
            css {
                minWidth = 80.px
                maxWidth = 200.px
                padding(4.px)
            }
            mSelect(
                value = value(),
                onChange = { event, child ->
                    val selection = event.targetValue as String
                    setValueState(selection)
                }
            ) {
                css {
                    minWidth = 80.px
                    maxWidth = 200.px
                }
                attrs.fullWidth = true

                for (element in OutFunction.values()) {
                    mMenuItem(
                        primaryText = element.readableName,
                        value = element.name
                    )
                }
            }
        }
    }

    fun  RBuilder.inFunctionSelect(
        label: String,
        value: () -> String,
        switch: () -> Boolean,
        setValueState: (String) -> Unit,
        setSwitchState: (Boolean) -> Unit,
    ) {
        mTableCell {
            css {
                padding(4.px)
            }
            mTypography(
                text = label,
                variant = MTypographyVariant.body1
            )
        }
        mTableCell(size = MTableCellSize.small) {
            css {
                padding(0.spacingUnits)
                paddingRight = 4.px
            }
            mSelect(
                value = value(),
                onChange = { event, child ->
                    val selection = event.targetValue as String
                    setValueState(selection)
                }
            ) {
                attrs.fullWidth = true

                for (element in InFunction.values()) {
                    mMenuItem(
                        primaryText = element.readableName,
                        value = element.name
                    )
                }
            }
        }
        mTableCell {
            css {
                width = 125.px
                padding(0.spacingUnits)
            }
            mTypography(
                text = "Schaltpegel: ",
                variant = MTypographyVariant.body1
            ) {
                css {
                    paddingRight = 4.px
                    minWidth = 80.px
                    float = Float.left
                    marginTop = 4.px
                }
            }
            mSelect(
                value = if (switch()) "t" else "f",
                onChange = { event, child ->
                    val selection = event.targetValue as String
                    setSwitchState(selection == "t")
                }
            ) {
                css {
                    maxWidth = 120.px
                    minWidth = 60.px
                    float = Float.left
                }
//                attrs.margin = MFormControlMargin.dense.toString()
                attrs.fullWidth = true
                mMenuItem(primaryText = "Active High", value = "t")
                mMenuItem(primaryText = "Active Low", value = "f")
            }
        }
    }
    fun RBuilder.detail() {
        mGridContainer2(direction = MGridDirection.row) {
            mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                mCard {
                    css(GlobalStyles.card)
                    mCardContent {
                        css(GlobalStyles.cardListContent)
                        if (state.profiles.isEmpty()) {
                            emptyView(
                                title = "Verkabelung Profil",
                                caption = "Es wurden keine Profile gefunden",
                                addButton = false,
                            )
                        } else {
                            mListSubheader(
                                heading = "Profil: ${state.selectedProfile?.name ?: "k.A."}"
                            )
                            mDivider { }
                            styledDiv {
                                css { padding(2.spacingUnits) }

                                mTextField(
                                    id = "name",
                                    variant = MFormControlVariant.outlined,
                                    label = "Name",
                                    disabled = !state.editable,
                                    type = InputType.text,
                                    value = state.selectedProfile?.name ?: "",
                                    onChange = {
                                        val value = it.targetInputValue
                                        //val newProfile = state.selectedProfile?.copy(profileName = value)
                                        setState {
                                            selectedProfile?.let {
                                                selectedProfile = it.copy(name = value)
                                                saveSuccess = false
                                            }
                                        }
                                    },
                                    autoFocus = true
                                ) {
                                    attrs.margin = MFormControlMargin.dense;
                                    attrs.fullWidth = true
                                }

                                if (state.profiles?.isNotEmpty() == true) {
                                    mTableContainer {
                                        mTable {
                                            css {
                                                width = LinearDimension("unset")
                                                whiteSpace = WhiteSpace.nowrap
                                            }
                                            mTableHead {
                                                mTableRowSlim {
                                                    mTableCell(
                                                        align = MTableCellAlign.left,
                                                        variant = MTableCellVariant.head,
                                                        padding = MTableCellPadding.none
                                                    ) {
                                                        css {
                                                            width = 125.px
                                                            padding(1.spacingUnits)
                                                        }
                                                        +"Funktion"
                                                    }
                                                    mTableCell(
                                                        align = MTableCellAlign.left,
                                                        variant = MTableCellVariant.head,
                                                        padding = MTableCellPadding.none
                                                    ) {
                                                        css {
                                                            padding(1.spacingUnits)
                                                            minWidth = 80.px
                                                            width = 200.px
                                                            maxWidth = 200.px
                                                        }
                                                        +"Einstellung"
                                                    }
                                                    mTableCell(
                                                        align = MTableCellAlign.left,
                                                        variant = MTableCellVariant.head,
                                                        padding = MTableCellPadding.none
                                                    ) {
                                                        css {
                                                            minWidth = 220.px
                                                            padding(1.spacingUnits)
                                                        }
                                                        +""
                                                    }
                                                    mTableCell(
                                                        align = MTableCellAlign.left,
                                                        variant = MTableCellVariant.head,
                                                        padding = MTableCellPadding.none
                                                    ) {
                                                        css { padding(1.spacingUnits) }
                                                        +""
                                                    }
                                                }
                                            }
                                            mTableBody {
                                                state.selectedProfile?.let { profile ->
                                                    //val profileMapping = state.selectedProfile?.mapping?.firstOrNull { it.telegramType == telegram }

                                                    mTableRowSlim {
                                                        inFunctionSelect(
                                                            label = "Eingang 1",
                                                            value = { profile.in1.name },
                                                            switch = { profile.in1SwitchHigh },
                                                            setValueState = { value ->
                                                                setState {
                                                                    selectedProfile =
                                                                        profile.copy(in1 = InFunction.valueOf(value))
                                                                    saveSuccess = false
                                                                }
                                                            },
                                                            setSwitchState = { value ->
                                                                setState {
                                                                    selectedProfile =
                                                                        profile.copy(in1SwitchHigh = value)
                                                                    saveSuccess = false
                                                                }
                                                            },
                                                        )
                                                    }
                                                    mTableRowSlim {
                                                        inFunctionSelect(
                                                            label = "Eingang 2",
                                                            value = { profile.in2.name },
                                                            switch = { profile.in2SwitchHigh },
                                                            setValueState = { value ->
                                                                setState {
                                                                    selectedProfile =
                                                                        profile.copy(in2 = InFunction.valueOf(value))
                                                                    saveSuccess = false
                                                                }
                                                            },
                                                            setSwitchState = { value ->
                                                                setState {
                                                                    selectedProfile =
                                                                        profile.copy(in2SwitchHigh = value)
                                                                    saveSuccess = false
                                                                }
                                                            },
                                                        )
                                                    }
                                                    mTableRowSlim {
                                                        inFunctionSelect(
                                                            label = "Eingang 3",
                                                            value = { profile.in3.name },
                                                            switch = { profile.in3SwitchHigh },
                                                            setValueState = { value ->
                                                                setState {
                                                                    selectedProfile =
                                                                        profile.copy(in3 = InFunction.valueOf(value))
                                                                    saveSuccess = false
                                                                }
                                                            },
                                                            setSwitchState = { value ->
                                                                setState {
                                                                    selectedProfile =
                                                                        profile.copy(in3SwitchHigh = value)
                                                                    saveSuccess = false
                                                                }
                                                            },
                                                        )
                                                    }
                                                    mTableRowSlim {
                                                        inFunctionSelect(
                                                            label = "Eingang 4",
                                                            value = { profile.in4.name },
                                                            switch = { profile.in4SwitchHigh },
                                                            setValueState = { value ->
                                                                setState {
                                                                    selectedProfile =
                                                                        profile.copy(in4 = InFunction.valueOf(value))
                                                                    saveSuccess = false
                                                                }
                                                            },
                                                            setSwitchState = { value ->
                                                                setState {
                                                                    selectedProfile =
                                                                        profile.copy(in4SwitchHigh = value)
                                                                    saveSuccess = false
                                                                }
                                                            },
                                                        )
                                                    }
                                                    mTableRowSlim {
                                                        outFunctionSelect(
                                                            label = "Ausgang 1",
                                                            value = { profile.out1.name },
                                                            setValueState = { value ->
                                                                setState {
                                                                    selectedProfile =
                                                                        profile.copy(out1 = OutFunction.valueOf(value))
                                                                    saveSuccess = false
                                                                }
                                                            },
                                                        )
                                                    }
                                                    mTableRowSlim {
                                                        outFunctionSelect(
                                                            label = "Ausgang 2",
                                                            value = { profile.out2.name },
                                                            setValueState = { value ->
                                                                setState {
                                                                    selectedProfile =
                                                                        profile.copy(out2 = OutFunction.valueOf(value))
                                                                    saveSuccess = false
                                                                }
                                                            },
                                                        )
                                                    }
                                                    mTableRowSlim {
                                                        outFunctionSelect(
                                                            label = "Ausgang 3",
                                                            value = { profile.out3.name },
                                                            setValueState = { value ->
                                                                setState {
                                                                    selectedProfile =
                                                                        profile.copy(out3 = OutFunction.valueOf(value))
                                                                    saveSuccess = false
                                                                }
                                                            },
                                                        )
                                                    }
                                                    mTableRowSlim {
                                                        outFunctionSelect(
                                                            label = "Ausgang 4",
                                                            value = { profile.out4.name },
                                                            setValueState = { value ->
                                                                setState {
                                                                    selectedProfile =
                                                                        profile.copy(out4 = OutFunction.valueOf(value))
                                                                    saveSuccess = false
                                                                }
                                                            },
                                                        )
                                                    }
                                                    mTableRowSlim {
                                                        outFunctionSelect(
                                                            label = "Ausgang 5 (nur Connect)",
                                                            value = { profile.out5.name },
                                                            setValueState = { value ->
                                                                setState {
                                                                    selectedProfile =
                                                                        profile.copy(out5 = OutFunction.valueOf(value))
                                                                    saveSuccess = false
                                                                }
                                                            },
                                                        )
                                                    }
                                                    mTableRowSlim {
                                                        wireTypeSelect(
                                                            label = "Lautsprecher 1",
                                                            value = { profile.speaker1.name },
                                                            setValueState = { value ->
                                                                setState {
                                                                    selectedProfile =
                                                                        profile.copy(speaker1 = WiredType.valueOf(value))
                                                                    saveSuccess = false
                                                                }
                                                            },
                                                        )
                                                    }
                                                    mTableRowSlim {
                                                        wireTypeSelect(
                                                            label = "Lautsprecher 2 (nur Connect)",
                                                            value = { profile.speaker2.name },
                                                            setValueState = { value ->
                                                                setState {
                                                                    selectedProfile =
                                                                        profile.copy(speaker2 = WiredType.valueOf(value))
                                                                    saveSuccess = false
                                                                }
                                                            },
                                                        )
                                                    }
                                                    mTableRowSlim {
                                                        volumeSelect(
                                                            label = "Ausgangslautstärke",
                                                            value = { profile.speakerVolume },
                                                            setValueState = { value ->
                                                                setState {
                                                                    selectedProfile =
                                                                        profile.copy(speakerVolume = value)
                                                                    saveSuccess = false
                                                                }
                                                            },
                                                        )
                                                    }
                                                    mTableRowSlim {
                                                        canTypeSelect(
                                                            label = "CAN Bus",
                                                            value = { profile.can.name },
                                                            baud = { profile.canBaud.toString() },
                                                            termination = { profile.canTermination },
                                                            setValueState = { value ->
                                                                setState {
                                                                    selectedProfile =
                                                                        profile.copy(can = WiredType.valueOf(value))
                                                                    saveSuccess = false
                                                                }
                                                            },
                                                            setBaudState = { value ->
                                                                setState {
                                                                    try {
                                                                        selectedProfile =
                                                                            profile.copy(canBaud = value.toInt())
                                                                    } catch (e: Exception) {
                                                                            profile.copy(canBaud = 0)
                                                                    }
                                                                    saveSuccess = false
                                                                }
                                                            },
                                                            setTermination = { value ->
                                                                setState {
                                                                    selectedProfile = profile.copy(canTermination = value)
                                                                    saveSuccess = false
                                                                }
                                                            },
                                                        )
                                                    }

                                                    mTableRowSlim {
                                                        ibisTypeSelect(
                                                            label = "IBIS VDV300",
                                                            value = { profile.ibisVDV300.name },
                                                            transmit = { profile.ibisTransmit },
                                                            receive = { profile.ibisReceive },
                                                            setValueState = { value ->
                                                                setState {
                                                                    selectedProfile =
                                                                        if(WiredType.valueOf(value) != WiredType.WIRED)
                                                                            profile.copy(
                                                                                ibisVDV300 = WiredType.valueOf(value),
                                                                                ibisTransmit = false,
                                                                                ibisReceive = false
                                                                            )
                                                                        else
                                                                            profile.copy(ibisVDV300 = WiredType.valueOf(value))

                                                                    saveSuccess = false
                                                                }
                                                            },
                                                            setTransmitState = { value ->
                                                                setState {
                                                                    selectedProfile = profile.copy(ibisTransmit = value)
                                                                    saveSuccess = false
                                                                }
                                                            },
                                                            setReceiveState = { value ->
                                                                setState {
                                                                    selectedProfile = profile.copy(ibisReceive = value)
                                                                    saveSuccess = false
                                                                }
                                                            },
                                                        )
                                                    }

                                                    mTableRowSlim {
                                                        ethernetSelect(
                                                            label = "Ethernet",
                                                            value = { profile.ethernet.name },
                                                            ibisIP = { profile.ibisIP },
                                                            setValueState = { value ->
                                                                setState {
                                                                    selectedProfile =
                                                                        if(WiredType.valueOf(value) != WiredType.WIRED)
                                                                            profile.copy(
                                                                                ethernet = WiredType.valueOf(value),
                                                                                ibisIP = false
                                                                            )
                                                                        else
                                                                            profile.copy(ethernet = WiredType.valueOf(value))

                                                                    saveSuccess = false
                                                                }
                                                            },
                                                            setIbisIPState = {
                                                                setState {
                                                                    selectedProfile = profile.copy(ibisIP = it)
                                                                    saveSuccess = false
                                                                }
                                                            }
                                                        )
                                                    }
                                                    mTableRowSlim {
                                                        wireTypeSelect(
                                                            label = "GPS",
                                                            value = { profile.gps.name },
                                                            setValueState = { value ->
                                                                setState {
                                                                    selectedProfile =
                                                                        profile.copy(gps = WiredType.valueOf(value))
                                                                    saveSuccess = false
                                                                }
                                                            },
                                                        )
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        if (state.profiles.isNotEmpty()) {
                            mDivider { }
                            mCardActions {
                                css { padding(2.spacingUnits) }
                                mGridContainer2(
                                    direction = MGridDirection.row,
                                    alignItems = MGridAlignItems.center
                                ) {
                                    mGridItem2(
                                        MGridBreakpoints2(MGridSize2.Auto)
                                            .down(Breakpoint.xs, MGridSize2.Cells12)
                                    ) {
                                        mButton(
                                            caption = "Übernehmen",
                                            variant = MButtonVariant.contained,
                                            color = MColor.secondary,
                                            onClick = {
                                                if (state.profiles
                                                        .filter { it.id != state.selectedProfile?.id }
                                                        .any { it.name == state.selectedProfile?.name }
                                                ) {
                                                    setState {
                                                        warnDialog = "Es gibt bereits ein Profil mit diesem Namen."
                                                    }
                                                } else
                                                    state.selectedProfile?.let {
                                                        saveProfile(it)
                                                    }
                                            }
                                        ) {
                                            attrs.disableElevation = true
                                        }
                                        if (state.saveSuccess == true) {
                                            mIconNoTranslate("check_circle") {
                                                css { color = Color.green }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        mDialog(
            open = state.warnDialog != null,
            onClose = { _, _ -> setState { warnDialog = null } }
        ) {
            mDialogContent { mTypography(state.warnDialog) }
            mDialogActions {
                mButton(
                    caption = "Okay",
                    onClick = { setState { warnDialog = null } }
                )
            }
        }
    }

    private fun RBuilder.sideBar() {
        mDialog(
            open = state.deleteProfileDialog,
            scroll = DialogScroll.paper,
            maxWidth = Breakpoint.md,
            onClose = { _, _ ->
                setState {
                    deleteProfileDialog = false
                    deleteProfileCandidate = null
                }
            }
        ) {
            attrs.disableEscapeKeyDown = true
            mTypography(
                text = "Profil \"${state.deleteProfileCandidate?.name}\" löschen?"
            ) { css { margin(horizontal = LinearDimension.auto, vertical = 6.pt) } }
            mDialogActions {
                mButton(
                    caption = "Ja, Profil löschen.",
                    color = MColor.secondary,
                    onClick = {
                        deleteProfile(state.deleteProfileCandidate)
                        setState {
                            deleteProfileDialog = false
                            deleteProfileCandidate = null
                        }
                    }
                )
                mButton(
                    caption = "Abbrechen",
                    color = MColor.primary,
                    onClick = {
                        setState {
                            deleteProfileDialog = false
                            deleteProfileCandidate = null
                        }
                    }
                )
            }
        }

        mGridContainer2(direction = MGridDirection.column) {
            mGridItem2 {
                if (state.profiles.isEmpty()) {
                    if (isAuthorized(Permissions.VehicleProfileManagement.wiringProfileEdit)) {
                        emptyView(
                            iconName = "menu",
                            title = "Verkabelungsprofile",
                            caption = "Es wurden keine Verkabelungsprofile gefunden",
                            addButton = true,
                            actionBtnCaption = "Erstellen"
                        ) {
                            setState {
                                selectedProfile = null
                                state.company?.let {
                                    val newProfile = WiringProfileDTO()
                                    saveProfile(newProfile)
                                }
                            }
                        }
                    }
                } else {
                    mCard {
                        css(GlobalStyles.card)
                        mCardContent {
                            css(GlobalStyles.cardListContent)

                            styledDiv {
                                css {
                                    display = Display.flex
                                    justifyContent = JustifyContent.spaceBetween
                                    alignItems = Align.center
                                }
                                mListSubheader(heading = "Verkabelungsprofile")
                                if (isAuthorized(Permissions.VehicleProfileManagement.wiringProfileEdit)) {
                                    mButton(
                                        caption = "Neu",
                                        size = MButtonSize.small,
                                        color = MColor.secondary,
                                        variant = MButtonVariant.outlined,
                                        onClick = {
                                            setState {
                                                state.company?.let {
                                                    val newProfile = WiringProfileDTO(name = "Neues Profil")
                                                    profiles.add(newProfile)
                                                    selectedProfile = newProfile
                                                }
                                            }
                                        }
                                    ) {
                                        css { margin(0.px, 1.spacingUnits) }
                                    }
                                }
                            }

                            mList {
                                state.profiles.forEach { profile ->
                                    mListItem(
                                        button = true,
                                        onClick = {
                                            setState {
                                                selectedProfile = profile
                                            }
                                        }
                                    ) {
                                        mListItemText(
                                            primary = profile.name,
                                        )

                                        if (isAuthorized(Permissions.VehicleProfileManagement.wiringProfileEdit))
                                            mListItemSecondaryAction {
                                                mIconButtonNoTranslate(
                                                    iconName = "delete",
                                                    color = MColor.primary,
                                                    onClick = {
                                                        setState {
                                                            deleteProfileCandidate = profile
                                                            deleteProfileDialog = true
                                                        }
                                                    }
                                                )
                                            }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}