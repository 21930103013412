package de.geomobile.frontend.features.map

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.styles.Breakpoint
import com.ccfraser.muirwik.components.styles.down
import de.geomobile.common.portalmodels.DeviceIdentifier
import de.geomobile.common.portalmodels.Product
import de.geomobile.common.portalmodels.TimestampStatus
import de.geomobile.common.portalmodels.VehicleType
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.currentTheme
import de.geomobile.frontend.features.device.toDetailPath
import de.geomobile.frontend.spacer
import de.geomobile.frontend.utils.mIconNoTranslate
import kotlinx.css.*
import org.w3c.dom.events.Event
import react.*
import react.router.dom.RouteResultHistory
import react.router.dom.route
import styled.css
import styled.styledDiv

fun RBuilder.devicesMap(
    basePath: String,
    drawerMenu: ReactElement,
) = child(DevicesMapComponent::class) {
    attrs.basePath = basePath
    attrs.drawerMenu = drawerMenu
}

class DevicesMapComponent : RComponent<DevicesMapComponent.Props, DevicesMapComponent.State>() {

    class State(
        var hideOfflineVehicles: Boolean = true,
        var showOnlyBadSignalVehicles: Boolean = false
    ) : RState

    init {
        state = State()
    }

    interface Props : RProps {
        var path: String
        var basePath: String
        var drawerMenu: ReactElement
    }

    override fun RBuilder.render() {
        mAppBar(position = MAppBarPosition.fixed) {
            css(GlobalStyles.appbar)
            mToolbar {
                child(props.drawerMenu)

                styledDiv {
                    css { flexGrow = 1.0; display = Display.flex; alignItems = Align.center }

                    toolbarLegend(
                        label = "Aktuell",
                        statusLoc = TimestampStatus.Status.ONLINE,
                        statusSeen = TimestampStatus.Status.ONLINE
                    )
                    toolbarLegend(
                        label = "Unterbrochen",
                        statusLoc = TimestampStatus.Status.OVERDUE,
                        statusSeen = TimestampStatus.Status.ONLINE
                    )
                    toolbarLegend(
                        label = "Veraltet",
                        statusLoc = TimestampStatus.Status.OFFLINE,
                        statusSeen = TimestampStatus.Status.ONLINE
                    )
                    toolbarLegend(
                        label = "Schwach",
                        statusLoc = TimestampStatus.Status.UNKNOWN,
                        statusSeen = TimestampStatus.Status.UNKNOWN,
                        gpsQuality = 0
                    )
                    toolbarLegend(
                        label = "N/A",
                        statusLoc = TimestampStatus.Status.OFFLINE,
                        statusSeen = TimestampStatus.Status.OFFLINE
                    )
                }

                route<RProps>(props.basePath) { routeProps ->
                    mButton(
                        caption = "Legende",
                        variant = MButtonVariant.outlined,
                        color = MColor.inherit,
                        onClick = { routeProps.history.push("${props.basePath}/legende/status") }
                    ) {
                        attrs.endIcon = mIconNoTranslate(
                            iconName = "star",
                            addAsChild = false
                        ) {
                            css {
                                fontSize = LinearDimension.inherit
                                media(currentTheme.breakpoints.down(Breakpoint.md)) {
                                    display = Display.none
                                }
                            }
                        }
                    }
                }

                route<RProps>(props.basePath) { routeProps ->
                    mButton(
                        caption = "Signal",
                        variant = MButtonVariant.outlined,
                        color = MColor.inherit,
                        onClick = { routeProps.history.push("${props.basePath}/gps/settings") }
                    ) {
                        css { marginLeft = 1.spacingUnits }

                        attrs.endIcon = mIconNoTranslate(
                            iconName = "perm_data_settings",
                            addAsChild = false
                        ) {
                            css {
                                fontSize = LinearDimension.inherit
                                media(currentTheme.breakpoints.down(Breakpoint.md)) {
                                    display = Display.none
                                }
                            }
                        }
                    }
                }
            }
        }
        styledDiv {
            css {
                display = Display.flex
                flexDirection = FlexDirection.column

                width = 100.pct
                height = 100.pct
            }
            spacer()
            styledDiv {
                css {
                    display = Display.flex
                    flexDirection = FlexDirection.row
                    flexGrow = 1.0
                    width = 100.pct
                    height = 100.pct
                    minHeight = 0.spacingUnits
                }
                route<RProps>(props.basePath) { routeProps ->
                    mapView(
                        hideOfflineVehicles = state.hideOfflineVehicles,
                        showOnlyBadSignalVehicles = state.showOnlyBadSignalVehicles,
                        drawerMenu = props.drawerMenu,
                        onDeviceClick = { identifier -> routeProps.history.push("${props.basePath}${identifier.toDetailPath()}") }
                    )
                }
                route<IdProp>("${props.basePath}/gps/settings", exact = true) { routeProps ->
                    renderSettings(
                        history = routeProps.history,
                        hideOfflineVehicles = state.hideOfflineVehicles,
                        showOnlyBadSignalVehicles = state.showOnlyBadSignalVehicles,
                        handleHideOfflineVehicles = { _, checked ->
                            setState { hideOfflineVehicles = checked }
                        },
                        handleShowOnlyBadSignalVehicles = { _, checked ->
                            setState { showOnlyBadSignalVehicles = checked }
                        }
                    )
                }
                route<IdProp>("${props.basePath}/legende/status", exact = true) { routeProps ->
                    renderLegend(
                        history = routeProps.history
                    )
                }
                route<IdProp>("${props.basePath}/:id", exact = true) { routeProps ->
                    val id: Int = routeProps.match.params.id

                    details(
                        identifier = DeviceIdentifier.Id(id),
                        history = routeProps.history
                    )
                }
                route<SerialNumberProp>(
                    "${props.basePath}/:product/serialnumber/:serialNumber",
                    exact = true
                ) { routeProps ->
                    val product = Product.fromName(routeProps.match.params.product)
                    val serialNumber = routeProps.match.params.serialNumber

                    details(
                        identifier = DeviceIdentifier.SerialNumber(product, serialNumber),
                        history = routeProps.history
                    )
                }
                route<CpuIdProp>("${props.basePath}/:product/cpuid/:cpuId", exact = true) { routeProps ->
                    val product = Product.fromName(routeProps.match.params.product)
                    val cpuId = routeProps.match.params.cpuId

                    details(
                        identifier = DeviceIdentifier.CpuId(product, cpuId),
                        history = routeProps.history
                    )
                }
            }
        }
    }

    private fun RBuilder.details(identifier: DeviceIdentifier, history: RouteResultHistory): ReactElement = styledDiv {
        mapDetail(selectedId = identifier, onClose = { history.push(props.basePath) })
    }

    private fun RBuilder.renderLegend(history: RouteResultHistory): ReactElement = styledDiv {
        mapLegend(onClose = { history.push(props.basePath) })
    }

    private fun RBuilder.renderSettings(
        history: RouteResultHistory,
        hideOfflineVehicles: Boolean,
        showOnlyBadSignalVehicles: Boolean,
        handleHideOfflineVehicles: (event: Event, checked: Boolean) -> Unit,
        handleShowOnlyBadSignalVehicles: (event: Event, checked: Boolean) -> Unit
    ): ReactElement = styledDiv {
        mapSettings(
            hideOfflineVehicles = hideOfflineVehicles,
            showOnlyBadSignalVehicles = showOnlyBadSignalVehicles,
            handleHideOfflineVehicles = handleHideOfflineVehicles,
            handleShowOnlyBadSignalVehicles = handleShowOnlyBadSignalVehicles,
            onClose = { history.push(props.basePath) }
        )
    }

    private interface IdProp : RProps {
        var id: Int
    }

    private interface SerialNumberProp : RProps {
        var product: String
        var serialNumber: Int
    }

    private interface CpuIdProp : RProps {
        var product: String
        var cpuId: String
    }
}

private fun RBuilder.toolbarLegend(
    label: String,
    statusLoc: TimestampStatus.Status,
    statusSeen: TimestampStatus.Status,
    gpsQuality: Int = 100
) {
    styledDiv {
        css {
            paddingTop = 1.spacingUnits
            marginRight = (-1).spacingUnits
            media(currentTheme.breakpoints.down(Breakpoint.xs)) {
                marginRight = (-2).spacingUnits
            }
        }
        marker {
            enableTransform = false
            currentZoom = 12
            key = ""
            lat = 0
            lng = 0
            id = 0
            vehicleId = null
            serialNumber = null
            vehicleType = VehicleType.BUS
            statusLocation = statusLoc
            statusLastSeen = statusSeen
            this.gpsQuality = gpsQuality
            onClick = { }
        }
    }

    mHidden(smDown = true) {
        mTypography(
            text = label,
            color = MTypographyColor.inherit,
            variant = MTypographyVariant.subtitle2,
            noWrap = true
        )
    }
}