package portalmodels

import de.geomobile.common.portalmodels.DeviceIdentifier
import de.geomobile.common.portalmodels.FloorType
import de.geomobile.common.portalmodels.OccupancyLevel
import de.geomobile.common.portalmodels.VehicleType
import de.geomobile.common.time.LocalDateTime
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import kotlin.random.Random

fun generateToken(): String {
    val chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
    return (1..(60 + Random.nextInt(0,5)) )
        .map { chars.random() }
        .joinToString("")
}

@Serializable
data class TicketTokenDTO(
    val token: String,
    val companyId: String,
    val maxRatePerSecond: Int,
    val maxRatePerMinute: Int,
    val exchangedForNew: Boolean = false,
    val expirationDate: LocalDateTime? = null
)

data class TicketApiCache(
    val companyId: String,
    val cache: HashMap<Int, TicketApiCacheEntry>
)

@Serializable
data class TicketApiCacheEntry(
    val vehicleId: String,
    @Transient
    val companyId: String = "",
    val line: String = "",
    val ibisLine: String = "",
    val direction: String = "",
    val destination: String = "",
    val vehicleType: VehicleType = VehicleType.NONE,
    val vehicleFloorType: FloorType = FloorType.NONE,
    val occupancyLevel: OccupancyLevel = OccupancyLevel.NONE,
    @Transient
    val lastOnline: Long = 0,
    @Transient
    val latitude: Double? = null,
    @Transient
    val longitude: Double? = null
)

@Serializable
data class TicketApiStatusDTO(
    val vehicleId: String,
    val lat: Double? = null,
    val lon: Double? = null,
    val occupancyLevel: OccupancyLevel = OccupancyLevel.NONE,
)

@Serializable
data class TicketApiDTO(
    val vehicleId: String,
    val line: String,
    val ibisLine: String,
    val direction: String,
    val destination: String,
    val vehicleType: VehicleType,
    val vehicleFloorType: FloorType,
    val occupancyLevel: OccupancyLevel
)

data class FastGpsDevice(
    val cpuId: DeviceIdentifier.CpuId,
    val vehicleId: DeviceIdentifier.VehicleId,
    val lat: Float,
    val lon: Float
)