package de.geomobile.frontend.features.admin.companies

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonSize
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardActions
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.form.MFormControlMargin
import com.ccfraser.muirwik.components.form.mFormControl
import com.ccfraser.muirwik.components.form.mFormGroup
import com.ccfraser.muirwik.components.input.mInput
import com.ccfraser.muirwik.components.input.mInputLabel
import de.geomobile.common.feature.Feature
import de.geomobile.common.permission.Permissions
import de.geomobile.common.portalmodels.Company
import de.geomobile.common.portalmodels.CompanySmall
import de.geomobile.common.stage.Stage
import de.geomobile.common.statistics.Month
import de.geomobile.common.statistics.text
import de.geomobile.common.time.LocalDateTime
import de.geomobile.frontend.features.admin.features.featureSelection
import de.geomobile.frontend.features.admin.stages.stageSelection
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.utils.CComponent
import de.geomobile.frontend.utils.authorize
import de.geomobile.frontend.utils.isAuthorized
import de.geomobile.frontend.utils.mCardHeaderExtended
import kotlinext.js.jsObject
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.*
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.json.Json
import org.w3c.dom.Element
import portalmodels.GenericResponseDTO
import portalmodels.MonitoringDTO
import react.RBuilder
import react.RProps
import react.RState
import react.setState
import styled.css
import styled.styledDiv

fun RBuilder.adminCompanyCreate(
    cancel: () -> Unit,
    created: (companyId: String) -> Unit
) = child(AdminCompanyDetailEdit::class) {
    attrs.companyId = null
    attrs.mode = AdminCompanyDetailEdit.Mode.CREATE
    attrs.cancel = cancel
    attrs.saved = created
}

fun RBuilder.adminCompanyEdit(
    companyId: String,
    goBack: () -> Unit
) = child(AdminCompanyDetailEdit::class) {
    attrs.companyId = companyId
    attrs.mode = AdminCompanyDetailEdit.Mode.EDIT
    attrs.cancel = { goBack() }
    attrs.saved = { goBack() }
}

class AdminCompanyDetailEdit : CComponent<AdminCompanyDetailEdit.Props, AdminCompanyDetailEdit.State>() {

    enum class Mode {
        CREATE, EDIT
    }

    interface Props : RProps {
        var companyId: String?
        var mode: Mode
        var cancel: () -> Unit
        var saved: (companyId: String) -> Unit
    }

    class State(
        var id: String? = null,
        var name: String? = null,
        var description: String? = null,
        var originator: Boolean = false,
        var selectedFeatures: Map<String, Feature> = emptyMap(),
        var selectedStages: Set<Stage> = emptySet(),
        var selectedCompaniesForSharing: Set<CompanySmall> = emptySet(),
        var selectedMonitoring: MutableList<MonitoringDTO> = mutableListOf(),
        var focusHelper: Element? = null,

        var saving: Boolean = false
    ) : RState

    init {
        state = State()
    }


    override fun componentDidMount() {
        launch {
            val company = if (props.companyId != null)
                withContext(Dispatchers.Default) {
                    portalRestApi.get("/admin/companies/${props.companyId}", Company.serializer())
                }
            else
                null

            val monitoring = company?.let {
                withContext(Dispatchers.Default) {
                    portalRestApi.get(
                        "/monitoring/${props.companyId}/companyId",
                        ListSerializer(MonitoringDTO.serializer())
                    )
                }
            }


            setState {
                this.name = company?.name
                this.description = company?.description
                this.originator = company?.originator == true
                this.selectedFeatures = company?.features?.associateBy { it.id }.orEmpty()
                this.selectedStages = company?.stages.orEmpty()
                this.selectedCompaniesForSharing = company?.shareDevicesWith.orEmpty()
                this.selectedMonitoring = monitoring?.toMutableList() ?: mutableListOf()
            }
        }
    }

    override fun RBuilder.render() {
        mCard {
            mCardHeaderExtended(
                title = when (props.mode) {
                    Mode.CREATE -> "Erstellen"
                    Mode.EDIT -> "Bearbeiten"
                },
                subHeader = state.name ?: "",
                titleTextTransform = TextTransform.uppercase,
                avatar = RBuilder().mIconButtonNoTranslate(
                    iconName = "arrow_back",
                    onClick = { props.cancel() }
                )
            ) {
                attrs.titleTypographyProps = jsObject<MTypographyProps> {
                    variant = MTypographyVariant.subtitle2
                    color = MTypographyColor.secondary
                }
                attrs.subheaderTypographyProps = jsObject<MTypographyProps> {
                    variant = MTypographyVariant.caption
                }
            }
            mDivider { }
            mCardContent {
                if (props.mode == Mode.EDIT && state.name == null) {
                    mCircularProgress { css { display = Display.block; margin(LinearDimension.auto) } }
                } else {
                    mFormGroup {
                        mFormControl(
                            margin = MFormControlMargin.normal,
                            required = true,
                            fullWidth = true,
                            disabled = props.companyId != null
                        ) {
                            mInputLabel(caption = "ID", htmlFor = "id")
                            mInput(
                                value = props.companyId ?: state.id ?: "",
                                name = "id",
                                id = "id",
                                onChange = {
                                    val value = it.targetInputValue
                                    setState { this.id = value }
                                }
                            )
                        }
                        mFormControl(margin = MFormControlMargin.normal, required = true, fullWidth = true) {
                            mInputLabel(caption = "Name", htmlFor = "name")
                            mInput(
                                value = state.name ?: "",
                                name = "name",
                                id = "name",
                                onChange = {
                                    val value = it.targetInputValue
                                    setState { this.name = value }
                                }
                            )
                        }
                        mFormControl(margin = MFormControlMargin.normal, required = false, fullWidth = true) {
                            mInputLabel(caption = "Beschreibung", htmlFor = "description")
                            mInput(
                                value = state.description ?: "",
                                name = "description",
                                id = "description",
                                onChange = {
                                    val value = it.targetInputValue
                                    setState { this.description = value }
                                }
                            )
                        }
                    }

                    (props.companyId ?: state.id)?.let {
                        mTypography("Accounting Downloads", variant = MTypographyVariant.h6) { css.marginTop = 3.spacingUnits }
                        val currentMonth = LocalDateTime.now().month()
                        val currentYear = LocalDateTime.now().year().toInt()
                        for(year in 2023..currentYear) {
                            for(month in 1..12) {
                                if ( year == currentYear && month > currentMonth) continue
                                val label = if(month == currentMonth && year == currentYear) "Aktueller Monat" else Month(year, month).text
                                mLink(
                                    hRefOptions = HRefOptions(
                                        href = "/../accounting/download/$it/$year/$month/summary.csv",
                                    ),
                                    underline = MLinkUnderline.none
                                ) {
                                    mButton(
                                        caption = "Abbrechnung $label",
                                        size = MButtonSize.small,
                                        color = MColor.secondary,
                                        variant = MButtonVariant.outlined
                                    ) {
                                        css {
                                            width = 15.pct
                                            margin(0.spacingUnits, 1.spacingUnits)
                                        }
                                    }
                                }
                                mLink(
                                    hRefOptions = HRefOptions(
                                        href = "/../accounting/download/$it/$year/$month/rawdata.csv",
                                    ),
                                    underline = MLinkUnderline.none
                                ) {
                                    mButton(
                                        caption = "Rohdaten $label",
                                        size = MButtonSize.small,
                                        color = MColor.secondary,
                                        variant = MButtonVariant.outlined
                                    ) {
                                        css { margin(0.spacingUnits, 1.spacingUnits) }
                                    }
                                }
                                mDivider {
                                    css {
                                        padding(2.px)
                                        visibility = Visibility.hidden
                                    }
                                }

                            }
                        }
                    }
                    mTypography("Monitoring", variant = MTypographyVariant.h6) { css.marginTop = 3.spacingUnits }
                    styledDiv {
                        state.selectedMonitoring.forEachIndexed { index, dto ->
                            mTextField(
                                label = "Label",
                                value = dto.label,
                                autoFocus = index == state.selectedMonitoring.size - 1,
                                onChange = { ev ->
                                    val text = ev.targetInputValue
                                    setState {
                                        if (text.isBlank() && dto.url.isBlank())
                                            selectedMonitoring.removeAt(index)
                                        else
                                            selectedMonitoring[index] = dto.copy(label = text)
                                    }
                                }
                            ) {
                                css {
                                    width = 10.pct
                                    marginTop = 2.px
                                    paddingRight = 4.px
                                }
                            }
                            mTextField(
                                label = "URL",
                                value = dto.url,
                                onChange = { ev ->
                                    val text = ev.targetInputValue
                                    setState {
                                        if (text.isBlank() && dto.label.isBlank())
                                            selectedMonitoring.removeAt(index)
                                        else
                                            selectedMonitoring[index] = dto.copy(url = text)
                                    }
                                }
                            ) {
                                css {
                                    width = 90.pct
                                    marginTop = 2.px
                                    paddingRight = 4.px
                                }
                            }
                            mTextFieldMultiLine(
                                label = "Beschreibung",
                                value = dto.description,
                                onChange = { ev ->
                                    val text = ev.targetInputValue

                                    setState {
                                        if (text.isBlank() && dto.description.isBlank())
                                            selectedMonitoring.removeAt(index)
                                        else
                                            selectedMonitoring[index] = dto.copy(description = text)
                                    }
                                }
                            ) {
                                css {
                                    width = 100.pct
                                    marginTop = 2.px
                                }
                            }
                        }
                    }
                    styledDiv {
                        mTextField(
                            label = "Label",
                            value = "",
                            onChange = { ev ->
                                val text = ev.targetInputValue
                                setState {
                                    props.companyId?.let { c ->
                                        selectedMonitoring.add(
                                            MonitoringDTO(
                                                companyId = c,
                                                label = text,
                                                url = "",
                                                description = ""
                                            )
                                        )
                                    }
                                }
                            }
                        ) {
                            css {
                                width = 10.pct
                                marginTop = 2.px
                                paddingRight = 4.px
                            }
                        }
                        mTextField(
                            label = "URL",
                            value = "",
                            onChange = { ev ->
                                val text = ev.targetInputValue
                                setState {
                                    props.companyId?.let { c ->
                                        selectedMonitoring.add(
                                            MonitoringDTO(
                                                companyId = c,
                                                label = "",
                                                url = text,
                                                description = ""
                                            )
                                        )
                                    }
                                }
                            }
                        ) {
                            css {
                                width = 90.pct
                                marginTop = 2.px
                                paddingRight = 4.px
                            }
                        }
                        mTextFieldMultiLine(
                            label = "Beschreibung",
                            value = "",
                            onChange = { ev ->
                                val text = ev.targetInputValue
                                setState {
                                    props.companyId?.let { c ->
                                        selectedMonitoring.add(
                                            MonitoringDTO(
                                                companyId = c,
                                                label = "",
                                                url = "",
                                                description = text
                                            )
                                        )
                                    }
                                }
                            }
                        ) {
                            css {
                                width = 100.pct
                                marginTop = 2.px
                            }
                        }
                    }

                    authorize(Permissions.CompanyManagement.stageEdit) {
                        mTypography("Stages", variant = MTypographyVariant.h6) { css.marginTop = 3.spacingUnits }
                        stageSelection(
                            mutable = !state.originator,
                            stages = state.selectedStages,
                            onChanged = {
                                setState { this.selectedStages = it }
                            }
                        )
                    }

                    mTypography("Geräte teilen mit", variant = MTypographyVariant.h6) { css.marginTop = 3.spacingUnits }
                    companySelection(
                        mutable = !state.originator,
                        selectedCompanies = state.selectedCompaniesForSharing,
                        onChanged = {
                            setState { this.selectedCompaniesForSharing = it }
                        }
                    )

                    authorize(Permissions.CompanyManagement.featureEdit) {
                        mTypography("Features", variant = MTypographyVariant.h6) { css.marginTop = 3.spacingUnits }
                        featureSelection(
                            mutable = !state.originator || isAuthorized(Permissions.AdminPermissions.internalAccess),
                            features = state.selectedFeatures,
                            onChanged = {
                                setState { this.selectedFeatures = it }
                            }
                        )
                    }

                }
            }

            if (state.saving) {
                mCircularProgress(size = 30.px) {
                    css {
                        display = Display.block
                        margin(vertical = 1.spacingUnits, horizontal = 3.spacingUnits)
                        marginLeft = LinearDimension.auto
                    }
                }
            } else {
                mDivider { }
                mCardActions {
                    css { padding(2.spacingUnits) }

                    val disabled = state.name == null || (props.companyId ?: state.id) == null

                    mButton(
                        caption = when (props.mode) {
                            Mode.CREATE -> "Erstellen"
                            Mode.EDIT -> "Übernehmen"
                        },
                        variant = MButtonVariant.contained,
                        color = MColor.secondary,
                        disabled = disabled,
                        onClick = {
                            val company = Company(
                                id = props.companyId ?: state.id!!,
                                name = state.name!!,
                                description = state.description,
                                features = state.selectedFeatures.values.toSet(),
                                stages = state.selectedStages,
                                shareDevicesWith = state.selectedCompaniesForSharing
                            )
                            saveCompany(company)
                        }
                    ) {
                        attrs.disableElevation = true
                    }

                    mButton(
                        caption = "Abbrechen",
                        variant = MButtonVariant.contained,
                        color = MColor.default,
                        onClick = { props.cancel() }
                    ) {
                        attrs.disableElevation = true
                    }
                }
            }
        }
    }

    private fun saveMonitoring() {
        launch {
            withContext(Dispatchers.Default) {
                val body = Json.encodeToString(ListSerializer(MonitoringDTO.serializer()), state.selectedMonitoring)
                portalRestApi.put("/monitoring/savelist", body, GenericResponseDTO.serializer())
            }
        }
    }

    private fun saveCompany(company: Company) {
        setState {
            this.saving = true
        }

        launch {
            saveMonitoring()
            val newCompany = withContext(Dispatchers.Default) {
                when (props.mode) {
                    Mode.CREATE ->
                        portalRestApi.post(
                            path = "/admin/companies",
                            body = Json.encodeToString(Company.serializer(), company),
                            serializer = Company.serializer()
                        )

                    Mode.EDIT ->
                        portalRestApi.put(
                            path = "/admin/companies/${company.id}",
                            body = Json.encodeToString(Company.serializer(), company),
                            serializer = Company.serializer()
                        )
                }
            }

            props.saved(newCompany.id)
        }
    }
}