package de.geomobile.common.portalmodels

import de.geomobile.common.time.LocalDate
import kotlinx.serialization.Serializable
import portalmodels.FmsProfileDTO
import portalmodels.IbisProfileDTO

@Serializable
data class DeviceDetailDTO(
    val id: Int,
    val product: Product,
    val productVariant: String?,
    val serialNumber: Int?,
    val vehicleId: String,
    val vehicleIdByIbis: String,
    val vehicleIdOverride: Boolean,
    val inceptionDate: LocalDate,
    val installDate: LocalDate,
    val shippingDate: LocalDate?,
    val vehicleType: VehicleType,
    val lastSeen: TimestampStatus,
    val betaLastSeen: TimestampStatus,
    val description: String? = null,
    val vehicleProfile: VehicleProfileSmallDTO? = null,
    val fmsProfile: FmsProfileDTO? = null,
    val ibisProfile: IbisProfileDTO? = null,
) {

    fun identifierById(): DeviceIdentifier.Id = DeviceIdentifier.Id(id = id)

    fun identifierBySerialNumber(): DeviceIdentifier.SerialNumber? = serialNumber?.let {
        DeviceIdentifier.SerialNumber(
            product = product,
            serialNumber = serialNumber
        )
    }

    fun primaryIdentifier(): DeviceIdentifier =
        identifierBySerialNumber()
            ?: identifierById()
}