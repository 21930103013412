package de.geomobile.frontend.features.dashboard

import com.ccfraser.muirwik.components.MGridAlignItems
import com.ccfraser.muirwik.components.MGridDirection
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.list.mListSubheader
import com.ccfraser.muirwik.components.mDivider
import com.ccfraser.muirwik.components.styles.Breakpoint
import de.geomobile.common.portalmodels.VehicleType
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.components.renderCountItem
import de.geomobile.frontend.components.renderDivider
import de.geomobile.frontend.components.statusCountView
import de.geomobile.frontend.utils.*
import kotlinx.css.Color
import kotlinx.css.TextAlign
import kotlinx.css.px
import kotlinx.css.textAlign
import portalmodels.DashboardStatusSummaryDTO
import react.RBuilder
import react.RProps
import react.RState
import react.buildElement
import styled.css

fun RBuilder.dashboardVehicles(
    company: String?,
    loading: Boolean,
    summaries: DashboardStatusSummaryDTO?,
) = child(DashboardVehicles::class) {
    attrs.company = company
    attrs.loading = loading
    attrs.summaries = summaries
}

class DashboardVehicles : CComponent<DashboardVehicles.Props, DashboardVehicles.State>() {

    interface Props : RProps {
        var company: String?
        var loading: Boolean
        var summaries: DashboardStatusSummaryDTO?
    }

    class State : RState

    init {
        state = State()
    }

    override fun RBuilder.render() {
        if (props.loading && props.summaries == null)
            mSkeleton(
                height = 150.px,
                animation = MSkeletonAnimation.wave,
                variant = MSkeletonVariant.rect
            )
        else {
            mCard {
                css(GlobalStyles.card)
                mListSubheader(heading = "Fahrzeuge") {
                    css { textAlign = TextAlign.center }
                }
                mDivider { }
                mCardContent {
                    css(GlobalStyles.cardDashboardContent)
                    mGridContainer2(
                        direction = MGridDirection.row,
                        alignItems = MGridAlignItems.center,
                        justify = MGridJustify.center
                    ) {
                        props.summaries?.vehicleStatus
                            ?.filterValues { (it.linuxOnline + it.ivantoOnline + it.offline) > 0 }
                            ?.let {
                                it.forEach { (vehicleType, status) ->
                                    statusCountView(
                                        breakpoints = MGridBreakpoints2(MGridSize2.Cells4)
                                            .down(Breakpoint.xs, MGridSize2.Cells12),
                                        product = null,
                                        title = vehicleType.readableName,
                                        iconName = when (vehicleType) {
                                            VehicleType.BUS -> "directions_bus"
                                            VehicleType.TRAM -> "tram"
                                            VehicleType.SUBWAY -> "subway"
                                            VehicleType.TRAIN -> "train"
                                            VehicleType.TRAM_GER -> "tram"
                                            VehicleType.LIGHT_RAIL -> "train"
                                            else -> "help_outline"
                                        },
                                        css = {},
                                        children = buildElement {
                                            val linuxCount = status.linuxOnline
                                            val ivantoCount = status.linuxOnline
                                            val offlineCount = status.offline

                                            mGridContainer2(
                                                direction = MGridDirection.row,
                                                alignItems = MGridAlignItems.center,
                                                justify = MGridJustify.center
                                            ) {
                                                renderCountItem(
                                                    caption = "Betriebssystem",
                                                    countColor = when (linuxCount) {
                                                        0 -> null
                                                        else -> Color.green
                                                    },
                                                    count = linuxCount,
                                                )
                                                renderDivider()
                                                renderCountItem(
                                                    caption = "ivanto",
                                                    countColor = when {
                                                        ivantoCount == 0 -> null
                                                        linuxCount == ivantoCount -> Color.green
                                                        linuxCount > ivantoCount -> Color.orange
                                                        else -> null
                                                    },
                                                    count = ivantoCount,
                                                )
                                                renderDivider()
                                                renderCountItem(
                                                    caption = "Offline",
                                                    countColor = if (offlineCount >= 1) Color.red else null,
                                                    count = offlineCount,
                                                )
                                            }
                                        }
                                    )

                                }
                            }
                    }
                }
            }
        }
    }
}