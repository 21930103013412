package de.geomobile.frontend.utils

import kotlin.random.Random

private val characters = ('A'..'Z') + ('a'..'z') + ('0'..'9')

fun Random.nextString(length: Int): String {
    return (0 until length)
        .map { nextInt(characters.size) }
        .map { characters[it] }
        .joinToString("")
}