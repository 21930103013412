package de.geomobile.frontend.features.admin.roles

import de.geomobile.common.permission.Permissions
import de.geomobile.frontend.utils.authorize
import react.RBuilder
import react.RComponent
import react.RProps
import react.RState
import react.router.dom.route
import react.router.dom.switch

fun RBuilder.adminRoles(path: String) = child(AdminRoles::class) {
    attrs.path = path
}

class AdminRoles : RComponent<AdminRoles.Props, RState>() {

    interface Props : RProps {
        var path: String
    }

    override fun RBuilder.render() {

        switch {
            authorize(Permissions.RoleManagement.rolesEdit) {

                route<RProps>("${props.path}/create", exact = true) { routeProps ->
                    adminRoleCreate(
                        cancel = { routeProps.history.push(props.path) },
                        created = { routeProps.history.push(props.path) }
                    )
                }

            }

            route<RProps>(props.path, exact = true) { routeProps ->
                adminRoleList(
                    roleSelected = { routeProps.history.push("${props.path}/$it") },
                    createRole = { routeProps.history.push("${props.path}/create") }
                )
            }

            route<AdminRoleEdit.Props>("${props.path}/:roleId", exact = true) { routeProps ->
                val roleId = routeProps.match.params.roleId!!
                adminRoleEdit(roleId, goBack = { routeProps.history.push(props.path) })
            }
        }
    }
}