package de.geomobile.frontend.features.device.detail

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.list.mList
import com.ccfraser.muirwik.components.list.mListItem
import com.ccfraser.muirwik.components.list.mListItemSecondaryAction
import com.ccfraser.muirwik.components.list.mListItemText
import de.geomobile.common.portalmodels.CableCheck
import de.geomobile.common.time.LocalDateTime
import de.geomobile.frontend.currentTheme
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.portalWebSocketApi
import de.geomobile.frontend.utils.*
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.css.*
import kotlinx.serialization.json.Json
import react.RBuilder
import react.RProps
import react.RState
import react.setState
import styled.StyleSheet
import styled.css
import styled.styledDiv

fun RBuilder.deviceCableCheck(
    id: Int,
) = child(DeviceCableCheckComponent::class) {
    key = id.toString()
    attrs.id = id
}

object CustomStyles : StyleSheet("ComponentStyles") {
    val label by css {
        color = Color.white
        borderRadius = 3.px
        display = Display.inline
        padding(3.px, 6.px)
    }
}

class DeviceCableCheckComponent : CComponent<DeviceCableCheckComponent.Props, DeviceCableCheckComponent.State>() {

    private var relayJob: Job = Job()

    private val cableCheckSocket by kotlin.lazy {
        portalWebSocketApi.subscribe("/device/cablecheck", mapOf("id" to props.id.toString()))
    }

    interface Props : RProps {
        var id: Int
    }

    class State(
        var cableCheck: CableCheck = CableCheck(),
        var changedAudioVolume: Int? = null,
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        cableCheckSocket.connect {
            onmessage = {
                val cableCheck = Json.decodeFromString(CableCheck.serializer(), it)
                setState {
                    this.cableCheck = cableCheck
                }
            }
        }
    }

    override fun componentWillUnmount() {
        super.componentWillUnmount()
        cableCheckSocket.close()
    }

    override fun RBuilder.render() {
        val cableCheck = state.cableCheck
        val audioVolume = state.changedAudioVolume ?: 50

        styledDiv {
            css { padding(2.spacingUnits) }

            mList {
                attrs.dense = true
                mListItem {
                    attrs.button = false
                    attrs.divider = true

                    mListItemText {
                        mTypography(
                            text = "Systemzeit",
                            variant = MTypographyVariant.caption,
                        )
                        mTypography(
                            text = cableCheck.systemTime?.timestamp?.toText() ?: "-",
                            variant = MTypographyVariant.subtitle2
                        )
                    }
                    mListItemSecondaryAction {
                        if (cableCheck.systemTime?.timestamp != null)
                            timerWrapper(1000) {
                                val diff = (LocalDateTime.now() - cableCheck.systemTime!!.timestamp).diffString()
                                mTypography(
                                    text = "Empfangen vor $diff",
                                    variant = MTypographyVariant.caption
                                )
                            }
                    }
                }
                mListItem {
                    attrs.button = false
                    attrs.divider = true

                    mListItemText {
                        mTypography(
                            text = "Bootzeit",
                            variant = MTypographyVariant.caption,
                        )
                        mTypography(
                            text = cableCheck.bootTime?.value?.toText() ?: "-",
                            variant = MTypographyVariant.subtitle2
                        )
                    }
                    mListItemSecondaryAction {
                        if (cableCheck.bootTime?.timestamp != null)
                            timerWrapper(1000) {
                                val diff = (LocalDateTime.now() - cableCheck.bootTime!!.timestamp).diffString()
                                mTypography(
                                    text = "Empfangen vor $diff",
                                    variant = MTypographyVariant.caption
                                )
                            }
                    }
                }
                mListItem {
                    attrs.button = false
                    attrs.divider = true

                    mListItemText {
                        mTypography(
                            text = "IBIS",
                            variant = MTypographyVariant.caption,
                            component = "div",
                        )
                        val ibis = cableCheck.ibis
                        if (ibis != null)
                            mTypography(
                                text = when (ibis.value) {
                                    CableCheck.IbisState.ON -> "An"
                                    CableCheck.IbisState.OFF -> "Aus"
                                    CableCheck.IbisState.CHECKING -> "Prüfen"
                                    CableCheck.IbisState.ERROR -> "Fehler: ${ibis.raw}"
                                },
                                variant = MTypographyVariant.subtitle2
                            ) {
                                css {
                                    +CustomStyles.label
                                    backgroundColor = when (ibis.value) {
                                        CableCheck.IbisState.ON -> Color.darkGreen
                                        CableCheck.IbisState.CHECKING -> Color.yellow.darken(20)
                                        CableCheck.IbisState.OFF -> Color.grey
                                        CableCheck.IbisState.ERROR -> Color.darkRed
                                    }
                                }
                            }
                        else mTypography(
                            text = "-",
                            variant = MTypographyVariant.subtitle2
                        )
                    }
                    mListItemSecondaryAction {
                        if (cableCheck.ibis?.timestamp != null)
                            timerWrapper(1000) {
                                val diff = (LocalDateTime.now() - cableCheck.ibis!!.timestamp).diffString()
                                mTypography(
                                    text = "Empfangen vor $diff",
                                    variant = MTypographyVariant.caption
                                )
                            }
                    }
                }
                mListItem {
                    attrs.button = false
                    attrs.divider = true

                    mListItemText {
                        mTypography(
                            text = "Nachlauf",
                            variant = MTypographyVariant.caption,
                            component = "div",
                        )
                        val overshoot = cableCheck.overshoot
                        if (overshoot != null)
                            mTypography(
                                text = when (overshoot.value) {
                                    CableCheck.ToggleState.ON -> "An"
                                    CableCheck.ToggleState.OFF -> "Aus"
                                    CableCheck.ToggleState.ERROR -> "Fehler: ${overshoot.raw}"
                                },
                                variant = MTypographyVariant.subtitle2
                            ) {
                                css {
                                    +CustomStyles.label
                                    backgroundColor = when (overshoot.value) {
                                        CableCheck.ToggleState.ON -> Color.darkGreen
                                        CableCheck.ToggleState.OFF -> Color.grey
                                        CableCheck.ToggleState.ERROR -> Color.darkRed
                                    }
                                }
                            }
                        else mTypography(
                            text = "-",
                            variant = MTypographyVariant.subtitle2
                        )
                    }
                    mListItemSecondaryAction {
                        if (cableCheck.overshoot?.timestamp != null)
                            timerWrapper(1000) {
                                val diff = (LocalDateTime.now() - cableCheck.overshoot!!.timestamp).diffString()
                                mTypography(
                                    text = "Empfangen vor $diff",
                                    variant = MTypographyVariant.caption
                                )
                            }
                    }
                }

                for ((index, input) in cableCheck.inputs.withIndex()) {
                    mListItem {
                        attrs.button = false
                        attrs.divider = true

                        mListItemText {
                            mTypography(
                                text = "Eingang ${index + 1}",
                                variant = MTypographyVariant.caption,
                                component = "div",
                            )

                            if (input != null)
                                mTypography(
                                    text = when (input.value) {
                                        CableCheck.ToggleState.ON -> "An"
                                        CableCheck.ToggleState.OFF -> "Aus"
                                        CableCheck.ToggleState.ERROR -> "Fehler: ${input.raw}"
                                    },
                                    variant = MTypographyVariant.subtitle2
                                ) {
                                    css {
                                        +CustomStyles.label
                                        backgroundColor = when (input.value) {
                                            CableCheck.ToggleState.ON -> Color.darkGreen
                                            CableCheck.ToggleState.OFF -> Color.grey
                                            CableCheck.ToggleState.ERROR -> Color.darkRed
                                        }
                                    }
                                }
                            else mTypography(
                                text = "-",
                                variant = MTypographyVariant.subtitle2
                            )
                        }
                        mListItemSecondaryAction {
                            if (input?.timestamp != null)
                                timerWrapper(1000) {
                                    val diff = (LocalDateTime.now() - input!!.timestamp).diffString()
                                    mTypography(
                                        text = "Empfangen vor $diff",
                                        variant = MTypographyVariant.caption
                                    )
                                }
                        }
                    }
                }

                for ((index, relay) in cableCheck.relays.withIndex()) {
                    mListItem {
                        attrs.button = false
                        attrs.divider = true

                        mListItemText {
                            mTypography(
                                text = "Relay ${index + 1}",
                                variant = MTypographyVariant.caption,
                                component = "div",
                            )

                            if (relay != null)
                                mTypography(
                                    text = when (relay.value) {
                                        CableCheck.ToggleState.ON -> "An"
                                        CableCheck.ToggleState.OFF -> "Aus"
                                        CableCheck.ToggleState.ERROR -> "Fehler: ${relay.raw}"
                                    },
                                    variant = MTypographyVariant.subtitle2
                                ) {
                                    css {
                                        +CustomStyles.label
                                        backgroundColor = when (relay.value) {
                                            CableCheck.ToggleState.ON -> Color.darkGreen
                                            CableCheck.ToggleState.OFF -> Color.grey
                                            CableCheck.ToggleState.ERROR -> Color.darkRed
                                        }
                                    }
                                }
                            else mTypography(
                                text = "-",
                                variant = MTypographyVariant.subtitle2
                            )
                        }
                        mListItemSecondaryAction {
                            if (relay?.timestamp != null)
                                timerWrapper(1000) {
                                    val diff = (LocalDateTime.now() - relay!!.timestamp).diffString()
                                    mTypography(
                                        text = "Empfangen vor $diff",
                                        variant = MTypographyVariant.caption
                                    )
                                }
                            mTooltip("Auslösen") {
                                mIconButtonNoTranslate(
                                    iconName = "play_arrow",
                                    iconColor = MIconColor.inherit,
                                    onClick = { triggerRelay(index + 1) },
                                    addAsChild = true
                                ) {
                                    css {
                                        color = Color.white
                                        background = currentTheme.palette.secondary.main
                                        marginLeft = 2.spacingUnits
                                    }
                                }
                            }
                        }
                    }
                }

                mListItem {
                    attrs.button = false
                    attrs.divider = true

                    mListItemText {
                        mTypography(
                            text = "Lautstärke",
                            variant = MTypographyVariant.caption,
                            component = "div"
                        )
                        mTypography(
                            text = "$audioVolume",
                            variant = MTypographyVariant.subtitle2
                        )
                        mSlider(
                            value = audioVolume,
                            min = 0,
                            max = 100,
                            step = 1,
                            onChange = { value ->
                                setState {
                                    this.changedAudioVolume = value
                                }
                            }
                        )
                    }
                }
                mListItem {
                    attrs.button = false
                    attrs.divider = true

                    mListItemText {
                        mTypography(
                            text = "Audio",
                            variant = MTypographyVariant.caption,
                            component = "div"
                        )
                        val audio = cableCheck.audio
                        if (audio != null)
                            mTypography(
                                text = when (audio.value) {
                                    CableCheck.ToggleState.ON -> "An"
                                    CableCheck.ToggleState.OFF -> "Aus"
                                    CableCheck.ToggleState.ERROR -> "Fehler: ${audio.raw}"
                                },
                                variant = MTypographyVariant.subtitle2
                            ) {
                                css {
                                    +CustomStyles.label
                                    backgroundColor = when (audio.value) {
                                        CableCheck.ToggleState.ON -> Color.darkGreen
                                        CableCheck.ToggleState.OFF -> Color.grey
                                        CableCheck.ToggleState.ERROR -> Color.darkRed
                                    }
                                }
                            }
                        else mTypography(
                            text = "-",
                            variant = MTypographyVariant.subtitle2
                        )
                    }
                    mListItemSecondaryAction {
                        if (cableCheck.audio?.timestamp != null)
                            timerWrapper(1000) {
                                val diff = (LocalDateTime.now() - cableCheck.audio!!.timestamp).diffString()
                                mTypography(
                                    text = "Empfangen vor $diff",
                                    variant = MTypographyVariant.caption
                                )
                            }

                        mTooltip("Abspielen") {
                            mIconButtonNoTranslate(
                                iconName = "volume_up",
                                iconColor = MIconColor.inherit,
                                onClick = {
                                    launch(Dispatchers.Default) {
                                        portalRestApi.post("/device/${props.id}/cablecheck/playaudio/$audioVolume")
                                    }
                                },
                                addAsChild = true
                            ) {
                                css {
                                    color = Color.white
                                    background = currentTheme.palette.secondary.main
                                    marginLeft = 2.spacingUnits
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    private fun triggerRelay(relayNumber: Int) {
        relayJob.cancel()
        relayJob = launch(Dispatchers.Default) {
            portalRestApi.post("/device/${props.id}/cablecheck/relay$relayNumber")
        }
    }
}