package de.geomobile.frontend.features.device.detail

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonSize
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.dialog.DialogScroll
import com.ccfraser.muirwik.components.dialog.mDialog
import com.ccfraser.muirwik.components.dialog.mDialogActions
import com.ccfraser.muirwik.components.dialog.mDialogContent
import com.ccfraser.muirwik.components.form.MFormControlVariant
import com.ccfraser.muirwik.components.list.mListItem
import com.ccfraser.muirwik.components.list.mListItemSecondaryAction
import com.ccfraser.muirwik.components.list.mListItemText
import de.geomobile.common.portalmodels.DeviceDetailDTO
import de.geomobile.common.portalmodels.VehicleProfileDTO
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.utils.CComponent
import de.geomobile.frontend.utils.mIconNoTranslate
import kotlinx.browser.document
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.css.*
import kotlinx.html.InputType
import react.RBuilder
import react.RProps
import react.RState
import react.dom.div
import react.setState
import styled.css
import styled.styledDiv

fun RBuilder.tableRowVehicleIdEdit(
    label: String,
    allowEdit: Boolean,
    device: DeviceDetailDTO,
) {
    child(VehicleIdComponent::class) {
        attrs.label = label
        attrs.multiline = false
        attrs.allowEdit = allowEdit
        attrs.device = device
    }
}

class VehicleIdComponent : CComponent<VehicleIdComponent.Props, VehicleIdComponent.State>() {

    interface Props : RProps {
        var label: String
        var multiline: Boolean
        var allowEdit: Boolean
        var device: DeviceDetailDTO
    }

    class State(
        var dialogOpen: Boolean = false,
        var vehicleId: String? = null,
        var vehicleIdOverride: Boolean? = null,
        var vehicleProfile: VehicleProfileDTO? = null,
        var isSaving: Boolean = false
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        setState {
            vehicleId = props.device.vehicleId
            vehicleIdOverride = props.device.vehicleIdOverride
        }
        launch {
            fetchVehicleProfile()
        }.invokeOnCompletion {

        }
    }

    suspend fun fetchVehicleProfile() {
        val vp = portalRestApi.get("/device/${props.device.id}/vehicleProfile", VehicleProfileDTO.serializer())

        setState {
            if (vp.id.isNotEmpty())
                vehicleProfile = vp
            else
                vehicleProfile = null
        }
    }

    private var editValue: Job = Job()

    private fun dialog(open: Boolean) {
        setState { dialogOpen = open }
    }

    private fun applyVehicleId() {
        setState { isSaving = true }
        launch {
            state.vehicleId?.let {
                portalRestApi.put("/device/${props.device.id}/vehicleId", it)
            }
            state.vehicleIdOverride?.let {
                portalRestApi.put("/device/${props.device.id}/vehicleIdOverride", it)
            }
        }.invokeOnCompletion {
            launch {
                fetchVehicleProfile()
            }
            setState {
                isSaving = false
                dialog(false)
            }
        }
    }

    override fun RBuilder.render() {
        mListItem {
            attrs.button = false
            attrs.divider = true

            mListItemText {
                mTypography(
                    text = props.label,
                    variant = MTypographyVariant.caption,
                )

                styledDiv {
                    css { display = Display.flex }

                    mTypography(
                        text = props.device.vehicleId.takeIf { it.isNotBlank() } ?: "-",
                        variant = MTypographyVariant.subtitle2,
                        color = MTypographyColor.textPrimary
                    )
                    if (props.device.vehicleIdByIbis.isNotBlank()) {
                        styledDiv {
                            css { display = Display.flex; alignItems = Align.center; margin(0.spacingUnits, 1.spacingUnits) }

                            mTypography(
                                text = "(${props.device.vehicleIdByIbis})",
                                variant = MTypographyVariant.subtitle2,
                                color = MTypographyColor.textSecondary
                            )
                            mTooltip("Von IBIS mitgeteilte Fahrzeugnummer") {
                                css { marginLeft = 3.px }
                                mIconNoTranslate(
                                    "help_outline",
                                    false,
                                    MIconColor.disabled,
                                    MIconFontSize.small
                                )
                            }
                        }
                    }
                }
            }
            if (props.allowEdit)
                mListItemSecondaryAction {
                    mTooltip("Bearbeiten") {
                        mIconButtonNoTranslate(
                            iconName = "edit",
                            color = MColor.inherit,
                            size = MButtonSize.small,
                            onClick = { dialog(true) },
                            addAsChild = true
                        ) {
                            css { background = "#2196f3"; color = Color.white; }
                        }
                    }
                }
        }

        mDialog(
            open = state.dialogOpen,
            scroll = DialogScroll.paper,
            onClose = { _, _ -> dialog(false) }
        ) {
            attrs.fullWidth = true
            attrs.disableEscapeKeyDown = true

            mDialogContent {
                val tooltipSave =
                    if (!(state.vehicleProfile?.vehicleIdByIBIS != true ||
                                (state.vehicleProfile?.vehicleIdByIBIS == true && state.vehicleIdOverride == true)
                                )
                    )
                        "Fahrzeugnummer wird von IBIS gesetzt. Zum manuellen überschreiben die Checkbox anhaken."
                    else
                        ""
                mTooltip(tooltipSave) {
                    div {
                        mTextField(
                            variant = MFormControlVariant.outlined,
                            label = props.label,
                            value = state.vehicleId,
                            type = InputType.number,
                            disabled = !(
                                    (state.vehicleProfile?.vehicleIdByIBIS != true ||
                                            (state.vehicleProfile?.vehicleIdByIBIS == true && state.vehicleIdOverride == true))
                                    ),
                            onChange = {
                                val text = it.targetInputValue
                                setState {
                                    vehicleId = text
                                }
                            },
                            autoFocus = true,
                            fullWidth = true
                        )
                    }
                }

                val tooltipCheckbox =
                    when {
                        state.vehicleProfile == null ->
                            "Fahrzeuge ohne Fahrzeugprofil können immer überschrieben werden."

                        state.vehicleProfile?.vehicleIdAllowOverride != true ->
                            "Manuelles überschreiben wurde im Fahrzeugprofil deaktiviert."

                        else ->
                            ""
                    }
                mTooltip(tooltipCheckbox) {
                    styledDiv {
//                        css { marginTop = 2.spacingUnits }
                        mCheckboxWithLabel(
                            label = "Manuell Überschreiben",
                            checked = state.vehicleIdOverride == true,
                            disabled = state.vehicleProfile?.vehicleIdAllowOverride != true,
                            onChange = { _, checked ->
                                setState {
                                    vehicleIdOverride = checked
                                    if (!checked) {
                                        vehicleId = props.device.vehicleId
                                    }
                                }
                            }
                        )
                        if(state.isSaving) {
                            mCircularProgress {
                                css {
                                    position = Position.absolute
                                    left = 80.pct
                                    marginLeft = 4.spacingUnits
                                }
                            }
                        }
                    }
                }
            }
            mDialogActions {
                mButton(
                    "Abbrechen",
                    color = MColor.primary,
                    onClick = {
                        dialog(false)
                    }
                )
                mButton(
                    "Speichern",
                    color = MColor.primary,
                    onClick = {
                        applyVehicleId()
                    }
                )
            }
        }

        document.onkeydown = {
            if (state.dialogOpen && it.keyCode == 13) // User hits enter
                applyVehicleId()
        }
    }

}