package com.ccfraser.muirwik.components

import com.ccfraser.muirwik.components.button.*
import de.geomobile.frontend.utils.mIconNoTranslate
import org.w3c.dom.events.Event
import react.RBuilder
import react.RComponent
import react.RProps
import react.RState
import styled.StyledHandler


@JsModule("@material-ui/core/Button")
private external val buttonModule: dynamic
private val buttonComponent: RComponent<MButtonProps, RState> = buttonModule.default

@JsModule("@material-ui/core/Fab")
private external val fabModule: dynamic

@Suppress("UnsafeCastFromDynamic")
private val fabComponent: RComponent<MFabProps, RState> = fabModule.default

@JsModule("@material-ui/core/IconButton")
private external val iconButtonModule: dynamic

@Suppress("UnsafeCastFromDynamic")
private val iconButtonComponent: RComponent<MIconButtonProps, RState> = iconButtonModule.default

/**
 * If the icon name is given, we shall create a child mIcon with the given name and try and match the color.
 * If the icon name is not given, a child mIcon should be given. This also allows more options and styling
 * to be given to the icon.
 */
fun RBuilder.mIconButtonNoTranslate(
    iconName: String? = null,
    primary: Boolean = false, // If true, then this overrides the color... just an easier setter...
    onClick: ((Event) -> Unit)? = null,
    disabled: Boolean = false,
    color: MColor = MColor.default,

    size: MButtonSize = MButtonSize.medium,
    href: String? = null,
    targetBlank: Boolean = false,
    target: String? = null,

    disableRipple: Boolean = false,
    centerRipple: Boolean = false,
    focusRipple: Boolean = false,
    onKeyboardFocus: ((Event) -> Unit)? = null,
    touchRippleProps: RProps? = null,

    iconColor: MIconColor? = null,

    addAsChild: Boolean = true,
    className: String? = null,
    handler: StyledHandler<MIconButtonProps>? = null
) = createStyled(iconButtonComponent, addAsChild) {
    attrs.centerRipple = centerRipple
    attrs.color = if (primary) MColor.primary else color
    attrs.disabled = disabled
    attrs.disableRipple = disableRipple
    attrs.focusRipple = focusRipple
    setHRefTargetNoOpener(attrs, href, targetBlank, target)
    onClick?.let { attrs.onClick = onClick }
    onKeyboardFocus?.let { attrs.onKeyboardFocus = onKeyboardFocus }
    touchRippleProps?.let { attrs.touchRippleProps = touchRippleProps }

    var iconColorToUse = iconColor
    // If the iconColor is null, we shall map to the button color if we can
    if (iconColorToUse == null) {
        iconColorToUse = when (color) {
            MColor.inherit -> MIconColor.inherit
            MColor.default -> MIconColor.action
            MColor.secondary -> MIconColor.secondary
            MColor.primary -> MIconColor.primary
        }
    }

    if (iconName != null) {
        val fontSize = when (size) {
            MButtonSize.small -> MIconFontSize.small
            MButtonSize.medium -> MIconFontSize.inherit
            MButtonSize.large -> MIconFontSize.large
        }

        mIconNoTranslate(iconName, primary = primary, color = iconColorToUse, fontSize = fontSize)
    }

    setStyledPropsAndRunHandler(className, handler)
}
