package de.geomobile.frontend.features.device.detail

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonSize
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.dialog.*
import com.ccfraser.muirwik.components.list.mList
import com.ccfraser.muirwik.components.list.mListItem
import com.ccfraser.muirwik.components.list.mListItemSecondaryAction
import com.ccfraser.muirwik.components.list.mListItemText
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.utils.CComponent
import de.geomobile.frontend.utils.mSlider
import kotlinx.browser.document
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.css.*
import kotlinx.html.InputType
import react.RBuilder
import react.RProps
import react.RState
import react.setState
import styled.css
import kotlin.math.max
import kotlin.math.min

fun RBuilder.rowChooseVolume(
    label: String,
    allowEdit: Boolean,
    value: Int?,
    applyPath: String,
    applyBody: (Int) -> String = { it.toString() },
    testPath: String? = null,
) {
    child(ChooseVolumeComponent::class) {
        attrs.label = label
        attrs.value = value
        attrs.allowEdit = allowEdit
        attrs.applyPath = applyPath
        attrs.applyBody = applyBody
        attrs.testPath = testPath
    }
}

class ChooseVolumeComponent : CComponent<ChooseVolumeComponent.Props, ChooseVolumeComponent.State>() {

    private var editValue: Job = Job()

    interface Props : RProps {
        var label: String
        var allowEdit: Boolean
        var value: Int?
        var applyPath: String
        var applyBody: (Int) -> String
        var testPath: String?
    }

    class State(
        var editState: EditValueState = EditValueState.Idle,
    ) : RState

    sealed class EditValueState {
        object Idle : EditValueState()
        data class Editing(val newValue: Int, val applying: Boolean = false) : EditValueState()
    }

    init {
        state = State()
    }

    override fun RBuilder.render() {
        mList {
            attrs.disablePadding = true

            mListItem {
                attrs.divider = false
                attrs.button = false

                mListItemText {
                    mTypography(
                        text = "Lautstärke",
                        variant = MTypographyVariant.caption,
                    )
                    mTypography(
                        text = props.value?.toString() ?: "-",
                        variant = MTypographyVariant.subtitle2,
                        color = MTypographyColor.textPrimary
                    )
                }
                if (props.allowEdit)
                    mListItemSecondaryAction {
                        mTooltip("Bearbeiten") {
                            mIconButtonNoTranslate(
                                iconName = "edit",
                                size = MButtonSize.small,
                                color = MColor.inherit,
                                onClick = { show() },
                                addAsChild = true
                            ) {
                                css { background = "#2196f3"; color = Color.white; }
                            }
                        }
                    }
            }
        }

        val open = state.editState is EditValueState.Editing
        val currentState = state.editState

        mDialog(
            open,
            scroll = DialogScroll.paper,
            onClose = { _, _ -> hide() }
        ) {
            attrs.disableEscapeKeyDown = true

            mDialogTitle(props.label)

            mDialogContent {
                css {
//                    minWidth = 400.px
                    overflow = Overflow.hidden
                    display = Display.flex
                    alignItems = Align.baseline
                }

                if (currentState is EditValueState.Editing) {
                    if (currentState.applying) {
                        mCircularProgress {
                            css {
                                display = Display.block
                                margin(
                                    vertical = 2.spacingUnits, horizontal = LinearDimension.auto
                                )
                            }
                        }
                    } else {
                        mSlider(
                            value = currentState.newValue,
                            min = 0,
                            max = 100,
                            step = 1,
                            onChange = { value -> changeValue(min(100, max(0, value))) }
                        ) {
                            css {
                                paddingRight = 2.spacingUnits
                            }
                        }
                        mTextField(
                            id = "asd",
                            label = "",
                            type = InputType.number,
                            value = currentState.newValue.toString(),
                            onChange = { changeValue(min(100, max(0, it.targetInputValue.toInt()))) },
                            autoFocus = true
                        ) {
                            css { width = 50.px }
                        }
                    }
                }
            }
            mDialogActions {
                mButton(
                    "Abbrechen",
                    color = MColor.primary,
                    onClick = {
                        hide()
                    }
                )
                if (props.testPath != null) {
                    mButton(
                        caption = "Test",
                        color = MColor.secondary,
                        onClick = {
                            if (currentState is EditValueState.Editing)
                                test()
                        }
                    ) {
                        css { marginLeft = LinearDimension.auto }
                    }
                }
                mButton(
                    "Speichern",
                    disabled = currentState !is EditValueState.Editing,
                    color = MColor.primary,
                    onClick = {
                        if (currentState is EditValueState.Editing)
                            apply()
                    }
                )
            }
        }

        document.onkeydown = {
            if (open && it.keyCode == 13) // User hits enter
                apply()
        }
    }

    private fun show() {
        setState {
            val value = props.value ?: 50
            editState = EditValueState.Editing(value)
        }
    }

    private fun hide() {
        setState {
            editState = EditValueState.Idle
        }
    }

    private fun changeValue(newValue: Int) {
        (state.editState as? EditValueState.Editing)?.let {
            setState {
                editState = it.copy(newValue = newValue)
            }
        }
    }

    private fun apply() {
        (state.editState as? EditValueState.Editing)?.let {
            setState { editState = it.copy(applying = true) }
            editValue.cancel()
            editValue = launch {
                launch(Dispatchers.Default) {

                    val path = props.applyPath
                    val body = props.applyBody(it.newValue)
                    portalRestApi.put(path, body)

                }.join()

                setState {
                    editState = EditValueState.Idle
                    props.value = it.newValue
                }
            }
        }
    }

    private fun test() {
        (state.editState as? EditValueState.Editing)?.let {
            val audioVolume = it.newValue
            launch(Dispatchers.Default) {
                portalRestApi.get("${props.testPath}/$audioVolume")
            }
        }
    }
}