package de.geomobile.frontend.utils

import de.geomobile.common.permission.Permission
import de.geomobile.frontend.UserStore
import react.RBuilder

fun isAuthorized(vararg permissions: Permission) =
    UserStore.permissions.containsAll(permissions.asList()) and permissions.all { hasFeature(*it.requiredFeatures.toTypedArray()) }

fun RBuilder.authorize(
    vararg permissions: Permission,
    handler: RBuilder.() -> Unit,
) {
    if (permissions.isEmpty())
        handler()
    else if (isAuthorized(*permissions))
        handler()
}

fun RBuilder.authorize(
    check: PermissionCheck,
    handler: RBuilder.() -> Unit,
) {
    if (check.check(UserStore.permissions))
        handler()
}

interface PermissionCheck {
    fun check(permissions: Set<Permission>): Boolean
}

infix fun Permission.or(second: Permission) =
    PermissionOrCheck(PermissionSingleCheck(this), PermissionSingleCheck(second))

infix fun Permission.and(second: Permission) =
    PermissionOrCheck(PermissionSingleCheck(this), PermissionSingleCheck(second))

infix fun PermissionCheck.or(second: Permission) =
    PermissionOrCheck(this, PermissionSingleCheck(second))

infix fun PermissionCheck.and(second: Permission) = PermissionOrCheck(this, PermissionSingleCheck(second))

infix fun Permission.or(second: PermissionCheck) =
    PermissionOrCheck(PermissionSingleCheck(this), second)

infix fun Permission.and(second: PermissionCheck) = PermissionOrCheck(PermissionSingleCheck(this), second)

infix fun PermissionCheck.or(second: PermissionCheck) =
    PermissionOrCheck(this, second)

infix fun PermissionCheck.and(second: PermissionCheck) = PermissionOrCheck(this, second)

class PermissionOrCheck(
    val first: PermissionCheck,
    val second: PermissionCheck,
) : PermissionCheck {
    override fun check(permissions: Set<Permission>) = first.check(permissions) || second.check(permissions)
}

class PermissionAndCheck(
    val first: PermissionCheck,
    val second: PermissionCheck,
) : PermissionCheck {
    override fun check(permissions: Set<Permission>) = first.check(permissions) && second.check(permissions)
}

class PermissionSingleCheck(
    val permission: Permission,
) : PermissionCheck {
    override fun check(permissions: Set<Permission>) =
        (permission in permissions) and (hasFeature(*permission.requiredFeatures.toTypedArray()))
}