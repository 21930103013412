package de.geomobile.frontend.utils.grid

import com.ccfraser.muirwik.components.MHiddenImplementation
import com.ccfraser.muirwik.components.createStyled
import com.ccfraser.muirwik.components.setStyledPropsAndRunHandler
import kotlinx.css.CssValue
import kotlinx.css.LinearDimension
import kotlinx.css.px
import kotlinx.css.zIndex
import react.*
import styled.StyledElementBuilder
import styled.StyledHandler
import styled.StyledProps
import styled.css

@JsModule("@devexpress/dx-react-grid-material-ui")
private external val dx_react_grid_material_ui: dynamic

@Suppress("UnsafeCastFromDynamic")
val rowComponent: RComponent<RProps, RState> = dx_react_grid_material_ui.Table.Row

@Suppress("UnsafeCastFromDynamic")
val CellComponent: RComponent<RProps, RState> = dx_react_grid_material_ui.Table.Cell

fun RBuilder.tableRow(props: RProps) = child(rowComponent, props, emptyList())

fun RBuilder.tableCell(props: RProps) = child(CellComponent, props, emptyList())

interface RowProps : RProps {
    var tableRow: TableRow
}
interface CellProps : RProps {

}

// TODO: Clean up
//external interface `T$0` {
//    var className: String? get() = definedExternally; set(value) = definedExternally
//    var style: React.CSSProperties? get() = definedExternally; set(value) = definedExternally
//    @nativeGetter
//    operator fun get(x: String): Any?
//    @nativeSetter
//    operator fun set(x: String, value: Any)
//}
//external interface `T$1` {
//    var ToggleButton: React.ComponentType<ColumnChooser_2.ToggleButtonProps /* ColumnChooser_2.ToggleButtonProps & `T$0` */>
//    var Overlay: React.ComponentType<ColumnChooser_2.OverlayProps /* ColumnChooser_2.OverlayProps & `T$0` */>
//    var Container: React.ComponentType<ColumnChooser_2.ContainerProps /* ColumnChooser_2.ContainerProps & `T$0` */>
//    var Item: React.ComponentType<ColumnChooser_2.ItemProps /* ColumnChooser_2.ItemProps & `T$0` */>
//}
//external var ColumnChooser: React.ComponentType<ColumnChooserProps> /* React.ComponentType<ColumnChooserProps> & `T$1` */ = definedExternally


@Suppress("UnsafeCastFromDynamic")
private val columnChooserComponent: RComponent<ColumnChooserProps, RState> = dx_react_grid_material_ui.ColumnChooser

fun RBuilder.columnChooser() = createStyled(columnChooserComponent) {
    attrs.messages = ColumnChooserProps.LocalizationMessages(showColumnChooser = "Spaltenauswahl")
}

interface ColumnChooserProps : StyledProps {
    //    var containerComponent: React.ComponentType<ColumnChooser_2.ContainerProps>? get() = definedExternally; set(value) = definedExternally
//    var itemComponent: React.ComponentType<ColumnChooser_2.ItemProps>? get() = definedExternally; set(value) = definedExternally
    var messages: LocalizationMessages?

    //    var overlayComponent: React.ComponentType<ColumnChooser_2.OverlayProps>? get() = definedExternally; set(value) = definedExternally
//    var toggleButtonComponent: React.ComponentType<ColumnChooser_2.ToggleButtonProps>? get() = definedExternally; set(value) = definedExternally
    data class LocalizationMessages(val showColumnChooser: String)
}
//external interface `T$2` {
//    var Container: React.ComponentType<DragDropProvider_2.ContainerProps /* DragDropProvider_2.ContainerProps & `T$0` */>
//    var Column: React.ComponentType<DragDropProvider_2.ColumnProps /* DragDropProvider_2.ColumnProps & `T$0` */>
//}
//external var DragDropProvider: React.ComponentType<DragDropProviderProps> /* React.ComponentType<DragDropProviderProps> & `T$2` */ = definedExternally

@Suppress("UnsafeCastFromDynamic")
private val dragDropProviderComponent: RComponent<DragDropProviderProps, RState> =
    dx_react_grid_material_ui.DragDropProvider

fun RBuilder.dragDropProvider() = createStyled(dragDropProviderComponent) {}

interface DragDropProviderProps : StyledProps {
//    var columnComponent: React.ComponentType<DragDropProvider_2.ColumnProps>? get() = definedExternally; set(value) = definedExternally
//    var containerComponent: React.ComponentType<DragDropProvider_2.ContainerProps>? get() = definedExternally; set(value) = definedExternally
}
//external interface `T$3` {
//    var Root: React.ComponentType<Grid_2.RootProps /* Grid_2.RootProps & `T$0` */>
//}
//external var Grid: React.ComponentType<GridProps> /* React.ComponentType<GridProps> & `T$3` */ = definedExternally

interface GridProps : StyledProps {
    var columns: Array<Column>

    //    var getCellValue: ((row: Any, columnName: String) -> Any)?
    var getRowId: ((row: Any) -> Any)?

    //    var rootComponent: dynamic
    var rows: Array<Any>
}

@Suppress("UnsafeCastFromDynamic")
private val gridComponent: RComponent<GridProps, RState> = dx_react_grid_material_ui.Grid

fun RBuilder.grid(
    columns: Collection<Column>,
    rows: Collection<Any>,
    getRowId: ((row: Any) -> Any)? = null,
    className: String? = null,
    handler: StyledHandler<GridProps>? = null,
) = createStyled(gridComponent) {
    attrs.columns = columns.toTypedArray()
    attrs.rows = rows.toTypedArray()
    attrs.getRowId = getRowId
    setStyledPropsAndRunHandler(className, handler)
}

//external interface `T$4` {
//    var Container: React.ComponentType<GroupingPanel_2.ContainerProps /* GroupingPanel_2.ContainerProps & `T$0` */>
//    var Item: React.ComponentType<GroupingPanel_2.ItemProps /* GroupingPanel_2.ItemProps & `T$0` */>
//    var EmptyMessage: React.ComponentType<GroupingPanel_2.EmptyMessageProps /* GroupingPanel_2.EmptyMessageProps & `T$0` */>
//}
//external var GroupingPanel: React.ComponentType<GroupingPanelProps> /* React.ComponentType<GroupingPanelProps> & `T$4` */ = definedExternally

@Suppress("UnsafeCastFromDynamic")
private val groupingPanelComponent: RComponent<GroupingPanelProps, RState> = dx_react_grid_material_ui.GroupingPanel

fun RBuilder.groupingPanel(
    showGroupingControls: Boolean,
    showSortingControls: Boolean,
) = createStyled(groupingPanelComponent) {
    css {
        setCustomProperty("order", LinearDimension("-1") )
    }
    attrs.showGroupingControls = showGroupingControls
    attrs.showSortingControls = showSortingControls
    attrs.messages = GroupingPanelProps.LocalizationMessages(
        groupByColumn = "Ziehen Sie eine Spalte hierhin, um danach zu gruppieren"
    )
}

interface GroupingPanelProps : StyledProps {
    //    var containerComponent: React.ComponentType<GroupingPanel_2.ContainerProps>? get() = definedExternally; set(value) = definedExternally
//    var emptyMessageComponent: React.ComponentType<GroupingPanel_2.EmptyMessageProps>? get() = definedExternally; set(value) = definedExternally
//    var itemComponent: React.ComponentType<GroupingPanel_2.ItemProps>? get() = definedExternally; set(value) = definedExternally
    var messages: LocalizationMessages?
    var showGroupingControls: Boolean
    var showSortingControls: Boolean

    data class LocalizationMessages(
        val groupByColumn: String,
    )
}
//external interface `T$5` {
//    var Container: React.ComponentType<PagingPanel_2.ContainerProps /* PagingPanel_2.ContainerProps & `T$0` */>
//}
//external var PagingPanel: React.ComponentType<PagingPanelProps> /* React.ComponentType<PagingPanelProps> & `T$5` */ = definedExternally

@Suppress("UnsafeCastFromDynamic")
private val pagingPanelComponent: RComponent<PagingPanelProps, RState> = dx_react_grid_material_ui.PagingPanel

//TODO: try to implement this
//@Suppress("UnsafeCastFromDynamic")
//private val pagingPanelComponent: RComponent<PagingPanelProps, RState> = rFunction<PagingPanelProps, RState>("PagingPanel") { cellProps ->
//    val newProps = kotlinext.js.clone(cellProps)
//
//    newProps.asDynamic().style = kotlinext.js.js {
//        paddingTop = "2px"
//        paddingBottom = "2px"
//    }
//
//    tableCell(newProps)
//}

//private fun pagingPanelComponent(): RComponent<PagingPanelProps, RState> {
//    val panel = dx_react_grid_material_ui.PagingPanel
//
//    panel.style = kotlinext.js.js {
//
//        order = "-1"
//    }
//
//    return panel // as RComponent<PagingPanelProps, RState>
//}


fun RBuilder.pagingPanel(
    pageSizes: List<Int>,
    className: String? = null,
    handler: StyledHandler<PagingPanelProps>? = null,
) = createStyled(pagingPanelComponent) {
    val props = PagingPanelProps.LocalizationMessages(
        showAll = "Alle",
        rowsPerPage = "Zeilen pro Seite",
        info = "Zeilen {from} bis {to} ({count} Elemente)"
    )

    attrs.pageSizes = pageSizes.toTypedArray()
    attrs.messages = props

    setStyledPropsAndRunHandler(className, handler)
}

interface PagingPanelProps : StyledProps {
    //    var containerComponent: React.ComponentType<PagingPanel_2.ContainerProps>? get() = definedExternally; set(value) = definedExternally
    var messages: LocalizationMessages?
    var pageSizes: Array<Int>

    data class LocalizationMessages(val showAll: String, val rowsPerPage: String, val info: String)
}
//external interface `T$6` {
//    var Input: React.ComponentType<SearchPanel_2.InputProps /* SearchPanel_2.InputProps & `T$0` */>
//}
//external var SearchPanel: React.ComponentType<SearchPanelProps> /* React.ComponentType<SearchPanelProps> & `T$6` */ = definedExternally

@Suppress("UnsafeCastFromDynamic")
private val searchPanelComponent: RComponent<SearchPanelProps, RState> = dx_react_grid_material_ui.SearchPanel

fun RBuilder.searchPanel() = createStyled(searchPanelComponent) {
    attrs.messages = SearchPanelProps.LocalizationMessages(
        searchPlaceholder = "Suche..."
    )
}

interface SearchPanelProps : StyledProps {
    //    var inputComponent: React.ComponentType<SearchPanel_2.InputProps>? get() = definedExternally; set(value) = definedExternally
    var messages: LocalizationMessages?

    data class LocalizationMessages(val searchPlaceholder: String)
}
//external interface `T$7` {
//    var COLUMN_TYPE: Any?
//    var ROW_TYPE: Any?
//    var NODATA_ROW_TYPE: Any?
//}
//external interface `T$8` {
//    var Table: React.ComponentType<Any? /* Any? & `T$0` */>
//    var TableHead: React.ComponentType<Any? /* Any? & `T$0` */>
//    var TableBody: React.ComponentType<Any? /* Any? & `T$0` */>
//    var Container: React.ComponentType<Any? /* Any? & `T$0` */>
//    var Cell: React.ComponentType<Table_2.DataCellProps /* Table_2.DataCellProps & `T$0` */>
//    var Row: React.ComponentType<Table_2.DataRowProps /* Table_2.DataRowProps & `T$0` */>
//    var NoDataCell: React.ComponentType<Table_2.NoDataCellProps /* Table_2.NoDataCellProps & `T$0` */>
//    var NoDataRow: React.ComponentType<Table_2.RowProps /* Table_2.RowProps & `T$0` */>
//    var StubRow: React.ComponentType<Table_2.RowProps /* Table_2.RowProps & `T$0` */>
//    var StubCell: React.ComponentType<Table_2.CellProps /* Table_2.CellProps & `T$0` */>
//    var StubHeaderCell: React.ComponentType<Table_2.CellProps /* Table_2.CellProps & `T$0` */>
//}
//external var Table: React.ComponentType<TableProps> /* React.ComponentType<TableProps> & `T$7` & `T$8` */ = definedExternally
//external interface `T$9` {
//    var ROW_TYPE: Any?
//}
//external interface `T$10` {
//    var Cell: React.ComponentType<React.ComponentType<Table_2.CellProps> /* React.ComponentType<Table_2.CellProps> & `T$0` */>
//    var Row: React.ComponentType<React.ComponentType<Table_2.RowProps> /* React.ComponentType<Table_2.RowProps> & `T$0` */>
//}
//external var TableBandHeader: React.ComponentType<TableBandHeaderProps> /* React.ComponentType<TableBandHeaderProps> & `T$9` & `T$10` */ = definedExternally
//external interface TableBandHeaderProps {
//    var cellComponent: React.ComponentType<TableBandHeader_2.CellProps>? get() = definedExternally; set(value) = definedExternally
//    var columnBands: Array<TableBandHeader_2.ColumnBands>? get() = definedExternally; set(value) = definedExternally
//    var rowComponent: React.ComponentType<Table_2.RowProps>? get() = definedExternally; set(value) = definedExternally
//}
//external var TableColumnReordering: React.ComponentType<TableColumnReorderingProps> = definedExternally

@Suppress("UnsafeCastFromDynamic")
private val tableColumnReorderingComponent: RComponent<TableColumnReorderingProps, RState> =
    dx_react_grid_material_ui.TableColumnReordering

fun RBuilder.tableColumnReordering(
    defaultOrder: List<String>? = emptyList(),
    order: List<String>? = null,
    onOrderChange: ((nextOrder: Array<String>) -> Unit)? = null,
) = createStyled(tableColumnReorderingComponent) {
    attrs.defaultOrder = defaultOrder?.toTypedArray()
    attrs.order = order?.toTypedArray()
    attrs.onOrderChange = onOrderChange
}

interface TableColumnReorderingProps : StyledProps {
    var defaultOrder: Array<String>?
    var onOrderChange: ((nextOrder: Array<String>) -> Unit)?
    var order: Array<String>?
}
//external var TableColumnResizing: React.ComponentType<TableColumnResizingProps> = definedExternally

@Suppress("UnsafeCastFromDynamic")
private val tableColumnResizingComponent: RComponent<TableColumnResizingProps, RState> =
    dx_react_grid_material_ui.TableColumnResizing

fun RBuilder.tableColumnResizing(
    defaultColumnWidths: List<TableColumnWidthInfo>? = null,
) = createStyled(tableColumnResizingComponent) {
    attrs.defaultColumnWidths = defaultColumnWidths?.toTypedArray()
}

interface TableColumnResizingProps : StyledProps {
    //    var columnWidths: Array<TableColumnWidthInfo>? get() = definedExternally; set(value) = definedExternally
    var defaultColumnWidths: Array<TableColumnWidthInfo>?
//    var minColumnWidth: Number? get() = definedExternally; set(value) = definedExternally
//    var onColumnWidthsChange: ((nextColumnWidths: Array<TableColumnWidthInfo>) -> Unit)? get() = definedExternally; set(value) = definedExternally
}
//external interface `T$11` {
//    var EmptyMessage: React.ComponentType<TableColumnVisibility_2.EmptyMessageProps /* TableColumnVisibility_2.EmptyMessageProps & `T$0` */>
//}
//external var TableColumnVisibility: React.ComponentType<TableColumnVisibilityProps> /* React.ComponentType<TableColumnVisibilityProps> & `T$11` */ = definedExternally

@Suppress("UnsafeCastFromDynamic")
private val tableColumnVisibilityComponent: RComponent<TableColumnVisibilityProps, RState> =
    dx_react_grid_material_ui.TableColumnVisibility

fun RBuilder.tableColumnVisibility(
    hiddenColumnNames: List<String>? = null,
    onHiddenColumnNamesChange: ((hiddenColumnNames: Array<String>) -> Unit)? = null,
) = createStyled(tableColumnVisibilityComponent) {
    attrs.hiddenColumnNames = hiddenColumnNames?.toTypedArray()
    attrs.onHiddenColumnNamesChange = onHiddenColumnNamesChange
}

interface TableColumnVisibilityProps : StyledProps {
    //    var columnExtensions: Array<TableColumnVisibility_2.ColumnExtension>? get() = definedExternally; set(value) = definedExternally
//    var columnTogglingEnabled: Boolean? get() = definedExternally; set(value) = definedExternally
//    var defaultHiddenColumnNames: Array<String>? get() = definedExternally; set(value) = definedExternally
//    var emptyMessageComponent: React.ComponentType<TableColumnVisibility_2.EmptyMessageProps>? get() = definedExternally; set(value) = definedExternally
    var hiddenColumnNames: Array<String>?

    //    var messages: TableColumnVisibility_2.LocalizationMessages? get() = definedExternally; set(value) = definedExternally
    var onHiddenColumnNamesChange: ((hiddenColumnNames: Array<String>) -> Unit)?
}
//external interface `T$12` {
//    var COLUMN_TYPE: Any?
//}
//external interface `T$13` {
//    var Command: React.ComponentType<TableEditColumn_2.CommandProps /* TableEditColumn_2.CommandProps & `T$0` */>
//    var Cell: React.ComponentType<TableEditColumn_2.CellProps /* TableEditColumn_2.CellProps & `T$0` */>
//    var HeaderCell: React.ComponentType<TableEditColumn_2.HeaderCellProps /* TableEditColumn_2.HeaderCellProps & `T$0` */>
//}
//external var TableEditColumn: React.ComponentType<TableEditColumnProps> /* React.ComponentType<TableEditColumnProps> & `T$12` & `T$13` */ = definedExternally
//external interface TableEditColumnProps {
//    var cellComponent: React.ComponentType<TableEditColumn_2.CellProps>? get() = definedExternally; set(value) = definedExternally
//    var commandComponent: React.ComponentType<TableEditColumn_2.CommandProps>? get() = definedExternally; set(value) = definedExternally
//    var headerCellComponent: React.ComponentType<TableEditColumn_2.HeaderCellProps>? get() = definedExternally; set(value) = definedExternally
//    var messages: TableEditColumn_2.LocalizationMessages? get() = definedExternally; set(value) = definedExternally
//    var showAddCommand: Boolean? get() = definedExternally; set(value) = definedExternally
//    var showDeleteCommand: Boolean? get() = definedExternally; set(value) = definedExternally
//    var showEditCommand: Boolean? get() = definedExternally; set(value) = definedExternally
//    var width: dynamic /* String | Number */ get() = definedExternally; set(value) = definedExternally
//}
//external interface `T$14` {
//    var ADDED_ROW_TYPE: Any?
//    var EDIT_ROW_TYPE: Any?
//}
//external interface `T$15` {
//    var Cell: React.ComponentType<TableEditRow_2.CellProps /* TableEditRow_2.CellProps & `T$0` */>
//    var Row: React.ComponentType<TableEditRow_2.RowProps /* TableEditRow_2.RowProps & `T$0` */>
//}
//external var TableEditRow: React.ComponentType<TableEditRowProps> /* React.ComponentType<TableEditRowProps> & `T$14` & `T$15` */ = definedExternally
//external interface TableEditRowProps {
//    var cellComponent: React.ComponentType<TableEditRow_2.CellProps>? get() = definedExternally; set(value) = definedExternally
//    var rowComponent: React.ComponentType<TableEditRow_2.RowProps>? get() = definedExternally; set(value) = definedExternally
//    var rowHeight: Number? get() = definedExternally; set(value) = definedExternally
//}
//external interface `T$16` {
//    var Cell: React.ComponentType<TableFilterRow_2.CellProps /* TableFilterRow_2.CellProps & `T$0` */>
//    var Row: React.ComponentType<Table_2.RowProps /* Table_2.RowProps & `T$0` */>
//    var FilterSelector: React.ComponentType<TableFilterRow_2.FilterSelectorProps /* TableFilterRow_2.FilterSelectorProps & `T$0` */>
//    var Icon: React.ComponentType<TableFilterRow_2.IconProps /* TableFilterRow_2.IconProps & `T$0` */>
//    var Editor: React.ComponentType<TableFilterRow_2.EditorProps /* TableFilterRow_2.EditorProps & `T$0` */>
//    var ToggleButton: React.ComponentType<TableFilterRow_2.ToggleButtonProps /* TableFilterRow_2.ToggleButtonProps & `T$0` */>
//}
//external var TableFilterRow: React.ComponentType<TableFilterRowProps> /* React.ComponentType<TableFilterRowProps> & `T$9` & `T$16` */ = definedExternally

@Suppress("UnsafeCastFromDynamic")
private val tableFilterRowComponent: RComponent<TableFilterRowProps, RState> = dx_react_grid_material_ui.TableFilterRow

fun RBuilder.tableFilterRow(
    cellComponent: RClass<TableFilterRowProps.CellProps>? = null,
    rowComponent: RClass<RProps>? = null,
) = createStyled(tableFilterRowComponent) {
    if (cellComponent != null) attrs.cellComponent = cellComponent
    if (rowComponent != null) attrs.rowComponent = rowComponent
}

@Suppress("UnsafeCastFromDynamic")
private val tableFilterRowCellComponent: RComponent<TableFilterRowProps.CellProps, RState> =
    dx_react_grid_material_ui.TableFilterRow.Cell

fun RBuilder.tableFilterRowCell(props: TableFilterRowProps.CellProps) =
    child(StyledElementBuilder(tableFilterRowCellComponent, props).create())

interface TableFilterRowProps : StyledProps {
    var cellComponent: RClass<CellProps>?

    //    var editorComponent: React.ComponentType<TableFilterRow_2.EditorProps>? get() = definedExternally; set(value) = definedExternally
//    var filterSelectorComponent: React.ComponentType<TableFilterRow_2.FilterSelectorProps>? get() = definedExternally; set(value) = definedExternally
//    var iconComponent: React.ComponentType<TableFilterRow_2.IconProps>? get() = definedExternally; set(value) = definedExternally
//    var messages: TableFilterRow_2.LocalizationMessages? get() = definedExternally; set(value) = definedExternally
    var rowComponent: RClass<RProps>?

    //    var rowHeight: Number? get() = definedExternally; set(value) = definedExternally
//    var showFilterSelector: Boolean? get() = definedExternally; set(value) = definedExternally
//    var toggleButtonComponent: React.ComponentType<TableFilterRow_2.ToggleButtonProps>? get() = definedExternally; set(value) = definedExternally
    interface CellProps : StyledProps {
        var column: Column
        var filter: Filter?
        var onFilter: ((filter: Filter?) -> Unit)
    }
}
//external interface `T$17` {
//    var Cell: React.ComponentType<TableFixedColumns_2.CellProps /* TableFixedColumns_2.CellProps & `T$0` */>
//}
//external var TableFixedColumns: React.ComponentType<TableFixedColumnsProps> /* React.ComponentType<TableFixedColumnsProps> & `T$17` */ = definedExternally

@Suppress("UnsafeCastFromDynamic")
private val tableFixedColumnsComponent: RComponent<TableFixedColumnsProps, RState> =
    dx_react_grid_material_ui.TableFixedColumns

fun RBuilder.tableFixedColumns(
    leftColumns: List<String>? = null,
    rightColumns: List<String>? = null,
) = createStyled(tableFixedColumnsComponent) {
    attrs.leftColumns = leftColumns?.toTypedArray()
    attrs.rightColumns = rightColumns?.toTypedArray()
}

interface TableFixedColumnsProps : StyledProps {
    //    var cellComponent: React.ComponentType<TableFixedColumns_2.CellProps>? get() = definedExternally; set(value) = definedExternally
    var leftColumns: Array<String>?
    var rightColumns: Array<String>?
}
//external interface `T$18` {
//    var COLUMN_TYPE: Any?
//    var ROW_TYPE: Any?
//}
//external interface `T$19` {
//    var Row: React.ComponentType<TableGroupRow_2.RowProps /* TableGroupRow_2.RowProps & `T$0` */>
//    var Cell: React.ComponentType<TableGroupRow_2.CellProps /* TableGroupRow_2.CellProps & `T$0` */>
//    var Content: React.ComponentType<TableGroupRow_2.ContentProps /* TableGroupRow_2.ContentProps & `T$0` */>
//    var Icon: React.ComponentType<TableGroupRow_2.IconProps /* TableGroupRow_2.IconProps & `T$0` */>
//}
//external var TableGroupRow: React.ComponentType<TableGroupRowProps> /* React.ComponentType<TableGroupRowProps> & `T$18` & `T$19` */ = definedExternally


@Suppress("UnsafeCastFromDynamic")
private val tableGroupRowComponent: RComponent<TableGroupRowProps, RState> = dx_react_grid_material_ui.TableGroupRow

fun RBuilder.tableGroupRow() = createStyled(tableGroupRowComponent) {}

interface TableGroupRowProps : StyledProps {
//    var cellComponent: React.ComponentType<TableGroupRow_2.CellProps>? get() = definedExternally; set(value) = definedExternally
//    var columnExtensions: Array<TableGroupRow_2.ColumnExtension>? get() = definedExternally; set(value) = definedExternally
//    var contentComponent: React.ComponentType<TableGroupRow_2.ContentProps>? get() = definedExternally; set(value) = definedExternally
//    var iconComponent: React.ComponentType<TableGroupRow_2.IconProps>? get() = definedExternally; set(value) = definedExternally
//    var indentCellComponent: React.ComponentType<TableGroupRow_2.IndentCellProps>? get() = definedExternally; set(value) = definedExternally
//    var indentColumnWidth: Number? get() = definedExternally; set(value) = definedExternally
//    var rowComponent: React.ComponentType<TableGroupRow_2.RowProps>? get() = definedExternally; set(value) = definedExternally
//    var showColumnsWhenGrouped: Boolean? get() = definedExternally; set(value) = definedExternally
}
//external interface `T$20` {
//    var Row: React.ComponentType<Table_2.RowProps /* Table_2.RowProps & `T$0` */>
//    var Cell: React.ComponentType<TableHeaderRow_2.CellProps /* TableHeaderRow_2.CellProps & `T$0` */>
//    var Content: React.ComponentType<TableHeaderRow_2.ContentProps /* TableHeaderRow_2.ContentProps & `T$0` */>
//    var SortLabel: React.ComponentType<TableHeaderRow_2.SortLabelProps /* TableHeaderRow_2.SortLabelProps & `T$0` */>
//    var Title: React.ComponentType<Any? /* Any? & `T$0` */>
//    var GroupButton: React.ComponentType<TableHeaderRow_2.GroupButtonProps /* TableHeaderRow_2.GroupButtonProps & `T$0` */>
//}
//external var TableHeaderRow: React.ComponentType<TableHeaderRowProps> /* React.ComponentType<TableHeaderRowProps> & `T$9` & `T$20` */ = definedExternally


@Suppress("UnsafeCastFromDynamic")
private val tableHeaderRowComponent: RComponent<TableHeaderRowProps, RState> = dx_react_grid_material_ui.TableHeaderRow

fun RBuilder.tableHeaderRow(
    showSortingControls: Boolean = false,
    cellComponent: RClass<RProps>? = null,
) = createStyled(tableHeaderRowComponent) {
    attrs.showSortingControls = showSortingControls
    if (cellComponent != null) attrs.cellComponent = cellComponent
}

interface TableHeaderRowProps : StyledProps {
    var cellComponent: RClass<RProps>?
//    var contentComponent: React.ComponentType<TableHeaderRow_2.ContentProps>? get() = definedExternally; set(value) = definedExternally
//    var groupButtonComponent: React.ComponentType<TableHeaderRow_2.GroupButtonProps>? get() = definedExternally; set(value) = definedExternally
//    var messages: TableHeaderRow_2.LocalizationMessages? get() = definedExternally; set(value) = definedExternally
//    var rowComponent: React.ComponentType<Table_2.RowProps>? get() = definedExternally; set(value) = definedExternally
//    var showGroupingControls: Boolean? get() = definedExternally; set(value) = definedExternally
    var showSortingControls: Boolean
//    var sortLabelComponent: React.ComponentType<TableHeaderRow_2.SortLabelProps>? get() = definedExternally; set(value) = definedExternally
//    var titleComponent: React.ComponentType<Any>? get() = definedExternally; set(value) = definedExternally
}

//@Suppress("UnsafeCastFromDynamic")
//private val tableComponent: RComponent<TableProps, RState> = dx_react_grid_material_ui.Table

@Suppress("UnsafeCastFromDynamic")
private val tableComponent: RComponent<TableProps, RState> = dx_react_grid_material_ui.Table

fun RBuilder.table(
    columnExtensions: List<TableProps.ColumnExtension>? = null,
    rowComponent: RClass<RowProps>? = null,
    cellComponent: RClass<RProps>? = null,
) = createStyled(tableComponent) {
    attrs.columnExtensions = columnExtensions?.toTypedArray()
    if (rowComponent != null)  attrs.rowComponent  = rowComponent
    if (cellComponent != null) attrs.cellComponent = cellComponent
    attrs.messages = TableProps.LocalizationMessages(
        noData = "Keine Daten verfügbar"
    )
}

interface TableProps : StyledProps {
//    var bodyComponent: React.ComponentType<Any>? get() = definedExternally; set(value) = definedExternally
    var cellComponent: RClass<RProps>?//((RProps) -> RClass<RProps>)? //RClass<CellProps>? //
    var columnExtensions: Array<ColumnExtension>?

//    var containerComponent: React.ComponentType<Any>? get() = definedExternally; set(value) = definedExternally
//    var footerComponent: React.ComponentType<Any>? get() = definedExternally; set(value) = definedExternally
//    var headComponent: React.ComponentType<Any>? get() = definedExternally; set(value) = definedExternally
    var messages: LocalizationMessages?

//    var noDataCellComponent: React.ComponentType<Table_2.NoDataCellProps>? get() = definedExternally; set(value) = definedExternally
//    var noDataRowComponent: React.ComponentType<Table_2.RowProps>? get() = definedExternally; set(value) = definedExternally
    var rowComponent: RClass<RowProps>?
//    var stubCellComponent: React.ComponentType<Table_2.CellProps>? get() = definedExternally; set(value) = definedExternally
//    var stubHeaderCellComponent: React.ComponentType<Table_2.CellProps>? get() = definedExternally; set(value) = definedExternally
//    var stubRowComponent: React.ComponentType<Table_2.RowProps>? get() = definedExternally; set(value) = definedExternally
//    var tableComponent: React.ComponentType<Any>? get() = definedExternally; set(value) = definedExternally

    data class LocalizationMessages(val noData: String)

    data class ColumnExtension(
        val columnName: String,
        val width: Int? = null,
        val align: String? = null,
        val wordWrapEnabled: Boolean? = null,
    )
}
//external interface `T$21` {
//    var Cell: React.ComponentType<TableRowDetail_2.CellProps /* TableRowDetail_2.CellProps & `T$0` */>
//    var Row: React.ComponentType<TableRowDetail_2.RowProps /* TableRowDetail_2.RowProps & `T$0` */>
//    var ToggleCell: React.ComponentType<TableRowDetail_2.ToggleCellProps /* TableRowDetail_2.ToggleCellProps & `T$0` */>
//}
//external var TableRowDetail: React.ComponentType<TableRowDetailProps> /* React.ComponentType<TableRowDetailProps> & `T$18` & `T$21` */ = definedExternally
//external interface TableRowDetailProps {
//    var cellComponent: React.ComponentType<TableRowDetail_2.CellProps>? get() = definedExternally; set(value) = definedExternally
//    var contentComponent: React.ComponentType<TableRowDetail_2.ContentProps>? get() = definedExternally; set(value) = definedExternally
//    var rowComponent: React.ComponentType<TableRowDetail_2.RowProps>? get() = definedExternally; set(value) = definedExternally
//    var rowHeight: Number? get() = definedExternally; set(value) = definedExternally
//    var toggleCellComponent: React.ComponentType<TableRowDetail_2.ToggleCellProps>? get() = definedExternally; set(value) = definedExternally
//    var toggleColumnWidth: Number? get() = definedExternally; set(value) = definedExternally
//}
//external interface `T$22` {
//    var HeaderCell: React.ComponentType<TableSelection_2.HeaderCellProps /* TableSelection_2.HeaderCellProps & `T$0` */>
//    var Cell: React.ComponentType<TableSelection_2.CellProps /* TableSelection_2.CellProps & `T$0` */>
//}
//external var TableSelection: React.ComponentType<TableSelectionProps> /* React.ComponentType<TableSelectionProps> & `T$12` & `T$22` */ = definedExternally

@Suppress("UnsafeCastFromDynamic")
private val tableSelectionComponent: RComponent<TableSelectionProps, RState> = dx_react_grid_material_ui.TableSelection
val SELECTION_COLUMN_TYPE: String = dx_react_grid_material_ui.TableSelection.COLUMN_TYPE

fun RBuilder.tableSelection(
    highlightRow: Boolean? = false,
    showSelectAll: Boolean? = true,
    showSelectionColumn: Boolean? = true,
) = createStyled(tableSelectionComponent) {
    attrs.highlightRow = highlightRow
    attrs.showSelectAll = showSelectAll
    attrs.showSelectionColumn = showSelectionColumn
}

interface TableSelectionProps : StyledProps {
    //    var cellComponent: React.ComponentType<TableSelection_2.CellProps>? get() = definedExternally; set(value) = definedExternally
//    var headerCellComponent: React.ComponentType<TableSelection_2.HeaderCellProps>? get() = definedExternally; set(value) = definedExternally
    var highlightRow: Boolean?

    //    var selectByRowClick: Boolean? get() = definedExternally; set(value) = definedExternally
//    var selectionColumnWidth: Number? get() = definedExternally; set(value) = definedExternally
    var showSelectAll: Boolean?
    var showSelectionColumn: Boolean?
}
//external interface `T$23` {
//    var TREE_ROW_TYPE: Any?
//    var GROUP_ROW_TYPE: Any?
//    var TOTAL_ROW_TYPE: Any?
//}
//external interface `T$24` {
//    var Cell: React.ComponentType<TableSummaryRow_2.CellProps /* TableSummaryRow_2.CellProps & `T$0` */>
//    var TotalRow: React.ComponentType<React.ComponentType<Table_2.RowProps> /* React.ComponentType<Table_2.RowProps> & `T$0` */>
//    var GroupRow: React.ComponentType<React.ComponentType<Table_2.RowProps> /* React.ComponentType<Table_2.RowProps> & `T$0` */>
//    var TreeRow: React.ComponentType<React.ComponentType<Table_2.RowProps> /* React.ComponentType<Table_2.RowProps> & `T$0` */>
//    var TotalCell: React.ComponentType<React.ComponentType<TableSummaryRow_2.CellProps> /* React.ComponentType<TableSummaryRow_2.CellProps> & `T$0` */>
//    var GroupCell: React.ComponentType<React.ComponentType<TableSummaryRow_2.CellProps> /* React.ComponentType<TableSummaryRow_2.CellProps> & `T$0` */>
//    var TreeCell: React.ComponentType<React.ComponentType<TableSummaryRow_2.CellProps> /* React.ComponentType<TableSummaryRow_2.CellProps> & `T$0` */>
//    var TreeColumnCell: React.ComponentType<React.ComponentType<TableSummaryRow_2.CellProps> /* React.ComponentType<TableSummaryRow_2.CellProps> & `T$0` */>
//    var TreeColumnContent: React.ComponentType<React.ComponentType<TableSummaryRow_2.ContentProps> /* React.ComponentType<TableSummaryRow_2.ContentProps> & `T$0` */>
//    var TreeColumnIndent: React.ComponentType<React.ComponentType<TableSummaryRow_2.IndentProps> /* React.ComponentType<TableSummaryRow_2.IndentProps> & `T$0` */>
//    var Item: React.ComponentType<React.ComponentType<Any> /* React.ComponentType<Any> & `T$0` */>
//}
//external var TableSummaryRow: React.ComponentType<TableSummaryRowProps> /* React.ComponentType<TableSummaryRowProps> & `T$23` & `T$24` */ = definedExternally
//external interface TableSummaryRowProps {
//    var formatlessSummaryTypes: Array<String>? get() = definedExternally; set(value) = definedExternally
//    var groupCellComponent: React.ComponentType<TableSummaryRow_2.CellProps>? get() = definedExternally; set(value) = definedExternally
//    var groupRowComponent: React.ComponentType<Table_2.RowProps>? get() = definedExternally; set(value) = definedExternally
//    var itemComponent: React.ComponentType<TableSummaryRow_2.ItemProps>? get() = definedExternally; set(value) = definedExternally
//    var messages: TableSummaryRow_2.LocalizationMessages? get() = definedExternally; set(value) = definedExternally
//    var totalCellComponent: React.ComponentType<TableSummaryRow_2.CellProps>? get() = definedExternally; set(value) = definedExternally
//    var totalRowComponent: React.ComponentType<Table_2.RowProps>? get() = definedExternally; set(value) = definedExternally
//    var treeCellComponent: React.ComponentType<TableSummaryRow_2.CellProps>? get() = definedExternally; set(value) = definedExternally
//    var treeColumnCellComponent: React.ComponentType<TableSummaryRow_2.CellProps>? get() = definedExternally; set(value) = definedExternally
//    var treeColumnContentComponent: React.ComponentType<TableSummaryRow_2.ContentProps>? get() = definedExternally; set(value) = definedExternally
//    var treeColumnIndentComponent: React.ComponentType<TableSummaryRow_2.IndentProps>? get() = definedExternally; set(value) = definedExternally
//    var treeRowComponent: React.ComponentType<Table_2.RowProps>? get() = definedExternally; set(value) = definedExternally
//}
//external interface `T$25` {
//    var Cell: React.ComponentType<TableTreeColumn_2.CellProps /* TableTreeColumn_2.CellProps & `T$0` */>
//    var Content: React.ComponentType<TableTreeColumn_2.ContentProps /* TableTreeColumn_2.ContentProps & `T$0` */>
//    var Indent: React.ComponentType<TableTreeColumn_2.IndentProps /* TableTreeColumn_2.IndentProps & `T$0` */>
//    var ExpandButton: React.ComponentType<TableTreeColumn_2.ExpandButtonProps /* TableTreeColumn_2.ExpandButtonProps & `T$0` */>
//    var Checkbox: React.ComponentType<TableTreeColumn_2.CheckboxProps /* TableTreeColumn_2.CheckboxProps & `T$0` */>
//}
//external var TableTreeColumn: React.ComponentType<TableTreeColumnProps> /* React.ComponentType<TableTreeColumnProps> & `T$25` */ = definedExternally
//external interface TableTreeColumnProps {
//    var cellComponent: React.ComponentType<TableTreeColumn_2.CellProps>? get() = definedExternally; set(value) = definedExternally
//    var checkboxComponent: React.ComponentType<TableTreeColumn_2.CheckboxProps>? get() = definedExternally; set(value) = definedExternally
//    var contentComponent: React.ComponentType<TableTreeColumn_2.ContentProps>? get() = definedExternally; set(value) = definedExternally
//    var expandButtonComponent: React.ComponentType<TableTreeColumn_2.ExpandButtonProps>? get() = definedExternally; set(value) = definedExternally
//    var `for`: String? get() = definedExternally; set(value) = definedExternally
//    var indentComponent: React.ComponentType<TableTreeColumn_2.IndentProps>? get() = definedExternally; set(value) = definedExternally
//    var showSelectAll: Boolean? get() = definedExternally; set(value) = definedExternally
//    var showSelectionControls: Boolean? get() = definedExternally; set(value) = definedExternally
//}
//external interface `T$26` {
//    var Root: React.ComponentType<Toolbar_2.RootProps /* Toolbar_2.RootProps & `T$0` */>
//}
//external var Toolbar: React.ComponentType<ToolbarProps> /* React.ComponentType<ToolbarProps> & `T$26` */ = definedExternally

@Suppress("UnsafeCastFromDynamic")
private val toolbarComponent: RComponent<ToolbarProps, RState> = dx_react_grid_material_ui.Toolbar

fun RBuilder.gridToolbar() = createStyled(toolbarComponent) {}

interface ToolbarProps : StyledProps {
//    var rootComponent: React.ComponentType<Toolbar_2.RootProps>? get() = definedExternally; set(value) = definedExternally
}
//external interface `T$27` {
//    var Table: React.ComponentType<Any? /* Any? & `T$0` */>
//    var TableHead: React.ComponentType<Any? /* Any? & `T$0` */>
//    var TableBody: React.ComponentType<Any? /* Any? & `T$0` */>
//    var Container: React.ComponentType<Any? /* Any? & `T$0` */>
//    var Cell: React.ComponentType<Table_2.DataCellProps /* Table_2.DataCellProps & `T$0` */>
//    var Row: React.ComponentType<Table_2.DataRowProps /* Table_2.DataRowProps & `T$0` */>
//    var NoDataCell: React.ComponentType<Table_2.CellProps /* Table_2.CellProps & `T$0` */>
//    var NoDataRow: React.ComponentType<Table_2.RowProps /* Table_2.RowProps & `T$0` */>
//    var StubRow: React.ComponentType<Table_2.RowProps /* Table_2.RowProps & `T$0` */>
//    var StubCell: React.ComponentType<Table_2.CellProps /* Table_2.CellProps & `T$0` */>
//    var StubHeaderCell: React.ComponentType<Table_2.CellProps /* Table_2.CellProps & `T$0` */>
//}
//external var VirtualTable: React.ComponentType<VirtualTableProps> /* React.ComponentType<VirtualTableProps> & `T$7` & `T$27` */ = definedExternally
//external interface VirtualTableProps {
//    var bodyComponent: React.ComponentType<Any>? get() = definedExternally; set(value) = definedExternally
//    var cellComponent: React.ComponentType<Table_2.DataCellProps>? get() = definedExternally; set(value) = definedExternally
//    var columnExtensions: Array<Table_2.ColumnExtension>? get() = definedExternally; set(value) = definedExternally
//    var containerComponent: React.ComponentType<Any>? get() = definedExternally; set(value) = definedExternally
//    var estimatedRowHeight: Number? get() = definedExternally; set(value) = definedExternally
//    var footerComponent: React.ComponentType<Any>? get() = definedExternally; set(value) = definedExternally
//    var headComponent: React.ComponentType<Any>? get() = definedExternally; set(value) = definedExternally
//    var height: dynamic /* String | Number */ get() = definedExternally; set(value) = definedExternally
//    var messages: Table_2.LocalizationMessages? get() = definedExternally; set(value) = definedExternally
//    var noDataCellComponent: React.ComponentType<Table_2.NoDataCellProps>? get() = definedExternally; set(value) = definedExternally
//    var noDataRowComponent: React.ComponentType<Table_2.RowProps>? get() = definedExternally; set(value) = definedExternally
//    var rowComponent: React.ComponentType<Table_2.DataRowProps>? get() = definedExternally; set(value) = definedExternally
//    var stubCellComponent: React.ComponentType<Table_2.CellProps>? get() = definedExternally; set(value) = definedExternally
//    var stubHeaderCellComponent: React.ComponentType<Table_2.CellProps>? get() = definedExternally; set(value) = definedExternally
//    var stubRowComponent: React.ComponentType<Table_2.RowProps>? get() = definedExternally; set(value) = definedExternally
//    var tableComponent: React.ComponentType<Any>? get() = definedExternally; set(value) = definedExternally
//}
