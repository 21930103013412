package de.geomobile.common.portalmodels

import kotlinx.serialization.Serializable

@Serializable
data class DeltaUpdate<Item, ItemIdentifier>(
    val newOrModified:List<Item>? = null,
    val deleted:List<ItemIdentifier>? = null
)

@Serializable
data class DeltaMapUpdate<Item: Map<*, *>>(
    val newOrModified:Item? = null
)