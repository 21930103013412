package de.geomobile.frontend.utils

import com.ccfraser.muirwik.components.*
import react.RBuilder
import react.RComponent
import react.RState
import styled.StyledHandler

@JsModule("@material-ui/core/Icon")
private external val iconModule: dynamic

@Suppress("UnsafeCastFromDynamic")
private val iconComponent: RComponent<MIconProps, RState> = iconModule.default

fun RBuilder.mIconNoTranslate(
    iconName: String,
    primary: Boolean = false, // If true, then this overrides the color... just an easier setter...
    color: MIconColor? = null,
    fontSize: MIconFontSize = MIconFontSize.default,
    addAsChild: Boolean = true,
    className: String? = "notranslate",
    handler: StyledHandler<MIconProps>? = null,
) = createStyled(iconComponent, addAsChild) {

    if (primary) {
        attrs.color = MIconColor.primary
    } else {
        color?.let { attrs.color = color }
    }
    attrs.fontSize = fontSize

    childList.add(iconName)
    setStyledPropsAndRunHandler(className, handler)
}