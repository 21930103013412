package de.geomobile.common.softwaremgmt

import de.geomobile.common.portalmodels.CompanySmall
import de.geomobile.common.portalmodels.Product
import de.geomobile.common.time.LocalDateTime
import kotlinx.serialization.Serializable

@Serializable
data class SoftwareBundle(
    val id: Int,
    val company: CompanySmall,
    val internalVersion: String,
    val externalVersion: String,
    val software: List<BundledSoftware>,
    val createdBy: String,
    val createdAt: LocalDateTime,
    val product: Product
) {
    @Serializable
    data class BundledSoftware(
        val software: Software,
        val softwareVersion: SoftwareVersion
    )
}

@Serializable
data class SoftwareBundleDTO(
    val software: List<SoftwareVersionDTO>
)

fun SoftwareBundle.toDto(assignedAt: LocalDateTime) = SoftwareBundleDTO(
    software = software.map { it.softwareVersion.toDto(assignedAt) }
)

@Serializable
data class CreateBundleDTO(
    val internalVersion: String,
    val externalVersion: String,
    val software: List<SoftwareBundle.BundledSoftware>
)