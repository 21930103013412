package de.geomobile.common.softwaremgmt

import de.geomobile.common.filter.FilterRules
import de.geomobile.common.time.LocalDateTime
import kotlinx.serialization.Serializable

@Serializable
data class SoftwareAssignmentFilter(
    val id: Int,
    val name: String,
    val filterValid: Boolean,
    val filterRules: FilterRules,
    val assignment: SoftwareAssignment
)

@Serializable
data class SoftwareAssignment(
    val bundle: SoftwareBundle,
    val pollingIntervalInSec: Int?,
    val canceled: Boolean,
    val createdBy: String,
    val createdAt: LocalDateTime,
    val assignedBy: String,
    val assignedAt: LocalDateTime
)

@Serializable
data class SoftwareReassignment(
    val reassignedBy: String,
    val reassignedAt: LocalDateTime
)

@Serializable
data class DeviceSoftwareAssignment(
    val currentAssignment: SoftwareAssignment,
    val assignmentOverwrite: SoftwareAssignment? = null,
    val reassignment: SoftwareReassignment? = null,
    val assignmentFilterBranch: List<SoftwareAssignmentFilter>
)

@Serializable
data class SoftwareAssignmentFilterHistory(
    val name: String,
    val filterRules: FilterRules,
    val bundle: SoftwareBundle,
    val pollingIntervalInSec: Int?,
    val canceled: Boolean,
    val createdBy: String,
    val createdAt: LocalDateTime,
    val assignedBy: String,
    val assignedAt: LocalDateTime
)