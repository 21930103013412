package de.geomobile.frontend.utils

import kotlinx.coroutines.*
import react.*
import styled.StyledProps

abstract class IntervalComponent<P : RProps, S : RState> : RComponent<P, S> {
    private var disposable: Disposable? = null

    abstract val interval: Long

    constructor() : super()

    constructor(props: P) : super(props)

    open fun onTick() {}

    override fun componentDidMount() {
        disposable = TimerService.intervalCallback(interval) {
            onTick()
        }
        onTick()
    }

    override fun componentWillUnmount() {
        disposable?.dispose()
    }
}

fun RBuilder.timerWrapper(
    interval: Long,
    handler: RBuilder.() -> Unit,
) = child(TimerWrapper::class) {
    attrs.interval = interval
    attrs.handler = handler
}

class TimerWrapper : IntervalComponent<TimerWrapper.Props, RState>() {

    interface Props : StyledProps {
        var interval: Long
        var handler: RBuilder.() -> Unit
    }

    override val interval: Long
        get() = props.interval

    override fun onTick() {
        setState { }
    }

    override fun RBuilder.render() {
        props.handler(this)
    }
}

private interface Disposable {
    fun dispose()
}

private object TimerService {

    private data class Timer(
        val job: Job,
        val callbacks: MutableList<() -> Unit>,
    )

    private val timerCache = mutableMapOf<Long, Timer>()

    fun intervalCallback(interval: Long, callback: () -> Unit): Disposable {

        val timer = timerCache.getOrPut(interval) {
            val callbacks = mutableListOf<() -> Unit>()
            Timer(
                job = GlobalScope.launch {
                    while (isActive) {
                        for (callback in callbacks) {
                            callback()
                        }
                        delay(interval)
                    }
                },
                callbacks = callbacks
            )
        }

        timer.callbacks.add(callback)

        return object : Disposable {
            override fun dispose() {

                timer.callbacks.remove(callback)
                if (timer.callbacks.isEmpty()) {
                    timer.job.cancel()
                    timerCache.remove(interval)
                }
            }
        }
    }

}