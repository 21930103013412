package de.geomobile.frontend.features.admin.users

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardActions
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.dialog.mDialog
import com.ccfraser.muirwik.components.dialog.mDialogActions
import com.ccfraser.muirwik.components.dialog.mDialogContent
import com.ccfraser.muirwik.components.form.MFormControlMargin
import com.ccfraser.muirwik.components.form.MFormControlVariant
import com.ccfraser.muirwik.components.form.mFormControl
import com.ccfraser.muirwik.components.form.mFormHelperText
import com.ccfraser.muirwik.components.menu.mMenuItem
import com.ccfraser.muirwik.components.styles.Breakpoint
import de.geomobile.common.permission.Permissions
import de.geomobile.common.permission.Role
import de.geomobile.common.portalmodels.Company
import de.geomobile.common.portalmodels.UserDTO
import de.geomobile.common.time.LocalDateTime
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.utils.*
import kotlinext.js.jsObject
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.async
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.*
import kotlinx.html.InputType
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.json.Json
import react.RBuilder
import react.RProps
import react.RState
import react.setState
import styled.css

fun RBuilder.adminUserCreate(
    cancel: () -> Unit,
    created: (userId: String) -> Unit,
) = child(AdminUserDetailEdit::class) {
    attrs.userId = null
    attrs.mode = AdminUserDetailEdit.Mode.CREATE
    attrs.cancel = cancel
    attrs.saved = created
    attrs.onDeleted = cancel
}

fun RBuilder.adminUserEdit(
    userId: String,
    goBack: () -> Unit,
    onDeleted: () -> Unit,
) = child(AdminUserDetailEdit::class) {
    attrs.userId = userId
    attrs.mode = AdminUserDetailEdit.Mode.EDIT
    attrs.cancel = { goBack() }
    attrs.saved = { goBack() }
    attrs.onDeleted = onDeleted
}

class AdminUserDetailEdit : CComponent<AdminUserDetailEdit.Props, AdminUserDetailEdit.State>() {

    enum class Mode {
        CREATE, EDIT
    }

    interface Props : RProps {
        var userId: String?
        var mode: Mode
        var cancel: () -> Unit
        var saved: (userId: String) -> Unit
        var onDeleted: () -> Unit
    }

    class State(
        var email: String? = null,
        var firstName: String? = null,
        var lastName: String? = null,
        var company: Company? = null,
        var roleId: String? = null,
        var setPassword: Boolean = false,
        var password: String = "",
        var passwordRepeat: String = "",
        var blocked: Boolean = false,

        var confirmDelete: Boolean = false,

        var companies: List<Company>? = null,
        var roles: List<Role> = emptyList(),
        var saving: Boolean = false,
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        launch {
            val deferredCompanies = if (isAuthorized(Permissions.UserManagement.notRestrictedToCompany))
                async(Dispatchers.Default) {
                    portalRestApi.get("/admin/companies", ListSerializer(Company.serializer()))
                }
            else
                null

            val deferredRoles = async(Dispatchers.Default) {
                portalRestApi.get("/admin/roles", ListSerializer(Role.serializer()))
            }

            val user = if (props.userId != null)
                withContext(Dispatchers.Default) {
                    portalRestApi.get("/admin/users/${props.userId}", UserDTO.serializer())
                }
            else
                null

            val ownUser = if (props.userId == null)
                withContext(Dispatchers.Default) {
                    portalRestApi.get("/user", UserDTO.serializer())
                }
            else
                null

            val companies = deferredCompanies?.await()
            val roles = deferredRoles.await()

            setState {
                this.email = user?.email
                this.firstName = user?.firstName
                this.lastName = user?.lastName
                this.company = user?.company ?: ownUser!!.company
                this.roleId = user?.role?.id
                this.blocked = user?.state == UserDTO.State.Blocked

                this.companies = companies
                this.roles = roles
            }
        }
    }

    override fun RBuilder.render() {
        val companies = state.companies
        val roles = state.roles

        mCard {
            mCardHeaderExtended(
                title = when (props.mode) {
                    Mode.CREATE -> "Erstellen"
                    Mode.EDIT -> "Bearbeiten"
                },
                subHeader = state.email ?: "",
                titleTextTransform = TextTransform.uppercase,
                avatar = RBuilder().mIconButtonNoTranslate(
                    iconName = "arrow_back",
                    onClick = { props.cancel() }
                )
            ) {
                attrs.titleTypographyProps = jsObject<MTypographyProps> {
                    variant = MTypographyVariant.subtitle2
                    color = MTypographyColor.secondary
                }
                attrs.subheaderTypographyProps = jsObject<MTypographyProps> {
                    variant = MTypographyVariant.caption
                }
            }
            mDivider { }
            mCardContent {
                if (props.mode == Mode.EDIT && state.email == null) {
                    mCircularProgress { css { display = Display.block; margin(LinearDimension.auto) } }
                } else {
                    mGridContainer2(direction = MGridDirection.row) {

                        if (companies != null) {
                            mGridItem2(
                                MGridBreakpoints2(MGridSize2.Cells6)
                                    .down(Breakpoint.sm, MGridSize2.Cells12)
                            ) {
                                mFormControl(margin = MFormControlMargin.normal, required = true, fullWidth = true) {
                                    mTypography(
                                        text = "Unternehmen *",
                                        variant = MTypographyVariant.caption
                                    ) {
                                        css { display = Display.block; marginBottom = 6.px }
                                    }
                                    mSelect(
                                        value = state.company?.id,
                                        name = "company",
                                        id = "company",
                                        fullWidth = true,
                                        variant = MFormControlVariant.outlined,
                                        onChange = { event, _ ->
                                            val id = event.targetValue as String
                                            setState { this.company = companies.first { it.id == id } }
                                        }
                                    ) {
                                        attrs.required = true
                                        attrs.margin = MFormControlMargin.dense.toString()

                                        for (company in companies) {
                                            mMenuItem(primaryText = company.name, value = company.id)
                                        }
                                    }
                                }
                            }
                        }

                        mGridItem2(
                            MGridBreakpoints2(if (companies != null) MGridSize2.Cells6 else MGridSize2.Cells12)
                                .down(Breakpoint.sm, MGridSize2.Cells12)
                        ) {
                            mFormControl(margin = MFormControlMargin.normal, required = true, fullWidth = true) {
                                mTypography(
                                    text = "Rolle *",
                                    variant = MTypographyVariant.caption
                                ) {
                                    css { display = Display.block; marginBottom = 6.px }
                                }
                                mSelect(
                                    value = state.roleId ?: "-",
                                    name = "role",
                                    id = "role",
                                    variant = MFormControlVariant.outlined,
                                    onChange = { event, _ ->
                                        val value = event.targetValue as String
                                        setState { this.roleId = value }
                                    }
                                ) {
                                    attrs.required = true
                                    attrs.margin = MFormControlMargin.dense.toString()

                                    val filteredRoles = roles
                                        .filter { it.company == null || it.company == state.company }
                                        .filter { it.visibleForCompany || state.company?.originator == true }

                                    for (role in filteredRoles) {
                                        mMenuItem(primaryText = role.name, value = role.id)
                                    }
                                }
                            }
                        }

                        mGridItem2(
                            MGridBreakpoints2(MGridSize2.Cells4)
                                .down(Breakpoint.sm, MGridSize2.Cells12)
                        ) {
                            mTextField(
                                label = "Vorname",
                                value = state.firstName ?: "",
                                name = "firstname",
                                fullWidth = true,
                                required = false,
                                margin = MFormControlMargin.dense,
                                variant = MFormControlVariant.outlined,
                                id = "firstname",
                                onChange = {
                                    val value = it.targetInputValue
                                    setState { this.firstName = value }
                                }
                            )
                        }

                        mGridItem2(
                            MGridBreakpoints2(MGridSize2.Cells4)
                                .down(Breakpoint.sm, MGridSize2.Cells12)
                        ) {
                            mTextField(
                                label = "Nachname",
                                value = state.lastName ?: "",
                                required = false,
                                margin = MFormControlMargin.dense,
                                fullWidth = true,
                                variant = MFormControlVariant.outlined,
                                name = "lastname",
                                id = "lastname",
                                onChange = {
                                    val value = it.targetInputValue
                                    setState { this.lastName = value }
                                }
                            )
                        }

                        mGridItem2(
                            MGridBreakpoints2(MGridSize2.Cells4)
                                .down(Breakpoint.sm, MGridSize2.Cells12)
                        ) {
                            mTextField(
                                label = "E-Mail",
                                value = state.email ?: "",
                                required = true,
                                margin = MFormControlMargin.dense,
                                fullWidth = true,
                                variant = MFormControlVariant.outlined,
                                name = "email",
                                id = "email",
                                onChange = {
                                    val value = it.targetInputValue
                                    setState { this.email = value }
                                }
                            )
                        }

                        mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                            mSwitchWithLabel(
                                label = when (props.mode) {
                                    Mode.CREATE -> "Passwort manuell setzen"
                                    Mode.EDIT -> "Passwort ändern"
                                },
                                checked = state.setPassword,
                                onChange = { _, checked ->
                                    setState {
                                        setPassword = checked
                                        if (!checked) {
                                            password = ""
                                            passwordRepeat = ""
                                        }
                                    }
                                }
                            )

                            if (props.mode == Mode.CREATE) {
                                mFormHelperText(
                                    caption = "Wenn das Passwort nicht manuell gesetzt wird, wird ein Link zum Setzen des Passworts an die E-Mail-Adresse des Benutzers gesendet"
                                )
                            }
                        }

                        mGridItem2(
                            MGridBreakpoints2(MGridSize2.Cells6)
                                .down(Breakpoint.sm, MGridSize2.Cells12)
                        ) {
                            mTextField(
                                label = "Neues Passwort",
                                value = state.password,
                                name = "password",
                                required = true,
                                fullWidth = true,
                                disabled = !state.setPassword,
                                margin = MFormControlMargin.dense,
                                variant = MFormControlVariant.outlined,
                                id = "password",
                                type = InputType.password,
                                onChange = {
                                    val value = it.targetInputValue
                                    setState { this.password = value }
                                }
                            )
                        }

                        mGridItem2(
                            MGridBreakpoints2(MGridSize2.Cells6)
                                .down(Breakpoint.sm, MGridSize2.Cells12)
                        ) {
                            mTextField(
                                label = "Neues Passwort wiederholen",
                                value = state.passwordRepeat,
                                name = "passwordRepeat",
                                required = true,
                                fullWidth = true,
                                disabled = !state.setPassword,
                                margin = MFormControlMargin.dense,
                                variant = MFormControlVariant.outlined,
                                id = "passwordRepeat",
                                type = InputType.password,
                                error = state.password != state.passwordRepeat,
                                onChange = {
                                    val value = it.targetInputValue
                                    setState { this.passwordRepeat = value }
                                },
                            )
                        }

                        mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                            mSwitchWithLabel(
                                label = "Benutzer sperren",
                                checked = state.blocked,
                                onChange = { _, checked ->
                                    setState {
                                        blocked = checked
                                    }
                                }
                            )
                        }
                    }
                }
            }

            if (state.saving) {
                mCircularProgress(size = 30.px) {
                    css {
                        display = Display.block
                        margin(vertical = 1.spacingUnits, horizontal = 3.spacingUnits)
                        marginLeft = LinearDimension.auto
                    }
                }
            } else {
                mDivider { }
                mCardActions {
                    css { padding(2.spacingUnits) }

                    val disabled = state.email == null ||
                            state.company == null ||
                            state.roleId == null ||
                            state.password != state.passwordRepeat ||
                            (state.setPassword && state.password.isBlank())

                    mButton(
                        caption = when (props.mode) {
                            Mode.CREATE -> "Erstellen"
                            Mode.EDIT -> "Übernehmen"
                        },
                        variant = MButtonVariant.contained,
                        color = MColor.secondary,
                        disabled = disabled,
                        onClick = {
                            val user = UserDTO(
                                id = props.userId ?: "",
                                email = state.email!!,
                                firstName = state.firstName,
                                lastName = state.lastName,
                                company = state.company!!,
                                role = roles.first { it.id == state.roleId },
                                password = state.password.takeIf { state.setPassword },
                                state = when {
                                    state.blocked -> UserDTO.State.Blocked
                                    state.setPassword -> UserDTO.State.Active
                                    else -> UserDTO.State.Invited(LocalDateTime.now())
                                }
                            )
                            saveUser(user)
                        }
                    ) {
                        attrs.disableElevation = true
                    }

                    if (props.mode == Mode.EDIT && props.userId != null)
                        mButton(
                            color = MColor.inherit,
                            caption = "Löschen",
                            variant = MButtonVariant.contained,
                            onClick = { setState { confirmDelete = true } }
                        ) {
                            css {
                                backgroundColor = Color.red;
                                color = Color.white
                            }
                            attrs.disableElevation = true
                        }

                    mButton(
                        caption = "Abbrechen",
                        variant = MButtonVariant.contained,
                        color = MColor.default,
                        onClick = { props.cancel() }
                    ) {
                        attrs.disableElevation = true
                    }
                }
            }
        }

        mDialog(
            open = state.confirmDelete,
            onClose = { _, _ -> setState { confirmDelete = false } }
        ) {
            mDialogContent { mTypography("Möchten Sie diesen Account löschen?") }
            mDialogActions {
                mButton("Abbrechen", onClick = { setState { confirmDelete = false } })
                mButton("Löschen", onClick = { deleteUser(props.userId!!) }) {
                    css { color = Color.red }
                }
            }
        }
    }

    private fun saveUser(user: UserDTO) {
        setState {
            this.saving = true
        }

        launch {
            val newUser = withContext(Dispatchers.Default) {
                when (props.mode) {
                    Mode.CREATE ->
                        portalRestApi.post(
                            "/admin/users",
                            body = Json.encodeToString(UserDTO.serializer(), user),
                            serializer = UserDTO.serializer()
                        )

                    Mode.EDIT ->
                        portalRestApi.put(
                            "/admin/users/${user.id}",
                            body = Json.encodeToString(UserDTO.serializer(), user),
                            serializer = UserDTO.serializer()
                        )
                }
            }

            props.saved(newUser.id)
        }
    }

    private fun deleteUser(userId: String) {
        setState {
            this.saving = true
        }

        launch {
            withContext(Dispatchers.Default) {
                portalRestApi.delete("/admin/users/${userId}")
            }

            props.onDeleted()
        }
    }
}