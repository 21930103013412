package de.geomobile.frontend.features.companyProfile

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardActions
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.lab.alert.MAlertSeverity
import com.ccfraser.muirwik.components.lab.alert.MAlertVariant
import com.ccfraser.muirwik.components.lab.alert.mAlert
import com.ccfraser.muirwik.components.list.mList
import com.ccfraser.muirwik.components.list.mListItem
import com.ccfraser.muirwik.components.list.mListSubheader
import com.ccfraser.muirwik.components.styles.Breakpoint
import com.ccfraser.muirwik.components.table.*
import de.geomobile.common.permission.Permissions
import de.geomobile.common.portalmodels.UserDTO
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.spacer
import de.geomobile.frontend.utils.*
import de.geomobile.frontend.utils.charts.Column
import de.geomobile.frontend.utils.charts.chart
import de.geomobile.frontend.utils.grid.TableProps
import de.geomobile.frontend.utils.grid.grid
import de.geomobile.frontend.utils.grid.table
import de.geomobile.frontend.utils.grid.tableHeaderRow
import kotlinx.browser.localStorage
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.*
import kotlinx.html.InputType
import kotlinx.html.emptyMap
import kotlinx.html.id
import kotlinx.html.js.onChangeFunction
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.events.Event
import org.w3c.dom.get
import org.w3c.files.File
import org.w3c.files.get
import org.w3c.xhr.FormData
import portalmodels.ElaDeployedDTO
import portalmodels.ElaStashedDTO
import portalmodels.ElaStatisticsDTO
import portalmodels.GenericResponseDTO
import react.RBuilder
import react.RProps
import react.RState
import react.router.dom.route
import react.setState
import styled.css
import styled.styledDiv
import styled.styledInput

fun RBuilder.companyProfileELA() = child(CompanyProfileELA::class) {}

class CompanyProfileELA : CComponent<CompanyProfileELA.Props, CompanyProfileELA.State>() {

    private var loadElaStatusJob: Job = Job()
    private var companyJob: Job = Job()

    interface Props : RProps {
        var path: String
        var id: Int
    }

    class State(
        var file: File? = null,
        var elaDeployed: ElaDeployedDTO? = null,
        var elaStashed: ElaStashedDTO? = null,
        var elaStatistics: ElaStatisticsDTO? = null,
        //var latestUpdate: Map<String,String> = mapOf(),
        var company: String = "",
        var distribution: Array<Array<Any>>? = null,
        var publishResponse: String = "",
        var columns: List<de.geomobile.frontend.utils.grid.Column>,
        var rows: List<Row> = emptyList(),
        var isUploading: Boolean = false,
        var isApplying: Boolean = false,
    ) : RState

    init {
        val columns = listOf(
            de.geomobile.frontend.utils.grid.Column(name = "time", title = "Datum"),
            de.geomobile.frontend.utils.grid.Column(name = "upId", title = "ID"),
            de.geomobile.frontend.utils.grid.Column(name = "upType", title = "Typ"),
            de.geomobile.frontend.utils.grid.Column(name = "software", title = "Software Ver."),
            de.geomobile.frontend.utils.grid.Column(name = "ini", title = "INI Version"),
            de.geomobile.frontend.utils.grid.Column(name = "mp3", title = "MP3 Version")
        )
        state = State(
            columns = columns
        )
    }

    data class Row(
        val time: String?,
        var upId: Int?,
        var upType: String?,
        var software: String?,
        var ini: String?,
        var mp3: String?,
    )

    override fun componentDidMount() {
        loadElaStatus()
    }

    private fun handleSubmit(event: Event) {
        event.preventDefault()
        setState {
            isUploading = true
            isApplying = true
        }
        state.file?.let {
            try {
                var version = state.elaDeployed?.let { status ->
                    if (status.deployedUpdates.isEmpty())
                        1
                    else
                        status.deployedUpdates.last().updateId?.plus(1)
                } ?: 1
                val filename = "ela_update_$version.zip"
                val formData = FormData()
                formData.append(name = it.name, value = it)
                launch {
                    withContext(Dispatchers.Default) {
                        val pr = portalRestApi.post(
                            path = "/companyprofile/${state.company}/ela/$filename/upload",
                            header = emptyMap,
                            body = formData,
                            serializer = GenericResponseDTO.serializer()
                        )
                        setState {
                            publishResponse = pr.message
                            isUploading = false
                        }
                        loadElaStatus()
                    }
                }
            } catch (e: Exception) {
                setState {
                    isUploading = false
                }
                return
            }
        }
    }

    fun loadCompany() {
        companyJob.cancel()
        companyJob = launch {
            val companyId =
                if (isAuthorized(Permissions.VehicleProfileManagement.notRestrictedToCompany) && localStorage["CompanyProfileCompany"] != null) {
                    localStorage["CompanyProfileCompany"]
                } else {
                    withContext(Dispatchers.Default) {
                        portalRestApi.get("/user", UserDTO.serializer())
                    }.company.id
                }
            companyId?.let {
                setState {
                    company = it
                }
            }
        }
    }

    fun updateRows(elaStatus: ElaDeployedDTO) {
        var rows = mutableListOf<Row>()
        elaStatus.deployedUpdates.forEach {
            rows.add(
                Row(
                    time = it.timestamp?.toText() ?: "Unbekannt",
                    upId = it.updateId,
                    upType = it.updateType,
                    software = it.software,
                    ini = it.ini,
                    mp3 = it.mp3
                )
            )
        }
        setState {
            this.rows = rows.toList()
        }
    }

    fun loadElaStatus() {
        loadElaStatusJob.cancel()
        loadElaStatusJob = launch {
            loadCompany()
            val deployed =
                portalRestApi.get("/companyprofile/${state.company}/ela/deployed", ElaDeployedDTO.serializer())
            val stashed = portalRestApi.get("/companyprofile/${state.company}/ela/stashed", ElaStashedDTO.serializer())
            val stats = portalRestApi.get("/companyprofile/${state.company}/ela/stats", ElaStatisticsDTO.serializer())
            deployed?.let {
                if (it.deployedUpdates.isNotEmpty())
                    updateRows(it)
            }
            val dist = stats?.let {
                if (it.outOfDateId.isNotEmpty())
                    arrayOf(
                        arrayOf("Aktuell", (it.totalElaBoxes - it.outOfDateId.size) as Any),
                        arrayOf("Veraltet", it.outOfDateId.size as Any)
                    )
                else
                    null
            }
            setState {
                deployed?.let { elaDeployed = it }
                stashed?.let { elaStashed = it }
                stats?.let { elaStatistics = it }
                dist?.let { distribution = it }
                isApplying = false
            }
        }
    }

    fun publishUpdate(upId: Int) {
        launch {
            val response =
                portalRestApi.get("/companyprofile/${state.company}/ela/$upId/publish", GenericResponseDTO.serializer())
            setState {
                //isApplying = false
                publishResponse = response.message
            }
            loadElaStatus()
        }
    }

    fun deleteUpdate(upId: Int) {
        launch {
            val response =
                portalRestApi.get("/companyprofile/${state.company}/ela/$upId/delete", GenericResponseDTO.serializer())
            setState {
                //isApplying = false
                publishResponse = response.message
            }
            loadElaStatus()
        }
    }

    override fun RBuilder.render() {
        route<RProps>("/companyprofile/system/ela") { _ ->
            spacer()
            mGridContainer2(direction = MGridDirection.column) {
                mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                    mBackdrop(open = state.isUploading || state.isApplying) {
                        css { color = Color.white; zIndex = 999999 }
                        mCircularProgress(color = MCircularProgressColor.inherit)
                    }
                    elaSettings()
                    if (state.elaDeployed?.deployedUpdates?.isNotEmpty() == true)
                        elaStatus()
                    if (state.elaStashed?.stashedUpdates?.isNotEmpty() == true)
                        elaStash()
                    if (state.rows.isNotEmpty())
                        elaHistory(state.columns, state.rows)
                }
            }
        }
    }

    private fun RBuilder.tableFormat() =
        table(
            columnExtensions = listOf(
                TableProps.ColumnExtension(
                    columnName = "time",
                ),
                TableProps.ColumnExtension(
                    columnName = "upId",
                ),
                TableProps.ColumnExtension(
                    columnName = "upType",
                ),
                TableProps.ColumnExtension(
                    columnName = "software",
                ),
                TableProps.ColumnExtension(
                    columnName = "ini",
                ),
                TableProps.ColumnExtension(
                    columnName = "mp3",
                )
            )
        )

    private fun RBuilder.elaStash() =
        mGridContainer2(direction = MGridDirection.row) {
            mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                mCard {
                    css(GlobalStyles.card)
                    mCardContent {
                        css(GlobalStyles.cardBoxContent)
                        mListSubheader(heading = "Nicht veröffentlichte Updates")
                        mTable {
                            mTableHead {
                                mTableRow {
                                    mTableCell(padding = MTableCellPadding.none) { +" " }
                                    mTableCell(padding = MTableCellPadding.none) { +" " }
                                    mTableCell(padding = MTableCellPadding.default) { +"Upload" }
                                    mTableCell(padding = MTableCellPadding.default) { +"ID" }
                                    mTableCell(padding = MTableCellPadding.default) { +"Typ" }
                                    mTableCell(padding = MTableCellPadding.default) { +"Software" }
                                    mTableCell(padding = MTableCellPadding.default) { +"ini" }
                                    mTableCell(padding = MTableCellPadding.default) { +"mp3" }
                                }
                            }
                            mTableBody {
                                state.elaStashed?.stashedUpdates?.forEach { update ->
                                    mTableRow {
                                        mTableCell {
                                            mTooltip("Veröffentlichen") {
                                                mIconButtonNoTranslate(
                                                    iconName = "publish",
                                                    color = MColor.primary,
                                                    onClick = {
                                                        setState { isApplying = true }
                                                        update.updateId?.let { id -> publishUpdate(id) }
                                                    }
                                                )
                                            }
                                        }
                                        mTableCell {
                                            mTooltip("Löschen") {
                                                mIconButtonNoTranslate(
                                                    iconName = "delete",
                                                    color = MColor.secondary,
                                                    onClick = {
                                                        setState { isApplying = true }
                                                        update.updateId?.let { id -> deleteUpdate(id) }
                                                    }
                                                )
                                            }
                                        }
                                        update.timestamp?.let {
                                            mTableCell(padding = MTableCellPadding.default) {
                                                mTypography(it.toText())
                                            }
                                        } ?: mTableCell { "" }
                                        update.updateId?.let {
                                            mTableCell(padding = MTableCellPadding.default) {
                                                mTypography(it.toString())
                                            }
                                        } ?: mTableCell { "" }
                                        update.updateType?.let {
                                            mTableCell(padding = MTableCellPadding.default) {
                                                mTypography(it)
                                            }
                                        } ?: mTableCell { "" }
                                        update.software?.let {
                                            mTableCell(padding = MTableCellPadding.default) {
                                                mTypography(it)
                                            }
                                        } ?: mTableCell { "" }
                                        update.ini?.let {
                                            mTableCell(padding = MTableCellPadding.default) {
                                                mTypography(it)
                                            }
                                        } ?: mTableCell { "" }
                                        update.mp3?.let {
                                            mTableCell(padding = MTableCellPadding.default) {
                                                mTypography(it)
                                            }
                                        } ?: mTableCell { "" }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

    private fun RBuilder.elaStatus() =
        mGridContainer2(direction = MGridDirection.row) {
            mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                mCard {
                    css(GlobalStyles.card)
                    mCardContent {
                        css(GlobalStyles.cardBoxContent)
                        mListSubheader(heading = "ELA Rollout")
                        styledDiv {
                            css { padding(0.spacingUnits) }
                            mList {
                                attrs.dense = true
                                css { padding(0.spacingUnits) }
                                mListItem(
                                    primaryText = "Hochgeladen am",
                                    secondaryText = state.elaDeployed?.deployedUpdates?.last()?.timestamp?.toText()
                                        ?: "-"
                                ) { attrs.divider = false }
                                mListItem(
                                    primaryText = "Update Typ",
                                    secondaryText = state.elaDeployed?.deployedUpdates?.last()?.timestamp?.toText()
                                        ?: "-"
                                ) { attrs.divider = false }
                                mListItem(
                                    primaryText = "Software Version",
                                    secondaryText = state.elaDeployed?.deployedUpdates?.last()?.timestamp?.toText()
                                        ?: "-"
                                ) { attrs.divider = false }
                                mListItem(
                                    primaryText = "INI Version",
                                    secondaryText = state.elaDeployed?.deployedUpdates?.last()?.timestamp?.toText()
                                        ?: "-"
                                ) { attrs.divider = false }
                                mListItem(
                                    primaryText = "MP3 Version",
                                    secondaryText = state.elaDeployed?.deployedUpdates?.last()?.timestamp?.toText()
                                        ?: "-"
                                ) { attrs.divider = false }
                            }
                        }
                    }
                }
            }
            state.elaStatistics?.outOfDateId?.forEach {
                mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                    mLink(
                        hRefOptions = HRefOptions(
                            href = "../../devices/ivantoConnect/cpuid/${it}"
                        ),
                        underline = MLinkUnderline.none
                    ) {
                        mAlert(
                            variant = MAlertVariant.filled,
                            severity = MAlertSeverity.warning,
                            message = "Fahrzeug mit der CPU ID $it ist nicht mehr aktuell."
                        )
                    }
                }
            }
            if (state.distribution != null)
                mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                    mCard {
                        css(GlobalStyles.card)
                        mCardContent {
                            css(GlobalStyles.cardChartContent)
                            chart(
                                type = "PieChart",
                                titleLabel = "Aktualität",
                                columns = arrayOf(
                                    Column(type = "string", label = "Status"),
                                    Column(type = "number", label = "Anzahl")
                                ),
                                rows = state.distribution,
                                legendPosition = "bottom"
                            )
                        }
                    }
                }
        }

    private fun RBuilder.elaSettings() =
        mGridContainer2(direction = MGridDirection.row, alignItems = MGridAlignItems.baseline) {
            mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                mCard {
                    css(GlobalStyles.card)
                    mCardContent {
                        css(GlobalStyles.cardContent)
                        mListSubheader(heading = "Einstellungen")
                        mDivider { }
                        styledDiv {
                            css { padding(2.spacingUnits) }
                            styledDiv {
                                css {
                                    padding(0.spacingUnits)
                                    justifyItems = JustifyItems.center
                                    display = Display.flex
                                    alignItems = Align.flexStart
                                }
                                mTypography(
                                    text = "Paket",
                                    variant = MTypographyVariant.caption
                                ) {
                                    css { display = Display.block; marginBottom = 1.spacingUnits }
                                }
                            }
                            styledInput {
                                css {
                                    padding(2.spacingUnits)
                                    width = 100.pct
                                    border = "2px dashed #eeeeee"
                                    borderRadius = 3.px
                                }
                                attrs.id = "elaform"
                                attrs.name = "file"
                                attrs.type = InputType.file
                                attrs.accept = ".zip"
                                attrs.multiple = false
                                attrs.onChangeFunction = { event ->
                                    var fileEvent = event.target as HTMLInputElement
                                    var files = fileEvent.files
                                    files?.let {
                                        setState {
                                            file = if (it.length >= 1) it[0] else null
                                        }
                                    }
                                }
                            }
                            if (state.publishResponse != "") {
                                if (state.publishResponse != "OK") {
                                    mTypography(
                                        text = state.publishResponse,
                                        variant = MTypographyVariant.body2,
                                        color = MTypographyColor.error,
                                        align = MTypographyAlign.left
                                    ) { css { paddingTop = 1.spacingUnits } }
                                }
                            }
                            mTypography(
                                text = "Die Datei sollte das folgende Format haben: .zip",
                                variant = MTypographyVariant.caption,
                                color = MTypographyColor.textSecondary
                            ) { css { display = Display.block; marginTop = 2.spacingUnits } }
                        }
                    }
                    mDivider { }
                    mCardActions {
                        css { padding(2.spacingUnits) }
                        mGridItem2(
                            MGridBreakpoints2(MGridSize2.Auto)
                                .down(Breakpoint.xs, MGridSize2.Cells12)
                        ) {
                            mButton(
                                caption = "Hochladen",
                                color = MColor.secondary,
                                variant = MButtonVariant.contained,
                                disabled = state.file == null,
                                onClick = { event ->
                                    handleSubmit(event)
                                }
                            ) {
                                attrs.disableElevation = true
                                attrs.fullWidth = true
                            }
                        }
                    }
                }
            }
        }

    private fun RBuilder.elaHistory(columns: List<de.geomobile.frontend.utils.grid.Column>, rowData: List<Row>) =
        mGridContainer2(direction = MGridDirection.row) {
            mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                mCard {
                    css(GlobalStyles.card)
                    mCardContent {
                        css(GlobalStyles.cardContent)
                        mListSubheader(heading = "Update Historie")
                        grid(
                            columns = columns,
                            rows = rowData
                        ) {
                            tableFormat()
                            tableHeaderRow()
                        }
                    }
                }
            }
        }
}