package de.geomobile.frontend.utils

import de.geomobile.common.feature.Feature
import de.geomobile.common.feature.FeatureGroup
import de.geomobile.frontend.UserStore
import react.RBuilder

inline fun hasFeature(vararg features: Feature) = UserStore.features.map { it.id }.containsAll(features.map { it.id })
inline fun isTrial(vararg features: Feature): Boolean =
    UserStore.features.filter { userFeature -> features.any { it.id == userFeature.id } }.all { it.testVersion }

fun RBuilder.withFeature(
    vararg features: Feature,
    handler: RBuilder.() -> Unit,
) {
    if (hasFeature(*features))
        handler()
}

fun RBuilder.withFeature(
    check: FeatureCheck,
    handler: RBuilder.() -> Unit,
) {
    if (check.check(UserStore.features))
        handler()
}

interface FeatureCheck {
    fun check(features: Set<Feature>): Boolean
}

infix fun Feature.or(second: Feature) =
    FeatureOrCheck(FeatureSingleCheck(this), FeatureSingleCheck(second))

infix fun Feature.and(second: Feature) =
    FeatureOrCheck(FeatureSingleCheck(this), FeatureSingleCheck(second))

infix fun FeatureCheck.or(second: Feature) =
    FeatureOrCheck(this, FeatureSingleCheck(second))

infix fun FeatureCheck.and(second: Feature) = FeatureOrCheck(this, FeatureSingleCheck(second))

infix fun Feature.or(second: FeatureCheck) =
    FeatureOrCheck(FeatureSingleCheck(this), second)

infix fun Feature.and(second: FeatureCheck) = FeatureOrCheck(FeatureSingleCheck(this), second)

infix fun FeatureCheck.or(second: FeatureCheck) =
    FeatureOrCheck(this, second)

infix fun FeatureCheck.and(second: FeatureCheck) = FeatureOrCheck(this, second)

data class FeatureUnit(val check: Boolean) : FeatureCheck {
    override fun check(features: Set<Feature>) = check
}

class FeatureOrCheck(
    val first: FeatureCheck,
    val second: FeatureCheck,
) : FeatureCheck {
    override fun check(features: Set<Feature>) = first.check(features) || second.check(features)
}

class FeatureAndCheck(
    val first: FeatureCheck,
    val second: FeatureCheck,
) : FeatureCheck {
    override fun check(features: Set<Feature>) = first.check(features) && second.check(features)
}

class FeatureSingleCheck(
    val feature: Feature,
) : FeatureCheck {
    override fun check(features: Set<Feature>) = feature.id in features.map { it.id }
}

val FeatureGroup.any: FeatureCheck get() = features.fold(FeatureUnit(false) as FeatureCheck) { acc, feature -> acc or feature }