package de.geomobile.frontend.utils

interface TableDisplayable {
    fun asList() : List<TableDisplayableContent>

    var enabled: Boolean?
}

data class TableDisplayableContent(
    val enabledIcon: String? = null,
    val disabledIcon: String? = null,
    val key: String,
    val value: Any?
)
