package de.geomobile.frontend.features.device.detail

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardActionArea
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.list.mList
import com.ccfraser.muirwik.components.list.mListItem
import com.ccfraser.muirwik.components.list.mListItemText
import com.ccfraser.muirwik.components.transitions.mCollapse
import de.geomobile.common.portalmodels.*
import de.geomobile.common.time.LocalDateTime
import de.geomobile.frontend.api.TopicSession
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.portalWebSocketApi
import de.geomobile.frontend.utils.CComponent
import de.geomobile.frontend.utils.diffString
import de.geomobile.frontend.utils.mCardHeaderExtended
import de.geomobile.frontend.utils.timerWrapper
import kotlinx.browser.localStorage
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.*
import kotlinx.serialization.SerializationException
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.builtins.MapSerializer
import kotlinx.serialization.builtins.serializer
import kotlinx.serialization.json.Json
import react.RBuilder
import react.RProps
import react.RState
import react.setState
import styled.css
import styled.styledDiv
import kotlin.math.round

fun RBuilder.passengerLive(
    id: Int,
    passengerExpanded: Boolean = false,
    onPassengerExpanded: () -> Unit,
    internal: Boolean = false
) = child(PassengerLiveComponent::class) {
    key = id.toString()
    attrs.id = id
    attrs.passengerExpanded = passengerExpanded
    attrs.onPassengerExpanded = onPassengerExpanded
    attrs.internal = internal
}

class PassengerLiveComponent : CComponent<PassengerLiveComponent.Props, PassengerLiveComponent.State>() {

    private var r2pSession: TopicSession? = null
    private var geoSession: TopicSession? = null
    private var isMounted = false

    interface Props : RProps {
        var id: Int
        var passengerExpanded: Boolean
        var onPassengerExpanded: () -> Unit
        var internal: Boolean
    }

    class State(
        var receivedValues: Boolean = false,
        var simpleCount: DevicePassengerStatisticDTO? = null,
        var r2pCount: DevicePassengerStatisticDTO? = null,
        var geoCount: Map<String, List<PassengerCountingData>> = mapOf(),
        var occupancy: OccupancyLevel? = null,
        var calibration: R2PWifiCalibrationDTO? = null,
        var capacitySet: Boolean = false,
        var vehicleProfile: VehicleProfileDTO? = null
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        isMounted = true

        launch {
            if (isMounted) {
                //get company
                val company = withContext(Dispatchers.Default) {
                    portalRestApi.get("/device/${props.id}/company", CompanySmall.serializer())
                }

                //get calib factor
                val wifiCalibration: R2PWifiCalibrationDTO? = withContext(Dispatchers.Default) {
                    try {
                        portalRestApi.get(
                            "/companyprofile/${company.id}/r2pWifiCalibrationFactor",
                            R2PWifiCalibrationDTO.serializer()
                        )
                    } catch (e: SerializationException) {
                        // if no calibration factor
                        null
                    }
                }

                // get vehicle profile
                val profile = withContext(Dispatchers.Default) {
                    portalRestApi.get("/device/${props.id}/vehicleProfile", VehicleProfileDTO.serializer())
                }

                setState {
                    this.capacitySet = profile.seatingCapacity != null && profile.standingCapacity != null
                    this.calibration = wifiCalibration
                    this.receivedValues = true
                    this.vehicleProfile = profile
                }
            }
        }

        r2pSession?.close()
        r2pSession = portalWebSocketApi.subscribe("/device/passenger", mapOf("id" to props.id.toString()))
        r2pSession?.connect {
            onmessage = {
                if (isMounted) {
                    val list = Json.decodeFromString(DevicePassengerStatisticsDTO.serializer(), it).statistics

                    val simpleCount =
                        list.firstOrNull { (it.source == PassengerCountSource.SIMPLE) || (it.source == PassengerCountSource.SIMPLE_CALIB) }

                    val r2pCount = list.firstOrNull { it.source == PassengerCountSource.R2P }

                    setState {
                        this.simpleCount = simpleCount
                        this.r2pCount = r2pCount
                    }
                }
            }
        }
        geoSession?.close()
        geoSession = portalWebSocketApi.subscribe("/device/ibisip/passenger", mapOf("id" to props.id.toString()))
        geoSession?.connect {
            onmessage = {
                if (isMounted) {
                    val list = Json.decodeFromString(MapSerializer(String.serializer(), ListSerializer(PassengerCountingData.serializer())), it)
                    setState {
                        geoCount = list
                    }
                }
            }
        }
    }

    override fun componentWillUnmount() {
        isMounted = false
        r2pSession?.close()
        geoSession?.close()
    }

    fun extractPassengerCount(data: Map<String,List<PassengerCountingData>>): Int {
        val totalInput = data.values.map {
            it.map {
                it.entries
                    .filter { it.quality == PassengerCountingData.Entry.Quality.REGULAR }
                    .map { it.enter }
            }
        }.flatten().flatten().sumOf { it }
        val totalOutput = data.values.map {
            it.map {
                it.entries
                    .filter { it.quality == PassengerCountingData.Entry.Quality.REGULAR }
                    .map { it.exit }
            }
        }.flatten().flatten().sumOf { it }

        return maxOf(totalInput - totalOutput, 0)
    }

    override fun RBuilder.render() {
        val passengerCount = state.r2pCount ?: state.simpleCount
        val pass = extractPassengerCount(state.geoCount)
        val capacity = when (state.vehicleProfile?.passengerLimitOption) {
            PassengerLimitOption.SEATED_STANDING -> (state.vehicleProfile?.seatingCapacity ?: 0) + (state.vehicleProfile?.standingCapacity ?: 0)
            PassengerLimitOption.ONLY_SEATED -> state.vehicleProfile?.seatingCapacity ?: 0
            PassengerLimitOption.ONLY_STANDING -> state.vehicleProfile?.standingCapacity ?: 0
            else -> 0
        }
        val occupancy = if(capacity == 0) 0.0 else pass.div(capacity.toDouble())
        val lowerLimitInt = state.vehicleProfile?.lowerPassengerLimit ?: 33
        val upperLimitInt = state.vehicleProfile?.upperPassengerLimit ?: 66
        val lowerLimit = lowerLimitInt.div(100.0)
        val upperLimit = upperLimitInt.div(100.0)
        val geoOccupancy = when {
            occupancy < lowerLimit -> OccupancyLevel.LEVEL_1
            occupancy >= lowerLimit -> OccupancyLevel.LEVEL_2
            occupancy >= upperLimit -> OccupancyLevel.LEVEL_3
            else -> null
        }
        val occupancyLevel = geoOccupancy ?: passengerCount?.occupancy


        mCard {
            mCardActionArea(
                onClick = { props.onPassengerExpanded() },
            ) {
                attrs.component = "div"
                mCardHeaderExtended(
                    title = "Fahrzeugauslastung",
                    action = mIconButtonNoTranslate(
                        if (props.passengerExpanded) "expand_less" else "expand_more",
                        onClick = { event ->
                            run {
                                props.onPassengerExpanded()
                                event.stopPropagation()
                            }
                        },
                        addAsChild = false
                    ) {
                        attrs.component = "div"
                    }
                ) {
                    val infoText: String
                    var icon = "people"
                    val iconColor: Color

                    when {
                        state.receivedValues == false -> {
                            icon = "people_outline"
                            iconColor = Color.grey
                            infoText = "Laden..."
                        }

                        occupancyLevel == null -> {
                            icon = "people_outline"
                            iconColor = Color.grey
                            infoText = "Fahrgastanzahl nicht verfügbar"
                        }

                        !state.capacitySet -> {
                            icon = "people_outline"
                            iconColor = Color.grey
                            infoText = "Kein Fahrzeugprofil vorhanden"
                        }

                        occupancyLevel == OccupancyLevel.LEVEL_1 -> {
                            iconColor = Color.green
                            infoText = "Unter $lowerLimitInt% Auslastung"
                        }

                        occupancyLevel == OccupancyLevel.LEVEL_2 -> {
                            iconColor = Color.orange
                            infoText = "Auslastung $lowerLimitInt%-$upperLimitInt%"
                        }

                        occupancyLevel == OccupancyLevel.LEVEL_3 -> {
                            iconColor = Color.red
                            infoText = "Über $upperLimitInt% Auslastung"
                        }

                        else -> {
                            iconColor = Color.green
                            infoText = "Unter $lowerLimitInt% Auslastung"
                        }
                    }

                    attrs.avatar = mTooltip(infoText) {
                        mAvatar {
                            mIconButtonNoTranslate(
                                icon,
                                iconColor = MIconColor.inherit,
                                addAsChild = true
                            ) {
                                css { backgroundColor = iconColor; color = Color.white }
                            }
                        }
                    }
                }
            }
            mCollapse(props.passengerExpanded) {
                mDivider {}
                mCardContent {
                    if (props.internal) {
                        val showSimpleCountDetails = state.simpleCount != null
                        val showOccupancyPercentage = passengerCount?.occupancyPercentage != null

                        if (!showSimpleCountDetails && !showOccupancyPercentage) {
                            mTypography(
                                text = "Keine internen Daten verfügbar",
                                variant = MTypographyVariant.body1,
                                align = MTypographyAlign.center
                            ) { css { padding(1.spacingUnits) } }
                        }

                        if (showOccupancyPercentage) {
                            val percentage = round(passengerCount!!.occupancyPercentage!! * 1000) / 10
                            passengerCountHalfRow(
                                label = "Auslastung",
                                stateHandler = {
                                    mTypography(
                                        text = "$percentage %",
                                        variant = MTypographyVariant.subtitle2
                                    )
                                }
                            )
                        }

                        if (showSimpleCountDetails) {
                            val calibValue = state.calibration?.factor ?: if (state.receivedValues) 0.142 else null

                            // TODO: Clean up
//                        passengerCountHalfRow(
//                            label = "Calibration used",
//                            // timestamp = state.calibration?.timestamp,
//                            stateHandler = {
//                                mTypography(
//                                    // round value to 3 decimal places (if values have been loaded but there is no factor -> use standard factor)
//                                    text = if (state.simpleCount!!.source == PassengerCountSource.SIMPLE_CALIB) "new (gps)" else "old",
//                                    variant = MTypographyVariant.body1
//                                )
//                            }
//                        )

                            if (state.simpleCount!!.source == PassengerCountSource.SIMPLE_CALIB) {
                                passengerCountHalfRow(
                                    label = "Anzahl Datenpunkte für Kalib.",
                                    stateHandler = {
                                        mTypography(
                                            text = "${state.simpleCount!!.numUsedValues} ",
                                            variant = MTypographyVariant.subtitle2
                                        )
                                    }
                                )
                                passengerCountHalfRow(
                                    label = "Neuer Kalibrierungsfaktor",
                                    stateHandler = {
                                        mTypography(
                                            text = state.simpleCount!!.factor?.let { "${round(it * 10000) / 10000}" }
                                                ?: "-",
                                            variant = MTypographyVariant.subtitle2
                                        )
                                    }
                                )
                                passengerCountHalfRow(
                                    label = "Fahrgastanzahl (alt)",
                                    stateHandler = {
                                        mTypography(
                                            text = calibValue?.let { "${(state.simpleCount!!.scan1?.times(it))?.toInt()}" }
                                                ?: "-",
                                            variant = MTypographyVariant.subtitle2
                                        )
                                    }
                                )
                            }
                            passengerCountHalfRow(
                                label = "Alter Kalibrierungsfaktor",
                                stateHandler = {
                                    mTypography(
                                        text = calibValue?.let { "${round(it * 10000) / 10000}" } ?: "-",
                                        variant = MTypographyVariant.subtitle2
                                    )
                                }
                            )
                            passengerCountHalfRow(
                                label = "SCAN 1",
                                stateHandler = {
                                    mTypography(
                                        text = "${state.simpleCount!!.scan1}",
                                        variant = MTypographyVariant.subtitle2
                                    )
                                }
                            )
                            passengerCountHalfRow(
                                label = "SCAN 2",
                                stateHandler = {
                                    mTypography(
                                        text = "${state.simpleCount!!.scan2}",
                                        variant = MTypographyVariant.subtitle2
                                    )
                                }
                            )
                            passengerCountHalfRow(
                                label = "SCAN intersection",
                                stateHandler = {
                                    mTypography(
                                        text = "${state.simpleCount!!.scan_intersection}",
                                        variant = MTypographyVariant.subtitle2
                                    )
                                }
                            )
                            passengerCountHalfRow(
                                label = "SCAN inter. filtered",
                                stateHandler = {
                                    mTypography(
                                        text = "${state.simpleCount!!.scan_intersection_filtered}",
                                        variant = MTypographyVariant.subtitle2
                                    )
                                }
                            )
                        }
                    } else {
                        passengerCountRow(
                            label = if (state.r2pCount != null) "Externes Zählsystem (R2P)" else "Externes Zählsystem",
                            timestamp = state.r2pCount?.timestamp?.timestamp,
                            stateHandler = { formatPassengerCount(state.r2pCount?.count) }
                        )
                        passengerCountRow(
                            label = "Internes Zählsystem",
                            timestamp = state.simpleCount?.timestamp?.timestamp,
                            stateHandler = { formatPassengerCount(state.simpleCount?.count) }
                        )
                    }
                    if(state.geoCount.isNotEmpty()) {

                        passengerCountRow(
                            label = "Geomobile Zählsystem",
                            stateHandler = { formatPassengerCount(pass) }
                        )
                        passengerCountRow(
                            label = "Geomobile Zählsystem Auslastung",
                            stateHandler = {
                                mTypography(
                                    text = when {
                                        occupancy < lowerLimit -> "Geringe Auslastung (<$lowerLimitInt%)"
                                        occupancy >= lowerLimit -> "Mittlere Auslastung ($lowerLimitInt%-$upperLimitInt%)"
                                        occupancy >= upperLimit -> "Hohe Auslastung (>$upperLimitInt%)"
                                        else -> "Auslastung Unbekannt"
                                    },
                                    variant = MTypographyVariant.subtitle2
                                )
                            }
                        )
                        state.geoCount.forEach { (sensor, values) ->
                            var passengerEnter = 0
                            var passengerExit = 0
                            values.forEach {
                                it.entries.filter { it.quality == PassengerCountingData.Entry.Quality.REGULAR }.forEach {
                                    passengerEnter += it.enter
                                    passengerExit += it.exit
                                }
                            }
                            styledDiv {
                                css { paddingLeft = 1.spacingUnits }
                                passengerCountRow(
                                    label = "Sensor: $sensor",
                                    stateHandler = {
                                        formatPassengerCount(passengerEnter, true)
                                        formatPassengerCount(passengerExit, false)
                                    }
                                )
                            }
                        }
                    }
                }
            }
        }
    }
}

private fun RBuilder.formatPassengerCount(value: Int?, isPassIn: Boolean? = null) {
    val postfix = value?.let { if (it == 1) "Fahrgast" else "Fahrgäste" } ?: ""
    val suffix = isPassIn?.let { if (it) "eingestiegen" else "ausgestiegen" } ?: ""
    mTypography(
        text = value?.let { "$it $postfix $suffix" } ?: "-",
        variant = MTypographyVariant.subtitle2
    )
}

private fun RBuilder.passengerCountHalfRow(
    label: String,
    stateHandler: RBuilder.() -> Unit,
) {
    mList {
        attrs.dense = true
        attrs.disablePadding = true

        mListItem {
            attrs.button = false
            attrs.divider = true

            mListItemText {
                mTypography(
                    text = label,
                    variant = MTypographyVariant.caption,
                )
                stateHandler()
            }
        }
    }
}

private fun RBuilder.passengerCountRow(
    label: String,
    timestamp: LocalDateTime? = null,
    stateHandler: RBuilder.() -> Unit,
) {

    mList {
        attrs.dense = true
        attrs.disablePadding = true

        mListItem {
            attrs.button = false
            attrs.divider = true

            mListItemText {
                mTypography(
                    text = label,
                    variant = MTypographyVariant.caption,
                )
                stateHandler()
                if (timestamp != null)
                    styledDiv {
                        timerWrapper(1000) {
                            val diff = (LocalDateTime.now() - timestamp).diffString()
                            mTypography(
                                text = "Empfangen vor $diff",
                                variant = MTypographyVariant.caption
                            ) { css { color = Color.grey } }
                        }
                    }
            }
        }
    }
}


