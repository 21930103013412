package de.geomobile.common.filter

import kotlinx.serialization.Transient

data class FilterMatcher<ELEMENT>(
    val filterRules: FilterRules,
    val filters: Map<String, Filter<ELEMENT, *>>
) {

    private fun <ELEMENT, VALUE> matcher(
        filter: Filter<ELEMENT, VALUE>,
        rule: Filter.Rule
    ): (ELEMENT) -> Boolean {
        val operator: Filter.Operator<VALUE> =
            filter.operators.single { operator -> operator.id == rule.operatorId }
        return { element: ELEMENT -> operator.eval(rule.ref, filter.elementToValue(element)) }
    }

    @Transient
    private val matchers: List<(ELEMENT) -> Boolean> by lazy {
        if (!filterRules.isValid(filters)) return@lazy listOf({ _: ELEMENT -> false })

        filterRules.rules.map { rule ->
            when (val filter = filters.getValue(rule.filterId)) {
                is FilterLong -> matcher(filter, rule.rule)
                is FilterDouble -> matcher(filter, rule.rule)
                is FilterString -> matcher(filter, rule.rule)
                is FilterEnumerableInt -> matcher(filter, rule.rule)
                is FilterEnumerableString -> matcher(filter, rule.rule)
            }
        }
    }

    fun matches(element: ELEMENT): Boolean = matchers.all { matcher -> matcher(element) }

}