package de.geomobile.frontend.features.admin.users

import de.geomobile.common.permission.Permissions
import de.geomobile.common.portalmodels.UserDTO
import de.geomobile.frontend.utils.TimeUnit
import de.geomobile.frontend.utils.authorize
import de.geomobile.frontend.utils.toText
import react.RBuilder
import react.RComponent
import react.RProps
import react.RState
import react.router.dom.route
import react.router.dom.switch

fun RBuilder.adminUsers(path: String) = child(AdminUsers::class) {
    attrs.path = path
}

class AdminUsers : RComponent<AdminUsers.Props, RState>() {

    interface Props : RProps {
        var path: String
    }

    override fun RBuilder.render() {
        switch {

            authorize(Permissions.UserManagement.usersEdit) {

                route<RProps>("${props.path}/create", exact = true) { routeProps ->
                    adminUserCreate(
                        cancel = { routeProps.history.push(props.path) },
                        created = { routeProps.history.push("${props.path}/$it") }
                    )
                }

                route<AdminUserDetail.Props>("${props.path}/:userId/edit", exact = true) { routeProps ->
                    val userId = routeProps.match.params.userId
                    adminUserEdit(
                        userId,
                        goBack = { routeProps.history.push("${props.path}/$userId") },
                        onDeleted = { routeProps.history.push(props.path) }
                    )
                }

            }

            route<RProps>(props.path, exact = true) { routeProps ->
                adminUserList(userSelected = { routeProps.history.push("${props.path}/$it") },
                    createUser = { routeProps.history.push("${props.path}/create") }
                )
            }

            route<AdminUserDetail.Props>("${props.path}/:userId", exact = true) { routeProps ->
                val userId = routeProps.match.params.userId
                adminUserDetail(
                    userId,
                    goBack = { routeProps.history.push(props.path) },
                    edit = { routeProps.history.push("${props.path}/$userId/edit") }
                )
            }
        }
    }
}

fun UserDTO.State.toText(short: Boolean = false): String = when (this) {
    UserDTO.State.Created -> "Erstellt"
    is UserDTO.State.Invited ->
        if (short) "Eingeladen"
        else "Eingeladen am ${timestamp.toText(from = TimeUnit.MINUTE)}"
    UserDTO.State.Active -> "Aktiv"
    UserDTO.State.Blocked -> "Gesperrt"
}
