package de.geomobile.frontend.features.vehicleProfile

import de.geomobile.common.permission.Permissions
import de.geomobile.frontend.utils.authorize
import react.RBuilder
import react.RComponent
import react.RProps
import react.RState
import react.router.dom.route
import react.router.dom.switch

fun RBuilder.vehicleProfiles(
    path: String
) = child(VehicleProfiles::class) {
    attrs.path = path
}

class VehicleProfiles : RComponent<VehicleProfiles.Props, RState>() {

    interface Props : RProps {
        var path: String
    }

    override fun RBuilder.render() {
        switch {
            authorize(Permissions.VehicleProfileManagement.profilesEdit) {
                route<RProps>(
                    "${props.path}/create",
                    exact = true
                ) { routeProps ->
                    vehicleProfilesCreate(
                        profileId = null,
                        cancel = { routeProps.history.push(props.path) },
                        created = { routeProps.history.push(props.path) }
                    )
                }
                route<VehicleProfilesDetailEdit.Props>(
                    "${props.path}/create/:profileId",
                    exact = true
                ) { routeProps ->
                    val profileId = routeProps.match.params.profileId
                    vehicleProfilesCreate(
                        profileId = profileId,
                        cancel = { routeProps.history.push(props.path) },
                        created = { routeProps.history.push(props.path) }
                    )
                }
                route<VehicleProfilesDetail.Props>(
                    "${props.path}/:profileId/edit",
                    exact = true
                ) { routeProps ->
                    val profileId = routeProps.match.params.profileId
                    vehicleProfilesEdit(
                        profileId,
                        goBack = { routeProps.history.push(props.path) },
                        onDeleted = { routeProps.history.push(props.path) }
                    )
                }
            }
            route<RProps>(
                props.path,
                exact = true
            ) { routeProps ->
                vehicleProfilesList(
                    profileSelected = { routeProps.history.push("${props.path}/$it") },
                    createProfile = {
                        if (it != null) {
                            routeProps.history.push("${props.path}/create/$it")
                        } else {
                            routeProps.history.push("${props.path}/create")
                        }
                    }
                )
            }
            route<VehicleProfilesDetail.Props>(
                "${props.path}/:profileId",
                exact = true
            ) { routeProps ->
                val profileId = routeProps.match.params.profileId
                vehicleProfilesDetail(
                    profileId,
                    goBack = { routeProps.history.push(props.path) },
                    edit = { routeProps.history.push("${props.path}/$profileId/edit") }
                )
            }
            route<VehicleProfilesDetail.Props>(
                "${props.path}/:profileId/view",
                exact = true
            ) { routeProps ->
                val profileId = routeProps.match.params.profileId
                vehicleProfilesDetail(
                    profileId,
                    goBack = { routeProps.history.goBack() },
                    edit = null
                )
            }
        }
    }
}