package de.geomobile.frontend.features.portalSettings

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonSize
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.form.MFormControlMargin
import com.ccfraser.muirwik.components.form.MFormControlVariant
import com.ccfraser.muirwik.components.form.margin
import com.ccfraser.muirwik.components.list.*
import com.ccfraser.muirwik.components.menu.mMenuItem
import de.geomobile.common.permission.Permissions
import de.geomobile.common.portalmodels.Company
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.spacer
import de.geomobile.frontend.utils.*
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.*
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.json.Json
import portalmodels.GenericResponseDTO
import portalmodels.IbisTelegramSettingsDTO
import portalmodels.SFTPEncryption
import react.RBuilder
import react.RProps
import react.RState
import react.setState
import styled.StyledElementBuilder
import styled.css
import styled.styledDiv

fun RBuilder.ibisTelegrams() = child(PortalSettingsIbisTelegrams::class) {}

class PortalSettingsIbisTelegrams : CComponent<PortalSettingsIbisTelegrams.Props, PortalSettingsIbisTelegrams.State>() {

    private var fetchListJob: Job = Job()
    private var fetchIbisJob: Job = Job()
    private var setListJob: Job = Job()

    interface Props : RProps

    class State(
        var ibisSettingsList: MutableList<IbisTelegramSettingsDTO> = mutableListOf(),
        var companies: List<String> = listOf(),
        var newTelegram: String? = null,
        var saveOK: Boolean = false,
        var errorText: String? = null
    ) : RState

    init {
        state = State()
    }

    private fun fetchIbisTelegrams() {
        fetchIbisJob.cancel()
        fetchIbisJob = launch {
            val settings = portalRestApi.get("/portalsettings/ibis/getlist/all", ListSerializer(IbisTelegramSettingsDTO.serializer()))
            val sorted = settings.sortedBy { it.telegram }

            setState {
                ibisSettingsList = sorted.toMutableList()
            }
        }
    }

    private fun fetchCompanies() {
        launch {
            val companies = portalRestApi.get("/admin/companies", ListSerializer(Company.serializer()))
            setState {
                this.companies = companies.map { it.id }
            }
        }
    }

    private fun setIbisTelegrams() {
        launch {
            val response = withContext(Dispatchers.Default) {
                val body = Json.encodeToString(
                    ListSerializer(IbisTelegramSettingsDTO.serializer()),
                    state.ibisSettingsList
                )
                portalRestApi.put("/portalsettings/ibis/setlist", body, GenericResponseDTO.serializer())
            }

            if (!response.isError)
                setState {
                    saveOK = true
                }
        }.invokeOnCompletion {
            fetchIbisTelegrams()
        }
    }

    override fun componentDidMount() {
        fetchCompanies()
        fetchIbisTelegrams()
    }

    override fun RBuilder.render() {
        authorize(Permissions.AdminPermissions.internalAccess) {
            spacer()
            mGridContainer2(direction = MGridDirection.row) {
                if (isAuthorized(Permissions.AdminPermissions.internalAccess)) {
                    mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                        detail()
                    }
                }
            }
        }
    }

    private fun StyledElementBuilder<MGridProps2>.detail() {
        mGridContainer2(direction = MGridDirection.row) {
            mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                mCard {
                    css(GlobalStyles.card)
                    mCardContent {
                        css(GlobalStyles.cardContent)
                        mListSubheader(heading = "VDV 300 Telegramme")
                        mDivider {}
                        styledDiv {
                            css { padding(2.spacingUnits) }
                            mButton(
                                caption = "Übernehmen",
                                color = MColor.secondary,
                                variant = MButtonVariant.contained,
                                onClick = {
                                    setIbisTelegrams()
                                }
                            ) {
                                attrs.disableElevation = true
                            }
                            mButton(
                                caption = "Erstellen",
                                color = MColor.primary,
                                variant = MButtonVariant.contained,
                                onClick = {
                                    setState {
                                        newTelegram?.let { new ->
                                            if (!ibisSettingsList.any { it.telegram == newTelegram && it.companyId == "ALLE" } ) {
                                                errorText = null
                                                ibisSettingsList.add(
                                                    IbisTelegramSettingsDTO(
                                                        companyId = "ALLE",
                                                        telegram = new,
                                                        active = false
                                                    )
                                                )
                                                ibisSettingsList.sortBy { it.telegram }
                                            } else {
                                                errorText = "Dieses Telegram existiert bereits für ALLE Unternehmen."
                                            }
                                        }
                                    }
                                }
                            ) {
                                css { marginLeft = 1.spacingUnits }

                                attrs.disableElevation = true
                            }
                            if (state.saveOK)
                                mIconNoTranslate("check") {
                                    css {
                                        color = Color.green
                                    }
                                }
                            if (state.errorText != null) {
                                mIconNoTranslate("error") {
                                    css {
                                        color = Color.red
                                    }
                                }
                                mTypography(state.errorText!!, MTypographyVariant.body2) {
                                    css {
                                        color = Color.red
                                    }
                                }
                            }
                        }
                        mDivider {}
                        styledDiv {
                            css { padding(2.spacingUnits) }
                            mTextField(
                                label = "",
                                placeholder = "Neues Telegramm",
                                variant = MFormControlVariant.outlined,
                                onChange = { event ->
                                    setState {
                                        val name = event.targetInputValue as String
                                        if (name.isBlank())
                                            newTelegram = null
                                        else
                                            newTelegram = name
                                    }
                                }
                            ) { attrs.fullWidth = true; attrs.margin = MFormControlMargin.dense }
                        }

                        if (state.ibisSettingsList.isNotEmpty()) {
                            mDivider {}
                            styledDiv {
                                css { padding(0.spacingUnits, 2.spacingUnits) }
                                mList {
                                    css { padding(0.spacingUnits) }
                                    state.ibisSettingsList.forEachIndexed { idx, setting ->
                                        mListItem(
                                            divider = false,
                                            button = false,
                                            dense = true
                                        ) {
                                            attrs.disableGutters = true
                                            mSelect(
                                                variant = MFormControlVariant.outlined,
                                                value = setting.companyId,
                                                disabled = false,
                                                onChange = { event, _ ->
                                                    val newValue = event.targetValue as String
                                                    setState {
                                                        if(!ibisSettingsList.any{ it.telegram == setting.telegram && it.companyId == newValue }) {
                                                            errorText = null
                                                            ibisSettingsList[idx] = setting.copy(companyId = newValue)
                                                        } else {
                                                            errorText = "Dieses Telegram existiert bereits für $newValue."
                                                        }
                                                    }
                                                }
                                            ) {
                                                css {
                                                    marginTop = 1.spacingUnits
                                                    width = 320.px
                                                }

                                                attrs.margin = MFormControlMargin.dense.toString()

                                                for (mode in state.companies.plus("ALLE")) {
                                                    mMenuItem(primaryText = mode, value = mode)
                                                }
                                            }

                                            mListItemIcon {
                                                mCheckbox(
                                                    checked = setting.active,
                                                    onChange = { _, checked ->
                                                        setState {
                                                            ibisSettingsList[idx] = setting.copy(active = checked)
                                                            saveOK = false
                                                        }
                                                    }
                                                )
                                            }
                                            mListItemText(
                                                primary = setting.telegram
                                            )
                                            mListItemSecondaryAction {
                                                mIconButtonNoTranslate(
                                                    iconName = "delete",
                                                    size = MButtonSize.small,
                                                    color = MColor.inherit,
                                                    onClick = { _ ->
                                                        setState {
                                                            ibisSettingsList.removeAt(idx)
                                                        }
                                                    }
                                                ) {
                                                    css {
                                                        backgroundColor = Color.red; color = Color.white
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}