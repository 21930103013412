package de.geomobile.frontend.features.monitoring

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardActionArea
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.styles.Breakpoint
import com.ccfraser.muirwik.components.styles.up
import com.ccfraser.muirwik.components.transitions.mCollapse
import de.geomobile.frontend.currentTheme
import de.geomobile.frontend.utils.CComponent
import de.geomobile.frontend.utils.mCardHeaderExtended
import kotlinext.js.assign
import kotlinx.browser.localStorage
import kotlinx.css.*
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import org.w3c.dom.get
import org.w3c.dom.set
import react.RBuilder
import react.RProps
import react.RState
import react.dom.attrs
import styled.css
import styled.styledDiv
import styled.styledIframe

fun RBuilder.monitoringContent(
    monitoringDescription: String?,
    monitoringPage: String
) = child(MonitoringContent::class) {
    attrs.monitoringDescription = monitoringDescription
    attrs.monitoringPage = monitoringPage
}

class MonitoringContent : CComponent<MonitoringContent.Props, MonitoringContent.State>() {

    interface Props : RProps {
        var monitoringDescription: String?
        var monitoringPage: String
    }

    class State(
        var expand: Boolean = true,
    ) : RState

    init {
        state = State(
            expand = localStorage["monitoringContentExpand"]?.toBooleanStrictOrNull() ?: true
        )
    }

    override fun componentDidMount() {}

    private fun saveExpandState() {
        setState({
            assign(it) {
                expand = expand != true
            }
        }) {
            localStorage["monitoringContentExpand"] = Json.encodeToString(state.expand)
        }
    }

    override fun RBuilder.render() {
        styledDiv {
            css {
                display = Display.flex
                flexDirection = FlexDirection.column
                width = 100.pct
                media(currentTheme.breakpoints.up(Breakpoint.sm)) {
                    height = 100.vh - 64.px - 48.px
                }
                height = 100.vh - 56.px - 48.px
            }

            if (props.monitoringDescription?.isEmpty() != true) {
                styledDiv {
                    css { padding = 2.spacingUnits.toString() }
                    mCard {
                        mCardActionArea(
                            onClick = { saveExpandState() }
                        ) {
                            attrs.component = "div"
                            mCardHeaderExtended(
                                title = "Info",
                                action = mIconButtonNoTranslate(
                                    if (state.expand) "expand_less" else "expand_more",
                                    onClick = { event ->
                                        run {
                                            saveExpandState()
                                            event.stopPropagation()
                                        }
                                    },
                                    addAsChild = false
                                ) {
                                    attrs.component = "div"
                                }
                            )
                        }
                        mCollapse(state.expand) {
                            mDivider {}
                            mCardContent {
                                mTypography(
                                    variant = MTypographyVariant.body2,
                                    text = props.monitoringDescription,
                                    color = MTypographyColor.inherit,
                                    component = "pre"
                                )
                            }
                        }
                    }
                }
            }

            styledIframe {
                attrs {
                    height = "100%"
                    width = "100%"
                    src = props.monitoringPage
                }
                css {
                    border = "none"
                }
            }
        }
    }
}