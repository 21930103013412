package de.geomobile.frontend.features.device.detail

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardActionArea
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.form.MFormControlMargin
import com.ccfraser.muirwik.components.menu.mMenuItem
import com.ccfraser.muirwik.components.styles.Breakpoint
import com.ccfraser.muirwik.components.transitions.mCollapse
import de.geomobile.common.feature.Feature
import de.geomobile.common.feature.Features
import de.geomobile.common.permission.Permission
import de.geomobile.common.permission.Permissions
import de.geomobile.common.portalmodels.DeviceIdentifier
import de.geomobile.common.portalmodels.Product
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.device.detail.trialRibbon
import de.geomobile.frontend.features.device.detail.internal.*
import de.geomobile.frontend.features.device.detail.selftest.deviceSelfTest
import de.geomobile.frontend.features.device.deviceIdResolver
import de.geomobile.frontend.features.device.toDetailPath
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.utils.*
import hardware.HardwareFeature
import kotlinext.js.assign
import kotlinx.browser.document
import kotlinx.browser.localStorage
import kotlinx.browser.window
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import kotlinx.css.*
import kotlinx.serialization.builtins.MapSerializer
import kotlinx.serialization.builtins.serializer
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import org.w3c.dom.get
import org.w3c.dom.set
import react.RBuilder
import react.RProps
import react.RState
import react.router.dom.RouteResultHistory
import react.router.dom.redirect
import react.setState
import styled.css

fun RBuilder.deviceQuickCheck(
    identifier: DeviceIdentifier,
    routeProps: RouteResultHistory,
) = deviceIdResolver(identifier) { id ->
    child(DeviceQuickCheck::class) {
        attrs.identifier = id
        attrs.routeProps = routeProps
    }
}

class DeviceQuickCheck : CComponent<DeviceQuickCheck.Props, DeviceQuickCheck.State>() {

    interface Props : RProps {
        var identifier: DeviceIdentifier.Id
        var routeProps: RouteResultHistory
    }

    class State(
        var id: Int,
        var redirectProduct: String? = Product.ALL.readableName,
        var redirectSerial: String? = null,
    ) : RState

    init {
        state = State(id = 0)
    }

    override fun componentDidMount() {
        println("Mount OK")
        setState { id = props.identifier.id }
        window.scroll(0.0, 0.0)

            launch(Dispatchers.Default) {
                val serialnumber =
                    if(props.identifier.id == 0)
                        0
                    else
                        portalRestApi.get("/device/${props.identifier.id}/serialnumber", Int.serializer())

                setState {
                    this.redirectSerial = serialnumber.toString()
                }
            }
    }

    private fun RBuilder.deviceFeature(
        title: String,
        hardwareFeature: HardwareFeature?,
        trialFeature: Feature?,
        expandKey: String,
        vararg permissions: Permission,
        handler: RBuilder.() -> Unit
    ) {
        authorize(*permissions) {
            mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                mCard {
                    css(GlobalStyles.card)
                    css { position = Position.relative }
                    if (trialFeature != null && isTrial(trialFeature)) trialRibbon()
                    mCardActionArea {
                        attrs.component = "div"
                        mCardHeaderExtended(
                            title = title
                        )
                    }
                    mCollapse(true) {
                        mDivider {}
                        mCardContent {
                            css(GlobalStyles.cardBoxContent)
                            handler()
                        }
                    }
                }
            }
        }
    }

    override fun RBuilder.render() {
        val id = state.id

        document.onkeydown = {
            if (it.keyCode == 13) // User hits enter
                props.routeProps.push("/devices/quickcheck/${state.redirectProduct}/${state.redirectSerial}")
        }

        deviceDetailToolbar(state.id, props.routeProps.asDynamic().goBack)
        mGridContainer2(direction = MGridDirection.row) {
            mCard {
                css {
                    height = 80.px
                    width = 100.pct
                    maxWidth = 600.px
                    margin(1.spacingUnits)
                }
                mCardContent {
                    css {
                        padding(0.spacingUnits)
                        paddingLeft = 2.spacingUnits
                    }
                    mTextField(
                        label = "Seriennummer",
                        value = state.redirectSerial ?: "",
                        onChange = { event ->
                            val serial = event.targetValue as String
                            setState {
                                redirectSerial = serial
                            }
                        },
                    ) { css { width = 69.pct } }
//                    mTextFieldSelect(
//                        label = "Produkt",
//                        value = state.redirectProduct ?: Product.CONNECT.readableName,
//                        onChange = { event ->
//                            val product = event.targetValue as String
//                            setState {
//                                redirectProduct = product
//                                println(product)
//                            }
//                        },
//                    ) {
//                        css { width = 46.pct; marginLeft = 1.spacingUnits }
//                        Product.values().forEach { product ->
//                            mMenuItem(product.readableName, value = product.readableName)
//                        }
//                    }

                    mButton(
                        caption = "Los",
                        variant = MButtonVariant.contained,
                        color = MColor.primary,
                        onClick = {
                            props.routeProps.push("/devices/quickcheck/${state.redirectProduct}/${state.redirectSerial}")
                        }
                    ) {
                        css {
                            width = 23.pct
                            verticalAlign = VerticalAlign("bottom")
                            margin(1.spacingUnits)
                        }
                    }

                }
            }

            if(props.identifier.id.toString() != "0")
                deviceFeature(
                    title = "Verkabelungscheck",
                    hardwareFeature = HardwareFeature.CABLE_CHECK,
                    trialFeature = Features.DeviceFeatures.cableCheck,
                    expandKey = "cableCheckV2",
                    Permissions.DeviceManagement.cableCheck
                ) {
                    deviceCableCheckV2(id, isQuickCheck = true)
                }
        }
    }
}