package components

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonSize
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardContent
import de.geomobile.frontend.utils.*
import de.geomobile.frontend.utils.MGridJustify
import kotlinx.css.TextAlign
import kotlinx.css.boxShadow
import kotlinx.css.margin
import kotlinx.css.properties.BoxShadows
import kotlinx.css.textAlign
import react.RBuilder
import react.RComponent
import react.RProps
import react.RState
import styled.css

fun RBuilder.emptyView(
    boxShadow: Boolean = true,
    iconName: String = "info_outlined",
    title: String,
    caption: String,
    addButton: Boolean,
    actionBtnCaption: String? = null,
    handleAdd: (() -> Unit?)? = null,
) = child(EmptyView::class) {
    attrs.boxShadow = boxShadow
    attrs.iconName = iconName
    attrs.title = title
    attrs.caption = caption
    attrs.addButton = addButton
    attrs.actionBtnCaption = actionBtnCaption
    if (handleAdd != null) {
        attrs.handleAdd = handleAdd
    }
}

class EmptyView : RComponent<EmptyView.Props, EmptyView.State>() {

    interface Props : RProps {
        var boxShadow: Boolean
        var iconName: String
        var title: String
        var caption: String
        var addButton: Boolean
        var actionBtnCaption: String?
        var handleAdd: () -> Unit?
    }

    class State : RState

    override fun RBuilder.render() {
        mCard {
            if (!props.boxShadow)
                css { textAlign = TextAlign.center; boxShadow = BoxShadows.none }
            else
                css { textAlign = TextAlign.center }

            mCardContent {
                mGridContainer2(
                    direction = MGridDirection.row,
                    justify = MGridJustify.center,
                    alignItems = MGridAlignItems.center
                ) {
                    mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                        mIconNoTranslate(
                            iconName = props.iconName,
                            color = MIconColor.disabled
                        ) { css { margin(1.spacingUnits) } }
                        mTypography(
                            text = props.title,
                            align = MTypographyAlign.center,
                            variant = MTypographyVariant.h6,
                            color = MTypographyColor.primary,
                            component = "div"
                        )
                        mTypography(
                            text = props.caption,
                            align = MTypographyAlign.center,
                            variant = MTypographyVariant.subtitle2,
                            color = MTypographyColor.textSecondary,
                            component = "div"
                        )

                        if (props.addButton)
                            mButton(
                                caption = props.actionBtnCaption ?: "",
                                size = MButtonSize.small,
                                color = MColor.secondary,
                                variant = MButtonVariant.contained,
                                onClick = { props.handleAdd() }
                            ) {
                                css { margin(1.spacingUnits) }
                                attrs.disableElevation = true
                            }
                    }
                }
            }
        }
    }
}