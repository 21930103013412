package portalmodels

import kotlinx.serialization.Serializable

@Serializable
data class ProductVariantDTO(
    val id: Int,
    val materialNumber: Int,
    val revision: String,
    val name: String,
) {
    companion object {
        fun empty() =
            ProductVariantDTO(
                id = 0,
                materialNumber = 0,
                revision = "",
                name = ""
            )
    }
}

