package de.geomobile.frontend.features.device.detail

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.dialog.mDialog
import com.ccfraser.muirwik.components.dialog.mDialogActions
import com.ccfraser.muirwik.components.dialog.mDialogContent
import com.ccfraser.muirwik.components.form.MFormControlMargin
import com.ccfraser.muirwik.components.form.MFormControlVariant
import com.ccfraser.muirwik.components.form.margin
import com.ccfraser.muirwik.components.menu.mMenuItem
import com.ccfraser.muirwik.components.styles.Breakpoint
import com.ccfraser.muirwik.components.table.*
import de.geomobile.common.portalmodels.*
import de.geomobile.common.time.LocalDateTime
import de.geomobile.common.utils.nonStrictJson
import de.geomobile.frontend.api.TopicSession
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.portalWebSocketApi
import de.geomobile.frontend.utils.*
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.*
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.json.Json
import portalmodels.GenericResponseDTO
import portalmodels.IbisFunction
import portalmodels.IbisProfileDTO
import portalmodels.IbisProfileEntryDTO
import react.RBuilder
import react.RProps
import react.RState
import react.setState
import styled.css
import styled.styledDiv

fun RBuilder.ibisV18(
    id: Int,
    readOnly: Boolean = true
) = child(DeviceIbisV18::class) {
    key = id.toString()
    attrs.id = id
    attrs.readOnly = readOnly
}

class DeviceIbisV18 : CComponent<DeviceIbisV18.Props, DeviceIbisV18.State>() {

    private var session: TopicSession? = null
    private var isMounted: Boolean = false

    interface Props : RProps {
        var id: Int
        var readOnly: Boolean
    }

    class State(
        var telegrams: MutableMap<Pair<IbisFunction,String>, Row> = mutableMapOf(),
        var currentIbisProfileId: Int? = null,
        var currentIbisProfileEntries: Map<Pair<IbisFunction,String>, IbisProfileEntryDTO>? = null,
        var checkbox: Boolean? = null,
        var newProfileName: String? = null,
        var saveDestination: Int? = null,
        var company: CompanySmall? = null,
        var warnDialog: String? = null,
        var profiles: List<IbisProfileDTO> = listOf(),
        var deviceHasVehicleProfile: Boolean = false,
    ) : RState

    class Row(
        var telegram: DeviceIbisV18InfoDTO,
        var function: IbisFunction,
        var appMapping: Boolean,
        var portalMapping: Boolean,
        var timestamp: LocalDateTime? = null,
    )

    init {
        state = State()
    }

    override fun componentDidMount() {
        isMounted = true
        launch {
            if (isMounted) {
                var companyId = withContext(Dispatchers.Default) {
                    portalRestApi.get("/device/${props.id}/company", CompanySmall.serializer())
                }
                setState {
                    company = companyId
                }
            }
        }.invokeOnCompletion {
            fetchProfiles()
            fetchMyProfile()
        }
    }

    override fun componentWillUnmount() {
        isMounted = false
        session?.close()
    }

    private fun truncateText(max: Int, input: String): String {
        if (input.length > max) {
            return "${input.substring(0, max)}..."
        }
        return input
    }

    private fun fetchProfiles() {
        launch {
            if (isMounted) {
                val profiles = portalRestApi.get(
                    "/vehicleprofiles/ibis/profiles/${state.company?.id}", ListSerializer(IbisProfileDTO.serializer())
                )

                setState {
                    this.profiles = profiles
                }
            }
        }
    }

    private fun fetchMyProfile() {
        launch {
            if (isMounted) {
                val ibisProfileId = withContext(Dispatchers.Default) {
                    val vp = portalRestApi.get("/device/${props.id}/vehicleProfile", VehicleProfileDTO.serializer())
                    val cp = portalRestApi.getRaw("/companyprofile/ibis/${state.company?.id}").toIntOrNull()

                    vp.let {
                        if (vp.id != "") setState {
                            deviceHasVehicleProfile = true
                        }
                        it.ibisProfile
                    } ?: cp
                }

                val entries = ibisProfileId?.let {
                    portalRestApi.get(
                        "/vehicleprofiles/ibis/entries/$it",
                        ListSerializer(IbisProfileEntryDTO.serializer())
                    )
                }

                setState {
                    currentIbisProfileId = ibisProfileId
                    currentIbisProfileEntries = entries?.associateBy { Pair(it.ibisFunction, it.telegramType) }
                }
            }
        }.invokeOnCompletion {
            connectTelegramBroadcast()
        }
    }

    private fun deserializeTelegram(dto: DeviceIbisV18InfoRawDTO): DeviceIbisV18InfoDTO? {
        val telegramSerializer = IbisTelegramSerializer.values().firstOrNull { it.name.uppercase() == dto.function.uppercase() }?.serializer ?: ValueTelegram.serializer()
        return try {
            if (telegramSerializer !is LoggingTelegramSerializer)
                DeviceIbisV18InfoDTO(dto.timestamp, IbisFunction.valueOf(dto.function.uppercase()), dto.type, nonStrictJson.decodeFromString(telegramSerializer, dto.telegram))
            else
                DeviceIbisV18InfoDTO(dto.timestamp, IbisFunction.valueOf(dto.function.uppercase()), dto.type, LoggingTelegram(dto.telegram))
        } catch (ex: Exception) {
            null
        }
    }

    private fun connectTelegramBroadcast() {
        session?.close()
        session = portalWebSocketApi.subscribe("/device/ibis_info_v18", mapOf("id" to props.id.toString()))
        session?.connect {
            onmessage = { message ->
                if (isMounted) {
                    val item = Json.decodeFromString(DeviceIbisV18InfoRawDTO.serializer(), message)
                    val telegram = deserializeTelegram(item)
                    telegram?.let {

                        setState {
                            if (!telegrams.containsKey(Pair(it.function, it.type)) || (telegrams.containsKey(Pair(it.function, it.type)) && props.readOnly))
                                telegrams[Pair(it.function, it.type)] = Row(
                                    telegram = it,
                                    function = currentIbisProfileEntries?.get(Pair(it.function, it.type))?.ibisFunction ?: IbisFunction.NONE,
                                    appMapping = currentIbisProfileEntries?.get(Pair(it.function, it.type))?.isAppMapping ?: false,
                                    portalMapping = currentIbisProfileEntries?.get(Pair(it.function, it.type))?.isPortalMapping ?: false,
                                    timestamp = it.timestamp
                                )
                        }
                    }
                }
            }
        }
    }

    private fun saveQuickProfile() {
        state.newProfileName?.let { pname ->
            state.company?.let { company ->
                launch {
                    val profile = IbisProfileDTO(
                        profileId = 0,
                        profileName = pname,
                        companyId = company.id,
                        customOptions = "{}"
                    )

                    val bodyProfile = Json.encodeToJsonElement(IbisProfileDTO.serializer(), profile)
                    val savedProfile = portalRestApi.put(
                        "/vehicleprofiles/ibis/profile/${company.id}", bodyProfile, IbisProfileDTO.serializer()
                    )

                    val entries = state.telegrams.values.map {
                        IbisProfileEntryDTO(
                            profileId = savedProfile.profileId,
                            entryId = -1,
                            telegramType = it.telegram.type,
                            isAppMapping = it.appMapping,
                            isPortalMapping = it.portalMapping,
                            ibisFunction = it.function,
                            filter = listOf()
                        )
                    }

                    val bodyEntries = Json.encodeToJsonElement(
                        ListSerializer(IbisProfileEntryDTO.serializer()),
                        entries
                    )
                    portalRestApi.put(
                        "/vehicleprofiles/ibis/entries/${savedProfile.profileId}",
                        bodyEntries,
                        GenericResponseDTO.serializer()
                    )

                    if (state.saveDestination == 1) {
                        val body = Json.encodeToJsonElement(IbisProfileDTO.serializer(), savedProfile)
                        portalRestApi.put("/companyprofile/ibis/set/${company.id}", body)
                    }

                    if (state.saveDestination == 2) {
                        val vehicleProfile = portalRestApi.get(
                            "/device/${props.id}/vehicleProfile",
                            VehicleProfileDTO.serializer()
                        )

                        val body = Json.encodeToJsonElement(IbisProfileDTO.serializer(), savedProfile)
                        portalRestApi.put(
                            "/vehicleprofiles/ibis/set/${vehicleProfile.id}",
                            body,
                            IbisProfileDTO.serializer()
                        )
                    }
                }.invokeOnCompletion {
                    fetchProfiles()
                    fetchMyProfile()
                }
            }
        }
    }

    override fun RBuilder.render() {
        if (!props.readOnly) {
            styledDiv {
                css { padding(2.spacingUnits) }

                mGridContainer2(
                    direction = MGridDirection.row,
                    alignItems = MGridAlignItems.center
                ) {
                    val destinations = listOf("IBIS Profile", "Unternehmensprofil", "Fahrzeugprofil")

                    mGridItem2(
                        MGridBreakpoints2(MGridSize2.Auto).down(Breakpoint.sm, MGridSize2.Cells12)
                    ) {
                        mButton(
                            caption = "Erstellen",
                            color = MColor.secondary,
                            variant = MButtonVariant.contained,
                            disabled = state.newProfileName.isNullOrBlank(),
                            onClick = { event ->
                                if (state.profiles.any { it.profileName == state.newProfileName }) setState {
                                    warnDialog = "Es gibt bereits ein Profil mit diesem Namen."
                                } else {
                                    saveQuickProfile()
                                    setState {
                                        profiles = profiles.plus(
                                            IbisProfileDTO(
                                                0, state.newProfileName ?: "", "", "{}"
                                            )
                                        )
                                        newProfileName = ""
                                    }
                                }
                            }) {
                            attrs.fullWidth = true
                            attrs.disableElevation = true
                        }
                    }
                    mGridItem2(
                        MGridBreakpoints2(MGridSize2.Auto).down(Breakpoint.sm, MGridSize2.Cells6)
                    ) {
                        mTextField(
                            label = "",
                            variant = MFormControlVariant.outlined,
                            value = state.newProfileName ?: "",
                            placeholder = "Name vom Profil",
                            onChange = {
                                val text = it.targetInputValue
                                setState {
                                    newProfileName = text
                                }
                            }) {
                            css { marginTop = 4.px };
                            attrs.margin = MFormControlMargin.dense;
                            attrs.fullWidth = true
                        }
                    }
                    mGridItem2(
                        MGridBreakpoints2(MGridSize2.Auto).down(Breakpoint.sm, MGridSize2.Cells6)
                    ) {
                        mSelect(
                            value = state.saveDestination?.toString() ?: "0",
                            variant = MFormControlVariant.outlined,
                            name = "ibisSave",
                            fullWidth = true,
                            onChange = { event, _ ->
                                val id = event.targetValue as String
                                setState { this.saveDestination = id.toInt() }
                            }
                        ) {
                            attrs.margin = MFormControlMargin.dense.toString()

                            destinations.forEachIndexed { index, s ->
                                mMenuItem(
                                    primaryText = s,
                                    value = index.toString(),
                                    disabled = s == "Fahrzeugprofil" && !state.deviceHasVehicleProfile
                                )
                            }
                        }
                    }
                }
            }
            mDivider { }
        }
        styledDiv {
            css { padding(2.spacingUnits) }

            if (state.telegrams.entries.isEmpty()) {
                mTypography(
                    text = "Keine Daten verfügbar",
                    variant = MTypographyVariant.body1,
                    align = MTypographyAlign.center
                )
            } else {
                mTableContainer {
                    mTable {
                        css {
                            width = LinearDimension.auto
                            whiteSpace = WhiteSpace.nowrap
                        }
                        mTableHead {
                            mTableRowSlim {
                                mTableCell(
                                    align = MTableCellAlign.left,
                                    variant = MTableCellVariant.head,
                                    padding = MTableCellPadding.none,
                                ) {
                                    css { padding(1.spacingUnits) }
                                    +"Funktion"
                                }
                                mTableCell(
                                    align = MTableCellAlign.left,
                                    variant = MTableCellVariant.head,
                                    padding = MTableCellPadding.none
                                ) {
                                    css { padding(1.spacingUnits) }
                                    +"Telegram"
                                }
                                if (!props.readOnly) {
                                    mTableCell(
                                        align = MTableCellAlign.left,
                                        variant = MTableCellVariant.head,
                                        padding = MTableCellPadding.none
                                    ) {
                                        css { minWidth = 100.px; padding(1.spacingUnits) }
                                        +"Connect Mapping"
                                    }
                                    mTableCell(
                                        align = MTableCellAlign.left,
                                        variant = MTableCellVariant.head,
                                        padding = MTableCellPadding.none
                                    ) {
                                        css { minWidth = 100.px; padding(1.spacingUnits) }
                                        +"Portal Anzeige"
                                    }
                                } else {
                                    mTableCell(
                                        align = MTableCellAlign.left,
                                        variant = MTableCellVariant.head,
                                        padding = MTableCellPadding.none
                                    ) {
                                        css { padding(1.spacingUnits) }
                                        +"Empfangszeit"
                                    }
                                }
                                mTableCell(
                                    align = MTableCellAlign.left,
                                    variant = MTableCellVariant.head,
                                    padding = MTableCellPadding.none
                                ) {
                                    css { padding(1.spacingUnits) }
                                    +"Text"
                                }
                                mTableCell(
                                    align = MTableCellAlign.left,
                                    variant = MTableCellVariant.head,
                                    padding = MTableCellPadding.none
                                ) {
                                    css { padding(1.spacingUnits) }
                                    +"Rohdaten"
                                }
                                mTableCell(
                                    align = MTableCellAlign.left,
                                    variant = MTableCellVariant.head,
                                    padding = MTableCellPadding.none
                                ) {
                                    css { minWidth = 100.px; padding(1.spacingUnits) }
                                    +"Filter"
                                }
                            }
                        }

                        mTableBody {
                            for (row in state.telegrams.entries
                                .filter { !props.readOnly || it.value.portalMapping }
                                .sortedBy { it.key.second }) {

                                mTableRowSlim {
                                    mTableCell {
                                        css { padding(1.spacingUnits) }
                                        if (!props.readOnly) mSelect(
                                            value = row.value.function.name,
                                            onChange = { event, child ->
                                                val text = event.targetValue as String
                                                setState {
                                                    state.telegrams[row.key]?.function =
                                                        IbisFunction.valueOf(text.uppercase())
                                                }
                                            }) {
                                            attrs.margin = MFormControlMargin.dense.toString()
                                            attrs.fullWidth = true

                                            for (element in IbisFunction.values()) {
                                                mMenuItem(
                                                    primaryText = element.readableName,
                                                    value = element.name
                                                )
                                            }
                                        }
                                        else mTypography(
                                            text = row.value.function.readableName,
                                            variant = MTypographyVariant.body2
                                        )
                                    }
                                    mTableCell {
                                        css { padding(1.spacingUnits) }
                                        mTypography(
                                            text = row.value.telegram.type,
                                            variant = MTypographyVariant.body2
                                        )
                                    }
                                    if (!props.readOnly) {
                                        mTableCell {
                                            css { padding(0.spacingUnits, 1.spacingUnits) }
                                            mCheckbox(
                                                checked = row.value.appMapping,
                                                id = "ibisTarget",
                                                onChange = { _, checked ->
                                                    setState {
                                                        state.telegrams[row.key]?.appMapping = checked
                                                    }
                                                })
                                        }
                                        mTableCell {
                                            css { padding(0.spacingUnits, 1.spacingUnits) }
                                            mCheckbox(
                                                checked = row.value.portalMapping,
                                                id = "ibisTarget",
                                                onChange = { _, checked ->
                                                    setState {
                                                        state.telegrams[row.key]?.portalMapping = checked
                                                    }
                                                })
                                        }
                                    } else {
                                        mTableCell {
                                            css { padding(1.spacingUnits) }
                                            mTypography(
                                                text = row.value.timestamp?.toText() ?: "",
                                                variant = MTypographyVariant.body2
                                            )
                                        }
                                    }
                                    mTooltip(extractTelegramValueReadable(row.value.telegram.telegram)) {
                                        mTableCell {
                                            css { padding(1.spacingUnits) }
                                            mTypography(
                                                text = truncateText(
                                                    max = 50,
                                                    input = extractTelegramValueReadable(
                                                        row.value.telegram.telegram
                                                    )
                                                ),
                                                variant = MTypographyVariant.body2
                                            )
                                        }
                                    }
                                    mTooltip(extractTelegramRawValueReadable(row.value.telegram.telegram)) {
                                        mTableCell {
                                            css { padding(1.spacingUnits) }
                                            mTypography(
                                                text = extractTelegramRawValueReadable(
                                                    row.value.telegram.telegram
                                                ).take(32),
                                                variant = MTypographyVariant.body2
                                            )
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        mDialog(
            open = state.warnDialog != null,
            onClose = { _, _ -> setState { warnDialog = null } }
        ) {
            mDialogContent { mTypography(state.warnDialog) }
            mDialogActions {
                mButton(
                    "Okay",
                    onClick = { setState { warnDialog = null } }
                )
            }
        }
    }
}

fun extractTelegramValueReadable(telegram: IbisTelegram): String {
    return when (telegram) {
        is ValueTelegram -> telegram.value
        is LineTelegram -> telegram.value
        is MapTelegram -> telegram.value.map { "${it.key}: ${it.value}" }.joinToString()
        is ListMapTelegram -> telegram.value.joinToString { map ->
            map.value.map { "${it.key}: ${it.value}" }.joinToString()
        }
        is DS021a -> telegram.value.map { map ->
            "${map.key} - " + map.value.value.map { "${it.key}: ${it.value}" }.joinToString(" - ")
        }.joinToString()
//        is LoggingTelegram -> telegram.log
        else -> ""
    }

}

fun extractTelegramRawValueReadable(telegram: IbisTelegram): String {
    return when (telegram) {
        is ValueTelegram -> telegram.rawTelegram
        is LineTelegram -> telegram.rawTelegram
        is MapTelegram -> telegram.rawTelegram
        is ListMapTelegram -> telegram.value.joinToString { map ->
            map.rawTelegram
        }
        is DS021a -> telegram.value.map { map ->
            "${map.key}: " + map.value.rawTelegram
        }.joinToString()
        is LoggingTelegram -> telegram.log
        else -> ""
    }
}
