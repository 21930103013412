package de.geomobile.common.portalmodels

import de.geomobile.common.time.LocalDateTime
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import utils.millisToLocalDateTime

val DeviceListItemDTO.TimestampStatus.old get() = TimestampStatus(
    timestamp = this.timestamp?.millisToLocalDateTime(),
    intervalSec = this.intervalSec
)

@Serializable
data class TimestampStatus(
    val timestamp: LocalDateTime? = null,
    val intervalSec: Int? = null
) {
    enum class Status {
        ONLINE,
        OVERDUE,
        OFFLINE,
        UNKNOWN
    }

    @Transient
    val status: Status
        get() = when {
            timestamp == null -> Status.UNKNOWN
            intervalSec == null -> Status.UNKNOWN
            timestamp < LocalDateTime.now().minusSeconds(intervalSec * 5) -> Status.OFFLINE
            timestamp < LocalDateTime.now().minusSeconds(intervalSec * 2) -> Status.OVERDUE
            else -> Status.ONLINE
        }
}