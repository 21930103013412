package de.geomobile.frontend.features.companyProfile

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.*
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardActions
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.form.MFormControlMargin
import com.ccfraser.muirwik.components.form.MFormControlVariant
import com.ccfraser.muirwik.components.form.mFormGroup
import com.ccfraser.muirwik.components.form.margin
import com.ccfraser.muirwik.components.input.MInputProps
import com.ccfraser.muirwik.components.list.mListSubheader
import com.ccfraser.muirwik.components.styles.Breakpoint
import de.geomobile.common.permission.Permissions
import de.geomobile.common.portalmodels.Product
import de.geomobile.common.portalmodels.UserDTO
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.utils.*
import de.geomobile.frontend.utils.grid.*
import kotlinext.js.jsObject
import kotlinx.browser.localStorage
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.*
import kotlinx.serialization.builtins.ListSerializer
import org.w3c.dom.get
import portalmodels.VehicleStatusDTO
import react.*
import react.router.dom.route
import styled.css
import styled.styledDiv

fun RBuilder.companyProfileFSH() = child(CompanyProfileFSH::class) {}

class CompanyProfileFSH : CComponent<CompanyProfileFSH.Props, CompanyProfileFSH.State>() {

    private var companyJob: Job = Job()
    private var dataJob: Job = Job()
    private var loadExportTokenJob: Job = Job()

    interface Props : RProps

    class State(
        var company: String? = null,
        var vehicleHistoryEnabled: Boolean = false,
        var singleVehicle: Boolean = false,
        var histories: List<VehicleStatusDTO>? = null,
        var cpuIdToProduct: Map<String, Product> = emptyMap(),
        var serialToProduct: Map<Int, Product> = emptyMap(),
        var currentVehicleIndex: Int = 0,
        var vIDs: List<String> = listOf(),
        var exportToken: String = "",
        var columns: List<Column>,
        var rows: List<Row> = emptyList(),
        var offlineOnly: Boolean = false,
        var disableDialogue: Boolean = false,
    ) : RState

    init {
        val columns = listOf(
            Column(name = "vehicleId", title = "Fahrzeugnummer"),
            Column(name = "status", title = "Status"),
            Column(name = "lastSeen", title = "Zuletzt Gesehen"),
            Column(name = "checkedAt", title = "Zuletzt Geprüft"),
            Column(name = "cpuId", title = "CPU ID"),
            Column(name = "serialNumber", title = "Seriennummer")
        )
        state = State(columns = columns)
    }

    data class Row(
        val vehicleId: String,
        val status: Int,
        var lastSeen: String,
        var checkedAt: String,
        var cpuId: String,
        var serialNumber: String
    )

    override fun componentDidMount() {
        launch {
            loadCompany()
            loadVehicleStatusHistories()
            loadExportToken()
        }
    }

    private fun loadExportToken() {
        loadExportTokenJob.cancel()
        loadExportTokenJob = launch {
            val token = withContext(Dispatchers.Default) {
                portalRestApi.getRaw("/companyprofile/exporttoken")
            }
            setState {
                this.exportToken = token
            }
        }
    }

    suspend fun loadCompany() {
        val companyId =
            if (isAuthorized(Permissions.VehicleProfileManagement.notRestrictedToCompany) && localStorage["CompanyProfile"] != null) {
                localStorage["CompanyProfile"]
            } else {
                withContext(Dispatchers.Default) {
                    portalRestApi.get("/user", UserDTO.serializer())
                }.company.id
            }
        setState {
            company = companyId
        }
    }

    fun createSnapshot() {
        setState {
            histories = null
        }
        launch {
            state.company?.let {
                portalRestApi.getRaw("/companyprofile/$it/fsh/snap")
            }
            loadVehicleStatusHistories()
        }
    }

    fun State.getVehicleStatusCode(vs: VehicleStatusDTO): Int {
        return vs.lastSeen?.let { ls ->
            val dt = vs.checkedAt.minusMinutes(60)
            if (ls > dt)
                if (vs.isBetaTime)
                    1
                else
                    0
            else
                2
        } ?: 2
    }

    fun State.fillRows() {
        var rowList: MutableList<Row> = mutableListOf()
        histories?.let { histories ->
            if (singleVehicle) {
                if (histories.isNotEmpty())
                    histories.filter {
                        if ((currentVehicleIndex) < vIDs.size)
                            it.vehicleId == vIDs[(currentVehicleIndex)]
                        else
                            false
                    }.forEach {
                        rowList.add(
                            Row(
                                vehicleId = it.vehicleId.ifEmpty { "-" },
                                status = getVehicleStatusCode(it),
                                lastSeen = it.lastSeen?.toText() ?: "-",
                                checkedAt = it.checkedAt.toText(),
                                cpuId = it.cpuId ?: "-",
                                serialNumber = if (it.serialNumber != null) it.serialNumber.toString() else "-"
                            )
                        )
                    }
            } else {
                if (histories.isNotEmpty())
                    vIDs.forEach { vid ->
                        histories.filter { it.vehicleId == vid }.last().let {
                            val status = getVehicleStatusCode(it)
                            if (!offlineOnly || (offlineOnly && status == 2))
                                rowList.add(
                                    Row(
                                        vehicleId = it.vehicleId.ifEmpty { "-" },
                                        status = getVehicleStatusCode(it),
                                        lastSeen = it.lastSeen?.toText() ?: "-",
                                        checkedAt = it.checkedAt.toText(),
                                        cpuId = it.cpuId ?: "-",
                                        serialNumber = if (it.serialNumber != null) it.serialNumber.toString() else "-"
                                    )
                                )
                        }
                    }
            }
        }
        rows = rowList.toList()
    }

    suspend fun loadVehicleStatusHistories() {

        val hist = state.company?.let {
            portalRestApi.get(
                "/companyprofile/${state.company}/fsh/data",
                ListSerializer(VehicleStatusDTO.serializer())
            )
        }
        val active = state.company?.let {
            portalRestApi.getRaw("/companyprofile/${state.company}/fsh/active")
        }
        active?.let {
            setState {
                vehicleHistoryEnabled = (active == "true")
            }
        }
        hist?.let { h ->
            setState {
                histories = h
                cpuIdToProduct = h.filter { it.cpuId != null }.associate { it.cpuId!! to it.product }
                serialToProduct = h.filter { it.serialNumber != null }.associate { it.serialNumber!! to it.product }
                vIDs = h.map { it.vehicleId }.distinct()
                fillRows()
            }
        }
    }

    private val rowWrapper = rFunction<RowProps>("TableRowWrapper") { rowProps ->
        //val newProps = kotlinext.js.clone(rowProps)
        rowProps.tableRow
        tableRow(rowProps)
    }

    private val statusFormatter = rFunction<ValueFormatterProps>("StatusFormatter") { props ->
        mTooltip(
            when (props.value as Int) {
                0 -> "Zuletzt normal gesehen."
                1 -> "Zuletzt im Fallback Channel gesehen."
                2 -> "Nicht gesehen."
                else -> "Fehler beim ermitteln des Status."
            }
        ) {
            css { marginRight = 16.pt }
            mIconNoTranslate("circle") {
                css {
                    when (props.value as Int) {
                        0 -> color = Color.green
                        1 -> color = Color.yellow
                        2 -> color = Color.red
                        else -> color = Color.black
                    }
                }
            }
        }
    }

    /**
     * TODO: cpuId & serialNumber have a wrong linking depending on the case (ivantoConnect, ivantoCore)
     */
    private val LinkFormatter = rFunction<ValueFormatterProps>("LinkFormatter") { props ->
        val prop = props.value as String
        if (prop != "-") {
            val isCpuId = state.cpuIdToProduct[prop]
            val isSerial = state.serialToProduct[prop.toIntOrNull()]
            val product = isCpuId ?: isSerial
            val identifier = isCpuId?.let { "cpuid" } ?: isSerial?.let { "serialnumber" }
            if (product != null) {
                mLink(
                    hRefOptions = HRefOptions(
                        href = "../../devices/${product.readableName}/$identifier/${props.value}",
                    ),
                    underline = MLinkUnderline.none
                ) {
                    mTypography(
                        text = prop,
                        variant = MTypographyVariant.subtitle2,
                        color = MTypographyColor.secondary,
                        align = MTypographyAlign.left
                    )
                }
            } else {
                mTypography(
                    text = prop,
                    variant = MTypographyVariant.subtitle2,
                    color = MTypographyColor.textPrimary,
                    align = MTypographyAlign.left
                )
            }
        }

    }

    //    private fun RBuilder.LinkFormat(value: String, product: String, isSerial: Boolean) {
//        if (value != "-") {
//            mLink(
//                hRefOptions = HRefOptions(
//                    href = "../../devices/ivanto$product/cpuid/${value}",
//                ),
//                underline = MLinkUnderline.none
//            ) {
//                mTypography(
//                    text = value,
//                    variant = MTypographyVariant.subtitle2,
//                    color = MTypographyColor.secondary,
//                    align = MTypographyAlign.left
//                )
//            }
//        } else {
//            mTypography(
//                text = value,
//                variant = MTypographyVariant.subtitle2,
//                color = MTypographyColor.textPrimary,
//                align = MTypographyAlign.left
//            )
//        }
//    }
    override fun RBuilder.render() {
        route<RProps>("/companyprofile/system/fsh") { _ ->
            mGridContainer2(direction = MGridDirection.column) {
                mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                    mCard {
                        css(GlobalStyles.card)
                        mCardContent {
                            css(GlobalStyles.cardBoxContent)
                            mListSubheader(heading = "Einstellungen")
                            mDivider { }
                            styledDiv {
                                css { padding(2.spacingUnits) }
                                mFormGroup {
                                    mSwitchWithLabel(
                                        label = "Alle Einträge eines einzelnen Fahrzeugs anzeigen.",
                                        checked = state.singleVehicle,
                                        onChange = { _, checked ->
                                            setState {
                                                singleVehicle = checked
                                                if (checked)
                                                    offlineOnly = !checked
                                                fillRows()
                                            }
                                        }
                                    )
                                    mSwitchWithLabel(
                                        label = "Nur Fahrzeuge anzeigen, die Offline sind.",
                                        checked = state.offlineOnly,
                                        onChange = { _, checked ->
                                            setState {
                                                offlineOnly = checked
                                                if (checked) {
                                                    singleVehicle = !checked
                                                    currentVehicleIndex = 0
                                                }

                                                fillRows()
                                            }
                                        }
                                    )
                                }
                            }
                        }
                        mDivider { }
                        mCardActions {
                            css { padding(2.spacingUnits) }
                            mGridContainer2(direction = MGridDirection.row) {
                                mGridItem2(
                                    MGridBreakpoints2(MGridSize2.Auto)
                                        .down(Breakpoint.xs, MGridSize2.Cells12)
                                ) {
                                    mButton(
                                        caption = "Aktuellen Stand erfassen",
                                        color = MColor.secondary,
                                        variant = MButtonVariant.contained,
                                        onClick = {
                                            createSnapshot()
                                        }
                                    ) {
                                        attrs.fullWidth = true
                                        attrs.disableElevation = true
                                    }
                                }
                                mGridItem2(
                                    MGridBreakpoints2(MGridSize2.Auto)
                                        .down(Breakpoint.xs, MGridSize2.Cells12)
                                ) {
                                    mLink(
                                        hRefOptions = HRefOptions(
                                            href = "/../companyprofile/download/${state.company}/vehicleStatusHistory.csv?token=${state.exportToken}",
                                        ),
                                        underline = MLinkUnderline.none,
                                    ) {
                                        mTooltip("Enthält immer alle Daten") {
                                            styledDiv {
                                                mButton(
                                                    caption = "CSV Download",
                                                    variant = MButtonVariant.outlined,
                                                    disabled = state.histories == null
                                                ) {
                                                    attrs.fullWidth = true
                                                    attrs.disableElevation = true
                                                    attrs.endIcon = mIconNoTranslate("get_app", addAsChild = false)
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                if (state.singleVehicle) {
                    mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                        mCard {
                            css(GlobalStyles.card)
                            mCardContent {
                                css(GlobalStyles.cardPaginationContent)
                                mTextField(
                                    label = "",
                                    variant = MFormControlVariant.outlined,
                                    onChange = {
                                        val value = state.vIDs.indexOf(it.targetInputValue)
                                        setState {
                                            if (value >= 0) {
                                                currentVehicleIndex = value
                                                fillRows()
                                            }
                                        }
                                    }
                                ) {
                                    css { "& input" { textAlign = TextAlign.center } }
                                    attrs.inputProps = jsObject<MInputProps> {
                                        startAdornment = buildElement {
                                            mTooltip("Zurück") {
                                                mIconButtonNoTranslate(
                                                    size = MButtonSize.small,
                                                    addAsChild = true,
                                                    iconName = "arrow_back",
                                                    onClick = {
                                                        val value = --state.currentVehicleIndex
                                                        if (value >= 0) {
                                                            setState {
                                                                currentVehicleIndex = value
                                                                fillRows()
                                                            }
                                                        }
                                                    }
                                                ) {
                                                    css { fontSize = 14.px }
                                                    attrs.size = MIconButtonSize.small
                                                }
                                            }
                                        }
                                        endAdornment = buildElement {
                                            mTooltip("Vor") {
                                                mIconButtonNoTranslate(
                                                    size = MButtonSize.small,
                                                    addAsChild = true,
                                                    iconName = "arrow_forward",
                                                    onClick = {
                                                        val value = ++state.currentVehicleIndex
                                                        if (value <= state.vIDs.size - 1) {
                                                            setState {
                                                                currentVehicleIndex = value
                                                                fillRows()
                                                            }
                                                        }
                                                    }
                                                ) {
                                                    css { fontSize = 14.px }
                                                    attrs.size = MIconButtonSize.small
                                                }
                                            }
                                        }
                                    }
                                    attrs.margin = MFormControlMargin.dense
                                    attrs.placeholder = "Fahrzeugnummer"
                                    attrs.fullWidth = true
                                }
                            }
                        }
                    }
                }
                mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                    mCard {
                        css(GlobalStyles.card)
                        mCardContent {
                            css(GlobalStyles.cardBoxContent)
                            mListSubheader(heading = "Fahrzeuge")
                            mDivider { }
                            fshTablesEx()
                        }
                    }
                }
            }
        }
    }

    private val listCellStyle = rFunction<RProps>("TableCell") { cellProps ->
        val newProps = kotlinext.js.clone(cellProps)

        newProps.asDynamic().style = kotlinext.js.js {
            paddingTop = 1.spacingUnits
            paddingBottom = 1.spacingUnits
        }

        tableCell(newProps)
    }

    private fun RBuilder.fshTablesEx() =
        grid(
            columns = state.columns,
            rows = state.rows
        ) {
            searchState()
            sortingState(
                defaultSorting = Sorting(columnName = "vehicleId", direction = "asc")
            )
            integratedFiltering()
            integratedSorting()
            dataTypeProvider(
                columns = listOf("status"),
                formatterComponent = statusFormatter
            )
            dataTypeProvider(
                columns = listOf("cpuId", "serialNumber"),
                formatterComponent = LinkFormatter
            )
            table(
                rowComponent = rowWrapper,
                columnExtensions = listOf(
                    TableProps.ColumnExtension(
                        columnName = "vehicleId",
                        width = 100
                    ),
                    TableProps.ColumnExtension(
                        columnName = "status",
                        width = 100
                    ),
                    TableProps.ColumnExtension(
                        columnName = "lastSeen",
                        width = 200
                    ),
                    TableProps.ColumnExtension(
                        columnName = "checkedAt",
                        width = 200
                    ),
                    TableProps.ColumnExtension(
                        columnName = "cpuId",
                        width = 100
                    ),
                    TableProps.ColumnExtension(
                        columnName = "serialNumber",
                        width = 100
                    )
                ),
                cellComponent = listCellStyle
            )
            tableHeaderRow(
                showSortingControls = true
            )
            gridToolbar()
            searchPanel()
        }
}