package de.geomobile.frontend.features.vehicleProfile

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.dialog.mDialog
import com.ccfraser.muirwik.components.dialog.mDialogActions
import com.ccfraser.muirwik.components.dialog.mDialogContent
import com.ccfraser.muirwik.components.form.MFormControlMargin
import com.ccfraser.muirwik.components.form.MFormControlVariant
import com.ccfraser.muirwik.components.form.mFormControl
import com.ccfraser.muirwik.components.form.mFormGroup
import com.ccfraser.muirwik.components.input.MInputProps
import com.ccfraser.muirwik.components.menu.mMenuItem
import com.ccfraser.muirwik.components.styles.Breakpoint
import de.geomobile.common.permission.Permissions
import de.geomobile.common.portalmodels.*
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.spacer
import de.geomobile.frontend.utils.*
import kotlinext.js.jsObject
import kotlinx.browser.localStorage
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.async
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.*
import kotlinx.html.InputType
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.json.Json
import org.w3c.dom.get
import portalmodels.FmsProfileDTO
import portalmodels.IbisProfileDTO
import portalmodels.WiringProfileDTO
import react.RBuilder
import react.RProps
import react.RState
import react.setState
import styled.css
import styled.styledDiv

fun RBuilder.vehicleProfilesCreate(
    profileId: String?,
    cancel: () -> Unit,
    created: () -> Unit,
) = child(VehicleProfilesDetailEdit::class) {
    attrs.profileId = profileId
    attrs.mode = VehicleProfilesDetailEdit.Mode.CREATE
    attrs.cancel = cancel
    attrs.saved = created
    attrs.onDeleted = cancel
}

fun RBuilder.vehicleProfilesEdit(
    profileId: String,
    goBack: () -> Unit,
    onDeleted: () -> Unit,
) = child(VehicleProfilesDetailEdit::class) {
    attrs.profileId = profileId
    attrs.mode = VehicleProfilesDetailEdit.Mode.EDIT
    attrs.cancel = { goBack() }
    attrs.saved = { goBack() }
    attrs.onDeleted = onDeleted
}

class VehicleProfilesDetailEdit : CComponent<VehicleProfilesDetailEdit.Props, VehicleProfilesDetailEdit.State>() {

    enum class Mode {
        CREATE, EDIT
    }

    interface Props : RProps {
        var profileId: String?
        var mode: Mode
        var cancel: () -> Unit
        var saved: () -> Unit
        var onDeleted: () -> Unit
    }

    class State(
        var name: String? = null,
        var company: CompanySmall? = null,
        var description: String? = null,
        var vehicleModel: String? = null,
        var vehicleType: VehicleType? = null,
        var floorType: FloorType? = null,
        var vehicleIdByIBIS: Boolean? = null,
        var vehicleIdAllowOverride: Boolean? = null,
        var passengerLimitOption: PassengerLimitOption? = null,
        var lowerPassengerLimit: Int? = null,
        var upperPassengerLimit: Int? = null,
        var seatingCapacity: Int? = null,
        var standingCapacity: Int? = null,
        var ramp: AvailableType? = null,
        var out1: OutFunction? = null,
        var out2: OutFunction? = null,
        var out3: OutFunction? = null,
        var out4: OutFunction? = null,
        var out5: OutFunction? = null,
        var in1: InFunction? = null,
        var in2: InFunction? = null,
        var in3: InFunction? = null,
        var in4: InFunction? = null,
        var speaker1: WiredType? = null,
        var speaker2: WiredType? = null,
        var can: WiredType? = null,
        var ibis: WiredType? = null,
        var fmsProfiles: List<FmsProfileDTO>? = null,
        var fmsSelectedProfile: Int? = null,
        var ibisProfiles: List<IbisProfileDTO>? = null,
        var ibisSelectedProfile: Int? = null,
        var wiringProfiles: List<WiringProfileDTO>? = null,
        var wiringSelectedProfile: Int? = null,
        var elaAudioVolume: Int? = null,
        var elaAudioTreble: Int? = null,
        var elaAudioBass: Int? = null,
        var confirmDelete: Boolean = false,

        var saving: Boolean = false,
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        launch {
            val deferredCompanies = if (isAuthorized(Permissions.VehicleProfileManagement.notRestrictedToCompany))
                async(Dispatchers.Default) {
                    portalRestApi.get("/admin/companies", ListSerializer(Company.serializer()))
                }
            else
                null

            var profile = if (props.profileId != null)
                withContext(Dispatchers.Default) {
                    portalRestApi.get("/vehicleprofiles/id/${props.profileId}", VehicleProfileDTO.serializer())
                }
            else
                null

            //check if duplicate requested
            if (props.mode == Mode.CREATE && props.profileId != null) {
                // add "Kopie" to name and remove assigned devices
                profile = profile?.let { it.copy(name = it.name + " (Kopie)") }
            }

            // get company from local storage (if authorized) otherwise use own users company
            val company =
                if (isAuthorized(Permissions.VehicleProfileManagement.notRestrictedToCompany)) {
                    deferredCompanies?.await()?.first { it.id == localStorage["VehicleProfileCompany"] }?.small

                } else {
                    withContext(Dispatchers.Default) {
                        portalRestApi.get("/user", UserDTO.serializer())
                    }.company.small
                }

            val fmsProfiles = company?.let {
                withContext(Dispatchers.Default) {
                    portalRestApi.get(
                        "/vehicleprofiles/fms/profiles/${company.id}",
                        ListSerializer(FmsProfileDTO.serializer())
                    )
                }
            }

            val ibisProfiles = company?.let {
                withContext(Dispatchers.Default) {
                    portalRestApi.get(
                        "/vehicleprofiles/ibis/profiles/${company.id}",
                        ListSerializer(IbisProfileDTO.serializer())
                    )
                }
            }

            val wiringProfiles = company?.let {
                withContext(Dispatchers.Default) {
                    portalRestApi.get(
                        "/vehicleprofiles/wiring/profiles/${company.id}",
                        ListSerializer(WiringProfileDTO.serializer())
                    )
                }
            }

            setState {
                this.name = profile?.name
                this.company = profile?.company ?: company
                this.description = profile?.description
                this.vehicleModel = profile?.vehicleModel
                this.vehicleType = profile?.vehicleType
                this.floorType = profile?.floorType
                this.vehicleIdByIBIS = profile?.vehicleIdByIBIS
                this.vehicleIdAllowOverride = profile?.vehicleIdAllowOverride
                this.passengerLimitOption = profile?.passengerLimitOption
                this.lowerPassengerLimit = profile?.lowerPassengerLimit
                this.upperPassengerLimit = profile?.upperPassengerLimit
                this.seatingCapacity = profile?.seatingCapacity
                this.standingCapacity = profile?.standingCapacity
                this.ramp = profile?.ramp
                this.out1 = profile?.out1
                this.out2 = profile?.out2
                this.out3 = profile?.out3
                this.out4 = profile?.out4
                this.out5 = profile?.out5
                this.in1 = profile?.in1
                this.in2 = profile?.in2
                this.in3 = profile?.in3
                this.in4 = profile?.in4
                this.speaker1 = profile?.speaker1
                this.speaker2 = profile?.speaker2
                this.can = profile?.can
                this.ibis = profile?.ibis
                this.fmsProfiles = fmsProfiles
                this.fmsSelectedProfile = profile?.fmsProfile
                this.ibisProfiles = ibisProfiles
                this.ibisSelectedProfile = profile?.ibisProfile
                this.wiringProfiles = wiringProfiles
                this.wiringSelectedProfile = profile?.wiringProfile
                this.elaAudioVolume = profile?.elaAudioVolume
                this.elaAudioTreble = profile?.elaAudioTreble
                this.elaAudioBass = profile?.elaAudioBass

            }
        }
    }

    override fun RBuilder.render() {
        spacer()
        mGridContainer2(direction = MGridDirection.row) {
            mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                val disabled = state.name == null || state.company == null || state.saving

                styledDiv {
                    css { display = Display.flex }
                    mButton(
                        when (props.mode) {
                            Mode.CREATE -> if (state.saving) "Lädt" else "Erstellen"
                            Mode.EDIT -> if (state.saving) "Lädt" else "Übernehmen"
                        },
                        variant = MButtonVariant.contained,
                        color = MColor.secondary,
                        disabled = disabled,
                        onClick = {
                            val profile = VehicleProfileDTO(
                                id = props.profileId ?: "",
                                name = state.name!!,
                                company = state.company!!,
                                description = state.description,
                                vehicleModel = state.vehicleModel,
                                vehicleType = state.vehicleType,
                                floorType = state.floorType,
                                vehicleIdByIBIS = state.vehicleIdByIBIS,
                                vehicleIdAllowOverride = state.vehicleIdAllowOverride,
                                passengerLimitOption = state.passengerLimitOption,
                                lowerPassengerLimit = state.lowerPassengerLimit,
                                upperPassengerLimit = state.upperPassengerLimit,
                                seatingCapacity = state.seatingCapacity,
                                standingCapacity = state.standingCapacity,
                                ramp = state.ramp,
                                out1 = state.out1,
                                out2 = state.out2,
                                out3 = state.out3,
                                out4 = state.out4,
                                out5 = state.out5,
                                in1 = state.in1,
                                in2 = state.in2,
                                in3 = state.in3,
                                in4 = state.in4,
                                speaker1 = state.speaker1,
                                speaker2 = state.speaker2,
                                can = state.can,
                                ibis = state.ibis,
                                fmsProfile = state.fmsSelectedProfile,
                                ibisProfile = state.ibisSelectedProfile,
                                wiringProfile = state.wiringSelectedProfile,
                                elaAudioVolume = state.elaAudioVolume,
                                elaAudioTreble = state.elaAudioTreble,
                                elaAudioBass = state.elaAudioBass
                            )
                            saveProfile(profile)
                        }
                    ) {
                        attrs.disableElevation = true
                    }

                    mButton(
                        caption = "Abbrechen",
                        variant = MButtonVariant.contained,
                        onClick = { props.cancel() },
                        color = MColor.default
                    ) {
                        css { marginLeft = 1.spacingUnits }
                        attrs.disableElevation = true
                    }

                    if (props.mode == Mode.EDIT && props.profileId != null)
                        mButton(
                            caption = "Löschen",
                            color = MColor.inherit,
                            variant = MButtonVariant.contained,
                            onClick = { setState { confirmDelete = true } }
                        ) {
                            attrs.disableElevation = true
                            css {
                                marginRight = 1.spacingUnits
                                marginLeft = LinearDimension.auto
                                backgroundColor = Color.red
                                color = Color.white
                            }
                        }
                }
            }
            mCard {
                css { width = 100.pct }
                mCardContent {
                    css { maxWidth = 70.pct }
                    mGridContainer2(direction = MGridDirection.row) {
                        mGridItem2(MGridBreakpoints2(MGridSize2.Cells6)
                            .down(Breakpoint.md, MGridSize2.Cells12)) {
                            if (props.mode == Mode.EDIT && state.name == null)
                                mCircularProgress { css { display = Display.block; margin(LinearDimension.auto) } }
                            else
                                mFormGroup {
                                    mFormControl(
                                        margin = MFormControlMargin.normal,
                                        required = true,
                                        fullWidth = true
                                    ) {
                                        mTextField(
                                            margin = MFormControlMargin.dense,
                                            id = "name",
                                            label = "Name",
                                            value = state.name ?: "",
                                            variant = MFormControlVariant.outlined,
                                            onChange = {
                                                val value = it.targetInputValue
                                                setState { this.name = value }
                                            }
                                        )
                                    }

                                    mFormControl(
                                        margin = MFormControlMargin.normal,
                                        required = false,
                                        fullWidth = true
                                    ) {
                                        mTextField(
                                            margin = MFormControlMargin.dense,
                                            id = "description",
                                            label = "Beschreibung",
                                            value = state.description ?: "",
                                            variant = MFormControlVariant.outlined,
                                            onChange = {
                                                val value = it.targetInputValue
                                                setState { this.description = value }
                                            }
                                        )
                                    }

                                    mFormControl(
                                        margin = MFormControlMargin.normal,
                                        required = false,
                                        fullWidth = true
                                    ) {
                                        mTextField(
                                            margin = MFormControlMargin.dense,
                                            id = "vehiclemodel",
                                            label = "Fahrzeugmodell",
                                            value = state.vehicleModel ?: "",
                                            variant = MFormControlVariant.outlined,
                                            onChange = {
                                                val value = it.targetInputValue
                                                setState { this.vehicleModel = value }
                                            }
                                        )
                                    }

                                    mFormControl(
                                        margin = MFormControlMargin.normal,
                                        required = false,
                                        fullWidth = true
                                    ) {
                                        mTypography(text = "Fahrzeugtyp", variant = MTypographyVariant.caption) {
                                            css { display = Display.block; marginBottom = 3.px }
                                        }
                                        mSelect(
                                            variant = MFormControlVariant.outlined,
                                            value = state.vehicleType ?: "",
                                            name = "vehicletype",
                                            id = "vehicletype",
                                            onChange = { event, _ ->
                                                val value = event.targetValue as String
                                                setState { this.vehicleType = VehicleType.valueOf(value) }
                                            }
                                        ) {
                                            attrs.margin = MFormControlMargin.dense.toString()

                                            VehicleType.values().forEach {
                                                mMenuItem(primaryText = it.readableName, value = it.name)
                                            }
                                        }
                                    }

                                    mFormControl(
                                        margin = MFormControlMargin.normal,
                                        required = false,
                                        fullWidth = true
                                    ) {
                                        mTypography(text = "Flurtechnik", variant = MTypographyVariant.caption) {
                                            css { display = Display.block; marginBottom = 3.px }
                                        }
                                        mSelect(
                                            variant = MFormControlVariant.outlined,
                                            value = state.floorType ?: "",
                                            name = "floortype",
                                            id = "floortype",
                                            onChange = { event, _ ->
                                                val value = event.targetValue as String
                                                setState { this.floorType = FloorType.valueOf(value) }
                                            }
                                        ) {
                                            attrs.margin = MFormControlMargin.dense.toString()

                                            FloorType.values().forEach {
                                                mMenuItem(primaryText = it.readableName, value = it.name)
                                            }

                                        }
                                    }

                                    mFormControl(
                                        margin = MFormControlMargin.normal,
                                        required = false,
                                        fullWidth = true
                                    ) {
                                        mTypography(text = "Passagiergrenze gemessen an", variant = MTypographyVariant.caption) {
                                            css { display = Display.block; marginBottom = 3.px }
                                        }
                                        mSelect(
                                            variant = MFormControlVariant.outlined,
                                            value = state.passengerLimitOption ?: "",
                                            name = "passengerlimitoption",
                                            id = "passengerlimitoption",
                                            onChange = { event, _ ->
                                                val value = event.targetValue as String
                                                setState { this.passengerLimitOption = PassengerLimitOption.valueOf(value) }
                                            }
                                        ) {
                                            attrs.margin = MFormControlMargin.dense.toString()

                                            PassengerLimitOption.values().forEach {
                                                mMenuItem(primaryText = it.readableName, value = it.name)
                                            }
                                        }
                                    }

                                    mFormControl(margin = MFormControlMargin.dense, required = true, fullWidth = true) {
                                        mTextField(
                                            margin = MFormControlMargin.dense,
                                            id = "lowerpassengerlimit",
                                            label = "Passagiergrenze mittlere Auslastung in %",
                                            value = state.lowerPassengerLimit?.toString() ?: "0",
                                            variant = MFormControlVariant.outlined,
                                            type = InputType.number,
                                            onChange = {
                                                val value = it.targetInputValue.toInt()
                                                setState {
                                                    this.lowerPassengerLimit =
                                                        value.takeIf { it != this.lowerPassengerLimit }
                                                }
                                            }
                                        ) {
                                            attrs.inputProps = jsObject<MInputProps> {
                                                inputProps = kotlinext.js.js { min = 0 }
                                            }
                                        }
                                    }

                                    mFormControl(margin = MFormControlMargin.dense, required = true, fullWidth = true) {
                                        mTextField(
                                            margin = MFormControlMargin.dense,
                                            id = "upperpassengerlimit",
                                            label = "Passagiergrenze hohe Auslastung in %",
                                            value = state.upperPassengerLimit?.toString() ?: "0",
                                            variant = MFormControlVariant.outlined,
                                            type = InputType.number,
                                            onChange = {
                                                val value = it.targetInputValue.toInt()
                                                setState {
                                                    this.upperPassengerLimit =
                                                        value.takeIf { it != this.upperPassengerLimit }
                                                }
                                            }
                                        ) {
                                            attrs.inputProps = jsObject<MInputProps> {
                                                inputProps = kotlinext.js.js { min = 0 }
                                            }
                                        }
                                    }

                                    mFormControl(margin = MFormControlMargin.dense, required = true, fullWidth = true) {
                                        mTextField(
                                            margin = MFormControlMargin.dense,
                                            id = "seatingcap",
                                            label = "Anzahl Sitzplätze",
                                            value = state.seatingCapacity?.toString() ?: "0",
                                            variant = MFormControlVariant.outlined,
                                            type = InputType.number,
                                            onChange = {
                                                val value = it.targetInputValue.toInt()
                                                setState {
                                                    this.seatingCapacity =
                                                        value.takeIf { it != this.seatingCapacity }
                                                }
                                            }
                                        ) {
                                            attrs.inputProps = jsObject<MInputProps> {
                                                inputProps = kotlinext.js.js { min = 0 }
                                            }
                                        }
                                    }

                                    mFormControl(
                                        margin = MFormControlMargin.normal,
                                        required = true,
                                        fullWidth = true
                                    ) {
                                        mTextField(
                                            margin = MFormControlMargin.dense,
                                            id = "standingcap",
                                            label = "Anzahl Stehplätze",
                                            value = state.standingCapacity?.toString() ?: "0",
                                            variant = MFormControlVariant.outlined,
                                            type = InputType.number,
                                            onChange = {
                                                val value = it.targetInputValue.toInt()
                                                setState {
                                                    this.standingCapacity =
                                                        value.takeIf { it != this.standingCapacity }
                                                }
                                            }
                                        ) {
                                            attrs.inputProps = jsObject<MInputProps> {
                                                inputProps = kotlinext.js.js { min = 0 }
                                            }
                                        }
                                    }
                                    authorize(Permissions.DeviceManagement.ela) {


                                        mFormControl(
                                            margin = MFormControlMargin.normal,
                                            required = false,
                                            fullWidth = true
                                        ) {
                                            mTextField(
                                                margin = MFormControlMargin.dense,
                                                id = "elaAudioVolume",
                                                label = "ELA Lautstärke",
                                                value = state.elaAudioVolume?.toString() ?: "0",
                                                variant = MFormControlVariant.outlined,
                                                type = InputType.number,
                                                onChange = {
                                                    val value = it.targetInputValue.toInt()
                                                    setState {
                                                        this.elaAudioVolume =
                                                            when (value) {
                                                                in 0..100 -> value
                                                                else -> 0
                                                            }
                                                    }
                                                }
                                            ) {
                                                attrs.inputProps = jsObject<MInputProps> {
                                                    inputProps = kotlinext.js.js { min = 0 }
                                                }
                                            }
                                        }
                                        mFormControl(
                                            margin = MFormControlMargin.normal,
                                            required = false,
                                            fullWidth = true
                                        ) {
                                            mTextField(
                                                margin = MFormControlMargin.dense,
                                                id = "elaAudioTreble",
                                                label = "ELA Treble",
                                                value = state.elaAudioTreble?.toString() ?: "0",
                                                variant = MFormControlVariant.outlined,
                                                type = InputType.number,
                                                onChange = {
                                                    val value = it.targetInputValue.toInt()
                                                    setState {
                                                        this.elaAudioTreble =
                                                            when (value) {
                                                                in 0..100 -> value
                                                                else -> 0
                                                            }
                                                    }
                                                }
                                            ) {
                                                attrs.inputProps = jsObject<MInputProps> {
                                                    inputProps = kotlinext.js.js { min = 0 }
                                                }
                                            }
                                        }
                                        mFormControl(
                                            margin = MFormControlMargin.normal,
                                            required = false,
                                            fullWidth = true
                                        ) {
                                            mTextField(
                                                margin = MFormControlMargin.dense,
                                                id = "elaAudioBass",
                                                label = "ELA Bass",
                                                value = state.elaAudioBass?.toString() ?: "0",
                                                variant = MFormControlVariant.outlined,
                                                type = InputType.number,
                                                onChange = {
                                                    val value = it.targetInputValue.toInt()
                                                    setState {
                                                        this.elaAudioBass =
                                                            when (value) {
                                                                in 0..100 -> value
                                                                else -> 0
                                                            }
                                                    }
                                                }
                                            ) {
                                                attrs.inputProps = jsObject<MInputProps> {
                                                    inputProps = kotlinext.js.js { min = 0 }
                                                }
                                            }
                                        }
                                    }

                                    mFormControl(
                                        margin = MFormControlMargin.normal,
                                        required = false,
                                        fullWidth = true
                                    ) {
                                        mTypography(text = "Rampe", variant = MTypographyVariant.caption) {
                                            css { display = Display.block; marginBottom = 3.px }
                                        }
                                        mSelect(
                                            variant = MFormControlVariant.outlined,
                                            value = state.ramp ?: "",
                                            name = "ramp",
                                            id = "ramp",
                                            onChange = { event, _ ->
                                                val value = event.targetValue as String
                                                setState { this.ramp = AvailableType.valueOf(value) }
                                            }
                                        ) {
                                            attrs.margin = MFormControlMargin.dense.toString()

                                            AvailableType.values().forEach {
                                                mMenuItem(primaryText = it.readableName, value = it.name)
                                            }
                                        }
                                    }
                                }
                        }
                        mGridItem2(MGridBreakpoints2(MGridSize2.Cells6)
                            .down(Breakpoint.md, MGridSize2.Cells12)) {
                            if (props.mode == Mode.EDIT && state.name == null)
                                mCircularProgress { css { display = Display.block; margin(LinearDimension.auto) } }
                            else {
                                mFormGroup {
                                    authorize(Permissions.VehicleProfileManagement.fmsProfileView) {
                                        mFormControl(
                                            margin = MFormControlMargin.normal,
                                            required = false,
                                            fullWidth = true
                                        ) {
                                            css { marginTop = 2.px }
                                            mTypography(text = "FMS Profil", variant = MTypographyVariant.caption) {
                                                css {
                                                    display = Display.block
                                                    marginBottom = 5.px
                                                }
                                            }
                                            mSelect(
                                                variant = MFormControlVariant.outlined,
                                                value = state.fmsSelectedProfile ?: "null",
                                                name = "FMS Profil",
                                                id = "fms2",
                                                disabled = !isAuthorized(Permissions.VehicleProfileManagement.fmsProfileView),
                                                onChange = { event, _ ->
                                                    val value = try {
                                                        (event.targetValue as String).toInt()
                                                    } catch (ex: Exception) {
                                                        null
                                                    }
                                                    setState { fmsSelectedProfile = value }
                                                }
                                            ) {
                                                attrs.margin = MFormControlMargin.dense.toString()

                                                mMenuItem(primaryText = "-", value = "null")
                                                state.fmsProfiles?.forEach {
                                                    mMenuItem(primaryText = it.name, value = it.id.toString())
                                                }
                                            }
                                        }
                                    }
                                    authorize(Permissions.VehicleProfileManagement.ibisProfileEdit) {
                                        mFormControl(
                                            margin = MFormControlMargin.normal,
                                            required = false,
                                            fullWidth = true
                                        ) {
                                            css { marginTop = 2.px }
                                            mTypography(text = "IBIS Profil", variant = MTypographyVariant.caption) {
                                                css {
                                                    display = Display.block
                                                    marginBottom = 5.px
                                                }
                                            }
                                            mSelect(
                                                variant = MFormControlVariant.outlined,
                                                value = state.ibisSelectedProfile ?: "null",
                                                name = "IBIS Profil",
                                                id = "ibis2",
                                                disabled = !isAuthorized(Permissions.VehicleProfileManagement.ibisProfileView),
                                                onChange = { event, _ ->
                                                    val value = try {
                                                        (event.targetValue as String).toInt()
                                                    } catch (ex: Exception) {
                                                        null
                                                    }
                                                    setState { ibisSelectedProfile = value }
                                                }
                                            ) {
                                                attrs.margin = MFormControlMargin.dense.toString()
                                                mMenuItem(primaryText = "-", value = "null")
                                                state.ibisProfiles?.forEach {
                                                    mMenuItem(
                                                        primaryText = it.profileName,
                                                        value = it.profileId.toString()
                                                    )
                                                }
                                            }
                                        }
                                    }
                                    authorize(Permissions.DeviceManagement.cableCheck) {
                                        mFormControl(
                                            margin = MFormControlMargin.normal,
                                            required = false,
                                            fullWidth = true
                                        ) {
                                            css { marginTop = 2.px }
                                            mTypography(
                                                text = "Verkabelungsprofil",
                                                variant = MTypographyVariant.caption
                                            ) {
                                                css {
                                                    display = Display.block
                                                    marginBottom = 5.px
                                                }
                                            }
                                            mSelect(
                                                variant = MFormControlVariant.outlined,
                                                value = state.wiringSelectedProfile ?: "null",
                                                name = "Verkabelungsprofil",
                                                id = "cableProfile",
                                                disabled = !isAuthorized(Permissions.DeviceManagement.cableCheck),
                                                onChange = { event, _ ->
                                                    val value = try {
                                                        (event.targetValue as String).toInt()
                                                    } catch (ex: Exception) {
                                                        null
                                                    }
                                                    setState { wiringSelectedProfile = value }
                                                }
                                            ) {
                                                attrs.margin = MFormControlMargin.dense.toString()
                                                mMenuItem(primaryText = "-", value = "null")
                                                state.wiringProfiles?.forEach {
                                                    mMenuItem(primaryText = it.name, value = it.id.toString())
                                                }
                                            }
                                        }
                                    }
                                    mFormControl(margin = MFormControlMargin.dense, required = true, fullWidth = true) {
                                        mCheckboxWithLabel(
                                            label = "Fahrzeugnummer über IBIS",
                                            checked = state.vehicleIdByIBIS == true,
                                            id = "vehicleIdByIBIS",
                                            onChange = { _, checked ->
                                                setState { this.vehicleIdByIBIS = checked }
                                            }
                                        )
                                        mCheckboxWithLabel(
                                            label = "Fahrzeugnummer überschreibbar",
                                            checked = state.vehicleIdAllowOverride == true,
                                            id = "vehicleIdAllowOverride",
                                            onChange = { _, checked ->
                                                setState { this.vehicleIdAllowOverride = checked }
                                            }
                                        )
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        mDialog(
            open = state.confirmDelete,
            onClose = { _, _ -> setState { confirmDelete = false } }
        ) {
            mDialogContent { mTypography("Möchten Sie dieses Profil löschen?") }
            mDialogActions {
                mButton("Abbrechen", onClick = { setState { confirmDelete = false } })
                mButton("Löschen", onClick = { deleteProfile(props.profileId!!) }) {
                    css { color = Color.red }
                }
            }
        }
    }

    private fun saveProfile(profile: VehicleProfileDTO) {
        setState {
            this.saving = true
        }

        launch {
            withContext(Dispatchers.Default) {
                when (props.mode) {
                    Mode.CREATE ->
                        portalRestApi.post(
                            "/vehicleprofiles/manage",
                            body = Json.encodeToString(VehicleProfileDTO.serializer(), profile),
                            serializer = VehicleProfileDTO.serializer()
                        )

                    Mode.EDIT ->
                        portalRestApi.put(
                            "/vehicleprofiles/manage/${profile.id}",
                            body = Json.encodeToString(VehicleProfileDTO.serializer(), profile),
                            serializer = VehicleProfileDTO.serializer()
                        )
                }
            }
            props.saved()
        }
    }

    private fun deleteProfile(profileId: String) {
        setState {
            this.saving = true
        }

        launch {
            withContext(Dispatchers.Default) {
                portalRestApi.delete("/vehicleprofiles/manage/${profileId}")
            }

            props.onDeleted()
        }
    }
}