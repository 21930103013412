package de.geomobile.frontend.features.companyProfile

import com.ccfraser.muirwik.components.MGridDirection
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.list.mList
import com.ccfraser.muirwik.components.list.mListItem
import com.ccfraser.muirwik.components.list.mListSubheader
import com.ccfraser.muirwik.components.mDivider
import com.ccfraser.muirwik.components.styles.Breakpoint
import de.geomobile.common.permission.Permission
import de.geomobile.common.permission.Permissions
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.features.portalSettings.vehicleApiAccess
import de.geomobile.frontend.spacer
import de.geomobile.frontend.utils.*
import react.RBuilder
import react.RProps
import react.RState
import react.router.dom.redirect
import react.router.dom.route
import react.router.dom.switch
import styled.css

fun RBuilder.companyProfileSystem(
    path: String,
) = child(CompanyProfileSystem::class) {
    attrs.path = path
}

class CompanyProfileSystem : CComponent<CompanyProfileSystem.Props, CompanyProfileSystem.State>() {

    interface Props : RProps {
        var path: String
    }

    class State(
        /*var simDataSource: String   = CompanyProfileSIMSource.REMOTE.readableName,
        var simDataExist: String    = "Nein",
        var simDataDeployed: String = "Nein",*/
        // TODO: Replace String list with Enumerators
        var categoryList: Map<Pair<String, String>, Permission> = mapOf(
            ("ELA" to "ela") to Permissions.CompanyProfileManagement.profileView,
            ("Fahrzeug Historie" to "fsh") to Permissions.CompanyProfileManagement.fshView,
            ("Betriebshöfe" to "betriebshöfe") to Permissions.CompanyProfileManagement.depotList,
            ("IBIS Profil" to "ibisprofile") to Permissions.VehicleProfileManagement.ibisProfileView,
            ("Linien Liste" to "ibisline") to Permissions.VehicleProfileManagement.ibisProfileView,
            ("GPS Tunnel" to "fakegps") to Permissions.CompanyProfileManagement.fakeGPSEdit,
            ("Ticketautomaten API" to "ticketapi") to Permissions.CompanyProfileManagement.ticketApiView,
        )
    ) : RState

    init {
        state = State()
    }

    override fun RBuilder.render() {
        spacer()
        mGridContainer2(direction = MGridDirection.row) {
            mGridItem2(
                MGridBreakpoints2(MGridSize2.Cells2)
                    .down(Breakpoint.md, MGridSize2.Cells12)
            ) {
                sideBar()
            }
            mGridItem2(
                MGridBreakpoints2(MGridSize2.Cells10)
                    .down(Breakpoint.md, MGridSize2.Cells12)
            ) {
                switch {
                    authorize(Permissions.CompanyProfileManagement.fshView) {
                        route<RProps>("/companyprofile/system/fsh") {
                            companyProfileFSH()
                        }
                    }
                    route<RProps>("/companyprofile/system/ela") {
                        companyProfileELA()
                    }
                    authorize(Permissions.CompanyProfileManagement.depotList) {
                        route<RProps>("/companyprofile/system/betriebshöfe") {
                            companyProfileDepots()
                        }
                    }
                    authorize(Permissions.VehicleProfileManagement.ibisProfileView) {
                        route<RProps>("/companyprofile/system/ibisprofile") {
                            companyProfileIBIS()
                        }
                    }
                    authorize(Permissions.CompanyProfileManagement.ibisLineView) {
                        route<RProps>("/companyprofile/system/ibisline") {
                            companyProfileIBISLine()
                        }
                    }
                    authorize(Permissions.CompanyProfileManagement.fakeGPSEdit) {
                        route<RProps>("/companyprofile/system/fakegps") {
                            companyProfileGPS()
                        }
                    }
                    authorize(Permissions.CompanyProfileManagement.ticketApiView) {
                        route<RProps>("/companyprofile/system/ticketapi") {
                            vehicleApiAccess()
                        }
                    }
                    // companyProfileWIFI()
                    // companyProfileCAN()
                    redirect(
                        from = "/companyprofile/system",
                        to = "/companyprofile/system" + "/" + state.categoryList.entries.first().key.second.lowercase(),
                        exact = true
                    )
                }
            }
        }
    }

    private fun RBuilder.sideBar() {
        mGridContainer2(direction = MGridDirection.column) {
            mGridItem2 {
                mCard {
                    css(GlobalStyles.card)
                    mCardContent {
                        css(GlobalStyles.cardListItemContent)
                        mListSubheader(heading = "Filter")
                        mDivider { }
                        route<RProps>("/companyprofile/system") { props ->
                            mList {
                                for (category in state.categoryList) {
                                    authorize(category.value) {
                                        mListItem(
                                            primaryText = category.key.first,
                                            divider = false,
                                            onClick = {
                                                props.history.push("/companyprofile/system/${category.key.second.lowercase()}")
                                            }
                                        ) {
                                            attrs.dense = true
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


