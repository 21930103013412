package de.geomobile.frontend.features.device.list

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.list.mList
import com.ccfraser.muirwik.components.list.mListItem
import com.ccfraser.muirwik.components.list.mListItemIcon
import com.ccfraser.muirwik.components.list.mListItemText
import com.ccfraser.muirwik.components.menu.mMenu
import de.geomobile.common.feature.Features
import de.geomobile.common.filter.FilterMatcher
import de.geomobile.common.filter.FilterRule
import de.geomobile.common.filter.FilterRules
import de.geomobile.common.permission.Permissions
import de.geomobile.common.portalmodels.DeviceIdentifier
import de.geomobile.common.portalmodels.DeviceListItemDelta
import de.geomobile.common.portalmodels.TimestampStatus
import de.geomobile.frontend.currentTheme
import de.geomobile.frontend.features.device.detail.deviceStatusLed
import de.geomobile.frontend.features.device.toDetailPath
import de.geomobile.frontend.portalWebSocketApi
import de.geomobile.frontend.utils.*
import de.geomobile.frontend.utils.grid.*
import kotlinext.js.js
import kotlinx.browser.localStorage
import kotlinx.coroutines.*
import kotlinx.css.*
import kotlinx.serialization.json.JsonArray
import kotlinx.serialization.json.jsonArray
import kotlinx.serialization.json.jsonPrimitive
import org.w3c.dom.events.MouseEvent
import org.w3c.dom.get
import org.w3c.dom.set
import react.*
import react.dom.tr
import styled.css
import styled.styledB
import styled.styledDiv

fun RBuilder.deviceList(
    persistenceId: String = "",
    headless: Boolean = false,
    onDeviceClick: (DeviceIdentifier) -> Unit,
    searchInput: String = "",
    editMode: Boolean = false,
    onSelectedDevicesChanged: (selection: List<DeviceListItem>) -> Unit = {},
    filter: FilterMatcher<DeviceListItem>? = null,
    onFilteredDevicesChanged: ((List<Int>) -> Unit)? = null,
    onGetNotMatchedRules: ((FilterRules) -> Unit)? = null
) = child(DeviceList::class) {
    attrs.persistenceId = persistenceId
    attrs.headless = headless
    attrs.onDeviceClick = onDeviceClick
    attrs.editMode = editMode
    attrs.searchInput = searchInput
    attrs.onSelectedDevicesChanged = onSelectedDevicesChanged
    attrs.filter = filter
    attrs.onFilteredDevicesChanged = onFilteredDevicesChanged
    attrs.onGetNotMatchedRules = onGetNotMatchedRules

    key = persistenceId
}

class DeviceList(props: Props) : CComponent<DeviceList.Props, DeviceList.State>(props) {

    private val session = portalWebSocketApi.subscribe("devicelist")

    data class Row(
        val id: Int,
        val primaryIdentifier: DeviceIdentifier,
        val product: String,
        val productVariant: String,
        val serialNumber: IntValue,
        val cpuId: String,
        val vehicleId: String,
        val vehicleType: String,
        val hardwareId: String,
        val vehicleProfile: String,
        val description: String,
        val company: String,
        val stage: String,
        val inceptionDate: Timestamp,
        val installDate: Timestamp,
        val shippingDate: Timestamp,
        val updateProgress: String,
        val updateState: String,
        val internalUpdateState: String,
        val lastSeen: Timestamp,
        val status: Int,
        val statusDTO: TimestampStatus,
        val betaLastSeen: Timestamp,
        val betaStatus: Int,
        val betaStatusDTO: TimestampStatus,
        val line: String,
        val destination: String
    ) {
        data class IntValue(
            val value: Int?
        ) : Comparable<IntValue> {
            override fun toString(): String = value?.toString() ?: "-"
            override fun compareTo(other: IntValue): Int = compareValues(value, other.value)
        }

        data class Timestamp(
            val text: String,
            val timestamp: Long
        ) : Comparable<Timestamp> {
            override fun toString(): String = text
            override fun compareTo(other: Timestamp): Int = timestamp.compareTo(other.timestamp)
        }
    }

    interface Props : RProps {
        var persistenceId: String
        var headless: Boolean
        var onDeviceClick: (DeviceIdentifier) -> Unit
        var searchInput: String
        var editMode: Boolean
        var onSelectedDevicesChanged: ((selection: List<DeviceListItem>) -> Unit)?
        var filter: FilterMatcher<DeviceListItem>?
        var onFilteredDevicesChanged: ((List<Int>) -> Unit)?
        var onGetNotMatchedRules: ((FilterRules) -> Unit)?
    }

    class State(
        var newTabId: DeviceIdentifier? = null,
        var mouseX: Int? = null,
        var mouseY: Int? = null,

        var loading: Boolean = true,
        var devices: Map<Int, DeviceListItem> = emptyMap(),
        var columns: List<Column>,

        var rows: List<Row> = emptyList(),
        var searchRegex: Regex? = null,

        var selectedDevices: List<Int> = emptyList(),
        var hiddenColumns: List<String>,
        var columnOrder: List<String>,
        var pageSize: Int,
        var showFilter: Boolean = true,
        var filters: Array<Filter> = emptyArray(),

        var expandedGroups: Array<String> = emptyArray(),
        var grouping: Array<Grouping> = emptyArray(),
        var page: Int = 0,
        var sorting: Array<Sorting> = arrayOf(Sorting(columnName = "serialNumber", direction = "asc"))
    ) : RState

    init {
        val columns = listOf(
            Column(name = "serialNumber", title = "Seriennummer"),
            Column(name = "cpuId", title = "Cpu Id", permissions = listOf(Permissions.AdminPermissions.internalAccess)),
            Column(name = "product", title = "Produkt"),
            Column(name = "productVariant", title = "Produktvariante"),
            Column(name = "vehicleId", title = "Fahrzeugnummer"),
            Column(name = "vehicleType", title = "Fahrzeugtyp"),
            Column(name = "hardwareId", title = "Hardware Id"),
            Column(
                name = "vehicleProfile",
                title = "Fahrzeugprofil",
                feature = Features.VehicleProfileFeatures.vehicleProfiles
            ),
            Column(
                name = "company",
                title = "Unternehmen",
                feature = Features.CompanyFeatures.shareDevices
            ),
            Column(name = "stage", title = "Stage"),
            Column(name = "description", title = "Beschreibung"),
            // Previously we only had "manufactureDate", but the manufacture date
            // is set each time that the flash script is executed so it falsifies the
            // actual date of device inception. Hence we changed it to "inceptionDate" and "installDate".
            Column(name = "inceptionDate", title = "Hergestellt am"),
            Column(name = "installDate", title = "Betriebssystem aufgesetzt am"),
            Column(name = "shippingDate", title = "Versandt am"),
            Column(name = "updateProgress", title = "Update Fortschritt"),
            Column(name = "updateState", title = "Update-Status"),
            Column(
                name = "internalUpdateState",
                title = "Update-Status (intern)",
                permissions = listOf(Permissions.AdminPermissions.internalAccess)
            ),
            Column(name = "lastSeen", title = "Zuletzt gesehen"),
            Column(name = "status", title = "Status"),
            Column(
                name = "betaLastSeen",
                title = "Zuletzt gesehen (beta)",
                permissions = listOf(Permissions.AdminPermissions.internalAccess)
            ),
            Column(
                name = "betaStatus",
                title = "Status (beta)",
                permissions = listOf(Permissions.AdminPermissions.internalAccess)
            ),
            Column(
                name = "line",
                title = "Linie"
            ),
            Column(
                name = "destination",
                title = "Ziel"
            )
        )
            .filter { isAuthorized(*it.permissions.toTypedArray()) }
            .filter {
                if (it.feature == null) true
                else hasFeature(it.feature)
            }

        state = State(
            columns = columns,
            hiddenColumns = localStorage["deviceListHiddenColumns${props.persistenceId}"]?.split(";").orEmpty().let {
                val original = columns.map { it.name }
                it.intersect(original).toList()
            },
            columnOrder =
            (localStorage["deviceListColumnOrder${props.persistenceId}"]?.split(";")
                ?: columns.map { it.name }).let {
                val original = columns.map { it.name }
                val newColumns = original - it
                it.intersect(original).toList() + newColumns
            },
            pageSize = localStorage["deviceListPageSize${props.persistenceId}"]?.toIntOrNull() ?: 20,
            grouping = localStorage["deviceListGrouping${props.persistenceId}"]?.split(";").orEmpty().let { element ->
                val original = columns.map { it.name }
                element.intersect(original.toSet()).map { Grouping(it) }.toTypedArray()
            },
            expandedGroups = localStorage["deviceListExpandedGroups${props.persistenceId}"]?.split(";").orEmpty().let {
                val original = columns.map { it.name }
                it.toTypedArray()
            },
            //showFilter = localStorage["deviceListToggledFilter${props.persistenceId}"].toBoolean()
        )
    }

    private data class DeviceListUpdate(
        val companies: List<DeviceListItemDelta.Company>,
        val vehicleProfiles: List<DeviceListItemDelta.VehicleProfile>,
        val devices: Map<Int, DeviceListItem>,
        val rows: List<Row>
    )

    override fun componentDidMount() {
        session.connect {
            onbytemessage = {

                launch {
                    val result = withContext(Dispatchers.Default) {

                        val update = DeviceListItemDelta.ADAPTER.decode(it)

                        var devices = state.devices
                            .plus(
                                update.newOrModified
                                    .map { it.toListItem(update.companies, update.vehicleProfiles) }
                                    .associateBy { it.id }
                            )
                            .minus(update.deleted)

                        val rows = devices.toRows(props.filter)

                        DeviceListUpdate(
                            companies = update.companies,
                            vehicleProfiles = update.vehicleProfiles,
                            devices = devices,
                            rows = rows
                        )
                    }

                    props.onSelectedDevicesChanged?.invoke(state.selectedDevices.mapNotNull { result.devices[it] })

//                    val localStorageCompanyId = try {
//                        localStorage["deviceListSelectedFilters"]
//                    } catch (e: NoSuchElementException) {
//                        null
//                    }
//
//                    val filters =
//                        if ((localStorageCompanyId != null) and (localStorageCompanyId != "null")) {
//                            localStorage["deviceListSelectedFilters"]
//                        } else {
//                            localStorage["deviceListSelectedFilters"]
//                        }

                    setState {
                        loading = false
                        // this.filters = filters?.let { JSON.parse(it) } ?: emptyArray()
                        this.devices = result.devices
                        this.rows = result.rows
                    }
                }
            }
        }

        launch {
            while (isActive) {
                setState {
                    this.rows = this.rows.map {
                        it.copy(
                            status = it.statusDTO.status.ordinal,
                            betaStatus = it.betaStatusDTO.status.ordinal
                        )
                    }
                }
                delay(10_000)
            }
        }
    }

    override fun componentWillUnmount() {
        super.componentWillUnmount()
        session.close()
    }

    override fun componentWillReceiveProps(nextProps: Props) {
        setState {
            searchRegex =
                if (nextProps.searchInput.isNotEmpty()) Regex(
                    Regex.escape(nextProps.searchInput),
                    RegexOption.IGNORE_CASE
                )
                else null
        }

        if (nextProps.filter != props.filter) {
            val rows = state.devices.toRows(nextProps.filter)
            setState {
                this.rows = rows
            }
        }
    }

    private fun Map<Int, DeviceListItem>.toRows(
        filter: FilterMatcher<DeviceListItem>?
    ): List<Row> = this
        .filter { filter?.matches(it.value) ?: true }
        .map { (_, device) ->
            Row(
                id = device.id,
                primaryIdentifier = device.primaryIdentifier(),
                product = device.product.readableName,
                productVariant = device.productVariant ?: "-",
                serialNumber = Row.IntValue(device.serialNumber),
                cpuId = device.cpuId ?: "-",
                vehicleId = device.vehicleId.takeUnless { it.isNullOrBlank() } ?: "-",
                vehicleType = device.vehicleType.readableName,
                hardwareId = device.hardwareId,
                vehicleProfile = device.vehicleProfile?.name ?: "-",
                description = device.description ?: "-",
                company = device.company.name,
                stage = device.stage.readableName,
                inceptionDate = Row.Timestamp(
                    text = device.inceptionDate.toText(from = TimeUnit.MONTH),
                    timestamp = device.inceptionDate.millis
                ),
                installDate = Row.Timestamp(
                    text = device.installDate.toText(from = TimeUnit.MONTH),
                    timestamp = device.installDate.millis
                ),
                shippingDate = Row.Timestamp(
                    text = device.shippingDate?.toText(from = TimeUnit.MONTH) ?: "-",
                    timestamp = device.shippingDate?.millis ?: 0
                ),
                updateProgress = if (device.updateProgress < 0) "-" else "${device.updateProgress}%",
                updateState = device.externalUpdateState.readableName,
                internalUpdateState = device.updateState.readableName,
                lastSeen = Row.Timestamp(
                    text = device.status.timestamp?.toText() ?: "-",
                    timestamp = device.status.timestamp?.millis ?: 0
                ),
                status = device.status.status.ordinal,
                statusDTO = device.status,
                betaLastSeen = Row.Timestamp(
                    text = device.betaStatus.timestamp?.toText() ?: "-",
                    timestamp = device.betaStatus.timestamp?.millis ?: 0
                ),
                betaStatus = device.betaStatus.status.ordinal,
                betaStatusDTO = device.betaStatus,
                line = device.line ?: "-",
                destination = device.destination ?: "-"
            )
        }
        .also { props.onFilteredDevicesChanged?.invoke(it.map { it.id }) }
        .also { row ->
            props.onGetNotMatchedRules?.invoke(
                FilterRules(
                    if (filter != null) {
                        // TODO: Only working for operator in and vehicleId and serialnumber for now -> if needed add other filter ids and operators
                        filter.filterRules.rules.filter { it.rule.operatorId == "IN" }
                            .map { rule ->
                                val ref = when (rule.filterId) {
                                    "vehicleId" -> rule.rule.ref.jsonArray.filter { it.jsonPrimitive.content !in row.map { it.vehicleId } }
                                    "serialNumber" -> rule.rule.ref.jsonArray.filter { it.jsonPrimitive.content !in row.map { it.serialNumber.toString() } }
                                    else -> emptyList()
                                }

                                FilterRule(
                                    rule.filterId,
                                    de.geomobile.common.filter.Filter.Rule(JsonArray(ref), rule.rule.operatorId)
                                )
                            }

                    } else {
                        emptyList<FilterRule>()
                    }
                ))
        }

    private val serialNumberFormatter = rFunction<ValueFormatterProps>("SerialNumberFormatter") { params ->
        styledDiv {
            css {
                margin(4.px)
                color = Color(currentTheme.palette.secondary.main)
                if (state.searchRegex == null)
                    fontWeight = FontWeight.bold
            }
            searchHighlightText((params.value as Row.IntValue).toString())
        }
    }

    private val searchFormatter = rFunction<ValueFormatterProps>("SearchFormatter") { props ->
        searchHighlightText(props.value as? String ?: props.value.toString())
    }

    private fun RBuilder.searchHighlightText(text: String) {
        val match = state.searchRegex?.find(text)
        if (match != null) {
            +text.substring(0, match.range.start)
            styledB {
                css { color = Color(currentTheme.palette.secondary.main) }
                +text.substring(match.range)
            }
            +text.substring(match.range.endInclusive + 1)
        } else {
            +text
        }
    }

    private val statusFormatter = rFunction<ValueFormatterProps>("StatusFormatter") { props ->
        deviceStatusLed(status = TimestampStatus.Status.values()[props.value as Int])
    }

    private val filterRowWrapper = rFunction<RProps>("FilterRowWrapper") { props ->
        if (state.showFilter) tableRow(props)
        else tr {}
    }

    private val rowWrapper = rFunction<RowProps>("TableRowWrapper") { rowProps ->
        val newProps = kotlinext.js.clone(rowProps)

        newProps.asDynamic().onContextMenu = { event: MouseEvent ->
            event.preventDefault()
            setState {
                newTabId = (rowProps.tableRow.row as Row).primaryIdentifier
                mouseX = event.clientX - 2
                mouseY = event.clientY - 4
            }
        }
        newProps.asDynamic().onClick = { props.onDeviceClick((rowProps.tableRow.row as Row).primaryIdentifier) }
        newProps.asDynamic().style = js { cursor = "pointer" }

        tableRow(newProps)
    }

    private val listCellStyle = rFunction<RProps>("TableCell") { cellProps ->
        val newProps = kotlinext.js.clone(cellProps)

        newProps.asDynamic().style = js {
            paddingTop = "2px"
            paddingBottom = "2px"
        }

        tableCell(newProps)
    }

    private val sortCellStyle = rFunction<RProps>("TableHeaderCell") { cellProps ->
        val newProps = kotlinext.js.clone(cellProps)

        newProps.asDynamic().style = js {
            fontSize = "0.75rem"
        }

        tableCell(newProps)
    }

    override fun RBuilder.render() {
        if (props.headless) return

        grid(
            columns = state.columns,
            rows = state.rows,
            getRowId = { (it as? Row)?.id ?: -1 }
        ) {
            searchState(
                value = props.searchInput
            )
            sortingState(
                sorting = state.sorting,
                onSortingChange = { setState { sorting = it } }
            )
            filteringState(
                filters = state.filters,
                onFiltersChange = {
                    setState { filters = it }
                    // localStorage["deviceListSelectedFilters"] = JSON.stringify(it)
                }
            )
            selectionState(
                selection = state.selectedDevices,
                onSelectionChange = {
                    setState { selectedDevices = it.asList() }
                    props.onSelectedDevicesChanged?.invoke(it.mapNotNull { state.devices[it] })
                }
            )
            groupingState(
                grouping = state.grouping,
                expandedGroups = state.expandedGroups,
                onGroupingChange = { grp ->
                    localStorage["deviceListGrouping${props.persistenceId}"] =
                        grp.map { it.columnName }.joinToString(";")
                    setState { grouping = grp }
                },
                onExpandedGroupsChange = {
                    localStorage["deviceListExpandedGroups${props.persistenceId}"] = it.joinToString(";")
                    setState { expandedGroups = it }
                }
            )
            pagingState(
                pageSize = state.pageSize,
                onPageSizeChange = { pageSize ->
                    localStorage["deviceListPageSize${props.persistenceId}"] = pageSize.toString()
                    setState { this.pageSize = pageSize }
                },
                currentPage = state.page,
                onCurrentPageChange = { setState { page = it } }
            )
            integratedGrouping()
            integratedFiltering(
                columnExtensions = listOf(
                    IntegratedFilteringProps.ColumnExtension(
                        columnName = "status",
                        predicate = { value, filter, _ ->
                            if (filter.operation == "selectStatus") {
                                (value as Int) == filter.value?.toInt()
                            } else {
                                false
                            }
                        }
                    ),
                    IntegratedFilteringProps.ColumnExtension(
                        columnName = "betaStatus",
                        predicate = { value, filter, _ ->
                            if (filter.operation == "selectStatus") {
                                (value as Int) == filter.value?.toInt()
                            } else {
                                false
                            }
                        }
                    )
                )
            )
            integratedSorting(
                listOf(
                    IntegratedSortingProps.ColumnExtension(
                        columnName = "serialNumber",
                        compare = { a, b ->
                            (a as? Row.IntValue)?.compareTo(
                                (b as? Row.IntValue) ?: return@ColumnExtension 0
                            ) ?: 0
                        }
                    ),
                    IntegratedSortingProps.ColumnExtension(
                        columnName = "inceptionDate",
                        compare = { a, b ->
                            (a as? Row.Timestamp)?.compareTo(
                                (b as? Row.Timestamp) ?: return@ColumnExtension 0
                            ) ?: 0
                        }
                    ),
                    IntegratedSortingProps.ColumnExtension(
                        columnName = "installDate",
                        compare = { a, b ->
                            (a as? Row.Timestamp)?.compareTo(
                                (b as? Row.Timestamp) ?: return@ColumnExtension 0
                            ) ?: 0
                        }
                    ),
                    IntegratedSortingProps.ColumnExtension(
                        columnName = "shippingDate",
                        compare = { a, b ->
                            (a as? Row.Timestamp)?.compareTo(
                                (b as? Row.Timestamp) ?: return@ColumnExtension 0
                            ) ?: 0
                        }
                    ),
                    IntegratedSortingProps.ColumnExtension(
                        columnName = "lastSeen",
                        compare = { a, b ->
                            (a as? Row.Timestamp)?.compareTo(
                                (b as? Row.Timestamp) ?: return@ColumnExtension 0
                            ) ?: 0
                        }
                    ),
                    IntegratedSortingProps.ColumnExtension(
                        columnName = "betaLastSeen",
                        compare = { a, b ->
                            (a as? Row.Timestamp)?.compareTo(
                                (b as? Row.Timestamp) ?: return@ColumnExtension 0
                            ) ?: 0
                        }
                    )
                )
            )
            integratedSelection()
            integratedPaging()
            dataTypeProvider(
                columns = listOf("serialNumber"),
                formatterComponent = serialNumberFormatter
            )
            dataTypeProvider(
                columns = listOf("status", "betaStatus"),
                formatterComponent = statusFormatter
            )
            dataTypeProvider(
                columns = listOf(
                    "cpuId",
                    "company",
                    "vehicleId",
                    "vehicleType",
                    "hardwareId",
                    "vehicleProfile",
                    "description",
                    "inceptionDate",
                    "installDate",
                    "shippingDate",
                    "lastSeen",
                    "betaLastSeen",
                    "line",
                    "destination"
                ),
                formatterComponent = searchFormatter
            )
            dragDropProvider()
            table(
                columnExtensions = listOf(
                    TableProps.ColumnExtension("serialNumber", width = 140),
                    TableProps.ColumnExtension("status", width = 100, align = "center"),
                    TableProps.ColumnExtension("betaStatus", width = 120, align = "center")
                ),
                rowComponent = rowWrapper,
                cellComponent = listCellStyle
            )
            tableColumnReordering(
                order = state.columnOrder,
                onOrderChange = { nextOrder ->
                    localStorage["deviceListColumnOrder${props.persistenceId}"] = nextOrder.joinToString(";")
                    setState { columnOrder = nextOrder.toList() }
                }
            )
            tableHeaderRow(
                showSortingControls = true
            )
            tableFilterRow(
                cellComponent = DeviceListFilterCell::class.js.unsafeCast<RClass<TableFilterRowProps.CellProps>>(),
                rowComponent = filterRowWrapper
            )
            tableColumnVisibility(
                hiddenColumnNames = state.hiddenColumns,
                onHiddenColumnNamesChange = { hiddenColumnNames ->
                    localStorage["deviceListHiddenColumns${props.persistenceId}"] = hiddenColumnNames.joinToString(";")
                    setState { hiddenColumns = hiddenColumnNames.toList() }
                }
            )
            tableSelection(
                showSelectAll = props.editMode,
                showSelectionColumn = props.editMode
            )
            tableGroupRow()
            gridToolbar()
            groupingPanel(
                showGroupingControls = true,
                showSortingControls = true
            )
            pagingPanel(pageSizes = listOf(10, 20, 30, 40, 50, 100))

            columnChooser()

            toolbarFilterToggle(
                active = state.showFilter,
                onToggle = {
                    setState {
                        showFilter = !showFilter
                        // localStorage["deviceListToggledFilter"] = showFilter.toString()
                    }
                }
            )
        }

        mMenu(
            open = state.mouseY != null,
            onClose = { _, _ -> setState { mouseX = null; mouseY = null } }
        ) {
            attrs.anchorReference = MPopoverAnchorRef.anchorPosition
            attrs.anchorPositionTop = state.mouseY ?: 0
            attrs.anchorPositionLeft = state.mouseX ?: 0

            mList(
                dense = true,
                disablePadding = true
            ) {
                mListItem(
                    button = true,
                    component = "a",
                    hRefOptions = HRefOptions(
                        href = "/portal/devices${state.newTabId?.toDetailPath()}",
                        targetBlank = true
                    )
                ) {
                    mListItemIcon(iconName = "open_in_new")
                    mListItemText(primary = "In neuem Fenster öffnen")
                }
                mListItem(
                    button = true,
                    onClick = { _ -> setState { mouseX = null; mouseY = null } }
                ) {
                    mListItemIcon(iconName = "close")
                    mListItemText(primary = "Schließen")
                }
            }
        }
    }
}
