package de.geomobile.frontend.features.softwareManagement.software

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonSize
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardActions
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.form.MFormControlMargin
import com.ccfraser.muirwik.components.form.MFormControlVariant
import com.ccfraser.muirwik.components.input.MInputProps
import com.ccfraser.muirwik.components.list.mListSubheader
import com.ccfraser.muirwik.components.styles.Breakpoint
import de.geomobile.common.portalmodels.Product
import de.geomobile.common.softwaremgmt.Software
import de.geomobile.common.softwaremgmt.isIdValid
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.navigator
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.utils.*
import kotlinext.js.jsObject
import kotlinx.browser.window
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.LinearDimension
import kotlinx.css.fontSize
import kotlinx.css.padding
import kotlinx.serialization.json.Json
import react.*
import styled.css
import styled.styledDiv

fun RBuilder.softwareDetail(
    software: Software?, onSave: (Software) -> Unit, product: Product
) = child(SoftwareDetail::class) {
    attrs.software = software
    attrs.onSave = onSave
    attrs.product = product
}

class SoftwareDetail(props: Props) : CComponent<SoftwareDetail.Props, SoftwareDetail.State>(props) {

    private val Props.initial
        get() = software ?: Software(
            id = "",
            name = "",
            uploadToken = "<<will be generated>>",
            product = Product.CONNECT
        )

    private var saveJob: Job = Job()

    interface Props : RProps {
        var software: Software?
        var onSave: (Software) -> Unit
        var product: Product
    }

    class State(
        var changed: Software,
    ) : RState

    init {
        state = State(changed = props.initial)
    }

    override fun componentWillReceiveProps(nextProps: Props) {
        if (nextProps.software != props.software)
            setState { changed = nextProps.initial }
    }

    override fun RBuilder.render() {
        val software = state.changed

        mCard {
            css(GlobalStyles.card)
            mCardContent {
                css(GlobalStyles.cardBoxContent)
                mListSubheader(heading = "Konfiguration")
                mDivider { }
                styledDiv {
                    css { padding(2.spacingUnits) }
                    mGridContainer2(
                        direction = MGridDirection.row
                    ) {
                        mGridItem2(
                            MGridBreakpoints2(MGridSize2.Cells6)
                                .down(Breakpoint.sm, MGridSize2.Cells12)
                        ) {
                            mTextField(
                                label = "Name",
                                value = software.name,
                                variant = MFormControlVariant.outlined,
                                fullWidth = true,
                                margin = MFormControlMargin.dense,
                                onChange = {
                                    val value = it.targetInputValue
                                    setState { changed = changed.copy(name = value) }
                                }
                            )
                        }
                        mGridItem2(
                            MGridBreakpoints2(MGridSize2.Cells6)
                                .down(Breakpoint.sm, MGridSize2.Cells12)
                        ) {
                            mTextField(
                                label = "ID (= Directory Name)",
                                value = software.id,
                                variant = MFormControlVariant.outlined,
                                fullWidth = true,
                                margin = MFormControlMargin.dense,
                                disabled = props.software != null,
                                onChange = {
                                    val value = it.targetInputValue
                                    setState { changed = changed.copy(id = value) }
                                }
                            )
                        }
                        mGridItem2(
                            MGridBreakpoints2(MGridSize2.Cells12)
                        ) {
                            mTextField(
                                label = "Upload Token",
                                value = software.uploadToken,
                                variant = MFormControlVariant.outlined,
                                margin = MFormControlMargin.dense,
                                fullWidth = true,
                                disabled = true
                            ) {
                                attrs.inputProps = jsObject<MInputProps> {
                                    endAdornment = buildElement {
                                        mIconButtonNoTranslate(
                                            size = MButtonSize.small,
                                            iconName = "assignment",
                                            onClick = {
                                                println(window.asDynamic().isSecureContext)
                                                navigator.clipboard.writeText(software.uploadToken)
                                            }
                                        ) {
                                            css { fontSize = LinearDimension.inherit }
                                        }
                                    }!!
                                }
                            }
                        }
                    }
                }
            }

            if (software != props.initial || props.software == null) {
                mDivider { }
                mCardActions {
                    css { padding(2.spacingUnits) }
                    mGridContainer2(
                        direction = MGridDirection.row
                    ) {
                        mGridItem2(
                            MGridBreakpoints2(MGridSize2.Auto)
                                .down(Breakpoint.xs, MGridSize2.Cells12)
                        ) {
                            mButton(
                                caption = "Übernehmen",
                                variant = MButtonVariant.contained,
                                color = MColor.secondary,
                                disabled = !software.isIdValid() || software.name.isBlank(),
                                onClick = {
                                    if (props.software == null) {
                                        addSoftware(new = software.copy(product = props.product))
                                    } else {
                                        changeSoftware(software = software)
                                    }
                                }) {
                                attrs.disableElevation = true
                                attrs.fullWidth = true
                            }
                        }
                        mGridItem2(
                            MGridBreakpoints2(MGridSize2.Auto)
                                .down(Breakpoint.xs, MGridSize2.Cells12)
                        ) {
                            mButton(
                                caption = "Abbrechen",
                                onClick = { setState { changed = props.initial } },
                                color = MColor.default,
                                variant = MButtonVariant.contained,
                            ) {
                                attrs.disableElevation = true
                                attrs.fullWidth = true
                            }
                        }
                    }
                }
            }
        }
    }

    private fun addSoftware(new: Software) {
        saveJob.cancel()
        saveJob = launch {
            withContext(Dispatchers.Default) {
                portalRestApi.put(
                    path = "/software/management",
                    body = Json.encodeToString(Software.serializer(), new)
                )
            }
            props.onSave(new)
        }
    }

    private fun changeSoftware(software: Software) {
        saveJob.cancel()
        saveJob = launch {
            withContext(Dispatchers.Default) {
                portalRestApi.post(
                    path = "/software/management/${software.id}",
                    body = Json.encodeToString(Software.serializer(), software)
                )
            }
            props.onSave(software)
        }
    }
}