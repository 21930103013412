package de.geomobile.common.portalmodels

import de.geomobile.server.device.config.DeviceConfigFilter
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonObject

@Serializable
data class DeviceConfig(
    val deactivateConfigs: Boolean,
    val currentConfig: JsonObject,
    val individualConfigOverlay: JsonObject,
    val configFilterCascade: List<DeviceConfigFilter>,
    val generatedConfigOverlay: JsonObject
)