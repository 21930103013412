package de.geomobile.common.portalmodels

import de.geomobile.common.time.LocalDateTime
import kotlinx.serialization.Serializable

@Serializable
data class DeviceCDADTO(
    val companySmall: CompanySmall? = null,
    val timestampReceived: LocalDateTime? = null,
    val timestampDeployed: LocalDateTime? = null,
    val timestampOnBox: LocalDateTime? = null,
    val versionInSystem: String? = null,
    val versionOnBox: String? = null,
    val status: CdaStatus? = null,
    val remoteStatus: CdaRemoteStatus? = null
) {
    enum class CdaStatus {
        CURRENT,
        OUTDATED,
        UNSET_IN_PROFILE,
        UNSET_ON_BOX,
        ERROR
    }
    enum class CdaRemoteStatus(val readableName: String) {
        OUTDATED_LOCAL("Veraltet (lokal)"),
        OUTDATED_FTP("Veraltet (FTP)"),
        CURRENT("Aktuell"),
        UPDATED_LOCAL("Aktualisiert (lokal)"),
        UPDATED_FTP("Aktualisiert (FTP)"),
        ERROR_FTP("Fehler (FTP)"),
        ERROR_LOCAL("Fehler (lokal)"),
    }
}