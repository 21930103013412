package de.geomobile.frontend

import de.geomobile.common.feature.Feature
import de.geomobile.common.permission.Permission
import de.geomobile.common.portalmodels.UserDTO
import de.geomobile.frontend.auth.AuthenticationRequiredObserver
import kotlinx.browser.localStorage
import kotlinx.serialization.json.Json
import org.w3c.dom.get
import org.w3c.dom.set

object UserStore {

    val reloadListeners = mutableListOf<() -> Unit>()

    val user: UserDTO?
        get() = if (debugMode) debugUser ?: realUser else realUser

    val token: String?
        get() = realToken?.let { if (debugMode) "DEBUG.$it" else it }

    val permissions: Set<Permission> get() = user?.role?.permissions.orEmpty()
    val features: Set<Feature> get() = user?.company?.features.orEmpty()

    var debugMode: Boolean = try {
        if (localStorage["debugUser"] != null)
            localStorage["debugMode"]?.toBoolean() ?: false
        else
            false
    } catch (e: Exception) {
        false
    }
        set(value) {
            if (value) {
                localStorage["debugMode"] = value.toString()
            } else {
                localStorage.removeItem("debugMode")
            }
            field = value
        }

    var realUser: UserDTO? = try {
        localStorage["user"]?.let { Json.decodeFromString(UserDTO.serializer(), it) }
    } catch (e: Exception) {
        null
    }
        set(value) {
            if (value == null) {
                localStorage.removeItem("user")
            } else {
                localStorage["user"] = Json.encodeToString(UserDTO.serializer(), value)
            }
            field = value
        }

    var realToken: String? = try {
        localStorage["token"]
    } catch (e: Exception) {
        null
    }
        set(value) {
            if (value == null) {
                localStorage.removeItem("token")
            } else {
                localStorage["token"] = value
            }
            field = value
        }

    var debugUser: UserDTO? = try {
        localStorage["debugUser"]?.let { Json.decodeFromString(UserDTO.serializer(), it) }
    } catch (e: Exception) {
        null
    }
        set(value) {
            if (value == null) {
                localStorage.removeItem("debugUser")
            } else {
                localStorage["debugUser"] = Json.encodeToString(UserDTO.serializer(), value)
            }
            field = value
        }

    val authenticationRequiredObserver = AuthenticationRequiredObserver {
        clear()
        triggerReload()
    }

    fun triggerReload() = reloadListeners.forEach { it() }

    fun clear() {
        realToken = null
        realUser = null
        debugUser = null
        debugMode = false
    }
}