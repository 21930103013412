package de.geomobile.frontend.features.admin

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.menu.mMenuItem
import de.geomobile.common.feature.Feature
import de.geomobile.common.feature.Features
import de.geomobile.common.permission.Permission
import de.geomobile.common.permission.Permissions
import de.geomobile.common.portalmodels.Company
import de.geomobile.common.portalmodels.DeviceIdentifier
import de.geomobile.common.portalmodels.UserDTO
import de.geomobile.common.portalmodels.small
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.features.admin.companies.adminCompanies
import de.geomobile.frontend.features.admin.roles.adminRoles
import de.geomobile.frontend.features.admin.sharedDevices.sharedDevices
import de.geomobile.frontend.features.admin.users.adminUsers
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.spacer
import de.geomobile.frontend.utils.CComponent
import de.geomobile.frontend.utils.hasFeature
import de.geomobile.frontend.utils.isAuthorized
import de.geomobile.frontend.utils.mToolbarTitle2
import kotlinx.browser.localStorage
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.async
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.*
import kotlinx.serialization.builtins.ListSerializer
import org.w3c.dom.get
import org.w3c.dom.set
import react.*
import react.router.dom.redirect
import react.router.dom.route
import react.router.dom.switch
import styled.css
import styled.styledDiv

fun RBuilder.adminDashboard(
    path: String,
    drawerMenu: ReactElement,
    onDeviceClick: (id: DeviceIdentifier) -> Unit,
) = child(AdminDashboard::class) {
    attrs.path = path
    attrs.drawerMenu = drawerMenu
    attrs.onDeviceClick = onDeviceClick
}

class AdminDashboard : CComponent<AdminDashboard.Props, AdminDashboard.State>() {

    private val tabs = listOf(
        Tab(
            label = "Benutzer", route = "/users",
            viewPermission = Permissions.UserManagement.usersView
        ) { adminUsers(path = it) },
        Tab(
            label = "Rollen",
            route = "/roles",
            viewPermission = Permissions.RoleManagement.rolesView
        ) {
            adminRoles(path = it)
        },
        Tab(
            label = "Unternehmen",
            route = "/companies",
            viewPermission = Permissions.CompanyManagement.companiesView
        ) { adminCompanies(path = it) },
        Tab(
            label = "Geteilte Geräte",
            route = "/sharedDevices",
            requiredFeature = Features.CompanyFeatures.shareDevices,
            requiresCompanySelect = true
        ) { sharedDevices(onDeviceClick = props.onDeviceClick, company = state.company?.small) },
    )

    private data class Tab(
        val label: String,
        val route: String,
        val viewPermission: Permission? = null,
        val requiredFeature: Feature? = null,
        val requiresCompanySelect: Boolean = false,
        val builder: RBuilder.(path: String) -> ReactElement,
    )

    interface Props : RProps {
        var path: String
        var drawerMenu: ReactElement
        var onDeviceClick: (id: DeviceIdentifier) -> Unit
    }

    class State(
        var company: Company? = null,
        var companies: List<Company>? = null,
    ) : RState

    init {
        state = AdminDashboard.State()
    }

    override fun componentDidMount() {
        launch {
            val deferredCompanies = if (isAuthorized(Permissions.VehicleProfileManagement.notRestrictedToCompany))
                async(Dispatchers.Default) {
                    portalRestApi.get("/admin/companies", ListSerializer(Company.serializer()))
                }
            else
                null

            val ownUser =
                withContext(Dispatchers.Default) {
                    portalRestApi.get("/user", UserDTO.serializer())
                }

            val companies = deferredCompanies?.await()

            val company =
                if (isAuthorized(Permissions.AdminPermissions.internalAccess)) {
                    if (localStorage["AdminCompany"] == null) {
                        localStorage["AdminCompany"] = ownUser.company.id
                        ownUser.company
                    } else {
                        companies?.first { it.id == localStorage["AdminCompany"] }
                    }

                } else {
                    ownUser.company
                }

            setState {
                this.company = company
                this.companies = companies
            }
        }
    }

    override fun RBuilder.render() {
        val companies = state.companies
        val permittedTabs = tabs.filter { isAuthorized(*listOfNotNull(it.viewPermission).toTypedArray()) }
            .filter {
                if (it.requiredFeature == null) true
                else hasFeature(it.requiredFeature)
            }

        route<RProps>(props.path) { routeProps ->
            mAppBar(position = MAppBarPosition.fixed) {
                css(GlobalStyles.appbar)

                val currentRoute = routeProps.history.location.pathname
                val selectedTab =
                    permittedTabs.firstOrNull { currentRoute.startsWith(props.path + it.route) }
                        ?: permittedTabs.first()

                mToolbar {
                    child(props.drawerMenu)
                    mToolbarTitle2("Admin")

                    if (companies != null && selectedTab.requiresCompanySelect) {
                        mTypography(text = "Unternehmen", color = MTypographyColor.inherit) {
                            css {
                                display = Display.inline; marginRight = 1.spacingUnits
                            }
                        }
                        mSelect(
                            value = state.company?.id ?: "",
                            name = "company",
                            id = "company",
                            onChange = { event, _ ->
                                val id = event.targetValue as String
                                localStorage["AdminCompany"] = id
                                setState { this.company = companies.first { it.id == id } }
                            }
                        ) {
                            css {
                                marginTop = 2.pt
                                color = kotlinx.css.Color.white
                            }
                            for (company in companies) {
                                mMenuItem(primaryText = company.name, value = company.id)
                            }
                        }
                    }
                }

                mTabs(
                    props.path + selectedTab.route,
                    centered = true,
                    onChange = { _, value -> routeProps.history.push(value as String) }
                ) {
                    for (tab in permittedTabs) {
                        mTab(tab.label, props.path + tab.route)
                    }
                }
            }
        }
        spacer()
        spacer()

        styledDiv {
            css { padding(2.spacingUnits) }
            switch {

                for (tab in permittedTabs) {
                    route<RProps>(props.path + tab.route) { routeProps -> tab.builder(this, routeProps.match.path) }
                }

                redirect(from = props.path, to = props.path + permittedTabs.first().route, exact = true)
            }
        }
    }
}