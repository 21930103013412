package portalmodels

import de.geomobile.common.time.LocalDateTime
import kotlinx.serialization.Serializable

enum class ElaUpdateStatus(val readableName: String) {
    NONE("None"),
    DEBUG("Debug"),
    LIVE("Live");

    companion object {
        private val mapping = values().associateBy(ElaUpdateStatus::readableName)
        fun fromReadable(readableName: String) = mapping[readableName]
    }
}

@Serializable
data class ElaDeployedDTO(
    var deployedUpdates: List<ElaUpdateHistoryDTO> = listOf()
)

@Serializable
data class ElaStashedDTO(
    var stashedUpdates: List<ElaUpdateHistoryDTO> = listOf()
)

@Serializable
data class ElaStatisticsDTO(
    var outOfDateId: List<String> = listOf(),
    var totalElaBoxes: Int = 0
)

@Serializable
data class ElaUpdateHistoryDTO(
        var updateId: Int? = null,
        var timestamp: LocalDateTime? = null,
        var updateType: String? = null,
        var updateCounter: Int? = null,
        var status: ElaUpdateStatus? = null,
        var filename: String? = null,
        var extension: String? = null,
        var software: String? = null,
        var ini: String? = null,
        var mp3: String? = null
)

// TODO: Clean up
//val ElaUpdateDTO.historyForMQTT: () -> String
//    get() = {
//        var lst:List<JsonElement> = deployedUpdates.map {
//            Json.toJson(ElaUpdateHistoryDTO.serializer(), it)
//        }
//        JsonArray(lst).toString()
//    }

