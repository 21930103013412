package de.geomobile.frontend.features.device.detail

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardActionArea
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.styles.Breakpoint
import com.ccfraser.muirwik.components.transitions.mCollapse
import de.geomobile.common.feature.Feature
import de.geomobile.common.feature.Features
import de.geomobile.common.permission.Permission
import de.geomobile.common.permission.Permissions
import de.geomobile.common.portalmodels.DeviceIdentifier
import de.geomobile.common.portalmodels.Product
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.device.detail.trialRibbon
import de.geomobile.frontend.features.device.detail.internal.*
import de.geomobile.frontend.features.device.detail.selftest.deviceSelfTest
import de.geomobile.frontend.features.device.deviceIdResolver
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.utils.*
import hardware.HardwareFeature
import kotlinext.js.assign
import kotlinx.browser.localStorage
import kotlinx.browser.window
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import kotlinx.css.*
import kotlinx.serialization.builtins.MapSerializer
import kotlinx.serialization.builtins.serializer
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import org.w3c.dom.get
import org.w3c.dom.set
import react.RBuilder
import react.RProps
import react.RState
import react.setState
import styled.css

fun RBuilder.deviceDetail(
    identifier: DeviceIdentifier,
    goBack: () -> Unit,
) = deviceIdResolver(identifier) { id ->
    child(DeviceDetail::class) {
        attrs.identifier = id
        attrs.goBack = goBack
    }
}

class DeviceDetail : CComponent<DeviceDetail.Props, DeviceDetail.State>() {

    interface Props : RProps {
        var identifier: DeviceIdentifier.Id
        var goBack: () -> Unit
    }

    class State(
        var product: Product? = null,
        var expandMap: MutableMap<String, Boolean> = mutableMapOf(),
    ) : RState

    init {
        state = State()
    }

    private fun saveExpandState(expandKey: String) {
        setState({
            assign(it) {
                expandMap[expandKey] = expandMap[expandKey] != true
            }
        }) {
            localStorage["deviceDetailExpandMap"] = Json.encodeToString(state.expandMap.toMap())
        }
    }

    private fun loadExpandState() {
        val map = localStorage["deviceDetailExpandMap"]?.let {
            Json.decodeFromString(
                MapSerializer(
                    String.serializer(),
                    Boolean.serializer()
                ), it
            )
        }
        if (map != null) {
            setState {
                expandMap = map.toMutableMap()
            }
        }
    }

    private fun expandIcon(feature: String): String {
        return if (state.expandMap[feature] == true) "expand_less" else "expand_more"
    }

    override fun componentDidMount() {
        loadExpandState()
        unsetCompatibleFeatures()
        window.scroll(0.0, 0.0)

        launch(Dispatchers.Default) {
            // Server checks the current Hardware Version of the Device and sends back a list of compatible features
            val hardwareVersion = portalRestApi.getRaw("/device/${props.identifier.id}/hardwarefeatures")
            if (hardwareVersion.isNotBlank()) {
                setCompatibleFeaturesRaw(hardwareVersion)
            }
            portalRestApi.post("/device/${props.identifier.id}/ping")

            //get product (connect or core) to display Features accordingly
            val product = portalRestApi.get("/device/${props.identifier.id}/product", Product.serializer())
            setState {
                this.product = product
            }
        }
    }

    private fun RBuilder.deviceFeature(
        title: String,
        hardwareFeature: HardwareFeature?,
        trialFeature: Feature?,
        expandKey: String,
        vararg permissions: Permission,
        handler: RBuilder.() -> Unit
    ) {
        authorize(*permissions) {
            hardwareCompatible(hardwareFeature) {
                mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                    mCard {
                        css(GlobalStyles.card)
                        css { position = Position.relative }
                        if (trialFeature != null && isTrial(trialFeature)) trialRibbon()
                        mCardActionArea(
                            onClick = {
                                saveExpandState(expandKey)
                            },
                        ) {
                            attrs.component = "div"
                            mCardHeaderExtended(
                                title = title,
                                action = mIconButtonNoTranslate(
                                    expandIcon(expandKey),
                                    onClick = { event ->
                                        run {
                                            saveExpandState(expandKey)
                                            event.stopPropagation()
                                        }
                                    },
                                    addAsChild = false
                                ) {
                                    attrs.component = "div"
                                }
                            )
                        }
                        mCollapse(state.expandMap[expandKey] == true) {
                            mDivider {}
                            mCardContent {
                                css(GlobalStyles.cardBoxContent)
                                handler()
                            }
                        }
                    }
                }
            }
        }
    }

    override fun RBuilder.render() {
        val id = props.identifier.id

        deviceDetailToolbar(id, props.goBack)
        mGridContainer2(direction = MGridDirection.row) {

            authorize(Permissions.DeviceManagement.map) {
                hardwareCompatible(HardwareFeature.GPS) {
                    mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                        mCard {
                            css { position = Position.relative }
                            if (isTrial(Features.DeviceFeatures.map)) trialRibbon()
                            mCardActionArea(
                                onClick = {
                                    saveExpandState("gpsMap")
                                },
                            ) {
                                attrs.component = "div"
                                mCardHeaderExtended(
                                    title = "GPS & Karte",
                                    action = mIconButtonNoTranslate(
                                        expandIcon("gpsMap"),
                                        onClick = { event ->
                                            run {
                                                saveExpandState("gpsMap")
                                                event.stopPropagation()
                                            }
                                        },
                                        addAsChild = false
                                    ) {
                                        attrs.component = "div"
                                    }
                                )
                            }
                            mCollapse(state.expandMap["gpsMap"] == true) {
                                mDivider {}
                                mCardContent {
                                    mGridContainer2(direction = MGridDirection.row) {
                                        mGridItem2(
                                            MGridBreakpoints2(MGridSize2.Cells6)
                                                .down(Breakpoint.md, MGridSize2.Cells12)
                                        ) {
                                            deviceDetailMap(id)
                                        }
                                        mGridItem2(
                                            MGridBreakpoints2(MGridSize2.Cells6)
                                                .down(Breakpoint.md, MGridSize2.Cells12)
                                        ) {
                                            deviceGps(id)
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            deviceFeature(
                title = "Status",
                hardwareFeature = null,
                trialFeature = null,
                expandKey = "status",
                Permissions.DeviceManagement.deviceStatus
            ) {
                deviceStatus(
                    id = id,
                    onDeviceDetailPage = true
                )
            }

            authorize(Permissions.DeviceManagement.vehicleOccupancy) {
                hardwareCompatible(HardwareFeature.PASSENGER_COUNT) {
                    mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                        passengerLive(
                            id,
                            passengerExpanded = state.expandMap["passengerLive"] == true,
                            onPassengerExpanded = {
                                saveExpandState("passengerLive")
                            }
                        )
                    }
                }
            }

            deviceFeature(
                title = "Software",
                hardwareFeature = HardwareFeature.SOFTWARE,
                trialFeature = Features.DeviceFeatures.software,
                expandKey = "software",
                Permissions.DeviceManagement.software
            ) {
                softwareStatus(id)
            }

            deviceFeature(
                title = "Software Detail",
                hardwareFeature = HardwareFeature.SOFTWARE,
                trialFeature = Features.DeviceFeatures.software,
                expandKey = "softwareDetail",
                Permissions.DeviceManagement.softwareDetail
            ) {
                softwareInternalStatus(
                    id,
                    state.product ?: Product.CONNECT,
                    softwareInternalExpanded = state.expandMap["softwareInternal"] == true,
                    onSoftwareInternalExpanded = {
                        saveExpandState("softwareInternal")
                    }
                )
            }

            deviceFeature(
                title = "Audio",
                hardwareFeature = HardwareFeature.AUDIO,
                trialFeature = Features.DeviceFeatures.audioConfig,
                expandKey = "audio",
                Permissions.DeviceManagement.audio
            ) {
                deviceAudio(id)
            }

            deviceFeature(
                title = "ELA",
                hardwareFeature = HardwareFeature.ELA,
                trialFeature = Features.CompanyFeatures.ela,
                expandKey = "ela",
                Permissions.DeviceManagement.ela
            ) {
                elaAudio(
                    id,
                    elaAudioUpdateExpanded = state.expandMap["elaAudioUpdate"] == true,
                    onElaAudioUpdateExpanded = {
                        saveExpandState("elaAudioUpdate")
                    },
                    elaAudioExpanded = state.expandMap["elaAudio"] == true,
                    onElaAudioExpanded = {
                        saveExpandState("elaAudio")
                    }
                )
            }

            deviceFeature(
                title = "IBIS",
                hardwareFeature = HardwareFeature.IBIS_INFO,
                trialFeature = Features.DeviceFeatures.busAccess,
                expandKey = "ibisInfo",
                Permissions.DeviceManagement.busAccess
            ) {
                ibisStatus(id)
            }

            deviceFeature(
                title = "IBIS Info v18",
                hardwareFeature = HardwareFeature.IBIS_INFO_V18,
                trialFeature = Features.DeviceFeatures.busAccessV18,
                expandKey = "ibisInfoV18",
                Permissions.DeviceManagement.busAccessV18
            ) {
                ibisV18(id, readOnly = true)
            }

            deviceFeature(
                title = "IBIS Schnelleinstellungen",
                hardwareFeature = HardwareFeature.IBIS_INFO_V18,
                trialFeature = Features.DeviceFeatures.busAccessV18,
                expandKey = "ibisInfoV18Write",
                Permissions.DeviceManagement.busAccessV18Write
            ) {
                ibisV18(id, readOnly = false)
            }

            deviceFeature(
                title = "Fahrgastinformationen",
                hardwareFeature = HardwareFeature.IBIS_INFO_V18,
                trialFeature = null,
                expandKey = "ibisTripInfo",
                Permissions.DeviceManagement.tripInfo
            ) {
                genericInfo(id, GenericInfoType.TRIP)
            }

            deviceFeature(
                title = "Zugverband",
                hardwareFeature = HardwareFeature.IBIS_INFO_V18,
                trialFeature = null,
                expandKey = "ibisTripInfo",
                Permissions.DeviceManagement.trainSetInfo
            ) {
                genericInfo(id, GenericInfoType.TRAIN_SET)
            }

            deviceFeature(
                title = "IBIS Geräteerkennung",
                hardwareFeature = HardwareFeature.IBIS_DEVICES,
                trialFeature = Features.DeviceFeatures.ibisSniffer,
                expandKey = "ibisSniffer",
                Permissions.DeviceManagement.ibisSniffer
            ) {
                ibisSniffer(id)
            }

            deviceFeature(
                title = "FMS",
                hardwareFeature = HardwareFeature.FMS,
                trialFeature = Features.VehicleProfileFeatures.fmsProfiles,
                expandKey = "fms",
                Permissions.DeviceManagement.fms
            ) {
                fmsStatus(id)
            }


            hardwareCompatible(HardwareFeature.CDA) {
                authorize(Permissions.DeviceManagement.cda) {
                    mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                        cdaStatus(
                            id,
                            expanded = state.expandMap["cdaStatus"] == true,
                            onExpand = {
                                saveExpandState("cdaStatus")
                            },
                        )
                    }
                }
            }

            deviceFeature(
                title = "Mobilfunk",
                hardwareFeature = HardwareFeature.CELLULAR,
                trialFeature = Features.DeviceFeatures.cellular,
                expandKey = "cellular",
                Permissions.DeviceManagement.cellular
            ) {
                cellularStatus(id)
            }

            deviceFeature(
                title = "LAN",
                hardwareFeature = HardwareFeature.LAN,
                trialFeature = Features.DeviceFeatures.lan,
                expandKey = "lan",
                Permissions.DeviceManagement.lan
            ) {
                lanStatus(id)
            }

            deviceFeature(
                title = "WLAN",
                hardwareFeature = HardwareFeature.WIRELESS,
                trialFeature = Features.DeviceFeatures.wifi,
                expandKey = "wifi",
                Permissions.DeviceManagement.wifi
            ) {
                wifiStatus(id)
            }

            deviceFeature(
                title = "Reparaturen",
                hardwareFeature = null,
                trialFeature = Features.DeviceFeatures.repairHistory,
                expandKey = "repair",
                Permissions.DeviceManagement.repairHistoryUser
            ) {
                deviceRepair(id)
            }

            deviceFeature(
                title = "Überprüfung der Verkabelung",
                hardwareFeature = HardwareFeature.CABLE_CHECK,
                trialFeature = Features.DeviceFeatures.cableCheck,
                expandKey = "cableCheck",
                Permissions.DeviceManagement.cableCheck
            ) {
                deviceCableCheck(id)
            }

            deviceFeature(
                title = "Überprüfung der Verkabelung V2",
                hardwareFeature = HardwareFeature.CABLE_CHECK_V2,
                trialFeature = Features.DeviceFeatures.cableCheck,
                expandKey = "cableCheckV2",
                Permissions.DeviceManagement.cableCheck
            ) {
                deviceCableCheckV2(id)
            }

            deviceFeature(
                title = "Selbsttest",
                hardwareFeature = HardwareFeature.SELF_CHECK,
                trialFeature = Features.DeviceFeatures.selfTest,
                expandKey = "selfTest",
                Permissions.DeviceManagement.selfTest
            ) {
                deviceSelfTest(id)
            }

            deviceFeature(
                title = "Elektrische Baugruppen erfassen",
                hardwareFeature = null,
                trialFeature = Features.DeviceFeatures.individualParts,
                expandKey = "qrCode",
                Permissions.DeviceManagement.individualParts
            ) {
                registerParts(id)
            }
        }
        authorize(Permissions.AdminPermissions.internalAccess) {
            mDivider { css { width = 100.pct; margin(5.spacingUnits, 0.spacingUnits) } }

            mGridContainer2 {
                mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                    mTypography(
                        text = "Intern".uppercase(),
                        variant = MTypographyVariant.h5,
                        align = MTypographyAlign.center,
                        color = MTypographyColor.primary
                    ) { css { marginBottom = 1.spacingUnits } }
                }

                deviceFeature(
                    title = "Eigenschaften",
                    hardwareFeature = null,
                    trialFeature = null,
                    expandKey = "deviceInternal"
                ) {
                    deviceInternalStatus(
                        id = id,
                        onDeviceDetailPage = true
                    )
                }

                authorize(Permissions.DeviceManagement.vehicleOccupancy) {
                    hardwareCompatible(HardwareFeature.PASSENGER_COUNT) {
                        mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
                            passengerLive(
                                id,
                                passengerExpanded = state.expandMap["passengerInternal"] == true,
                                onPassengerExpanded = {
                                    saveExpandState("passengerInternal")
                                },
                                true
                            )
                        }
                    }
                }

                deviceFeature(
                    title = "SSH",
                    hardwareFeature = null,
                    trialFeature = null,
                    expandKey = "ssh"
                ) {
                    sshStatus(id, "ssh")
                }

                // Only available on ivantoConnect
                if (state.product == Product.CONNECT) {
                    deviceFeature(
                        title = "SSH Tunnel (Beta)",
                        hardwareFeature = null,
                        trialFeature = null,
                        expandKey = "sshTunnel"
                    ) {
                        sshStatus(id, "tunnel")
                    }
                }

                deviceFeature(
                    title = "Config",
                    hardwareFeature = null,
                    trialFeature = null,
                    expandKey = "config"
                ) {
                    configStatus(id)
                }

                deviceFeature(
                    title = "M2M Log",
                    hardwareFeature = null,
                    trialFeature = null,
                    expandKey = "m2mLog"
                ) {
                    m2mLog(id)
                }
            }
        }
    }
}