package de.geomobile.frontend.features.device.detail

import com.ccfraser.muirwik.components.MTypographyColor
import com.ccfraser.muirwik.components.MTypographyVariant
import com.ccfraser.muirwik.components.list.mList
import com.ccfraser.muirwik.components.list.mListItem
import com.ccfraser.muirwik.components.list.mListItemText
import com.ccfraser.muirwik.components.mTypography
import com.ccfraser.muirwik.components.spacingUnits
import de.geomobile.common.portalmodels.SoftwareStatusDTO
import de.geomobile.frontend.portalWebSocketApi
import de.geomobile.frontend.utils.timestampStatus
import kotlinx.css.padding
import kotlinx.serialization.json.Json
import react.*
import styled.css
import styled.styledDiv

fun RBuilder.softwareStatus(
    id: Int,
) = child(SoftwareStatusComponent::class) {
    key = id.toString()
    attrs.id = id
}

class SoftwareStatusComponent : RComponent<SoftwareStatusComponent.Props, SoftwareStatusComponent.State>() {

    private val deviceSoftwareSocket by kotlin.lazy {
        portalWebSocketApi.subscribe("/device/software", mapOf("id" to props.id.toString()))
    }

    interface Props : RProps {
        var id: Int
    }

    class State(
        var softwareStatus: SoftwareStatusDTO = SoftwareStatusDTO(),
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        deviceSoftwareSocket.connect {
            onmessage = {
                val status = Json.decodeFromString(SoftwareStatusDTO.serializer(), it)
                setState {
                    this.softwareStatus = status
                }
            }
        }
    }

    override fun componentWillUnmount() {
        deviceSoftwareSocket.close()
    }

    override fun RBuilder.render() {
        val softwareStatus = state.softwareStatus

        styledDiv {
            css { padding(2.spacingUnits) }

            mList {
                attrs.dense = true

                mListItem {
                    attrs.button = false
                    attrs.divider = true

                    mListItemText {
                        mTypography(
                            text = "Installierte Version",
                            variant = MTypographyVariant.caption,
                        )
                        mTypography(
                            text = softwareStatus.lastInstalled ?: "-",
                            variant = MTypographyVariant.subtitle2,
                            color = MTypographyColor.textPrimary
                        )
                    }
                }

                if (softwareStatus.lastInstalled != softwareStatus.assigned)
                    mListItem {
                        attrs.button = false
                        attrs.divider = true

                        mListItemText {
                            mTypography(
                                text = "Zugewiesene Version",
                                variant = MTypographyVariant.caption,
                            )
                            mTypography(
                                text = softwareStatus.assigned ?: "-",
                                variant = MTypographyVariant.subtitle2,
                                color = MTypographyColor.textPrimary
                            )
                        }
                    }

                mListItem {
                    attrs.button = false
                    attrs.divider = true

                    mListItemText {
                        mTypography(
                            text = "Status",
                            variant = MTypographyVariant.caption,
                        )
                        mTypography(
                            text = softwareStatus.updateStatus ?: "-",
                            variant = MTypographyVariant.subtitle2,
                            color = MTypographyColor.textPrimary
                        )
                    }
                }

                mListItem {
                    attrs.button = false
                    attrs.divider = true

                    mListItemText {
                        mTypography(
                            text = "Update Fortschritt",
                            variant = MTypographyVariant.caption,
                        )
                        mTypography(
                            text = if(softwareStatus.updateProgress != -1) "${softwareStatus.updateProgress}%" else "-",
                            variant = MTypographyVariant.subtitle2,
                            color = MTypographyColor.textPrimary
                        )
                    }
                }

                mListItem {
                    attrs.button = false
                    attrs.divider = true

                    mListItemText {
                        mTypography(
                            text = "Letzter Abgleich",
                            variant = MTypographyVariant.caption,
                        )
                        styledDiv {
                            timestampStatus(softwareStatus.lastPoll)
                        }
                    }
                }
            }
        }
    }
}