package de.geomobile.common.portalmodels

import de.geomobile.common.feature.Feature
import de.geomobile.common.stage.Stage
import kotlinx.serialization.Serializable

val companyAlias = mapOf(
    "BREITENBACH" to listOf("breitenbach"),
    "BVG" to listOf("bvg"),
    "DEVELOPER" to listOf("developer"),
    "GEOMOBILE" to listOf("geomobile"),
    "HEAG" to listOf("heag"),
    "HERFORD" to listOf("herford"),
    "KREIS SOEST" to listOf("kreissoest"),
    "PADERSPRINTER" to listOf("pader"),
    "PRODUCTION" to listOf("production"),
    "RLG" to listOf("rlg"),
    "RUHRBAHN" to listOf("rbe","rbm"),
    "SCHWERTHEIM" to listOf("schwertheim"),
    "SWT" to listOf("swt"),
    "TRASH" to listOf("trash"),
    "UNDEFINED" to listOf("undefined"),
    "VAGFR" to listOf("vagf"),
    "VKU" to listOf("vku"),
    "WESTFALENBUS" to listOf("westfalenbus"),
    "WVG" to listOf("rvm"),
    "Öffis Hameln" to listOf("oeffis"),
    "MESSE" to listOf("messe"),
)

@Serializable
data class Company(
    val id: String,
    val name: String,
    val description: String? = null,
    val originator: Boolean = false,
    val features: Set<Feature>,
    val stages: Set<Stage>,
    val shareDevicesWith: Set<CompanySmall> = emptySet()
)

@Serializable
data class CompanySmall(
    val id: String,
    val name: String
)

val Company.small get() = CompanySmall(id = id, name = name)