package de.geomobile.frontend.features.dashboard

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.card.mCard
import com.ccfraser.muirwik.components.card.mCardContent
import com.ccfraser.muirwik.components.list.mListSubheader
import com.ccfraser.muirwik.components.styles.Breakpoint
import de.geomobile.frontend.GlobalStyles
import de.geomobile.frontend.components.renderCountItem
import de.geomobile.frontend.components.renderDivider
import de.geomobile.frontend.components.statusCountView
import de.geomobile.frontend.utils.*
import de.geomobile.frontend.utils.MGridJustify
import kotlinx.css.*
import portalmodels.DashboardStatusSummaryDTO
import react.RBuilder
import react.RProps
import react.RState
import react.buildElement
import styled.css

fun RBuilder.dashboardHardware(
    company: String?,
    loading: Boolean,
    summaries: DashboardStatusSummaryDTO?,
) = child(DashboardHardware::class) {
    attrs.company = company
    attrs.loading = loading
    attrs.summaries = summaries
//        .filterKeys { it != Product.CORE } // Uncomment to only show 2 products not all 3.
}

class DashboardHardware : CComponent<DashboardHardware.Props, DashboardHardware.State>() {
    
    interface Props : RProps {
        var company: String?
        var loading: Boolean
        var summaries: DashboardStatusSummaryDTO?
    }

    class State : RState

    init {
        state = State()
    }

    fun RBuilder.topperOnline() =
        mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
            mTypography(
                text = "Online".uppercase(),
                variant = MTypographyVariant.h5,
                color = MTypographyColor.primary,
                align = MTypographyAlign.center,
            ) {
                css {
                    fontWeight = FontWeight.bolder;
                    letterSpacing = 18.px;
                    marginRight = (-18).px;
                    marginBottom = 2.spacingUnits
                }
            }
    }

    fun RBuilder.topperProfile() =
        mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
            mTypography(
                text = "Profile".uppercase(),
                variant = MTypographyVariant.h5,
                color = MTypographyColor.primary,
                align = MTypographyAlign.center
            ) {
                css {
                    paddingTop = 2.spacingUnits;
                    fontWeight = FontWeight.bolder;
                    letterSpacing = 14.px;
                    marginRight = (-14).px;
                    marginBottom = 2.spacingUnits
                }
            }
        }


    fun RBuilder.topperReparatur() =
        mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
            mTypography(
                text = "Reparatur".uppercase(),
                variant = MTypographyVariant.h6,
                color = MTypographyColor.primary,
                align = MTypographyAlign.center
            ) {
                css {
                    fontSize = 1.1.rem;
                    fontWeight = FontWeight.bolder;
                    letterSpacing = 8.px;
                    paddingTop = 1.spacingUnits;
                    marginRight = (-8).px;
                }
            }
        }

    fun RBuilder.topperGelagert() =
        mGridItem2(MGridBreakpoints2(MGridSize2.Cells12)) {
            mTypography(
                text = "Gelagert".uppercase(),
                variant = MTypographyVariant.h6,
                color = MTypographyColor.primary,
                align = MTypographyAlign.center
            ) {
                css {
                    fontSize = 1.1.rem;
                    fontWeight = FontWeight.bolder;
                    letterSpacing = 11.px;
                    marginRight = (-11).px;
                    paddingTop = 1.spacingUnits;
                }
            }
        }

    fun RBuilder.dividerHardware() =
        mDivider {
            css {
                width = 85.pct
                margin(1.spacingUnits)
                marginTop = 2.spacingUnits
            }
        }

    override fun RBuilder.render() {
        if (props.loading && props.summaries == null)
            mSkeleton(
                height = 150.px,
                animation = MSkeletonAnimation.wave,
                variant = MSkeletonVariant.rect
            )
        else {
            mCard {
                css(GlobalStyles.card)
                mListSubheader(heading = "Hardware") {
                    css { textAlign = TextAlign.center }
                }
                mDivider { }
                mCardContent {
                    css(GlobalStyles.cardDashboardContent)
                    mGridContainer2(
                        direction = MGridDirection.row,
                        alignItems = MGridAlignItems.center,
                        justify = MGridJustify.center
                    ) {
                        props.summaries?.hardwareStatus?.forEach { (product, hwStatus) ->
                            val cells = when(props.summaries?.hardwareStatus?.size) {
                                0 -> MGridSize2.Cells12
                                1 -> MGridSize2.Cells4
                                2 -> MGridSize2.Cells6
                                else -> MGridSize2.Cells4
                            }
                            val breakpoints = MGridBreakpoints2(cells).down(Breakpoint.xs, MGridSize2.Cells12)

                            statusCountView(
                                breakpoints = breakpoints,
                                product = product,
                                children = buildElement {
                                    mGridContainer2(
                                        direction = MGridDirection.row,
                                        alignItems = MGridAlignItems.center,
                                        spacing = MGridSpacing.spacing0,
                                        justify = MGridJustify.spaceAround,
                                    ) {
                                        val linuxCount = hwStatus.linuxOnline
                                        val ivantoCount = hwStatus.ivantoOnline
                                        val offlineCount = hwStatus.offline

                                        topperOnline()

                                        renderCountItem(
                                            caption = "Betriebssystem",
                                            countColor = when (linuxCount) {
                                                0 -> null
                                                else -> Color.green
                                            },
                                            count = linuxCount,
                                        ) { width = 33.pct }
                                        renderDivider()
                                        renderCountItem(
                                            caption = "ivanto",
                                            countColor = when {
                                                ivantoCount == 0 -> null
                                                linuxCount == ivantoCount -> Color.green
                                                linuxCount > ivantoCount -> Color.orange
                                                linuxCount < ivantoCount -> Color.orange
                                                else -> Color.inherit
                                            },
                                            count = ivantoCount
                                        ) { width = 33.pct }
                                        renderDivider()
                                        renderCountItem(
                                            caption = "Offline",
                                            countColor = if (offlineCount >= 1) Color.red else null,
                                            count = offlineCount
                                        ) { width = 33.pct }

                                        val profileOnlineCount = hwStatus.ivantoOnline
                                        val profileOfflineCount = hwStatus.offline

                                        topperProfile()

                                        renderCountItem(
                                            caption = "Zugeordnet",
                                            count = hwStatus.linuxOnline + hwStatus.offline
                                        ) { width = 33.pct }
                                        renderDivider()
                                        renderCountItem(
                                            caption = "Online",
                                            countColor = if (profileOnlineCount >= 1) Color.green else null,
                                            count = profileOnlineCount,
                                        ) { width = 33.pct }
                                        renderDivider()
                                        renderCountItem(
                                            caption = "Offline",
                                            countColor = if (profileOfflineCount >= 1) Color.red else null,
                                            count = profileOfflineCount,
                                        ) { width = 33.pct }
                                    }
                                }
                            ) {
                                maxWidth = 35.pct
                                minWidth = 260.px
                            }
                        }
                    }
                    mGridContainer2(
                        direction = MGridDirection.row,
                        alignItems = MGridAlignItems.center,
                        justify = MGridJustify.center
                    ) {
//                        css { maxWidth = 35.pct }
                        props.summaries?.hardwareStatus?.forEach { (product, hwStatus) ->
                            val cells = when(props.summaries?.hardwareStatus?.size) {
                                0 -> MGridSize2.Cells12
                                1 -> MGridSize2.Cells4
                                2 -> MGridSize2.Cells6
                                else -> MGridSize2.Cells4
                            }

                            val breakpoints = MGridBreakpoints2(cells).down(Breakpoint.xs, MGridSize2.Cells12)

                                statusCountView(
                                    breakpoints = breakpoints,
                                    product = null,
                                    children = buildElement {
                                        mGridContainer2(
                                            direction = MGridDirection.row,
                                            alignItems = MGridAlignItems.center,
                                            spacing = MGridSpacing.spacing0,
                                            justify = MGridJustify.spaceAround,
                                        ) {
                                            dividerHardware()

                                            topperReparatur()

                                            renderCountItem(
                                                size = 1.1.rem,
                                                count = hwStatus.inRepair
                                            )

                                            topperGelagert()

                                            renderCountItem(
                                                size = 1.1.rem,
                                                count = hwStatus.inStorage
                                        )
                                    }
                                }
                            ) {
                                maxWidth = 35.pct
                                minWidth = 260.px
                            }
                        }
                    }
                }
            }
        }
    }
}