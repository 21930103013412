package de.geomobile.common.portalmodels

import de.geomobile.common.errorhandling.ApiError
import kotlinx.serialization.Serializable
import okio.ByteString

@Serializable
data class TopicSubscriptionRequest(
    val requestId: String,
    val topic: String,
    val parameter: Map<String, String> = emptyMap(),
    val metadata: Map<String, String> = emptyMap()
)

@Serializable
data class TopicSubscriptionResponse(
    val handle: Int,
    val path: String
)

sealed class WebSocketPayload {
    data class Text(val data: String) : WebSocketPayload()
    data class Bytes(val data: ByteString) : WebSocketPayload()
}

sealed class WebSocketMessage {

    data class Subscribe(
        val requestId: String,
        val topic: String,
        val parameter: Map<String, String> = emptyMap(),
        val metadata: Map<String, String> = emptyMap()
    ) : WebSocketMessage()

    data class SubscribeOk(
        val requestId: String,
        val handle: Int,
        val path: String
    ) : WebSocketMessage()

    data class SubscribeError(
        val requestId: String,
        val error: ApiError
    ) : WebSocketMessage()

    data class Unsubscribe(
        val handle: Int
    ) : WebSocketMessage()

    data class Event(
        val path: String,
        val payload: WebSocketPayload
    ) : WebSocketMessage()

}