package de.geomobile.frontend.auth

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.button.MButtonVariant
import com.ccfraser.muirwik.components.button.mButton
import com.ccfraser.muirwik.components.form.MFormControlMargin
import com.ccfraser.muirwik.components.form.mFormControl
import com.ccfraser.muirwik.components.form.mFormGroup
import com.ccfraser.muirwik.components.input.mInput
import com.ccfraser.muirwik.components.input.mInputLabel
import com.ccfraser.muirwik.components.styles.Breakpoint
import com.ccfraser.muirwik.components.styles.up
import de.geomobile.common.portalmodels.LoginResponseDTO
import de.geomobile.common.portalmodels.ResetPasswordDTO
import de.geomobile.frontend.UserStore
import de.geomobile.frontend.api.ApiException
import de.geomobile.frontend.api.isInvalidSessionError
import de.geomobile.frontend.currentTheme
import de.geomobile.frontend.portalRestApi
import de.geomobile.frontend.utils.CComponent
import de.geomobile.frontend.utils.mIconNoTranslate
import kotlinx.browser.document
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.*
import kotlinx.html.InputType
import kotlinx.serialization.json.Json
import react.RBuilder
import react.RProps
import react.RState
import react.dom.div
import react.setState
import styled.css
import styled.styledDiv
import styled.styledImg

fun RBuilder.resetPassword(
    token: String,
    loginEvent: () -> Unit,
) = child(ResetPassword::class) {
    attrs.token = token
    attrs.loginEvent = loginEvent
}

class ResetPassword : CComponent<ResetPassword.Props, ResetPassword.State>() {

    private var job: Job = Job()

    interface Props : RProps {
        var token: String
        var loginEvent: () -> Unit
    }

    class State(
        var email: String = "",
        var password: String = "",
        var passwordCheck: String = "",
        var loading: Boolean = true,
        var error: String? = null,
    ) : RState

    init {
        state = State()
    }

    override fun componentDidMount() {
        launch {

            val email = withContext(Dispatchers.Default) {
                portalRestApi.getRaw("/resetpassword?token=${props.token}")
            }

            setState {
                this.loading = false
                this.email = email
            }
        }
    }

    override fun RBuilder.render() {
        val loading = state.loading
        val error = state.error

        mAppBar(position = MAppBarPosition.absolute, color = MAppBarColor.inherit) {
            css {
                zIndex = currentTheme.zIndex.drawer + 1
                display = Display.block
            }
            styledImg(alt = "ivanto Logo", src = "/portal/static/ivanto_logo_rgb.png") {
                css {
                    margin(horizontal = LinearDimension.auto)
                    height = 80.px
                    display = Display.block
                }
            }
        }

        styledDiv {
            css {
                width = 100.pct
                display = Display.block
                marginLeft = 2.spacingUnits
                marginRight = 2.spacingUnits
                marginTop = 80.px
                media(currentTheme.breakpoints.up(Breakpoint.sm)) {
                    width = 400.px
                    marginLeft = LinearDimension.auto
                    marginRight = LinearDimension.auto
                }
            }

            document.onkeydown = {
                if (it.keyCode == 13) // User hits enter
                    tryReset()
            }

            mPaper {
                css {
                    marginTop = 8.spacingUnits
                    marginBottom = 1.spacingUnits
                    display = Display.flex
                    flexDirection = FlexDirection.column
                    alignItems = Align.center
                    padding(2.spacingUnits, 3.spacingUnits, 3.spacingUnits)
                }
                mAvatar {
                    css {
                        margin(2.spacingUnits)
                        color = Colors.white
                        backgroundColor = Color("#ee7203")
                    }
                    mIconNoTranslate("vpn_key")
                }
                mTypography(text = "Passwort setzen", variant = MTypographyVariant.h5)
                mFormGroup {
                    css {
                        marginTop = 1.spacingUnits
                        width = 100.pct
                    }
                    mFormControl(
                        margin = MFormControlMargin.normal,
                        required = true,
                        fullWidth = true,
                        disabled = true
                    ) {
                        mInputLabel(caption = "E-Mail", htmlFor = "email")
                        mInput(
                            value = state.email,
                            name = "email",
                            id = "email",
                        ) {
                            attrs.autoComplete = "email"
                        }
                    }
                    mFormControl(
                        margin = MFormControlMargin.normal,
                        required = true,
                        fullWidth = true,
                        disabled = loading
                    ) {
                        mInputLabel(caption = "Passwort", htmlFor = "password")
                        mInput(
                            name = "password",
                            type = InputType.password,
                            id = "password",
                            autoFocus = true,
                            onChange = {
                                val value = it.targetInputValue
                                setState { password = value }
                            }
                        )
                    }
                    mFormControl(
                        margin = MFormControlMargin.normal,
                        required = true,
                        fullWidth = true,
                        disabled = loading
                    ) {
                        mInputLabel(caption = "Passwort wiederholen", htmlFor = "password")
                        mInput(
                            name = "password",
                            type = InputType.password,
                            id = "password",
                            onChange = {
                                val value = it.targetInputValue
                                setState { passwordCheck = value }
                            }
                        )
                    }

                    if (loading) {
                        mCircularProgress {
                            css {
                                marginTop = 3.spacingUnits
                                marginLeft = LinearDimension.auto
                                marginRight = LinearDimension.auto
                            }
                        }
                    } else {
                        mButton(
                            variant = MButtonVariant.contained,
                            color = MColor.primary,
                            caption = "Speichern",
                            onClick = { tryReset() }
                        ) {
                            attrs.fullWidth = true

                            css { marginTop = 3.spacingUnits }
                        }
                    }

                    if (error != null) {
                        mSnackbar(
                            error,
                            open = true,
                            horizAnchor = MSnackbarHorizAnchor.center,
                            autoHideDuration = 4000
                        ) {
                            attrs.onClose = { _, _ -> setState { this.error = null } }
                            attrs.action = RBuilder().div {
                                mIconButtonNoTranslate(
                                    iconName = "close",
                                    onClick = { setState { this.error = null } },
                                    color = MColor.inherit
                                )
                            }
                        }
                    }
                }
            }
        }
    }

    private fun tryReset() {
        if (state.password != state.passwordCheck) {
            setState { this.error = "Die Passwörter stimmen nicht überein" }
            return
        }

        job.cancel()
        job = launch {
            setState { loading = true }
            try {
                val response = withContext(Dispatchers.Default) {
                    portalRestApi.post(
                        path = "/resetpassword",
                        body = Json.encodeToString(
                            ResetPasswordDTO.serializer(), ResetPasswordDTO(
                                token = props.token,
                                password = state.password
                            )
                        ),
                        serializer = LoginResponseDTO.serializer()
                    )
                }

                UserStore.realToken = response.token
                UserStore.realUser = response.userDTO
                props.loginEvent()

            } catch (e: ApiException) {
                println("LOGIN ERROR: $e")

                val error = if (e.apiError.isInvalidSessionError)
                    "Invalid login credentials"
                else
                    e.message

                setState {
                    this.error = error
                    this.loading = false
                }
            }

        }
    }
}