package de.geomobile.frontend.features.upload

import de.geomobile.frontend.utils.CComponent
import kotlinx.css.*
import react.RBuilder
import react.RProps
import react.RState
import react.ReactElement
import react.dom.attrs
import styled.css
import styled.styledDiv
import styled.styledIframe

fun RBuilder.uploadEmbed(
    headless: Boolean,
    drawerMenu: ReactElement,
) = child(UploadEmbed::class) {
    attrs.headless = headless
    attrs.drawerMenu = drawerMenu
}

class UploadEmbed : CComponent<UploadEmbed.Props, UploadEmbed.State>() {

    interface Props : RProps {
        var headless: Boolean
        var drawerMenu: ReactElement
    }

    class State() : RState

    init {
        state = State()
    }

    override fun RBuilder.render() {
        styledDiv {
            css {
                display = Display.flex
                flexDirection = FlexDirection.column

                width = 100.pct
                height = 100.vh
            }
            styledIframe {
                attrs {
                    height = "100%"
                    width = "100%"
                    src = "https://vdv.ivanto.de/"
                }
                css {
                    background = "#F9FAFB"
                    border = "none"
                }
            }
        }
    }
}
