// Code generated by Wire protocol buffer compiler, do not edit.
// Source: de.geomobile.common.portalmodels.DeviceListItemDTO in portalmodels/device_list_item_dto.proto
package de.geomobile.common.portalmodels

import com.squareup.wire.FieldEncoding
import com.squareup.wire.Message
import com.squareup.wire.ProtoAdapter
import com.squareup.wire.ProtoReader
import com.squareup.wire.ProtoWriter
import com.squareup.wire.ReverseProtoWriter
import com.squareup.wire.Syntax
import com.squareup.wire.Syntax.PROTO_2
import com.squareup.wire.WireField
import com.squareup.wire.`internal`.immutableCopyOf
import com.squareup.wire.`internal`.missingRequiredFields
import com.squareup.wire.`internal`.sanitize
import kotlin.Any
import kotlin.AssertionError
import kotlin.Boolean
import kotlin.Deprecated
import kotlin.DeprecationLevel
import kotlin.Int
import kotlin.Long
import kotlin.Nothing
import kotlin.String
import kotlin.Unit
import kotlin.collections.List
import kotlin.jvm.JvmField
import okio.ByteString

public class DeviceListItemDTO(
  @field:WireField(
    tag = 1,
    adapter = "com.squareup.wire.ProtoAdapter#INT32",
    label = WireField.Label.REQUIRED
  )
  public val id: Int,
  @field:WireField(
    tag = 2,
    adapter = "com.squareup.wire.ProtoAdapter#INT32",
    label = WireField.Label.REQUIRED
  )
  public val productOrdinal: Int,
  @field:WireField(
    tag = 3,
    adapter = "com.squareup.wire.ProtoAdapter#STRING"
  )
  public val productVariant: String? = null,
  @field:WireField(
    tag = 4,
    adapter = "com.squareup.wire.ProtoAdapter#INT32"
  )
  public val serialNumber: Int? = null,
  @field:WireField(
    tag = 5,
    adapter = "com.squareup.wire.ProtoAdapter#STRING"
  )
  public val cpuId: String? = null,
  @field:WireField(
    tag = 6,
    adapter = "com.squareup.wire.ProtoAdapter#STRING"
  )
  public val vehicleId: String? = null,
  @field:WireField(
    tag = 7,
    adapter = "com.squareup.wire.ProtoAdapter#INT32"
  )
  public val vehicleTypeOrdinal: Int? = null,
  @field:WireField(
    tag = 8,
    adapter = "com.squareup.wire.ProtoAdapter#STRING",
    label = WireField.Label.REQUIRED
  )
  public val hardwareId: String,
  @field:WireField(
    tag = 9,
    adapter = "com.squareup.wire.ProtoAdapter#INT32",
    label = WireField.Label.REQUIRED
  )
  public val companyIndex: Int,
  @field:WireField(
    tag = 10,
    adapter = "com.squareup.wire.ProtoAdapter#INT32",
    label = WireField.Label.REQUIRED
  )
  public val stageOrdinal: Int,
  @field:WireField(
    tag = 11,
    adapter = "com.squareup.wire.ProtoAdapter#INT64",
    label = WireField.Label.REQUIRED
  )
  public val inceptionDate: Long,
  @field:WireField(
    tag = 12,
    adapter = "com.squareup.wire.ProtoAdapter#INT64",
    label = WireField.Label.REQUIRED
  )
  public val installDate: Long,
  @field:WireField(
    tag = 13,
    adapter = "com.squareup.wire.ProtoAdapter#INT64"
  )
  public val shippingDate: Long? = null,
  @field:WireField(
    tag = 14,
    adapter = "de.geomobile.common.portalmodels.DeviceListItemDTO${'$'}TimestampStatus#ADAPTER",
    label = WireField.Label.REQUIRED
  )
  public val status: TimestampStatus,
  @field:WireField(
    tag = 15,
    adapter = "de.geomobile.common.portalmodels.DeviceListItemDTO${'$'}TimestampStatus#ADAPTER",
    label = WireField.Label.REQUIRED
  )
  public val betaStatus: TimestampStatus,
  @field:WireField(
    tag = 16,
    adapter = "com.squareup.wire.ProtoAdapter#INT32"
  )
  public val volume: Int? = null,
  @field:WireField(
    tag = 17,
    adapter = "com.squareup.wire.ProtoAdapter#STRING"
  )
  public val description: String? = null,
  @field:WireField(
    tag = 18,
    adapter = "com.squareup.wire.ProtoAdapter#INT32"
  )
  public val updateStateProgress: Int? = null,
  @field:WireField(
    tag = 19,
    adapter = "com.squareup.wire.ProtoAdapter#INT32"
  )
  public val updateStateOrdinal: Int? = null,
  @field:WireField(
    tag = 20,
    adapter = "com.squareup.wire.ProtoAdapter#INT32"
  )
  public val externalUpdateStateOrdinal: Int? = null,
  @field:WireField(
    tag = 21,
    adapter = "com.squareup.wire.ProtoAdapter#INT32",
    label = WireField.Label.REQUIRED
  )
  public val vehicleProfileIndex: Int,
  @field:WireField(
    tag = 22,
    adapter = "com.squareup.wire.ProtoAdapter#STRING"
  )
  public val line: String? = null,
  @field:WireField(
    tag = 23,
    adapter = "com.squareup.wire.ProtoAdapter#STRING"
  )
  public val destination: String? = null,
  sharingWithIndex: List<Int> = emptyList(),
  unknownFields: ByteString = ByteString.EMPTY
) : Message<DeviceListItemDTO, Nothing>(ADAPTER, unknownFields) {
  @field:WireField(
    tag = 24,
    adapter = "com.squareup.wire.ProtoAdapter#INT32",
    label = WireField.Label.REPEATED
  )
  public val sharingWithIndex: List<Int> = immutableCopyOf("sharingWithIndex", sharingWithIndex)

  @Deprecated(
    message = "Shouldn't be used in Kotlin",
    level = DeprecationLevel.HIDDEN
  )
  public override fun newBuilder(): Nothing = throw
      AssertionError("Builders are deprecated and only available in a javaInterop build; see https://square.github.io/wire/wire_compiler/#kotlin")

  public override fun equals(other: Any?): Boolean {
    if (other === this) return true
    if (other !is DeviceListItemDTO) return false
    if (unknownFields != other.unknownFields) return false
    if (id != other.id) return false
    if (productOrdinal != other.productOrdinal) return false
    if (productVariant != other.productVariant) return false
    if (serialNumber != other.serialNumber) return false
    if (cpuId != other.cpuId) return false
    if (vehicleId != other.vehicleId) return false
    if (vehicleTypeOrdinal != other.vehicleTypeOrdinal) return false
    if (hardwareId != other.hardwareId) return false
    if (companyIndex != other.companyIndex) return false
    if (stageOrdinal != other.stageOrdinal) return false
    if (inceptionDate != other.inceptionDate) return false
    if (installDate != other.installDate) return false
    if (shippingDate != other.shippingDate) return false
    if (status != other.status) return false
    if (betaStatus != other.betaStatus) return false
    if (volume != other.volume) return false
    if (description != other.description) return false
    if (updateStateProgress != other.updateStateProgress) return false
    if (updateStateOrdinal != other.updateStateOrdinal) return false
    if (externalUpdateStateOrdinal != other.externalUpdateStateOrdinal) return false
    if (vehicleProfileIndex != other.vehicleProfileIndex) return false
    if (line != other.line) return false
    if (destination != other.destination) return false
    if (sharingWithIndex != other.sharingWithIndex) return false
    return true
  }

  public override fun hashCode(): Int {
    var result = super.hashCode
    if (result == 0) {
      result = unknownFields.hashCode()
      result = result * 37 + id.hashCode()
      result = result * 37 + productOrdinal.hashCode()
      result = result * 37 + (productVariant?.hashCode() ?: 0)
      result = result * 37 + (serialNumber?.hashCode() ?: 0)
      result = result * 37 + (cpuId?.hashCode() ?: 0)
      result = result * 37 + (vehicleId?.hashCode() ?: 0)
      result = result * 37 + (vehicleTypeOrdinal?.hashCode() ?: 0)
      result = result * 37 + hardwareId.hashCode()
      result = result * 37 + companyIndex.hashCode()
      result = result * 37 + stageOrdinal.hashCode()
      result = result * 37 + inceptionDate.hashCode()
      result = result * 37 + installDate.hashCode()
      result = result * 37 + (shippingDate?.hashCode() ?: 0)
      result = result * 37 + status.hashCode()
      result = result * 37 + betaStatus.hashCode()
      result = result * 37 + (volume?.hashCode() ?: 0)
      result = result * 37 + (description?.hashCode() ?: 0)
      result = result * 37 + (updateStateProgress?.hashCode() ?: 0)
      result = result * 37 + (updateStateOrdinal?.hashCode() ?: 0)
      result = result * 37 + (externalUpdateStateOrdinal?.hashCode() ?: 0)
      result = result * 37 + vehicleProfileIndex.hashCode()
      result = result * 37 + (line?.hashCode() ?: 0)
      result = result * 37 + (destination?.hashCode() ?: 0)
      result = result * 37 + sharingWithIndex.hashCode()
      super.hashCode = result
    }
    return result
  }

  public override fun toString(): String {
    val result = mutableListOf<String>()
    result += """id=$id"""
    result += """productOrdinal=$productOrdinal"""
    if (productVariant != null) result += """productVariant=${sanitize(productVariant)}"""
    if (serialNumber != null) result += """serialNumber=$serialNumber"""
    if (cpuId != null) result += """cpuId=${sanitize(cpuId)}"""
    if (vehicleId != null) result += """vehicleId=${sanitize(vehicleId)}"""
    if (vehicleTypeOrdinal != null) result += """vehicleTypeOrdinal=$vehicleTypeOrdinal"""
    result += """hardwareId=${sanitize(hardwareId)}"""
    result += """companyIndex=$companyIndex"""
    result += """stageOrdinal=$stageOrdinal"""
    result += """inceptionDate=$inceptionDate"""
    result += """installDate=$installDate"""
    if (shippingDate != null) result += """shippingDate=$shippingDate"""
    result += """status=$status"""
    result += """betaStatus=$betaStatus"""
    if (volume != null) result += """volume=$volume"""
    if (description != null) result += """description=${sanitize(description)}"""
    if (updateStateProgress != null) result += """updateStateProgress=$updateStateProgress"""
    if (updateStateOrdinal != null) result += """updateStateOrdinal=$updateStateOrdinal"""
    if (externalUpdateStateOrdinal != null) result +=
        """externalUpdateStateOrdinal=$externalUpdateStateOrdinal"""
    result += """vehicleProfileIndex=$vehicleProfileIndex"""
    if (line != null) result += """line=${sanitize(line)}"""
    if (destination != null) result += """destination=${sanitize(destination)}"""
    if (sharingWithIndex.isNotEmpty()) result += """sharingWithIndex=$sharingWithIndex"""
    return result.joinToString(prefix = "DeviceListItemDTO{", separator = ", ", postfix = "}")
  }

  public fun copy(
    id: Int = this.id,
    productOrdinal: Int = this.productOrdinal,
    productVariant: String? = this.productVariant,
    serialNumber: Int? = this.serialNumber,
    cpuId: String? = this.cpuId,
    vehicleId: String? = this.vehicleId,
    vehicleTypeOrdinal: Int? = this.vehicleTypeOrdinal,
    hardwareId: String = this.hardwareId,
    companyIndex: Int = this.companyIndex,
    stageOrdinal: Int = this.stageOrdinal,
    inceptionDate: Long = this.inceptionDate,
    installDate: Long = this.installDate,
    shippingDate: Long? = this.shippingDate,
    status: TimestampStatus = this.status,
    betaStatus: TimestampStatus = this.betaStatus,
    volume: Int? = this.volume,
    description: String? = this.description,
    updateStateProgress: Int? = this.updateStateProgress,
    updateStateOrdinal: Int? = this.updateStateOrdinal,
    externalUpdateStateOrdinal: Int? = this.externalUpdateStateOrdinal,
    vehicleProfileIndex: Int = this.vehicleProfileIndex,
    line: String? = this.line,
    destination: String? = this.destination,
    sharingWithIndex: List<Int> = this.sharingWithIndex,
    unknownFields: ByteString = this.unknownFields
  ): DeviceListItemDTO = DeviceListItemDTO(id, productOrdinal, productVariant, serialNumber, cpuId,
      vehicleId, vehicleTypeOrdinal, hardwareId, companyIndex, stageOrdinal, inceptionDate,
      installDate, shippingDate, status, betaStatus, volume, description, updateStateProgress,
      updateStateOrdinal, externalUpdateStateOrdinal, vehicleProfileIndex, line, destination,
      sharingWithIndex, unknownFields)

  public companion object {
    @JvmField
    public val ADAPTER: ProtoAdapter<DeviceListItemDTO> = object : ProtoAdapter<DeviceListItemDTO>(
      FieldEncoding.LENGTH_DELIMITED, 
      DeviceListItemDTO::class, 
      "type.googleapis.com/de.geomobile.common.portalmodels.DeviceListItemDTO", 
      PROTO_2, 
      null, 
      "portalmodels/device_list_item_dto.proto"
    ) {
      public override fun encodedSize(`value`: DeviceListItemDTO): Int {
        var size = value.unknownFields.size
        size += ProtoAdapter.INT32.encodedSizeWithTag(1, value.id)
        size += ProtoAdapter.INT32.encodedSizeWithTag(2, value.productOrdinal)
        size += ProtoAdapter.STRING.encodedSizeWithTag(3, value.productVariant)
        size += ProtoAdapter.INT32.encodedSizeWithTag(4, value.serialNumber)
        size += ProtoAdapter.STRING.encodedSizeWithTag(5, value.cpuId)
        size += ProtoAdapter.STRING.encodedSizeWithTag(6, value.vehicleId)
        size += ProtoAdapter.INT32.encodedSizeWithTag(7, value.vehicleTypeOrdinal)
        size += ProtoAdapter.STRING.encodedSizeWithTag(8, value.hardwareId)
        size += ProtoAdapter.INT32.encodedSizeWithTag(9, value.companyIndex)
        size += ProtoAdapter.INT32.encodedSizeWithTag(10, value.stageOrdinal)
        size += ProtoAdapter.INT64.encodedSizeWithTag(11, value.inceptionDate)
        size += ProtoAdapter.INT64.encodedSizeWithTag(12, value.installDate)
        size += ProtoAdapter.INT64.encodedSizeWithTag(13, value.shippingDate)
        size += TimestampStatus.ADAPTER.encodedSizeWithTag(14, value.status)
        size += TimestampStatus.ADAPTER.encodedSizeWithTag(15, value.betaStatus)
        size += ProtoAdapter.INT32.encodedSizeWithTag(16, value.volume)
        size += ProtoAdapter.STRING.encodedSizeWithTag(17, value.description)
        size += ProtoAdapter.INT32.encodedSizeWithTag(18, value.updateStateProgress)
        size += ProtoAdapter.INT32.encodedSizeWithTag(19, value.updateStateOrdinal)
        size += ProtoAdapter.INT32.encodedSizeWithTag(20, value.externalUpdateStateOrdinal)
        size += ProtoAdapter.INT32.encodedSizeWithTag(21, value.vehicleProfileIndex)
        size += ProtoAdapter.STRING.encodedSizeWithTag(22, value.line)
        size += ProtoAdapter.STRING.encodedSizeWithTag(23, value.destination)
        size += ProtoAdapter.INT32.asRepeated().encodedSizeWithTag(24, value.sharingWithIndex)
        return size
      }

      public override fun encode(writer: ProtoWriter, `value`: DeviceListItemDTO): Unit {
        ProtoAdapter.INT32.encodeWithTag(writer, 1, value.id)
        ProtoAdapter.INT32.encodeWithTag(writer, 2, value.productOrdinal)
        ProtoAdapter.STRING.encodeWithTag(writer, 3, value.productVariant)
        ProtoAdapter.INT32.encodeWithTag(writer, 4, value.serialNumber)
        ProtoAdapter.STRING.encodeWithTag(writer, 5, value.cpuId)
        ProtoAdapter.STRING.encodeWithTag(writer, 6, value.vehicleId)
        ProtoAdapter.INT32.encodeWithTag(writer, 7, value.vehicleTypeOrdinal)
        ProtoAdapter.STRING.encodeWithTag(writer, 8, value.hardwareId)
        ProtoAdapter.INT32.encodeWithTag(writer, 9, value.companyIndex)
        ProtoAdapter.INT32.encodeWithTag(writer, 10, value.stageOrdinal)
        ProtoAdapter.INT64.encodeWithTag(writer, 11, value.inceptionDate)
        ProtoAdapter.INT64.encodeWithTag(writer, 12, value.installDate)
        ProtoAdapter.INT64.encodeWithTag(writer, 13, value.shippingDate)
        TimestampStatus.ADAPTER.encodeWithTag(writer, 14, value.status)
        TimestampStatus.ADAPTER.encodeWithTag(writer, 15, value.betaStatus)
        ProtoAdapter.INT32.encodeWithTag(writer, 16, value.volume)
        ProtoAdapter.STRING.encodeWithTag(writer, 17, value.description)
        ProtoAdapter.INT32.encodeWithTag(writer, 18, value.updateStateProgress)
        ProtoAdapter.INT32.encodeWithTag(writer, 19, value.updateStateOrdinal)
        ProtoAdapter.INT32.encodeWithTag(writer, 20, value.externalUpdateStateOrdinal)
        ProtoAdapter.INT32.encodeWithTag(writer, 21, value.vehicleProfileIndex)
        ProtoAdapter.STRING.encodeWithTag(writer, 22, value.line)
        ProtoAdapter.STRING.encodeWithTag(writer, 23, value.destination)
        ProtoAdapter.INT32.asRepeated().encodeWithTag(writer, 24, value.sharingWithIndex)
        writer.writeBytes(value.unknownFields)
      }

      public override fun encode(writer: ReverseProtoWriter, `value`: DeviceListItemDTO): Unit {
        writer.writeBytes(value.unknownFields)
        ProtoAdapter.INT32.asRepeated().encodeWithTag(writer, 24, value.sharingWithIndex)
        ProtoAdapter.STRING.encodeWithTag(writer, 23, value.destination)
        ProtoAdapter.STRING.encodeWithTag(writer, 22, value.line)
        ProtoAdapter.INT32.encodeWithTag(writer, 21, value.vehicleProfileIndex)
        ProtoAdapter.INT32.encodeWithTag(writer, 20, value.externalUpdateStateOrdinal)
        ProtoAdapter.INT32.encodeWithTag(writer, 19, value.updateStateOrdinal)
        ProtoAdapter.INT32.encodeWithTag(writer, 18, value.updateStateProgress)
        ProtoAdapter.STRING.encodeWithTag(writer, 17, value.description)
        ProtoAdapter.INT32.encodeWithTag(writer, 16, value.volume)
        TimestampStatus.ADAPTER.encodeWithTag(writer, 15, value.betaStatus)
        TimestampStatus.ADAPTER.encodeWithTag(writer, 14, value.status)
        ProtoAdapter.INT64.encodeWithTag(writer, 13, value.shippingDate)
        ProtoAdapter.INT64.encodeWithTag(writer, 12, value.installDate)
        ProtoAdapter.INT64.encodeWithTag(writer, 11, value.inceptionDate)
        ProtoAdapter.INT32.encodeWithTag(writer, 10, value.stageOrdinal)
        ProtoAdapter.INT32.encodeWithTag(writer, 9, value.companyIndex)
        ProtoAdapter.STRING.encodeWithTag(writer, 8, value.hardwareId)
        ProtoAdapter.INT32.encodeWithTag(writer, 7, value.vehicleTypeOrdinal)
        ProtoAdapter.STRING.encodeWithTag(writer, 6, value.vehicleId)
        ProtoAdapter.STRING.encodeWithTag(writer, 5, value.cpuId)
        ProtoAdapter.INT32.encodeWithTag(writer, 4, value.serialNumber)
        ProtoAdapter.STRING.encodeWithTag(writer, 3, value.productVariant)
        ProtoAdapter.INT32.encodeWithTag(writer, 2, value.productOrdinal)
        ProtoAdapter.INT32.encodeWithTag(writer, 1, value.id)
      }

      public override fun decode(reader: ProtoReader): DeviceListItemDTO {
        var id: Int? = null
        var productOrdinal: Int? = null
        var productVariant: String? = null
        var serialNumber: Int? = null
        var cpuId: String? = null
        var vehicleId: String? = null
        var vehicleTypeOrdinal: Int? = null
        var hardwareId: String? = null
        var companyIndex: Int? = null
        var stageOrdinal: Int? = null
        var inceptionDate: Long? = null
        var installDate: Long? = null
        var shippingDate: Long? = null
        var status: TimestampStatus? = null
        var betaStatus: TimestampStatus? = null
        var volume: Int? = null
        var description: String? = null
        var updateStateProgress: Int? = null
        var updateStateOrdinal: Int? = null
        var externalUpdateStateOrdinal: Int? = null
        var vehicleProfileIndex: Int? = null
        var line: String? = null
        var destination: String? = null
        val sharingWithIndex = mutableListOf<Int>()
        val unknownFields = reader.forEachTag { tag ->
          when (tag) {
            1 -> id = ProtoAdapter.INT32.decode(reader)
            2 -> productOrdinal = ProtoAdapter.INT32.decode(reader)
            3 -> productVariant = ProtoAdapter.STRING.decode(reader)
            4 -> serialNumber = ProtoAdapter.INT32.decode(reader)
            5 -> cpuId = ProtoAdapter.STRING.decode(reader)
            6 -> vehicleId = ProtoAdapter.STRING.decode(reader)
            7 -> vehicleTypeOrdinal = ProtoAdapter.INT32.decode(reader)
            8 -> hardwareId = ProtoAdapter.STRING.decode(reader)
            9 -> companyIndex = ProtoAdapter.INT32.decode(reader)
            10 -> stageOrdinal = ProtoAdapter.INT32.decode(reader)
            11 -> inceptionDate = ProtoAdapter.INT64.decode(reader)
            12 -> installDate = ProtoAdapter.INT64.decode(reader)
            13 -> shippingDate = ProtoAdapter.INT64.decode(reader)
            14 -> status = TimestampStatus.ADAPTER.decode(reader)
            15 -> betaStatus = TimestampStatus.ADAPTER.decode(reader)
            16 -> volume = ProtoAdapter.INT32.decode(reader)
            17 -> description = ProtoAdapter.STRING.decode(reader)
            18 -> updateStateProgress = ProtoAdapter.INT32.decode(reader)
            19 -> updateStateOrdinal = ProtoAdapter.INT32.decode(reader)
            20 -> externalUpdateStateOrdinal = ProtoAdapter.INT32.decode(reader)
            21 -> vehicleProfileIndex = ProtoAdapter.INT32.decode(reader)
            22 -> line = ProtoAdapter.STRING.decode(reader)
            23 -> destination = ProtoAdapter.STRING.decode(reader)
            24 -> sharingWithIndex.add(ProtoAdapter.INT32.decode(reader))
            else -> reader.readUnknownField(tag)
          }
        }
        return DeviceListItemDTO(
          id = id ?: throw missingRequiredFields(id, "id"),
          productOrdinal = productOrdinal ?: throw missingRequiredFields(productOrdinal,
              "productOrdinal"),
          productVariant = productVariant,
          serialNumber = serialNumber,
          cpuId = cpuId,
          vehicleId = vehicleId,
          vehicleTypeOrdinal = vehicleTypeOrdinal,
          hardwareId = hardwareId ?: throw missingRequiredFields(hardwareId, "hardwareId"),
          companyIndex = companyIndex ?: throw missingRequiredFields(companyIndex, "companyIndex"),
          stageOrdinal = stageOrdinal ?: throw missingRequiredFields(stageOrdinal, "stageOrdinal"),
          inceptionDate = inceptionDate ?: throw missingRequiredFields(inceptionDate,
              "inceptionDate"),
          installDate = installDate ?: throw missingRequiredFields(installDate, "installDate"),
          shippingDate = shippingDate,
          status = status ?: throw missingRequiredFields(status, "status"),
          betaStatus = betaStatus ?: throw missingRequiredFields(betaStatus, "betaStatus"),
          volume = volume,
          description = description,
          updateStateProgress = updateStateProgress,
          updateStateOrdinal = updateStateOrdinal,
          externalUpdateStateOrdinal = externalUpdateStateOrdinal,
          vehicleProfileIndex = vehicleProfileIndex ?: throw
              missingRequiredFields(vehicleProfileIndex, "vehicleProfileIndex"),
          line = line,
          destination = destination,
          sharingWithIndex = sharingWithIndex,
          unknownFields = unknownFields
        )
      }

      public override fun redact(`value`: DeviceListItemDTO): DeviceListItemDTO = value.copy(
        status = TimestampStatus.ADAPTER.redact(value.status),
        betaStatus = TimestampStatus.ADAPTER.redact(value.betaStatus),
        unknownFields = ByteString.EMPTY
      )
    }

    private const val serialVersionUID: Long = 0L
  }

  public class TimestampStatus(
    @field:WireField(
      tag = 1,
      adapter = "com.squareup.wire.ProtoAdapter#INT64"
    )
    public val timestamp: Long? = null,
    @field:WireField(
      tag = 2,
      adapter = "com.squareup.wire.ProtoAdapter#INT32"
    )
    public val intervalSec: Int? = null,
    unknownFields: ByteString = ByteString.EMPTY
  ) : Message<TimestampStatus, Nothing>(ADAPTER, unknownFields) {
    @Deprecated(
      message = "Shouldn't be used in Kotlin",
      level = DeprecationLevel.HIDDEN
    )
    public override fun newBuilder(): Nothing = throw
        AssertionError("Builders are deprecated and only available in a javaInterop build; see https://square.github.io/wire/wire_compiler/#kotlin")

    public override fun equals(other: Any?): Boolean {
      if (other === this) return true
      if (other !is TimestampStatus) return false
      if (unknownFields != other.unknownFields) return false
      if (timestamp != other.timestamp) return false
      if (intervalSec != other.intervalSec) return false
      return true
    }

    public override fun hashCode(): Int {
      var result = super.hashCode
      if (result == 0) {
        result = unknownFields.hashCode()
        result = result * 37 + (timestamp?.hashCode() ?: 0)
        result = result * 37 + (intervalSec?.hashCode() ?: 0)
        super.hashCode = result
      }
      return result
    }

    public override fun toString(): String {
      val result = mutableListOf<String>()
      if (timestamp != null) result += """timestamp=$timestamp"""
      if (intervalSec != null) result += """intervalSec=$intervalSec"""
      return result.joinToString(prefix = "TimestampStatus{", separator = ", ", postfix = "}")
    }

    public fun copy(
      timestamp: Long? = this.timestamp,
      intervalSec: Int? = this.intervalSec,
      unknownFields: ByteString = this.unknownFields
    ): TimestampStatus = TimestampStatus(timestamp, intervalSec, unknownFields)

    public companion object {
      @JvmField
      public val ADAPTER: ProtoAdapter<TimestampStatus> = object : ProtoAdapter<TimestampStatus>(
        FieldEncoding.LENGTH_DELIMITED, 
        TimestampStatus::class, 
        "type.googleapis.com/de.geomobile.common.portalmodels.DeviceListItemDTO.TimestampStatus", 
        PROTO_2, 
        null, 
        "portalmodels/device_list_item_dto.proto"
      ) {
        public override fun encodedSize(`value`: TimestampStatus): Int {
          var size = value.unknownFields.size
          size += ProtoAdapter.INT64.encodedSizeWithTag(1, value.timestamp)
          size += ProtoAdapter.INT32.encodedSizeWithTag(2, value.intervalSec)
          return size
        }

        public override fun encode(writer: ProtoWriter, `value`: TimestampStatus): Unit {
          ProtoAdapter.INT64.encodeWithTag(writer, 1, value.timestamp)
          ProtoAdapter.INT32.encodeWithTag(writer, 2, value.intervalSec)
          writer.writeBytes(value.unknownFields)
        }

        public override fun encode(writer: ReverseProtoWriter, `value`: TimestampStatus): Unit {
          writer.writeBytes(value.unknownFields)
          ProtoAdapter.INT32.encodeWithTag(writer, 2, value.intervalSec)
          ProtoAdapter.INT64.encodeWithTag(writer, 1, value.timestamp)
        }

        public override fun decode(reader: ProtoReader): TimestampStatus {
          var timestamp: Long? = null
          var intervalSec: Int? = null
          val unknownFields = reader.forEachTag { tag ->
            when (tag) {
              1 -> timestamp = ProtoAdapter.INT64.decode(reader)
              2 -> intervalSec = ProtoAdapter.INT32.decode(reader)
              else -> reader.readUnknownField(tag)
            }
          }
          return TimestampStatus(
            timestamp = timestamp,
            intervalSec = intervalSec,
            unknownFields = unknownFields
          )
        }

        public override fun redact(`value`: TimestampStatus): TimestampStatus = value.copy(
          unknownFields = ByteString.EMPTY
        )
      }

      private const val serialVersionUID: Long = 0L
    }
  }
}
